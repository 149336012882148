export const styles = () => {
	const styles = [
		{
			id: "gl-draw-point-point-stroke-inactive",
			type: "circle",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Point"],
				["==", "meta", "feature"],
				["!=", "mode", "static"],
				["!=", "user_meta", "drawn_feature"]
			],
			paint: {
				"circle-radius": 11,
				"circle-opacity": 1,
				"circle-color": "#fff"
			}
		},
		{
			id: "gl-draw-point-inactive",
			type: "circle",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Point"],
				["==", "meta", "feature"],
				["!=", "mode", "static"],
				["!=", "user_meta", "drawn_feature"]
			],
			paint: {
				"circle-radius": 9,
				"circle-color": "#F7455D"
			}
		},
		{
			id: "gl-draw-point-stroke-active",
			type: "circle",
			filter: [
				"all",
				["==", "$type", "Point"],
				["==", "active", "true"],
				["!=", "meta", "midpoint"],
				["!=", "user_meta", "drawn_feature"]
			],
			paint: {
				"circle-radius": 11,
				"circle-opacity": 1,
				"circle-color": "#fff"
			}
		},
		{
			id: "gl-draw-point-active",
			type: "circle",
			filter: [
				"all",
				["==", "$type", "Point"],
				["!=", "meta", "midpoint"],
				["==", "active", "true"],
				["!=", "user_meta", "drawn_feature"]
			],
			paint: {
				"circle-radius": 9,
				"circle-color": "#F7455D"
			}
		},
		{
			id: "gl-draw-polygon-fill-inactive",
			type: "fill",
			filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
			paint: {
				"fill-color": "#3bb2d0",
				"fill-outline-color": "#3bb2d0",
				"fill-opacity": 0.0
			}
		},
		{
			id: "gl-draw-polygon-fill-active",
			type: "fill",
			filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"], ["!=", "user_type", "farm"]],
			paint: {
				"fill-color": "#fbb03b",
				"fill-outline-color": "#fbb03b",
				"fill-opacity": 0.1
			}
		},
		{
			id: "gl-draw-polygon-midpoint",
			type: "circle",
			filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
			paint: {
				"circle-radius": 3,
				"circle-color": "#fbb03b"
			}
		},
		{
			id: "gl-draw-polygon-stroke-inactive",
			type: "line",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Polygon"],
				["!=", "mode", "static"],
				["!has", "user_trialColor"],
				["!has", "user_defColor"],
				["has", "user_portColor"]
			],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": ["get", "user_portColor"],
				"line-width": 2
			}
		},
		{
			id: "gl-draw-polygon-stroke-active",
			type: "line",
			filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#fbb03b",
				"line-dasharray": [0.2, 2],
				"line-width": 2
			}
		},
		{
			id: "gl-draw-polygon-stroke-def-styling",
			type: "line",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Polygon"],
				["!=", "mode", "static"],
				["!has", "user_trialColor"],
				["has", "user_defColor"]
			],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": ["get", "user_defColor"],
				"line-width": 4
			}
		},
		{
			id: "gl-draw-line-inactive",
			type: "line",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "LineString"],
				["!=", "mode", "static"],
				["has", "user_type"]
			],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#f15c32",
				"line-dasharray": [0.2, 2],
				"line-width": ["interpolate", ["linear"], ["zoom"], 19, 0, 20, 6]
			}
		},
		{
			id: "gl-draw-line-active",
			type: "line",
			filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"], ["has", "user_type"]],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#f15c32",
				"line-dasharray": [0.2, 2],
				"line-width": ["interpolate", ["linear"], ["zoom"], 19, 0, 20, 6]
			}
		},
		{
			id: "gl-draw-line-inactive-no-zoom",
			type: "line",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "LineString"],
				["!=", "mode", "static"],
				["!has", "user_type"]
			],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#3bb2d0",
				"line-width": 2,
				"line-dasharray": [0.2, 2]
			}
		},
		{
			id: "gl-draw-line-active-no-zoom",
			type: "line",
			filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"], ["!has", "user_type"]],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#fbb03b",
				"line-width": 2,
				"line-dasharray": [0.2, 2]
			}
		},
		{
			id: "gl-draw-polygon-fill-static",
			type: "fill",
			filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
			paint: {
				"fill-color": "#404040",
				"fill-outline-color": "#404040",
				"fill-opacity": 0.1
			}
		},
		{
			id: "gl-draw-polygon-stroke-static",
			type: "line",
			filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#404040",
				"line-width": 2
			}
		},
		{
			id: "gl-draw-line-static",
			type: "line",
			filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#404040",
				"line-width": 2
			}
		},
		{
			id: "gl-draw-point-static",
			type: "circle",
			filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
			paint: {
				"circle-radius": 5,
				"circle-color": "#404040"
			}
		}, // end default styles
		{
			id: "gl-draw-point-color-picker",
			type: "circle",
			filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
			paint: {
				"circle-radius": 7,
				"circle-color": ["get", "user_portColor"]
			}
		},
		{
			id: "gl-draw-polygon-color-picker-2",
			type: "fill",
			filter: ["all", ["==", "$type", "Polygon"], ["has", "user_alignColor"]],
			paint: {
				"fill-color": ["get", "user_alignColor"],
				"fill-outline-color": ["get", "user_alignColor"],
				"fill-opacity": 0.35
			}
		},
		{
			id: "gl-draw-polygon-heatmap-fill",
			type: "fill",
			filter: ["all", ["==", "$type", "Polygon"], ["has", "user_heatmapColor"]],
			paint: {
				"fill-color": ["get", "user_heatmapColor"],
				"fill-opacity": ["get", "user_heatmapOpacity"]
			}
		},
		{
			id: "gl-draw-polygon-stroke-inactive-2",
			type: "line",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Polygon"],
				["!=", "mode", "static"],
				["has", "user_trialColor"]
			],
			layout: {
				"line-sort-key": ["get", "user_trialOrder"],
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": ["get", "user_trialColor"],
				"line-width": 4
			}
		},
		{
			id: "gl-draw-polygon-measurement-fill",
			type: "fill",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Polygon"],
				["!=", "mode", "static"],
				["!has", "user_trialColor"],
				["!has", "user_defColor"],
				["!has", "user_portColor"]
			],
			paint: {
				"fill-color": "#3bb2d0",
				"fill-opacity": 0.35
			}
		},
		{
			id: "gl-draw-polygon-measurement-outline",
			type: "line",
			filter: [
				"all",
				["==", "active", "false"],
				["==", "$type", "Polygon"],
				["!=", "mode", "static"],
				["!has", "user_trialColor"],
				["!has", "user_defColor"],
				["!has", "user_portColor"]
			],
			layout: {
				"line-cap": "round",
				"line-join": "round"
			},
			paint: {
				"line-color": "#3bb2d0",
				"line-width": 2,
				"line-dasharray": [0.2, 2]
			}
		},
		// Vertex
		//   Visible when editing polygons and lines
		//   Similar behaviour to Points
		//   Active state defines size
		{
			id: "gl-draw-vertex-outer",
			type: "circle",
			filter: ["all", ["==", "$type", "Point"], ["==", "meta", "vertex"], ["!=", "mode", "simple_select"]],
			paint: {
				"circle-radius": ["case", ["==", ["get", "active"], "true"], 7, 5],
				"circle-color": "#ffffff"
			}
		},
		{
			id: "gl-draw-vertex-inner",
			type: "circle",
			filter: ["all", ["==", "$type", "Point"], ["==", "meta", "vertex"], ["!=", "mode", "simple_select"]],
			paint: {
				"circle-radius": ["case", ["==", ["get", "active"], "true"], 5, 3],
				"circle-color": "#3bb2d0"
			}
		},
		{
			id: "gl-draw-point-outer",
			type: "circle",
			filter: ["all", ["==", "$type", "Point"], ["==", "meta", "feature"], ["==", "user_meta", "drawn_feature"]],
			paint: {
				"circle-radius": ["case", ["==", ["get", "active"], "true"], 7, 5],
				"circle-color": "#ffffff"
			}
		},
		{
			id: "gl-draw-point-inner",
			type: "circle",
			filter: ["all", ["==", "$type", "Point"], ["==", "meta", "feature"], ["==", "user_meta", "drawn_feature"]],
			paint: {
				"circle-radius": ["case", ["==", ["get", "active"], "true"], 5, 3],
				"circle-color": "#3bb2d0"
			}
		}
	];
	return styles;
};
