import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import "./styles.css";

import Tooltip from "rc-tooltip";
import { Dropdown, Icon, List, Pagination, Segment, Table } from "semantic-ui-react";
import PubUnpubTrialFlightsFromSearch from "../../../Lumber/PubUnpubTrialFlights/PubUnpubTrialFlightsFromSearch";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import ActionsMenu from "../../../Lumber/Search/ActionsMenu.tsx";
import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";

const TrialSearchResults = ({ trialList, parentIsDashboard = false, trialStatuses, toggleMetaFactorModal }) => {
	const userAuth = useUserAuth();
	// const dispatch = useDispatch();
	// const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "M/D/YYYY";

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState(trialList);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	const userSettings = useUserSettings();

	const [automaticSort, setAutomaticSort] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalTrialId, setModalTrialId] = useState("");

	//Annotation Summary Modal
	// const [openAnnotationSummaryModal, setOpenAnnotationSummaryModal] = useState(false);
	// const [trialNameForModal, setTrialNameForModal] = useState(null);
	// const [annotationSummaryData, setAnnotationSummaryData] = useState([]);
	// const [annotationSummaryDataLoader, setAnnotationSummaryDataLoader] = useState(true);

	//-- Initial view load
	useEffect(() => {
		if (trialList) {
			setTotalPages(Math.ceil(trialList.length / itemsPerPage));
			setDataSource(trialList);
			setTableData(dataSource);
			setAutomaticSort(true);
		}
	}, [trialList]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
		setAutomaticSort(false);
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	useEffect(() => {
		if (automaticSort === true) {
			handleSort(sortColumn);
			setAutomaticSort(false);
		}
	}, [automaticSort]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/plantDate/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return d[clickedColumn];
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn === "trialOwnerLastName") {
				setDataSource(
					_.sortBy(dataSource, [
						function (ds) {
							return ds.trialOwnerFirstName
								? `${ds.trialOwnerFirstName?.toLowerCase().trim()} ${ds.trialOwnerLastName?.toLowerCase().trim()}`
								: ds.trialOwnerFirstName;
						}
					])
				);
			} else if (clickedColumn.match(/num/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase().trim() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			return;
		}
		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
	};

	// const getAnnotationSummaryData = async (trialId, trialName) => {
	// 	setOpenAnnotationSummaryModal(true);
	// 	setTrialNameForModal(trialName);
	// 	const accessToken = await getTokenSilently();

	// 	dispatch(trialActions.getAnnotationSummaryForTrial(trialId, userAuth.currentClientId, accessToken))
	// 		.then((res) => {
	// 			setAnnotationSummaryData(res);
	// 			setAnnotationSummaryDataLoader(false);
	// 			console.log(res);
	// 			console.log(annotationSummaryData);
	// 		})
	// 		.catch((err) => {
	// 			toast.error("Error loading annotation summary data. Please try again.");
	// 			console.error(err);
	// 			setAnnotationSummaryDataLoader(false);
	// 		});
	// };

	// prettier-ignore
	return (
		<>
			<Segment basic style={{ overflowY: 'visible', padding: 0 }}>
				<Table celled striped sortable selectable textAlign="center">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={sortColumn === "trialName" ? sortDirection : null}
								onClick={() => handleSort("trialName")}
							>
								Trial
							</Table.HeaderCell>
							{parentIsDashboard ? (
								<Table.HeaderCell>Protocol Names</Table.HeaderCell>
							) : (
								<Table.HeaderCell
									sorted={sortColumn == "clientName" ? sortDirection : null}
									onClick={() => handleSort("clientName")}
								>
									Client
								</Table.HeaderCell>
							)}
							<Table.HeaderCell
								sorted={sortColumn == "cooperatorName" ? sortDirection : null}
								onClick={() => handleSort("cooperatorName")}
							>
								Cooperator
							</Table.HeaderCell>
							<Table.HeaderCell sorted={sortColumn == "city" ? sortDirection : null} onClick={() => handleSort("city")}>
								City
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn == "stateName" ? sortDirection : null}
								onClick={() => handleSort("stateName")}
							>
								State
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "trialOwnerLastName" ? sortDirection : null}
								onClick={() => handleSort("trialOwnerLastName")}
							>
								Owner
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "companyName" ? sortDirection : null}
								onClick={() => handleSort("companyName")}
							>
								Company
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "growingSeasonName" ? sortDirection : null}
								onClick={() => handleSort("growingSeasonName")}
							>
								Growing Season
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "farmName" ? sortDirection : null}
								onClick={() => handleSort("farmName")}
							>
								Farm
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "fieldName" ? sortDirection : null}
								onClick={() => handleSort("fieldName")}
							>
								Field
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "cropName" ? sortDirection : null}
								onClick={() => handleSort("cropName")}
							>
								Crop
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "plantDate" ? sortDirection : null}
								onClick={() => handleSort("plantDate")}
							>
								Plant Date
							</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell>Actions</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(
							tableData,
							(
								{
									trialId,
									trialName,
									protocolNames,
									clientName,
									cooperatorName,
									stateName,
									city,
									clientId,
									trialOwnerUserId,
									trialOwnerFirstName,
									trialOwnerLastName,
									companyName,
									farmAbbr,
									fieldId,
									fieldName,
									fieldClientId,
									cropName,
									growingSeasonName,
									plantDate
								},
								index
							) => {
								return (
									<Table.Row key={index}>
										<Table.Cell>
											<Link
												to={moduleNavigation.createTrialLink(
													false,
													trialId,
													"viewer",
													userAuth.hasApplicationArea(ApplicationArea.TrialViewer, clientId) ? fieldId : null
												)}
												target="_blank"
											>
												{trialName}
											</Link>
										</Table.Cell>
										<Table.Cell>
											{parentIsDashboard
												? _.map(protocolNames, (p, index) => {
														return <div key={index}>{p}</div>;
												  })
												: clientName}
										</Table.Cell>
										<Table.Cell>{cooperatorName}</Table.Cell>
										<Table.Cell>{city}</Table.Cell>
										<Table.Cell>{stateName}</Table.Cell>
										<Table.Cell>{!trialOwnerUserId ? null : `${trialOwnerFirstName} ${trialOwnerLastName}`}</Table.Cell>
										<Table.Cell>{companyName}</Table.Cell>
										<Table.Cell>{growingSeasonName}</Table.Cell>
										<Table.Cell>{farmAbbr}</Table.Cell>
										<Table.Cell>
											{userAuth.hasApplicationArea(ApplicationArea.FieldViewer, fieldClientId) ? (
												<Link to={moduleNavigation.createFieldLink(false, fieldId)} target="_blank">
													{fieldName}
												</Link>
											) : (
												<>{fieldName}</>
											)}
										</Table.Cell>
										<Table.Cell>{cropName}</Table.Cell>
										<Table.Cell>{!plantDate ? null : moment(plantDate).local().format(DATE_FORMAT)}</Table.Cell>
										<Table.Cell>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={"Ground Data: " + _.find(trialStatuses, { trialId: trialId })?.groundDataStatus}
												transitionName="rc-tooltip-zoom"
											>
												<Icon
													name="clipboard"
													color={
														_.find(trialStatuses, { trialId: trialId })?.groundDataStatus === "Not Created"
															? "grey"
															: _.find(trialStatuses, { trialId: trialId })?.groundDataStatus === "Planned"
															? "yellow"
															: "green"
													}
												/>
											</Tooltip>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={
													"Growth Curves: " +
													(_.find(trialStatuses, { trialId: trialId })?.growthStatus ? "Ran" : "Not Run")
												}
												transitionName="rc-tooltip-zoom"
											>
												<Icon
													name="line graph increase"
													color={_.find(trialStatuses, { trialId: trialId })?.growthStatus ? "green" : "grey"}
												/>
											</Tooltip>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={
													"Senescence Curves: " +
													(_.find(trialStatuses, { trialId: trialId })?.senescenceStatus ? "Ran" : "Not Run")
												}
												transitionName="rc-tooltip-zoom"
											>
												<Icon
													name="line graph decrease"
													color={_.find(trialStatuses, { trialId: trialId })?.senescenceStatus ? "green" : "grey"}
												/>
											</Tooltip>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={
													"Full Season Curves: " +
													(_.find(trialStatuses, { trialId: trialId })?.fullSeasonStatus ? "Ran" : "Not Run")
												}
												transitionName="rc-tooltip-zoom"
											>
												<Icon
													name="area graph"
													color={_.find(trialStatuses, { trialId: trialId })?.fullSeasonStatus ? "green" : "grey"}
												/>
											</Tooltip>
											{_.find(trialStatuses, { trialId: trialId })?.hasExclusions ||
											_.find(trialStatuses, { trialId: trialId })?.hasPartialExclusions ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={`Trial has ${
														_.find(trialStatuses, { trialId: trialId })?.hasExclusions ? "whole plot" : "partial"
													} exclusions`}
													transitionName="rc-tooltip-zoom"
												>
													<Icon
														style={{ color: "#d03f2c" }}
														className={`warning${
															_.find(trialStatuses, { trialId: trialId })?.hasExclusions ? " solid" : ""
														}`}
														onClick={() => {
															toggleMetaFactorModal(trialId, trialName, true);
														}}
													/>
													{/* <img 
														src={_.find(trialStatuses, { trialId: trialId })?.hasExclusions ? damagedIcon : partialExcludedIcon} 
														color={"#d03f2c"} 
														width={_.find(trialStatuses, { trialId: trialId })?.hasExclusions ? "18px" : "19px"} 
														style={{ marginLeft: "-2px", cursor: "pointer" }}
														onClick={() => { 
															toggleMetaFactorModal(trialId, trialName, true); 
														}}
													/> */}
												</Tooltip>
											) : null}
											{_.find(trialStatuses, { trialId: trialId })?.hasFlaggings ||
											_.find(trialStatuses, { trialId: trialId })?.hasPartialFlaggings ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={`Trial has ${
														_.find(trialStatuses, { trialId: trialId })?.hasFlaggings ? "whole plot" : "partial"
													} flaggings`}
													transitionName="rc-tooltip-zoom"
												>
													<Icon
														style={{ color: "#ffa500" }}
														className={`flag${
															_.find(trialStatuses, { trialId: trialId })?.hasFlaggings ? " solid" : ""
														}`}
														onClick={() => {
															toggleMetaFactorModal(trialId, trialName, true);
														}}
													/>
													{/* <img 
														src={_.find(trialStatuses, { trialId: trialId })?.hasFlaggings ? flaggedIcon : partialFlagIcon} 
														color={"#ffa500"}
														width={_.find(trialStatuses, { trialId: trialId })?.hasFlaggings ? "18px" : "19px"} 
														style={{ marginRight: "2px", cursor: "pointer" }} 
														onClick={() => { 
															toggleMetaFactorModal(trialId, trialName, true);
														}}
													/> */}
												</Tooltip>
											) : null}
										</Table.Cell>
										<Table.Cell textAlign="center">
											{/* {userAuth.hasApplicationArea(ApplicationArea.TrialEdit, clientId) && (
												<>
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={<p>Edit</p>}
														transitionName="rc-tooltip-zoom"
													>
														<Link
															to={moduleNavigation.createTrialLink(
																false,
																trialId,
																"edit",
																userAuth.hasApplicationArea(ApplicationArea.TrialEdit, clientId) ? fieldId : null
															)}
															target="_blank"
														>
															<Icon name="pencil" />
														</Link>
													</Tooltip>
													<span style={{ display: "inline-block", width: 7 }} />
												</>
											)} */}
											{/* {userAuth.hasApplicationArea(ApplicationArea.TrialHeatMap, clientId) && (
												<>
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={<p>Heatmap</p>}
														transitionName="rc-tooltip-zoom"
													>
														<Link
															to={moduleNavigation.createTrialLink(
																false,
																trialId,
																"heatmap",
																userAuth.hasApplicationArea(ApplicationArea.TrialHeatMap, clientId) ? fieldId : null
															)}
															target="_blank"
														>
															<Icon name="map" />
														</Link>
													</Tooltip>
													<span style={{ display: "inline-block", width: 5 }} />
												</>
											)} */}

											{userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisTimecourse, clientId) && (
												<>
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={<p>Analysis</p>}
														transitionName="rc-tooltip-zoom"
													>
														<Link
															to={moduleNavigation.createTrialLink(
																false,
																trialId,
																"analysis",
																userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisTimecourse, clientId)
																	? fieldId
																	: null
															)}
															target="_blank"
														>
															<Icon name="chart bar outline" />
														</Link>
													</Tooltip>
													<span style={{ display: "inline-block", width: 5 }} />
												</>
											)}
											{userAuth.hasApplicationArea(ApplicationArea.TrialEdit, clientId) && (
												<>
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={`Meta-Tags & Annotations`}
														transitionName="rc-tooltip-zoom"
													>
														<a
															style={{ cursor: "pointer" }}
															onClick={() => {
																toggleMetaFactorModal(trialId, trialName, true);
															}}
														>
															<Icon name="tags" />
														</a>
													</Tooltip>
													<span style={{ display: "inline-block", width: 5 }} />
												</>
											)}
											{/* {userAuth.isApAdmin ? (
												<>
													<PubUnpubTrialFlightsFromSearch trialId={trialId} />
													<span style={{ display: "inline-block", width: 5 }} />
												</>
												) : null} 
												<span style={{ display: "inline-block", width: 5 }} /> */}

											<ActionsMenu
												actions={[
													{
														hasArea: userAuth.hasApplicationArea(ApplicationArea.TrialEdit, clientId),
														icon: "pencil",
														title: "Edit",
														to: moduleNavigation.createTrialLink(
															false,
															trialId,
															"edit",
															userAuth.hasApplicationArea(ApplicationArea.TrialEdit, clientId) ? fieldId : null
														)
													},
													{
														hasArea: userAuth.hasApplicationArea(ApplicationArea.TrialHeatMap, clientId),
														icon: "map",
														title: "Heatmap",
														to: moduleNavigation.createTrialLink(
															false,
															trialId,
															"heatmap",
															userAuth.hasApplicationArea(ApplicationArea.TrialHeatMap, clientId) ? fieldId : null
														)
													},
													// {
													// 	hasArea: userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisTimecourse, clientId),
													// 	icon: "chart bar outline",
													// 	title: "Analysis",
													// 	to: moduleNavigation.createTrialLink(
													// 		false,
													// 		trialId,
													// 		"analysis",
													// 		userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisTimecourse, clientId)
													// 			? fieldId
													// 			: null
													// 	)
													// },
													// {
													// 	hasArea: userAuth.hasApplicationArea(ApplicationArea.TrialEdit, clientId),
													// 	component:
													// 		<a
													// 			className="item"
													// 			style={{ cursor: "pointer" }}
													// 			onClick={() => {
													// 				toggleMetaFactorModal(trialId, trialName, true);
													// 			}}
													// 		>
													// 			<Icon name="tags" />
													// 			<div className="content">Annotations</div>
													// 		</a>
													// },
													{
														hasArea: true,
														component: (
															<TrialFavoritingWidget
																key={trialId}
																clientId={clientId}
																trialId={trialId}
																userSettings={userSettings}
																displayMode="menu"
															/>
														)
													},
													{
														hasArea: userAuth.isApAdmin,
														component: (
															<List.Item
																as={"a"}
																onClick={() => {
																	setModalTrialId(trialId);
																	setModalOpen(true);
																}}
															>
																<Icon name="book" />
																<List.Content>Publishing</List.Content>
															</List.Item>
														)
													}
												]}
											/>
										</Table.Cell>
									</Table.Row>
								);
							}
						)}
					</Table.Body>

					<Table.Footer>
						<Table.Row textAlign="right">
							<Table.HeaderCell colSpan="14">
								<span style={{ marginRight: 10 }}>
									{dataSource.length === 1 ? `${dataSource.length} trial found` : `${dataSource.length} trials found`}
								</span>
								<Pagination
									onPageChange={(e, pageInfo) => {
										setActivePage(pageInfo.activePage);
									}}
									boundaryRange={5}
									siblingRange={1}
									totalPages={totalPages}
									activePage={activePage}
								/>
								<Dropdown
									onChange={(e, { value }) => {
										setItemsPerPage(value);
									}}
									selection
									options={[
										{ key: 1, text: 5, value: 5 },
										{ key: 2, text: 10, value: 10 },
										{ key: 3, text: 15, value: 15 },
										{ key: 4, text: 25, value: 25 },
										{ key: 5, text: 50, value: 50 }
									]}
									value={itemsPerPage}
									compact
									style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
								/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
			<PubUnpubTrialFlightsFromSearch
				trialId={modalTrialId}
				mode="menu"
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
			/>
		</>
	);
};

TrialSearchResults.propTypes = {
	trialList: PropTypes.array,
	parentIsDashboard: PropTypes.bool,
	trialStatuses: PropTypes.array,
	trialDEFStatuses: PropTypes.array,
	trialDEFStatusesLoader: PropTypes.array,
	toggleMetaFactorModal: PropTypes.func
};

export default TrialSearchResults;
