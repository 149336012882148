const regridBaseApi = process.env.regridBaseApi;
const regridToken = process.env.regridToken;
const regridLimit = 5;

export function getParcelsByPoint(lat, lon, radius) {
	return fetch(`${regridBaseApi}/parcels/point
	?lat=${lat}
	&lon=${lon}
	&radius=${radius}
	&limit=${regridLimit}
	&return_matched_buildings=false
	&token=${regridToken}`)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getParcelsByAddress(query, path) {
	return fetch(`${regridBaseApi}/parcels/address
	?query=${JSON.stringify(query)}
	&path=${path}
	&limit=${regridLimit}
	&token=${regridToken}`)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getParcelsByAPN() {}

export function getParcelsInArea(bbox) {
	const body = new FormData();

	body.append("token", regridToken);
	body.append("limit", regridLimit);
	body.append("geojson", JSON.stringify(bbox));
	body.append("return_matched_buildings", false);

	return fetch(`${regridBaseApi}/parcels/area`, {
		method: "POST",
		body: body
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getParcelsByTypeahead() {}

export function getParcelByRegridId() {}
