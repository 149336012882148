import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Loader, Segment, Grid, Modal, Button, List } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import NewEditUasForm from "./NewEditUasForm";

import * as uasActions from "../../../../redux/actions/uasActions";

const ConfigureUases = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	//-- Data Source
	const [uases, setUases] = useState(null);
	const [uasSensors, setUasSensors] = useState(null);
	const [uavs, setUavs] = useState(null);
	const [sensors, setSensors] = useState(null);
	const [orthoImageTypes, setOrthoImageTypes] = useState(null);
	const [analysesRequiredOrthos, setAnalysesRequiredOrthos] = useState(null);
	const [sensorsSupportedOrthos, setSensorsSupportedOrthos] = useState(null);

	//-- Selected Data
	const [selectedUas, setSelectedUas] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [showCreateUasModal, setShowCreateUasModal] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			getData(clientId);
		}
	}, [userAuth.isReady, clientId]);

	const getData = async (clientId) => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(uasActions.getConfigureUasesData(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setUases(res.data.uases);
					setUavs(res.data.uaVs);
					setSensors(res.data.sensors);
					setOrthoImageTypes(res.data.orthoImageTypes);
					setAnalysesRequiredOrthos(res.data.analysesRequiredOrthos);
					setSensorsSupportedOrthos(res.data.sensorsSupportedOrthos);
				} else {
					toast.error("Unable to load UAS data. Please try again.");
					setLoading(false);
				}
			})
			.catch(() => {
				toast.error("Unable to load UAS data. Please try again.");
				setLoading(false);
			});
	};

	//-- UASes
	useEffect(() => {
		if (uases && uases.length > 0) {
			if (!selectedUas) {
				uasSelected(uases[0].uasId);
			} else {
				uasSelected(selectedUas.uasId);
			}
		}
	}, [uases]);

	useEffect(() => {
		if (
			uases !== null &&
			uavs !== null &&
			sensors !== null &&
			orthoImageTypes !== null &&
			analysesRequiredOrthos !== null &&
			sensorsSupportedOrthos !== null
		) {
			setLoading(false);
		}
	}, [uases, uavs, sensors, orthoImageTypes, analysesRequiredOrthos, sensorsSupportedOrthos, selectedUas]);

	const uasSelected = (uasId, uasName) => {
		if (uasId || uasName) {
			const selUas = uases.find((u) => u.uasId === uasId || u.uasName === uasName);
			setSelectedUas(selUas);

			const uasSens = uases.reduce((sensorIdList, uas) => {
				if (uas.uasId === selUas.uasId) {
					return sensorIdList.concat(uas.sensorId);
				}
				return sensorIdList;
			}, []);
			setUasSensors(uasSens);
		}
	};

	const uasCreated = (uas) => {
		setShowCreateUasModal(false);
		getData(clientId);
		uasSelected(uas.uasName);
	};

	const uasUpdated = (uas) => {
		getData(clientId);
		uasSelected(uas.uasId);
	};

	const uasDeleted = () => {
		getData(clientId);
		setSelectedUas(null);
	};

	return (
		<Segment basic>
			{loading && (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			)}
			{!loading && (
				<Grid id="configure-uas-grid" columns="equal">
					<Grid.Row style={{ paddingBottom: "unset" }}>
						<Grid.Column width={4}>
							<label htmlFor="form-select-uas" className="fieldLabel">
								UASes
							</label>
							<Modal
								open={showCreateUasModal}
								trigger={
									<Button
										id="new-uas"
										color="blue"
										content="New UAS"
										style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
										onClick={() => setShowCreateUasModal(true)}
									/>
								}
							>
								<Modal.Header>New UAS</Modal.Header>
								<Modal.Content style={{ paddingBottom: "unset" }}>
									<NewEditUasForm
										orthoImageTypes={orthoImageTypes}
										sensorsSupportedOrthos={sensorsSupportedOrthos}
										analysesRequiredOrthos={analysesRequiredOrthos}
										clientId={clientId}
										uavs={uavs}
										sensors={sensors}
										originalUas={null}
										originalUasSensors={[]}
										onCreateUas={uasCreated}
										onUpdateUas={null}
										onCancel={() => setShowCreateUasModal(false)}
										onDeleteUas={null}
									/>
								</Modal.Content>
							</Modal>
							<List
								id="select-uas"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 500,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid var(--border)",
									backgroundColor: "rgba(255, 255, 255, 0.5)",
									borderRadius: "8px"
								}}
							>
								{_.map(uases, ({ uasId, uasName }) => {
									return (
										<List.Item
											key={uasId}
											style={{
												border: "2px solid transparent",
												backgroundClip: "padding-box"
											}}
											active={selectedUas?.uasId === uasId}
											onClick={() => {
												uasSelected(uasId);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{uasName}</span>
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
							<NewEditUasForm
								orthoImageTypes={orthoImageTypes}
								sensorsSupportedOrthos={sensorsSupportedOrthos}
								analysesRequiredOrthos={analysesRequiredOrthos}
								clientId={clientId}
								uavs={uavs}
								sensors={sensors}
								originalUas={selectedUas}
								originalUasSensors={uasSensors}
								onCreateUas={uasCreated}
								onUpdateUas={uasUpdated}
								onDeleteUas={uasDeleted}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Segment>
	);
};

export default ConfigureUases;
