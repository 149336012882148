// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboardForm.ui.segment {\n\tpadding: 0 1em;\n}\n\n.dashboardForm .field {\n\twidth: calc(50% - 0.25em);\n}\n\n.dashboardForm.ui.segment .field,\n.dashboardForm.ui.segment .fields .field {\n\tmargin-right: 0;\n}\n\n.dashboardForm.ui.segment .ui.button {\n\tmargin: 0;\n}\n\n.dashboardInput {\n\tmin-width: 125px;\n\tmax-width: 200px;\n}\n\n.dashboardInput > .ui.fluid.search.selection {\n\twhite-space: nowrap;\n\twidth: calc(100% + 3px);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Dashboard/style.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;;CAEC,eAAe;AAChB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;CACnB,uBAAuB;AACxB","sourcesContent":[".dashboardForm.ui.segment {\n\tpadding: 0 1em;\n}\n\n.dashboardForm .field {\n\twidth: calc(50% - 0.25em);\n}\n\n.dashboardForm.ui.segment .field,\n.dashboardForm.ui.segment .fields .field {\n\tmargin-right: 0;\n}\n\n.dashboardForm.ui.segment .ui.button {\n\tmargin: 0;\n}\n\n.dashboardInput {\n\tmin-width: 125px;\n\tmax-width: 200px;\n}\n\n.dashboardInput > .ui.fluid.search.selection {\n\twhite-space: nowrap;\n\twidth: calc(100% + 3px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardForm": "dashboardForm",
	"ui": "ui",
	"segment": "segment",
	"field": "field",
	"fields": "fields",
	"button": "button",
	"dashboardInput": "dashboardInput",
	"fluid": "fluid",
	"search": "search",
	"selection": "selection"
};
export default ___CSS_LOADER_EXPORT___;
