import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { Icon, Loader, Segment, Step } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as trialActions from "../../../../redux/actions/trialActions";
import EditTrial from "../EditTrial";
// import TrialApplications from "../TrialApplications";
// import TrialMaintenanceRecords from "../TrialMaintenanceRecords";
import TrialMapping from "../TrialMapping/index.tsx";
// import TrialSoilFertility from "../TrialSoilFertility";
import TrialTreatments from "../TrialTreatments/index.tsx";
import TrialOutline from "../TrialOutline";
import ConfrimNavModal from "../../../Lumber/ConfirmNavModal";
import TrialMetaTags from "../TrialMetaTags/index.tsx";

const TrialWizard = ({ activeStep }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();

	const [step, forceActiveStep] = useState(activeStep);
	const [loading, setLoading] = useState(true);

	const [originalTrial, setOriginalTrial] = useState(null);
	const [workingTrial, setWorkingTrial] = useState({});
	const [trialName, setTrialName] = useState(null);
	const [trialOwnerEmailAddress, setTrialOwnerEmailAddress] = useState({});
	const [trialSponsorEmailAddress, setTrialSponsorEmailAddress] = useState({});

	const [saveAction, setSaveAction] = useState();
	const [openConfirmNavModal, setOpenConfirmNavModal] = useState(false);
	const [target, setTarget] = useState("");
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		if (!isDirty && target !== "") {
			history.push(target);
		}
	}, [isDirty, target]);

	// this should only really have to run once, yet its running on each tab, investigate
	// happening because swapping tabs doesn't keep us in the same component
	// likey need to use redux if it gets too slow
	useEffect(() => {
		const func = async () => {
			setLoading(true);
			if (userAuth.currentClientId && moduleNavigation.trialId) {
				await getTrialData(userAuth.currentClientId);
				setLoading(false);
			}
		};
		func();
	}, [userAuth.currentClientId]);

	const getTrialData = async (clientId) => {
		try {
			const accessToken = await getTokenSilently();
			const res = await dispatch(trialActions.getTrialData(clientId, moduleNavigation.trialId, accessToken));

			setTrialData(res);
		} catch (err) {
			console.log("Error loading trial data");
			console.log(err);
		}
	};

	const setTrialData = (trialData) => {
		const ot = {
			clientId: trialData.trialInfo.clientId,
			id: trialData.trialInfo.trialId,
			name: trialData.trialInfo.trialName,
			company: trialData.trialInfo.companyName,
			trialOwnerPersonId: trialData.trialInfo.trialOwnerPersonId,
			trialOwner: trialData.trialInfo.trialOwnerPersonId
				? `${trialData.trialInfo.trialOwnerFirstName} ${trialData.trialInfo.trialOwnerLastName}`
				: "Not assigned",
			trialSponsor: trialData.trialInfo.trialSponsorPersonId
				? `${trialData.trialInfo.trialSponsorFirstName} ${trialData.trialInfo.trialSponsorLastName}`
				: "Not assigned",
			fieldResearcher: trialData.trialInfo.fieldResearcher,
			fieldResearcherId: trialData.trialInfo.fieldResearcherId,
			location: trialData.trialInfo.farmName,
			gpsCoordinates:
				trialData.trialInfo.plot101Lat && trialData.trialInfo.plot101Long
					? `${trialData.trialInfo.plot101Lat}, ${trialData.trialInfo.plot101Long}`
					: ``,
			plantDate: trialData.trialInfo.plantDate ? new Date(trialData.trialInfo.plantDate) : null,
			harvestDate: trialData.trialInfo.harvestDate ? new Date(trialData.trialInfo.harvestDate) : null,
			treatments: trialData.treatments,
			repCount: trialData.trialInfo.repCount,
			notes: trialData.trialInfo.notes ? trialData.trialInfo.notes : "",
			trialOwnerClientId: trialData.trialInfo.trialOwnerClientId,
			trialOwnerCompanyId: trialData.trialInfo.trialOwnerCompanyId,
			trialOwnerUserId: trialData.trialInfo.trialOwnerUserId,
			trialSponsorClientId: trialData.trialInfo.trialSponsorClientId,
			trialSponsorCompanyId: trialData.trialInfo.trialSponsorCompanyId,
			trialSponsorUserId: trialData.trialInfo.trialSponsorUserId,
			publishedToTrialOwner: trialData.trialInfo.publishedToTrialOwner,
			publishedToTrialSponsor: trialData.trialInfo.publishedToTrialSponsor,
			trialOwnerIsPending: trialData.trialInfo.trialOwnerIsPending,
			trialSponsorIsPending: trialData.trialInfo.trialSponsorIsPending,
			trialOwnerClientName: trialData.trialInfo.trialOwnerClientName,
			trialSponsorClientName: trialData.trialInfo.trialSponsorClientName,
			meanComparisonId: trialData.trialInfo.meanComparisonId,
			alphaId: trialData.trialInfo.alphaId,
			isEstimatedPlantDate: trialData.trialInfo.isEstimatedPlantDate,
			cooperatorName: trialData.trialInfo.cooperatorName,
			city: trialData.trialInfo.farmCity,
			state: trialData.trialInfo.farmState,
			flightScheduleId: trialData.trialInfo.flightScheduleId
		};

		setTrialOwnerEmailAddress(trialData.trialInfo.trialOwnerEmailAddress);
		setTrialSponsorEmailAddress(trialData.trialInfo.trialSponsorEmailAddress);

		setOriginalTrial(ot);
		setTrialName(ot.name);
		setWorkingTrial(ot);
	};

	const setActiveStep = (step) => {
		if (!isDirty) {
			forceActiveStep(step);
		}
	};

	// Navigation Functions

	const handleNavigateOut = async (action) => {
		switch (action) {
			case "saveCont":
				if (await saveAction()) {
					setIsDirty(false);
				} else {
					setTarget("");
				}
				setOpenConfirmNavModal(false);
				break;
			case "save":
				if (await saveAction()) {
					setIsDirty(false);
				}
				setTarget("");
				setOpenConfirmNavModal(false);
				break;
			case "discard":
				setIsDirty(false);
				setOpenConfirmNavModal(false);
				break;
			default:
				setTarget("");
				setOpenConfirmNavModal(false);
		}
	};

	const navigateToStep = (step) => {
		setActiveStep(step);
		history.push(moduleNavigation.createTrialLink(true, null, step));
	};

	return (
		<>
			<Segment basic>
				<Step.Group widths={6}>
					<Step link active={step === "edit"} onClick={() => navigateToStep("edit")}>
						<Icon name="folder open" />
						<Step.Content>
							<Step.Title>Trial Info</Step.Title>
							<Step.Description>Create new trials or link existing trials</Step.Description>
						</Step.Content>
					</Step>
					{/* <Step link active={step === "applications"} onClick={() => navigateToApplications()}>
					<Icon name="calendar check outline" />
					<Step.Content>
						<Step.Title>Applications</Step.Title>
						<Step.Description>Capture trail-specific field applications</Step.Description>
					</Step.Content>
				</Step> */}
					<Step link active={step === "treatments"} onClick={() => navigateToStep("treatments")}>
						<Icon name="list ol" />
						<Step.Content>
							<Step.Title>Treatments</Step.Title>
							<Step.Description>Define specific treatment information</Step.Description>
						</Step.Content>
					</Step>
					<Step link active={step === "meta-tags"} onClick={() => navigateToStep("meta-tags")}>
						<Icon name="tags" />
						<Step.Content>
							<Step.Title>Meta-Tags</Step.Title>
							<Step.Description>Annotate trial based on meta factors</Step.Description>
						</Step.Content>
					</Step>
					<Step link active={step === "outline"} onClick={() => navigateToStep("outline")}>
						<Icon name="table" />
						<Step.Content>
							<Step.Title>Outline</Step.Title>
							<Step.Description>Table listing of all plots with layout info</Step.Description>
						</Step.Content>
					</Step>
					<Step link active={step === "mapping"} onClick={() => navigateToStep("mapping")}>
						<Icon className="square dots" />
						<Step.Content>
							<Step.Title>Mapping</Step.Title>
							<Step.Description>Visualize field layout information</Step.Description>
						</Step.Content>
					</Step>
					{/* <Step link active={step === "soil-fertility"} onClick={() => navigateToSoilFertility()}>
					<Icon name="lab" />
					<Step.Content>
						<Step.Title>Soil Fertility</Step.Title>
						<Step.Description>Record soil test data</Step.Description>
					</Step.Content>
				</Step> */}
					{/* <Step disabled link active={step === "maintenance"} onClick={() => navigateToMaintenance()}>
					<Icon name="wrench" />
					<Step.Content>
						<Step.Title>Maintenance Records</Step.Title>
						<Step.Description>Update information on field management</Step.Description>
					</Step.Content>
				</Step> */}
				</Step.Group>

				<Segment id="trial-process-step-content" basic style={{ marginTop: "unset", padding: "unset", minHeight: 70 }}>
					{loading ? (
						<Loader active />
					) : (
						(() => {
							switch (step) {
								default:
								case "edit":
									return (
										<EditTrial
											navigateToNextStep={() => {
												navigateToStep("treatments");
											}}
											initOriginalTrial={originalTrial}
											initWorkingTrial={workingTrial}
											initTrialOwnerEmailAddress={trialOwnerEmailAddress}
											initTrialSponsorEmailAddress={trialSponsorEmailAddress}
											setSaveAction={setSaveAction}
											setIsDirty={setIsDirty}
										/>
									);
								// case "applications":
								// 	return (
								// 		<TrialApplications
								// 			trialName={trialName}
								// 			trialId={originalTrial?.id}
								// 			cropId={originalTrial?.cropId}
								// 			navigateToNextStep={() => {
								// 				navigateToTreatments();
								// 			}}
								// 			navigateToPreviousStep={() => {
								// 				navigateToInfo();
								// 			}}
								// 		/>
								// 	);
								case "treatments":
									return (
										<TrialTreatments
											trialId={originalTrial?.id}
											trialName={trialName}
											trialDetails={
												originalTrial
													? `${originalTrial.cooperatorName}, ${originalTrial.city}, ${originalTrial.state}`
													: "loading..."
											}
											initTreatments={originalTrial?.treatments}
											navigateToNextStep={() => {
												navigateToStep("meta-tags");
											}}
											navigateToPreviousStep={() => {
												navigateToStep("edit");
											}}
											setIsDirty={setIsDirty}
											setSaveAction={setSaveAction}
										/>
									);
								case "meta-tags":
									return (
										<TrialMetaTags
											trialId={originalTrial?.id}
											trialName={trialName}
											trialDetails={
												originalTrial
													? `${originalTrial.cooperatorName}, ${originalTrial.city}, ${originalTrial.state}`
													: "loading..."
											}
											navigateToNextStep={() => {
												navigateToStep("outline");
											}}
											navigateToPreviousStep={() => {
												navigateToStep("treatments");
											}}
											setIsDirty={setIsDirty}
											setSaveAction={setSaveAction}
										/>
									);
								case "outline":
									return (
										<TrialOutline
											navigateToNextStep={() => {
												navigateToStep("mapping");
											}}
											navigateToPreviousStep={() => {
												navigateToStep("meta-tags");
											}}
										/>
									);
								case "mapping":
									return (
										<TrialMapping
											trialId={originalTrial?.id}
											trialName={trialName}
											trialDetails={
												originalTrial
													? `${originalTrial.cooperatorName}, ${originalTrial.city}, ${originalTrial.state}`
													: "loading..."
											}
											treatments={originalTrial?.treatments}
											// navigateToNextStep={() => {
											// 	navigateToTreatments();
											// }}
											navigateToPreviousStep={() => {
												navigateToStep("outline");
											}}
										/>
									);
								// case "soil-fertility":
								// 	return (
								// 		<TrialSoilFertility
								// 			trialId={originalTrial?.id}
								// 			trialName={trialName}
								// 			navigateToNextStep={() => {
								// 				navigateToMaintenance();
								// 			}}
								// 			navigateToPreviousStep={() => {
								// 				navigateToMapping();
								// 			}}
								// 		/>
								// 	);
								// case "maintenance":
								// 	return <TrialMaintenanceRecords />;
							}
						})()
					)}
				</Segment>
			</Segment>
			<Prompt
				when={isDirty}
				message={(location) => {
					setTarget(location.pathname);
					setOpenConfirmNavModal(true);
					return false;
				}}
			/>
			<ConfrimNavModal
				open={openConfirmNavModal}
				onCancel={() => {
					handleNavigateOut("cancel");
				}}
				onSave={() => {
					handleNavigateOut("saveCont");
				}}
				onDiscard={() => {
					handleNavigateOut("discard");
				}}
			/>
		</>
	);
};

TrialWizard.propTypes = {
	activeStep: PropTypes.string.isRequired
};

export default TrialWizard;
