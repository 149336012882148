import Tooltip from "rc-tooltip";
import React, { FC, useState } from "react";
import { Icon, Menu } from "semantic-ui-react";
import HeatmapExportModal from "./index";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { ApplicationArea } from "../../Lumber/ApplicationAreas";

type Props = {
	id: string;
	type: "Trial" | "Field" | "Protocol";
	isApAdmin: boolean;
};

const HeatmapExportModalMenuIcon: FC<Props> = ({ id, type, isApAdmin }) => {
	const [open, setOpen] = useState(false);
	const userAuth = useUserAuth();

	return (
		<>
			<Tooltip
				placement="right"
				align={{ offset: [-8, 0] }}
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				trigger="hover"
				overlay="Data Export Modal"
				transitionName="rc-tooltip-zoom"
			>
				<Menu.Item
					key={"dataExportModal"}
					className={!id ? "disabledNavItem" : undefined}
					disabled={!id || (type == "Field" && !userAuth.hasApplicationArea(ApplicationArea.FieldExportFunctionality, userAuth.currentClientId)) || (type == "Trial" && !userAuth.hasApplicationArea(ApplicationArea.TrialExportFunctionality, userAuth.currentClientId))}
					active={open}
					onClick={() => {
						setOpen((prev) => !prev);
					}}
				>
					<Icon className="download" size="large" style={{ margin: "0px" }} />
				</Menu.Item>
			</Tooltip>
			<HeatmapExportModal id={id} open={open} setOpen={setOpen} type={type} isApAdmin={isApAdmin} />
		</>
	);
};

export default HeatmapExportModalMenuIcon;
