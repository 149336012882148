const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;
const analysisApiUri = process.env.aerialPlotConnections.aerialAnalysisApi.baseUrl;
const statisticsApiUri = process.env.aerialPlotConnections.aerialStatisticsApi.baseUrl;

export function analyzeFlight(flightId, clientId, rerunAnalysis, accessToken) {
	return fetch(
		`${analysisApiUri}api/v1/Analysis?flightId=${flightId}&clientId=${clientId}&rerunAnalysis=${rerunAnalysis}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	).then((res) => res);
}

export function standCountFlight(flightId, clientId, rerunAnalysis, accessToken) {
	return fetch(
		`${analysisApiUri}api/v1/StandCount?flightId=${flightId}&clientId=${clientId}&rerunAnalysis=${rerunAnalysis}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	).then((res) => res);
}

export function deleteFlightAnalysis(flightId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Analysis?flightId=${flightId}&clientId=${clientId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	}).then((res) => res);
}

export function getAnalysisResultsForFlight(flightId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Analysis/GetPlotAnalysisResultsForFlight?flightId=${flightId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getAnalysisResultsForField(clientId, fieldId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Analysis/GetPlotAnalysisResultsForField?clientId=${clientId}&fieldId=${fieldId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function getAnalysisResultsForTrial(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeDamagedOrExcluded = true
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetPlotAnalysisResultsForTrial?
		clientId=${clientId}
		&trialId=${trialId}
		&groundDataAssessmentId=${groundDataAssessmentId}
		&groundDataGrowthPhaseId=${groundDataGrowthPhaseId}
		&curveModelAnalysisId=${curveModelAnalysisId}
		&analysisId=${analysisId}
		&onlyGetAnalysisData=${onlyGetAnalysisData}
		&analysisTypeId=${analysisTypeId}
		&onlyGetGrowthCurveData=${onlyGetGrowthCurveData}
		&removeDamagedOrExcluded=${removeDamagedOrExcluded}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getProtocolAnalysisResultsForTimecourse(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetProtocolPlotAnalysisResultsForTimecourse?clientId=${clientId}&trialId=${trialId}&groundDataAssessmentId=${groundDataAssessmentId}&groundDataGrowthPhaseId=${groundDataGrowthPhaseId}&curveModelAnalysisId=${curveModelAnalysisId}&analysisId=${analysisId}&onlyGetAnalysisData=${onlyGetAnalysisData}&analysisTypeId=${analysisTypeId}&onlyGetGrowthCurveData=${onlyGetGrowthCurveData}
			&removeOutliers=${removeOutliers}&removeDamagedOrExcluded=${removeDamagedOrExcluded}&outlierType=${outlierType}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getProtocolAnalysisResultsForBoxWhisker(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetProtocolPlotAnalysisResultsForBoxWhisker?clientId=${clientId}&trialId=${trialId}&groundDataAssessmentId=${groundDataAssessmentId}&groundDataGrowthPhaseId=${groundDataGrowthPhaseId}&curveModelAnalysisId=${curveModelAnalysisId}&analysisId=${analysisId}&onlyGetAnalysisData=${onlyGetAnalysisData}&analysisTypeId=${analysisTypeId}&onlyGetGrowthCurveData=${onlyGetGrowthCurveData}
			&removeOutliers=${removeOutliers}&removeDamagedOrExcluded=${removeDamagedOrExcluded}&outlierType=${outlierType}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getProtocolAnalysisResultsForPianoCharts(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetProtocolPlotAnalysisResultsForPianoCharts?clientId=${clientId}&trialId=${trialId}&groundDataAssessmentId=${groundDataAssessmentId}&groundDataGrowthPhaseId=${groundDataGrowthPhaseId}&curveModelAnalysisId=${curveModelAnalysisId}&analysisId=${analysisId}&onlyGetAnalysisData=${onlyGetAnalysisData}&analysisTypeId=${analysisTypeId}&onlyGetGrowthCurveData=${onlyGetGrowthCurveData}
			&removeOutliers=${removeOutliers}&removeDamagedOrExcluded=${removeDamagedOrExcluded}&outlierType=${outlierType}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getAnalysisResultsForTrialWithMeanComparison(
	clientId,
	trialId,
	analysisId,
	flightId,
	quantifiedRegionTypeId,
	analysisTypeId,
	isPlotUniformity,
	groundDataAssessmentId,
	groundDatasetId,
	curveModelAnalysisId,
	meanComparisonId,
	alphaId,
	accessToken,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetPlotAnalysisResultsForBoxWhisker?clientId=${clientId}&trialId=${trialId}&meanComparisonTestId=${meanComparisonId}&alphaId=${alphaId}&isPlotUniformity=${isPlotUniformity}
			&removeDamagedOrExcluded=${removeDamagedOrExcluded}&removeOutliers=${removeOutliers}&outlierType=${outlierType}
		 ${analysisId ? `&analysisId=${analysisId}` : ""}
		 ${flightId ? `&flightId=${flightId}` : ""}
		 ${quantifiedRegionTypeId ? `&quantifiedRegionTypeId=${quantifiedRegionTypeId}` : ""}
		 ${analysisTypeId ? `&analysisTypeId=${analysisTypeId}` : ""}
		 ${groundDataAssessmentId ? `&groundDataAssessmentId=${groundDataAssessmentId}` : ""}
		 ${groundDatasetId ? `&groundDatasetId=${groundDatasetId}` : ""}
		 ${curveModelAnalysisId ? `&curveModelAnalysisId=${curveModelAnalysisId}` : ""}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getAnalysisResultsForSummaryTable(
	clientId,
	trialId,
	analysisId,
	quantifiedRegionTypeId,
	analysisTypeId,
	groundDataAssessmentId,
	growthCurveAnalysisId,
	selectedMeanComparisonId,
	alphaValueId,
	accessToken,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	const uri = analysisId
		? growthCurveAnalysisId
			? `${statisticsApiUri}api/v1/Plot/GetPlotResultsForSummaryTable?clientId=${clientId}&trialId=${trialId}&analysisId=${analysisId}&growthCurveAnalysisId=${growthCurveAnalysisId}&meanComparisonTestId=${selectedMeanComparisonId}&alphaId=${alphaValueId}&analysisTypeId=${analysisTypeId}&removeDamagedOrExcluded=${removeDamagedOrExcluded}&removeOutliers=${removeOutliers}&outlierType=${outlierType}`
			: `${statisticsApiUri}api/v1/Plot/GetPlotResultsForSummaryTable?clientId=${clientId}&trialId=${trialId}&analysisId=${analysisId}&quantifiedRegionTypeId=${quantifiedRegionTypeId}&meanComparisonTestId=${selectedMeanComparisonId}&alphaId=${alphaValueId}&removeDamagedOrExcluded=${removeDamagedOrExcluded}&removeOutliers=${removeOutliers}&outlierType=${outlierType}`
		: `${statisticsApiUri}api/v1/Plot/GetPlotResultsForSummaryTable?clientId=${clientId}&trialId=${trialId}&groundDataAssessmentId=${groundDataAssessmentId}&meanComparisonTestId=${selectedMeanComparisonId}&alphaId=${alphaValueId}&removeDamagedOrExcluded=${removeDamagedOrExcluded}&removeOutliers=${removeOutliers}&outlierType=${outlierType}`;
	return fetch(uri, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getAnalyses(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Analysis/GetAnalyses?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getAnalysesDetails(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Analysis/GetAnalysesDetails?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
export function getCorrelationPlotAnalysisResultsForTrial(
	clientId,
	trialId,
	analysisId,
	flightId,
	groundDataAssessmentId,
	groundDatasetId,
	curveModelAnalysisId,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetCorrelationPlotAnalysisResultsForTrial?clientId=${clientId}&trialId=${trialId}
		 ${analysisId ? `&analysisId=${analysisId}` : ""}
		 ${flightId ? `&flightId=${flightId}` : ""}
		 ${groundDataAssessmentId ? `&groundDataAssessmentId=${groundDataAssessmentId}` : ""}
		 ${groundDatasetId ? `&groundDatasetId=${groundDatasetId}` : ""}
		 ${curveModelAnalysisId ? `&curveModelAnalysisId=${curveModelAnalysisId}` : ""}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getCorrelationMatrixData(clientId, trialId, accessToken) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetTrialAnalysisCorrelationData?clientId=${clientId}&trialId=${trialId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function GetPlotAnalysisResultsForTrialForProtocolSummaryTable(
	clientId,
	trialId,
	accessToken,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType,
	standCountAnalysisId
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetPlotAnalysisResultsForTrialForProtocolSummaryTable?clientId=${clientId}&trialId=${trialId}&removeOutliers=${removeOutliers}
			&removeDamagedOrExcluded=${removeDamagedOrExcluded}&outlierType=${outlierType}&standCountAnalysisId=${standCountAnalysisId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getHeatmapAnalysisResultsForTrial(
	clientId,
	trialId,
	analysisId,
	analysisTypeId,
	analysisTypeName,
	assessmentId,
	curveModelAnalysisId,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetHeatmapAnalysisResultsForTrial?clientId=${clientId}&trialId=${trialId}
		 ${analysisId ? `&analysisId=${analysisId}` : ""}
		 ${analysisTypeId ? `&analysisTypeId=${analysisTypeId}&analysisTypeName=${analysisTypeName}` : ""}
		 ${assessmentId ? `&assessmentId=${assessmentId}` : ""}
		 ${curveModelAnalysisId ? `&curveModelAnalysisId=${curveModelAnalysisId}` : ""}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getHeatmapAnalysisResultsForField(
	clientId,
	fieldId,
	analysisId,
	analysisTypeName,
	assessmentId,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetHeatmapAnalysisResultsForField?clientId=${clientId}&fieldId=${fieldId}
		 ${analysisId ? `&analysisId=${analysisId}` : ""}
		 ${analysisTypeName ? `&analysisTypeName=${analysisTypeName}` : ""}
		 ${assessmentId ? `&assessmentId=${assessmentId}` : ""}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getHeatmapAnalysisResultsForFarm(
	clientId,
	farmId,
	analysisId,
	analysisTypeId,
	analysisTypeName,
	assessmentId,
	curveModelAnalysisId,
	flightYears,
	accessToken
) {
	let data = new FormData();

	for (let i = 0; i < flightYears.length; i++) {
		data.append(`FlightYears[${i}]`, flightYears[i]);
	}

	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetHeatmapAnalysisResultsForFarm?clientId=${clientId}&farmId=${farmId}${
			analysisId ? `&analysisId=${analysisId}` : ""
		}${analysisTypeId ? `&analysisTypeId=${analysisTypeId}&analysisTypeName=${analysisTypeName}` : ""}${
			assessmentId ? `&assessmentId=${assessmentId}` : ""
		}${curveModelAnalysisId ? `&curveModelAnalysisId=${curveModelAnalysisId}` : ""}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: data
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getFlightAnalysisResultsForTrial(clientId, trialId, accessToken) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/GetFlightAnalysisResultsForTrial?clientId=${clientId}&trialId=${trialId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function deleteAnalysisDataForAdmins(flightId, fieldId, trialId, protocolId, accessToken) {
	return fetch(
		`${statisticsApiUri}api/v1/Plot/DeleteAnalysisResultsForAdmin?
		${flightId ? `flightId=${flightId}` : ""}
		${fieldId ? `fieldId=${fieldId}` : ""}
		${trialId ? `trialid=${trialId}` : ""}
		${protocolId ? `protocolId=${protocolId}` : ""}`,
		{
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	).then((res) => res);
}
