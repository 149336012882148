import { bbox, bboxPolygon, center, featureCollection } from "@turf/turf";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Header, Icon, List, Modal } from "semantic-ui-react";
import * as uuid from "uuid";
import * as farmActions from "../../../../redux/actions/farmActions.ts";
import * as lookupActions from "../../../../redux/actions/lookupActions";
import * as mapboxSearchActions from "../../../../redux/actions/mapboxSearchActions.js";
import FarmListItem from "./FarmListItem";
import FileDropzone from "./FileDropzone";
import ParcelMapViewer from "./ParcelMapViewer";
import ParcelMetaDataModal from "./ParcelMetaDataModal.tsx";
import "./styles.css";
import { toast } from "react-toastify";

const FarmGeoPositioning = ({ clientId, userId, getTokenSilently, farmId, setSaveFunction, farmData, setIsDirty }) => {
	const dispatch = useDispatch();

	const [metaDataModalOpen, setMetaDataModalOpen] = useState(false);
	const [uploadingModalOpen, setUploadingModalOpen] = useState(false);
	const [stateOptions, setStateOptions] = useState([]);
	const [stateAbbreviationMap, setStateAbbreviationMap] = useState({});

	const [parcelList, setParcelListSilently] = useState(featureCollection([]));
	const [hoveredParcel, setHoveredParcel] = useState(null);
	const [selectedParcelList, setSelectedParcelList] = useState([]);
	const [focusedParcel, setFocusedParcel] = useState(null);
	const [fileUploadAction, setFileUploadAction] = useState("hide");
	const [layers, setLayers] = useState(["found", "selected"]);
	const focusedParcelFeature = useMemo(
		() => parcelList.features.find((f) => f.properties.ll_uuid === focusedParcel),
		[focusedParcel, parcelList]
	);

	// this is the zoom and coords to just display the USA
	const [initCoords, setInitCoords] = useState({
		latitude: 39.8097343,
		longitude: -98.5556199,
		zoom: 3
	});

	useEffect(() => {
		getStatesProvinces();
	}, []);

	useEffect(() => {
		if (farmId && farmData.farmId) {
			getFarmParcels();
		}
	}, [farmId, farmData]);

	useEffect(() => {
		setSaveFunction(async () => {
			await updateFarmParcelsAndBounds();
		});
	}, [parcelList, selectedParcelList]);

	const onItemClick = (ll_uuid) => {
		if (focusedParcel !== ll_uuid) {
			setFocusedParcel(ll_uuid);
		} else {
			setFocusedParcel(null);
		}
	};

	const onAddParcel = (ll_uuid) => {
		setFocusedParcel(ll_uuid);
		setMetaDataModalOpen(true);
	};

	const onSaveParcel = (ll_uuid, props) => {
		setParcelList((prev) => {
			const copy = _.cloneDeep(prev);
			const feature = copy.features.find((f) => f.properties.ll_uuid === ll_uuid);
			feature.properties = { ...feature.properties, ...props };
			return copy;
		});

		setSelectedParcelList((prev) => {
			if (prev.includes(ll_uuid)) return prev;
			return [...prev, ll_uuid];
		});
	};

	const onEditParcel = (ll_uuid) => {
		setFocusedParcel(ll_uuid);
		setMetaDataModalOpen(true);
	};

	const onDeleteParcel = useCallback((ll_uuid) => {
		setParcelList((prev) => {
			const copy = _.cloneDeep(prev);
			const features = prev.features.filter((f) => f.properties.ll_uuid !== ll_uuid);
			copy.features = features;
			return copy;
		});
	}, []);

	// const uploadKML = () => {
	// 	setUploadingModalOpen(true);
	// 	console.log("uploading");
	// };

	const onMouseEnterItem = (ll_uuid) => {
		setHoveredParcel(ll_uuid);
	};

	const onMouseLeaveItem = () => {
		setHoveredParcel(null);
	};

	const handleUploadedGeoJSON = (geo) => {
		const feature = geo.features[0];
		const featId = uuid.v4();
		const centerPt = center(feature);
		// feature.geometry.coordinates[0] = _.map(feature.geometry.coordinates[0], (c) => c.slice(0, 2));
		feature.properties.ll_uuid = featId;
		feature.properties.fields = {};
		feature.properties.fields.lat = centerPt.geometry.coordinates[1];
		feature.properties.fields.lon = centerPt.geometry.coordinates[0];

		setParcelList((prev) => {
			const copy = _.cloneDeep(prev);
			copy.features = [...copy.features, feature];
			return copy;
		});
		onAddParcel(featId);
	};

	const getStatesProvinces = async () => {
		const accessToken = await getTokenSilently();
		dispatch(lookupActions.getStates(clientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					setupStateOptions(res.data);
				} else {
					toast.error("Unable to load states. Please try again.");
				}
			})
			.catch(() => {
				toast.error("Unable to load states. Please try again.");
			});
	};

	const setupStateOptions = (sOptions) => {
		const stateOpts = sOptions.map(({ id, name }) => {
			const opt = { key: id, value: id, text: name };
			return opt;
		});
		const stateAbbvMap = sOptions.reduce((acc, curr) => {
			acc[curr.postalAbbreviation] = curr.id;
			return acc;
		}, {});
		setStateAbbreviationMap(stateAbbvMap);
		setStateOptions(stateOpts);
		// setStateDropdownLoading(false);
	};

	const setParcelList = useCallback((setStateAction) => {
		setIsDirty(true);
		setParcelListSilently(setStateAction);
	}, []);

	const getFarmParcels = useCallback(async () => {
		const accessToken = await getTokenSilently();
		const res = await dispatch(farmActions.getFarmParcels(accessToken, clientId, farmId));
		const parcelsToAdd = [];
		const lluuidsToSelect = [];
		for (let parcel of res.data) {
			const geoJSON = JSON.parse(parcel.geoJSON);
			Object.assign(geoJSON.properties.fields, {
				owner: parcel.ownerName,
				address: parcel.address1,
				address2: parcel.address2,
				city: parcel.city,
				stateId: parcel.stateId,
				szip5: parcel.zipCode,
				county: parcel.county,
				ll_gisacre: parcel.size
			});
			parcelsToAdd.push(geoJSON);
			lluuidsToSelect.push(geoJSON.properties.ll_uuid);
		}

		setParcelListSilently((prev) => featureCollection([...prev.features, ...parcelsToAdd]));
		setSelectedParcelList((prev) => [...prev, ...lluuidsToSelect]);

		setInitZoom(parcelsToAdd.length > 0 ? featureCollection(parcelsToAdd) : null);
	}, [farmId, clientId, getTokenSilently, farmData]);

	const updateFarmParcelsAndBounds = useCallback(async () => {
		const selectedCollection = featureCollection(
			_.filter(parcelList.features, (p) => selectedParcelList.includes(p.properties.ll_uuid))
		);
		const bounds = bbox(selectedCollection);
		const geometry = bboxPolygon(bounds).geometry;

		const LL = {
			long: geometry.coordinates[0][0][0],
			lat: geometry.coordinates[0][0][1]
		};
		const UL = {
			long: geometry.coordinates[0][1][0],
			lat: geometry.coordinates[0][1][1]
		};
		const UR = {
			long: geometry.coordinates[0][2][0],
			lat: geometry.coordinates[0][2][1]
		};
		const LR = {
			long: geometry.coordinates[0][3][0],
			lat: geometry.coordinates[0][3][1]
		};

		const accessToken = await getTokenSilently();
		try {
			await Promise.all([
				dispatch(farmActions.createFarmParcels(accessToken, clientId, farmId, selectedCollection)),
				dispatch(farmActions.updateFarmBounds(accessToken, clientId, farmId, LL, UL, UR, LR))
			]);

			setIsDirty(false);

			toast.success("Farm Parcels Updated Successfully");
		} catch (e) {
			console.error("error updating farm bounds!", e);
			toast.error("Farm Parcels Failed to Update");
		}
	}, [parcelList, selectedParcelList]);

	const setInitZoom = useCallback(
		(savedParcels) => {
			if (savedParcels) {
				const centerPt = center(savedParcels);
				setInitCoords({
					latitude: parseFloat(centerPt.geometry.coordinates[1]),
					longitude: parseFloat(centerPt.geometry.coordinates[0]),
					zoom: 15
				});
			} else if (farmData.lat && farmData.long) {
				setInitCoords({
					latitude: parseFloat(farmData.lat),
					longitude: parseFloat(farmData.long),
					zoom: 15
				});
			} else if (farmData.city && farmData.stateName) {
				const query = `${farmData.city}%20${farmData.stateName}`;
				dispatch(mapboxSearchActions.getForwardGeocode(query, { types: "place" })).then((res) => {
					const geocode = res.features[0];
					setInitCoords({
						latitude: geocode.geometry.coordinates[1],
						longitude: geocode.geometry.coordinates[0],
						zoom: 15
					});
				});
			}
		},
		[farmData]
	);

	const reset = () => {
		setParcelList(featureCollection([]));
		setHoveredParcel(null);
		setSelectedParcelList([]);
		setFocusedParcel(null);
	};

	return (
		<>
			<Grid columns="equal" stretched style={{ width: "100%", height: "100%" }}>
				<Grid.Column style={{ overflow: "hidden", borderRadius: 8, padding: 0, maxWidth: 340 }}>
					<Grid.Row style={{ height: "50%" }}>
						{/* Search Results */}
						<div className="listHeader">
							<Button negative style={{ position: "absolute", left: 10 }} onClick={reset} size="small" compact>
								Reset
							</Button>
							<Header style={{ margin: 0 }}>Found Parcels</Header>
							<Icon
								className={layers.includes("found") ? `eye-regular` : `eye-slash-regular`}
								size="large"
								style={{ position: "absolute", right: 10, cursor: "pointer" }}
								onClick={() => {
									if (layers.includes("found")) {
										setLayers((prev) => prev.filter((l) => l !== "found"));
									} else {
										setLayers((prev) => [...prev, "found"]);
									}
								}}
							/>
						</div>
						<div className="listWrapper">
							<List divided selection className="parcelList">
								{parcelList &&
									selectedParcelList &&
									_.reduce(
										parcelList.features,
										(acc, p, i) => {
											const ll_uuid = p.properties.ll_uuid;
											if (!selectedParcelList.includes(ll_uuid)) {
												return [
													...acc,
													<FarmListItem
														key={i}
														id={p.properties.ll_uuid}
														parcelName={p.properties.headline ?? `Parcel No. ${i + 1}`}
														subText={`${parseFloat(p.properties.fields.lat).toFixed(6)}, ${parseFloat(
															p.properties.fields.lon
														).toFixed(6)}`}
														context="search"
														onClickIcon={() => onAddParcel(ll_uuid)}
														onClickItem={() => onItemClick(ll_uuid)}
														onMouseEnter={() => onMouseEnterItem(ll_uuid)}
														onMouseLeave={onMouseLeaveItem}
														hoveredParcel={hoveredParcel}
														focusedParcel={focusedParcel}
													/>
												];
											}
											return acc;
										},
										[]
									)}
							</List>
						</div>
					</Grid.Row>
					<Grid.Row style={{ height: "50%" }}>
						{/* Selected Results */}
						<div className="listHeader">
							<Header style={{ margin: 0 }}>Selected Parcels</Header>
							<Icon
								className={layers.includes("selected") ? `eye-regular` : `eye-slash-regular`}
								size="large"
								style={{ position: "absolute", right: 10, cursor: "pointer" }}
								onClick={() => {
									if (layers.includes("selected")) {
										setLayers((prev) => prev.filter((l) => l !== "selected"));
									} else {
										setLayers((prev) => [...prev, "selected"]);
									}
								}}
							/>{" "}
						</div>
						<div className="listWrapper">
							<List divided selection className="parcelList">
								{parcelList &&
									selectedParcelList &&
									_.reduce(
										parcelList.features,
										(acc, p, i) => {
											const ll_uuid = p.properties.ll_uuid;
											if (selectedParcelList.includes(ll_uuid)) {
												return [
													...acc,
													<FarmListItem
														key={i}
														id={p.properties.ll_uuid}
														parcelName={p.properties.headline ?? `Parcel No. ${i + 1}`}
														subText={`${parseFloat(p.properties.fields.lat).toFixed(6)}, ${parseFloat(
															p.properties.fields.lon
														).toFixed(6)}`}
														context="selected"
														onClickIcon={() => onEditParcel(ll_uuid)}
														onClickItem={() => onItemClick(ll_uuid)}
														onMouseEnter={() => onMouseEnterItem(ll_uuid)}
														onMouseLeave={onMouseLeaveItem}
														hoveredParcel={hoveredParcel}
														focusedParcel={focusedParcel}
													/>
												];
											}
											return acc;
										},
										[]
									)}
							</List>
							<div className="listFooter" onClick={() => setFileUploadAction("browse")}>
								Click to upload a KML
							</div>
						</div>
						<FileDropzone
							overlayAction={fileUploadAction}
							setOverlayAction={setFileUploadAction}
							onGeoJSONUploaded={handleUploadedGeoJSON}
						/>
					</Grid.Row>
				</Grid.Column>
				<Grid.Column style={{ padding: 0, paddingLeft: "1rem" }}>
					<ParcelMapViewer
						userId={userId}
						parcelList={parcelList}
						setParcelList={setParcelList}
						hoveredParcel={hoveredParcel}
						setHoveredParcel={setHoveredParcel}
						selectedParcelList={selectedParcelList}
						setSelectedParcelList={setSelectedParcelList}
						focusedParcel={focusedParcel}
						setFocusedParcel={setFocusedParcel}
						initCoords={initCoords}
						setInitCoords={setInitCoords}
						visibleLayers={layers}
						onAddParcel={onAddParcel}
					/>
				</Grid.Column>
			</Grid>
			<ParcelMetaDataModal
				open={metaDataModalOpen}
				setOpen={setMetaDataModalOpen}
				onSave={(props) => onSaveParcel(focusedParcel, props)}
				parcelFeature={focusedParcelFeature}
				stateOptions={stateOptions}
				onDelete={onDeleteParcel}
				stateAbbreviationMap={stateAbbreviationMap}
			/>
			<Modal basic open={uploadingModalOpen} onClose={() => setUploadingModalOpen(false)}>
				<Modal.Header>Browse for a KML File</Modal.Header>
			</Modal>
		</>
	);
};

FarmGeoPositioning.propTypes = {
	clientId: PropTypes.string,
	userId: PropTypes.string,
	farmId: PropTypes.string,
	getTokenSilently: PropTypes.func,
	setSaveFunction: PropTypes.func,
	farmData: PropTypes.object,
	setIsDirty: PropTypes.func
};

export default FarmGeoPositioning;
