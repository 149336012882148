//-- http://www.andrewnoske.com/wiki/Code_-_heatmaps_and_color_gradients

class ColorGradient {
	constructor(min, mid, max, customGradient = false, reversed = false) {
		if (customGradient) {
			this.colorPoints = customGradient(min, mid, max, reversed);
		} else {
			this.createDefaultHeatMapGradient(min, mid, max, reversed);
		}
	}

	//-- An array of color points in ascending value.
	colorPoints = [];

	//-- Places a 3 color heapmap gradient into the "color" vector:
	createDefaultHeatMapGradient(min, mid, max, reversed) {
		this.colorPoints = reversed
			? [
					{ r: 0, g: 255, b: 0, a: 0.5, v: min }, //-- Red
					{ r: 255, g: 255, b: 0, a: 0.5, v: mid }, //-- Yellow
					{ r: 255, g: 0, b: 0, a: 0.5, v: max } //-- Green
			  ]
			: [
					{ r: 255, g: 0, b: 0, a: 0.5, v: min }, //-- Green
					{ r: 255, g: 255, b: 0, a: 0.5, v: mid }, //-- Yellow
					{ r: 0, g: 255, b: 0, a: 0.5, v: max } //-- Red
			  ];
	}

	static oneColorGradient(color) {
		const func = (min, _mid, max, reversed) =>
			!reversed
				? [
						{ r: 0, g: 0, b: 0, a: 0.5, v: min },
						{ ...color, a: 0.5, v: max }
				  ]
				: [
						{ ...color, a: 0.5, v: min },
						{ r: 0, g: 0, b: 0, a: 0.5, v: max }
				  ];
		return func;
	}

	static twoColorGradient(color1, color2) {
		const func = (min, _mid, max, reversed) =>
			!reversed
				? [
						{ ...color1, a: 0.5, v: min },
						{ ...color2, a: 0.5, v: max }
				  ]
				: [
						{ ...color2, a: 0.5, v: min },
						{ ...color1, a: 0.5, v: max }
				  ];
		return func;
	}

	static threeColorGradient(color1, color2, color3) {
		const func = (min, mid, max, reversed) =>
			!reversed
				? [
						{ ...color1, a: 0.5, v: min },
						{ ...color2, a: 0.5, v: mid },
						{ ...color3, a: 0.5, v: max }
				  ]
				: [
						{ ...color3, a: 0.5, v: min },
						{ ...color2, a: 0.5, v: mid },
						{ ...color1, a: 0.5, v: max }
				  ];
		return func;
	}

	//-- Inputs a (value) between 0 and 1 and outputs the (red), (green) and (blue)
	//-- values representing that position in the gradient.
	getColorAtValue(value) {
		if (this.colorPoints.length === 0) return { r: 0, g: 0, b: 0 };

		for (let i = 0; i < this.colorPoints.length; i++) {
			let currentColor = this.colorPoints[i];
			if (value <= currentColor.v) {
				let previousColor = null;

				if (i === 0) {
					previousColor = this.colorPoints[0];
				} else {
					previousColor = this.colorPoints[i - 1];
				}

				const valueDiff = previousColor.v - currentColor.v;
				const fractBetween = valueDiff === 0 ? 0 : (value - currentColor.v) / valueDiff;
				const r = (previousColor.r - currentColor.r) * fractBetween + currentColor.r;
				const g = (previousColor.g - currentColor.g) * fractBetween + currentColor.g;
				const b = (previousColor.b - currentColor.b) * fractBetween + currentColor.b;

				//-- r,g,b,alpha
				return `rgb(${r}, ${g}, ${b}, .5)`;
			}
		}
	}

	//-- Same function as above, returning rgb values only
	getRgbValuesAtValue(value, domain) {
		if (this.colorPoints.length === 0) return { r: 0, g: 0, b: 0 };

		if (domain.length === 2) {
			if (value <= domain[0]) {
				const firstColor = this.colorPoints[0];
				return [firstColor.r, firstColor.g, firstColor.b];
			}

			if (value >= domain[1]) {
				const lastColor = this.colorPoints[this.colorPoints.length - 1];
				return [lastColor.r, lastColor.g, lastColor.b];
			}
		}

		for (let i = 0; i < this.colorPoints.length; i++) {
			let currentColor = this.colorPoints[i];

			if (value <= currentColor.v) {
				let previousColor = null;

				if (i === 0) {
					previousColor = this.colorPoints[0];
				} else {
					previousColor = this.colorPoints[i - 1];
				}

				const valueDiff = previousColor.v - currentColor.v;
				const fractBetween = valueDiff === 0 ? 0 : (value - currentColor.v) / valueDiff;
				const r = (previousColor.r - currentColor.r) * fractBetween + currentColor.r;
				const g = (previousColor.g - currentColor.g) * fractBetween + currentColor.g;
				const b = (previousColor.b - currentColor.b) * fractBetween + currentColor.b;

				//-- r,g,b
				return [r, g, b];
			}
		}
	}
}

export default ColorGradient;
