import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { Segment } from "semantic-ui-react";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import MapboxGl from "mapbox-gl";
import ReactMapboxGl from "react-mapbox-gl";
import * as turf from "@turf/turf";

import leaf from "../../../../assets/leaf.svg";

const Ortho = ({ trials }) => {
	const moduleNavigation = useModuleNavigation();

	const Map = ReactMapboxGl({
		accessToken: process.env.MAPBOX_ACCESS_TOKEN,
		maxZoom: 23,
		bearingSnap: 0
	});

	function setupMap(map) {
		addTrialsLayer(map);

		map.on("mouseover", "trials", () => {
			map.getCanvas().style.cursor = "pointer";
		});

		map.on("mouseleave", "trials", () => {
			map.getCanvas().style.cursor = "default";
		});

		map.on("click", "trials", (e) => {
			window.open(moduleNavigation.createTrialLink(false, e.features[0].properties.trial, "viewer"), "_blank");
		});

		let openPopup = null;

		map.on("mouseenter", "trials", (e) => {
			openPopup = new MapboxGl.Popup({ closeButton: false })
				.setLngLat(e.features[0].geometry.coordinates)
				.setHTML(`<p style="font-size: 15px; font-weight: bold;">${e.features[0].properties.name}</p>`)
				.addTo(map);
		});

		map.on("mouseleave", "trials", () => {
			openPopup.remove();
		});

		let points = _.map(trials, ({ llLong, llLat, urLong, urLat }) => {
			return turf.point(turf.midpoint(turf.point([llLong, llLat]), turf.point([urLong, urLat])).geometry.coordinates);
		});
		let bboxCoords = turf.bbox(turf.featureCollection(points));

		map.fitBounds(MapboxGl.LngLatBounds.convert(bboxCoords), {
			padding: 30,
			animate: false
		});
	}

	function addTrialsLayer(map) {
		const leafImage = new Image(64, 64);
		leafImage.onload = () => map.addImage("leaf", leafImage, { sdf: "true" });
		leafImage.src = leaf;

		let labelCollection = {
			type: "FeatureCollection",
			features: []
		};

		_.map(trials, (trial) => {
			let labelToAdd = {
				type: "Feature",
				properties: {
					name: trial.name,
					trial: trial.trialId
				},
				geometry: {
					type: "Point",
					coordinates: turf.midpoint(turf.point([trial.llLong, trial.llLat]), turf.point([trial.urLong, trial.urLat]))
						.geometry.coordinates
				}
			};
			labelCollection.features.push(labelToAdd);
		});

		map.addSource("trialSource", {
			type: "geojson",
			data: labelCollection
		});

		map.addLayer({
			id: "trials",
			type: "symbol",
			source: "trialSource",
			layout: {
				"icon-image": "leaf",
				"icon-size": 0.7,
				"icon-allow-overlap": true
			},
			paint: {
				"icon-color": "#00ffff"
			}
		});
	}

	return (
		<Segment basic id="overviewMapboxComponent">
			<Map
				style={"mapbox://styles/mapbox/satellite-streets-v11"}
				containerStyle={{
					height: "calc(100vh - 550px)",
					width: "100%"
				}}
				center={[0, 0]}
				zoom={[1]}
				onStyleLoad={(e) => {
					setupMap(e);
				}}
			></Map>
		</Segment>
	);
};

Ortho.propTypes = {
	trials: PropTypes.array.isRequired
};

export default Ortho;
