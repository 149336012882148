import React, { useImperativeHandle, useState } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

interface ViewerDropdownProps {
	label: string;
	updateCallback: (val: any) => string;
}

const ViewerDropdown = React.forwardRef(({ label, updateCallback }: ViewerDropdownProps, ref) => {
	useImperativeHandle(ref, () => {
		return {
			setSelected: (val: any) => setSelected(val),
			setOptions: (opts: DropdownItemProps[]) => setOptions(opts)
		};
	});

	const [selected, setSelected] = useState<any>(null);
	const [options, setOptions] = useState<DropdownItemProps[]>([]);

	//-- This will check if we are allowed to changed to the selection
	const updateSelected = (value: any) => {
		if (value) {
			updateCallback(value);
		}
	};

	return (
		<>
			<Dropdown
				fluid
				selection
				search
				options={options}
				placeholder={label}
				value={selected}
				onChange={(_, { value }) => updateSelected(value)}
				loading={!options}
			/>
		</>
	);
});

ViewerDropdown.displayName = "ViewerDropdown";
export default ViewerDropdown;
