import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Loader, Segment } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import Ortho from "../../../Lumber/OrthoViewer";

const FarmViewer = () => {
	const moduleNavigation = useModuleNavigation();

	// -- SET UP ENTITY NAMES

	//-- Data
	const [farmName, setFarmName] = useState(null);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [imageFound, setImageFound] = useState(null);

	useEffect(() => {
		if (entityNames?.farmId && moduleNavigation.farmId.toUpperCase() === entityNames.farmId.toUpperCase()) {
			setFarmName(entityNames.farmName);
			setLoading(false);
		}
	}, [entityNames]);

	return loading ? (
		<Loader active />
	) : (!loading && !farmName) || imageFound === false ? (
		<Segment>
			<Segment>
				<p>{"This farm could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment basic style={{ height: "100%" }}>
			<Ortho
				level="farm"
				edit={true}
				viewer={true}
				setOrthoImageTypes={() => {}}
				setDraw={() => {}}
				setImageFound={(truth) => setImageFound(truth)}
			/>
		</Segment>
	);
};

export default FarmViewer;
