import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";

import { toast } from "react-toastify";
import { Button, Form, Loader, Modal } from "semantic-ui-react";

import "../../Content/Settings/styles.css";

import { useAuth0 } from "../../../auth/auth0";

import * as companyActions from "../../../redux/actions/companyActions";
import * as farmActions from "../../../redux/actions/farmActions.ts";
import * as lookupActions from "../../../redux/actions/lookupActions";

const FmAddEditFarm = ({ clientId, farm, onCancel, onFarmAdded, onFarmUpdated }) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Data Sources
	const mode = farm ? "update" : "add";

	//-- Options
	const [stateOptions, setStateOptions] = useState(null);
	const [cooperatorCompanyOptions, setCooperatorCompanyOptions] = useState([]);
	//-- Selected Data
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const [abbreviation, setAbbreviation] = useState(null);
	const [address1, setAddress1] = useState(null);
	const [address2, setAddress2] = useState(null);
	const [city, setCity] = useState(null);
	const [stateId, setStateId] = useState(null);
	const [zip, setZip] = useState(null);
	const [county, setCounty] = useState(null);
	const [lat, setLat] = useState(null);
	const [long, setLong] = useState(null);
	const [notes, setNotes] = useState(null);
	const [cooperatorCompanyId, setCooperatorCompanyId] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		getData();
		setupFarm();
	}, []);

	async function getData() {
		setLoading(true);
		await getStatesProvinces();
		await getCooperatorCompanies();
		setLoading(false);
	}

	async function getStatesProvinces() {
		const accessToken = await getTokenSilently();
		dispatch(lookupActions.getStates(clientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					setupStateOptions(res.data);
				} else {
					console.error("Unable to load states. Please try again.");
				}
			})
			.catch(() => {
				console.error("Unable to load states. Please try again.");
			});
	}

	function setupStateOptions(sOptions) {
		const stateOpts = _.map(sOptions, ({ id, name }) => {
			const opt = { key: id, value: id, text: name };
			return opt;
		});
		setStateOptions(stateOpts);
	}

	async function getCooperatorCompanies() {
		const accessToken = await getTokenSilently();
		dispatch(companyActions.getCooperatorCompanies(clientId, accessToken))
			.then((res) => {
				if (res.data.length > 0) {
					setupCooperatorCompanyOptions(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	function setupCooperatorCompanyOptions(data) {
		const opts = _.map(data, ({ id, name }) => {
			const opt = { key: id, value: id, text: name };
			return opt;
		});
		setCooperatorCompanyOptions(opts);
	}

	const setupFarm = () => {
		setId(farm?.farmId ?? "");
		setName(farm?.farmName ?? "");
		setAbbreviation(farm?.abbreviation ?? "");
		setAddress1(farm?.address1 ?? "");
		setAddress2(farm?.address2 ?? "");
		setCity(farm?.city ?? "");
		setStateId(farm?.stateId ?? ""), setZip(farm?.zip ?? "");
		setCounty(farm?.county ?? "");
		setLat(farm?.lat ?? "");
		setLong(farm?.long ?? "");
		setNotes(farm?.notes ?? "");
		setCooperatorCompanyId(farm?.cooperatorCompanyId ?? "");
	};

	const createFarm = async () => {
		const farmObject = getFarmObject();

		setSaving(true);

		const accessToken = await getTokenSilently();

		dispatch(farmActions.createFarm(accessToken, farmObject))
			.then((res) => {
				if (res.statusCode === 200) {
					onFarmAdded(res.data, farmObject.name);
					toast.success("Farm created successfully");
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onFarmAdded();
								toast.info(m.text, {});
							} else if (m.type === "FailedValidation") {
								toast.error(m.text, {
									autoClose: false,
									hideProgressBar: true,
									closeOnClick: true,
									draggable: false
								});
							}
						});
					}
				}

				setSaving(false);
			})
			.catch((err) => {
				toast.error("Unable to create farm. Please try again.");
				console.log(err);
				setSaving(false);
			});
	};

	const updateFarm = async () => {
		const farmObject = getFarmObject();

		setSaving(true);

		const accessToken = await getTokenSilently();

		dispatch(farmActions.updateFarm(accessToken, farmObject))
			.then((res) => {
				if (res.statusCode === 200) {
					onFarmUpdated();
					toast.success("Farm updated successfully");
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onFarmUpdated();
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text, {
									autoClose: false,
									hideProgressBar: true,
									closeOnClick: true,
									draggable: false
								});
							}
						});
					}
				}

				setSaving(false);
			})
			.catch((err) => {
				toast.error("Unable to update farm. Please try again.");
				console.log(err);
				setSaving(false);
			});
	};

	const getFarmObject = () => {
		let farmObj = {
			clientId,
			id,
			name,
			abbreviation,
			address1,
			address2,
			city,
			stateId,
			zip,
			county,
			lat,
			long,
			notes,
			cooperatorCompanyId
		};

		return farmObj;
	};

	return loading ? (
		<Loader />
	) : (
		<>
			<Modal.Content>
				<Form>
					<Form.Group>
						<Form.Input
							width={8}
							label="Name"
							required
							placeholder="Name"
							value={name}
							onChange={(event, { value }) => {
								setName(value);
							}}
						/>
						<Form.Input
							width={4}
							label="Abbreviation"
							required
							placeholder="Abbr."
							value={abbreviation}
							onChange={(event, { value }) => {
								setAbbreviation(value);
							}}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							width={8}
							label="Address 1"
							placeholder="Address 1"
							value={address1}
							onChange={(event, { value }) => {
								setAddress1(value);
							}}
						/>
						<Form.Input
							width={8}
							label="Address 2"
							placeholder="Address 2"
							value={address2}
							onChange={(event, { value }) => {
								setAddress2(value);
							}}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							width={4}
							label="City"
							placeholder="City"
							required
							value={city}
							onChange={(event, { value }) => {
								setCity(value);
							}}
						/>
						<Form.Select
							width={4}
							label="State"
							selection
							placeholder="State"
							required
							search
							options={stateOptions ?? []}
							loading={!stateOptions}
							value={stateId}
							onChange={(event, { value }) => {
								setStateId(value);
							}}
						/>
						<Form.Input
							width={4}
							label="Zip"
							placeholder="Zip"
							value={zip}
							onChange={(event, { value }) => {
								setZip(value);
							}}
						/>
						<Form.Input
							width={4}
							label="County"
							placeholder="County"
							value={county}
							onChange={(event, { value }) => {
								setCounty(value);
							}}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							width={4}
							label="Latitude"
							placeholder="Lat"
							value={lat}
							onChange={(event, { value }) => {
								setLat(value);
							}}
						/>
						<Form.Input
							width={4}
							label="Longitude"
							placeholder="Long"
							value={long}
							onChange={(event, { value }) => {
								setLong(value);
							}}
						/>
						<Form.Select
							width={8}
							label="Cooperator Company"
							required
							selection
							placeholder="Cooperator Company"
							search
							options={cooperatorCompanyOptions ?? []}
							loading={cooperatorCompanyOptions.length === 0}
							value={cooperatorCompanyId}
							onChange={(event, { value }) => {
								setCooperatorCompanyId(value);
							}}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Input
							width={16}
							label="Notes"
							placeholder="Notes"
							value={notes}
							onChange={(event, { value }) => {
								setNotes(value);
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					id="form-button-cancel"
					floated="left"
					type="button"
					content="Cancel"
					onClick={onCancel}
					disabled={saving}
				/>
				<Button
					id="form-button-save"
					type="button"
					primary
					content={mode === "add" ? "Create" : "Update"}
					onClick={mode === "add" ? createFarm : updateFarm}
					loading={saving}
					disabled={saving}
				/>
			</Modal.Actions>
		</>
	);
};

FmAddEditFarm.propTypes = {
	clientId: PropTypes.string.isRequired,
	farm: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
	onFarmAdded: PropTypes.func.isRequired,
	onFarmUpdated: PropTypes.func.isRequired
};

export default FmAddEditFarm;
