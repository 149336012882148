import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Loader, Segment } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as flightImageActions from "../../../../redux/actions/flightImageActions";
import Ortho from "../../../Lumber/OrthoViewer";
import OrthoConfirmModal from "../../../Lumber/OrthoViewer/orthoConfirmModal";
import * as OrthoControlFunctions from "../../../Lumber/OrthoViewer/orthoControlFunctions";

const OrthoAlignment = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const orthoModal = React.createRef();
	const [loading, setLoading] = useState(false);
	const [imageFound, setImageFound] = useState(null);
	let orthoImageTypes = [];
	let path = "";
	let unblock = React.useRef();
	let draw = null;

	useEffect(() => {
		if (orthoModal && orthoModal.current) {
			unblock.current = history.block(({ pathname }) => {
				let arePlotsSaved = OrthoControlFunctions.checkPlotsSaved(getDraw());
				if (arePlotsSaved !== -1) {
					path = pathname;
					getOrthoModal().current.handleClick();
					return false;
				} else {
					unblock.current();
				}
			});
		}
	}, [orthoModal]);

	const getOrthoModal = () => {
		return orthoModal;
	};

	const getDraw = () => {
		return draw;
	};

	async function handleContinue() {
		let allFieldsLocked = OrthoControlFunctions.checkPlotsLocked(draw);
		const accessToken = await getTokenSilently();
		if (allFieldsLocked === -1) {
			var saveButton = document.getElementById("form-button-continue");
			saveButton.classList.add("loading");
			toast.info("Checking if images have been processed");
			dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
				saveButton.classList.remove("loading");
				if (!res.rasterUpload) {
					toast.warn("Images have not finished processing. Please try again shortly", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
				} else {
					setLoading(true);
					unblock.current();
					OrthoControlFunctions.handleSave(
						moduleNavigation.flightId,
						clientId,
						orthoImageTypes,
						"field",
						getTokenSilently,
						dispatch,
						() => {
							history.push(moduleNavigation.createFlightLink(true, null, "add-new-fields"));
						}
					);
				}
			});
		} else {
			path = moduleNavigation.createFlightLink(true, null, "add-new-fields");
			orthoModal.current.handleClick();
		}
	}

	const handleBack = () => {
		history.push(moduleNavigation.createFlightLink(true, null, "ortho-upload"));
		unblock.current();
	};

	return (
		<>
			{imageFound === false ? (
				<Segment>
					<p>{"This flight image could not be found."}</p>
				</Segment>
			) : (
				<>
					{loading ? (
						<Segment basic style={{ height: "calc(100vh - 200px)" }}>
							<Loader active />
						</Segment>
					) : (
						<div style={{ height: "calc(100% - 1rem)" }}>
							<Ortho
								level="flight"
								edit={false}
								viewer={false}
								handleBack={() => handleBack()}
								handleContinue={() => handleContinue()}
								setOrthoImageTypes={(orthos) => (orthoImageTypes = orthos)}
								setDraw={(childDraw) => (draw = childDraw)}
								setImageFound={(truth) => setImageFound(truth)}
							/>
						</div>
					)}
					<OrthoConfirmModal
						ref={orthoModal}
						navTo={() => path}
						orthoImageTypes={() => orthoImageTypes}
						type="field"
						unblock={() => unblock.current()}
					/>
				</>
			)}
		</>
	);
};

export default OrthoAlignment;
