import React, { useEffect, useState } from "react";
import { Segment, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Tooltip from "rc-tooltip";
import PropTypes from "prop-types";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import * as uuid from "uuid";

import _ from "lodash";

const TreatmentsTable = ({ summaryInfo, applications, associatedTrials, groundData, trialGroundData }) => {
	const moduleNavigation = useModuleNavigation();

	const [uniqueApplications, setUniqueApplications] = useState([]);
	const [uniqueAssociatedTrials, setUniqueAssociatedTrials] = useState([]);

	useEffect(() => {
		setUniqueApplications(_.uniqBy(applications, "id"));
	}, [applications]);

	useEffect(() => {
		setUniqueAssociatedTrials(_.uniqBy(associatedTrials, "trialId"));
	}, [associatedTrials]);

	function overlayMessage(validations) {
		let messages = [];
		_.map(validations, (v) => {
			if (v.type === 2 || v.type === 0) {
				messages.push(<p>{v.message}</p>);
			}
		});

		return messages;
	}

	function getApplicationTiming(timing) {
		let applicationTiming = "";

		switch (timing.timingMethodType) {
			case "int":
				applicationTiming = `${timing.timingMethodName} ${timing.growthPhaseInteger}`;
				break;
			case "Date":
				applicationTiming = timing.growthPhaseDate;
				break;
			case "StartEnd":
				applicationTiming = `${timing.startGrowthStageName} - ${timing.endGrowthStageName}`;
				break;
			case "Start":
				applicationTiming = timing.startGrowthStageName;
				break;
			case "Text":
				applicationTiming = timing.growthPhaseText;
				break;
			case "None":
				applicationTiming = timing.timingMethodName;
				break;
		}

		return applicationTiming;
	}
	return (
		<>
			<Segment basic style={{ width: "100%", overflowX: "scroll" }}>
				<Table celled structured striped sortable selectable color="blue" textAlign="center">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell colSpan="3" rowSpan="2">
								Treatments
							</Table.HeaderCell>
							<Table.HeaderCell colSpan={uniqueApplications.length}>Applications</Table.HeaderCell>
							<Table.HeaderCell colSpan={uniqueAssociatedTrials.length} rowSpan="2">
								Associated Trials
							</Table.HeaderCell>
						</Table.Row>
						<Table.Row>
							{_.map(uniqueApplications, (a) => {
								return <Table.HeaderCell key={a.id}>App {a.code}</Table.HeaderCell>;
							})}
						</Table.Row>
						<Table.Row>
							<Table.HeaderCell>#</Table.HeaderCell>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							{_.map(uniqueApplications, (a) => {
								return (
									<Table.HeaderCell key={a.id}>
										{a.applicationType}
										<br />
										{a.applicationMethod}
										<br />
										{getApplicationTiming(a)}
									</Table.HeaderCell>
								);
							})}
							{_.map(_.sortBy(uniqueAssociatedTrials, ["state", "city", "trialName"]), (uat) => {
								return (
									<Table.HeaderCell key={uat.trialId}>
										<>
											Trial{" "}
											<Link to={moduleNavigation.createTrialLink(false, uat.trialId, "edit", null)} target="_blank">
												{uat.trialName}
											</Link>{" "}
											<br /> {uat.city}, {uat.stateName} <br /> {uat.cooperatorName}
										</>
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(summaryInfo, (si) => {
							return (
								<Table.Row key={si.id}>
									<Table.Cell>{si.trialTreatmentId}</Table.Cell>
									<Table.Cell>{si.name}</Table.Cell>
									<Table.Cell>{si.treatmentTypeName}</Table.Cell>
									{_.map(si.applications, (a) => {
										return <Table.Cell key={a.id}>{a.checked ? <i className="check icon"></i> : ""}</Table.Cell>;
									})}
									{_.map(_.sortBy(uniqueAssociatedTrials, ["state", "city", "trialName"]), (at) => {
										let trial = _.find(si.associatedTrials, (sat) => {
											return at.trialId === sat.trialId;
										});

										if (!trial) {
											return (
												<Tooltip
													key={uuid.v4()}
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Treatment does not exist on trial</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Table.Cell warning>
														<i className="exclamation icon"></i>
													</Table.Cell>
												</Tooltip>
											);
										} else {
											if (trial.validations.length === 0) {
												return (
													<Table.Cell key={uuid.v4()} positive>
														<i className="check icon"></i>
													</Table.Cell>
												);
											} else if (
												_.some(trial.validations, (v) => {
													return v.type === 2;
												})
											) {
												return (
													<Tooltip
														key={uuid.v4()}
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														multiline={true}
														overlay={overlayMessage(trial.validations)}
														transitionName="rc-tooltip-zoom"
													>
														<Table.Cell error>
															<i className="close icon"></i>
														</Table.Cell>
													</Tooltip>
												);
											} else if (
												_.some(trial.validations, (v) => {
													return v.type === 0;
												})
											) {
												return (
													<Tooltip
														key={uuid.v4()}
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={_.map(trial.validations, (v) => {
															if (v.type === 0) {
																return v.message;
															}
														})}
														transitionName="rc-tooltip-zoom"
													>
														<Table.Cell warning>
															<i className="exclamation icon"></i>
														</Table.Cell>
													</Tooltip>
												);
											}
										}
									})}
								</Table.Row>
							);
						})}
					</Table.Body>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell colSpan={3}>Assessments</Table.HeaderCell>
							<Table.HeaderCell colSpan={uniqueApplications.length + uniqueAssociatedTrials.length}></Table.HeaderCell>
						</Table.Row>
						<Table.Row>
							<Table.HeaderCell>Dataset</Table.HeaderCell>
							<Table.HeaderCell>Assessment</Table.HeaderCell>
							<Table.HeaderCell>Timing</Table.HeaderCell>
							<Table.HeaderCell colSpan={uniqueApplications.length + uniqueAssociatedTrials.length}></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(groundData, (gd) => {
							{
								return _.map(gd, (assessment, index) => {
									return (
										<Table.Row key={assessment.groundDatasetAssessmentDataId}>
											{index === 0 ? (
												<Table.Cell rowSpan={gd.length}>{assessment.datasetNumericalId}</Table.Cell>
											) : null}
											<Table.Cell>{assessment.groundDataAssessmentName}</Table.Cell>
											<Table.Cell>{getApplicationTiming(assessment)}</Table.Cell>
											<Table.Cell colSpan={uniqueApplications?.length}></Table.Cell>
											{_.map(_.sortBy(uniqueAssociatedTrials, ["state", "city", "trialName"]), (uat) => {
												const matchingGroundData = _.find(trialGroundData, (tgd) => {
													return (
														tgd.trialId === uat.trialId &&
														((tgd.groundDataAssessmentId == assessment.groundDataAssessmentId &&
															tgd.groundDataTimingMethodId == assessment.groundDataTimingMethodId &&
															tgd.timingMethodName != "Growth Phase" &&
															tgd.growthPhaseDate == assessment.growthPhaseDate &&
															tgd.endGrowthStageId == assessment.endGrowthStageId &&
															tgd.growthPhaseInteger == assessment.growthPhaseInteger &&
															tgd.startGrowthStageId == assessment.startGrowthStageId &&
															tgd.growthPhaseText == assessment.growthPhaseText) ||
															(tgd.timingMethodName == "Growth Phase" &&
																tgd.groundDataGrowthPhaseId == assessment.groundDataGrowthPhaseId))
													);
												});
												if (matchingGroundData && matchingGroundData.isAssessmentLinkedToProtoocol) {
													return (
														<Tooltip
															key={uuid.v4()}
															placement="bottom"
															mouseEnterDelay={0}
															mouseLeaveDelay={0}
															trigger="hover"
															overlay="Assessment is already linked to protocol"
															transitionName="rc-tooltip-zoom"
														>
															<Table.Cell positive>
																<i className="check icon"></i>
															</Table.Cell>
														</Tooltip>
													);
												} else if (matchingGroundData) {
													return (
														<Tooltip
															key={uuid.v4()}
															placement="bottom"
															mouseEnterDelay={0}
															mouseLeaveDelay={0}
															trigger="hover"
															overlay={_.map(matchingGroundData.validations, (v) => {
																if (v.type === 0) {
																	return v.message;
																}
															})}
															transitionName="rc-tooltip-zoom"
														>
															<Table.Cell warning>
																<i className="exclamation icon"></i>
															</Table.Cell>
														</Tooltip>
													);
												} else {
													return (
														<Table.Cell positive key={uuid.v4()}>
															<i className="plus icon"></i>
														</Table.Cell>
													);
												}
											})}
										</Table.Row>
									);
								});
							}
						})}
					</Table.Body>
				</Table>
			</Segment>
		</>
	);
};
TreatmentsTable.propTypes = {
	summaryInfo: PropTypes.array,
	applications: PropTypes.array,
	associatedTrials: PropTypes.array,
	groundData: PropTypes.object,
	trialGroundData: PropTypes.array
};
export default TreatmentsTable;
