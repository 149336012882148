import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import _ from "lodash";

import Tooltip from "rc-tooltip";

import { Divider, Icon, Menu } from "semantic-ui-react";

import { useUserAuth } from "../../../hooks/useUserAuth";

import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import TrialMetaFactorsModalMenuIcon from "../../Content/Trials/TrialMetaTags/TrialMetaFactorsModalMenuIcon.tsx";
import HeatmapExportModalMenuIcon from "../../Lumber/HeatmapExportModal/HeatmapExportModalMenuIcon.tsx";
import AdminTools from "../../Lumber/AdminTools";
import "./style.css";

const LeftNavSecondary = ({ navItems, activeItem }) => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const [adminToolsOpen, setAdminToolsOpen] = useState(false);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	function moduleIsInContext(moduleName) {
		var modInContext = moduleNavigation.moduleIsInContext(moduleName);
		return modInContext;
	}

	return (
		<Menu id="leftNavSecondary" vertical floated borderless className="nav leftNav">
			{_.map(navItems, (navItem, index) => {
				return (
					navItem.enabled &&
					navItem.showInNav &&
					userAuth.userCanAccessSubmodule(navItem) && (
						<Tooltip
							key={index}
							placement="right"
							align={{ offset: [-3, 0] }}
							mouseEnterDelay={0}
							mouseLeaveDelay={0}
							trigger="hover"
							overlay={<p>{navItem.linkText}</p>}
						>
							<Menu.Item
								key={navItem.id}
								as={!userAuth.isApAdmin && navItem.id === "farm-edit" && entityNames?.farmClientId !== userAuth.currentClientId ? null : Link}
								to={!userAuth.isApAdmin && navItem.id === "farm-edit" && entityNames?.farmClientId !== userAuth.currentClientId ? null : navItem.link}
								className={!moduleIsInContext(navItem.module) && navItem.requiresContext ? "disabledNavItem" : null}
								active={activeItem.name === navItem.name || navItem.navMatchingNames?.includes(activeItem.name)}
								onClick={(e) => {
									if (!moduleIsInContext(navItem.module) && navItem.requiresContext) {
										e.preventDefault();
									}
								}}
								disabled={!userAuth.isApAdmin && navItem.id === "farm-edit" && entityNames?.farmClientId !== userAuth.currentClientId}
							>
								{navItem.cornerIcon ? (
									<Icon.Group>
										<Icon
											className={navItem.icon}
											corner="top left"
											style={{ fontSize: "1.5em", textShadow: "unset" }}
										/>
										<Icon className={navItem.cornerIcon} corner="bottom right" style={{ fontSize: 10 }} />
										{/* textShadow: "-1px -1px 0 rgb(255, 255, 255, 1), 1px -1px 0 rgb(255, 255, 255, 1), -1px 1px 0 rgb(255, 255, 255, 1), 1px 1px 0 rgb(255, 255, 255, 1)" */}
									</Icon.Group>
								) : (
									<Icon style={{ margin: 0 }} className={navItem.icon} size="large" />
								)}
							</Menu.Item>
						</Tooltip>
					)
				);
			})}
			{["trials", "protocols", "fields"].includes(moduleNavigation.activeModule.name) && (
				<Divider style={{ borderTopColor: "var(--border)" }} />
			)}
			{moduleNavigation.activeModule.name === "trials" && (
				<TrialMetaFactorsModalMenuIcon trialId={moduleNavigation.trialId} />
			)}
			{moduleNavigation.activeModule.name === "trials" && (
				<HeatmapExportModalMenuIcon id={moduleNavigation.trialId} type={"Trial"} isApAdmin={userAuth.isApAdmin} />
			)}
			{moduleNavigation.activeModule.name === "fields" && (
				<HeatmapExportModalMenuIcon id={moduleNavigation.fieldId} type={"Field"} isApAdmin={userAuth.isApAdmin} />
			)}
			{moduleNavigation.activeModule.name === "protocols" && (
				<HeatmapExportModalMenuIcon id={moduleNavigation.protocolId} type={"Protocol"} isApAdmin={userAuth.isApAdmin} />
			)}
			{["trials", "flights", "protocols", "fields"].includes(moduleNavigation.activeModule.name) &&
				userAuth.isApAdmin && (
					<Tooltip
						placement="right"
						align={{ offset: [-3, 0] }}
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						trigger="hover"
						overlay={<p>Admin Tools</p>}
					>
						<Menu.Item
							style={{ position: "fixed", bottom: 10 }}
							position="right"
							//className={!moduleIsInContext(navItem.module) && navItem.requiresContext ? "disabledNavItem" : null}
							onClick={() => {
								setAdminToolsOpen(!adminToolsOpen);
							}}
						>
							<Icon.Group>
								<Icon name="cog" size="large" />
								<div className="adminText">ADMIN</div>
							</Icon.Group>
						</Menu.Item>
					</Tooltip>
				)}
			{adminToolsOpen && (
				<AdminTools
					style={{ position: "fixed", bottom: 0, left: 136, zIndex: 10 }}
					handleClose={() => {
						setAdminToolsOpen(false);
					}}
				/>
			)}
		</Menu>
	);
};

LeftNavSecondary.propTypes = {
	navItems: PropTypes.array.isRequired,
	activeItem: PropTypes.object.isRequired
};

export default LeftNavSecondary;
