import _ from "lodash";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function orthoViewerReducer(state = initialState.orthoViewer, action) {
	let newState = {};
	let dates = [];

	switch (action.type) {
		case types.OV_SET_CURRENT_FLIGHT_ID:
			return { ...state, currentFlightId: action.flightId };

		case types.OV_SET_CURRENT_FLIGHT_DATE:
			return { ...state, currentFlightDate: action.flightDateAndTime, imageLoaded: false };

		case types.OV_SET_CURRENT_FLIGHT_YEAR:
			return { ...state, currentFlightYear: action.year };

		case types.OV_UPDATE_FILTERS:
			newState = { ...state, filters: { ...state.filters, ...action.filters } };
			// find the intersection of all filters to make it as strict as possible
			// the `...dates.filter()` is so we don't include empty filters
			dates = [
				newState.filters.trialDates.filter((d) => state.currentFlightYear.includes(Number(d.slice(-4)))),
				newState.filters.fieldDates.filter((d) => state.currentFlightYear.includes(Number(d.slice(-4)))),
				newState.filters.cropDates.filter((d) => state.currentFlightYear.includes(Number(d.slice(-4))))
			];
			newState.filters.flightDates = _.intersection(...dates.filter((arr) => arr.length > 0)).map(
				(date) => +new Date(date)
			);
			return newState;

		case types.OV_RESET_FILTERS:
			return { ...state, filters: initialState.filters };

		case types.OV_SET_IMAGE_LOADED:
			return { ...state, imageLoaded: action.imageLoaded };

		default:
			return state;
	}
}
