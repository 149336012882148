import { kml } from "@tmcw/togeojson";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";

const FileDropzone = ({ overlayAction, setOverlayAction, onGeoJSONUploaded }) => {
	const [isDragging, setIsDragging] = useState(false);
	const [show, setShow] = useState(false);
	const fileUploadRef = useRef(null);

	useEffect(() => {
		window.addEventListener("dragenter", onWindowDragDetected);

		// clean up listener
		return () => {
			window.removeEventListener("dragenter", onWindowDragDetected);
		};
	}, []);

	useEffect(() => {
		if (overlayAction === "browse") {
			fileUploadRef.current.click();
			setShow(true);
		} else if (overlayAction === "drop") {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [overlayAction]);

	const handleDrag = (e) => {
		if (e.dataTransfer.types[0] === "Files") {
			e.dataTransfer.dropEffect = "copy";
			e.preventDefault();
		}
		setIsDragging(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setIsDragging(false);
		setOverlayAction("hide");
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setOverlayAction("hide");
		const { files } = e.dataTransfer;

		if (files && files.length) {
			onUpload(files);
		}
	};

	const onWindowDragDetected = (e) => {
		if (e.dataTransfer.types[0] === "Files") {
			setOverlayAction("drop");
		} else {
			e.dataTransfer.dropEffect = "none";
		}
	};

	const onUpload = (files) => {
		console.log(files[0]);
		const reader = new FileReader();

		reader.onload = (e) => {
			const text = e.target.result;

			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(text, "text/xml");

			const converted = kml(xmlDoc);
			onGeoJSONUploaded(converted);
		};

		reader.readAsText(files[0]);
	};

	const handleFileInputChange = (e) => {
		const { files } = e.target;
		setOverlayAction("hide");
		if (files && files.length) {
			onUpload(files);
		}
		fileUploadRef.current.value = null;
	};

	return (
		<>
			<input type="file" style={{ display: "none" }} ref={fileUploadRef} onChange={handleFileInputChange} />
			{show ? (
				<div
					className="dropZoneOverlay"
					onClick={() => {
						fileUploadRef.current.click();
					}}
					onDragEnter={handleDrag}
					onDragOver={handleDrag}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<Icon
						name="close"
						link
						onClick={(e) => {
							e.stopPropagation();
							setOverlayAction("hide");
						}}
					/>
					<div className={`customBorder ${isDragging && "dragging"}`}></div>
					<div className="dragDrop">
						<span>Drag &amp; Drop to Upload a KML</span>
						<span>or Click anywhere to Browse for one</span>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

FileDropzone.propTypes = {
	overlayAction: PropTypes.string,
	setOverlayAction: PropTypes.func,
	onGeoJSONUploaded: PropTypes.func
};

export default FileDropzone;
