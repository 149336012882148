import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Table, Modal, Button, Icon } from "semantic-ui-react";

import _ from "lodash";
import moment from "moment";

import BulkAssessmentsModal from "./BulkAssessmentsModal";

const BulkAssessmentsTable = ({
	batchAssessments,
	curveModelAnalysisResults,
	flightSelections,
	plotAnalysisResults,
	batchRunPlotAnalysisResults,
	gduByDays,
	trialData,
	canCalculateGdus,
	setBatchAssessments,
	setFlightSelections,
	removeCurveModelData
}) => {
	//-- Data Sources
	const [flights, setFlights] = useState([]);
	// const [tableData, setTableData] = useState(batchAssessments);
	const [clickedPhenotypeId, setClickedPhenotypeId] = useState(null);

	//-- UI Control
	const [sortColumn, setSortColumn] = useState(null);
	const [sortDirection, setSortDirection] = useState("ascending");
	const [modalOpen, setModalOpen] = useState(false);

	const DATE_FORMAT = "M/D";

	useEffect(() => {
		if (plotAnalysisResults?.length > 0 && trialData) {
			let uniqueFlights = [];

			if (trialData.trialInfo.plantDate) {
				uniqueFlights.push({
					flightId: null,
					flightDate: trialData.trialInfo.plantDate,
					plantFlight: true,
					harvestFlight: false
				});
			}

			if (trialData.trialInfo.harvestDate) {
				uniqueFlights.push({
					flightId: null,
					flightDate: trialData.trialInfo.harvestDate,
					plantFlight: false,
					harvestFlight: true
				});
			}

			uniqueFlights = [...uniqueFlights, ..._.uniqBy(plotAnalysisResults, "flightId")];

			_.map(uniqueFlights, (uf) => {
				let analysesOnFlight = _.map(
					_.uniqBy(
						_.filter(plotAnalysisResults, (par) => par.flightId === uf.flightId),
						"analysisId"
					),
					"analysisId"
				);
				uf.analysisIds = analysesOnFlight;
			});

			uniqueFlights = _.sortBy(uniqueFlights, "flightDate");

			setFlights(uniqueFlights);
		}
	}, [plotAnalysisResults, trialData]);

	useEffect(() => {
		if (curveModelAnalysisResults && batchAssessments?.length > 0) {
			_.map(batchAssessments, (td) => {
				let filteredCurveModelAnalysisResults = _.filter(
					curveModelAnalysisResults,
					(cmar) =>
						cmar.analysisId === td.analysisId &&
						cmar.analysisTypeId === td.analysisTypeId &&
						cmar.curveModelId === td.curveModelId &&
						(cmar.quantifiedRegionTypeId === td.quantifiedRegionTypeId ||
							cmar.quantifiedRegionTypeId === td.secondaryQuantifiedRegionTypeId)
				);

				//-- Saved status is what we fall back to if the user adds to batch and removes
				//-- We don't want a previously run curve model to display "Not Yet Analyzed"
				if (filteredCurveModelAnalysisResults?.length > 0) {
					if (_.some(filteredCurveModelAnalysisResults, (fcmar) => fcmar.error)) {
						td.status = "Error";
						td.savedStatus = "Error";
					} else {
						td.status = "Success";
						td.savedStatus = "Success";
					}
				}
			});
		}
	}, [curveModelAnalysisResults]);

	const calculateGdu = (flight) => {
		let gduSum = 0;

		let gduIndex = _.findIndex(
			gduByDays,
			(g) => moment(g.day).format(DATE_FORMAT) == moment(flight.flightDate).format(DATE_FORMAT)
		);
		if (gduIndex !== -1 && !flight.plantDate) {
			let filteredGdus = _.filter(gduByDays, (g, i) => i < gduIndex);
			_.map(filteredGdus, (fg) => (gduSum += fg.gdu));
		}

		return Math.ceil(gduSum);
	};

	const handleSort = (header) => {
		if (sortColumn !== header) {
			setSortColumn(header);
			let dataSource = _.sortBy(batchAssessments, [(d) => (d[header] ? d[header].toLowerCase() : d[header])]);

			setSortDirection("ascending");
			setBatchAssessments(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		let dataSource = _.cloneDeep(batchAssessments).reverse();
		setBatchAssessments(dataSource);
	};

	return (
		<>
			<Table celled striped sortable selectable color="blue" textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Actions</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "curveModelPhenotypeName" ? sortDirection : null}
							onClick={() => handleSort("curveModelPhenotypeName")}
						>
							Assessment
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "orthoImageTypeAbbreviation" ? sortDirection : null}
							onClick={() => handleSort("orthoImageTypeAbbreviation")}
						>
							Ortho
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "curveModelName" ? sortDirection : null}
							onClick={() => handleSort("curveModelName")}
						>
							Timing
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "analysisTypeName" ? sortDirection : null}
							onClick={() => handleSort("analysisTypeName")}
						>
							Analysis Type
						</Table.HeaderCell>
						<Table.HeaderCell
							style={{ whiteSpace: "break-spaces" }}
							sorted={sortColumn === "quantifiedRegionTypeName" ? sortDirection : null}
							onClick={() => handleSort("quantifiedRegionTypeName")}
						>
							Quantified Region Type
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "curveModelFitFunctionName" ? sortDirection : null}
							onClick={() => handleSort("curveModelFitFunctionName")}
						>
							Curve Model
						</Table.HeaderCell>
						{_.map(flights, (f, i) => {
							return (
								<Table.HeaderCell key={i}>
									<div style={{ writingMode: "vertical-rl", transform: "rotate(180deg)" }}>
										{moment(f.flightDate).format(DATE_FORMAT)}
										{canCalculateGdus && (
											<>
												<br />({calculateGdu(f)})
											</>
										)}
									</div>
								</Table.HeaderCell>
							);
						})}
						<Table.HeaderCell
							sorted={sortColumn === "status" ? sortDirection : null}
							//onClick={() => handleSort("status")}
						>
							Status
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{_.map(batchAssessments, (ba, index) => {
						let matchingPlots = _.filter(batchRunPlotAnalysisResults, (res) => {
							return (
								res.analysisId === ba.analysisId &&
								res.curveModelId === ba.curveModelId &&
								res.analysisTypeId === ba.analysisTypeId
							);
						});
						let uniquePlots = _.uniqBy(matchingPlots, "plotId");
						let excludedPlots = uniquePlots.filter((x) => x.excluded);
						let total = uniquePlots?.length - excludedPlots.length;
						let lgfPlots = _.uniqBy(matchingPlots, function (elem) {
							return [elem.failed, elem.isAUC, elem.plotId, elem.isGDU].join();
						}).filter((x) => !x.isAUC);
						let hasMissingPlots = lgfPlots?.length !== total;
						let aucFailedPlots = _.uniqBy(matchingPlots, function (elem) {
							return [elem.failed, elem.isAUC, elem.plotId, elem.isGDU].join();
						}).filter((x) => x.isAUC && !x.isGDU && (x.failed || x.error));
						//Need to check for error flag because if DAP are not getting run but GDUs are and all are failing it means there was a script error. Otherwise we want to pull in just failed DAP values
						let lgfFailedPlots = _.uniqBy(matchingPlots, function (elem) {
							return [elem.failed, elem.isAUC, elem.plotId, elem.isGDU].join();
						}).filter((x) => !x.isAUC && !x.excluded && ((x.failed && !x.isGDU) || x.error));
						let uniqueLgfFailedPlots = _.uniqBy(lgfFailedPlots, "plotId");
						let totalUniqueLgfFailedPlots = uniqueLgfFailedPlots?.length;

						let successes =
							lgfPlots.length === 0 ? 0 : totalUniqueLgfFailedPlots === 0 ? total : total - totalUniqueLgfFailedPlots;

						//AUC only curve fit models shouldn't have failures
						if (ba.curveModelFitFunctionName === "AUC") {
							successes = uniquePlots?.length - excludedPlots.length;
						}
						//If LGF curve fails to run and we have missing plots and there was an error
						if (ba.curveModelFitFunctionName !== "AUC" && hasMissingPlots && aucFailedPlots?.length === total) {
							successes = 0;
						}
						return (
							<Table.Row key={index}>
								<Table.Cell>
									<Button icon onClick={() => removeCurveModelData(ba.curveModelPhenotypeId)}>
										<Icon name="trash" />
									</Button>
								</Table.Cell>
								<Table.Cell
									style={{ cursor: "pointer", textDecoration: "underline", color: "mediumblue" }}
									onClick={() => {
										setClickedPhenotypeId(ba.curveModelPhenotypeId);
										setModalOpen(true);
									}}
								>
									{ba.curveModelPhenotypeName}
								</Table.Cell>
								<Table.Cell>{ba.orthoImageTypeAbbreviation}</Table.Cell>
								<Table.Cell>{ba.curveModelName}</Table.Cell>
								<Table.Cell>{ba.analysisTypeName}</Table.Cell>
								<Table.Cell>{ba.quantifiedRegionTypeName}</Table.Cell>
								<Table.Cell>{ba.curveModelFitFunctionName}</Table.Cell>
								{_.map(flights, (f, i) => {
									let filteredSelectedFlightsForBa = _.filter(
										flightSelections,
										(fs) =>
											fs.analysisId === ba.analysisId &&
											fs.curveModelId === ba.curveModelId &&
											fs.analysisTypeId === ba.analysisTypeId &&
											(fs.quantifiedRegionTypeId === ba.quantifiedRegionTypeId ||
												fs.quantifiedRegionTypeId === ba.secondaryQuantifiedRegionTypeId)
									);
									let flightSelected =
										(f.flightId && _.map(filteredSelectedFlightsForBa, "flightId").includes(f.flightId)) ||
										(_.some(filteredSelectedFlightsForBa, "plantFlight") && f.plantFlight) ||
										(_.some(filteredSelectedFlightsForBa, "harvestFlight") && f.harvestFlight);

									let flightExists = true;
									if (flightSelected) {
										flightExists =
											(f.flightId &&
												_.find(filteredSelectedFlightsForBa, { flightId: f.flightId })?.existsOnAnalysis) ||
											(_.some(filteredSelectedFlightsForBa, "plantFlight") && f.plantFlight) ||
											(_.some(filteredSelectedFlightsForBa, "harvestFlight") && f.harvestFlight);
									}

									return (
										<Table.Cell key={i}>{flightSelected && flightExists ? "x" : !flightExists ? "-" : " "}</Table.Cell>
									);
								})}

								{!ba.status && <Table.Cell style={{ color: "grey" }}>Not Yet Analyzed</Table.Cell>}
								{ba.status === "Ready - Batched" && (
									<Table.Cell style={{ color: "#2185d0" }}>Ready - Batched</Table.Cell>
								)}
								{/* {ba.status === "Success" && <Table.Cell style={{ color: successes?.length !== total ? "#db2828" : "#2cbf1f" }}>{successes?.length} successes / {total} total</Table.Cell>}
								{ba.status === "Error" && <Table.Cell style={{ color: "#db2828" }}>Run Failed</Table.Cell>}*/}
								{ba.status && ba.status !== "Ready - Batched" && successes !== total && (
									<Table.Cell style={{ color: "#db2828" }}>
										{successes} successes / {total} total
									</Table.Cell>
								)}
								{/*<Table.Cell style={{ color: "#2cbf1f" }}>{successes?.length} successes / {total} total</Table.Cell>*/}
								{ba.status && ba.status !== "Ready - Batched" && successes === total && (
									<Table.Cell style={{ color: "#2cbf1f" }}>
										{successes} successes / {total} total
									</Table.Cell>
								)}
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
			<Modal open={modalOpen} style={{ width: "80%" }}>
				<Modal.Content>
					<BulkAssessmentsModal
						clickedPhenotypeId={clickedPhenotypeId}
						tableData={batchAssessments}
						flights={flights}
						flightSelections={flightSelections}
						plotAnalysisResults={plotAnalysisResults}
						trialData={trialData}
						canCalculateGdus={canCalculateGdus}
						calculateGdus={(f) => calculateGdu(f)}
						setBatchAssessments={(ba) => setBatchAssessments(ba)}
						setFlightSelections={(fs) => setFlightSelections(fs)}
						setModalClosed={() => setModalOpen(false)}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
};

BulkAssessmentsTable.propTypes = {
	batchAssessments: PropTypes.array.isRequired,
	curveModelAnalysisResults: PropTypes.array.isRequired,
	flightSelections: PropTypes.array.isRequired,
	plotAnalysisResults: PropTypes.array.isRequired,
	gduByDays: PropTypes.array.isRequired,
	trialData: PropTypes.object.isRequired,
	canCalculateGdus: PropTypes.bool.isRequired,
	setBatchAssessments: PropTypes.func.isRequired,
	setFlightSelections: PropTypes.func.isRequired,
	removeCurveModelData: PropTypes.func.isRequired,
	batchRunPlotAnalysisResults: PropTypes.array.isRequired
};

export default BulkAssessmentsTable;
