// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewerContainer {\n\twidth: 100%;\n\tbackground-color: #aaa;\n\tborder-radius: 4px;\n\toverflow: hidden;\n\taspect-ratio: 1/1;\n\toutline: 3px solid white;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Farms/FarmSummary/viewer.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,sBAAsB;CACtB,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB;CACjB,wBAAwB;AACzB","sourcesContent":[".viewerContainer {\n\twidth: 100%;\n\tbackground-color: #aaa;\n\tborder-radius: 4px;\n\toverflow: hidden;\n\taspect-ratio: 1/1;\n\toutline: 3px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewerContainer": "viewerContainer"
};
export default ___CSS_LOADER_EXPORT___;
