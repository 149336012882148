import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Modal } from "semantic-ui-react";

const DeleteModal = ({ open, setOpen, onDelete, deleting = false }) => {
	return (
		<Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
			<Modal.Header>
				Delete Farm
				<Icon name="close" link onClick={() => setOpen(false)} />
			</Modal.Header>
			<Modal.Content>Are you sure you would like to delete this farm?</Modal.Content>
			<Modal.Actions>
				<Button
					compact
					content="Cancel"
					disabled={deleting}
					onClick={() => {
						setOpen(false);
					}}
				/>
				<Button
					style={{ margin: 0 }}
					negative
					compact
					content="Delete"
					loading={deleting}
					disabled={deleting}
					onClick={() => {
						onDelete();
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
};

DeleteModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	deleting: PropTypes.bool
};

export default DeleteModal;
