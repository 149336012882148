import React, { useState } from "react";

import { Segment } from "semantic-ui-react";

import Ortho from "../../../Lumber/OrthoViewer";

const FlightViewer = () => {
	const [imageFound, setImageFound] = useState(null);

	return imageFound === false ? (
		<Segment>
			<p>{"This flight could not be found."}</p>
		</Segment>
	) : (
		<Segment basic style={{ height: "100%" }}>
			<Ortho
				level="flight"
				edit={true}
				viewer={true}
				setOrthoImageTypes={() => {}}
				setDraw={() => {}}
				setImageFound={(truth) => setImageFound(truth)}
			/>
		</Segment>
	);
};

export default FlightViewer;
