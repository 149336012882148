export const parcelLayer = {
	id: "parcels",
	type: "fill",
	paint: {
		"fill-color": "rgba(217, 217, 217, 0.35)"
	}
};

export const outlineParcelLayer = {
	id: "outlines",
	type: "line",
	paint: {
		"line-color": "#ffffff",
		"line-width": 2
	}
};

export const hoveredParcelLayer = {
	id: "parcels-hovered",
	type: "fill",
	paint: {
		"fill-color": "rgba(158, 158, 158, 0.35)"
	}
};

export const hoveredOutlineParcelLayer = {
	id: "outlines-hovered",
	type: "line",
	paint: {
		"line-color": "#959595",
		"line-width": 2
	}
};

export const focusedParcelLayer = {
	id: "parcels-focused",
	type: "fill",
	paint: {
		"fill-color": "rgba(80, 162, 238, 0.35)"
	}
};

export const focusedOutlineParcelLayer = {
	id: "outlines-focused",
	type: "line",
	paint: {
		"line-color": "#50A2EE",
		"line-width": 2
	}
};

export const selectedParcelLayer = {
	id: "parcels-selected",
	type: "fill",
	paint: {
		"fill-color": "rgba(35, 185, 139, 0.35)"
	}
};

export const selectedOutlineParcelLayer = {
	id: "outlines-selected",
	type: "line",
	paint: {
		"line-color": "#23B98B",
		"line-width": 2
	}
};
