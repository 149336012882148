import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import * as excelGenerator from "../../../apis/excelGeneratorApi.js"
import * as excelGenerationFunctions from "../../../utilities/excelGeneration.js"
import * as protocolOptions from "../../../apis/protocolApi.js"
import { useAuth0 } from "../../../auth/auth0";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation.js";
import _, { } from "lodash";
import moment from "moment";
import * as entityApi from "../../../apis/entityApi.js";

enum ExportTypes {
	Universal = "Universal",
	Tabbed = "Tabbed",
	Standard = "Standard"
};

type Props = {
	id: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	name?: string;
	type: "Trial" | "Field" | "Protocol";
	isApAdmin: boolean;
};

const heatmapDownload: { id: string, includeSubplots: boolean, quantifiedRegion: string, isForAdmin: boolean, exclusions: boolean, isForCSV: boolean, currentDateTimeString: string } = {
	id: "",
	includeSubplots: false,
	quantifiedRegion: "",
	isForAdmin: false,
	exclusions: false,
	isForCSV: false,
	currentDateTimeString: JSON.stringify(new Date().toLocaleString())
};

const HeatmapExportModal: FC<Props> = ({ id, open, setOpen, name, type, isApAdmin }) => {
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const queryClient = useQueryClient();
	const getEntityNames = async (id) => {
		const accessToken = await getTokenSilently();
		const json = type === "Trial" ? entityApi.getEntityNamesAsync(undefined, undefined, id, undefined, accessToken) : type === "Field" ? entityApi.getEntityNamesAsync(undefined, id, undefined, undefined, accessToken) :
			entityApi.getEntityNamesAsync(undefined, undefined, undefined, id, accessToken);
		return json;
	};

	const { data: entityName, refetch: refetchEntityName } = useQuery({
		queryKey: ["heatmap export options", id],
		queryFn: () => getEntityNames(id),
		select: (data) => type === "Trial" ? data.trialName : type === "Field" ? data.fieldName : data.protocolName,
		enabled: !!id && !name && open
	});

	//Dropdowns
	const [fileFormatOptions, setFileFormatOptions] = useState([] as any[]);
	const [dataUnitOptions, setDataUnitOptions] = useState([] as any[]);
	const [filteredDataUnitOptions, setFilteredDataUnitOptions] = useState([] as any[]);
	const [quantifiedRegionOptions, setQuantifiedRegionOptions] = useState([] as any[]);
	const [filteredQuantifiedRegionOptions, setFilteredQuantifiedRegionOptions] = useState([] as any[]);
	const [dataContentOptions, setDataContentOptions] = useState([] as any[]);

	//Selected Values
	const [fileFormat, setFileFormat] = useState<string | undefined>(undefined);
	const [dataUnit, setDataUnit] = useState<string | undefined>(undefined);
	const [quantifiedRegion, setQuantifiedRegion] = useState<string | undefined>(undefined);
	const [dataContent, setDataContent] = useState<string | undefined>(undefined);

	const [localEntityName, setLocalEntityName] = useState(name);
	const [dashboardOptions, setDashboardOptions] = useState(null);


	useEffect(() => {
		setupFileFormatOptions();
		setupDataUnitOptions();
		setupQuantifiedRegionOptions();
		setupDataContentOptions();
	}, [isApAdmin]);

	useEffect(() => {
		if (id) {
			refetchEntityName();
		}
	}, [id]);

	useEffect(() => {
		if (entityName) {
			setLocalEntityName(entityName);
		}
	}, [entityName]);

	//Used to get trial ids for Protocol downloads
	useEffect(() => {
		if (type === "Protocol" && !dashboardOptions) {
			protocolDashboardOptionsMutate();
		}
	}, [type, id]);


	const setupFileFormatOptions = () => {
		let options = [{ key: "tabbedDataFrame", text: "Tabbed Data Frames (.xlsx)", value: "tabbedDataFrame", isForCSV: false, type: ExportTypes.Tabbed, exclusions: true, hidden: false, isForStackedDownload: true },
		{ key: "universalDataFrame", text: "Universal Data Frames (.csv)", value: "universalDataFrame", isForCSV: true, type: ExportTypes.Universal, exclusions: true, hidden: false, isForStackedDownload: false },
		{ key: "universalDataFrameExcel", text: "Universal Data Frames (.xlsx)", value: "universalDataFrameExcel", isForCSV: false, type: ExportTypes.Universal, exclusions: true, hidden: false, isForStackedDownload: false },
		{ key: "standardDataFrame", text: "Standard (.xlsx)", value: "standardDataFrame", isForCSV: false, type: ExportTypes.Standard, exclusions: true, hidden: false, isForStackedDownload: false },
		{ key: "standardNoExclusionsDataFrame", text: "Standard Without Exclusions (.xlsx)", value: "standardNoExclusionsDataFrame", isForCSV: false, type: ExportTypes.Standard, exclusions: false, hidden: false, isForStackedDownload: false }];

		setFileFormatOptions(_.filter(options, (o) => !o.hidden));
		setFileFormat(options[3].value);
	}

	const setupDataUnitOptions = () => {
		let options = [{ key: "plots", text: "Plots", value: "plots", hidden: false, includeSubplots: false },
		{ key: "subplots", text: "Subplots (Admin Only)", value: "subplots", hidden: !isApAdmin, includeSubplots: true }]

		setDataUnitOptions(_.filter(options, (o) => !o.hidden));
		setFilteredDataUnitOptions(_.filter(options, (o) => !o.hidden && o.value !== "subplots"));
		setDataUnit(options[0].value);
	}

	const setupQuantifiedRegionOptions = () => {
		let options = [{ key: "standard", text: "Default Qr", value: "standard", hidden: false },
		{ key: "all", text: "All Qrs", value: "all", hidden: false }];

		setQuantifiedRegionOptions(_.filter(options, (o) => !o.hidden));
		setFilteredQuantifiedRegionOptions(_.filter(options, (o) => !o.hidden && o.value !== "all"));
		setQuantifiedRegion(options[0].value);
	}

	const setupDataContentOptions = () => {
		let options = [{ key: "standard", text: "Standard Data", value: "standard", hidden: false, isForAdmin: false },
		{ key: "all", text: "All Data (Admin Only)", value: "all", hidden: !isApAdmin || type === "Field", isForAdmin: true }];

		setDataContentOptions(_.filter(options, (o) => !o.hidden));
		setDataContent(options[0].value);
	}

	const resetInputs = () => {
		setFileFormat(fileFormatOptions[2].value);
		setDataUnit(dataUnitOptions[0].value);
		setQuantifiedRegion(quantifiedRegionOptions[0].value);
		setDataContent(dataContentOptions[0].value);
	};

	const filterDataUnitOptions = (value) => {
		let match = fileFormatOptions.find(x => x.value == value);
		if (match.type == ExportTypes.Standard) {
			setFilteredDataUnitOptions(_.filter(dataUnitOptions, (o) => o.value !== "subplots"))
			setDataUnit(dataUnitOptions[0].value);
		}
		else {
			setFilteredDataUnitOptions(dataUnitOptions);
		}
	}

	const filterQuantifiedRegionOptions = (value) => {
		let match = fileFormatOptions.find(x => x.value == value);
		if (match.type == ExportTypes.Standard) {
			setFilteredQuantifiedRegionOptions(_.filter(quantifiedRegionOptions, (o) => o.value !== "all"))
			setQuantifiedRegion(quantifiedRegionOptions[0].value);
		}
		else {
			setFilteredQuantifiedRegionOptions(quantifiedRegionOptions);
		}
	}

	const generateStandardTrialHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateTrialHeatmapExcel(userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.id, vars.isForAdmin, vars.currentDateTimeString, vars.exclusions, accessToken);
	};

	const generateStandardProtocolHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateProtocolHeatmapExcel((dashboardOptions as any).trialIds, userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.isForAdmin, vars.currentDateTimeString, vars.exclusions, accessToken);
	};

	const generateStackedTrialHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateStackedTrialHeatmapExcel(userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.id, vars.isForAdmin, vars.currentDateTimeString, vars.exclusions, vars.quantifiedRegion, vars.includeSubplots, vars.isForCSV, accessToken);
	};

	const generateTabbedTrialHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateTabbedTrialHeatmapExcel(userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.id, vars.isForAdmin, vars.currentDateTimeString, vars.exclusions, vars.quantifiedRegion, vars.includeSubplots, vars.isForCSV, accessToken);
	};

	const generateStackedProtocolHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateStackedProtocolHeatmapExcel((dashboardOptions as any).trialIds, userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.isForAdmin, vars.currentDateTimeString, vars.exclusions, vars.quantifiedRegion, vars.includeSubplots, vars.isForCSV, accessToken);
	};

	const generateTabbedProtocolHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateTabbedProtocolHeatmapExcel((dashboardOptions as any).trialIds, userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.isForAdmin, vars.currentDateTimeString, vars.exclusions, vars.quantifiedRegion, vars.includeSubplots, vars.isForCSV, accessToken);
	};

	const generateStandardFieldHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateFieldHeatmapExcel(userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.id, vars.currentDateTimeString, vars.exclusions, accessToken);
	};

	const generateStackedFieldHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateStackedFieldHeatmapExcel(userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.id, vars.currentDateTimeString, vars.exclusions, vars.quantifiedRegion, vars.includeSubplots, vars.isForCSV, accessToken);
	};

	const generateTabbedFieldHeatmapExcel = async (vars: typeof heatmapDownload) => {
		const accessToken = await getTokenSilently();
		return excelGenerator.generateTabbedFieldHeatmapExcel(userAuth.currentClientId, (userAuth as any).currentClient.clientName, vars.id, vars.currentDateTimeString, vars.exclusions, vars.quantifiedRegion, vars.includeSubplots, vars.isForCSV, accessToken);
	};

	const getProtocolDashboardOptions = async () => {
		const accessToken = await getTokenSilently();
		return protocolOptions.getProtocolDashboardOptions(moduleNavigation.protocolId, userAuth.currentClientId, accessToken);
	};

	const generateStandardTrialHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateStandardTrialHeatmapExcel(vars),
			onSuccess: (data, { id, exclusions }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap standard export", id] });
				options?.onSuccess?.();
				let fileName = exclusions ? `${localEntityName}_StandardTrialData_${moment().format("YYYYMMDD_hhmma")}.xlsx` :
					`${localEntityName}_StandardTrialData_${moment().format("YYYYMMDD_hhmma")}_Without_Exclusions.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateStandardProtocolHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateStandardProtocolHeatmapExcel(vars),
			onSuccess: (data, { id, exclusions }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap protocol standard export", id] });
				options?.onSuccess?.();
				let fileName = `${localEntityName}_StandardProtocolData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;

				_.map(data, (trial) => {
					trial.trialName = `${trial.trialName}_${trial.cooperatorName}_StandardTrialData_${moment().format(
						"YYYYMMDD_hhmma"
					)}${!exclusions ? "_Without_Exclusions" : ""}.xlsx`;
				});

				if (!exclusions) {
					excelGenerationFunctions.downloadZippedExcelFile(
						data,
						fileName.split(".")[0] + "_Without_Exclusions.xlsx"
					);
				} else {
					excelGenerationFunctions.downloadZippedExcelFile(data, fileName);
				}
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateStackedTrialHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateStackedTrialHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap universal export", id] });
				options?.onSuccess?.();
				let fileName = heatmapDownload.isForCSV ? `${localEntityName}_UniversalTrialData_${moment().format("YYYYMMDD_hhmma")}.csv` : `${localEntityName}_UniversalTrialData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
				resetInputs();
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateTabbedTrialHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateTabbedTrialHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Heatmap download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap tabbed export", id] });
				options?.onSuccess?.();
				let fileName = heatmapDownload.isForCSV ? `${localEntityName}_TabbedTrialHeatmap_${moment().format("YYYYMMDD_hhmma")}.csv` : `${localEntityName}_TabbedTrialHeatmap_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
				resetInputs();
			},
			onError: (error) => {
				toast.error("Heatmap download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateStackedProtocolHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateStackedProtocolHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap protocol universal export", id] });
				options?.onSuccess?.();
				let fileName = heatmapDownload.isForCSV ? `${localEntityName}_UniversalProtocolData_${moment().format("YYYYMMDD_hhmma")}.csv` : `${localEntityName}_UniversalProtocolData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateTabbedProtocolHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateTabbedProtocolHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap protocol tabbed export", id] });
				options?.onSuccess?.();
				let fileName = `${localEntityName}_TabbedProtocolData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateStandardFieldHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateStandardFieldHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap standard field export", id] });
				options?.onSuccess?.();
				let fileName = `${localEntityName}_StandardFieldData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateStackedFieldHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateStackedFieldHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap universal field export", id] });
				options?.onSuccess?.();
				let fileName = heatmapDownload.isForCSV ? `${localEntityName}_UniversalFieldData_${moment().format("YYYYMMDD_hhmma")}.csv` : `${localEntityName}_UniversalFieldData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
				resetInputs();
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const generateTabbedFieldHeatmapExcelMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: typeof heatmapDownload) => generateTabbedFieldHeatmapExcel(vars),
			onSuccess: (data, { id }) => {
				toast.success("Data download was successful!");
				queryClient.invalidateQueries({ queryKey: ["heatmap tabbed field export", id] });
				options?.onSuccess?.();
				let fileName = `${localEntityName}_TabbedFieldData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(data, fileName, heatmapDownload.isForCSV);
				resetInputs();
			},
			onError: (error) => {
				toast.error("Data download failed.");
				console.error(error);
				options?.onError?.();
			}
		});

	const getProtocolDashboardOptionsMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: () => getProtocolDashboardOptions(),
			onSuccess: (data) => {
				queryClient.invalidateQueries({ queryKey: ["protocol dashboard options", id] });
				options?.onSuccess?.();
				setDashboardOptions(data.data);
			},
			onError: (error) => {
				toast.error("Failed to get protocol dashboard options.");
				console.error(error);
				options?.onError?.();
			}
		});

	const { mutate: stackedTrialMutate } = generateStackedTrialHeatmapExcelMutator();
	const { mutate: stackedProtocolMutate } = generateStackedProtocolHeatmapExcelMutator();
	const { mutate: standardTrialMutate } = generateStandardTrialHeatmapExcelMutator();
	const { mutate: tabbedTrialMutate } = generateTabbedTrialHeatmapExcelMutator();
	const { mutate: tabbedProtocolMutate } = generateTabbedProtocolHeatmapExcelMutator();
	const { mutate: standardProtocolMutate } = generateStandardProtocolHeatmapExcelMutator();
	const { mutate: stackedFieldMutate } = generateStackedFieldHeatmapExcelMutator();
	const { mutate: standardFieldMutate } = generateStandardFieldHeatmapExcelMutator();
	const { mutate: tabbedFieldMutate } = generateTabbedFieldHeatmapExcelMutator();
	const { mutate: protocolDashboardOptionsMutate } = getProtocolDashboardOptionsMutator();


	const exportData = () => {
		toast.info("Data Export Started");
		setOpen(false);
		let fileFormatMatch = fileFormatOptions.find(x => x.value == fileFormat);
		let dataUnitMatch = dataUnitOptions.find(x => x.value == dataUnit);
		let dataContentMatch = dataContentOptions.find(x => x.value == dataContent);
		let quantifiedRegionMatch = quantifiedRegionOptions.find(x => x.value == quantifiedRegion);

		heatmapDownload.id = id;
		heatmapDownload.includeSubplots = dataUnitMatch.includeSubplots;
		heatmapDownload.quantifiedRegion = quantifiedRegionMatch.value;
		heatmapDownload.isForAdmin = dataContentMatch.isForAdmin;
		heatmapDownload.exclusions = fileFormatMatch.exclusions;
		heatmapDownload.isForCSV = fileFormatMatch.isForCSV;

		if (type == "Trial") {
			if (fileFormatMatch.type == ExportTypes.Universal) {
				stackedTrialMutate(heatmapDownload);
			}
			else if (fileFormatMatch.type == ExportTypes.Standard) {
				standardTrialMutate(heatmapDownload);
			}
			else if (fileFormatMatch.type == ExportTypes.Tabbed) {
				tabbedTrialMutate(heatmapDownload);
			}
		}
		else if (type == "Field") {
			if (fileFormatMatch.type == ExportTypes.Universal) {
				stackedFieldMutate(heatmapDownload);
			}
			else if (fileFormatMatch.type == ExportTypes.Standard) {
				standardFieldMutate(heatmapDownload);
			}
			else if (fileFormatMatch.type == ExportTypes.Tabbed) {
				tabbedFieldMutate(heatmapDownload);
			}
		}
		else if (type == "Protocol") {
			if (fileFormatMatch.type == ExportTypes.Universal) {
				stackedProtocolMutate(heatmapDownload);
			}
			else if (fileFormatMatch.type == ExportTypes.Standard) {
				standardProtocolMutate(heatmapDownload);
			}
			else if (fileFormatMatch.type == ExportTypes.Tabbed) {
				tabbedProtocolMutate(heatmapDownload);
			}
		}
		return true;
	};


	return (
		<Modal open={open} style={{ width: "30%", maxHeight: "85%" }}>
			<Modal.Header>{`Download ${type} Data for ${localEntityName}`}</Modal.Header>
			<Modal.Content>
				<Form style={{ padding: "1em" }}>
					<div style={{ display: "flex", flexDirection: "column", paddingRight: "1em" }}>
						<Form.Field width={16} style={{ flexGrow: 1 }}>
							<Form.Select
								label="File Format"
								options={fileFormatOptions}
								value={fileFormat}
								onChange={(_e, { value }) => {
									setFileFormat(value as string);
									filterDataUnitOptions(value);
									filterQuantifiedRegionOptions(value);
								}}
							/>
						</Form.Field>
						<Form.Field width={16} style={{ flexGrow: 1 }}>
							<Form.Select
								label="Data Unit"
								options={filteredDataUnitOptions}
								value={dataUnit}
								onChange={(_e, { value }) => {
									setDataUnit(value as string);
								}}
							/>
						</Form.Field>
						<Form.Field width={16} style={{ flexGrow: 1 }}>
							<Form.Select
								label="Quantified Region"
								options={filteredQuantifiedRegionOptions}
								value={quantifiedRegion}
								onChange={(_e, { value }) => {
									setQuantifiedRegion(value as string);
								}}
							/>
						</Form.Field>
						<Form.Field width={16} style={{ flexGrow: 1 }}>
							<Form.Select
								label="Data Content"
								options={dataContentOptions}
								value={dataContent}
								onChange={(_e, { value }) => {
									setDataContent(value as string);
								}}
							/>
						</Form.Field>
					</div>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="Cancel"
					onClick={() => {
						setOpen(false);
						resetInputs();
					}}
				/>
				<Button primary content="Export" onClick={exportData} />
			</Modal.Actions>
		</Modal>
	);
};

export default HeatmapExportModal;