import React, { useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";
import FieldWizard from "../../Content/Fields/FieldWizard/index.js";
import * as ControlFunctions from "./orthoControlFunctions";
import * as fieldActions from "../../../redux/actions/fieldActions";

import { toast } from "react-toastify";
import { useAuth0 } from "../../../auth/auth0";
import { useDispatch, useSelector } from "react-redux";

const EditFieldModal = React.forwardRef(({ validateSelectedField, deselectField, alignPlotsRef, dropdownRef }, ref) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const [disabled, setDisabled] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [fieldId, setFieldId] = useState(null);

	EditFieldModal.displayName = "EditFieldModal";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	async function handleCancel() {
		toast.info("Checking for field changes...");
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getNewFieldInfo(fieldId, clientId, accessToken)).then((res) => {
			ControlFunctions.applyEditFieldChanges(res.plots, res.field, dropdownRef);

			deselectField();
			setDisabled(true);
			setModalOpen(false);

			if (alignPlotsRef?.current) {
				alignPlotsRef?.current?.setDisabled(true);
			}
		});
	}

	function handleSave(qrModify, qrDelete, finish) {
		//-- Handle modifying qrs on current flight
		if (qrModify?.length > 0 || qrDelete?.length > 0) {
			ControlFunctions.handleModifyQrFromFieldModal(fieldId, qrModify, qrDelete);
		}

		if (finish) {
			handleCancel();
		}
	}

	return (
		<>
			<Modal
				trigger={
					<Button
						icon
						compact
						disabled={disabled}
						onClick={() => {
							let validFieldId = validateSelectedField();

							if (validFieldId) {
								setFieldId(validFieldId);
								setModalOpen(true);
							}
						}}
					>
						<Icon size="large" name="pencil" />
						<span>Edit Field Info</span>
					</Button>
				}
				open={modalOpen}
				size="fullscreen"
			>
				<Modal.Header>Edit Field</Modal.Header>
				<Modal.Content>
					<FieldWizard
						activeStep="info"
						handleCancel={() => handleCancel()}
						handleSave={(qrModify, qrDelete, finish) => handleSave(qrModify, qrDelete, finish)}
						fromViewer={true}
						mode="edit"
						fieldIdFromViewer={fieldId}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
});

EditFieldModal.propTypes = {
	validateSelectedField: PropTypes.func.isRequired,
	deselectField: PropTypes.func.isRequired,
	alignPlotsRef: PropTypes.object,
	dropdownRef: PropTypes.object.isRequired
};

export default EditFieldModal;
