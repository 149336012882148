import { featureCollection, polygon } from "@turf/turf";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import * as farmActions from "../../../../redux/actions/farmActions.ts";
import ParcelPreviewViewer from "./ParcelPreviewViewer";
import "./styles.css";

const FarmSummary = ({ farmId, clientId, getTokenSilently }) => {
	const disaptch = useDispatch();
	const [farmInfo, setFarmInfo] = useState(null);
	const [previewCollection, setPreviewCollection] = useState(featureCollection());

	useEffect(() => {
		if (farmId !== null) {
			getSummaryInfo();
		}
	}, [farmId]);

	const getSummaryInfo = async () => {
		const accessToken = await getTokenSilently();
		const res = await disaptch(farmActions.getFarmSummary(accessToken, clientId, farmId));
		const info = res.data.info;
		const parcels = res.data.parcels;
		const parcelFeatures = _.map(parcels, (p) => {
			return JSON.parse(p.geoJSON);
		});

		setFarmInfo(info);
		const farmBounds = polygon([
			[
				[info.llLong, info.llLat],
				[info.ulLong, info.ulLat],
				[info.urLong, info.urLat],
				[info.lrLong, info.lrLat],
				[info.llLong, info.llLat]
			]
		]);

		farmBounds.properties.isBounds = true;
		setPreviewCollection(featureCollection([...parcelFeatures, farmBounds]));
	};

	return (
		<Grid className="summaryGrid" columns="equal">
			<Grid.Column width={8}>
				{farmInfo ? (
					<>
						<Grid.Row>
							<div className="title">{farmInfo.name} - Farm Summary</div>
							<div className="abbreviation">{farmInfo.abbreviation}</div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader">Address</div>
							<div className="sectionBody">
								{farmInfo.address1}, {farmInfo.city}, {farmInfo.stateName} {farmInfo.zipCode}
							</div>
							{farmInfo.address2 && <div>{farmInfo.address2}</div>}
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader">Cooperator Company</div>
							<div className="sectionBody">{farmInfo.companyName}</div>
							<div className="sectionBody">{farmInfo.companyCode}</div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader">Farm Info</div>
							<div className="sectionBody">
								<span style={{ fontWeight: 600 }}>Land Owner:</span> {farmInfo.landOwner}
							</div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader">Notes</div>
							<div className="sectionBody">{farmInfo.notes}</div>
						</Grid.Row>
					</>
				) : (
					<>
						<Grid.Row>
							<div className="title placeholder"></div>
							<div className="abbreviation placeholder"></div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader placeholder"></div>
							<div className="sectionBody placeholder"></div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader placeholder"></div>
							<div className="sectionBody placeholder"></div>
							<div className="sectionBody placeholder"></div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader placeholder"></div>
							<div className="sectionBody placeholder"></div>
						</Grid.Row>
						<Grid.Row>
							<div className="sectionHeader placeholder"></div>
							<div className="sectionBody placeholder notes"></div>
						</Grid.Row>
					</>
				)}
			</Grid.Column>
			<Grid.Column width={8}>
				<ParcelPreviewViewer parcelList={previewCollection} />
			</Grid.Column>
		</Grid>
	);
};

FarmSummary.propTypes = {
	farmId: PropTypes.string,
	clientId: PropTypes.string,
	getTokenSilently: PropTypes.func
};

export default FarmSummary;
