import * as types from "./actionTypes";
import * as mapboxSearchApi from "../../apis/mapboxSearchApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function getSearchSuggestionsSuccess() {
	return { type: types.GET_SEARCH_SUGGESTIONS_SUCCESS };
}

export function retrieveFromSearchSuggestionSuccess() {
	return { type: types.RETRIEVE_FROM_SEARCH_SUGGESTION_SUCCESS };
}

export function getForwardGeocodeSuccess() {
	return { type: types.GET_FORWARD_GEOCODE_SUCCESS };
}

export function getSearchSuggestions(query, userId, options = {}) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return mapboxSearchApi
				.getSearchSuggestions(query, userId, options)
				.then((res) => {
					if (!res) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getSearchSuggestionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function retrieveFromSearchSuggestion(suggestionId, userId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return mapboxSearchApi
				.retrieveFromSearchSuggestion(suggestionId, userId)
				.then((res) => {
					if (!res) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(retrieveFromSearchSuggestionSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getForwardGeocode(query, options) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return mapboxSearchApi
				.getForwardGeocode(query, options)
				.then((res) => {
					if (!res) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getForwardGeocodeSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
