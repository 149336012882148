import React, { useState } from "react";

import { Segment, Accordion, Icon, Button } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";

const Support = () => {
	const { isAuthenticated } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	const [activeIndex, setActiveIndex] = useState(null);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;
		setActiveIndex(newIndex);
	};

	return (
		<Segment style={{ margin: 15 }}>
			<p>
				If you have any questions that cannot be answered by the FAQs below or require assistance, please contact
				aerialPLOT support at <a href="mailto:support@aerialplot.com">support@aerialplot.com</a>
			</p>

			<Accordion>
				<Accordion.Title
					active={activeIndex === 0}
					index={0}
					onClick={handleClick}
					style={{ backgroundColor: "#EEEEEE", borderRadius: "4px" }}
				>
					<Icon name="dropdown" />
					How do I reset my password?
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 0}>
					<p>
						aerialPLOT uses Microsoft and Google authentication to allow users to log into the system. We do not store
						passwords. If you have forgotten your password, please refer to these articles.
					</p>
					<ul>
						<li>
							<a
								href="https://support.microsoft.com/en-us/help/4026971/microsoft-account-how-to-reset-your-password"
								rel="noopener noreferrer"
								target="_blank"
							>
								How to reset your Microsoft account password
							</a>
						</li>
						<li>
							<a
								href="https://support.microsoft.com/en-us/help/17875/microsoft-account-reset-or-recover-password"
								rel="noopener noreferrer"
								target="_blank"
							>
								Reset or recover your Microsoft account
							</a>
						</li>
						<li>
							<a
								href="https://support.google.com/accounts/answer/41078?co=GENIE.Platform%3DDesktop&hl=en"
								rel="noopener noreferrer"
								target="_blank"
							>
								How to reset your Google account password
							</a>
						</li>
						<li>
							<a
								href="https://accounts.google.com/signin/v2/recoveryidentifier?flowName=GlifWebSignIn&flowEntry=ServiceLogin"
								rel="noopener noreferrer"
								target="_blank"
							>
								Reset or recover your Google account
							</a>
						</li>
					</ul>
					<p>
						{`If you're using an account issued to you by your work, school, or organization, please contact your IT helpdesk.`}
					</p>
				</Accordion.Content>
			</Accordion>

			{!isAuthenticated && (
				<Segment basic textAlign="center">
					<Button
						content="Return to Login"
						onClick={() => {
							moduleNavigation.goToLogin();
						}}
					/>
				</Segment>
			)}
		</Segment>
	);
};

export default Support;
