import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Segment, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as fieldActions from "../../../../redux/actions/fieldActions";

import FieldOutlineTable from "./FieldOutlineTable";

const FieldOutline = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Query results
	const [fieldOutlineData, setFieldOutlineData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (clientId) {
			getFieldOutlineData(clientId);
		}
	}, [clientId]);

	async function getFieldOutlineData(clientId) {
		if (moduleNavigation.fieldId) {
			const accessToken = await getTokenSilently();
			setLoading(true);
			dispatch(fieldActions.getFieldOutlineData(clientId, moduleNavigation.fieldId, accessToken))
				.then((res) => {
					setFieldOutlineData(res);
					setLoading(false);
				})
				.catch((err) => {
					toast.error("Error loading Field Outline. Please try again.");
					setLoading(false);
					console.log(err);
				});
		}
	}

	return loading ? (
		<Loader active />
	) : !loading && (!fieldOutlineData || fieldOutlineData.length === 0) ? (
		<Segment style={{ margin: 15 }}>
			<p>{"Outline data for this field could not be found."}</p>
		</Segment>
	) : (
		<Segment basic>
			<FieldOutlineTable fieldOutlineData={fieldOutlineData} />
		</Segment>
	);
};

export default FieldOutline;
