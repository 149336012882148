import React from "react";
import { Segment } from "semantic-ui-react";

const AccessDenied = () => {
	return (
		<Segment basic textAlign="center">
			<h1>aerialPLOT Access Denied</h1>
			<br />
			<p>
				{`You do not have access to this data. Please contact aerialPLOT support at`}{" "}
				<a href="mailto:support@aerialplot.com">support@aerialplot.com</a>
			</p>
			<br />
		</Segment>
	);
};

export default AccessDenied;
