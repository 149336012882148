import _ from "lodash";
import moment from "moment";
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Icon, Loader, Segment, SemanticICONS, Table } from "semantic-ui-react";
import "./table.css";
import { AnyFilter, DateFilter, StringFilter } from "./types";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { ApplicationArea } from "../ApplicationAreas";

interface TableProps<T> {
	data: T[];
	// setData: Dispatch<React.SetStateAction<T[]>>;
	headers: {
		[key in keyof T]?: string;
	};
	onRenderDataColumn?: {
		[key in keyof T]?: (col: T[key]) => string | string[] | undefined;
	};
	onSortColumn?: {
		[key in keyof T]?: ((a: T[key], b: T[key]) => number) | boolean;
	};
	columnFilters?: {
		[key in keyof T]?: AnyFilter;
	};
	links?: {
		[key in keyof T]?: (row: T) => string;
	};
	actions?: { icon: SemanticICONS; subIcon?: SemanticICONS; action: (row: T) => string }[];
	level: "flight" | "farm" | "field" | "trial" | "protocol";
	loading: boolean;
	searchTerm?: [string, keyof T];
}

const ResultsTable = <T,>({
	data,
	// setData,
	headers,
	onRenderDataColumn,
	onSortColumn,
	columnFilters,
	links,
	actions,
	level,
	loading = false,
	searchTerm
}: TableProps<T>): React.ReactElement => {
	const paginationOptions = [
		{ text: "10", value: 10, key: "10" },
		{ text: "25", value: 25, key: "25" },
		{ text: "50", value: 50, key: "50" },
		{ text: "100", value: 100, key: "100" }
	];

	const [sortDirection, setSortDirection] = useState<"descending" | "ascending">("descending");
	const [sortedColumn, setSortedColumn] = useState<keyof T | "">("");
	const [queriedData, setQueriedData] = useState<T[]>(data);
	const [sortedData, setSortedData] = useState<T[]>(data);
	const [filteredData, setFilteredData] = useState<T[]>(data);
	const [pageSize, setPageSize] = useState(25);
	const [pageNumber, setPageNumber] = useState(1);

	const userAuth = useUserAuth();

	const maxPage = useMemo(() => Math.ceil(filteredData.length / pageSize), [filteredData, pageSize]);
	const paginatedData = useMemo(
		() => filteredData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize),
		[filteredData, pageSize, pageNumber]
	);

	const pages = useMemo(() => {
		let length = 5;
		let split = 2;

		if (pageNumber - 2 > 1) {
			//showing "1 ..."
			length -= 1;
			split = 1;
		}

		if (pageNumber + 2 < maxPage) {
			//showing "... {maxPage}"
			length -= 1;
		}

		return { length, split };
	}, [maxPage, pageNumber]);

	useEffect(() => {
		if (maxPage < pageNumber) {
			setPageNumber(Math.max(maxPage, 1));
		}
	}, [maxPage]);

	useEffect(() => {
		if (searchTerm) {
			const [term, column] = searchTerm;
			if (term) {
				setQueriedData(() => {
					const re = RegExp(_.escapeRegExp(searchTerm?.[0]), "i");
					const queried = _.filter(data, (r) => re.test(r[column] as string));
					return queried;
				});
			} else {
				setQueriedData(data);
			}
		} else {
			setQueriedData(data);
		}
	}, [data, searchTerm]);

	useEffect(() => {
		if (sortedColumn !== "" && onSortColumn !== undefined) {
			const compareFn = onSortColumn[sortedColumn];

			if (typeof compareFn !== "function") {
				setSortedData(() => {
					const sortableRows = _.filter(queriedData, (r) => r[sortedColumn] !== null);
					const nullRows = _.filter(queriedData, (r) => r[sortedColumn] === null);
					let sorted = _.sortBy(sortableRows, sortedColumn);
					if (sortDirection === "ascending") {
						sorted.reverse();
					}
					sorted = sorted.concat(...nullRows);
					return sorted;
				});
			} else {
				setSortedData(() => {
					const sortableRows = _.filter(queriedData, (r) => r[sortedColumn] !== null);
					const nullRows = _.filter(queriedData, (r) => r[sortedColumn] === null);
					let sorted = sortableRows.sort((a, b) => {
						return compareFn(a[sortedColumn], b[sortedColumn]);
					});
					if (sortDirection === "ascending") {
						sorted.reverse();
					}
					sorted = sorted.concat(...nullRows);
					return sorted;
				});
			}
		} else {
			setSortedData(queriedData);
		}
	}, [sortedColumn, sortDirection, queriedData]);

	useEffect(() => {
		if (columnFilters) {
			filterColumns();
		} else {
			// case when no "live" filters
			setFilteredData(sortedData);
		}
	}, [sortedData, columnFilters]);

	const filterColumns = useCallback(() => {
		if (!columnFilters) return;
		const columnsToFilter = Object.keys(columnFilters);
		if (columnsToFilter.length < 1) return;
		const filtered = _.filter(sortedData, (row) => {
			const shouldShow = _.reduce(
				Object.entries(columnFilters),
				(acc, [col, filter]) => {
					if ((filter as AnyFilter).type === "string") {
						const names = (filter as StringFilter).names;
						const point = row[col];
						if (Array.isArray(point)) {
							acc = acc && names.some((r) => point.includes(r));
						} else {
							acc = acc && names.includes(point);
						}
					}

					if ((filter as AnyFilter).type === "date") {
						const startDate = (filter as DateFilter).startDate;
						const endDate = (filter as DateFilter).endDate;
						acc = acc && moment(row[col]).isBetween(startDate, endDate);
					}

					return acc;
				},
				true
			);
			return shouldShow;
		});

		setFilteredData(filtered);
	}, [sortedData, columnFilters]);

	const changeSort = useCallback(
		(prop) => {
			if (sortedColumn === prop) {
				setSortDirection((prev) => {
					if (prev === "descending") return "ascending";
					else return "descending";
				});
			} else {
				setSortedColumn(prop);
				setSortDirection("descending");
			}
		},
		[sortedColumn, sortDirection]
	);

	return (
		<>
			<Segment className="tableContainer" basic>
				{loading ? (
					<div style={{ height: "100%", width: "100%", display: "flex" }}>
						{" "}
						<Loader active />{" "}
					</div>
				) : (
					<Table className="resultsTable">
						<Table.Header>
							<Table.Row>
								{_.map(Object.entries<string | undefined>(headers), ([prop, display]) =>
									onSortColumn && onSortColumn[prop] ? (
										<Table.HeaderCell
											className="sortable"
											sorted={sortedColumn === prop ? sortDirection : undefined}
											onClick={() => {
												changeSort(prop);
											}}
										>
											<span>{display}</span>
											{columnFilters && columnFilters[prop]?.applied && (
												<Icon name="filter" className="headerFilterIcon" size="small" />
											)}
										</Table.HeaderCell>
									) : (
										<Table.HeaderCell>
											<span>{display}</span>
											{columnFilters && columnFilters[prop]?.applied && (
												<Icon name="filter" className="headerFilterIcon" size="small" />
											)}
										</Table.HeaderCell>
									)
								)}
								<Table.HeaderCell>Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(paginatedData, (row) => {
								// map each row
								return (
									<Table.Row
										style={{
											backgroundColor:
												row["error"] && row["farmClientId"] === userAuth.currentClientId
													? "var(--accent-negative-UI-200)"
													: "unset"
										}}
									>
										{_.map(Object.keys(headers), (th) => (
											<Table.Cell>
												{(() => {
													const col = row[th];
													let textToRender: string | string[];

													if (onRenderDataColumn && onRenderDataColumn[th]) {
														textToRender = onRenderDataColumn[th](col) ?? "-";
													} else {
														textToRender = col ?? "-";
													}

													const hasLink = !!links && !!links[th];

													if (hasLink) {
														if (Array.isArray(textToRender)) {
															return _.map(textToRender, (ttr, i) => (
																<Link to={links[th](col[i])} target="_blank">
																	{ttr}
																</Link>
															));
														} else {
															return (
																<Link to={links[th](row)} target="_blank">
																	{textToRender}
																</Link>
															);
														}
													} else {
														if (Array.isArray(textToRender)) {
															if (textToRender.length > 5) {
																return (
																	<span>
																		{textToRender.sort().slice(0, 5).join(", ")}{" "}
																		<span title={textToRender.sort().join(", ")}>...</span>
																	</span>
																);
															} else {
																return textToRender.sort().join(", ");
															}
														}
														return textToRender;
													}
												})()}
											</Table.Cell>
										))}
										<Table.Cell>
											{_.map(actions, ({ icon, subIcon, action }) =>
												(!userAuth.isApAdmin &&
													level === "farm" &&
													icon == "pencil" &&
													row["farmClientId"] !== userAuth.currentClientId) ||
												(level === "farm" &&
													icon == "pencil" &&
													!userAuth.hasApplicationArea(ApplicationArea.FarmEdit, userAuth.currentClientId)) ? (
													<Icon name={icon} disabled />
												) : (
													<Link to={action(row)} target="_blank">
														{subIcon !== undefined ? (
															<Icon.Group>
																<Icon name={icon} corner="top left" style={{ fontSize: 14, textShadow: "unset" }} />
																<Icon name={subIcon} corner="bottom right" style={{ fontSize: 9 }} />
															</Icon.Group>
														) : (
															<Icon name={icon} />
														)}
													</Link>
												)
											)}
										</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
						{/* <Table.Footer>
							<Table.Row>
								<Table.Cell colSpan={Object.keys(headers).length + 1}>
									<Button icon="arrow left" />
									<Button icon="arrow right" />
								</Table.Cell>
							</Table.Row>
						</Table.Footer> */}
					</Table>
				)}
			</Segment>
			<div className="pagination">
				<span>
					{filteredData.length} {level}
					{filteredData.length !== 1 ? "s" : ""} found
				</span>
				<Button icon="previous" disabled={pageNumber <= 1} onClick={() => setPageNumber(1)} />
				<Button icon="arrow left" disabled={pageNumber <= 1} onClick={() => setPageNumber((prev) => prev - 1)} />
				<span style={{ fontWeight: 600, userSelect: "none" }}>
					{maxPage > 5 ? (
						<>
							{pageNumber - 2 > 1 && (
								<>
									<span className="pageNumber" onClick={() => setPageNumber(1)}>
										1
									</span>
									<span>{" ... "}</span>
								</>
							)}
							{Array.from(
								{ length: pages.length },
								(_v, k) => _.clamp(pageNumber - pages.split, 1, maxPage - (pages.length - 1))! + k
							).map((n, i) => (
								<>
									<span className={"pageNumber" + (pageNumber === n ? " active" : "")} onClick={() => setPageNumber(n)}>
										{n}
									</span>
									{n !== maxPage && i !== pages.length - 1 && <span style={{ cursor: "default" }}>{", "}</span>}
								</>
							))}
							{pageNumber + 2 < maxPage && (
								<>
									<span>{" ... "}</span>
									<span className="pageNumber" onClick={() => setPageNumber(maxPage)}>
										{maxPage}
									</span>
								</>
							)}
						</>
					) : (
						<>
							{Array.from({ length: maxPage }, (_v, k) => k + 1).map((n, i) => (
								<>
									<span className={"pageNumber" + (pageNumber === n ? " active" : "")} onClick={() => setPageNumber(n)}>
										{n}
									</span>
									{n !== maxPage && i !== maxPage - 1 && <span style={{ cursor: "default" }}>{", "}</span>}
								</>
							))}
						</>
					)}
				</span>
				<Button icon="arrow right" disabled={pageNumber >= maxPage} onClick={() => setPageNumber((prev) => prev + 1)} />
				<Button icon="next" disabled={pageNumber >= maxPage} onClick={() => setPageNumber(maxPage)} />
				<Dropdown
					selection
					fluid
					className="pageSizeDropdown"
					options={paginationOptions}
					defaultValue={25}
					onChange={(_, { value }) => {
						setPageSize(value as number);
						setPageNumber(1);
					}}
				/>
			</div>
		</>
	);
};

export default ResultsTable;
