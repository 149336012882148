import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Grid, Icon, Loader, Popup, Segment } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import _ from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import * as protocolApi from "../../../../apis/protocolApi";
import DataAnalysisCriteria from "../../../Lumber/DataAnalysisCriteria";
import AnovaTable from "./AnovaTable";
import ProtocolBoxWhiskerChart from "./ProtocolBoxWhiskerChart";
import ProtocolDataNormalizationTable from "./ProtocolDataNormalizationTable";
import ProtocolSiteSpecificTable from "./ProtocolSiteSpecificTable";
import ProtocolTimecourseSiteSpecificTable from "./ProtocolTimecourseSiteSpecificTable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MetaTagsTable from "./MetaTagsTable.tsx";

const ProtocolBoxWhisker = ({
	assessmentOptions,
	plannedTimingOptions,
	dataNormalizationOptions,
	meanComparisonOptions,
	alphaOptions,
	trialIds,
	treatmentData,
	defaultMeanComparisonOption,
	defaultAlphaOption,
	toggleMetaFactorModal,
	setIsDirty,
	setSaveAction
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const getProtocolTrialFilters = async (clientId) => {
		const accessToken = await getTokenSilently();
		const json = protocolApi.getProtocolTrialFilters(clientId, accessToken);
		return json;
	};

	const { isLoading: trialFiltersLoading, data: protocolTrialFilters } = useQuery({
		queryKey: ["protocol trial filters", userAuth.currentClientId],
		queryFn: () => getProtocolTrialFilters(userAuth.currentClientId),
		select: (data) => data.data
	});

	const updateTrialsMetaTags = async (updateInfo) => {
		const accessToken = await getTokenSilently();
		return protocolApi.updateTrialsMetaTags(updateInfo, userAuth.currentClientId, accessToken);
	};

	const { mutate: updateMetaTags } = useMutation({
		mutationFn: (updateInfo) => updateTrialsMetaTags(updateInfo),
		onSuccess: () => {
			toast.success("Meta Tags updated successfully!");
			queryClient.invalidateQueries({ queryKey: ["meta factors", userAuth.currentClientId] });
		},
		onError: (error) => {
			toast.error("Meta Tags failed to update.");
			console.error(error);
		}
	});

	//-- Dropdown Options
	const [filteredDataNormalizationOptions, setFilteredDataNormalizationOptions] = useState(null);
	const [selectedAssessmentOption, setSelectedAssessmentOptions] = useState(assessmentOptions[0]?.key);
	const [selectedPlannedTimingOption, setSelectedPlannedTimingOptions] = useState(plannedTimingOptions[0]?.key);
	const [selectedDataNormalizationOption, setSelectedDataNormalizationOption] = useState(
		_.find(dataNormalizationOptions, { text: "% MEAN" })?.key ?? dataNormalizationOptions[0]?.key
	);
	const [selectedMeanComparisonOption, setSelectedMeanComparisonOption] = useState(defaultMeanComparisonOption);
	const [selectedAlphaOption, setSelectedAlphaOption] = useState(defaultAlphaOption);

	//-- Data Sources
	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));
	const [rawTrialData, setRawTrialData] = useState(null);
	const [trialPlotSpecificData, setTrialPlotSpecificData] = useState(null);
	const [siteSpecificData, setSiteSpecificData] = useState(null);
	const [compiledData, setCompiledData] = useState(null);
	const [compiledMeanComparisonData, setCompiledMeanComparisonData] = useState([]);
	const [compiledTrialMeanComparisonData, setCompiledTrialMeanComparisonData] = useState([]);
	const [anovaData, setAnovaData] = useState(null);
	const [singleTrialAnovaValues, setSingleTrialAnovaValues] = useState(null);
	const [trialData, setTrialData] = useState(null);
	const [filteredTrialPlotSpecificData, setFilteredTrialPlotSpecificData] = useState(null);
	const [filteredSiteSpecificData, setFilteredSiteSpecificData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [metadataLoading, setMetadataLoading] = useState(true);
	const [treatmentsChecked, setTreatmentsChecked] = useState(null);
	const [trialsChecked, setTrialsChecked] = useState(null);
	const [initTrialsChecked, setInitTrialsChecked] = useState({});
	const [showAveragesChecked, setShowAveragesChecked] = useState(true);
	const [tukeyRunning, setTukeyRunning] = useState(false);
	const [firstTimeLoad, setFirstTimeLoad] = useState(true);
	const [showTwoWayAnova, setShowTwoWayAnova] = useState(false);
	const [hideMeanComparisonResults, setHideMeanComparisonResults] = useState(false);
	const [hideLetters, setHideLetters] = useState(false);
	const [disableColors, setDisableColors] = useState(false);
	const [initialGroupTrialDataCheckedCount, setInitialGroupTrialDataCheckedCount] = useState([]);
	const [hideUncheckedTrialData, setHideUncheckedTrialData] = useState(false);
	const [needToRerunStats, setNeedToRerunStats] = useState(false);
	const [needToRerunNormalization, setNeedToRerunNormalization] = useState(false);
	const [oneTrialDatasetError, setOneTrialDatasetError] = useState(false);
	const [multiTrialDatasetError, setMultiTrialDatasetError] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [trialFilter, setTrialFilter] = useState({
		value: "",
		filters: {
			quality: [],
			status: [],
			response: []
		}
	});

	const trialFitlerOptions = useMemo(() => {
		const opts =
			protocolTrialFilters?.map((opt) => ({
				key: opt.id,
				text: opt.name,
				filters: {
					quality: opt.trialDataQualityIds,
					status: opt.trialExclusionStatusIds,
					response: opt.trialSiteResponseIds
				},
				value: opt.id
			})) ?? [];
		opts.push({
			key: "custom",
			text: "Custom",
			filters: {},
			value: "custom"
		});

		return opts;
	}, [protocolTrialFilters]);

	//Data Analysis Critera
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	const trialInfo = useMemo(
		() =>
			_.sortBy(
				_.map(
					_.filter(trialData, (td) =>
						_.map(_.uniqBy(filteredTrialPlotSpecificData, "trialId"), "trialId").includes(td.trialInfo.trialId)
					),
					(td) => td.trialInfo
				),
				["farmState", "farmCity", "trialName", "cooperatorName", "trialId"]
			),
		[trialData, filteredTrialPlotSpecificData]
	);

	useEffect(() => {
		if (trialData && trialFilter?.value && trialFilter.value !== "custom") {
			for (let { trialInfo } of trialData) {
				if (
					!trialFilter.filters.status.includes(trialInfo.trialExclusionStatusId) &&
					!trialFilter.filters.response.includes(trialInfo.trialSiteResponseId) &&
					!trialFilter.filters.quality.includes(trialInfo.trialDataQualityId)
				) {
					updateTrialsChecked(true, trialInfo.trialId, true);
				} else {
					updateTrialsChecked(false, trialInfo.trialId, true);
				}
			}
		}
	}, [trialFilter, trialData, initTrialsChecked, tukeyRunning]);

	useEffect(() => {
		updateCheckedSiteData();
		updateCheckedTrialPlotSpecificData();
	}, [trialsChecked]);

	useEffect(() => {
		if (!firstTimeLoad) {
			updateCheckedSiteData();
			updateCheckedTrialPlotSpecificData();
		}
	}, [siteSpecificData]);

	useEffect(() => {
		if (assessmentOptions.length > 0 && trialData) {
			const isCurveModel =
				_.find(assessmentOptions, (ao) => {
					return ao.key === selectedAssessmentOption;
				}).iscurvemodel === String(true);
			const isStandCount =
				_.find(assessmentOptions, (ao) => {
					return ao.key === selectedAssessmentOption;
				}).isStandCount === String(true);
			if ((selectedAssessmentOption && selectedPlannedTimingOption) || isCurveModel || isStandCount) {
				setInitTrialsChecked({});
				if (firstTimeLoad) {
					getAnalysisResultsForTrial();
				} else {
					updateMeanComparisonData();
				}
			} else {
				setLoading(false);
			}
		}
	}, [selectedAssessmentOption, selectedPlannedTimingOption, trialData]);

	useEffect(() => {
		if (
			rawTrialData &&
			trialData &&
			reduxProtocolData &&
			selectedDataNormalizationOption &&
			trialFitlerOptions.length
		) {
			calculateSiteData();

			if (firstTimeLoad) {
				initializeTreatmentsChecked();
				initializeTrialsChecked();
			}
		}
	}, [rawTrialData, trialData, reduxProtocolData, trialFitlerOptions]);

	useEffect(() => {
		if (siteSpecificData?.length > 0) {
			updateCompiledData(true);
		}
	}, [siteSpecificData]);

	useEffect(() => {
		if (filteredSiteSpecificData?.length > 0) {
			updateCompiledData(false);
		}
	}, [filteredSiteSpecificData]);

	useEffect(() => {
		if (reduxProtocolData) {
			if (!defaultMeanComparisonOption) {
				setSelectedMeanComparisonOption(reduxProtocolData?.meanComparisonId ?? meanComparisonOptions[0]?.key);
			}

			if (!defaultAlphaOption) {
				setSelectedAlphaOption(reduxProtocolData?.alphaId ?? alphaOptions[0]?.key);
			}

			if (!trialData) {
				getTrialData();
			}
		}
	}, [reduxProtocolData]);

	useEffect(() => {
		if (compiledData && compiledData.length > 0 && firstTimeLoad === true) {
			updateMeanComparisonData();
			setFirstTimeLoad(false);
		}
	}, [compiledData]);

	function initializeTreatmentsChecked() {
		if (!_.some(treatmentsChecked, (tc) => tc.treatmentChecked)) {
			const tChecked = _.map(treatmentData, (td) => {
				return { trialTreatmentId: td.trialTreatmentId, treatmentChecked: true, dirty: false };
			});
			setTreatmentsChecked(tChecked);
		}
	}

	function initializeTrialsChecked() {
		const tChecked = _.map(trialIds, (t) => {
			return { trialId: t, trialChecked: true };
		});
		setTrialsChecked(tChecked);
		setTrialFilter(trialFitlerOptions.find((opt) => opt.text === "Remove Excluded Trials"));
	}

	useEffect(() => {
		if (dataNormalizationOptions && treatmentData) {
			const filteredValues = _.filter(dataNormalizationOptions, (dno) => {
				const check =
					dno.treatmenttypeid === null ||
					_.some(treatmentData, (td) => {
						return dno.treatmenttypeid == td.treatmentTypeId;
					});
				return check;
			});
			setFilteredDataNormalizationOptions(filteredValues);
		}
	}, [dataNormalizationOptions, treatmentData]);

	function calculateSiteData() {
		let siteData = [];
		let plotObjects = [];
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === selectedAssessmentOption;
		});

		_.map(rawTrialData, (td) => {
			const dataNormalization = getDataNormalizationModifier(td);
			let cleanTrialData = [];

			for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
				var trialTreatmentData = _.filter(td, (trial) => {
					return (
						trial.trialTreatmentId === i &&
						trial.excluded !== true &&
						(trial.groundDatasetAssessmentValue !== null ||
							trial.curveModelAnalysisValue !== null ||
							trial.standCountAnalysisResultValue !== null)
					);
				});

				var trialTreatmentPlotData = _.map(
					_.filter(td, (trial) => {
						return (
							trial.trialTreatmentId === i &&
							trial.excluded !== true &&
							(trial.groundDatasetAssessmentValue !== null ||
								trial.curveModelAnalysisValue !== null ||
								trial.standCountAnalysisResultValue !== null)
						);
					}),
					(d) => {
						return {
							plotReplicate: d.plotReplicate,
							plotId: d.plotId,
							plotName: d.plotName,
							fieldId: d.fieldId,
							analysisValue:
								((assessment.isStandCount === String(true)
									? d.standCountAnalysisResultValue
									: assessment.isgrounddata == String(true)
									? d.groundDatasetAssessmentValue
									: d.curveModelAnalysisValue) /
									dataNormalization) *
								getDataNormalizationMultiplier(),
							trialTreatmentId: d.trialTreatmentId,
							assessmentId:
								assessment.isStandCount == String(true)
									? d.analysisId
									: assessment.isgrounddata == String(true)
									? d.assessmentId
									: d.curveModelName,
							trialId: d.trialId,
							dataNormalizationId: selectedDataNormalizationOption,
							excludeFromAssessment: d.excludeFromAssessment,
							isOutlier3: d.isOutlier3,
							isOutlier1_5: d.isOutlier1_5
						};
					}
				);

				if (td?.length > 0) {
					_.map(trialTreatmentData, (t) => {
						let metadata = _.find(trialData, (tmd) => tmd.trialId === t.trialId)?.trialInfo;
						let data =
							assessment.isStandCount === String(true)
								? t.standCountAnalysisResultValue
								: assessment.isgrounddata == String(true)
								? t.groundDatasetAssessmentValue
								: t.curveModelAnalysisValue;

						let plotObject = {
							trialId: t.trialId,
							trialName: t.trialName,
							plotReplicate: t.plotReplicate,
							plotName: t.plotName,
							trialTreatmentId: i,
							average: (data / dataNormalization) * getDataNormalizationMultiplier(),
							excluded: false,
							stateName: t.stateName,
							city: t.city,
							cooperatorName: metadata?.cooperatorName,
							//--TODO need to update this based on previous selection
							trialChecked: true,
							treatmentChecked: true,
							excludeFromAssessment: t.excludeFromAssessment,
							isOutlier3: t.isOutlier3,
							isOutlier1_5: t.isOutlier1_5
						};

						plotObjects.push(plotObject);
					});
					let metadata = _.find(trialData, (tmd) => tmd.trialId === td[0].trialId)?.trialInfo;
					let siteObject = {
						trialId: td[0].trialId,
						trialName: td[0].trialName,
						trialTreatmentId: i,
						average: 0,
						excluded: true,
						stateName: td[0].stateName,
						city: td[0].city,
						cooperatorName: metadata?.cooperatorName,
						trialChecked:
							_.find(trialsChecked, (tc) => {
								return tc.trialId === td[0].trialId;
							})?.trialChecked ?? true,
						treatmentChecked:
							_.find(treatmentsChecked, (tc) => {
								return tc.trialTreatmentId === i;
							})?.treatmentChecked ?? true,
						plots: trialTreatmentPlotData,
						assessmentName: td[0].assessmentName ?? td[0].curveModelName ?? td[0].analysisName,
						assessmentId:
							assessment.isStandCount == String(true)
								? td[0].analysisId
								: assessment.isgrounddata == String(true)
								? td[0].assessmentId
								: td[0].curveModelName
					};
					if (trialTreatmentData.length > 0) {
						trialTreatmentData = _.map(trialTreatmentData, (t) => {
							let data =
								assessment.isStandCount == String(true)
									? t.standCountAnalysisResultValue
									: assessment.isgrounddata == String(true)
									? t.groundDatasetAssessmentValue
									: t.curveModelAnalysisValue;

							return (data / dataNormalization) * getDataNormalizationMultiplier();
						});
						const average = _.meanBy(trialTreatmentData);
						siteObject.average = average;

						siteObject.excluded = false;
					}
					//If no treatment data then set average to null, we will use this to filter values
					else {
						siteObject.average = null;
					}

					cleanTrialData.push(siteObject);
				}
			}
			siteData = siteData.concat(cleanTrialData);
		});

		setSiteSpecificData(siteData);
		setFilteredSiteSpecificData(siteData);

		setTrialPlotSpecificData(plotObjects);
		setFilteredTrialPlotSpecificData(plotObjects);
	}

	function getDataNormalizationModifier(trialData) {
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			selectedDataNormalizationOption
		]).text.toLowerCase();
		switch (selectedDataNormalizationName) {
			case "% mean":
				return getPercentMean(trialData);
			case "% control":
			case "% commercial":
			case "% gsp":
				return getPercentByTreatmentType(trialData);
			case "none":
			case "rank order":
			default:
				//-- No modifications to none, just display raw data
				return 1;
		}
	}

	function getDataNormalizationMultiplier() {
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			selectedDataNormalizationOption
		]).text.toLowerCase();

		switch (selectedDataNormalizationName) {
			case "% mean":
			case "% control":
			case "% commercial":
			case "% gsp":
				return 100;
			case "none":
			case "rank order":
			default:
				//-- No modifications to none, just display raw data
				return 1;
		}
	}

	function getPercentMean(trialData) {
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === selectedAssessmentOption;
		});
		const data = _.map(
			_.filter(trialData, (td) => {
				return (
					td.excluded !== true &&
					(td.groundDatasetAssessmentValue !== null ||
						td.curveModelAnalysisValue !== null ||
						td.standCountAnalysisResultValue !== null)
				);
			}),
			assessment.isStandCount === String(true)
				? "standCountAnalysisResultValue"
				: assessment.isgrounddata == String(true)
				? "groundDatasetAssessmentValue"
				: "curveModelAnalysisValue"
		);
		const mean = _.meanBy(data);
		return mean;
	}

	function getPercentByTreatmentType(trialData) {
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === selectedAssessmentOption;
		});
		const treatmentTypeId = _.find(dataNormalizationOptions, (dno) => {
			return dno.value === selectedDataNormalizationOption;
		}).treatmenttypeid;
		const trialTreament = _.find(treatmentData, (td) => {
			return td.treatmentTypeId === treatmentTypeId;
		});
		const data = _.map(
			_.filter(trialData, (td) => {
				return (
					td.trialTreatmentId === trialTreament.trialTreatmentId &&
					td.excluded !== true &&
					(td.groundDatasetAssessmentValue !== null ||
						td.curveModelAnalysisValue ||
						td.standCountAnalysisResultValue !== null)
				);
			}),
			assessment.isStandCount === String(true)
				? "standCountAnalysisResultValue"
				: assessment.isgrounddata == String(true)
				? "groundDatasetAssessmentValue"
				: "curveModelAnalysisValue"
		);
		const mean = _.meanBy(data);
		return mean;
	}

	function updateCompiledData(runCompiledTukey) {
		let averageData = [];
		for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
			const treatment = _.find(treatmentData, { trialTreatmentId: i });

			let treatmentDataAverages = _.filter(runCompiledTukey ? siteSpecificData : filteredSiteSpecificData, (sd) => {
				return sd.trialTreatmentId === i && sd.trialChecked !== false;
			});

			//Filter out trials with no plot data
			treatmentDataAverages = _.filter(treatmentDataAverages, (tda) => {
				return tda?.plots.length > 0;
			});
			const averages = _.map(treatmentDataAverages, "average");
			const average = _.meanBy(averages);

			//Building a list of missing treatments in the trials. This will help determine which treatment needs an info icon
			let treatmentGroup = _.groupBy(
				_.filter(runCompiledTukey ? siteSpecificData : filteredSiteSpecificData, (sd) => {
					return sd.trialChecked !== false;
				}),
				"trialTreatmentId"
			);

			let maxNumberOfObjects = _.max(Object.values(treatmentGroup).map((a) => a.length));
			let treatmentsMissingInTrials = [];
			_.map(treatmentGroup, (tg) => {
				if (
					tg.length != maxNumberOfObjects ||
					_.some(tg, (t) => {
						return t.excluded === true;
					})
				) {
					treatmentsMissingInTrials.push(tg[0].trialTreatmentId);
				}
			});

			let compiledTreatment = {
				trialTreatmentId: i,
				treatmentTypeName: treatment.treatmentTypeName,
				treatmentName: treatment.name,
				average: average,
				assessmentData: _.map(treatmentDataAverages, (tada) => {
					return {
						plots: tada.plots
					};
				}),
				treatmentChecked: _.find(runCompiledTukey ? siteSpecificData : filteredSiteSpecificData, (ssd) => {
					return ssd.trialTreatmentId === i;
				}).treatmentChecked,
				reversed: runCompiledTukey
					? siteSpecificData[0]?.assessmentName?.includes("MG50")
					: filteredSiteSpecificData[0]?.assessmentName?.includes("MG50"),
				hasMissingTreatments: treatmentsMissingInTrials.includes(i)
			};
			averageData.push(compiledTreatment);
		}
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			selectedDataNormalizationOption
		]).text.toLowerCase();

		if (selectedDataNormalizationName === "rank order") {
			getRankOrder(averageData);
			_.map(averageData, (ad) => {
				ad.reversed = true;
			});
		}
		let updatedCompiledData = _.map(averageData, (cd) => {
			const treatment = _.find(treatmentsChecked, (tc) => {
				return tc.trialTreatmentId === cd.trialTreatmentId;
			});

			cd.treatmentChecked = treatment.treatmentChecked;
			return cd;
		});
		setCompiledData(updatedCompiledData);
		if (!firstTimeLoad && runCompiledTukey) {
			getCompiledTukeyData(updatedCompiledData);
		}
	}

	function updateCompiledDataChecked(runCompiledTukey) {
		let updatedCompiledData = _.map(compiledData, (cd) => {
			const treatment = _.find(treatmentsChecked, (tc) => {
				return tc.trialTreatmentId === cd.trialTreatmentId;
			});

			cd.treatmentChecked = treatment.treatmentChecked;
			return cd;
		});
		setCompiledData(updatedCompiledData);
		if (!firstTimeLoad && runCompiledTukey) {
			getCompiledTukeyData(updatedCompiledData);
		}
	}

	async function getCompiledTukeyData(averageData) {
		setTukeyRunning(true);

		let allPlotData = [];
		let trialsInAssessment = _.max(_.map(_.map(averageData, "assessmentData"), (ad) => ad.length));

		_.map(averageData, (d) => {
			if (_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === d.trialTreatmentId)?.treatmentChecked) {
				if (d.assessmentData?.length === trialsInAssessment) {
					_.map(d.assessmentData, (ad) => {
						_.map(ad.plots, (p) => {
							allPlotData.push(p);
						});
					});
				}
			}
		});

		let groupedPlotData = _.groupBy(allPlotData, "assessmentId");
		let dataToPass = [];
		_.map(groupedPlotData, (g) => dataToPass.push(g));

		let updatedCompiledMeanComparisonData = _.cloneDeep(compiledMeanComparisonData);
		let updatedCompiledTrialMeanComparisonData = _.cloneDeep(compiledTrialMeanComparisonData);

		if (showTwoWayAnova === false) {
			getSingleTrialAucMeanComparisonData();
		}

		if (_.uniqBy(allPlotData, "trialId")?.length > 1) {
			if (dataToPass.length > 0) {
				await Promise.all(
					_.map(dataToPass, async (plotData) => {
						const accessToken = await getTokenSilently();
						return dispatch(
							statisticsActions.getCompiledTukeyData(
								plotData,
								selectedAlphaOption,
								selectedMeanComparisonOption,
								userAuth.currentClientId,
								accessToken
							)
						).then((res) => {
							return res;
						});
					})
				)
					.then((res) => {
						let condensedData = [];
						let condensedTrialData = [];
						_.map(res, (r) => {
							setupAnovaData(r.data.protocolData[0]);
							_.map(r.data.protocolData, (pd) => {
								condensedData.push(pd);
							});
							_.map(r.data.trialData, (td) => {
								condensedTrialData.push(td);
							});
						});

						let meanComparisonData = _.groupBy(condensedData, "assessmentId");
						_.map(meanComparisonData, (td) => {
							assignMeanComparisonLetters(updatedCompiledMeanComparisonData, td);
						});

						setCompiledMeanComparisonData(updatedCompiledMeanComparisonData);

						let trialMeanComparisonData = _.groupBy(condensedTrialData, "assessmentId");
						_.map(trialMeanComparisonData, (tmcd) => {
							assignMeanComparisonLetters(updatedCompiledTrialMeanComparisonData, tmcd);
						});

						setCompiledTrialMeanComparisonData(updatedCompiledTrialMeanComparisonData);

						setTukeyRunning(false);
						setHideMeanComparisonResults(false);

						if (needToUpdateAnalytics) {
							setNeedToUpdateAnalytics(false);
						}
					})
					.catch((err) => {
						console.log(err);
						//Adding a toast id so that we do not get multiples of the same messages
						toast.error("There was an issue getting mean comparison values for the multi-trial datasets", {
							toastId: "MultiTrialDatasetErrorSend"
						});

						setMultiTrialDatasetError(true);

						setTukeyRunning(false);
						setHideMeanComparisonResults(false);

						if (needToUpdateAnalytics) {
							setNeedToUpdateAnalytics(false);
						}
					});
			} else {
				setCompiledMeanComparisonData(updatedCompiledMeanComparisonData);
				setCompiledTrialMeanComparisonData(updatedCompiledTrialMeanComparisonData);

				//These cause a little delay in the anova table rerendering but it looks like I need this to avoid infinite spinning
				setTukeyRunning(false);
				if (needToUpdateAnalytics) {
					setNeedToUpdateAnalytics(false);
				}
			}
		} else {
			//Adding a toast id so that we do not get multiples of the same messages
			toast.error("Cannot run multi-trial mean comparisons when only 1 trial dataset is available", {
				toastId: "OneTrialDatasetErrorSend"
			});

			setOneTrialDatasetError(true);

			setTukeyRunning(false);

			if (needToUpdateAnalytics) {
				setNeedToUpdateAnalytics(false);
			}
		}

		toast.success("All data has finished loading.", { toastId: "allFinished" });
	}

	function assignMeanComparisonLetters(updatedCompiledData, tukeyData) {
		if (!tukeyData || tukeyData?.length === 0) {
			return;
		}
		let tukeyDataToAdd = _.cloneDeep(tukeyData);
		if (_.every(tukeyData, (td) => td.tukeyLetters === "a")) {
			_.forEach(tukeyDataToAdd, (td) => (td.tukeyLetters = "-"));
		}

		updatedCompiledData[tukeyData[0].assessmentId] = tukeyDataToAdd;
	}

	function setupAnovaData(meanComparisonResult) {
		let tAnovaData = {
			numeratorDf: meanComparisonResult?.numeratorDF,
			denominatorDf: meanComparisonResult?.denominatorDF,
			fValues: meanComparisonResult?.fValues,
			pValues: meanComparisonResult?.pValues
		};

		setAnovaData(tAnovaData);
	}

	async function getSingleTrialAucMeanComparisonData() {
		let uniqueTrials = _.map(_.uniqBy(filteredTrialPlotSpecificData, "trialId"), "trialId");

		const accessToken = await getTokenSilently();
		await Promise.all(
			_.map(uniqueTrials, (td) => {
				const requestData = {
					data: _.map(
						_.filter(filteredTrialPlotSpecificData, (tpsd) => {
							return tpsd.trialId === td && tpsd.treatmentChecked;
						}),
						(tpsd) => {
							return {
								trialTreatmentId: tpsd.trialTreatmentId,
								plotReplicate: tpsd.plotReplicate,
								totalAuc: tpsd.average
							};
						}
					)
				};
				return dispatch(
					statisticsActions.getMeanComparisonForAuc(
						requestData,
						userAuth.currentClientId,
						selectedMeanComparisonOption,
						selectedAlphaOption,
						true,
						accessToken
					)
				).then((res) => {
					res.trialId = td;
					_.map(res.letteringPairs, (l) => {
						l.trialId = td;
						l.trialTreatmentId = l.treatmentId;
						l.tukeyLetters = l.letter;
						l.pValue = res.pValue;
						l.cv = res.cv;
						l.mean = res.mean;
					});
					return res;
				});
			})
		).then((res) => {
			setSingleTrialAnovaValues(res);
		});
	}

	function getRankOrder(averageData) {
		averageData = _.orderBy(averageData, ["average"], ["desc"]);
		_.map(averageData, (ad, index) => {
			ad.average = index + 1;
		});

		return _.sortBy(averageData, "trialTreatmentId");
	}

	async function getAnalysisResultsForTrial() {
		const accessToken = await getTokenSilently();
		if (!needToUpdateAnalytics || hideMeanComparisonResults) {
			setLoading(true);
		}
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === selectedAssessmentOption;
		});
		await Promise.all(
			_.map(trialIds, (id) => {
				return dispatch(
					analysisActions.getProtocolAnalysisResultsForBoxWhisker(
						userAuth.currentClientId,
						id,
						accessToken,
						assessment.isgrounddata == String(true) ? selectedAssessmentOption : "",
						assessment.isgrounddata == String(true) ? selectedPlannedTimingOption : "",
						assessment.iscurvemodel == String(true) ? assessment.curvemodelanalysisid : "",
						assessment.iscurvemodel == String(true) || assessment.isStandCount == String(true)
							? assessment.analysisid
							: "",
						false,
						assessment.iscurvemodel == String(true) || assessment.isStandCount == String(true)
							? assessment.analysistypeid
							: "",
						false,
						removeOutliers,
						removeDamagedOrExcluded,
						outlierType
					)
				).then((res) => {
					return res.plotAnalysisResults;
				});
			})
		)
			.then((res) => {
				setLoading(false);
				let filteredTreatments = [];
				_.filter(res, (r) => {
					//Filter out trials that do not have data for selected analysis
					let f = _.filter(r, (trial) => {
						return (
							trial.trialTreatmentId <= reduxProtocolData.treatments &&
							((trial.analysisId === assessment.analysisid && trial.analysisTypeId === assessment.analysistypeid) ||
								(trial.assessmentId && trial.groundDatasetAssessmentValue !== null)) &&
							trial.excludeFromAssessment === false
						);
					});
					if (f?.length > 0) {
						filteredTreatments.push(f);
					}
				});
				setRawTrialData(filteredTreatments);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	function updateCheckedTreatment(value, trialTreatmentId) {
		const updatedTreatmentsChecked = _.map(treatmentsChecked, (tc) => {
			if (tc.trialTreatmentId === trialTreatmentId) {
				tc.treatmentChecked = value;
				tc.dirty = true;
			}

			return tc;
		});
		setTreatmentsChecked(updatedTreatmentsChecked);

		let uncheckedList = [];
		_.filter(updatedTreatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});

		let trialPlotSpecificDataClone = _.cloneDeep(trialPlotSpecificData);

		_.map(trialPlotSpecificDataClone, (tpsd) => {
			tpsd.treatmentChecked = !_.includes(uncheckedList, tpsd.trialTreatmentId);
		});
		setFilteredTrialPlotSpecificData(trialPlotSpecificDataClone);

		const updatedFilteredSiteSpecificData = _.map(filteredSiteSpecificData, (fspd) => {
			if (fspd.trialTreatmentId === trialTreatmentId) {
				fspd.treatmentChecked = value;
				fspd.dirty = true;
			}

			return fspd;
		});
		updateCompiledDataChecked(false);
		setFilteredSiteSpecificData(updatedFilteredSiteSpecificData);
		setHideMeanComparisonResults(true);
		setNeedToUpdateAnalytics(true);
	}

	function updateTrialsChecked(value, trialId, silent = false) {
		if (!silent) {
			setTrialFilter(trialFitlerOptions.find((opt) => opt.text === "Custom"));
		}

		const updatedTrialsChecked = _.map(trialsChecked, (tc) => {
			if (tc.trialId === trialId) {
				tc.trialChecked = value;
			}

			return tc;
		});
		let updatedInitChecked = initTrialsChecked;
		if (updatedInitChecked[trialId] === undefined) {
			updatedInitChecked[trialId] = value;
			setInitTrialsChecked(updatedInitChecked);
		}
		setTrialsChecked(updatedTrialsChecked);

		if (
			Object.keys(initTrialsChecked).length > 0 &&
			!updatedTrialsChecked.every((t) => t.trialChecked === updatedInitChecked[t.trialId])
		) {
			setHideUncheckedTrialData(true);
		} else {
			setHideUncheckedTrialData(false);
		}
		setNeedToUpdateAnalytics(true);
	}

	function updateCheckedSiteData() {
		let updatedSiteData = _.map(siteSpecificData, (ssd) => {
			const treatment = _.find(treatmentsChecked, (tc) => {
				return tc.trialTreatmentId === ssd.trialTreatmentId;
			});

			ssd.treatmentChecked = treatment.treatmentChecked;
			return ssd;
		});

		updatedSiteData = _.map(updatedSiteData, (ssd) => {
			const trial = _.find(trialsChecked, (tc) => {
				return tc.trialId === ssd.trialId;
			});
			ssd.trialChecked = trial.trialChecked;
			return ssd;
		});

		setFilteredSiteSpecificData(updatedSiteData);
	}

	function updateCheckedTrialPlotSpecificData() {
		let updatedCheckedData = _.map(trialPlotSpecificData, (tpsd) => {
			const treatment = _.find(treatmentsChecked, (tc) => {
				return tc.trialTreatmentId === tpsd.trialTreatmentId;
			});

			tpsd.treatmentChecked = treatment.treatmentChecked;
			return tpsd;
		});

		updatedCheckedData = _.map(updatedCheckedData, (tpsd) => {
			const trial = _.find(trialsChecked, (tc) => {
				return tc.trialId === tpsd.trialId;
			});

			tpsd.trialChecked = trial.trialChecked;
			return tpsd;
		});

		setFilteredTrialPlotSpecificData(updatedCheckedData);
	}

	async function updateMeanComparisonData() {
		setHideLetters(false);
		setOneTrialDatasetError(false);
		setMultiTrialDatasetError(false);

		if (_.some(treatmentsChecked, (tc) => tc.dirty)) {
			updateCheckedSiteData();
			updateCheckedTrialPlotSpecificData();

			let clonedTreatmentsChecked = _.cloneDeep(treatmentsChecked);
			setTreatmentsChecked(
				_.map(clonedTreatmentsChecked, (tc) => {
					tc.dirty = false;
					return tc;
				})
			);
		}

		if (needToUpdateAnalytics && !firstTimeLoad) {
			setTukeyRunning(true);
			setFilteredSiteSpecificData([]);
			setSingleTrialAnovaValues([]);
			setHideUncheckedTrialData(false);
			let treatmentCheckedList = [];
			let orderedTreatmentList = _.orderBy(treatmentsChecked, "trialTreatmentId");
			_.filter(orderedTreatmentList, (utc) => {
				treatmentCheckedList.push(utc.treatmentChecked);
			});
			setInitialGroupTrialDataCheckedCount(treatmentCheckedList);
			setInitTrialsChecked({});

			await getAnalysisResultsForTrial();
			setNeedToRerunStats(false);
			setNeedToRerunNormalization(false);
		} else if (!firstTimeLoad) {
			updateCompiledData();
			setNeedToRerunStats(false);
		}
		if (firstTimeLoad) {
			const averageData = _.cloneDeep(compiledData);
			getCompiledTukeyData(averageData);
		}
	}

	async function getTrialData() {
		const accessToken = await getTokenSilently();

		setMetadataLoading(true);
		dispatch(trialActions.getMetadataForTrials(trialIds, userAuth.currentClientId, accessToken))
			.then((res) => {
				//split data up per trial
				//Add props to make sorting easier (sorting it the same way as the site table)
				_.map(res, (trial) => {
					trial.farmState = trial.trialInfo.farmState;
					trial.farmCity = trial.trialInfo.farmCity;
					trial.trialName = trial.trialInfo.trialName;
					trial.trialId = trial.trialInfo.trialId;
					trial.cooperatorName = trial.trialInfo.cooperatorName;
				});
				let orderedData = _.sortBy(res, ["farmState", "farmCity", "trialName", "cooperatorName", "trialId"]);

				setTrialData(orderedData);

				setMetadataLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setMetadataLoading(false);
			});
	}

	const handleSaveMetaTags = (info) => {
		const dataMap = new Map(info.map((i) => [i.trialId, i]));
		setTrialData((prev) =>
			prev.map((td) => {
				const curr = dataMap.get(td.trialInfo.trialId);
				if (curr) {
					return { ...td, trialInfo: curr };
				}
				return td;
			})
		);
		updateMetaTags(info);
	};

	return assessmentOptions.length < 1 ? (
		<Segment style={{ width: "100%" }}>
			<p>{"We could not find data for this protocol."}</p>
		</Segment>
	) : (
		<Segment basic style={{ padding: 0 }}>
			<Form>
				<Segment className="ribbonBanner" style={{ zIndex: 999, margin: "0 -1em" }}>
					<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
						<div>
							<Form.Field>
								<label htmlFor="form-assessment">Assessment</label>
							</Form.Field>
							<Form.Select
								id="form-assessment"
								selection
								fluid
								disabled={loading || tukeyRunning || metadataLoading}
								options={assessmentOptions}
								onChange={(event, { value }) => {
									setSelectedAssessmentOptions(value);
									setHideMeanComparisonResults(true);
									setNeedToUpdateAnalytics(true);
								}}
								value={selectedAssessmentOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-planned-timing">Growth Phase</label>
							</Form.Field>
							<Form.Select
								id="form-planned-timing"
								selection
								fluid
								disabled={
									_.find(assessmentOptions, (ao) => {
										return ao.key === selectedAssessmentOption;
									}).iscurvemodel === String(true) ||
									loading ||
									metadataLoading
								}
								options={
									_.filter(plannedTimingOptions, (pto) => {
										return _.some(pto.grounddataassessmentids, (gdai) => {
											return gdai === selectedAssessmentOption;
										});
									}) ?? []
								}
								onChange={(event, { value }) => {
									setSelectedPlannedTimingOptions(value);
									setHideMeanComparisonResults(true);
								}}
								value={selectedPlannedTimingOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-data-normalization">Data Normalization</label>
							</Form.Field>
							<Form.Select
								id="form-data-normalization"
								selection
								fluid
								disabled={tukeyRunning || loading || metadataLoading}
								options={filteredDataNormalizationOptions ?? []}
								onChange={(event, { value }) => {
									setSelectedDataNormalizationOption(value);
									setHideMeanComparisonResults(true);
									setNeedToUpdateAnalytics(true);
									setNeedToRerunNormalization(true);
								}}
								value={selectedDataNormalizationOption}
							/>
						</div>
						<div>
							<Form.Field inline>
								<label htmlFor="form-mean-comparison">Mean Comparison</label>
								<Popup
									content="A statistical test or p-value adjustment that evaluates differences among all pairs of treatments"
									trigger={<Icon name="info circle" link style={{ marginLeft: -5 }} />}
								/>
							</Form.Field>
							<Form.Select
								id="form-mean-comparison"
								selection
								fluid
								disabled={loading || tukeyRunning || metadataLoading}
								options={meanComparisonOptions}
								onChange={(event, { value }) => {
									setSelectedMeanComparisonOption(value);
									setHideMeanComparisonResults(true);
									setNeedToRerunStats(true);
									setNeedToUpdateAnalytics(true);
								}}
								value={selectedMeanComparisonOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-alpha">Alpha</label>
							</Form.Field>
							<Form.Select
								id="form-alpha"
								selection
								fluid
								disabled={loading || tukeyRunning || metadataLoading}
								options={alphaOptions}
								onChange={(event, { value }) => {
									setSelectedAlphaOption(value);
									setHideMeanComparisonResults(true);
									setNeedToRerunStats(true);
									setNeedToUpdateAnalytics(true);
								}}
								value={selectedAlphaOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-assessment">Remove Plot Exclusions</label>
							</Form.Field>
							<DataAnalysisCriteria
								removeDamagedOrExcluded={removeDamagedOrExcluded}
								removeOutliers={removeOutliers}
								outlierType={outlierType}
								setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
								setRemoveOutliers={setRemoveOutliers}
								setOutlierType={setOutlierType}
								setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
								disabled={loading || tukeyRunning || metadataLoading}
							/>
							<Popup
								trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
								content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-trial-filters">Trial Filters</label>
							</Form.Field>
							<Form.Select
								style={{ maxWidth: 250 }}
								id="form-trial-filters"
								options={trialFitlerOptions}
								loading={trialFiltersLoading}
								value={trialFilter?.value}
								onChange={(_, { value, options }) => {
									setTrialFilter(options.find((opt) => opt.value === value));
								}}
							/>
						</div>
						<div style={{ alignSelf: "end" }}>
							<Button
								primary={!hideMeanComparisonResults && !needToUpdateAnalytics}
								negative={hideMeanComparisonResults || needToUpdateAnalytics}
								content="Update Analytics"
								onClick={() => {
									if (needToUpdateAnalytics) {
										if (
											_.filter(treatmentsChecked, (tc) => tc.treatmentChecked).length < 2 ||
											_.filter(trialsChecked, (tc) => tc.trialChecked).length < 2
										) {
											toast.error("At least two treatments and two trials need to be selected");
										} else {
											updateMeanComparisonData();
										}
									}
								}}
								disabled={loading || tukeyRunning || metadataLoading || isEditing}
								loading={tukeyRunning}
							/>
						</div>
					</div>
				</Segment>
				<Grid>
					{loading ? (
						<Loader active inline="centered" />
					) : !siteSpecificData?.length > 0 ? (
						<Segment basic>
							<p>{"Please select an Assessment and Planned Timing Method to view data."}</p>
						</Segment>
					) : (
						<>
							<Grid.Row style={{ paddingTop: "1em" }}>
								<Grid.Column width="6" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
								<Grid.Column width="4" textAlign="center">
									<h2>Compiled Protocol Data</h2>
								</Grid.Column>
								<Grid.Column width="6" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
							</Grid.Row>
							{filteredSiteSpecificData != null &&
								filteredSiteSpecificData?.length > 0 &&
								compiledData != null &&
								compiledData.length > 0 &&
								trialPlotSpecificData != null &&
								trialPlotSpecificData.length > 0 && (
									<Grid.Row>
										<Grid.Column width="8">
											<Form.Field>
												<ProtocolDataNormalizationTable
													compiledData={compiledData}
													tukeyData={compiledMeanComparisonData}
													disableCheckBoxes={tukeyRunning}
													selectedAssessmentOption={selectedAssessmentOption}
													dataNormalization={
														_.find(dataNormalizationOptions, (dno) => {
															return dno.value === selectedDataNormalizationOption;
														}).text
													}
													updateCheckedTreatment={updateCheckedTreatment}
													treatmentsChecked={treatmentsChecked}
													hideMeanComparisonResults={hideMeanComparisonResults}
													hideLetters={hideLetters}
													setHideLetters={setHideLetters}
													setTreatmentsChecked={setTreatmentsChecked}
													initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
													updateCheckedTrial={updateTrialsChecked}
													hideUncheckedTrialData={hideUncheckedTrialData}
													needToRerunStats={needToRerunStats}
													needToRerunNormalization={needToRerunNormalization}
													oneTrialDatasetError={oneTrialDatasetError}
												/>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8">
											<Grid.Row>
												{siteSpecificData && trialPlotSpecificData ? (
													<ProtocolBoxWhiskerChart
														tukeyData={compiledMeanComparisonData[selectedAssessmentOption]}
														tukeyRunning={tukeyRunning}
														data={_.filter(
															showAveragesChecked
																? !_.some(treatmentsChecked, (tc) => tc.treatmentChecked)
																	? filteredSiteSpecificData
																	: _.filter(filteredSiteSpecificData, (ssd) => {
																			return ssd.treatmentChecked && ssd.trialChecked && ssd.plots.length > 0;
																	  })
																: !_.some(treatmentsChecked, (tc) => tc.treatmentChecked)
																? filteredTrialPlotSpecificData
																: _.filter(filteredTrialPlotSpecificData ?? trialPlotSpecificData, (tsd) => {
																		return tsd.treatmentChecked && tsd.trialChecked;
																  })
														)}
														hideMeanComparisonResults={hideMeanComparisonResults}
														axisYTitle={`${
															_.find(assessmentOptions, { key: selectedAssessmentOption })?.text.split(" (")[0]
														} (${
															_.find(filteredDataNormalizationOptions, { key: selectedDataNormalizationOption })?.text
														})`}
														chartHeight={500}
														allTreatmentData={_.filter(
															showAveragesChecked
																? filteredSiteSpecificData
																: filteredTrialPlotSpecificData ?? trialPlotSpecificData
														)}
														isCompiled={showAveragesChecked ? "averages" : "allReps"}
														disableColors={disableColors}
														initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
														hideUncheckedTrialData={hideUncheckedTrialData}
														needToRerunStats={needToRerunStats}
														allTreatmentsUnchecked={!_.some(treatmentsChecked, (tc) => tc.treatmentChecked)}
														needToRerunNormalization={needToRerunNormalization}
													/>
												) : null}
											</Grid.Row>
											<Grid.Row>
												<Grid>
													<Grid.Column width="4">
														<Form.Radio
															checked={showAveragesChecked}
															label="Trial Averages"
															disabled={loading || tukeyRunning || metadataLoading}
															onChange={() => {
																setShowAveragesChecked(true);
															}}
														/>
														<Form.Radio
															checked={!showAveragesChecked}
															label="All Replicates"
															disabled={loading || tukeyRunning || metadataLoading}
															onChange={() => {
																setShowAveragesChecked(false);
															}}
														/>
														<Form.Checkbox
															value={disableColors}
															onChange={() => setDisableColors(!disableColors)}
															label="Disable Graph Colors"
															toggle
														/>
													</Grid.Column>
													<Grid.Column width="12">
														<AnovaTable
															anovaData={anovaData}
															hideData={
																tukeyRunning ||
																needToRerunStats ||
																hideLetters ||
																hideUncheckedTrialData ||
																needToRerunNormalization
															}
															wasThereAnError={multiTrialDatasetError || oneTrialDatasetError}
														/>
													</Grid.Column>
												</Grid>
											</Grid.Row>
										</Grid.Column>
									</Grid.Row>
								)}
							<Grid.Row>
								<Grid.Column width="7" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
								<Grid.Column width="2" textAlign="center">
									<h2>Trial Data</h2>
								</Grid.Column>
								<Grid.Column width="7" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
							</Grid.Row>

							{loading ? null : (
								<Grid.Row>
									<Grid.Column width="16" textAlign="center">
										<h4>{`${_.find(assessmentOptions, { key: selectedAssessmentOption })?.text.split(" (")[0]} (${
											_.find(filteredDataNormalizationOptions, { key: selectedDataNormalizationOption })?.text
										})`}</h4>
									</Grid.Column>
									<Grid.Column width="8" style={{ display: "none" }}>
										<Form.Group inline>
											<label>Perform Mean Comparisons as: </label>
											<Form.Radio
												checked={showTwoWayAnova}
												label={"Single Multi-Location Data Set"}
												disabled={loading || tukeyRunning || metadataLoading}
												onChange={() => {
													setHideMeanComparisonResults(true);
													setShowTwoWayAnova(true);
												}}
											></Form.Radio>
											<Form.Radio
												checked={!showTwoWayAnova}
												label={"Seperate, Isolated Data Sets"}
												disabled={loading || tukeyRunning || metadataLoading}
												onChange={() => {
													setHideMeanComparisonResults(true);
													setShowTwoWayAnova(false);
												}}
											></Form.Radio>
										</Form.Group>
									</Grid.Column>
								</Grid.Row>
							)}
							{filteredSiteSpecificData && filteredSiteSpecificData?.length > 0 ? (
								<div className="trialTableWrapper">
									<Grid.Row style={{ padding: 0 }}>
										<MetaTagsTable
											trialInfo={trialInfo}
											onSave={handleSaveMetaTags}
											setSaveAction={setSaveAction}
											setIsDirty={setIsDirty}
											setIsEditing={setIsEditing}
										/>
									</Grid.Row>
									<Grid.Row style={{ padding: 0 }}>
										<ProtocolSiteSpecificTable
											siteSpecificData={filteredSiteSpecificData}
											tukeyData={
												showTwoWayAnova
													? compiledTrialMeanComparisonData[selectedAssessmentOption]
													: _.flatten(
															_.map(singleTrialAnovaValues, (data) => {
																return data.letteringPairs;
															})
													  )
											}
											tukeyDataLoading={tukeyRunning}
											disableCheckBoxes={tukeyRunning}
											updateCheckedTrial={updateTrialsChecked}
											appenedPercent={_.find(dataNormalizationOptions, [
												"value",
												selectedDataNormalizationOption
											]).text.includes("%")}
											showPValues={!showTwoWayAnova}
											hideMeanComparisonResults={hideMeanComparisonResults}
											initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
											needToRerunNormalization={needToRerunNormalization}
											needToRerunStats={needToRerunStats}
										/>
									</Grid.Row>
								</div>
							) : null}
							<Grid.Row>
								<Grid.Column width="6" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
								<Grid.Column width="4" textAlign="center">
									<h2>Trial Box Whisker Charts</h2>
								</Grid.Column>
								<Grid.Column width="6" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
							</Grid.Row>
							{filteredSiteSpecificData && filteredSiteSpecificData?.length > 0
								? _.map(
										_.filter(trialData, (td) =>
											_.map(_.uniqBy(filteredTrialPlotSpecificData, "trialId"), "trialId").includes(
												td.trialInfo.trialId
											)
										),
										(ftd) => {
											return (
												<Grid.Row key={ftd.trialInfo.trialId}>
													<Grid.Column width="3">
														<ProtocolTimecourseSiteSpecificTable
															trialId={ftd.trialInfo.trialId}
															trialName={ftd.trialInfo.trialName}
															cooperatorName={ftd.trialInfo.cooperatorName}
															farmCity={ftd.trialInfo.farmCity}
															farmState={ftd.trialInfo.farmState}
															clientId={userAuth.currentClientId}
															hasExclusions={ftd.trialInfo.hasExclusions}
															hasFlaggings={ftd.trialInfo.hasFlaggings}
															hasPartialExclusions={ftd.trialInfo.hasPartialExclusions}
															hasPartialFlaggings={ftd.trialInfo.hasPartialFlaggings}
															toggleMetaFactorModal={toggleMetaFactorModal}
														/>
													</Grid.Column>
													<Grid.Column width="13">
														<ProtocolBoxWhiskerChart
															data={_.filter(filteredTrialPlotSpecificData, (ssd) => {
																return ssd.trialId === ftd.trialInfo.trialId;
															})}
															tukeyData={
																showTwoWayAnova
																	? _.filter(compiledTrialMeanComparisonData[selectedAssessmentOption], (data) => {
																			return data.trialId === ftd.trialInfo.trialId;
																	  })
																	: _.find(singleTrialAnovaValues, (data) => {
																			return data.trialId === ftd.trialInfo.trialId;
																	  })?.letteringPairs
															}
															tukeyRunning={tukeyRunning}
															hideMeanComparisonResults={hideMeanComparisonResults}
															disableColors={disableColors}
															needToRerunStats={needToRerunStats}
															initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
															needToRerunNormalization={needToRerunNormalization}
															chartHeight={300}
														/>
													</Grid.Column>
												</Grid.Row>
											);
										}
								  )
								: null}
						</>
					)}
				</Grid>
			</Form>
		</Segment>
	);
};

ProtocolBoxWhisker.propTypes = {
	assessmentOptions: PropTypes.array,
	plannedTimingOptions: PropTypes.array,
	dataNormalizationOptions: PropTypes.array,
	meanComparisonOptions: PropTypes.array,
	alphaOptions: PropTypes.array,
	trialIds: PropTypes.array,
	treatmentData: PropTypes.array,
	defaultMeanComparisonOption: PropTypes.string,
	defaultAlphaOption: PropTypes.string,
	toggleMetaFactorModal: PropTypes.func,
	setIsDirty: PropTypes.func,
	setSaveAction: PropTypes.func
};

export default ProtocolBoxWhisker;
