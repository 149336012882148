// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".firstRow {\n\tmargin-top: 30px;\n}\n\n.rowHeight {\n\tmargin-top: 40px;\n}\n.modal.ui.modal .content.scrolling.content {\n\tmax-height: calc(80vh);\n}\n\n/* .ui.table thead tr:first-child > th {\n\ttop: 0 !important;\n} */\n\n.ui.multiple.dropdown > .text {\n\tmargin-left: 0;\n}\n\n.actionsCell .ui.icon.button {\n\tbackground-color: transparent;\n}\n\n.actionsCell .ui.icon.button:hover {\n\tbackground-color: var(--neutral-200);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/GroundData/NewGroundData/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB;AACA;CACC,sBAAsB;AACvB;;AAEA;;GAEG;;AAEH;CACC,cAAc;AACf;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,oCAAoC;AACrC","sourcesContent":[".firstRow {\n\tmargin-top: 30px;\n}\n\n.rowHeight {\n\tmargin-top: 40px;\n}\n.modal.ui.modal .content.scrolling.content {\n\tmax-height: calc(80vh);\n}\n\n/* .ui.table thead tr:first-child > th {\n\ttop: 0 !important;\n} */\n\n.ui.multiple.dropdown > .text {\n\tmargin-left: 0;\n}\n\n.actionsCell .ui.icon.button {\n\tbackground-color: transparent;\n}\n\n.actionsCell .ui.icon.button:hover {\n\tbackground-color: var(--neutral-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstRow": "firstRow",
	"rowHeight": "rowHeight",
	"modal": "modal",
	"ui": "ui",
	"content": "content",
	"scrolling": "scrolling",
	"multiple": "multiple",
	"dropdown": "dropdown",
	"text": "text",
	"actionsCell": "actionsCell",
	"icon": "icon",
	"button": "button"
};
export default ___CSS_LOADER_EXPORT___;
