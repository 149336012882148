import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { Segment } from "semantic-ui-react";

import NewEditFieldForm from "./newFieldForm";

const NewEditField = ({
	mode,
	setModeState,
	setFieldId,
	fieldId,
	fromViewer,
	handleCancel,
	handleFinish,
	navigateToNextStep
}) => {
	const history = useHistory();

	function handleSave(fieldSaveResponse) {
		if (fieldSaveResponse) {
			if (!fromViewer) {
				history.push(`/fields/${fieldSaveResponse.fieldId}/edit`);
			}
		}
	}

	return !fieldId && mode === "edit" ? (
		<Segment basic>
			<Segment basic>
				<p>{"This field could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<NewEditFieldForm
			setFieldId={(id) => setFieldId(id)}
			fieldId={fieldId}
			fromViewer={fromViewer}
			handleCancel={() => handleCancel()}
			handleFinish={(id) => handleFinish(id)}
			handleSave={(res) => handleSave(res)}
			navigateToNextStep={navigateToNextStep}
			setModeState={(m) => setModeState(m)}
			mode={mode}
		/>
	);
};

NewEditField.propTypes = {
	mode: PropTypes.string.isRequired,
	setModeState: PropTypes.func,
	setFieldId: PropTypes.func,
	fieldId: PropTypes.string,
	fromViewer: PropTypes.bool,
	handleCancel: PropTypes.func,
	handleFinish: PropTypes.func,
	navigateToNextStep: PropTypes.func
};

export default NewEditField;
