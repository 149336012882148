import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Divider, Form } from "semantic-ui-react";
import * as companyActions from "../../../../redux/actions/companyActions";
import * as farmActions from "../../../../redux/actions/farmActions.ts";
import * as lookupActions from "../../../../redux/actions/lookupActions";
import * as mapboxSearchActions from "../../../../redux/actions/mapboxSearchActions";
import CooperatorModal from "./CooperatorModal.tsx";
import "./form.css";
import validate from "./formValidation";

const NewEditFarm = ({
	clientId,
	getTokenSilently,
	userId,
	setSaveFunction,
	setIsDirty,
	filledForm,
	setFilledFormMember,
	mode = "new"
}) => {
	const dispatch = useDispatch();

	const [openCooperatorModal, setOpenCooperatorModal] = useState(false);
	const [stateOptions, setStateOptions] = useState([]);
	const [addressOptions, setAddressOptions] = useState([]);
	const [stateDropdownLoading, setStateDropdownLoading] = useState(true);
	const [cooperatorDropdownLoading, setCooperatorDropdownLoading] = useState(true);
	const [cooperatorCompanyOptions, setCooperatorCompanyOptions] = useState([]);
	const [valid, setValid] = useState(true);
	const [messages, setMessages] = useState({});
	const [companySaving, setCompanySaving] = useState(false);
	const [companyCodeGenerating, setCompanyCodeGenerating] = useState(false);
	const [newCompany, setNewCompany] = useState({});

	// const filledForm = useSelector((state) => state.farmForm ?? null);
	const setFilledForm = (form, silent = false) => {
		setFilledFormMember(form);
		if (!silent) {
			setIsDirty(true);
		}
	};
	const selectedStateId = useMemo(() => filledForm.stateId, [stateOptions, filledForm.stateId]);
	const selectedCooperatorCompanyId = useMemo(
		() => filledForm.cooperatorCompanyId,
		[cooperatorCompanyOptions, filledForm.cooperatorCompanyId]
	);

	useEffect(() => {
		getDropDowns();
		getCooperatorCompanyType();
		setFilledForm({ clientId: clientId }, true);
	}, []);

	// useEffect(() => {
	// 	if (mode === "edit" && farmId) {
	// 		getFarmInfo();
	// 	}
	// }, [farmId]);

	useEffect(() => {
		debouncedValidate(filledForm);
	}, [filledForm]);

	useEffect(() => {
		setNewCompany((prev) => ({ ...prev, clientId: clientId, userId: userId }));
	}, [userId, clientId]);

	useEffect(() => {
		if (newCompany.id) {
			setFilledForm({ cooperatorCompanyId: newCompany.id });
		}
	}, [newCompany, cooperatorCompanyOptions]);

	useEffect(() => {
		setSaveFunction(async () => {
			const validation = validate(filledForm, true);
			setValid(!validation.failed);
			setMessages(validation.messages);

			if (!validation.failed) {
				if (mode === "new") {
					return await createFarm();
				} else if (mode === "edit") {
					await updateFarm();
				}
			} else {
				throw "Farm information did not pass validation. Please fix any errors and try again.";
			}
		});
	}, [filledForm]);

	const getCooperatorCompanyType = async () => {
		const accessToken = await getTokenSilently();
		const res = await dispatch(companyActions.getCompanyTypes(clientId, accessToken));
		const cooperatorCompanyTypeId = res.data.find((t) => t.name === "Cooperator").id;
		setNewCompany((prev) => ({ ...prev, companyTypeIds: [cooperatorCompanyTypeId] }));
	};

	const getDropDowns = async () => {
		await getStatesProvinces();
		await getCooperatorCompanies();
	};

	const getStatesProvinces = async () => {
		const accessToken = await getTokenSilently();
		dispatch(lookupActions.getStates(clientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					setupStateOptions(res.data);
				} else {
					toast.error("Unable to load states. Please try again.");
				}
			})
			.catch(() => {
				toast.error("Unable to load states. Please try again.");
			});
	};

	const getCooperatorCompanies = async () => {
		const accessToken = await getTokenSilently();
		dispatch(companyActions.getCooperatorCompanies(clientId, accessToken))
			.then((res) => {
				if (res.data.length > 0) {
					setupCooperatorCompanyOptions(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	async function saveCooperatorCompany() {
		setCompanySaving(true);
		const accessToken = await getTokenSilently();
		dispatch(companyActions.createCompany(newCompany, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Created!");
					setCooperatorDropdownLoading(true);
					getCooperatorCompanies();
					setNewCompany((prev) => ({ ...prev, id: res.data }));
					setOpenCooperatorModal(false);
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setCompanySaving(false);
			})
			.catch((err) => {
				toast.error("Unable to create new company. Please try again.");
				setCompanySaving(false);
				console.log(err);
			});
	}

	async function generateCompanyCode() {
		setCompanyCodeGenerating(true);
		const accessToken = await getTokenSilently();
		if (newCompany.name) {
			dispatch(companyActions.generateCompanyCode(newCompany.clientId, newCompany.name, accessToken))
				.then((res) => {
					setNewCompany((prev) => ({ ...prev, companyCode: res.data }));
					setCompanyCodeGenerating(false);
				})
				.catch((err) => {
					console.log(err);
					toast.error("Failed to generate company code.");
					setCompanyCodeGenerating(false);
				});
		} else {
			toast.info("Please enter a company name to generate a company code.");
			setCompanyCodeGenerating(false);
		}
	}

	const setupStateOptions = (sOptions) => {
		const stateOpts = _.map(sOptions, ({ id, name }) => {
			const opt = { key: id, value: id, text: name };
			return opt;
		});
		setStateOptions(stateOpts);
		setStateDropdownLoading(false);
	};

	function setupCooperatorCompanyOptions(data) {
		const opts = _.map(data, ({ id, name }) => {
			const opt = { key: id, value: id, text: name };
			return opt;
		});
		setCooperatorCompanyOptions(opts);
		setCooperatorDropdownLoading(false);
	}

	const queryAddress = useCallback(async (query) => {
		const res = await dispatch(mapboxSearchActions.getSearchSuggestions(query, userId, { types: "address" }));
		const suggestionOptions = _.map(res.suggestions, (s) => ({
			key: s.mapbox_id,
			value: s,
			text: s.full_address
		}));

		setAddressOptions(suggestionOptions);
	}, []);

	const retrieveAddress = useCallback(async (suggestion) => {
		const res = await dispatch(mapboxSearchActions.retrieveFromSearchSuggestion(suggestion.mapbox_id, userId));
		return res.features[0];
	}, []);

	const debouncedQueryAddress = useCallback(_.debounce(queryAddress, 500), []);

	const handleSelection = async (data) => {
		const suggestion = data;
		const state = _.find(stateOptions, (s) => s.text === suggestion.context.region.name);
		const feature = await retrieveAddress(suggestion);

		setFilledForm({
			address1: suggestion.context.address.name,
			city: suggestion.context.place.name,
			stateId: state.value,
			zipCode: suggestion.context.postcode.name,
			county: suggestion.context.district?.name,
			lat: feature.geometry.coordinates[1],
			long: feature.geometry.coordinates[0]
		});
	};

	const debouncedValidate = useCallback(
		_.debounce((form) => {
			const validation = validate(form, false);
			setValid(!validation.failed);
			setMessages(validation.messages);
			return validation;
		}, 500),
		[]
	);

	const createFarm = useCallback(async () => {
		const accessToken = await getTokenSilently();
		try {
			const res = await dispatch(farmActions.createFarm(accessToken, filledForm));
			const newId = res.data;
			setIsDirty(false);
			return newId;
		} catch (err) {
			console.error(err);
			toast.error("Unable to create farm.");
		}
	}, [filledForm]);

	const updateFarm = useCallback(async () => {
		const accessToken = await getTokenSilently();
		try {
			await dispatch(farmActions.updateFarm(accessToken, filledForm));
			setIsDirty(false);
		} catch (err) {
			console.error(err);
			toast.error("Unable to update farm.");
		}
	}, [filledForm]);

	return (
		<>
			<Form style={{ width: "100%", maxWidth: 1000 }} error={!valid}>
				<Form.Group widths={"equal"}>
					<Form.Input
						label="Farm Name"
						id="form-input-name"
						required
						error={messages?.name ? { content: messages.name, pointing: "below" } : false}
						value={filledForm.name}
						onChange={(_e, data) => {
							setFilledForm({ name: data.value });
						}}
					/>
					<Form.Input
						label="Abbreviation"
						id="form-input-abbr"
						required
						error={messages?.abbreviation ? { content: messages.abbreviation, pointing: "below" } : false}
						value={filledForm.abbreviation}
						onChange={(_e, data) => {
							setFilledForm({ abbreviation: data.value });
						}}
					/>
				</Form.Group>
				<Form.Group widths={"equal"}>
					<Form.Dropdown
						label="Address 1"
						id="form-input-adr1"
						onSearchChange={(_e, { searchQuery }) => {
							debouncedQueryAddress(searchQuery);
						}}
						search={(opts) => opts}
						options={addressOptions}
						onChange={(_e, { value }) => {
							handleSelection(value);
						}}
						text={filledForm.address1}
						selection
					/>
					<Form.Input
						label="Address 2"
						id="form-input-adr2"
						error={messages?.address2 ? { content: messages.address2, pointing: "below" } : false}
						value={filledForm.address2}
						onChange={(_e, data) => {
							setFilledForm({ address2: data.value });
						}}
					/>
				</Form.Group>
				<Form.Group widths={"equal"}>
					<Form.Input
						required
						label="City"
						id="form-input-city"
						error={messages?.city ? { content: messages.city, pointing: "below" } : false}
						value={filledForm.city}
						onChange={(_e, data) => {
							setFilledForm({ city: data.value });
						}}
					/>
					<Form.Select
						required
						label="State"
						id="form-input-state"
						search
						error={messages?.stateId ? { content: messages.stateId, pointing: "below" } : false}
						value={selectedStateId}
						options={stateOptions}
						loading={stateDropdownLoading}
						onChange={(_e, data) => {
							setFilledForm({ stateId: data.value });
						}}
					/>
					<Form.Input
						label="Zip Code"
						id="form-input-zip"
						error={messages?.zipCode ? { content: messages.zipCode, pointing: "below" } : false}
						value={filledForm.zipCode}
						onChange={(_e, data) => {
							setFilledForm({ zipCode: data.value });
						}}
					/>
					<Form.Input
						label="County"
						id="form-input-cnty"
						error={messages?.county ? { content: messages.county, pointing: "below" } : false}
						value={filledForm.county}
						onChange={(_e, data) => {
							setFilledForm({ county: data.value });
						}}
					/>
				</Form.Group>
				<Form.Group widths={16}>
					<Form.Input
						label="Latitude"
						id="form-input-lat"
						width={4}
						type="number"
						error={messages?.lat ? { content: messages.lat, pointing: "below" } : false}
						value={filledForm.lat}
						onChange={(_e, data) => {
							setFilledForm({ lat: data.value });
						}}
					/>
					<Form.Input
						label="Longitude"
						id="form-input-long"
						width={4}
						type="number"
						error={messages?.long ? { content: messages.long, pointing: "below" } : false}
						value={filledForm.long}
						onChange={(_e, data) => {
							setFilledForm({ long: data.value });
						}}
					/>
					<Form.Select
						search
						label={
							<div style={{ display: "flex", gap: 10, alignItems: "center", margin: "0 0 0.28571429rem 0" }}>
								<label>Cooperator Company</label>
								<Button
									primary
									content="+ Add New"
									style={{ padding: "1px 6px", borderRadius: "6px", fontSize: "12px" }}
									onClick={(e) => {
										e.preventDefault();
										setOpenCooperatorModal(true);
									}}
								/>
							</div>
						}
						required
						width={8}
						value={selectedCooperatorCompanyId}
						options={cooperatorCompanyOptions}
						loading={cooperatorDropdownLoading}
						error={messages?.cooperatorCompanyId ? { content: messages.cooperatorCompanyId, pointing: "below" } : false}
						onChange={(_e, data) => {
							setFilledForm({ cooperatorCompanyId: data.value });
						}}
					/>
				</Form.Group>
				<Form.Group widths={16}>
					<Form.Input
						width={8}
						label="Land Owner"
						id="form-input-owner"
						error={messages?.owner ? { content: messages.owner, pointing: "below" } : false}
						value={filledForm.owner}
						onChange={(_e, data) => {
							setFilledForm({ owner: data.value });
						}}
					/>
				</Form.Group>
				<Form.Checkbox
					label="Is Active?"
					id="form-checkbox-active"
					checked={filledForm.isActive}
					onChange={(_e, data) => {
						setFilledForm({ isActive: data.checked });
					}}
				/>
				<Divider />
				<Form.Group widths={16}>
					<Form.TextArea
						label="Notes"
						width={16}
						id="form-textarea-notes"
						error={messages?.notes ? { content: messages.notes, pointing: "below" } : false}
						value={filledForm.notes}
						onChange={(_e, data) => {
							setFilledForm({ notes: data.value });
						}}
					/>
				</Form.Group>
			</Form>
			<CooperatorModal
				open={openCooperatorModal}
				setOpen={(isOpen) => setOpenCooperatorModal(isOpen)}
				generating={companyCodeGenerating}
				saving={companySaving}
				onGenerateCompanyCode={generateCompanyCode}
				onSaveCompany={saveCooperatorCompany}
				newCompany={newCompany}
				setNewCompany={setNewCompany}
			/>
		</>
	);
};

NewEditFarm.propTypes = {
	farmId: PropTypes.string,
	setSaveFunction: PropTypes.func.isRequired,
	clientId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	getTokenSilently: PropTypes.func.isRequired,
	setIsDirty: PropTypes.func,
	mode: PropTypes.string,
	filledForm: PropTypes.object,
	setFilledFormMember: PropTypes.func
};

export default NewEditFarm;
