import React, { useState, useEffect } from "react";

import { Segment, Form, Grid, Accordion, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "lodash";

import TrialSearchResults from "../Trials/TrialSearch/TrialSearchResults";
import TrialMetaFactorsModal from "../Trials/TrialMetaTags/TrialMetaFactorsModal.tsx";

const TrialSearch = ({
	unfilteredTrialList,
	filteredTrialList,
	trialStatuses,
	setTrialName,
	setTrialOwner,
	setCooperator,
	setCity,
	setState,
	setCompany,
	setDefStatus,
	loading,
	reset
}) => {
	//-- Search Options
	const [name, setName] = useState(null);
	const [owner, setOwner] = useState(null);
	const [trialCooperator, setTrialCooperator] = useState(null);
	const [trialCity, setTrialCity] = useState(null);
	const [trialState, setTrialState] = useState(null);
	const [trialCompany, setTrialCompany] = useState(null);
	const [trialDEFStatus, setTrialDEFStatus] = useState(null);

	const [metaFactorModalOpen, setMetaFactorsModalOpen] = useState(false);
	const [modalTrialId, setModalTrialId] = useState("");
	const [modalTrialName, setModalTrialName] = useState("");

	const defStatusOptions = [{ key: "All", value: "All", text: "All" }]
		.concat([{ key: "Exclusions", value: "Exclusions", text: "Exclusions Only" }])
		.concat([{ key: "Flags", value: "Flags", text: "Flags Only" }])
		.concat([{ key: "Both", value: "Both", text: "Flags & Exclusions" }])
		.concat([{ key: "No Annotations", value: "No Annotations", text: "No Annotations" }]);

	//-- UI Control
	const [accordionActive, setAccordionActive] = useState(true);

	//-- Resets all fields
	useEffect(() => {
		setName(null);
		setOwner(null);
		setTrialCooperator(null);
		setTrialCity(null);
		setTrialState(null);
		setTrialCompany(null);
		setTrialDEFStatus(null);
	}, [reset]);

	//-- Sends trial name back up to parent search page
	useEffect(() => {
		setTrialName(name);
	}, [name]);

	//-- Sends trial owner back up to parent search page
	useEffect(() => {
		setTrialOwner(owner);
	}, [owner]);

	//-- Sends cooperator back up to parent search page
	useEffect(() => {
		setCooperator(trialCooperator);
	}, [trialCooperator]);

	//-- Sends city back up to parent search page
	useEffect(() => {
		setCity(trialCity);
	}, [trialCity]);

	//-- Sends state back up to parent search page
	useEffect(() => {
		setState(trialState);
	}, [trialState]);

	//-- Sends company back up to parent search page
	useEffect(() => {
		setCompany(trialCompany);
	}, [trialCompany]);

	//-- Sends def status back up to parent search page
	useEffect(() => {
		setDefStatus(trialDEFStatus);
	}, [trialDEFStatus]);

	//-- Toggles accordian
	const handleAccordionClick = () => {
		setAccordionActive(!accordionActive);
	};

	const toggleMetaFactorModal = (trialId, trialName, open = true) => {
		if (trialId) {
			setModalTrialId(trialId);
			setModalTrialName(trialName);
			setMetaFactorsModalOpen(open);
		}
	};

	return (
		<>
			<Segment
				style={{
					marginTop: 10,
					backgroundColor: "var(--secondary-soy-bean-800)",
					border: "none",
					borderRadius: "8px"
				}}
			>
				<Accordion fluid>
					<Accordion.Title active={accordionActive} style={{ cursor: "unset", paddingBottom: 0 }}>
						<Grid>
							<Grid.Row style={{ paddingTop: 0, color: "white", gap: "12px" }}>
								<div
									onClick={() => handleAccordionClick()}
									style={{ cursor: "pointer", alignSelf: "end", margin: "0 1em" }}
								>
									<h2>
										<Icon name="dropdown" />
										Trials
									</h2>
								</div>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setName(value);
									}}
									onSearchChange={(event, { searchQuery }) => {
										setName(searchQuery);
									}}
									options={_.uniqBy(
										_.map(loading ? [] : unfilteredTrialList, (t) => {
											return { text: t.trialName, value: t.trialName };
										}),
										"value"
									).sort((a, b) => (a.value > b.value ? 1 : -1))}
									placeholder="Trial Name/ID"
									search
									searchQuery={name ?? ""}
									selection
									selectOnBlur={false}
									type="text"
									value={name ?? ""}
									disabled={loading}
									onOpen={() => {
										setName("");
									}}
								/>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setOwner(value);
									}}
									onSearchChange={(event, { searchQuery }) => {
										setOwner(searchQuery);
									}}
									options={_.uniqBy(
										_.map(
											loading
												? []
												: _.filter(unfilteredTrialList, (utl) => utl.trialOwnerFirstName && utl.trialOwnerLastName),
											(t) => {
												return {
													text: t.trialOwnerFirstName + " " + t.trialOwnerLastName,
													value: t.trialOwnerFirstName + " " + t.trialOwnerLastName
												};
											}
										),
										"value"
									).sort((a, b) => (a.value > b.value ? 1 : -1))}
									placeholder="Trial Owner"
									search
									searchQuery={owner ?? ""}
									selection
									selectOnBlur={false}
									type="text"
									value={owner ?? ""}
									disabled={loading}
									onOpen={() => {
										setOwner("");
									}}
								/>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setTrialCooperator(value);
									}}
									onSearchChange={(event, { searchQuery }) => {
										setTrialCooperator(searchQuery);
									}}
									options={_.uniqBy(
										_.map(loading ? [] : _.filter(unfilteredTrialList, (utl) => utl.cooperatorName), (t) => {
											return { text: t.cooperatorName, value: t.cooperatorName };
										}),
										"value"
									).sort((a, b) => (a.value > b.value ? 1 : -1))}
									placeholder="Cooperator"
									search
									searchQuery={trialCooperator ?? ""}
									selection
									selectOnBlur={false}
									type="text"
									value={trialCooperator ?? ""}
									disabled={loading}
									onOpen={() => {
										setTrialCooperator("");
									}}
								/>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setTrialCity(value);
									}}
									onSearchChange={(event, { searchQuery }) => {
										setTrialCity(searchQuery);
									}}
									options={_.uniqBy(
										_.map(loading ? [] : _.filter(unfilteredTrialList, (utl) => utl.city), (t) => {
											return { text: t.city, value: t.city };
										}),
										"value"
									).sort((a, b) => (a.value > b.value ? 1 : -1))}
									placeholder="City"
									search
									searchQuery={trialCity ?? ""}
									selection
									selectOnBlur={false}
									type="text"
									value={trialCity ?? ""}
									disabled={loading}
									onOpen={() => {
										setTrialCity("");
									}}
								/>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setTrialState(value);
									}}
									onSearchChange={(event, { searchQuery }) => {
										setTrialState(searchQuery);
									}}
									options={_.uniqBy(
										_.map(loading ? [] : _.filter(unfilteredTrialList, (utl) => utl.stateName), (t) => {
											return { text: t.stateName, value: t.stateName };
										}),
										"value"
									).sort((a, b) => (a.value > b.value ? 1 : -1))}
									placeholder="State"
									search
									searchQuery={trialState ?? ""}
									selection
									selectOnBlur={false}
									type="text"
									value={trialState ?? ""}
									disabled={loading}
									onOpen={() => {
										setTrialState("");
									}}
								/>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setTrialCompany(value);
									}}
									onSearchChange={(event, { searchQuery }) => {
										setTrialCompany(searchQuery);
									}}
									options={_.uniqBy(
										_.map(loading ? [] : _.filter(unfilteredTrialList, (utl) => utl.companyName), (t) => {
											return { text: t.companyName, value: t.companyName };
										}),
										"value"
									).sort((a, b) => (a.value > b.value ? 1 : -1))}
									placeholder="Company"
									search
									searchQuery={trialCompany ?? ""}
									selection
									selectOnBlur={false}
									type="text"
									value={trialCompany ?? ""}
									disabled={loading}
									onOpen={() => {
										setTrialCompany("");
									}}
								/>

								<Form.Dropdown
									fluid
									className="dashboardInput solid"
									openOnFocus
									onChange={(event, { value }) => {
										setTrialDEFStatus(value);
									}}
									options={defStatusOptions}
									placeholder="Annotation Status"
									search
									selection
									selectOnBlur={false}
									type="text"
									value={trialDEFStatus ?? ""}
									disabled={loading}
								/>
							</Grid.Row>
						</Grid>
					</Accordion.Title>
					<Accordion.Content active={accordionActive} style={{ maxHeight: "500px", overflowX: "auto", padding: 0 }}>
						<TrialSearchResults
							parentIsDashboard={true}
							trialList={loading ? [] : filteredTrialList}
							trialStatuses={trialStatuses}
							toggleMetaFactorModal={toggleMetaFactorModal}
						/>
					</Accordion.Content>
				</Accordion>
			</Segment>
			<TrialMetaFactorsModal
				open={metaFactorModalOpen}
				setOpen={setMetaFactorsModalOpen}
				trialId={modalTrialId}
				trialName={modalTrialName}
			/>
		</>
	);
};

TrialSearch.propTypes = {
	unfilteredTrialList: PropTypes.array,
	filteredTrialList: PropTypes.array.isRequired,
	trialStatuses: PropTypes.array.isRequired,
	setTrialName: PropTypes.func.isRequired,
	setTrialOwner: PropTypes.func.isRequired,
	setCooperator: PropTypes.func.isRequired,
	setCity: PropTypes.func.isRequired,
	setState: PropTypes.func.isRequired,
	setCompany: PropTypes.func.isRequired,
	setDefStatus: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	reset: PropTypes.bool.isRequired
};

export default TrialSearch;
