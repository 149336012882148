// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.actionsPopup .ui.selection.list > a.item {\n\tcolor: var(--hyperlink);\n\tborder-radius: 0;\n\tpadding: 10px 12px;\n}\n\n.ui.actionsPopup .ui.selection.list > a.item:hover {\n\tcolor: var(--hyperlink-hover);\n\tbackground-color: rgba(0, 0, 0, 0.05);\n}\n\n.ui.actionsPopup .ui.selection.ui.list .list > a.item i.icon,\n.ui.actionsPopup .ui.selection.ui.list > a.item i.icon {\n\tcolor: var(--hyperlink);\n}\n\n.ui.actionsPopup .ui.selection.ui.list .list > a.item:hover i.icon,\n.ui.actionsPopup .ui.selection.ui.list > a.item:hover i.icon {\n\tcolor: var(--hyperlink-hover);\n}\n\n.actionsTrigger {\n\tcolor: var(--hyperlink);\n}\n\n.ui.popup.actionsPopup {\n\t/* border: 1px solid var(--border); */\n\tpadding: 0;\n\tborder-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/Search/actions.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,6BAA6B;CAC7B,qCAAqC;AACtC;;AAEA;;CAEC,uBAAuB;AACxB;;AAEA;;CAEC,6BAA6B;AAC9B;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,qCAAqC;CACrC,UAAU;CACV,kBAAkB;AACnB","sourcesContent":[".ui.actionsPopup .ui.selection.list > a.item {\n\tcolor: var(--hyperlink);\n\tborder-radius: 0;\n\tpadding: 10px 12px;\n}\n\n.ui.actionsPopup .ui.selection.list > a.item:hover {\n\tcolor: var(--hyperlink-hover);\n\tbackground-color: rgba(0, 0, 0, 0.05);\n}\n\n.ui.actionsPopup .ui.selection.ui.list .list > a.item i.icon,\n.ui.actionsPopup .ui.selection.ui.list > a.item i.icon {\n\tcolor: var(--hyperlink);\n}\n\n.ui.actionsPopup .ui.selection.ui.list .list > a.item:hover i.icon,\n.ui.actionsPopup .ui.selection.ui.list > a.item:hover i.icon {\n\tcolor: var(--hyperlink-hover);\n}\n\n.actionsTrigger {\n\tcolor: var(--hyperlink);\n}\n\n.ui.popup.actionsPopup {\n\t/* border: 1px solid var(--border); */\n\tpadding: 0;\n\tborder-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"actionsPopup": "actionsPopup",
	"selection": "selection",
	"list": "list",
	"item": "item",
	"icon": "icon",
	"actionsTrigger": "actionsTrigger",
	"popup": "popup"
};
export default ___CSS_LOADER_EXPORT___;
