export default {
	user: null,
	clients: {
		currentId: null
	},
	protocolData: {
		id: null,
		protocolId: "",
		protocolName: "",
		protocolOwnerId: null,
		cropId: null,
		cropName: "",
		growingSeasonId: null,
		growingSeasonName: "",
		geographyId: null,
		treatments: null,
		replicates: null,
		plotSizeId: null,
		plotSizeName: "",
		plotSizeDescription: "",
		designId: null,
		meanComparisonId: null,
		alphaId: null,
		yield: null,
		rotation: "",
		genetics: "",
		herbicides: "",
		fertilizer: "",
		pesticides: "",
		other: "",
		objectives: "",
		regulatories: [],
		protocolVariables: [],
		owner: "",
		treatmentData: [],
		applications: []
	},
	uploadingOrthos: [],
	flightElevationOrthoUploads: [],
	flightMultibandOrthoUploads: [],
	orthoViewer: {
		currentFlightId: null,
		currentFlightDate: null,
		currentFlightYear: null,
		filteredFlightIds: [],
		filteredFlightDates: [],
		filters: { trialDates: [], fieldDates: [], cropDates: [], flightDates: [], flightIds: [] }
	},
	apiCallsInProgress: 0,
	timecourseMeanComparisonData: [],
	entityNames: {
		flightId: null,
		flightName: "",
		fieldId: null,
		fieldName: "",
		trialId: null,
		trialName: ""
	},
	clientLogoUri: null,
	autoUploadedOrthos: []
};
