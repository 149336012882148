import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment, Grid, Form, Button, Loader } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as growingSeasonActions from "../../../../redux/actions/growingSeasonActions";

import FieldSearchResults from "./FieldSearchResults";

import "./styles.css";

const FieldSearch = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	const DATE_FORMAT = "MM/DD/YYYY";

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [fieldSearchDataOptions, setFieldSearchDataOptions] = useState(null);
	const [growingSeasons, setGrowingSeasons] = useState([]);

	//-- Dropdown options
	const [cropOptions, setCropOptions] = useState([]);
	const [farmOptions, setFarmOptions] = useState([]);
	const [fieldOptions, setFieldOptions] = useState([]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [cooperatorCompanyOptions, setCooperatorCompanyOptions] = useState([]);
	const presetTimeOptions = [
		{
			key: "noDate",
			value: "noDate",
			text: "No Plant Date"
		},
		{
			key: "thisYear",
			value: "thisYear",
			text: "This Year"
		},
		{
			key: "thisMonth",
			value: "thisMonth",
			text: "This Month"
		},
		{
			key: "thisWeek",
			value: "thisWeek",
			text: "This Week"
		},
		{
			key: "lastYear",
			value: "lastYear",
			text: "Last Year"
		},
		{
			key: "lastMonth",
			value: "lastMonth",
			text: "Last Month"
		},
		{
			key: "lastWeek",
			value: "lastWeek",
			text: "Last Week"
		},
		{
			key: "custom",
			value: "custom",
			text: "Custom"
		},
		{
			key: "allDates",
			value: "allDates",
			text: "All Dates"
		}
	];

	//-- Selected filter options
	//-- When setting the default time filter to this year or last year, update these dates to getFullYear() (this year) or getFullYear() - 1
	//-- Also update the presetTimeId to either ("thisYear") or ("lastYear")
	//-- e.g.
	const [plantDateFrom, setPlantDateFrom] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [prevPlantDateFrom, setPrevPlantDateFrom] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [plantDateTo, setPlantDateTo] = useState(new Date(new Date().getFullYear() - 1, 11, 31));
	const [prevPlantDateTo, setPrevPlantDateTo] = useState(new Date(new Date().getFullYear() - 1, 11, 31));

	const [cropId, setCropId] = useState("all");
	const [growingSeasonId, setGrowingSeasonId] = useState(null);
	const [farmId, setFarmId] = useState("all");
	const [fieldId, setFieldId] = useState("all");
	const [cooperatorCompanyId, setCooperatorCompanyId] = useState("all");
	const [presetTimeId, setPresetTimeId] = useState("lastYear");
	const [searchAllClients, setSearchAllClients] = useState(false);
	const [hasSearchedAllClients, setHasSearchedAllClients] = useState(false);

	//-- Search results
	const [fieldSearchResults, setFieldSearchResults] = useState(null);
	const [filteredFieldList, setFilteredFieldList] = useState([]);
	const [trialData, setTrialData] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [loadingSearchResults, setLoadingSearchResults] = useState(true);

	useEffect(() => {
		if (clientId) {
			getFieldSearchDataOptions(clientId);
			getGrowingSeasons();
		}
	}, [clientId]);

	useEffect(() => {
		if (userAuth.isReady && fieldSearchDataOptions) {
			setLoadingSearchResults(true);
			setDropdownOptions();
			searchForFields(clientId);
			//-- DO NOT setLoadingSearchResults(false) because the loader will not show
			//-- The next useEffect will take care of setting it to false
		}
	}, [userAuth.isReady, fieldSearchDataOptions]);

	useEffect(() => {
		if (fieldSearchResults) {
			setLoadingSearchResults(true);
			handleFilter();
			setLoadingSearchResults(false);
		}
	}, [fieldSearchResults]);

	useEffect(() => {
		if (fieldSearchResults) {
			setDropdownOptions();
		}
	}, [searchAllClients]);

	async function getFieldSearchDataOptions(clientId) {
		const accessToken = await getTokenSilently();
		setLoadingSearchResults(true);
		dispatch(fieldActions.getFieldSearchDataOptions(clientId, searchAllClients, accessToken))
			.then((res) => {
				setFieldSearchDataOptions(res);
				//-- Don't set loading to false here because we are going to search after we get the options, so let that function set loading to false
			})
			.catch((err) => {
				toast.error("Error loading Field Search page. Please try again.");
				setLoadingSearchResults(false);
				console.log(err);
			});
	}

	const getGrowingSeasons = async () => {
		const accessToken = await getTokenSilently();
		dispatch(growingSeasonActions.getGrowingSeasonDetails(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setGrowingSeasons(res.data);
				} else {
					toast.error("Unable to load Growing Season data. Please try again.");
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load Growing Season data. Please try again.");
			});
	};

	async function searchForFields(clientId) {
		if (clientId && plantDateTo && plantDateFrom) {
			const accessToken = await getTokenSilently();
			setLoadingSearchResults(true);
			dispatch(
				fieldActions.searchForFields(
					{
						plantDateFrom: plantDateFrom.toDateString(DATE_FORMAT),
						plantDateTo: plantDateTo.toDateString(DATE_FORMAT)
					},
					clientId,
					searchAllClients,
					accessToken
				)
			)
				.then((res) => {
					setFieldSearchResultsState(res);
					setLoading(false);
					setLoadingSearchResults(false);
				})
				.catch((err) => {
					toast.error("Error searching for fields. Please try again.");
					setLoading(false);
					setLoadingSearchResults(false);
					console.log(err);
				});
		}
	}

	function setDropdownOptions() {
		setCropOptionsState(fieldSearchDataOptions.crops);
		setFarmOptionsState(fieldSearchDataOptions.farms);
		setFieldOptionsState(fieldSearchDataOptions.fields);
		setGrowingSeasonOptionsState(fieldSearchDataOptions.growingSeasons);
		setCooperatorCompanyOptionsState(
			_.filter(fieldSearchDataOptions.companies, (c) => {
				return c.isCooperator;
			})
		);
	}

	function setCropOptionsState(crops) {
		setCropOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(crops, ({ cropId, name }) => {
					const opt = { key: cropId, value: cropId, text: name };
					return opt;
				})
			)
		);
	}

	function setFarmOptionsState(farms) {
		farms = _.filter(farms, (f) => {
			if (searchAllClients === true || (searchAllClients === false && f.clientId === clientId)) {
				return f;
			}
		});
		setFarmOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(farms, ({ farmId, name, clientName }) => {
					const opt = {
						key: farmId,
						value: farmId,
						text: name,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
								<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
							</div>
						)
					};
					return opt;
				})
			)
		);

		if (
			farmId &&
			!_.some(farms, (f) => {
				return f.farmId === farmId;
			})
		) {
			setFarmId("all");
		}
	}

	function setFieldOptionsState(fields) {
		fields = _.filter(fields, (f) => {
			if (searchAllClients === true || (searchAllClients === false && f.clientId === clientId)) {
				return f;
			}
		});
		setFieldOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(fields, ({ id, name, clientName }) => {
					const opt = {
						key: id,
						value: id,
						text: name,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
								<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
							</div>
						)
					};
					return opt;
				})
			)
		);

		if (
			fieldId &&
			!_.some(fields, (f) => {
				return f.id === fieldId;
			})
		) {
			setFieldId("all");
		}
	}

	function setGrowingSeasonOptionsState(growingSeasons) {
		let tempGsOptions = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(growingSeasons, ({ growingSeasonId, name }) => {
				const opt = { key: growingSeasonId, value: growingSeasonId, text: name };
				return opt;
			})
		);
		setGrowingSeasonOptions(tempGsOptions);

		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(tempGsOptions, (gs) => gs.text.includes(currentGrowingSeason))?.value ?? tempGsOptions[0]?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);
	}

	function setCooperatorCompanyOptionsState(companies) {
		companies = _.filter(companies, (c) => {
			if (searchAllClients === true || (searchAllClients === false && c.clientId === clientId)) {
				return c;
			}
		});
		setCooperatorCompanyOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(companies, ({ id, name }) => {
					const opt = {
						key: id,
						value: id,
						text: name,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
								{/* <span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span> */}
							</div>
						)
					};
					return opt;
				})
			)
		);

		if (
			cooperatorCompanyId &&
			!_.some(companies, (c) => {
				return c.id === cooperatorCompanyId;
			})
		) {
			setCooperatorCompanyId("all");
		}
	}

	function setFieldSearchResultsState(searchResults) {
		if (searchResults) {
			setFieldSearchResults(searchResults);
		}
	}

	const handleFilter = () => {
		if (fieldSearchResults?.fieldInfos && fieldSearchResults?.trialInfos) {
			if (clientId) {
				var mPlantDateFrom = moment(plantDateFrom);
				var mPrevPlantDateFrom = moment(prevPlantDateFrom);
				var mPlantDateTo = moment(plantDateTo);
				var mPrevPlantDateTo = moment(prevPlantDateTo);

				if (searchAllClients === true && hasSearchedAllClients === false) {
					getFieldSearchDataOptions(clientId);
					setHasSearchedAllClients(true);
				} else if (!mPlantDateFrom.isSame(mPrevPlantDateFrom) || !mPlantDateTo.isSame(mPrevPlantDateTo)) {
					if (searchAllClients === false) {
						setHasSearchedAllClients(false);
					}
					searchForFields(clientId);
				}

				setPrevPlantDateFrom(plantDateFrom);
				setPrevPlantDateTo(plantDateTo);
			}

			let currentGs = getCurrentGrowingSeason();
			let currentGsSelection = _.find(growingSeasonOptions, (gs) => gs.text.includes(currentGs))?.value;
			const filteredList = fieldSearchResults.fieldInfos.filter((fi) => {
				return (
					//-- client
					(searchAllClients === true || (searchAllClients === false && fi.clientId === clientId)) &&
					//-- crop
					(cropId === "all" || fi.cropId === cropId) &&
					//-- farm
					(farmId === "all" || fi.farmId === farmId) &&
					//-- field
					(fieldId === "all" || fi.fieldId === fieldId) &&
					//-- growing season
					(growingSeasonId === "all" ||
						fi.growingSeasonId === growingSeasonId ||
						(growingSeasonId === null && fi.growingSeasonId === currentGsSelection)) &&
					//-- cooperator comapnies
					(cooperatorCompanyId === "all" || fi.cooperatorCompanyId === cooperatorCompanyId) &&
					((plantDateFrom === null && plantDateTo === null) || presetTimeId === "noDate" ? fi.plantDate === null : true)
				);
			});
			setFilteredFieldList(filteredList);
			setTrialData(fieldSearchResults.trialInfos);
		}
	};

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	const resetFilter = () => {
		setPlantDateFrom(new Date(new Date().getFullYear() - 1, 0, 1));
		setPlantDateTo(new Date(new Date().getFullYear() - 1, 11, 31));
		setPresetTimeId("lastYear");
		setCropId("all");
		setFarmId("all");
		setFieldId("all");
		setCooperatorCompanyId("all");
		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection = _.find(growingSeasonOptions, (gs) =>
			gs.text.includes(currentGrowingSeason)
		)?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);
	};

	useEffect(() => {
		if (presetTimeId) {
			if (presetTimeId !== "custom") {
				setPrevPlantDateFrom(plantDateFrom);
				setPrevPlantDateTo(plantDateTo);
			}

			if (presetTimeId === "thisYear") {
				setPlantDateFrom(new Date(new Date().getFullYear(), 0, 1));
				setPlantDateTo(new Date(new Date().getFullYear(), 11, 31));
			} else if (presetTimeId === "thisMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

				setPlantDateFrom(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
				setPlantDateTo(new Date(new Date().getFullYear(), new Date().getMonth(), lastDayOfMonth));
			} else if (presetTimeId === "thisWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 1));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff + 5));

				setPlantDateFrom(beginningOfWeek);
				setPlantDateTo(endOfWeek);
			} else if (presetTimeId === "lastYear") {
				setPlantDateFrom(new Date(new Date().getFullYear() - 1, 0, 1));
				setPlantDateTo(new Date(new Date().getFullYear() - 1, 11, 31));
			} else if (presetTimeId === "lastMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
				let year = new Date().getFullYear();
				if (new Date().getMonth() === 1) {
					year = new Date().getFullYear() - 1;
				}

				setPlantDateFrom(new Date(year, new Date().getMonth() - 1, 1));
				setPlantDateTo(new Date(year, new Date().getMonth() - 1, lastDayOfMonth));
			} else if (presetTimeId === "lastWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 8));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff - 2));

				setPlantDateFrom(beginningOfWeek);
				setPlantDateTo(endOfWeek);
			} else if (presetTimeId === "allDates") {
				setPlantDateFrom(new Date(2001, 0, 1));
				setPlantDateTo(new Date(2099, 0, 1));
				setGrowingSeasonId("all");
			} else if (presetTimeId === "noDate") {
				setPlantDateFrom(null);
				setPlantDateTo(null);
			}
		}
	}, [presetTimeId]);

	return loading ? (
		<Loader active />
	) : !loading && (!fieldSearchDataOptions || !fieldOptions || (fieldOptions && fieldOptions.length === 0)) ? (
		<Segment style={{ width: "100%", background: "white", margin: 15 }}>
			<Segment>
				<p>{"Field Search could not be loaded. Please try again. If the problem persists, please contact support."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment basic>
			<Form onSubmit={handleFilter}>
				<Grid id="fieldSearchGrid" verticalAlign="middle">
					<Form.Field
						id="form-date-time-plant-date-from"
						control={DateTimePicker}
						label="Plant Date From"
						includeTime={false}
						value={plantDateFrom}
						required
						error={!plantDateFrom && presetTimeId !== "noDate"}
						disabled={loadingSearchResults}
						onChange={(date) => {
							setPresetTimeId("custom");
							if (date) {
								date.setHours(0, 0, 0, 0);
								setPlantDateFrom(date);
							} else {
								setPlantDateFrom(null);
							}
							setGrowingSeasonId("all");
						}}
					/>
					<Form.Field
						id="form-date-time-plant-date-to"
						control={DateTimePicker}
						label="Plant Date To"
						includeTime={false}
						value={plantDateTo}
						required
						error={!plantDateTo && presetTimeId !== "noDate"}
						disabled={loadingSearchResults}
						onChange={(date) => {
							setPresetTimeId("custom");
							if (date) {
								date.setHours(0, 0, 0, 0);
								setPlantDateTo(date);
							} else {
								setPlantDateTo(null);
							}
							setGrowingSeasonId("all");
						}}
					/>
					<Form.Select
						id="form-select-preset-times"
						label="Date Range"
						options={presetTimeOptions}
						value={presetTimeId}
						disabled={loadingSearchResults}
						onChange={(event, { value }) => {
							setPresetTimeId(value);
							setGrowingSeasonId("all");
						}}
					/>
					<Form.Select
						id="form-select-growing-season"
						label="Growing Season"
						options={growingSeasonOptions}
						search
						value={growingSeasonId}
						onChange={(event, { value }) => {
							setGrowingSeasonId(value);
							if (value === "all") {
								setPlantDateFrom(new Date(2001, 0, 1));
								setPlantDateTo(new Date(2099, 0, 1));
							} else {
								let gsMatch = _.filter(growingSeasons, (gs) => {
									return gs.id === value;
								});
								setPlantDateFrom(new Date(gsMatch[0].startYear, 0, 1));
								setPlantDateTo(new Date(gsMatch[0].endYear, 11, 31));
							}
							setPresetTimeId("custom");
						}}
						disabled={loadingSearchResults}
						loading={!growingSeasonOptions || growingSeasonOptions.length === 0}
					/>
					<Form.Select
						id="form-select-farm"
						label="Farm"
						options={farmOptions}
						selection
						search
						value={farmId}
						onChange={(event, { value }) => {
							setFarmId(value);
						}}
						disabled={loadingSearchResults}
						loading={!farmOptions || farmOptions.length === 0}
					/>
					<Form.Select
						id="form-select-crop"
						label="Crop"
						options={cropOptions}
						search
						value={cropId}
						onChange={(event, { value }) => {
							setCropId(value);
						}}
						disabled={loadingSearchResults}
						loading={!cropOptions || cropOptions.length === 0}
					/>
					<Form.Select
						id="form-select-state"
						label="Field"
						options={fieldOptions}
						value={fieldId}
						search
						onChange={(event, { value }) => {
							setFieldId(value);
						}}
						disabled={loadingSearchResults}
						loading={!fieldOptions || fieldOptions.length === 0}
					/>
					<Form.Select
						id="form-select-cooperator-company"
						label="Cooperator"
						options={cooperatorCompanyOptions}
						value={cooperatorCompanyId}
						search
						onChange={(event, { value }) => {
							setCooperatorCompanyId(value);
						}}
						disabled={loadingSearchResults}
						loading={!cooperatorCompanyOptions || cooperatorCompanyOptions.length === 0}
					/>
					<Form.Checkbox
						id="form-search-all-clients"
						label="Search All Clients"
						checked={searchAllClients}
						disabled={loadingSearchResults}
						onChange={(e, { checked }) => {
							setSearchAllClients(checked);
						}}
					/>
					<div className="buttons">
						<Button
							id="form-button-clear-filter"
							content="Reset"
							negative
							disabled={loadingSearchResults}
							onClick={resetFilter}
						/>
						<Button id="form-button-filter" primary disabled={loadingSearchResults} content="Filter" />
					</div>
				</Grid>
			</Form>
			{loadingSearchResults ? (
				<Loader active inline="centered" />
			) : (
				<FieldSearchResults fieldList={filteredFieldList} trialData={trialData} userAuth={userAuth} />
			)}
		</Segment>
	);
};

export default FieldSearch;
