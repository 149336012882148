import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";
import _ from "lodash";
import moment from "moment";
import * as timecourseCalculations from "../../../Lumber/AreaUnderTheCurve";
const LineChart = ({ data, analysisName, cumulativeGDUs, gduByDay, useGDU, hideLineCharts }) => {
	const CanvasJSChart = CanvasJSReact.CanvasJSChart;
	const DATE_FORMAT = "M/D";

	//-- Data
	const [groupedTreatments, setGroupedTreatments] = useState([]);
	const [chartOptions, setChartOptions] = useState({});
	const [cumulativeGDU, setCumulativeGDU] = useState([]);
	const [noData, setNoData] = useState(true);

	useEffect(() => {
		if (data.length > 0) {
			setNoData(false);
			let treatments = _.groupBy(data, "trialTreatmentId");
			setGroupedTreatments(treatments);
			if (gduByDay) {
				let calculatedCumulativeGDU = timecourseCalculations.calculateCumulativeGdu(
					_.find(treatments, (t) => {
						return t !== undefined;
					}),
					gduByDay
				);
				setCumulativeGDU(calculatedCumulativeGDU);
			}
		} else {
			setNoData(true);
		}
	}, [data]);

	useEffect(() => {
		if (cumulativeGDUs) {
			setCumulativeGDU(cumulativeGDUs);
		}
	}, [cumulativeGDUs]);

	useEffect(() => {
		if (useGDU) {
			createChartForGDU();
		} else {
			createChartForDays();
		}
	}, [groupedTreatments, useGDU, cumulativeGDU]);

	function createChartForGDU() {
		let dataObject = [];
		_.map(groupedTreatments, (treatments, trialIndex) => {
			let dataPoints = [];
			_.map(treatments, (treatment) => {
				let gdu = _.find(cumulativeGDU, (g) => {
					return g.flightId === treatment.flightId;
				});
				if (gdu === undefined) {
					if (treatment?.isPlantFlight) {
						gdu = [];
						gdu.gdu = 0;
					} else if (treatment?.isHarvestFlight) {
						gdu = cumulativeGDU[cumulativeGDU.length - 1];
					}
				}
				const data = {
					//convert to number when you calculate this value
					x: Number(gdu?.gdu ?? 0),
					y: hideLineCharts ? "" : treatment.trialAnalysisResultAverageValue,
					label: gdu?.gdu.toString()
				};
				dataPoints.push(data);
			});

			let data = {
				indexLabelFontColor: "darkSlateGray",
				name: trialIndex,
				type: "line",
				showInLegend: true,
				toolTipContent: `${trialIndex} - {label}: {y}`,
				dataPoints: dataPoints
			};
			dataObject.push(data);
		});

		let opt = {
			animationEnabled: true,
			theme: "light2",
			axisY: {
				title: analysisName,
				includeZero: false
			},
			legend: {
				verticalAlign: "top",
				horizontalAlign: "center",
				dockInsidePlotArea: false
			},
			data: dataObject
		};
		setChartOptions(opt);
	}

	function createChartForDays() {
		let dataObject = [];
		_.map(groupedTreatments, (treatments, trialIndex) => {
			let dataPoints = [];
			_.map(treatments, (treatment) => {
				const dateUtc = treatment.flightDate + "Z";
				let date = new Date(dateUtc);
				date.setHours(0, 0, 0, 0);
				const data = {
					x: new Date(date),
					y: hideLineCharts ? "" : treatment.trialAnalysisResultAverageValue,
					label: moment(treatment.flightDate).local().format(DATE_FORMAT)
				};
				dataPoints.push(data);
			});

			let data = {
				indexLabelFontColor: "darkSlateGray",
				name: trialIndex,
				type: "line",
				showInLegend: true,
				toolTipContent: `${trialIndex} - {label}: {y}`,
				dataPoints: dataPoints
			};
			dataObject.push(data);
		});

		let opt = {
			animationEnabled: true,
			theme: "light2",
			backgroundColor: "transparent",
			axisX: { valueFormatString: DATE_FORMAT },
			axisY: {
				title: analysisName,
				includeZero: false
			},
			legend: {
				verticalAlign: "top",
				horizontalAlign: "center",
				dockInsidePlotArea: false
			},
			data: dataObject
		};
		setChartOptions(opt);
	}

	return (
		<Segment basic style={{ borderRadius: "8px", border: "1px solid var(--border)", background: "#ffffffe6" }}>
			{noData == true ? (
				<p> There is no data selected for the line chart.</p>
			) : (
				<CanvasJSChart
					options={chartOptions}
					containerProps={{ width: "100%", position: "relative", height: "400px" }}
				/>
			)}
		</Segment>
	);
};

LineChart.propTypes = {
	data: PropTypes.array.isRequired,
	analysisName: PropTypes.string,
	cumulativeGDUs: PropTypes.array,
	gduByDay: PropTypes.array,
	useGDU: PropTypes.bool,
	hideLineCharts: PropTypes.bool
};

export default LineChart;
