import React, { useState } from "react";
import PropTypes from "prop-types";

import { Icon, Popup } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import Converter from "./Converter";

const UnitConverter = ({
	hidden = false,
	defaults = null,
	userConfigurable = false,
	changeUnit1 = true,
	twoWayConversion = false,
	onUnitConverted = () => {}
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return hidden ? (
		<></>
	) : (
		<>
			<Popup
				className="unitConverterPopup"
				trigger={
					<Icon.Group size="large" style={{ height: "fit-content", alignSelf: "flex-end", marginBottom: 8 }}>
						<Icon name="calculator" />
						<Icon corner="bottom right" name="sync" />
					</Icon.Group>
				}
				basic
				position="right center"
				flowing
				content={
					<Converter
						defaults={defaults}
						userConfigurable={userConfigurable}
						changeUnit1={changeUnit1}
						twoWayConversion={twoWayConversion}
						onClose={() => setIsOpen(false)}
						onAccept={(data) => {
							onUnitConverted(data);
							setIsOpen(false);
						}}
					/>
				}
				on="click"
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
				onOpen={() => {
					setIsOpen(true);
				}}
			/>
		</>
	);
};

UnitConverter.propTypes = {
	hidden: PropTypes.bool,
	defaults: PropTypes.object,
	userConfigurable: PropTypes.bool,
	changeUnit1: PropTypes.bool,
	twoWayConversion: PropTypes.bool,
	onUnitConverted: PropTypes.func
};

export default UnitConverter;
