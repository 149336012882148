// import ProtocolSearch from "./Content/Protocols/ProtocolSearch";
import ProtocolProcess from "./Content/Protocols/ProtocolProcess";
import ProtocolLog from "./Content/Protocols/ProtocolLog";
import ProtocolOverview from "./Content/Protocols/Overview";

import { guidRegex } from "../utilities/regex";
import ProtocolAnalysis from "./Content/Protocols/ProtocolAnalysis";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const protocolRoutes = [
	{
		id: "protocol-search",
		name: "search",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Search",
		pageTitle: "Protocol Search",
		icon: "search",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/protocols`, `/protocols/search`],
		exact: true,
		component: ProtocolLog,
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolSearch],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "protocol-new",
		name: "new",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "New",
		pageTitle: "Create New Protocol",
		icon: "add",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/protocols/new`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "info", mode: "new" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolNew],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "protocol-info",
		navMatchingNames: ["info", "applications", "treatments", "assessments", "associate-trials", "summary"],
		name: "info",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		pageTitle: "Edit Protocol",
		icon: "pencil",
		cornerIcon: undefined,
		showInNav: true,
		secured: true,
		paths: [`/protocols/:id${guidRegex}?/info`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "info", mode: "edit" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolEdit],
		requiresContext: true,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "protocol-applications",
		navMatchingNames: ["info", "applications", "treatments", "assessments", "associate-trials", "summary"],
		name: "applications",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Applications",
		pageTitle: "Protocol Applications",
		icon: "folder open",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/protocols/:id${guidRegex}/applications`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "applications" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolEdit],
		requiresContext: true
	},
	{
		id: "protocol-treatments",
		navMatchingNames: ["info", "applications", "treatments", "assessments", "associate-trials", "summary"],
		name: "treatments",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Treatments",
		pageTitle: "Protocol Treatments",
		icon: "folder open",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/protocols/:id${guidRegex}/treatments`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "treatments" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolEdit],
		requiresContext: true
	},
	{
		id: "protocol-assessments",
		navMatchingNames: ["info", "applications", "treatments", "assessments", "associate-trials", "summary"],
		name: "assessments",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Assessments",
		pageTitle: "Protocol Assessments",
		icon: "folder open",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/protocols/:id${guidRegex}/assessments`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "assessments" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolEdit],
		requiresContext: true
	},
	{
		id: "protocol-associate-trials",
		navMatchingNames: ["info", "applications", "treatments", "assessments", "associate-trials", "summary"],
		name: "associate-trials",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Associate Trials",
		pageTitle: "Associate Trials to Protocol",
		icon: "folder open",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/protocols/:id${guidRegex}/associate-trials`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "associateTrials" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolEdit],
		requiresContext: true
	},
	{
		id: "protocol-summary",
		navMatchingNames: ["info", "applications", "treatments", "assessments", "associate-trials", "summary"],
		name: "summary",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Summary",
		pageTitle: "Protocol Summary",
		icon: "folder open",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/protocols/:id${guidRegex}/summary`],
		exact: true,
		component: () => ProtocolProcess({ activeStep: "summary" }),
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolEdit],
		requiresContext: true
	},
	{
		id: "overview-status",
		name: "overview-status",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Overview and Status",
		pageTitle: "Protocol Overview and Status",
		icon: "dashboard",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/protocols/:id${guidRegex}`, `/protocols/:id${guidRegex}/overview-status`],
		exact: true,
		component: ProtocolOverview,
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolOverview],
		requiresContext: true
	},
	{
		id: "analysis",
		name: "analysis",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Analysis",
		pageTitle: "Protocol Analysis",
		icon: "chart bar outline",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/protocols/:id${guidRegex}/analysis`],
		exact: true,
		component: ProtocolAnalysis,
		module: "protocols",
		applicationAreas: [ApplicationArea.ProtocolAnalysis],
		requiresContext: true
	}
];

export default {
	protocolRoutes
};
