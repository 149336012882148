import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuth0 } from "../../../../auth/auth0";

import { DateTimePicker } from "react-widgets";
import { Button, Form, Grid, Modal } from "semantic-ui-react";

import * as userLicenseActions from "../../../../redux/actions/userLicenseActions";

import { validateForm, validObject } from "./formValidation";

const LmAddUserLicense = ({ clientOptions, potentialUserOptions, licenseOptions, onCancel, onUserLicenseAdded }) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Filtered Data
	const [filteredLicenseOptions, setFilteredLicenseOptions] = useState(licenseOptions);

	//-- UI Control
	const [adding, setAdding] = useState(false);
	const [validation, setValidation] = useState(validObject());

	//-- Selected Data
	const [selectedClient, setSelectedClient] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedLicense, setSelectedLicense] = useState(null);
	const [signUpDate, setSignUpDate] = useState(null);
	const [expiresDate, setExpiresDate] = useState(null);

	useEffect(() => {
		if (selectedClient !== null) {
			const apAdmin = _.find(licenseOptions, (lOpt) => {
				return lOpt?.text === "ApAdmin";
			})?.value;

			const selectedClientName = _.find(clientOptions, (cOpt) => {
				return cOpt.value === selectedClient;
			});
			if (selectedClientName.text !== "aerialPLOT") {
				if (selectedLicense === apAdmin) {
					setSelectedLicense(null);
				}
				const flOpt = _.filter(licenseOptions, (lOpt) => {
					return lOpt.value !== apAdmin;
				});
				setFilteredLicenseOptions(flOpt);
			} else {
				setFilteredLicenseOptions(licenseOptions);
			}
		}
	}, [selectedClient]);

	const addUserLicense = async () => {
		const userLicenseObject = getUserLicenseObject();
		const formValidation = validateForm(userLicenseObject);
		setValidation(formValidation);
		if (
			formValidation.clientIdError === true ||
			formValidation.userIdError === true ||
			formValidation.licenseIdError === true ||
			formValidation.signUpDateError === true ||
			formValidation.expiresDateError === true
		) {
			toast.error("All fields must be filled out.");
			return;
		}
		setAdding(true);
		const accessToken = await getTokenSilently();
		dispatch(userLicenseActions.addUserLicense(accessToken, userLicenseObject))
			.then((res) => {
				if (res.statusCode === 200) {
					onUserLicenseAdded();
					toast.success("User License saved successfully");
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onUserLicenseAdded();
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}

				setAdding(false);
			})
			.catch((err) => {
				toast.error("Unable to save user license. Please try again.");
				console.log(err);
				setAdding(false);
			});
	};

	const getUserLicenseObject = () => {
		let ulObject = {
			clientId: selectedClient,
			userId: selectedUser,
			licenseId: selectedLicense,
			signUpDate: signUpDate,
			expiresDate: expiresDate
		};

		return ulObject;
	};

	return (
		<>
			<Modal.Content>
				<Form>
					<Grid id="license-management-grid" verticalAlign="middle" columns="equal">
						<Grid.Row hidden>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-client">Client*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="14">
								<Form.Select
									selection
									placeholder="Select client"
									search
									options={clientOptions ?? []}
									loading={!clientOptions}
									value={selectedClient}
									error={validation.clientIdError}
									onChange={(event, { value }) => {
										setSelectedClient(value);
									}}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-user">User*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="14">
								<Form.Select
									selection
									placeholder="Select user"
									search
									options={potentialUserOptions ?? []}
									loading={!potentialUserOptions}
									value={selectedUser}
									error={validation.userIdError}
									onChange={(event, { value }) => {
										setSelectedUser(value);
									}}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-user">License*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="14">
								<Form.Select
									selection
									placeholder="Select license"
									options={filteredLicenseOptions ?? []}
									loading={!filteredLicenseOptions}
									value={selectedLicense}
									error={validation.licenseIdError}
									onChange={(event, { value }) => {
										setSelectedLicense(value);
									}}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-user">Sign Up Date*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="6">
								<Form.Field
									id="form-sign-up-date"
									control={DateTimePicker}
									includeTime={false}
									value={signUpDate}
									error={validation.signUpDateError}
									onChange={(date) => {
										setSignUpDate(date);
									}}
								/>
							</Grid.Column>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-user">Expires Date*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="6">
								<Form.Field
									id="form-expires-date"
									control={DateTimePicker}
									includeTime={false}
									value={expiresDate}
									error={validation.expiresDateError}
									onChange={(date) => {
										setExpiresDate(date);
									}}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button id="form-button-cancel" type="button" content="Cancel" onClick={onCancel} disabled={adding} />
				<Button
					id="form-button-save"
					type="button"
					primary
					content="Assign"
					onClick={addUserLicense}
					loading={adding}
					disabled={adding}
				/>
			</Modal.Actions>
		</>
	);
};

LmAddUserLicense.propTypes = {
	clientOptions: PropTypes.array,
	potentialUserOptions: PropTypes.array,
	licenseOptions: PropTypes.array,
	clientId: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	onUserLicenseAdded: PropTypes.func.isRequired
};
export default LmAddUserLicense;
