// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.form .fields .field {\n\tposition: relative;\n}\n\n.ui.pointing.label.prompt {\n\tposition: absolute;\n\ttop: 0;\n\tright: 0.5em;\n\tz-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Farms/NewEditFarm/form.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,MAAM;CACN,YAAY;CACZ,UAAU;AACX","sourcesContent":[".ui.form .fields .field {\n\tposition: relative;\n}\n\n.ui.pointing.label.prompt {\n\tposition: absolute;\n\ttop: 0;\n\tright: 0.5em;\n\tz-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"form": "form",
	"fields": "fields",
	"field": "field",
	"pointing": "pointing",
	"label": "label",
	"prompt": "prompt"
};
export default ___CSS_LOADER_EXPORT___;
