import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";

import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { Dimmer, Grid, Loader, Segment, Transition } from "semantic-ui-react";

import _ from "lodash";
import { useCookies } from "react-cookie";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { useUserSettings } from "../../../hooks/useUserSettings";

import AP_Header from "./Header";
import LeftNav from "./LeftNav";
import LeftNavSecondary from "./LeftNavSecondary";

import * as entityActions from "../../../redux/actions/entityActions";
import * as trialActions from "../../../redux/actions/trialActions";
import * as userActions from "../../../redux/actions/userActions";

import "./icons.css";

const unsetTopBottomPadding = {
	paddingTop: "unset",
	paddingBottom: "unset"
};

const Layout = ({ children }) => {
	//-- Instantiate hooks
	const dispatch = useDispatch();
	const { loading, isAuthenticated, getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();

	//-- Data Sources
	const user = useSelector((state) => (state.user ? state.user : null));
	const currentClientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [useSettingsNav, setUseSettingsNav] = useState(moduleNavigation.activeModule.settings);
	const [cookies] = useCookies(["eulaAgreement"]);
	const [eulaAgreement, setEulaAgreement] = useState(false);
	const [entityNames, setEntityNames] = useState(null);

	//-- UI Control
	const [gettingUser, setGettingUser] = useState(false);

	//-- Application Insights
	const appInsights = useAppInsightsContext();
	const trackUser = useTrackEvent(appInsights, "User Login", user);

	useEffect(() => {
		let eulaAgreementFromCookie = _.some(cookies.eulaAgreement, (ua) => {
			if (user !== null && user.userInfo !== null) {
				return ua.userId === user.userInfo.basic.userId;
			}
		});

		setEulaAgreement(eulaAgreementFromCookie);

		if (!moduleNavigation.activeModule.logout) {
			if (isAuthenticated && !user) {
				setGettingUser(true);
				getUserInfo();
			} else if (isAuthenticated && user && user.setupNeeded) {
				moduleNavigation.goToSetupNeeded();
				setGettingUser(false);
			} else if (userAuth.isReady && isAuthenticated && user) {
				if (!eulaAgreementFromCookie) {
					moduleNavigation.goToEula();
				} else if (!userAuth.isSubsriptionActive && !moduleNavigation.savedPathIsLink()) {
					moduleNavigation.goToSubscriptionExpired();
				}
			}
		}
	}, [isAuthenticated, user, cookies, userAuth.isReady]);

	useEffect(() => {
		setUseSettingsNav(moduleNavigation.activeModule.settings);
	}, [moduleNavigation.activeModule.settings]);

	useEffect(() => {
		if (!userAuth.isSubsriptionActive && userAuth.isReady === true) {
			moduleNavigation.goToSubscriptionExpired();
		}
	}, [userAuth.isSubsriptionActive]);

	async function getUserInfo() {
		const accessToken = await getTokenSilently();
		dispatch(userActions.logIn(accessToken)).then(() => {
			if (!userSettings.currentClientLogoUri) {
				userSettings.getClientLogoUri();
			}
		});
	}

	useEffect(() => {
		if (userAuth.isReady) {
			checkClient();
			getEntityName();
		}
	}, [userAuth.isReady, moduleNavigation.currentLocation]);

	useEffect(() => {
		if (user) {
			trackUser(user.userInfo?.basic);
		}
	}, [user]);

	async function checkClient() {
		const accessToken = await getTokenSilently();
		let switchingClients = false;
		dispatch(
			entityActions.checkClient(
				moduleNavigation.flightId,
				moduleNavigation.fieldId,
				moduleNavigation.trialId,
				moduleNavigation.protocolId,
				moduleNavigation.farmId,
				currentClientId,
				moduleNavigation.activeModule.isStrict ?? moduleNavigation.activeSubmodule.isStrict ?? false,
				accessToken
			)
		).then((res) => {
			if (
				res &&
				currentClientId !== res &&
				user.userInfo.clientMemberships.filter((client) => client.clientId === res)
			) {
				userAuth.switchClient(currentClientId, res);
				switchingClients = true;
				if (isAuthenticated && user && !user?.setupNeeded) {
					setGettingUser(false);
				}
			} else if (
				moduleNavigation.flightId ||
				moduleNavigation.fieldId ||
				moduleNavigation.trialId ||
				moduleNavigation.farmId
			) {
				if (!res) {
					moduleNavigation.goToAccessDenied();
				} else if (!userAuth.isSubsriptionActive) {
					moduleNavigation.goToSubscriptionExpired();
				}
			}
			if (!switchingClients) {
				check();
			}
		});
	}

	const getEntityName = async () => {
		if (
			moduleNavigation.flightId ||
			moduleNavigation.fieldId ||
			moduleNavigation.trialId ||
			moduleNavigation.farmId ||
			moduleNavigation.protocolId
		) {
			const accessToken = await getTokenSilently();
			const res = await dispatch(
				entityActions.getEntityNames(
					moduleNavigation.flightId,
					moduleNavigation.fieldId,
					moduleNavigation.trialId,
					moduleNavigation.farmId,
					moduleNavigation.protocolId,
					accessToken
				)
			);

			setEntityNames(res);
		} else {
			setEntityNames(null);
		}
	};

	function check() {
		if (
			userAuth.userCanAccessModule(moduleNavigation.activeModule) &&
			userAuth.userCanAccessSubmodule(moduleNavigation.activeSubmodule)
		) {
			//-- Set published trial as viewed (see api for implementation)
			if (moduleNavigation.qsPtfk) {
				setPublishedTrialFlightViewed(moduleNavigation.qsPtfk);
			}
			setGettingUser(false);
		} else {
			moduleNavigation.goToAccessDenied();
		}
	}

	async function setPublishedTrialFlightViewed(qsPtfk) {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.setPublishedTrialFlightViewed(qsPtfk, accessToken));
	}

	return loading || gettingUser ? (
		<Loader active />
	) : (
		<>
			{isAuthenticated && !moduleNavigation.activeModule.logout && !user.setupNeeded && eulaAgreement
				? userAuth.isReady && !userAuth.isSubsriptionActive
					? renderSubscriptionExpired(children)
					: renderAuthenticated(
							children,
							useSettingsNav,
							moduleNavigation.allModules,
							moduleNavigation.activeModule,
							moduleNavigation.currentSubmodules,
							moduleNavigation.activeSubmodule,
							userAuth,
							entityNames
					  )
				: renderUnauthenticated(moduleNavigation.activeModule.secured, children)}
		</>
	);
};

const renderUnauthenticated = (securedRoute, children) => {
	return (
		<Grid id="rootGrid" centered style={{ height: "100%", backgroundColor: "var(--nav-background)" }}>
			<Grid.Row id="rootGridContentRow" verticalAlign="middle" style={{ ...unsetTopBottomPadding }}>
				<Grid.Column width={7}>
					{/* Acounts for the case where the user navigates to a secured component w/o first logging */}
					{securedRoute ? (
						<Segment textAlign="center" style={{ minHeight: 116 }}>
							<Dimmer inverted active>
								<Loader inverted>Logging in</Loader>
								{children}
							</Dimmer>
						</Segment>
					) : (
						<>{children}</>
					)}
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

const renderAuthenticated = (
	children,
	useSettingsNav,
	rootModules,
	activeModule,
	submodules,
	activeSubmodule,
	userAuth,
	entityNames
) => {
	return (
		<Grid id="rootGrid" stretched>
			<Grid.Row id="rootGridHeaderRow" style={unsetTopBottomPadding}>
				<Grid.Column id="rootGridHeaderCol" style={{ padding: 0 }}>
					<AP_Header entityNames={entityNames} />
				</Grid.Column>
			</Grid.Row>
			{/* {userAuth.isSubsriptionActive ? (
				<Grid.Row id="rootGridSecondaryHeaderRow" style={unsetTopBottomPadding}>
					<Grid.Column id="rootGridSecondaryHeaderCol">
						<HeaderSecondary />
					</Grid.Column>
				</Grid.Row>
			) : null} */}

			{/* Height of the viewport minus the header and secondary header height */}
			<Grid.Row
				id="rootGridContentRow"
				style={{
					...unsetTopBottomPadding,
					flexGrow: "1",
					height: 0,
					border: "none"
				}}
			>
				{userAuth.isSubsriptionActive ? (
					<Grid.Column id="rootGridLeftNavCol" style={{ paddingLeft: "unset", paddingRight: "unset", width: 72 }}>
						<LeftNav
							navItems={
								useSettingsNav ? rootModules.filter((rm) => rm.settings) : rootModules.filter((rm) => !rm.settings)
							}
							activeItem={activeModule}
						/>
					</Grid.Column>
				) : null}
				{userAuth.isSubsriptionActive ? (
					<Grid.Column
						id="rootGridLeftNavSecondaryCol"
						style={{ paddingLeft: "unset", paddingRight: "unset", width: 59 }}
					>
						<Transition.Group animation="slide right" duration="100">
							{submodules.length > 0 && (
								<Grid.Column id="leftNavSecondaryTransitionContainer">
									<LeftNavSecondary navItems={submodules} activeItem={activeSubmodule} />
								</Grid.Column>
							)}
						</Transition.Group>
					</Grid.Column>
				) : null}
				<Grid.Column
					id="rootGridContentColumn"
					style={{
						paddingLeft: "unset",
						paddingRight: "unset",
						flexGrow: 1,
						height: "100%",
						overflow: "auto"
					}}
				>
					<Segment id="rootSegment" basic style={{ padding: "unset" }}>
						{children}
					</Segment>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

const renderSubscriptionExpired = (children) => {
	return (
		<Grid id="rootGrid" stretched centered>
			<Grid.Row id="rootGridHeaderRow" style={unsetTopBottomPadding}>
				<Grid.Column id="rootGridHeaderCol">
					<AP_Header />
				</Grid.Column>
			</Grid.Row>

			{/* Height of the viewport minus the header and secondary header height */}
			<Grid.Row id="rootGridContentRow" style={{ ...unsetTopBottomPadding, height: "calc(100vh - 78px)" }}>
				<Grid.Column
					id="rootGridContentColumn"
					style={{ paddingLeft: "unset", paddingRight: "unset", width: "calc(100% - 110px)" }}
				>
					<Segment basic>{children}</Segment>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};
Layout.propTypes = {
	children: PropTypes.element.isRequired
};

export default Layout;
