import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as groundDataActions from "../../../../redux/actions/groundDataActions";
import NewGroundData from "../../../Lumber/GroundData/NewGroundData";
const Assessments = () => {
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const history = useHistory();
	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));

	//-- Data Sources
	const [groundDataQuestions, setGroundDataQuestions] = useState(null);
	const [growthStages, setGrowthStages] = useState([]);
	const [persons, setPersons] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userAuth.currentClientId && !groundDataQuestions) {
			getGroundDataInfo();
		}
	}, [userAuth.currentClientId]);

	async function getGroundDataInfo() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(
			groundDataActions.getGroundDataInfo("", "", userAuth.currentClientId, accessToken, reduxProtocolData.cropId)
		)
			.then((res) => {
				setGroundDataQuestions(res.data.groundDataQuestions);
				setGrowthStages(res.data.growthStages);
				setPersons(res.data.persons);
				setLoading(false);
			})
			.catch((err) => {
				// toast.error("Error trial ground data info. Please try again.");
				console.log(err);
				setLoading(false);
			});
	}

	function onSave(continueProcess) {
		if (!continueProcess && !moduleNavigation.protocolId) {
			history.push(moduleNavigation.createProtocolLink(true, null, "assessments"));
		}

		if (continueProcess) {
			history.push(moduleNavigation.createProtocolLink(true, null, "associate-trials"));
		}
	}

	function onBack() {
		history.push(moduleNavigation.createProtocolLink(true, null, "treatments"));
	}

	return loading ? (
		<Loader active />
	) : (
		<>
			<NewGroundData
				groundDataQuestions={groundDataQuestions}
				growthStages={growthStages}
				persons={persons}
				isForProtocol={true}
				onSave={onSave}
				onBack={onBack}
			/>
		</>
	);
};

export default Assessments;
