import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";
import { Handle, SliderRail, Tick, Track } from "./sliderComponents";

import { format } from "date-fns";
import { Icon } from "semantic-ui-react";

const TimelineSlider = ({ dates = [], updateSelected = () => {}, domain }) => {
	const minuteInMs = 1000 * 60; //-- 1000 ms in a second * 60 seconds in a minute

	const [workingDates, setWorkingDates] = useState([]);

	const [isValid, setIsValid] = useState(false);
	const [earliestDate, setEarliestDate] = useState(0);
	const [latestDate, setLatestDate] = useState(0);

	const [selectedId, setSelectedId] = useState(0);
	const [selectedRealDate, setSelectedRealDate] = useState(0);
	const [selectedValue, setSelectedValue] = useState(0);
	const [selectedPercent, setSelectedPercent] = useState(0);

	// EXAMPLE NODE BEING PASSED IN VIA THE DATES ARRAY
	// const timelineNode = {
	// 	id: Guid,
	// 	date: moment(date).local(),
	// 	color: "#4183c4",
	// 	clickable: true,
	// 	firstActive: moduleNavigation.flightId/trialId === id ? true : false,
	// 	tooltip: (
	// 		<>
	// 			{`Flight Date & Time`} <br /> {moment(date).format("ddd --- MMMM DD, YYYY --- hh:mm a")}
	// 		</>
	// 	)
	// };

	useEffect(() => {
		if (!dates || dates.length === 0 || domain === null) {
			setIsValid(false);
			console.warn("Timeline Slider was supplied with a null object. Timeline Slider will be hidden.");
			return;
		}

		//-- react-compound-slider will throw an error if the min and max values are the same
		if (dates.length === 1 && (typeof dates[0]?.date !== "object" || dates[0]?.date.date())) {
			dates[0].date = new Date(dates[0].date).setHours(0, 0, 0, 0);
			dates[0].timelineDate = new Date(dates[0].date).setHours(0, 0, 0, 0);

			const min = domain[0];
			const max = domain[1] - min;
			const percent = ((dates[0].timelineDate - min) / max) * 100;

			setSelectedId(dates[0].id);
			setSelectedRealDate(format(new Date(dates[0].date), "MMM dd, yyyy"));
			setSelectedValue(dates[0].timelineDate);
			updateSelected(dates[0]);
			setEarliestDate(dates[0].timelineDate);
			setLatestDate(dates[0].timelineDate);
			setSelectedPercent(percent);
			setWorkingDates(dates);
			setIsValid(true);
		} else if (dates.length > 0) {
			//-- Sort by date (earliest to latest)
			dates = dates.sort((a, b) => {
				return a.date - b.date;
			});

			try {
				let lastId = "";

				dates.forEach((d) => {
					// check for missing data
					if ((!d.id && d.id !== "0") || !d.date || !d.color) {
						throw `Missing data: ${JSON.stringify(d)}`;
					}
					// convert dates to timelineDates
					d.timelineDate = +d.date.startOf("day");
				});

				dates.forEach((d) => {
					// check for duplicate Ids
					if (d.id === lastId) {
						throw `Duplicate Id found: ${d.id}`;
					}

					// shift duplicate dates within the day so there are no overlaps
					// this has issues when the duplicate date is the last point
					dates
						.filter((dupe) => dupe.id !== d.id && dupe.timelineDate === d.timelineDate)
						.forEach((dupe, idx, arr) => {
							if (dupe) dupe.timelineDate = +dupe.date.add(((idx + 1) / arr.length) * 23, "hours");
						});

					lastId = d.id;
				});

				let firstActiveDate = dates.find(({ firstActive }) => firstActive);
				if (!firstActiveDate) {
					firstActiveDate = dates[dates.length - 1];
				}

				const min = domain[0];
				const max = domain[1] - min;

				const percent = ((firstActiveDate.timelineDate - min) / max) * 100;

				setSelectedId(firstActiveDate.id);
				setSelectedRealDate(format(new Date(firstActiveDate.date), "MMM dd, yyyy"));
				setSelectedValue(firstActiveDate.timelineDate);
				updateSelected(firstActiveDate);
				setEarliestDate(dates[0].timelineDate);
				setLatestDate(dates[dates.length - 1].timelineDate);
				setSelectedPercent(percent);

				setWorkingDates(dates);
				setIsValid(true);
			} catch (ex) {
				console.warn(`TimelineSlider encoutered an error: ${ex}. TimelineSlider will be hidden.`);
				console.warn(dates);
				setIsValid(false);
			}
		}
	}, [dates, domain?.[0], domain?.[1]]);

	function sliding(ms) {
		if (ms && !_.isEqual(selectedValue, ms[0]) && ms[0] > 0 && domain) {
			const min = domain[0];
			const max = domain[1] - min;

			const closest = workingDates
				.filter(({ clickable }) => clickable)
				.reduce((prev, curr) => (Math.abs(curr.timelineDate - ms) < Math.abs(prev.timelineDate - ms) ? curr : prev));
			const percent = ((ms - min) / max) * 100;

			setSelectedId(closest.id);
			setSelectedRealDate(format(new Date(closest.date), "MMM dd, yyyy"));
			setSelectedValue(closest.timelineDate);
			setSelectedPercent(percent);
			updateSelected(closest);
		}
	}

	function slide(ms) {
		//-- TODO: eliminate this workaround https://github.com/facebook/react/issues/18147
		if (ms && !_.isEqual(selectedValue, ms[0]) && ms[0] > 0 && domain) {
			const min = domain[0];
			const max = domain[1] - min;

			//-- Find the value in the array that's closest to the slider value the user chose
			const closest = workingDates
				.filter(({ clickable }) => clickable)
				.reduce((prev, curr) => (Math.abs(curr.timelineDate - ms) < Math.abs(prev.timelineDate - ms) ? curr : prev));
			const percent = ((closest.timelineDate - min) / max) * 100;

			setSelectedId(closest.id);
			setSelectedRealDate(format(new Date(closest.date), "MMM dd, yyyy"));
			setSelectedValue(closest.timelineDate);
			setSelectedPercent(percent);
			updateSelected(closest);
		}
	}

	function slideFirst() {
		if (dates.length > 0) {
			const first = workingDates[0];
			slide([first.timelineDate]);
		}
	}

	function slidePrevious() {
		if (dates.length > 0) {
			let currentIndex = -1;

			workingDates.find(({ id }, index) => {
				if (id === selectedId) {
					currentIndex = index;
					return true;
				} else {
					return false;
				}
			});

			if (currentIndex === 0) {
				return;
			}

			if (currentIndex > -1 && currentIndex <= workingDates.length - 1) {
				const next = workingDates[currentIndex - 1];
				slide([next.timelineDate]);
			}
		}
	}

	function slideNext() {
		if (dates.length > 0) {
			let currentIndex = -1;

			workingDates.find(({ id }, index) => {
				if (id === selectedId) {
					currentIndex = index;
					return true;
				} else {
					return false;
				}
			});

			if (currentIndex !== -1 && currentIndex < workingDates.length - 1) {
				const next = workingDates[currentIndex + 1];
				slide([next.timelineDate]);
			}
		}
	}

	function slideLast() {
		if (dates.length > 0) {
			const last = workingDates[workingDates.length - 1];
			slide([last.timelineDate]);
		}
	}

	//-- AG: Not sure why I have to do values={[selectedValue + 1]}, but it works, so I'm going with it
	return (
		isValid && (
			<div style={{ display: "flex", alignItems: "center" }}>
				<Icon name="previous" onClick={() => slideFirst()} style={{ cursor: "pointer", lineHeight: "16px" }} />
				<Icon name="arrow left" onClick={() => slidePrevious()} style={{ cursor: "pointer", lineHeight: "16px" }} />
				<Slider
					mode={1}
					step={minuteInMs}
					domain={domain ?? [+earliestDate, +latestDate]}
					rootStyle={{ position: "relative", width: "100%", height: 1, margin: "0 10px" }}
					onUpdate={sliding}
					onChange={slide}
					values={[selectedValue + 1]}
				>
					<Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
					<Handles>
						{({ handles, getHandleProps }) => (
							<div>
								{handles.map((handle) => {
									handle.percent = selectedPercent;
									handle.id = selectedId;
									handle.date = selectedRealDate;
									handle.color = workingDates.find((d) => d.id === selectedId).color;
									return (
										<Handle
											key={handle.id}
											handle={handle}
											domain={[+earliestDate, +latestDate]}
											getHandleProps={getHandleProps}
										/>
									);
								})}
							</div>
						)}
					</Handles>
					<Tracks left={true} right={false}>
						{({ tracks, getTrackProps }) => {
							return (
								<div>
									{tracks.map(({ id, source, target }) => {
										target.value = selectedValue;
										target.percent = selectedPercent;
										return <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />;
									})}
								</div>
							);
						}}
					</Tracks>
					<Ticks values={workingDates.map(({ timelineDate }) => timelineDate)}>
						{({ ticks }) => {
							ticks.forEach((t, i) => {
								t.id = workingDates[i].id;
								t.value = workingDates[i].timelineDate;
								t.color = workingDates[i].color;
								t.tooltip = workingDates[i].tooltip;
								t.marginTopMultiplier = 1;
							});
							return (
								<div>
									{ticks.map((tick, idx) => {
										return (
											<Tick
												key={tick.id}
												showLabel={idx === 0 || idx === ticks.length - 1 || tick.id === selectedId}
												tick={tick}
												count={ticks.length}
												format={(ms) => format(new Date(ms), "MMM dd, yy")}
												color={tick.color}
												onClick={() => {
													slide([tick.value]);
												}}
											/>
										);
									})}
								</div>
							);
						}}
					</Ticks>
				</Slider>
				<Icon name="arrow right" onClick={() => slideNext()} style={{ cursor: "pointer", lineHeight: "16px" }} />
				<Icon name="next" onClick={() => slideLast()} style={{ cursor: "pointer", lineHeight: "16px" }} />
			</div>
		)
	);
};

TimelineSlider.propTypes = {
	dates: PropTypes.array,
	updateSelected: PropTypes.func,
	domain: PropTypes.array
};

export default TimelineSlider;
