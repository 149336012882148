import PropTypes from "prop-types";
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { toast } from "react-toastify";
import { Checkbox, Dropdown, Segment } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";

import * as analysisActions from "../../../redux/actions/analysisActions";
import * as ControlFunctions from "./orthoControlFunctions.js";

import Timelineslider from "../../Lumber/TimelineSlider";
import { ApplicationArea } from "../ApplicationAreas";

const HeatmapTimeline = React.forwardRef(
	(
		{
			level,
			heatmapOverlayShading,
			heatmapOverlayLabels,
			setPlotAnalysisResults,
			heatmapOverlayLabelButton,
			heatmapOptionsButton,
			plotLabels,
			plotLabelButton,
			updateTimelineDomain
		},
		parentRef
	) => {
		HeatmapTimeline.displayName = "HeatmapTimeline";
		const dispatch = useDispatch();
		const { getTokenSilently } = useAuth0();
		const userAuth = useUserAuth();
		const moduleNavigation = useModuleNavigation();

		useImperativeHandle(parentRef, () => {
			return {
				setRawAnalysisOptions: (opts) => {
					setRawAnalysisOptions(opts);
				},
				getAnalysisOptions: () => {
					return analysisOptions;
				},
				setSelectedAnalysisId: (id) => {
					let matchingAnalysisOption = _.find(analysisOptions, { key: id })?.key;

					if (!matchingAnalysisOption) {
						matchingAnalysisOption = _.find(analysisOptions, (ao) => ao.key.includes(id))?.key;
					}

					setSelectedAnalysisId(matchingAnalysisOption);
				},
				getSelectedAnalysisId: () => {
					return selectedAnalysisId;
				},
				getHeatmapEnabled: () => {
					return heatmapEnabled;
				},
				setHeatmapEnabled: (status) => {
					setHeatmapEnabled(status);

					if (status !== heatmapEnabled && analysisData?.length > 0) {
						heatmapOverlayShading();

						if (heatmapOverlayLabelButton?.current) {
							heatmapOverlayLabelButton.current.setDisabled(!heatmapOverlayLabelButton.current.checkDisabled);
						}

						if (heatmapOverlayLabelButton?.current?.checkToggleState) {
							heatmapOverlayLabels();
						}
					}
				},
				setHeatmapDisabled: (status) => setHeatmapDisabled(status),
				getSelectedFlightId: () => {
					return selectedFlightId;
				},
				setSelectedFlightId: (id) => {
					//-- Setting up this useState so we can assign selected timeline node after new timeline nodes are built
					//-- instead of before, in case the node doesn't exist
					setTimelineRefreshNodeId(id);

					setSelectedFlightId(id);

					//-- Call this in case the timeline nodes don't need to be rebuilt
					updateTimelineSelected(id);
				},
				setSelectedFlightDate: (date) => {
					setTimelineRefreshNodeId(date);
					setSelectedFlightDate(date);
					updateTimelineSelected(date);
				},
				getSelectedAnalysisType: () => {
					return selectedAnalysisType;
				},
				getPlotAnalysisResults: () => {
					return getPlotAnalysisResults();
				},
				setDomain: (domain) => {
					return setDomain(domain);
				}
			};
		});

		//-- Data source
		const [rawAnalysisOptions, setRawAnalysisOptions] = useState(null);
		const [analysisOptions, setAnalysisOptions] = useState(null);
		const [analysisData, setAnalysisData] = useState(null);
		const [fieldId, setFieldId] = useState(null);
		const [trialId, setTrialId] = useState(null);
		const [farmId, setFarmId] = useState(null);

		const reduxFlightId = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightId : null));
		const reduxFlightDate = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightDate : null));
		const reduxFlightYear = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightYear : null));
		const reduxFilteredFlightDates = useSelector((state) => state.orthoViewer?.filters.flightDates ?? null);

		//-- Lists of timeline nodes
		const [analysisNodes, setAnalysisNodes] = useState([]);
		const [filteredNodes, setFilteredNodes] = useState([]);

		//-- Selected values
		const [selectedFlightId, setSelectedFlightId] = useState(null);
		const [selectedFlightDate, setSelectedFlightDate] = useState(null);
		const [selectedAnalysisId, setSelectedAnalysisId] = useState(null);
		const [selectedAnalysisType, setSelectedAnalysisType] = useState(null);

		//-- Control
		const [loading, setLoading] = useState(true);
		const [heatmapDisabled, setHeatmapDisabled] = useState(false);
		const [heatmapEnabled, setHeatmapEnabled] = useState(false);
		const [timelineRefreshNodeId, setTimelineRefreshNodeId] = useState(null);
		const [initial, setInitial] = useState(true);

		//-- Domain to be passed to timeline slider
		const [domain, setDomain] = useState(null);

		const AnalysisType = {
			Analysis: "Analysis",
			Assessment: "Assessment",
			CurveModel: "CurveModel"
		};

		useEffect(() => {
			if (rawAnalysisOptions) {
				if (level === "farm" && reduxFlightYear) {
					setAnalysisOptions(
						rawAnalysisOptions.filter(
							(rao) => rao.years === undefined || rao.years.some((yr) => reduxFlightYear.includes(yr))
						)
					);
				} else {
					setAnalysisOptions(rawAnalysisOptions);
				}
			}
		}, [level, rawAnalysisOptions, reduxFlightYear]);

		useEffect(() => {
			if (analysisOptions && analysisOptions.length > 1) {
				setSelectedAnalysisId(_.find(analysisOptions, { text: "Vegetation (NDVI)" })?.key ?? analysisOptions[1]?.key);
			}
		}, [analysisOptions]);

		useEffect(() => {
			if (level === "field") {
				setFieldId(moduleNavigation.fieldId);
			} else if (level === "trial") {
				setTrialId(moduleNavigation.trialId);
			} else if (level === "farm") {
				setFarmId(moduleNavigation.farmId);
			}
		}, [level]);

		useEffect(() => {
			if (selectedAnalysisId) {
				getPlotAnalysisResults();
			}
		}, [selectedAnalysisId]);

		useEffect(() => {
			if (filteredNodes?.length > 0) {
				let sortedNodes = _.sortBy(filteredNodes, (n) => n.date);
				updateTimelineDomain(
					sortedNodes.length > 1
						? [
							+new Date(sortedNodes[0].date).setHours(0, 0, 0, 0),
							+new Date(sortedNodes[filteredNodes.length - 1].date).setHours(0, 0, 0, 0)
						]
						: [
							+new Date(sortedNodes[0].date).setHours(0, 0, 0, 0) - 1,
							+new Date(sortedNodes[0].date).setHours(0, 0, 0, 0)
						]
				);
			} else {
				updateTimelineDomain([null, null]);
			}
		}, [filteredNodes]);

		useEffect(() => {
			if (analysisOptions) {
				heatmapOverlayShading();
				if (heatmapEnabled) {
					if (heatmapOverlayLabelButton?.current && plotLabelButton?.current) {
						heatmapOverlayLabels();
						heatmapOverlayLabelButton.current.setDisabled(false);
						plotLabelButton.current.setToggleState(false);
						heatmapOverlayLabelButton.current.setToggleState(true);
					}

					heatmapOptionsButton.current.setDisabled(false);

				} else {
					if (heatmapOverlayLabelButton?.current && plotLabelButton?.current) {
						if (!plotLabelButton.current.checkToggleState) {
							plotLabels();
							plotLabelButton.current.setToggleState(true);
						}
						heatmapOverlayLabelButton.current.setDisabled(true);
						heatmapOverlayLabelButton.current.setToggleState(false);
					}

					heatmapOptionsButton.current.setDisabled(true);

				}
			}
		}, [heatmapEnabled]);

		useEffect(() => {
			if (reduxFlightYear?.length > 0 && level === "farm" && !initial) {
				getPlotAnalysisResults();
			}
		}, [reduxFlightYear]);

		useEffect(() => {
			setFilteredNodes(
				analysisNodes.filter(
					(node) => !reduxFilteredFlightDates?.length || reduxFilteredFlightDates.includes(+new Date(node.id))
				)
			);
		}, [reduxFilteredFlightDates, analysisNodes]);

		const updateSelected = useCallback(
			_.debounce((selected) => {
				if (level !== "farm") {
					setSelectedFlightId(selected.id);
				} else {
					setSelectedFlightDate(selected.id);
				}
			}, 200),
			[]
		);

		useEffect(() => {
			if (analysisData && selectedAnalysisType) {
				if (level !== "farm") {
					setupPlotValues(null, null, selectedFlightId);
				} else {
					setupPlotValues(null, null, null, selectedFlightDate);
				}
			}
		}, [analysisData, selectedAnalysisType, selectedFlightId, selectedFlightDate]);

		async function getPlotAnalysisResults() {
			const accessToken = await getTokenSilently();
			setLoading(true);

			//-- Remove previous heatmap data from viewer
			let localHeatmapLabelState = false;
			if (heatmapEnabled && analysisData?.length > 0) {
				heatmapOverlayShading();

				if (heatmapOverlayLabelButton?.current?.checkToggleState) {
					localHeatmapLabelState = true;
					heatmapOverlayLabels();
				}
			}

			//-- Find which analysis data to retrieve
			let analysisOptionsData = ControlFunctions.getAnalysisOptions();
			let analysisId,
				analysisTypeId,
				analysisTypeName,
				assessmentId,
				quantifiedRegionTypeId,
				curveModelAnalysisId = null;

			let splitSelectedAnalysisIds = selectedAnalysisId.split("/");

			analysisId = _.find(analysisOptionsData.analysisOptions, {
				analysisId: splitSelectedAnalysisIds[0]
			})?.analysisId;
			assessmentId = _.find(analysisOptionsData.groundDataOptions, { assessmentId: selectedAnalysisId })?.assessmentId;

			analysisTypeId = splitSelectedAnalysisIds[1];
			quantifiedRegionTypeId = splitSelectedAnalysisIds[2];
			analysisTypeName = analysisTypeId
				? _.find([...analysisOptionsData.analysisOptions, ...analysisOptionsData.curveModelAnalysisOptions], {
					analysisTypeId: analysisTypeId,
					quantifiedRegionTypeId: quantifiedRegionTypeId
				})?.analysisTypeName
				: null;
			let curveModelData = _.find(
				analysisOptionsData.curveModelAnalysisOptions,
				(cmao) =>
					cmao.analysisId === selectedAnalysisId.split("/")[0] &&
					cmao.curveModelAnalysisId === selectedAnalysisId.split("/")[2]
			);

			if (curveModelData) {
				analysisId = curveModelData.analysisId;
				curveModelAnalysisId = curveModelData.curveModelAnalysisId;
			}

			dispatch(
				level === "field"
					? analysisActions.getHeatmapAnalysisResultsForField(
						userAuth.currentClientId,
						fieldId,
						analysisId,
						analysisTypeName,
						assessmentId,
						accessToken
					)
					: level === "trial"
						? analysisActions.getHeatmapAnalysisResultsForTrial(
							userAuth.currentClientId,
							trialId,
							analysisId,
							analysisTypeId,
							analysisTypeName,
							assessmentId,
							curveModelAnalysisId,
							accessToken
						)
						: analysisActions.getHeatmapAnalysisResultsForFarm(
							userAuth.currentClientId,
							farmId,
							analysisId,
							analysisTypeId,
							analysisTypeName,
							assessmentId,
							curveModelAnalysisId,
							reduxFlightYear,
							accessToken
						)
			)
				.then((res) => {
					//-- Need to map datasetId to flightId for ground data assessments
					if (assessmentId && res.data?.length > 0) {
						_.map(res.data, (d) => (d.flightId = d.datasetId));
					}

					//-- Skip data filtering for curve models
					if (curveModelAnalysisId) {
						setPlotAnalysisResults(res.data);
					}

					//-- Turns heatmap shading back on
					if (heatmapEnabled && analysisData?.length > 0) {
						heatmapOverlayShading();

						if (localHeatmapLabelState) {
							heatmapOverlayLabels();
						}
					}

					let analysisType = curveModelAnalysisId
						? AnalysisType.CurveModel
						: assessmentId
							? AnalysisType.Assessment
							: AnalysisType.Analysis;

					setAnalysisData(res.data);

					if (level !== "farm") {
						setupTimelineNodes(res.data, analysisType);
					} else {
						setupTimelineNodesWithDates(res.data, analysisType);
					}

					setSelectedAnalysisType(analysisType);

					setLoading(false);
					setInitial(false);
				})
				.catch((err) => {
					console.error(err);
					toast.error(`Failed to get analysis results for this ${level}`, { toastId: "analysisPull" });

					setLoading(false);
					setHeatmapEnabled(false);
					if (heatmapOverlayLabelButton?.current) {
						heatmapOverlayLabelButton.current.setDisabled(!heatmapOverlayLabelButton.current.checkDisabled);
					}
				});
		}

		function setupTimelineNodes(data = null, analysisType = null) {
			let nodes = [];
			if (
				(analysisType ?? selectedAnalysisType) === AnalysisType.Analysis ||
				(analysisType ?? selectedAnalysisType) === AnalysisType.Assessment
			) {
				nodes = _.map(_.uniqBy(data ?? analysisData, "flightId"), (ad) => {
					return {
						id: ad.flightId,
						date: moment(ad.dateCollected).local(),
						color: "var(--accent-alternate-UI-500)",
						clickable: true,
						firstActive: false,
						tooltip: (
							<>
								{`${analysisType ?? selectedAnalysisType} Date`} <br />{" "}
								{moment(ad.dateCollected).format("MMMM DD, YYYY")}
							</>
						)
					};
				});
			}
			//-- Default Selection logic
			if ((timelineRefreshNodeId || reduxFlightId) && nodes.length > 0) {
				var targetIndex = _.findIndex(nodes, { id: timelineRefreshNodeId ?? reduxFlightId });
				if (targetIndex != -1) {
					nodes[targetIndex].firstActive = true;
					_.map(
						_.filter(nodes, (n) => n.id !== (timelineRefreshNodeId ?? reduxFlightId)),
						(n) => (n.firstActive = false)
					);
				}
				setTimelineRefreshNodeId(null);
			}

			//-- Catches if no nodes are set to active
			if (!_.some(nodes, (n) => n.firstActive) && nodes.length > 0) {
				let sortedNodes = _.sortBy(nodes, (n) => n.date);
				_.find(nodes, { id: sortedNodes[0].id }).firstActive = true;
			}

			let activeId = _.find(nodes, "firstActive")?.id ?? null;
			setSelectedFlightId(activeId);
			//-- manually overriding analysis type for workaround with weird curve model interactions
			setupPlotValues(data, activeId === null ? AnalysisType.CurveModel : analysisType, activeId);
			setAnalysisNodes(nodes);
		}

		function setupTimelineNodesWithDates(data, analysisType) {
			let nodes = [];
			if (analysisType === AnalysisType.Analysis || analysisType === AnalysisType.Assessment) {
				nodes = _.map(
					_.uniqBy(data, (d) => moment(d.dateCollected).format("MM/DD/YYYY")),
					(ad) => {
						return {
							id: moment(ad.dateCollected).format("MM/DD/YYYY"),
							date: moment(ad.dateCollected).local(),
							color: "var(--accent-alternate-UI-500)",
							clickable: true,
							firstActive: false,
							tooltip: (
								<>
									{`${analysisType ?? selectedAnalysisType} Date`} <br />{" "}
									{moment(ad.dateCollected).format("MMMM DD, YYYY")}
								</>
							)
						};
					}
				);
			}

			//-- Default Selection logic
			if ((timelineRefreshNodeId || reduxFlightDate) && nodes.length > 0) {
				var targetIndex = _.findIndex(nodes, { id: timelineRefreshNodeId ?? reduxFlightDate });
				if (targetIndex != -1) {
					nodes[targetIndex].firstActive = true;
					_.map(
						_.filter(nodes, (n) => n.id !== (timelineRefreshNodeId ?? reduxFlightDate)),
						(n) => (n.firstActive = false)
					);
				}
				setTimelineRefreshNodeId(null);
			}

			//-- Catches if no nodes are set to active
			if (!_.some(nodes, (n) => n.firstActive) && nodes.length > 0) {
				let sortedNodes = _.sortBy(nodes, (n) => n.date);
				_.find(nodes, { id: sortedNodes[0].id }).firstActive = true;
			}

			let activeId = _.find(nodes, "firstActive")?.id ?? null;
			setSelectedFlightDate(activeId);
			//-- manually overriding analysis type for workaround with weird curve model interactions
			setupPlotValues(data, activeId === null ? AnalysisType.CurveModel : analysisType, null, activeId);
			setAnalysisNodes(nodes);
		}

		//-- Sets plot analysis values for non-curve models
		function setupPlotValues(data = null, analysisType = null, flightId = null, flightDate = null) {
			if ((data || analysisData) && (analysisType || selectedAnalysisType)) {
				if ((analysisType ?? selectedAnalysisType) !== AnalysisType.CurveModel) {
					let filteredAnalysisData = _.filter(data ?? analysisData, (ad) =>
						level !== "farm"
							? ad.flightId === (flightId ?? selectedFlightId)
							: moment(ad.dateCollected).format("MM/DD/YYYY") === (flightDate ?? selectedFlightDate)
					);
					if (filteredAnalysisData?.length > 0) {
						setPlotAnalysisResults(filteredAnalysisData);
					}
				}

				if (heatmapEnabled) {
					if (heatmapOverlayLabelButton?.current?.checkToggleState) {
						heatmapOverlayLabels();
						heatmapOverlayLabels();
					}
				}
			}
		}

		//-- Attempts to refresh the timeline nodes if needed and selects the node id
		function updateTimelineSelected(id) {
			if (filteredNodes?.length > 0) {
				let localFilteredNodes = _.cloneDeep(filteredNodes);

				_.map(localFilteredNodes, (n) => (n.firstActive = n.id === id));

				setFilteredNodes(localFilteredNodes);
			}
		}

		return (
			<>
				<div style={{ width: 232 }}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 10,
							justifyContent: "flex-end"
						}}
					>
						<div style={{ width: 64 }}>
							<label style={{ fontSize: "16px" }} htmlFor="select-analysis">
								Heatmap
							</label>
						</div>
						<div style={{ flexGrow: "1", width: "0" }}>
							<Dropdown
								id="select-analysis"
								selection
								fluid
								search
								options={analysisOptions}
								value={selectedAnalysisId}
								trigger={
									<div className="text" title={_.find(analysisOptions, { key: selectedAnalysisId })?.text}>
										{_.find(analysisOptions, { key: selectedAnalysisId })?.text}
									</div>
								}
								onChange={(event, { value }) => {
									setSelectedAnalysisId(value);
								}}
								loading={!analysisOptions}
							/>
						</div>
					</div>
				</div>
				<div style={{ flexGrow: 1 }}>
					{loading ? (
						<></>
					) : (
						<Segment basic style={{ paddingTop: 0, paddingLeft: "unset", paddingRight: "unset", paddingBottom: 0 }}>
							<Timelineslider dates={filteredNodes} updateSelected={updateSelected} domain={domain} />
						</Segment>
					)}
				</div>
				<div style={{ height: 21 }}>
					<Checkbox
						toggle
						disabled={
							loading ||
							heatmapDisabled ||
							(level === "trial" &&
								!userAuth.hasApplicationArea(ApplicationArea.TrialVisualHeatmapShading, userAuth.currentClientId)) ||
							(level === "field" &&
								!userAuth.hasApplicationArea(ApplicationArea.FieldVisualHeatmapShading, userAuth.currentClientId)) ||
							(level === "farm" &&
								!userAuth.hasApplicationArea(ApplicationArea.FarmVisualHeatmapShading, userAuth.currentClientId))
						}
						checked={heatmapEnabled}
						onClick={() => {
							if (!heatmapDisabled) {
								setHeatmapEnabled(!heatmapEnabled);
							}
						}}
					//onChange={() => {
					// heatmapOverlayShading();
					// heatmapOverlayLabels();
					// plotLabels();
					// if (heatmapOverlayLabelButton?.current?.checkToggleState) {
					// 	heatmapOverlayLabels();
					// }
					//}}
					/>
				</div>
			</>
		);
	}
);

HeatmapTimeline.propTypes = {
	level: PropTypes.string.isRequired,
	heatmapOverlayShading: PropTypes.func.isRequired,
	heatmapOverlayLabels: PropTypes.func.isRequired,
	setPlotAnalysisResults: PropTypes.func.isRequired,
	heatmapOverlayLabelButton: PropTypes.object,
	heatmapOptionsButton: PropTypes.object,
	plotLabels: PropTypes.func.isRequired,
	plotLabelButton: PropTypes.object,
	updateTimelineDomain: PropTypes.func
};

export default HeatmapTimeline;
