// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button,\ninput[type=\"number\"] {\n\t-webkit-appearance: none;\n\tmargin: 0;\n\t-moz-appearance: textfield;\n}\n\n.unitConverterPopup.ui.popup.basic {\n\tborder-radius: 8px;\n}\n\n.unitConverterPopup .disabled,\n.unitConverterPopup .disabled * {\n\topacity: 1 !important;\n\tcolor: #000000 !important;\n}\n\n.unitConverterPopup .ui.form .field:has(.button) {\n\tpadding: 0 0.5em;\n}\n\n#unitOneValueInput,\n#unitTwoValueInput {\n\twidth: 50%;\n\ttext-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/UnitConverter/styles.css"],"names":[],"mappings":"AAAA;;;CAGC,wBAAwB;CACxB,SAAS;CACT,0BAA0B;AAC3B;;AAEA;CACC,kBAAkB;AACnB;;AAEA;;CAEC,qBAAqB;CACrB,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;AACjB;;AAEA;;CAEC,UAAU;CACV,kBAAkB;AACnB","sourcesContent":["input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button,\ninput[type=\"number\"] {\n\t-webkit-appearance: none;\n\tmargin: 0;\n\t-moz-appearance: textfield;\n}\n\n.unitConverterPopup.ui.popup.basic {\n\tborder-radius: 8px;\n}\n\n.unitConverterPopup .disabled,\n.unitConverterPopup .disabled * {\n\topacity: 1 !important;\n\tcolor: #000000 !important;\n}\n\n.unitConverterPopup .ui.form .field:has(.button) {\n\tpadding: 0 0.5em;\n}\n\n#unitOneValueInput,\n#unitTwoValueInput {\n\twidth: 50%;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unitConverterPopup": "unitConverterPopup",
	"ui": "ui",
	"popup": "popup",
	"basic": "basic",
	"disabled": "disabled",
	"form": "form",
	"field": "field",
	"button": "button",
	"unitOneValueInput": "unitOneValueInput",
	"unitTwoValueInput": "unitTwoValueInput"
};
export default ___CSS_LOADER_EXPORT___;
