import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Button, Form, Grid, Icon, Loader, Segment } from "semantic-ui-react";

import { CSVLink } from "react-csv";

import { getDistinctObjects } from "../../../../utilities/jsUtils";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as analysisActions from "../../../../redux/actions/analysisActions";

import Heatmap from "../../../Lumber/Heatmap";
import Timelineslider from "../../../Lumber/TimelineSlider";

import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";

import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import "./styles.css";

const TrialHeatmapLegacy = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const ANALYSIS_DATE_FORMAT = "MM/DD/YYYY h:mma";

	//-- Data Sources
	const [trialHeatmapData, setTrialHeatmapData] = useState(null);
	const [flights, setFlights] = useState(null);
	const [analyses, setAnalyses] = useState(null);
	const [rawPresetOptions, setRawPresetOptions] = useState(null);
	const [plotSubsampleStatuses, setPlotSubsampleStatuses] = useState(null);
	const [selectedFlights, setSelectedFlights] = useState([]);

	//-- Timeline nodes
	const [flightNodes, setFlightNodes] = useState(null);

	//-- Dropdown options
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [allAssessments, setAllAssessments] = useState([]);

	//-- Selected option
	const [flightId, setFlightId] = useState(null);
	const [analysisId, setAnalysisId] = useState(null);
	const [quantifiedRegionTypeId, setQuantifiedRegionTypeId] = useState(null);
	const [analysisType, setAnalysisType] = useState(null);

	const [analysisResults, setAnalysisResults] = useState(null);

	//-- CSV
	const [fileName, setFileName] = useState("TrialHeatmap.csv");
	const [csvData, setCsvData] = useState(null);
	const [adminCsvData, setAdminCsvData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [growthCurveSelected, setGrowthCurveSelected] = useState(false);

	//-- Get/Set - Trial heatmap data
	useEffect(() => {
		if (userAuth.currentClientId && !trialHeatmapData) {
			getTrialHeatmapData(moduleNavigation.trialId);
		}
	}, [userAuth.currentClientId]);

	async function getTrialHeatmapData(trialId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(analysisActions.getAnalysisResultsForTrial(userAuth.currentClientId, trialId, accessToken))
			.then((res) => {
				const subsampleOrdering = [
					{ type: "Plot-Centered with Subsamples", order: 1 },
					{ type: "Plot-Centered", order: 2 },
					{ type: "Row-Centered", order: 3 },
					{ type: "Full Row", order: 4 },
					{ type: "Between-Row", order: 5 }
				];

				setSelectedFlights(res.selectedFlights);

				_.map(res.plotAnalysisResults, (par) => {
					par.subsampleOrder = _.find(subsampleOrdering, { type: par.quantifiedRegionTypeName })?.order;
					par.analysisOrder =
						_.find(
							res.presetOptions.timeSeriesPresets,
							(po) => po.analysisId === par.analysisId && po.analysisTypeId === par.analysisTypeId
						)?.order || Infinity;
				});

				res.plotAnalysisResults = addFabricatedPlots(res.plotAnalysisResults);

				let tempPlotAnalysisResults = _.sortBy(res.plotAnalysisResults, ["subsampleOrder", "analysisOrder"]);

				setTrialHeatmapData(tempPlotAnalysisResults);
				setRawPresetOptions(res.presetOptions);
				setPlotSubsampleStatuses(res.plotSubsampleStatuses);
			})
			.catch((err) => {
				toast.error("Error loading Trial Heatmap. Please try again.");
				setLoading(false);
				console.log(err);
			});
	}

	useEffect(() => {
		if (trialHeatmapData) {
			//-- analyses
			let anlyss = trialHeatmapData.map((fhmd) => {
				const a = {};
				a.id = fhmd.curveModelName ? fhmd.curveModelName : fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId;
				a.name = fhmd.analysisName
					? fhmd.analysisName
					: fhmd.assessmentName
					? fhmd.assessmentName
					: fhmd.curveModelName;
				a.subsample = fhmd.subsample;
				return a;
			});

			let idsWithSubsamples = _.uniq(
				_.map(
					_.filter(anlyss, (a) => a.subsample && a.subsample > 1),
					"id"
				)
			);

			anlyss = _.filter(anlyss, (a) => !idsWithSubsamples.includes(a.id));

			anlyss = _.sortBy(_.uniqWith(anlyss, _.isEqual), ["name"]);

			//-- quantified regions
			let quantifiedRegions = _.map(_.uniqBy(trialHeatmapData, "quantifiedRegionTypeId"), (qr) => {
				return { key: qr.quantifiedRegionTypeId, value: qr.quantifiedRegionTypeId, text: qr.quantifiedRegionTypeName };
			});

			quantifiedRegions = _.filter(quantifiedRegions, (qr) => {
				return qr.text !== null;
			});
			setQuantifiedRegionTypeOptions(quantifiedRegions);

			setAnalyses(anlyss);

			setLoading(false);
		}
	}, [trialHeatmapData]);

	//-- Set node and ddl options
	useEffect(() => {
		if (flights && flights.length > 0) {
			setProcessing(true);
			setFlightNodes(
				_.map(flights, ({ id, date, published, groundData }) => {
					const opt = {
						id: id,
						date: moment(date).local(),
						published: published,
						color: published ? "#77F777" : "#2185D0",
						groundData: groundData,
						clickable: true,
						firstActive: moduleNavigation.flightId === id || moduleNavigation.timelineFlightId === id,
						tooltip: (
							<>
								{`Flight Date & Time:`}{" "}
								<span style={{ opacity: 0.75 }}>{moment(date).format("ddd --- MMMM DD, YYYY --- hh:mm a")}</span>
								{!groundData && (
									<>
										<br />
										{`Published:`} <span style={{ opacity: 0.75 }}>{published ? "Yes" : "No"}</span>
									</>
								)}
							</>
						)
					};
					return opt;
				})
			);

			if (moduleNavigation.timelineFlightId && !flightId) {
				setFlightId(moduleNavigation.timelineFlightId);
			} else if (moduleNavigation.flightId && !flightId) {
				setFlightId(moduleNavigation.flightId);
			} else {
				setFlightId(flights[0].id);
			}

			setProcessing(false);
		}
	}, [flights]);

	useEffect(() => {
		if (analyses && analyses.length > 0) {
			setProcessing(true);

			//-- Setup the assessment options for the DDLs
			let analyticOptions = [];
			let orthos = analysisDropDownListFunctions.getOrthoTypes(trialHeatmapData);
			let groundData = analysisDropDownListFunctions.getGroundDataPresets(trialHeatmapData);
			let growthCurves = analysisDropDownListFunctions.getGrowthCurvePresets(trialHeatmapData);

			analyticOptions = orthos.concat(groundData).concat(growthCurves);
			setAllAssessments(allAssessments?.length > 0 ? allAssessments : analyticOptions);

			setProcessing(false);
		}
	}, [analyses]);

	useEffect(() => {
		//-- analysis results
		if (trialHeatmapData && analysisId && quantifiedRegionTypeId) {
			setProcessing(true);
			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			//-- flights
			let flts = trialHeatmapData
				.filter((thd) => {
					return thd.curveModelDataAnalysisId != null
						? analysisId == thd.curveModelName
						: (thd.analysisId ? thd.analysisId : thd.assessmentId) === (subsampleId ?? analysisId) &&
								//-- flight analysis quantified region data
								(thd.quantifiedRegionTypeId === quantifiedRegionTypeId || thd.groundDatasetId || thd.assessmentId) &&
								//-- Aalysis Type
								(thd.analysisTypeId === analysisType || thd.groundDatasetId || thd.assessmentId) &&
								//-- Ground Data subsamples
								(subsampleId === null || (subsampleId !== null && subsample == thd.subsample));
				})
				.map((fhmd) => {
					const f = {};
					f.id = fhmd.flightId
						? fhmd.flightId
						: fhmd.groundDatasetId
						? fhmd.groundDatasetId
						: fhmd.curveModelDataAnalysisId
						? fhmd.curveModelDataAnalysisId
						: fhmd.analysisId;
					f.name = fhmd.flightName;
					f.date = fhmd.flightDate
						? fhmd.flightDate
						: fhmd.groundDatasetDate
						? fhmd.groundDatasetDate
						: fhmd.curveModelAnalysisDateTime;
					f.published =
						fhmd.view === "Researcher"
							? fhmd.publishedToTrialOwner
							: fhmd.view === "Owner"
							? fhmd.publishedToTrialSponsor
							: false;
					f.publishedToTrialSponsor = fhmd.publishedToTrialSponsor;
					f.trialOwnerUserId = fhmd.trialOwnerUserId;
					f.trialOwnerFirstName = fhmd.trialOwnerFirstName;
					f.trialOwnerLastName = fhmd.trialOwnerLastName;
					f.trialSponsorUserId = fhmd.trialSponsorUserId;
					f.trialSponsorFirstName = fhmd.trialSponsorFirstName;
					f.trialSponsorLastName = fhmd.trialSponsorLastName;
					f.view = fhmd.view;
					f.groundData = fhmd.flightId === null && fhmd.CurveModelAnalysisId === null;
					f.excludeFromAssessment = fhmd.excludeFromAssessment;
					return f;
				});

			flts = getDistinctObjects(flts);

			let flightDifferences = _.difference(flts, flights);
			if (flightDifferences.length > 0) {
				setFlights(flts);
			}

			setProcessing(false);
		}
	}, [trialHeatmapData, analysisId, quantifiedRegionTypeId]);

	useEffect(() => {
		if (flightId && analysisId) {
			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			const fTrialHeatmapData = _.sortBy(
				trialHeatmapData.filter((fhmd) => {
					return fhmd.curveModelDataAnalysisId != null
						? flightId == fhmd.curveModelDataAnalysisId && analysisId == fhmd.curveModelName
						: (fhmd.flightId ? fhmd.flightId : fhmd.groundDatasetId) === flightId &&
								//-- flight analysis quantified region data
								(fhmd.quantifiedRegionTypeId === quantifiedRegionTypeId || fhmd.groundDatasetId || fhmd.assessmentId) &&
								//-- Analysis Type
								(fhmd.analysisTypeId === analysisType || fhmd.groundDatasetId || fhmd.assessmentId) &&
								(fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId) === (subsampleId ?? analysisId) &&
								(subsampleId === null || (subsampleId !== null && subsample == fhmd.subsample));
				}),
				["plotColumn", "plotRange"]
			);

			var ars = fTrialHeatmapData.map((fhmd) => {
				var r = {};
				r.id = fhmd.flightDataAnalysisResultId;
				r.x = fhmd.plotRange;
				r.y = fhmd.plotColumn;
				r.trialId = fhmd.trialId;
				r.trialName = fhmd.trialName;
				r.analysis = fhmd.analysisName ?? fhmd.curveModelName;
				r.value = fhmd.curveModelName
					? fhmd.curveModelAnalysisValue
					: fhmd.flightId
					? fhmd.plotAnalysisResultValue
					: fhmd.groundDatasetAssessmentValue;
				r.tooltipText = generatePlotTooltipText(fhmd);
				r.excludeFromAssessment = fhmd.excludeFromAssessment;
				r.maxRanges = fhmd.maxRanges;
				r.maxColumns = fhmd.maxColumns;
				r.isGrowthCurveData = fhmd.curveModelName != null;
				return r;
			});

			if (ars.length > 0) {
				setGrowthCurveSelected(ars[0].isGrowthCurveData);
			}

			setAnalysisResults(ars);
		} else {
			setAnalysisResults([]);
		}
	}, [flightId, analysisId, quantifiedRegionTypeId, analysisType]);

	function addFabricatedPlots(plotAnalysisResults) {
		let plotsWithFirstAnalysisId = _.uniqBy(
			_.filter(
				plotAnalysisResults,
				(par) => par.analysisOrder === _.minBy(plotAnalysisResults, "analysisOrder")?.analysisOrder && par.analysisName
			),
			"plotId"
		);

		let growthCurveAnalyses = _.uniqBy(
			_.filter(plotAnalysisResults, (d) => d.curveModelAnalysisId),
			(d) => [d.curveModelAnalysisId, d.analysisId, d.analysisTypeId, d.quantifiedRegionTypeId].join()
		);

		let plotsToFabricate = [];
		_.map(growthCurveAnalyses, (gca) => {
			let allPlotsInCurveModel = _.filter(
				plotAnalysisResults,
				(p) =>
					p.curveModelAnalysisId == gca.curveModelAnalysisId &&
					p.curveModelId === gca.curveModelId &&
					p.analysisId == gca.analysisId &&
					p.quantifiedRegionTypeId == gca.quantifiedRegionTypeId &&
					p.analysisTypeId == gca.analysisTypeId
			);

			if (allPlotsInCurveModel.length !== plotsWithFirstAnalysisId?.length) {
				_.map(plotsWithFirstAnalysisId, (up) => {
					let matchingPlotResult = _.find(
						plotAnalysisResults,
						(p) =>
							p.plotId === up.plotId &&
							p.curveModelAnalysisId == gca.curveModelAnalysisId &&
							p.curveModelId === gca.curveModelId &&
							p.analysisId == gca.analysisId &&
							p.quantifiedRegionTypeId == gca.quantifiedRegionTypeId &&
							p.analysisTypeId == gca.analysisTypeId
					);

					if (!matchingPlotResult) {
						let plotToFabricate = {
							...allPlotsInCurveModel[0],
							plotId: up.plotId,
							plotName: up.plotName,
							plotRange: up.plotRange,
							plotColumn: up.plotColumn,
							curveModelAnalysisValue: -1,
							excludeFromAssessment: true,
							excluded: up.excluded,
							flagged: up.flagged,
							descriptiveCompany: up.descriptiveCompany,
							trialName: up.trialName,
							trialTreatmentId: up.trialTreatmentId,
							plotReplicate: up.plotReplicate
						};

						plotsToFabricate.push(plotToFabricate);
					}
				});
			}
		});

		if (plotsToFabricate.length > 0) {
			plotAnalysisResults = [...plotAnalysisResults, ...plotsToFabricate];
		}

		return plotAnalysisResults;
	}

	function generatePlotTooltipText(plotData) {
		let tooltipText = {};
		tooltipText["Plot"] = plotData.plotName;

		if (plotData.trialTreatmentId) {
			tooltipText["Treatment Id"] = plotData.trialTreatmentId;
		}

		if (plotData.plotReplicate) {
			tooltipText["Replicate"] = plotData.plotReplicate;
		}

		if (plotData.plotSeed) {
			tooltipText["Seed"] = plotData.plotSeed;
		}

		return tooltipText;
	}

	function setCsvInfo(timeSeriesPresets) {
		if (trialHeatmapData && trialHeatmapData.length > 0) {
			const trialName = trialHeatmapData[0].trialName;

			setFileName(`${trialName}_TrialHeatmap_${moment().format("YYYYMMDD_hhmma")}.csv`);

			//Create a uniq list of results or the plot/column pair for a field will be duplicated for each analysis
			let filteredTrialHeatmapData = _.uniqBy(trialHeatmapData, (par) => {
				return [
					par.fieldName,
					par.plotRange,
					par.plotColumn,
					par.flightId ? par.flightId : par.groundDatasetId ? par.groundDatasetId : par.curveModelDataAnalysisId
				].join();
			});

			//Do I need to do this?
			_.map(filteredTrialHeatmapData, (td) => {
				if (td.flightDate === null) {
					if (td.curveModelAnalysisDateTime != null) {
						td.flightDate = td.curveModelAnalysisDateTime;
					} else {
						td.flightDate = td.groundDatasetDate;
					}
				}
			});

			filteredTrialHeatmapData = _.sortBy(filteredTrialHeatmapData, [
				"flightDate",
				"flightId",
				"groundDatasetId",
				"curveModelName",
				"subsample",
				"plotName"
			]);

			let uniqueAnalyses = Object.keys(
				_.groupBy(
					_.filter(trialHeatmapData, (d) => d.flightId !== null),
					(d) => `${d.analysisName} - ${d.quantifiedRegionTypeName}`
				)
			);

			let uniqueAssessmentKeys = Object.keys(
				_.groupBy(
					_.filter(trialHeatmapData, (d) => d.flightId === null && d.curveModelName == null),
					(d) => `${d.assessmentId} - ${d.subsample}`
				)
			).sort();

			let growthCurveAnalyses = _.uniqBy(
				_.filter(trialHeatmapData, (d) => d.curveModelAnalysisId),
				(d) => [d.curveModelAnalysisId, d.analysisId, d.analysisTypeId, d.quantifiedRegionTypeId].join()
			);

			//-- Reorder curve model names
			if (growthCurveAnalyses?.length > 0) {
				_.map(growthCurveAnalyses, (dcma) => {
					let displayName = _.find(
						rawPresetOptions.curveModelAnalysisDisplayNames,
						(dn) =>
							dn.primaryCurveModelAnalysisId === dcma.curveModelAnalysisId &&
							dn.analysisId === dcma.analysisId &&
							dn.analysisTypeId === dcma.analysisTypeId &&
							(dn.primaryQuantifiedRegionTypeId === dcma.quantifiedRegionTypeId ||
								dn.secondaryQuantifiedRegionTypeId === dcma.quantifiedRegionTypeId)
					);

					if (displayName) {
						dcma.curveModelDisplayName = `${displayName.name} (${dcma.curveModelName})`;
						dcma.order = displayName.order;
					}
				});

				growthCurveAnalyses = _.sortBy(
					growthCurveAnalyses,
					[(opt) => opt.order || Infinity, "curveModelName"],
					["desc"]
				);
			}

			let checkedAssessments = [];
			let uniqueAssessments = [];

			_.map(uniqueAssessmentKeys.reverse(), (ua) => {
				if (!checkedAssessments.includes(ua.split(" - ")[0])) {
					if (!(parseInt(ua.split(" - ")[1]) > 1)) {
						uniqueAssessments.push(ua.split(" - ")[0]);
					} else {
						uniqueAssessments.push(ua);
					}

					checkedAssessments.push(ua.split(" - ")[0]);
				} else {
					uniqueAssessments.push(ua);
				}
			});
			uniqueAssessments.reverse();

			let hasGroundData = false;
			let hasExcludedData = false;
			let hasFlaggedData = false;
			let hasNotes = false;
			let hasGrowthCurveData = false;
			let hasFlightData = false;

			_.map(trialHeatmapData, (data) => {
				if (data.excluded || data.excludeFromAssessment) {
					hasExcludedData = true;
				}
				if (data.flagged) {
					hasFlaggedData = true;
				}

				if (data.flightId !== null) {
					hasFlightData = true;
				}

				if (data.groundDatasetId !== null) {
					hasGroundData = true;
				}

				if (data.curveModelDataAnalysisId !== null) {
					hasGrowthCurveData = true;
				}

				if (data.notes !== null) {
					hasNotes = true;
				}
			});

			//-- Flags for subsample annotations and notes
			let hasPcwsAnnotations = false;
			let hasPcwsNotes = false;
			let hasPcAnnotations = false;
			let hasPcNotes = false;
			let hasBrAnnotations = false;
			let hasBrNotes = false;
			let hasFrAnnotations = false;
			let hasFrNotes = false;
			let hasRcAnnotations = false;
			let hasRcNotes = false;

			_.map(plotSubsampleStatuses, (pss) => {
				if (_.some(pss.plotCenteredWithSubsampleStatuses, (status) => status.excluded || status.flagged)) {
					hasPcwsAnnotations = true;

					if (_.some(pss.plotCenteredWithSubsampleStatuses, (status) => status.notes)) {
						hasPcwsNotes = true;
					}
				}

				if (_.some(pss.plotCenteredStatuses, (status) => status.excluded || status.flagged)) {
					hasPcAnnotations = true;

					if (_.some(pss.plotCenteredStatuses, (status) => status.notes)) {
						hasPcNotes = true;
					}
				}

				if (_.some(pss.betweenRowStatuses, (status) => status.excluded || status.flagged)) {
					hasBrAnnotations = true;

					if (_.some(pss.betweenRowStatuses, (status) => status.notes)) {
						hasBrNotes = true;
					}
				}

				if (_.some(pss.fullRowStatuses, (status) => status.excluded || status.flagged)) {
					hasFrAnnotations = true;

					if (_.some(pss.fullRowStatuses, (status) => status.notes)) {
						hasFrNotes = true;
					}
				}

				if (_.some(pss.rowCenteredStatuses, (status) => status.excluded || status.flagged)) {
					hasRcAnnotations = true;

					if (_.some(pss.rowCenteredStatuses, (status) => status.notes)) {
						hasRcNotes = true;
					}
				}
			});

			//-- Quantified region types in dataset
			const quantifiedRegions = _.uniq(_.map(uniqueAnalyses, (a) => a.split(" - ")[1]));

			//-- Map from qrType to plotSubsampleStatus property name
			const qrTypeMaps = [
				{
					type: "Plot-Centered with Subsamples",
					statusName: "plotCenteredWithSubsampleStatuses",
					abbreviation: "pcws"
				},
				{ type: "Plot-Centered", statusName: "plotCenteredStatuses", abbreviation: "pc" },
				{ type: "Between-Row", statusName: "betweenRowStatuses", abbreviation: "br" },
				{ type: "Full Row", statusName: "fullRowStatuses", abbreviation: "fr" },
				{ type: "Row-Centered", statusName: "rowCenteredStatuses", abbreviation: "rc" }
			];

			_.forEach(plotSubsampleStatuses, (pss) => {
				_.forEach(qrTypeMaps, ({ statusName }) => {
					if (pss[statusName]?.length > 0 && !pss[statusName][0]?.partial) {
						let matchingPlots = _.filter(
							trialHeatmapData,
							(thd) => thd.plotId === pss.plotId && (thd.flightId === pss.flightId || thd.curveModelDataAnalysisId)
						);

						if (matchingPlots?.length > 0) {
							_.forEach(matchingPlots, (mp) => {
								if (
									mp.curveModelDataAnalysisId &&
									!_.map(
										_.filter(selectedFlights, (sf) => sf.curveModelDataAnalysisId === mp.curveModelDataAnalysisId),
										"flightId"
									).includes(pss.flightId)
								) {
									return;
								}

								if (pss[statusName][0]?.excluded) {
									mp.excluded = true;
									hasExcludedData = true;
								}

								if (pss[statusName][0]?.flagged) {
									mp.flagged = true;
									hasFlaggedData = true;
								}

								mp.notes = pss[statusName][0]?.notes;
							});
						}
					}
				});
			});

			let csvObject = filteredTrialHeatmapData.map((fed) => {
				let data = {
					Field: fed.fieldName,
					Range: fed.plotRange,
					Column: fed.plotColumn,
					Company: fed.descriptiveCompany,
					Trial: fed.trialName,
					Treatment: fed.trialTreatmentId,
					Plot: fed.plotName,
					Rep: fed.plotReplicate,
					Seed: fed.plotSeed
				};

				//Do I need this?
				if (hasExcludedData || hasFlaggedData) {
					let matchingPlot = trialHeatmapData.find(
						(p) => p.plotId === fed.plotId && p.flightId === fed.flightId && fed.curveModelName == null
					);

					if (matchingPlot?.excluded || matchingPlot?.flagged || matchingPlot?.excludeFromAssessment) {
						if (matchingPlot.excluded || matchingPlot.excludeFromAssessment)
							data["Annotations - Entire Plot"] = "Excluded";
						if (matchingPlot.flagged) data["Annotations - Entire Plot"] = "Flagged";
					} else {
						data["Annotations - Entire Plot"] = null;
					}
				}

				if (hasNotes) {
					data["Notes - Entire Plot"] = fed?.notes ?? null;
				}

				data.AssessmentDate =
					fed.curveModelName == null
						? moment(fed.flightId ? fed.flightDate : fed.groundDatasetDate)
								.local()
								.format(ANALYSIS_DATE_FORMAT)
						: hasGrowthCurveData
						? trialHeatmapData.find(
								(p) =>
									p.curveModelName == fed.curveModelName &&
									fed.curveModelDataAnalysisId == p.curveModelDataAnalysisId &&
									fed.curveModelAnalysisResultId == p.curveModelAnalysisResultId
						  )?.curveModelAnalysisDateTime
							? moment
									.utc(
										trialHeatmapData.find(
											(p) =>
												p.curveModelName == fed.curveModelName &&
												fed.curveModelDataAnalysisId == p.curveModelDataAnalysisId &&
												fed.curveModelAnalysisResultId == p.curveModelAnalysisResultId
										).curveModelAnalysisDateTime
									)
									.local()
									.format(ANALYSIS_DATE_FORMAT)
							: null
						: null;

				if (hasFlightData) {
					_.map(quantifiedRegions, (qr) => {
						_.map(
							_.filter(uniqueAnalyses, (a) => a.split(" - ")[1] === qr),
							(a) => {
								let value = null;
								const analysisName = a.split(" - ")[0];
								const qrType = a.split(" - ")[1];

								value = trialHeatmapData.find(
									(p) =>
										p.plotId === fed.plotId &&
										p.flightId === fed.flightId &&
										p.analysisName == analysisName &&
										p.quantifiedRegionTypeName == qrType &&
										p.isPlotUniformityValue == false
								);
								let preset = "";
								if (value) {
									preset = _.find(timeSeriesPresets, (tsp) => {
										return tsp.analysisid === value?.analysisId && tsp.analysistypeid === value?.analysisTypeId;
									});
									data[preset?.text ? preset?.text + " - " + qrType : a] = value?.plotAnalysisResultValue;
								}

								if (preset?.text?.includes("Stand Count")) {
									data["Counted Row Length (ft)"] = value?.countedRowLength;
									data["Counted Region (acres)"] = value?.countedRegion.toFixed(5);
									data["Population(plants/acre)"] = value?.standCountAnalysisResultValue;
								}
							}
						);

						//-- Subsample annotations
						let matchingQrType = _.find(qrTypeMaps, { type: qr });
						let matchingPlotSubsampleStatus = _.find(
							plotSubsampleStatuses,
							(pss) =>
								pss.plotId === fed.plotId &&
								(pss.flightId === fed.flightId ||
									(fed.curveModelAnalysisId !== null &&
										_.map(
											_.filter(selectedFlights, (sf) => sf.curveModelDataAnalysisId === fed.curveModelDataAnalysisId),
											"flightId"
										).includes(pss.flightId)))
						);
						if (!matchingPlotSubsampleStatus || fed.excluded || fed.flagged) {
							let statusText = fed.excluded ? "excluded" : fed.flagged ? "flagged" : "";
							let notesText = fed.notes ?? "";

							if (hasPcwsAnnotations && matchingQrType?.abbreviation === "pcws") {
								data[`Annotations - ${qr}`] = statusText;
								if (hasPcwsNotes) data[`Notes - ${qr}`] = notesText;
							}
							if (hasPcAnnotations && matchingQrType?.abbreviation === "pc") {
								data[`Annotations - ${qr}`] = statusText;
								if (hasPcNotes) data[`Notes - ${qr}`] = notesText;
							}
							if (hasBrAnnotations && matchingQrType?.abbreviation === "br") {
								data[`Annotations - ${qr}`] = statusText;
								if (hasBrNotes) data[`Notes - ${qr}`] = notesText;
							}
							if (hasFrAnnotations && matchingQrType?.abbreviation === "fr") {
								data[`Annotations - ${qr}`] = statusText;
								if (hasFrNotes) data[`Notes - ${qr}`] = notesText;
							}
							if (hasRcAnnotations && matchingQrType?.abbreviation === "rc") {
								data[`Annotations - ${qr}`] = statusText;
								if (hasRcNotes) data[`Notes - ${qr}`] = notesText;
							}
						} else {
							setupQrAnnotationColumns(data, qr, qrTypeMaps, matchingPlotSubsampleStatus);
						}
					});

					//-- Plot Uniformity
					_.map(uniqueAnalyses, (a) => {
						let value = null;
						const analysisName = a.split(" - ")[0];
						const qrType = a.split(" - ")[1];

						if (qrType === "Plot-Centered with Subsamples") {
							value = trialHeatmapData.find(
								(p) =>
									p.plotId === fed.plotId &&
									p.flightId === fed.flightId &&
									p.analysisName == analysisName &&
									p.quantifiedRegionTypeName == qrType &&
									p.isPlotUniformityValue == false
							);
							let preset = "";
							if (value) {
								preset = _.find(timeSeriesPresets, (tsp) => {
									return tsp.analysisid === value.analysisId && tsp.analysistypeid === value.analysisTypeId;
								});
							}

							let plotUniformity = trialHeatmapData.find(
								(p) =>
									p.plotId === fed.plotId &&
									p.flightId === fed.flightId &&
									p.analysisName == analysisName &&
									p.quantifiedRegionTypeName == qrType &&
									p.isPlotUniformityValue == true
							);
							if (plotUniformity) {
								const name = preset?.text ?? analysisName;
								data[`${name} - Uniformity`] = plotUniformity?.plotAnalysisResultValue;
							}
						}
					});
				}

				if (hasGroundData) {
					_.map(uniqueAssessments, (ua) => {
						let value = null;
						// let maxSubsample = _.max(
						// 	_.map(
						// 		_.filter(trialHeatmapData, (p) => p.plotId === fed.plotId && p.assessmentId === ua.split(" - ")[0]),
						// 		"subsample"
						// 	)
						// );
						// let currentSubsample = ua.split(" - ").length > 1 ? parseInt(ua.split(" - ")[1]) : 1;
						let assessmentName = _.find(trialHeatmapData, (p) => p.assessmentId === ua.split(" - ")[0])?.assessmentName;

						if (ua.includes(" - ")) {
							value =
								trialHeatmapData.find(
									(p) =>
										p.plotId === fed.plotId &&
										p.groundDatasetId === fed.groundDatasetId &&
										p.assessmentId === ua.split(" - ")[0] &&
										p.subsample === parseInt(ua.split(" - ")[1])
								)?.groundDatasetAssessmentValue ?? null;
							data[`${assessmentName} - ${ua.split(" - ")[1]}`] = value;
						} else {
							value =
								trialHeatmapData.find(
									(p) =>
										p.plotId === fed.plotId &&
										p.groundDatasetId === fed.groundDatasetId &&
										p.assessmentId === ua.split(" - ")[0] &&
										(p.subsample === 1 || !p.subsample)
								)?.groundDatasetAssessmentValue ?? null;
							data[assessmentName] = value;
						}

						// if (maxSubsample === currentSubsample && maxSubsample === 1) {
						// 	data[`${assessmentName.split(" (")[0]} AssessmentDateTime`] =
						// 		value === null
						// 			? null
						// 			: trialHeatmapData.find(
						// 					(p) =>
						// 						p.plotId === fed.plotId &&
						// 						p.groundDatasetId === fed.groundDatasetId &&
						// 						p.assessmentId === ua.split(" - ")[0] &&
						// 						(p.subsample === 1 || p.subsample === null)
						// 			  )?.groundDatasetDate
						// 			? moment
						// 					.utc(
						// 						trialHeatmapData.find(
						// 							(p) =>
						// 								p.plotId === fed.plotId &&
						// 								p.groundDatasetId === fed.groundDatasetId &&
						// 								p.assessmentId === ua.split(" - ")[0] &&
						// 								(p.subsample === 1 || p.subsample === null)
						// 						).groundDatasetDate
						// 					)
						// 					.local()
						// 					.format(ANALYSIS_DATE_FORMAT)
						// 			: null;
						// }
					});
				}

				if (hasGrowthCurveData) {
					_.map(growthCurveAnalyses, (gca) => {
						let value = null;

						if (gca.curveModelName != null) {
							value = trialHeatmapData.find(
								(p) =>
									p.plotId === fed.plotId &&
									p.curveModelAnalysisId == gca.curveModelAnalysisId &&
									fed.curveModelDataAnalysisId == p.curveModelDataAnalysisId &&
									p.analysisId == gca.analysisId &&
									p.quantifiedRegionTypeId == gca.quantifiedRegionTypeId &&
									p.analysisTypeId == gca.analysisTypeId
							);

							if (value?.curveModelAnalysisValue === -1) value.curveModelAnalysisValue = "-";

							//-- We always want to assign a value to this header to keep the ordering of headers
							data[gca.curveModelDisplayName ?? gca.curveModelName] = value?.curveModelAnalysisValue ?? "";

							if (value?.excluded || value?.flagged || value?.excludeFromAssessment) {
								if (value.excluded || value.excludeFromAssessment) data["Annotations - Entire Plot"] = "Excluded";
								if (value.flagged) data["Annotations - Entire Plot"] = "Flagged";
							}
						}
					});
				}

				return data;
			});

			setAdminCsvData(_.cloneDeep(csvObject));

			if (hasGrowthCurveData) {
				let headersToRemove = _.map(
					_.filter(growthCurveAnalyses, (gca) => !gca.order),
					"curveModelName"
				);

				_.forEach(csvObject, (obj) => {
					_.forEach(headersToRemove, (header) => {
						delete obj[header];
					});
				});
			}

			setCsvData(csvObject);
		}
	}

	function setupQrAnnotationColumns(data, qr, qrTypeMaps, matchingPlotSubsampleStatus) {
		let mappedStatus = _.find(qrTypeMaps, { type: qr })?.statusName;
		if (mappedStatus) {
			let partial = matchingPlotSubsampleStatus[mappedStatus][0]?.partial;

			if (
				_.some(matchingPlotSubsampleStatus[mappedStatus], "excluded") &&
				!data[`Annotations - ${qr}`]?.includes("excluded")
			)
				data[`Annotations - ${qr}`]
					? (data[`Annotations - ${qr}`] += `; ${partial ? "partially " : ""}excluded`)
					: (data[`Annotations - ${qr}`] = `${partial ? "partially " : ""}excluded`);
			if (
				_.some(matchingPlotSubsampleStatus[mappedStatus], "flagged") &&
				!data[`Annotations - ${qr}`]?.includes("flagged")
			)
				data[`Annotations - ${qr}`]
					? (data[`Annotations - ${qr}`] += `; ${partial ? "partially " : ""}flagged`)
					: (data[`Annotations - ${qr}`] = `${partial ? "partially " : ""}flagged`);

			if (_.some(matchingPlotSubsampleStatus[mappedStatus], "notes")) {
				data[`Notes - ${qr}`] = _.map(
					_.uniqBy(_.filter(matchingPlotSubsampleStatus[mappedStatus], "notes"), "notes"),
					(mpss) => `${mpss.notes}; `
				);
			}
		}
	}

	function updateSelectedAnalysis(value) {
		setAnalysisId(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setQuantifiedRegionTypeId(value);
	}

	function updateSelectedAnalysisType(value) {
		setAnalysisType(value);
	}

	async function updatePresetList(timeSeriesPresets) {
		if (!csvData) {
			setLoading(true);
			await timeout(1);
			setCsvInfo(timeSeriesPresets);
			setLoading(false);
		}
	}

	function timeout(delay) {
		return new Promise((resolve) => {
			setTimeout(resolve, delay);
		});
	}

	return loading ? (
		<Loader active />
	) : !loading && (!trialHeatmapData || trialHeatmapData.length === 0) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Heatmap</h2>
			<hr />
			<Segment>
				<p>{"We could not find heatmap data for this trial."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<hr style={{ clear: "both" }} />
			<Form>
				<Grid id="trial-heatmap-grid" verticalAlign="middle">
					<Grid.Row>
						<Grid.Column>
							<Form.Field>
								{rawPresetOptions ? (
									<AnalysisDropDownLists
										rawPresetOptions={rawPresetOptions}
										allAssessmentTypes={allAssessments}
										quantifiedRegionTypes={quantifiedRegionTypeOptions}
										defaultQuantifiedRegion={
											_.find(trialHeatmapData, (t) => {
												return t.isDefaultQuantifiedRegion === true;
											})?.quantifiedRegionTypeId
										}
										updatedSelectedAnalysisType={updateSelectedAnalysisType}
										updateSelectedAnalysis={updateSelectedAnalysis}
										updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
										updatePresetList={updatePresetList}
										isApAdmin={userAuth.isApAdmin}
										heatmap={true}
									>
										<div style={{ width: "calc(100% - 1000px)" }} />
										{moduleNavigation.fieldId &&
											userAuth.hasApplicationArea(ApplicationArea.FieldHeatMap, userAuth.currentClientId) && (
												<Link to={moduleNavigation.createFieldLink(true, null, "heatmap")} target="_blank">
													View Field Heatmap
												</Link>
											)}
										{csvData &&
											userAuth.hasApplicationArea(ApplicationArea.FieldHeatMap, userAuth.currentClientId) &&
											userAuth.hasApplicationArea(
												ApplicationArea.TrialExportFunctionality,
												userAuth.currentClientId
											) && (
												<CSVLink filename={fileName} data={csvData} enclosingCharacter={""}>
													Download Heatmap Data
												</CSVLink>
											)}
										{adminCsvData && userAuth.isApAdmin && (
											<CSVLink
												filename={fileName}
												data={adminCsvData}
												enclosingCharacter={""}
												style={{ paddingLeft: 25 }}
											>
												Download Heatmap Data (Admin View)
											</CSVLink>
										)}
									</AnalysisDropDownLists>
								) : (
									<></>
								)}
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					{!_.isEmpty(flightNodes) && !growthCurveSelected && (
						<Grid.Row style={{ paddingBottom: 30 }}>
							<Grid.Column>
								<Segment basic style={{ width: "90%", margin: "0 auto" }}>
									<Timelineslider
										dates={flightNodes}
										updateSelected={(selected) => {
											setFlightId(selected.id);
										}}
									/>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					)}
					<Grid.Row centered style={{ border: "1px solid" }}>
						<Grid.Column width="16">
							{!processing && _.isEmpty(analysisResults) ? (
								<Segment basic>
									<p>
										{
											"We couldn't find data for the trial or selected analysis. Please run an analysis to see the heatmap."
										}
									</p>
								</Segment>
							) : processing ? (
								<></>
							) : (
								<TransformWrapper limitToBounds={false} centerOnInit>
									{({ zoomIn, zoomOut, centerView }) => (
										<React.Fragment>
											<div className="tools" style={{ float: "left" }}>
												<Button icon onClick={() => zoomIn()}>
													<Icon name="plus" />
												</Button>
												<Button icon onClick={() => zoomOut()}>
													<Icon name="minus" />
												</Button>
												<Button icon onClick={() => centerView(1)}>
													<Icon name="refresh" />
												</Button>
											</div>
											<TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
												<Heatmap data={analysisResults} />
											</TransformComponent>
										</React.Fragment>
									)}
								</TransformWrapper>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default TrialHeatmapLegacy;
