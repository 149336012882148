import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as queryString from "query-string";

import moment from "moment";
import { toast } from "react-toastify";
import { Button, Loader, Menu, Message, Segment } from "semantic-ui-react";

import { Prompt, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as protocolActions from "../../../../redux/actions/protocolActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";
import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";
import * as excelGenerationFunctions from "../../../../utilities/excelGeneration";

import ProtocolBoxWhisker from "./ProtocolBoxWhisker";
import ProtocolPianoCharts from "./ProtocolPianoCharts";
import ProtocolSummaryTable from "./ProtocolSummaryTable";
import ProtocolTimecourse from "./ProtocolTimecourse";

import _ from "lodash";
import ConfrimNavModal from "../../../Lumber/ConfirmNavModal";
import TrialMetaFactorsModal from "../../Trials/TrialMetaTags/TrialMetaFactorsModal.tsx";
import HeatmapExportModal from "../../../Lumber/HeatmapExportModal/index.tsx";

const ProtocolAnalysis = () => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const location = useLocation();
	const history = useHistory();
	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	const summaryTable = "summary-table";
	const boxWhiskerChart = "box-whisker";
	const timecourse = "timecourse";
	const pianoChart = "piano-chart";

	const urlQueryStrings = queryString.parse(location.search);
	const qsAt = urlQueryStrings.at;

	//-- Data
	const [protocolName, setProtocolName] = useState(null);
	const [dashboardOptions, setDashboardOptions] = useState(null);
	const [assessmentOptions, setAssessmentOptions] = useState(null);
	const [plannedTimingOptions, setPlannedTimingOptions] = useState(null);
	const [dataNormalizationOptions, setDataNormalizationOptions] = useState(null);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState(null);
	const [alphaOptions, setAlphaOptions] = useState(null);
	const [presetOptions, setPresetOptions] = useState(null);
	const [treatmentData, setTreatmentData] = useState(null);
	const [allMeanComparisonOptions, setAllMeanComparisonOptions] = useState(null);
	const [analysisOptions, setAnalysisOptions] = useState(null);
	const [gduByDay, setGDUByDay] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [activeItem, setActiveItem] = useState(summaryTable);
	const [showPiano] = useState(true);
	const [dataDone, setDataDone] = useState(true);
	const [weatherDataDone, setWeatherDataDone] = useState(false);
	const [weatherDataExcelDownloadLoading, setWeatherDataExcelDownloadLoading] = useState(false);
	const [metaFactorModalOpen, setMetaFactorsModalOpen] = useState(false);
	const [modalTrialId, setModalTrialId] = useState("");
	const [modalTrialName, setModalTrialName] = useState("");
	const [saveAction, setSaveAction] = useState();
	const [isDirty, setIsDirty] = useState(false);
	const [openConfirmNavModal, setOpenConfirmNavModal] = useState(false);
	const [target, setTarget] = useState("");
	const [heatmapExportModalOpen, setHeatmapExportModalOpen] = useState(false);

	useEffect(() => {
		if (!isDirty && target !== "") {
			history.push(target);
		}
	}, [isDirty, target]);

	useEffect(() => {
		if (userAuth.isReady) {
			setActiveItem(getAnalysisTypeFromQueryString());
			if (dashboardOptions === null) {
				getProtocolDashboardOptions();
				getMeanComparisonData();
				getAnalysesDashboardOptions();
			}
		}
	}, [userAuth.isReady, location.search, userAuth.currentClientId]);

	function getAnalysisTypeFromQueryString() {
		switch (qsAt) {
			case summaryTable:
				return summaryTable;
			case boxWhiskerChart:
				return boxWhiskerChart;
			case pianoChart:
				return pianoChart;
			default:
			case timecourse:
				return timecourse;
		}
	}

	useEffect(() => {
		if (reduxProtocolData?.id && moduleNavigation.protocolId?.toUpperCase() === reduxProtocolData.id?.toUpperCase()) {
			setProtocolName(reduxProtocolData?.protocolName);
			getTreatmentData();
		} else {
			getProtocolData();
		}
		//getPlotAnalysisStatus();
	}, [reduxProtocolData]);

	useEffect(() => {
		if (dashboardOptions) {
			if (analysisOptions !== null) {
				setAssessmentOptionsState(
					dashboardOptions.groundDataAssessments,
					dashboardOptions.curveModels,
					analysisOptions
				);
			} else {
				setAssessmentOptionsState(dashboardOptions.groundDataAssessments, dashboardOptions.curveModels);
			}

			setPlannedTimingOptionsState(dashboardOptions.groundDataAssessments);
			setDataNormalizationOptionsState(dashboardOptions.dataNormalizations);
			setMeanComparisonOptionsState(dashboardOptions.meanComparisons);
			setAlphaOptionsState(dashboardOptions.alphas);
			setPresetOptionsState(dashboardOptions.summaryTablePresets);
		}
	}, [dashboardOptions, analysisOptions]);

	//Weather Data UseEffect to Get Daily GDU values
	useEffect(() => {
		if (!gduByDay && dashboardOptions?.trialIds) {
			getGduByDay();
		}
	}, [dashboardOptions?.trialIds]);

	const handleNavigateOut = async (action) => {
		switch (action) {
			case "saveCont":
				await saveAction();
				setIsDirty(false);
				setOpenConfirmNavModal(false);
				break;
			case "save":
				await saveAction();
				setTarget("");
				setOpenConfirmNavModal(false);
				setIsDirty(false);
				break;
			case "discard":
				setIsDirty(false);
				setOpenConfirmNavModal(false);
				break;
			default:
				setTarget("");
				setOpenConfirmNavModal(false);
		}
	};

	function setAssessmentOptionsState(groundDataAssessments, curveModelsAnalyses, analysisOptions = null) {
		let groundData = _.map(
			_.uniqBy(_.sortBy(groundDataAssessments, "groundDataAssessmentName"), "groundDataAssessmentId"),
			({ groundDataAssessmentId, groundDataAssessmentName, unitName }) => {
				const opt = {
					key: groundDataAssessmentId,
					value: groundDataAssessmentId,
					text: groundDataAssessmentName,
					unitName: unitName,
					isgrounddata: String(true),
					iscurvemodel: String(false)
				};
				return opt;
			}
		);
		let curveModels = _.map(
			_.sortBy(curveModelsAnalyses, [(cma) => cma?.order || Infinity, "curveModelAnalysisAbbreviation"], ["desc"]),
			({ curveModelAnalysisId, analysisId, curveModelName, unitName, analysisTypeId, displayName, order }) => {
				//-- React is stupid. In order to  have booleans on a DOM object, it has to be a string. All of the props have to be lower case too
				const opt = {
					key: curveModelName,
					value: curveModelName,
					text: displayName ? `${displayName} (${curveModelName})` : curveModelName,
					curvemodelanalysisid: curveModelAnalysisId,
					analysisid: analysisId,
					analysistypeid: analysisTypeId,
					unitName: unitName,
					isgrounddata: String(false),
					iscurvemodel: String(true),
					order: order
				};
				return opt;
			}
		);
		if (analysisOptions !== null) {
			//If we have stand count data then add it to the assessment selections options, otherwise don't show it as an option
			if (dashboardOptions.trialsContainStandCounts) {
				let standCountAnaysis = _.find(analysisOptions.data, (ao) => {
					return ao.name.includes("Stand Count (LAI)");
				});
				const standCountData = {
					key: standCountAnaysis.id,
					value: standCountAnaysis.id,
					text: standCountAnaysis.name,
					analysisid: standCountAnaysis.id,
					analysistypeid: standCountAnaysis.analysisTypeId,
					isgrounddata: String(false),
					iscurvemodel: String(false),
					isStandCount: String(true)
				};

				let curveModelAndGroundData = groundData.concat(curveModels);
				//Move stand count to the top of the list
				curveModelAndGroundData.unshift(standCountData);
				setAssessmentOptions(curveModelAndGroundData);
			} else {
				setAssessmentOptions(groundData.concat(curveModels));
			}
		} else {
			setAssessmentOptions(groundData.concat(curveModels));
		}
		setDataDone(true);
	}

	function setPlannedTimingOptionsState(groundDataAssessments) {
		setPlannedTimingOptions(
			_.map(
				_.uniqBy(groundDataAssessments, "groundDataGrowthPhaseId"),
				({ groundDataGrowthPhaseId, groundDataGrowthPhaseName }) => {
					const groundDataAssessmentIds = _.map(
						_.filter(groundDataAssessments, (gda) => {
							return gda.groundDataGrowthPhaseId === groundDataGrowthPhaseId;
						}),
						"groundDataAssessmentId"
					);
					const opt = {
						key: groundDataGrowthPhaseId,
						value: groundDataGrowthPhaseId,
						text: groundDataGrowthPhaseName,
						grounddataassessmentids: [].concat(groundDataAssessmentIds)
					};
					return opt;
				}
			)
		);
	}

	function setDataNormalizationOptionsState(dataNormalizations) {
		setDataNormalizationOptions(
			_.map(dataNormalizations, ({ id, name, treatmentTypeId }) => {
				const opt = { key: id, value: id, text: name, treatmenttypeid: treatmentTypeId };
				return opt;
			})
		);
	}

	function setMeanComparisonOptionsState(meanComparisons) {
		setMeanComparisonOptions(
			_.map(meanComparisons, ({ id, name }) => {
				const opt = { key: id, value: id, text: name };
				return opt;
			})
		);
	}

	function setAlphaOptionsState(alphas) {
		setAlphaOptions(
			_.map(_.orderBy(alphas, ["value"]), ({ id, value }) => {
				const opt = { key: id, value: id, text: value };
				return opt;
			})
		);
	}

	function setPresetOptionsState(presets) {
		let tempOptions = [{ key: "none", value: "none", text: "None" }];

		tempOptions = tempOptions.concat(
			_.map(presets, (p) => {
				const opt = { key: p.id, value: p.id, text: p.name };
				return opt;
			})
		);

		setPresetOptions(tempOptions);
	}

	async function getMeanComparisonData() {
		const accessToken = await getTokenSilently();
		dispatch(statisticsActions.getMeanComparisonsOptions(userAuth.currentClientId, accessToken)).then((options) => {
			setAllMeanComparisonOptions(options);
		});
	}

	async function getProtocolData() {
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocolData(moduleNavigation.protocolId, userAuth.currentClientId, accessToken))
			.then(() => { })
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	async function getTreatmentData() {
		const accessToken = await getTokenSilently();
		dispatch(
			protocolActions.getProtocolTreatmentData(moduleNavigation.protocolId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				setTreatmentData(res.data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}

	async function getProtocolDashboardOptions() {
		const accessToken = await getTokenSilently();
		dispatch(
			protocolActions.getProtocolDashboardOptions(moduleNavigation.protocolId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				setDashboardOptions(res);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	async function getAnalysesDashboardOptions() {
		const accessToken = await getTokenSilently();
		dispatch(analysisActions.getAnalyses(userAuth.currentClientId, accessToken))
			.then((res) => {
				setAnalysisOptions(res);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	async function getGduByDay() {
		const accessToken = await getTokenSilently();
		let weatherDataTemp = [];
		dispatch(weatherDataActions.getGduDataForTrials(dashboardOptions.trialIds, userAuth.currentClientId, accessToken))
			.then((res) => {
				_.forEach(res, (r) => {
					weatherDataTemp.push({ trialId: r.trialDataViewModel?.trialInfo.trialId, data: r });
				});
				setGDUByDay(res);
				setWeatherDataDone(true);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	async function generateProtocolWeatherDataExcel() {
		const accessToken = await getTokenSilently();
		setWeatherDataExcelDownloadLoading(true);
		let currentDateTimeString = JSON.stringify(new Date().toLocaleString());
		let protocolNameForExcel = protocolName ?? "Protocol";
		protocolNameForExcel = `${protocolNameForExcel}_ProtocolWeatherData_${moment().format("YYYYMMDD_hhmma")}`;
		dispatch(
			weatherDataActions.generateProtocolWeatherDataExcel(
				dashboardOptions.trialIds,
				userAuth.currentClientId,
				userAuth.currentClient.clientName,
				currentDateTimeString,
				accessToken
			)
		)
			.then((res) => {
				_.map(res, (trial) => {
					trial.trialName = `${trial.trialName}_${trial.cooperatorName}_TrialWeather_${moment().format(
						"YYYYMMDD_hhmma"
					)}.xlsx`;
				});
				excelGenerationFunctions.downloadZippedExcelFile(res, protocolNameForExcel);

				setWeatherDataExcelDownloadLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setWeatherDataExcelDownloadLoading(false);
				console.log(err);
				toast.error("Error downloading weather data excel file. Please try again.");
			});
	}

	const toggleMetaFactorModal = (trialId, trialName, open = true) => {
		if (trialId) {
			setModalTrialId(trialId);
			setModalTrialName(trialName);
			setMetaFactorsModalOpen(open);
		}
	};

	return loading || !userAuth.isReady ? (
		<Loader active />
	) : !loading && !protocolName && userAuth.isReady ? (
		<Segment style={{ margin: 15 }}>
			<p>{"We could not find analysis data for this protocol."}</p>
		</Segment>
	) : (
		<>
			<Segment basic id="protocolAnalysis">
				<Menu pointing>
					<Menu.Item
						name="Timecourse"
						active={activeItem === timecourse}
						onClick={() => {
							history.push(`?at=${timecourse}`);
						}}
					/>
					<Menu.Item
						name="Box Whisker"
						active={activeItem === boxWhiskerChart}
						onClick={() => {
							history.push(`?at=${boxWhiskerChart}`);
						}}
					/>
					<Menu.Item
						name="Summary Table"
						active={activeItem === summaryTable}
						onClick={() => {
							history.push(`?at=${summaryTable}`);
						}}
					/>
					{showPiano && (
						<Menu.Item
							name="Piano Charts"
							active={activeItem === pianoChart}
							onClick={() => {
								history.push(`?at=${pianoChart}`);
							}}
						/>
					)}
					{dashboardOptions?.trialIds.length > 0 && (
						<Menu.Menu position="right">
							<Menu.Item style={{ gap: "5px" }}>
								<Button
									primary={dataDone}
									content="Trial Data Download"
									icon="download solid"
									labelPosition="left"
									onClick={() => setHeatmapExportModalOpen(true)}
									floated="right"
								/>
								<HeatmapExportModal
									id={moduleNavigation.protocolId}
									open={heatmapExportModalOpen}
									setOpen={setHeatmapExportModalOpen}
									name={protocolName}
									type={"Protocol"}
									isApAdmin={userAuth?.isApAdmin}
								/>
								<Button
									primary={weatherDataDone}
									floated="right"
									icon="download solid"
									labelPosition="left"
									content="Weather Data Download (.zip)"
									onClick={() => generateProtocolWeatherDataExcel()}
									loading={weatherDataExcelDownloadLoading}
									disabled={!weatherDataDone}
								/>
							</Menu.Item>
						</Menu.Menu>
					)}
				</Menu>
				{!dataDone && (
					<Message warning>
						Plot analysis data for this protocol is currently being recalculated, so data may not be complete or up to
						date. Please refresh the page in a few minutes.
					</Message>
				)}
				{activeItem === summaryTable
					? treatmentData &&
					!loading &&
					dashboardOptions &&
					assessmentOptions?.length > 0 && (
						<ProtocolSummaryTable
							assessmentOptions={assessmentOptions}
							plannedTimingOptions={plannedTimingOptions}
							dataNormalizationOptions={dataNormalizationOptions}
							trialIds={dashboardOptions.trialIds}
							treatmentData={treatmentData.protocolTreatments}
							dashboardOptions={dashboardOptions}
							meanComparisonOptions={meanComparisonOptions}
							alphaOptions={alphaOptions}
							presetOptions={presetOptions}
							defaultMeanComparisonOption={dashboardOptions?.defaultMeanComparisonId}
							defaultAlphaOption={dashboardOptions?.defaultAlphaId}
							presetData={dashboardOptions.summaryTablePresets}
							setIsDirty={setIsDirty}
							setSaveAction={setSaveAction}
						/>
					)
					: null}
				{activeItem === boxWhiskerChart ? (
					treatmentData && assessmentOptions?.length > 0 && !loading && dashboardOptions ? (
						<ProtocolBoxWhisker
							assessmentOptions={assessmentOptions}
							plannedTimingOptions={plannedTimingOptions}
							dataNormalizationOptions={dataNormalizationOptions}
							meanComparisonOptions={meanComparisonOptions}
							alphaOptions={alphaOptions}
							trialIds={dashboardOptions.trialIds}
							treatmentData={treatmentData.protocolTreatments}
							defaultMeanComparisonOption={dashboardOptions?.defaultMeanComparisonId}
							defaultAlphaOption={dashboardOptions?.defaultAlphaId}
							toggleMetaFactorModal={toggleMetaFactorModal}
							setIsDirty={setIsDirty}
							setSaveAction={setSaveAction}
						/>
					) : (
						<Segment>
							<p>{"We could not find analysis data for this protocol."}</p>
						</Segment>
					)
				) : null}
				{activeItem === timecourse && treatmentData && !loading && dashboardOptions && allMeanComparisonOptions ? (
					<ProtocolTimecourse
						dataNormalizationOptions={dataNormalizationOptions}
						meanComparisonOptions={meanComparisonOptions}
						treatmentData={treatmentData.protocolTreatments}
						alphaOptions={alphaOptions}
						trialIds={dashboardOptions.trialIds}
						trialAnalysisOptions={dashboardOptions.trialAnalysisOptions}
						statelessDataNormalizationOptions={dashboardOptions.dataNormalizations}
						allMeanComparisonOptions={allMeanComparisonOptions}
						defaultMeanComparisonOption={dashboardOptions?.defaultMeanComparisonId}
						defaultAlphaOption={dashboardOptions?.defaultAlphaId}
						gduByDay={gduByDay}
						toggleMetaFactorModal={toggleMetaFactorModal}
						setIsDirty={setIsDirty}
						setSaveAction={setSaveAction}
					/>
				) : null}
				{activeItem === pianoChart && treatmentData && !loading && dashboardOptions ? (
					<ProtocolPianoCharts
						assessmentOptions={_.filter(assessmentOptions, (ao) => {
							return !ao?.isStandCount;
						})}
						plannedTimingOptions={plannedTimingOptions}
						dataNormalizationOptions={dataNormalizationOptions}
						treatmentData={treatmentData.protocolTreatments}
						trialIds={dashboardOptions.trialIds}
					/>
				) : null}
			</Segment>
			<TrialMetaFactorsModal
				open={metaFactorModalOpen}
				setOpen={setMetaFactorsModalOpen}
				trialId={modalTrialId}
				trialName={modalTrialName}
				isForProtocol={true}
			/>
			<Prompt
				when={isDirty}
				message={(location) => {
					setTarget(location.pathname + location.search);
					setOpenConfirmNavModal(true);
					return false;
				}}
			/>
			<ConfrimNavModal
				open={openConfirmNavModal}
				onCancel={() => {
					handleNavigateOut("cancel");
				}}
				onSave={() => {
					handleNavigateOut("saveCont");
				}}
				onDiscard={() => {
					handleNavigateOut("discard");
				}}
			/>
		</>
	);
};

export default ProtocolAnalysis;
