import * as types from "./actionTypes";
import * as regridApi from "../../apis/regridApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function getParcelsInAreaSuccess() {
	return { type: types.GET_PARCELS_IN_AREA_SUCCESS };
}

export function getParcelsInArea(bbox) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return regridApi
				.getParcelsInArea(bbox)
				.then((res) => {
					if (!res) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getParcelsInAreaSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
