import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import { Loader, Segment } from "semantic-ui-react";

import AssessmentLog from "../../../Lumber/AssessmentLog";
const TrialAssessmentLog = () => {
	const moduleNavigation = useModuleNavigation();
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	const [trialName, setTrialName] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (entityNames.trialId && moduleNavigation.trialId.toUpperCase() === entityNames.trialId.toUpperCase()) {
			setTrialName(entityNames.trialName);
			setLoading(false);
		}
	}, [entityNames]);

	return loading ? (
		<Loader active />
	) : (
		<Segment style={{ margin: 15 }}>
			<AssessmentLog isTrial={true} isField={false} name={trialName} />
		</Segment>
	);
};

export default TrialAssessmentLog;
