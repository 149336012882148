import PropTypes from "prop-types";
import React from "react";
import ParcelPreviewWrapper from "./ParcelPreviewWrapper";
import "./viewer.css";

const ParcelPreviewViewer = ({ parcelList }) => {
	return (
		<div className="viewerContainer">
			<ParcelPreviewWrapper parcelList={parcelList} />
		</div>
	);
};

ParcelPreviewViewer.propTypes = {
	parcelList: PropTypes.object
};

export default ParcelPreviewViewer;
