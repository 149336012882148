const mapboxAccessToken = process.env.MAPBOX_ACCESS_TOKEN;
const mapboxSearchBoxUrl = process.env.MAPBOX_SEARCH_BOX_URL;
const mapboxGeocodeUrl = process.env.MAPBOX_GEOCODING_URL;

export function getSearchSuggestions(query, userId, options = {}) {
	return fetch(`
		${mapboxSearchBoxUrl}/suggest
		?q=${query}
		&access_token=${mapboxAccessToken}
		&session_token=${userId}
		&language=en
		${options.proximity ? `&proximity=${options.proximity}` : ""}
		${options.types ? `&types=${options.types}` : ""}
	`)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function retrieveFromSearchSuggestion(suggestionId, userId) {
	return fetch(`
		${mapboxSearchBoxUrl}/retrieve/${suggestionId}
		?access_token=${mapboxAccessToken}
		&session_token=${userId}
	`)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getForwardGeocode(query, options) {
	return fetch(`
		${mapboxGeocodeUrl}/forward
		?q=${query}
		&access_token=${mapboxAccessToken}
		&country=us
		&language=en
		${options.proximity ? `&proximity=${options.proximity}` : ""}
		${options.types ? `&types=${options.types}` : ""}
	`)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}
