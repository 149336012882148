import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { Button, Form, Grid, Loader, Segment, Select } from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as flightActions from "../../../../redux/actions/flightActions";
import * as growingSeasonActions from "../../../../redux/actions/growingSeasonActions";

import "./styles.css";
import FlightLogResults from "./FlightLogResults";
import { DateTimePicker } from "react-widgets/cjs";

/**
 * @typedef { import("../../../../apis/types/flightTypes").FlightLogData } FlightLogData
 */

const FlightLog = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	const DATE_FORMAT = "MM/DD/YYYY";

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [flightLogDataOptions, setFlightLogDataOptions] = useState(null);
	const uploadingOrthos = useSelector((state) => state.orthoUploads);
	const [growingSeasons, setGrowingSeasons] = useState([]);

	//-- Dropdown options
	const [uasOptions, setUasOptions] = useState([]);
	const [operatorOptions, setOperatorOptions] = useState([]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [farmOptions, setFarmOptions] = useState([]);
	const [cooperatorCompanyOptions, setCooperatorCompanyOptions] = useState([]);
	const analysisStatusOptions = [
		{
			key: "all",
			value: "all",
			text: "All"
		},
		{
			key: "Incomplete",
			value: "Incomplete",
			text: "Incomplete"
		},
		{
			key: "Complete",
			value: "Complete",
			text: "Complete"
		}
	];
	const presetTimeOptions = [
		{
			key: "thisYear",
			value: "thisYear",
			text: "This Year"
		},
		{
			key: "thisMonth",
			value: "thisMonth",
			text: "This Month"
		},
		{
			key: "thisWeek",
			value: "thisWeek",
			text: "This Week"
		},
		{
			key: "lastYear",
			value: "lastYear",
			text: "Last Year"
		},
		{
			key: "lastMonth",
			value: "lastMonth",
			text: "Last Month"
		},
		{
			key: "lastWeek",
			value: "lastWeek",
			text: "Last Week"
		},
		{
			key: "custom",
			value: "custom",
			text: "Custom"
		},
		{
			key: "allDates",
			value: "allDates",
			text: "All Dates"
		}
	];
	const cloudCoverageTypes = [
		{
			key: "all",
			value: "all",
			text: "All"
		},
		{
			key: "Clear",
			value: "Clear",
			text: "Clear"
		},
		{
			key: "Scattered",
			value: "Scattered",
			text: "Scattered"
		},
		{
			key: "Broken",
			value: "Broken",
			text: "Broken"
		},
		{
			key: "Overcast",
			value: "Overcast",
			text: "Overcast"
		}
	];

	const uploadedAndAnalyzedStatusOptions = [
		{
			key: "all",
			value: "all",
			text: "All"
		},
		{
			key: "uploaded",
			value: "uploaded",
			text: "Uploaded and Not Analyzed"
		},
		{
			key: "analyzed",
			value: "analyzed",
			text: "Uploaded and Analyzed"
		}
	];

	//-- Selected filter options
	//-- When setting the default time filter to this year or last year, update these dates to getFullYear() (this year) or getFullYear() - 1
	//-- Also update the presetTimeId to either ("thisYear") or ("lastYear")
	//-- e.g.
	// const [dateRange, setDateRange] = useState([
	// 	new Date(new Date().getFullYear() - 1, 0, 1),
	// 	new Date(new Date().getFullYear() - 1, 11, 31)
	// ]);
	const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [oldFromDate, setOldFromDate] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [toDate, setToDate] = useState(new Date(new Date().getFullYear() - 1, 11, 31));
	const [oldToDate, setOldToDate] = useState(new Date(new Date().getFullYear() - 1, 11, 31));

	const [flightName, setFlightName] = useState("");
	//const [flightPlan, setFlightPlan] = useState("");
	const [uasId, setUasId] = useState("all");
	const [operatorId, setOperatorId] = useState("all");
	const [growingSeasonId, setGrowingSeasonId] = useState(null);
	const [farmId, setFarm] = useState("all");
	const [analysisId, setAnalysis] = useState("all");
	const [cooperatorCompanyId, setCooperatorCompanyId] = useState("all");
	const [presetTimeId, setPresetTimeId] = useState("lastYear");
	const [searchAllClients, setSearchAllClients] = useState(false);
	const [hasSearchedAllClients, setHasSearchedAllClients] = useState(false);
	const [uploadedAndAnalyzedStatusId, setUploadedAndAnalyzedStatusId] = useState("all");
	const [cloudCoverage, setCloudCoverage] = useState("all");

	//-- Search results
	const [flightLogSearchResults, setFlightLogSearchResults] = useState(null);
	/** @type {[FlightLogData, React.Dispatch<React.SetStateAction<FlightLogData>>]} */
	const [filteredFlightLogs, setFilteredFlightLogs] = useState([]);

	//-- CSV
	const [flightLogFileName, setFlightLogFileName] = useState("FlightLog.csv");
	const [flightLogCsvData, setFlightLogCsvData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [loadingSearchResults, setLoadingSearchResults] = useState(true);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			if (!flightLogDataOptions) {
				setLoading(true);
				getFlightLogOptionData(clientId, searchAllClients);
				getGrowingSeasons();
			} else {
				setLoadingSearchResults(true);
				setDropdownOptions();
				getFlightLog(clientId);
				setLoading(false);
			}
		}
	}, [userAuth.isReady, clientId, flightLogDataOptions]);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			getFlightLogOptionData(clientId, searchAllClients);
		}
	}, [userAuth.currentClientId]);

	useEffect(() => {
		if (flightLogSearchResults) {
			setLoadingSearchResults(true);
			handleFilter();
			setLoadingSearchResults(false);
			setFlightLogCsvInfo();
		}
	}, [flightLogSearchResults]);

	//-- Update the filter/dropdown options based on if the user is searching all clients or not
	useEffect(() => {
		if (flightLogSearchResults) {
			setDropdownOptions();
		}
	}, [searchAllClients]);

	async function getFlightLogOptionData(clientId, searchAllClients) {
		const accessToken = await getTokenSilently();
		setLoadingSearchResults(true);
		dispatch(flightActions.getFlightLogDataOptions(clientId, searchAllClients, accessToken))
			.then((res) => {
				setFlightLogDataOptions(res);
				//-- Don't set loading to false here because we are going to search after we get the options, so let that function set loading to false
			})
			.catch((err) => {
				toast.error("Error loading Flight Log. Please try again.");
				setLoading(false);
				console.log(err);
			});
	}

	const getGrowingSeasons = async () => {
		const accessToken = await getTokenSilently();
		dispatch(growingSeasonActions.getGrowingSeasonDetails(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setGrowingSeasons(res.data);
				} else {
					toast.error("Unable to load Growing Season data. Please try again.");
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load Growing Season data. Please try again.");
			});
	};

	async function getFlightLog(clientId) {
		if (clientId && toDate && fromDate) {
			setLoadingSearchResults(true);
			const accessToken = await getTokenSilently();
			dispatch(
				flightActions.getFlightLog(
					fromDate.toDateString(DATE_FORMAT),
					toDate.toDateString(DATE_FORMAT),
					clientId,
					searchAllClients,
					accessToken
				)
			)
				.then((res) => {
					setFlightLogsState(res);
					setLoading(false);
					setLoadingSearchResults(false);
				})
				.catch((err) => {
					toast.error("Error searching for flight logs. Please try again.");
					setLoading(false);
					setLoadingSearchResults(false);
					console.log(err);
				});
		}
	}

	function setDropdownOptions() {
		setInitialUasOptions(flightLogDataOptions.uases);
		setInitialFarmOptions(flightLogDataOptions.farms);
		setInitialGrowingSeasonOptions(flightLogDataOptions.growingSeasons);
		setInitialOperatorOptions(flightLogDataOptions.operators);
		setCooperatorCompanyOptionsState(
			_.filter(flightLogDataOptions.companies, (c) => {
				return c.isCooperator;
			})
		);
	}

	function setInitialUasOptions(uasData) {
		uasData = _.filter(uasData, (ud) => {
			if (searchAllClients === true || (searchAllClients === false && ud.clientId === clientId)) {
				return ud;
			}
		});
		setUasOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(uasData, (uas) => {
					const opt = {
						key: uas.uasId,
						value: uas.uasId,
						text: uas.name,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{uas.name}</span>
								{/* <span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({uas.clientName})</span> */}
							</div>
						)
					};
					return opt;
				})
			)
		);
		if (
			uasId &&
			!_.some(uasData, (ud) => {
				return ud.uasId === uasId;
			})
		) {
			setUasId("all");
		}
	}

	function setInitialFarmOptions(farmData) {
		farmData = _.filter(farmData, (f) => {
			if (searchAllClients === true || (searchAllClients === false && f.clientId === clientId)) {
				return f;
			}
		});

		setFarmOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(farmData, (farm) => {
					const opt = {
						key: farm.farmId,
						value: farm.farmId,
						text: farm.name,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{farm.name}</span>
								<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({farm.clientName})</span>
							</div>
						)
					};
					return opt;
				})
			)
		);

		if (
			farmId &&
			!_.some(farmData, (f) => {
				return f.farmId === farmId;
			})
		) {
			setFarm("all");
		}
	}

	function setInitialGrowingSeasonOptions(growingSeasonData) {
		let tempGsOptions = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(growingSeasonData, (growingSeason) => {
				const opt = {
					key: growingSeason.growingSeasonId,
					value: growingSeason.growingSeasonId,
					text: growingSeason.name
				};
				return opt;
			})
		);
		setGrowingSeasonOptions(tempGsOptions);

		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(tempGsOptions, (gs) => gs.text.includes(currentGrowingSeason))?.value ?? tempGsOptions[0]?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);
	}

	function setInitialOperatorOptions(operatorData) {
		setOperatorOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(operatorData, (operator) => {
					const opt = {
						key: operator.personId,
						value: operator.personId,
						text: operator.firstName + " " + operator.lastName,
						content: (
							<div style={{ display: "flex", flexDirection: "column" }}>
								<span style={{ fontSize: 14, color: "#000000" }}>{operator.firstName + " " + operator.lastName}</span>
								{/* <span
									title={operator.emailAddress ? `(${operator.emailAddress})` : null}
									style={{ fontSize: 12, opacity: 0.75, overflow: "hidden", textOverflow: "ellipsis" }}
								>
									{operator.emailAddress ? `(${operator.emailAddress})` : null}
								</span> */}
							</div>
						)
					};
					return opt;
				})
			)
		);
		if (
			operatorId &&
			!_.some(operatorData, (od) => {
				return od.personId === operatorId;
			})
		) {
			setOperatorId("all");
		}
	}

	function setCooperatorCompanyOptionsState(companies) {
		companies = _.filter(companies, (c) => {
			if (searchAllClients === true || (searchAllClients === false && c.clientId === clientId)) {
				return c;
			}
		});
		setCooperatorCompanyOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(companies, ({ id, name }) => {
					const opt = {
						key: id,
						value: id,
						text: name,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
								{/* <span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span> */}
							</div>
						)
					};
					return opt;
				})
			)
		);

		if (
			cooperatorCompanyOptions &&
			!_.some(cooperatorCompanyOptions, (c) => {
				return c.id === cooperatorCompanyId;
			})
		) {
			setCooperatorCompanyId("all");
		}
	}

	function setFlightLogsState(searchResults) {
		if (searchResults) {
			let flights = searchResults.flights;
			_.map(flights, (flight) => {
				//Format dateAndTime
				flight.dateAndTime = moment(flight.dateAndTime).format(DATE_FORMAT + " h:mma");

				flight.farms = _.filter(searchResults.farms, ["flightId", flight.flightId]);

				flight.cooperators = _.map(
					_.uniqBy(_.filter(searchResults.farms, ["flightId", flight.flightId]), (f) => {
						return f.cooperatorCompanyId;
					}),
					(c) => {
						return { cooperatorCompanyId: c.cooperatorCompanyId, cooperatorName: c.cooperatorName };
					}
				);

				flight.growingSeasons = _.filter(searchResults.growingSeasons, ["flightId", flight.flightId]);

				flight.orthoImages = _.filter(searchResults.orthoImages, ["flightId", flight.flightId]);

				flight.flightParameters = { altitude: flight.altitude, droneSpeed: flight.droneSpeed };
				flight.flightConditions = {
					inFlightPauses: flight.inFlightPauses,
					windSpeed: flight.windSpeed,
					cloudCoverage: flight.cloudCoverage
				};
				flight.fieldsPlaced = { count: flight.fields, total: flight.totalFields };
				flight.plotAnalysis = _.every(flight.orthoImages, ["plotAnalysis", true]) ? "Complete" : "Incomplete";

				//search for images being uploaded in redux
				_.map(flight.orthoImages, (ortho) => {
					if (
						uploadingOrthos.some(
							(x) =>
								x.flightId === ortho.flightId &&
								x.sensorId === ortho.sensorId &&
								x.orthoImageTypeId === ortho.orthoImageTypeId
						)
					) {
						ortho.isUploading = true;
					} else {
						ortho.isUploading = false;
					}
				});
			});

			setFlightLogSearchResults(flights);
		}
	}

	const handleFilter = () => {
		if (flightLogSearchResults) {
			if (clientId) {
				var mFlightDateFrom = moment(fromDate);
				var mPrevFlightDateFrom = moment(oldFromDate);
				var mFlightDateTo = moment(toDate);
				var mPrevFlightDateTo = moment(oldToDate);
				if (searchAllClients === true && hasSearchedAllClients === false) {
					getFlightLogOptionData(clientId, searchAllClients);
					setHasSearchedAllClients(true);
				} else if (!mFlightDateFrom.isSame(mPrevFlightDateFrom) || !mFlightDateTo.isSame(mPrevFlightDateTo)) {
					if (searchAllClients === false) {
						setHasSearchedAllClients(false);
					}
					getFlightLog(clientId);
				}

				setOldFromDate(fromDate);
				setOldToDate(toDate);
			}

			let currentGs = getCurrentGrowingSeason();
			let currentGsSelection = _.find(growingSeasonOptions, (gs) => gs.text.includes(currentGs))?.value;
			const filteredList = flightLogSearchResults.filter((fLog) => {
				const included =
					//-- Flight Name
					(flightName === "" || fLog.name.match(new RegExp(flightName, "i"))) &&
					//-- Flight Plan
					//(flightPlan === "" || fLog.plan.match(new RegExp(flightPlan, "i"))) &&
					//-- Client
					(searchAllClients === true || (searchAllClients === false && fLog.clientId === clientId)) &&
					//-- UAS
					(uasId === "all" || fLog.uasId === uasId) &&
					//-- Operator
					(operatorId === "all" || fLog.operatorId === operatorId) &&
					//-- Growing Season
					(growingSeasonId === "all" ||
						_.some(fLog.growingSeasons, ["growingSeasonId", growingSeasonId]) ||
						(growingSeasonId === null && _.some(fLog.growingSeasons, ["growingSeasonId", currentGsSelection]))) &&
					//-- Cloud Coverage
					(cloudCoverage === "all" || (userAuth.isApAdmin && fLog.cloudCoverage === cloudCoverage)) &&
					//-- Farm
					(farmId === "all" || _.some(fLog.farms, ["farmId", farmId])) &&
					//-- Cooperator
					(cooperatorCompanyId === "all" || _.some(fLog.cooperators, ["cooperatorCompanyId", cooperatorCompanyId])) &&
					//-- Analysis Status
					(analysisId === "all" ||
						_.every(fLog.orthoImages, ["plotAnalysis", analysisId === "Complete" ? true : false])) &&
					//-- Uploaded and Analyzed Status
					(uploadedAndAnalyzedStatusId === "all" ||
						_.some(fLog.orthoImages, (o) => {
							return (
								o.uploaded === true && o.plotAnalysis === (uploadedAndAnalyzedStatusId === "analyzed" ? true : false)
							);
						}));
				return included;
			});
			setFilteredFlightLogs(filteredList);
		}
	};

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	const resetFilter = () => {
		setFromDate(new Date(new Date().getFullYear() - 1, 0, 1));
		setToDate(new Date(new Date().getFullYear() - 1, 11, 31));
		setPresetTimeId("lastYear");
		setFlightName("");
		//setFlightPlan("");
		setUasId("all");
		setOperatorId("all");
		setFarm("all");
		setAnalysis("all");
		setCooperatorCompanyId("all");
		setCloudCoverage("all");
		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection = _.find(growingSeasonOptions, (gs) =>
			gs.text.includes(currentGrowingSeason)
		)?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);
	};

	useEffect(() => {
		if (presetTimeId) {
			if (presetTimeId !== "custom") {
				setOldFromDate(fromDate);
				setOldToDate(toDate);
			}

			if (presetTimeId === "thisYear") {
				setFromDate(new Date(new Date().getFullYear(), 0, 1));
				setToDate(new Date(new Date().getFullYear(), 11, 31));
			} else if (presetTimeId === "thisMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

				setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
				setToDate(new Date(new Date().getFullYear(), new Date().getMonth(), lastDayOfMonth));
			} else if (presetTimeId === "thisWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 1));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff + 5));

				setFromDate(beginningOfWeek);
				setToDate(endOfWeek);
			} else if (presetTimeId === "lastYear") {
				setFromDate(new Date(new Date().getFullYear() - 1, 0, 1));
				setToDate(new Date(new Date().getFullYear() - 1, 11, 31));
			} else if (presetTimeId === "lastMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
				let year = new Date().getFullYear();
				if (new Date().getMonth() === 1) {
					year = new Date().getFullYear() - 1;
				}

				setFromDate(new Date(year, new Date().getMonth() - 1, 1));
				setToDate(new Date(year, new Date().getMonth() - 1, lastDayOfMonth));
			} else if (presetTimeId === "lastWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 8));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff - 2));

				setFromDate(beginningOfWeek);
				setToDate(endOfWeek);
			} else if (presetTimeId === "allDates") {
				setFromDate(new Date(2001, 0, 1));
				setToDate(new Date(2099, 0, 1));
				setGrowingSeasonId("all");
			}
		}
	}, [presetTimeId]);

	function setFlightLogCsvInfo() {
		if (flightLogSearchResults && flightLogSearchResults.length > 0) {
			setFlightLogFileName(`FlightLog_${moment().format("YYYYMMDD_hhmma")}.csv`);
			const csvData = _.map(flightLogSearchResults, (r) => {
				let data = {
					Flight: r.name,
					Client: r.clientName,
					Cooperators: _.join(
						_.map(r.cooperators, (c) => {
							return c.cooperatorName;
						}),
						["; "]
					),
					"Flight Date": r.dateAndTime,
					"Flight Plan": r.plan,
					Operator: r.operator,
					Altitude: r.altitude,
					Farms: _.join(
						_.map(r.farms, (f) => {
							return f.name;
						}),
						["; "]
					),
					Fields: r.fields,
					UAS: r.uas,
					"Growing Seasons": _.join(
						_.map(r.growingSeasons, (gs) => {
							return gs.name;
						}),
						["; "]
					),
					"Image Upload": _.join(
						_.map(
							_.filter(r.orthoImages, (oi) => {
								return oi.isUploading === true || oi.uploaded;
							}),
							(oi) => {
								return oi.abbreviation;
							}
						),
						["; "]
					),
					"Plot Analysis": _.every(r.orthoImages, ["plotAnalysis", true]) ? "Complete" : "Incomplete",
					"Drone Speed": userAuth.isApAdmin ? r.droneSpeed : null,
					"Wind Speed": userAuth.isApAdmin ? r.windSpeed : null,
					"Cloud Coverage": userAuth.isApAdmin ? r.cloudCoverage : null,
					"In Flight Pauses": userAuth.isApAdmin
						? r.inFlightPauses === true
							? "Yes"
							: r.inFlightPauses === false
							? "No"
							: null
						: null,
					"Upload Failed": userAuth.isApAdmin && _.some(r.orthoImages, (oi) => oi.uploadFailed) ? "Yes" : null,
					"Mapbox Upload Failed":
						userAuth.isApAdmin && _.some(r.orthoImages, (oi) => oi.mapboxUploadFailed) ? "Yes" : null
				};
				return data;
			});
			setFlightLogCsvData(csvData);
		}
	}

	return loading ? (
		<Loader active />
	) : !loading && (!flightLogDataOptions || !uasOptions || (uasOptions && uasOptions.length === 0)) ? (
		<Segment basic style={{ width: "100%", margin: 15 }}>
			<Segment>
				<p>{"Flight Log could not be loaded. Please try again. If the problem persists, please contact support."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment basic style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Form onSubmit={handleFilter}>
				<Grid id="flightLogGrid" verticalAlign="middle">
					<Form.Field
						id="form-date-time-picker-from"
						control={DateTimePicker}
						label="Flight Dates From"
						includeTime={false}
						value={fromDate}
						required
						error={!fromDate}
						disabled={loadingSearchResults}
						onChange={(date) => {
							setPresetTimeId("custom");
							if (date) {
								date.setHours(0, 0, 0, 0);
								setFromDate(date);
							} else {
								setFromDate(null);
							}
							setGrowingSeasonId("all");
						}}
					/>
					<Form.Field
						id="form-date-time-picker-to"
						control={DateTimePicker}
						label="Flight Dates To"
						includeTime={false}
						value={toDate}
						required
						error={!toDate}
						disabled={loadingSearchResults}
						onChange={(date) => {
							setPresetTimeId("custom");
							if (date) {
								date.setHours(0, 0, 0, 0);
								setToDate(date);
							} else {
								setToDate(null);
							}
							setGrowingSeasonId("all");
						}}
					/>
					<Form.Field
						id="form-select-preset-times"
						label="Date Range"
						style={{ flexGrow: 1 }}
						control={Select}
						placeholder="Date Range"
						options={presetTimeOptions}
						value={presetTimeId}
						fluid
						disabled={loadingSearchResults}
						onChange={(event, { value }) => {
							setPresetTimeId(value);
							setGrowingSeasonId("all");
						}}
					/>

					<Form.Input
						id="form-input-flight-name"
						label="Flight Name"
						type="text"
						value={flightName}
						fluid
						disabled={loadingSearchResults}
						onChange={(event) => setFlightName(event.target.value)}
					/>

					{/* <Form.Input
						id="form-input-flight-plan"
						label="Flight Plan"
						type="text"
						value={flightPlan}
						fluid
						disabled={loadingSearchResults}
						onChange={(event) => setFlightPlan(event.target.value)}
					/> */}

					<Form.Field
						id="form-select-uas"
						label="UAS"
						control={Select}
						placeholder="UAS"
						search
						disabled={loadingSearchResults}
						options={uasOptions}
						value={uasId}
						fluid
						onChange={(event, { value }) => {
							setUasId(value);
						}}
					/>

					<Form.Field
						id="form-select-operator"
						label="Operator"
						control={Select}
						placeholder="Operator"
						search
						disabled={loadingSearchResults}
						options={operatorOptions}
						value={operatorId}
						fluid
						onChange={(event, { value }) => {
							setOperatorId(value);
						}}
					/>

					<Form.Field
						id="form-select-growing-season"
						label="Growing Seasons"
						control={Select}
						placeholder="Growing Season"
						search
						disabled={loadingSearchResults}
						options={growingSeasonOptions}
						value={growingSeasonId}
						fluid
						onChange={(event, { value }) => {
							setGrowingSeasonId(value);
							if (value === "all") {
								setFromDate(new Date(2001, 0, 1));
								setToDate(new Date(2099, 0, 1));
							} else {
								let gsMatch = _.filter(growingSeasons, (gs) => {
									return gs.id === value;
								});
								setFromDate(new Date(gsMatch[0].startYear, 0, 1));
								setToDate(new Date(gsMatch[0].endYear, 11, 31));
							}
							setPresetTimeId("custom");
						}}
					/>
					<Form.Field
						id="form-select-farm"
						label="Farms"
						control={Select}
						placeholder="Farm"
						search
						disabled={loadingSearchResults}
						options={farmOptions}
						value={farmId}
						fluid
						onChange={(event, { value }) => {
							setFarm(value);
						}}
					/>

					<Form.Field
						id="form-select-status"
						label="Analysis Status"
						control={Select}
						placeholder="Status"
						options={analysisStatusOptions}
						disabled={loadingSearchResults}
						value={analysisId}
						fluid
						onChange={(event, { value }) => {
							setAnalysis(value);
						}}
					/>

					<Form.Field
						id="form-select-uploaded-analyzed-status"
						label="Uploaded & Analyzed Status"
						control={Select}
						placeholder="Status"
						options={uploadedAndAnalyzedStatusOptions}
						disabled={loadingSearchResults}
						value={uploadedAndAnalyzedStatusId}
						fluid
						onChange={(event, { value }) => {
							setUploadedAndAnalyzedStatusId(value);
						}}
					/>

					<Form.Select
						id="form-select-cooperator-company"
						label="Cooperator"
						options={cooperatorCompanyOptions}
						value={cooperatorCompanyId}
						search
						disabled={loadingSearchResults}
						onChange={(event, { value }) => {
							setCooperatorCompanyId(value);
						}}
						loading={!cooperatorCompanyOptions || cooperatorCompanyOptions.length === 0}
					/>

					{userAuth.isApAdmin && (
						<Form.Select
							id="form-select-cloud-coverage"
							label="Cloud Coverage"
							placeholder="Cloud Coverage"
							search
							disabled={loadingSearchResults}
							options={cloudCoverageTypes}
							value={cloudCoverage}
							onChange={(event, { value }) => {
								setCloudCoverage(value);
							}}
						/>
					)}

					<Form.Checkbox
						id="form-search-all-clients"
						label="Search All Clients"
						checked={searchAllClients}
						disabled={loadingSearchResults}
						onChange={(e, { checked }) => {
							setSearchAllClients(checked);
						}}
					/>

					<div className="buttons">
						<Button
							id="form-button-clear-filter"
							negative
							disabled={loadingSearchResults}
							content="Reset"
							onClick={resetFilter}
						/>
						<Button id="form-button-filter" primary disabled={loadingSearchResults} content="Filter" />
					</div>
				</Grid>
			</Form>

			<Segment basic style={{ margin: 0, padding: 0 }}>
				{flightLogCsvData && flightLogCsvData.length > 0 && (
					<span style={{ float: "right", textAlign: "right" }}>
						{flightLogCsvData && flightLogCsvData.length > 0 && (
							<CSVLink filename={flightLogFileName} data={flightLogCsvData} enclosingCharacter={""}>
								Download
							</CSVLink>
						)}
					</span>
				)}
			</Segment>
			{/* <FlightSearchResults data={filteredFlightLogs} setData={setFilteredFlightLogs} loading={loading} /> */}
			{loadingSearchResults ? (
				<Loader active inline="centered" />
			) : (
				<FlightLogResults flightLogs={filteredFlightLogs} userAuth={userAuth} />
			)}
		</Segment>
	);
};

export default FlightLog;
