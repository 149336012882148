import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { Segment, Table, Pagination, Dropdown } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const FlightsForTrialSponsorTable = ({ trialFlights }) => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "M/D/YYYY";

	//-- Data source
	const [dataSource, setDataSource] = useState(trialFlights);

	//-- Paging
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	//-- Sorting
	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- Initial view load
	useEffect(() => {
		if (trialFlights) {
			setTotalPages(Math.ceil(trialFlights.length / itemsPerPage));
			setDataSource(_.orderBy(trialFlights, ["flightDate"]));
			setTableData(_.orderBy(dataSource, ["flightDate"]));
		}
	}, [trialFlights]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return new moment(d[clickedColumn]).format();
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total|treatmentId/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	};

	// prettier-ignore
	return (
		<Segment basic style={{ paddingLeft: "unset" }}>
			<Table celled striped sortable selectable color="blue" textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={sortColumn === "uasName" ? sortDirection : null}
							onClick={() => handleSort("uasName")}
						>
							UAS
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'flightDate' ? sortDirection : null}
							onClick={() => handleSort('flightDate')}>Flight</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'publishedToTrialSponsorDate' ? sortDirection : null}
							onClick={() => handleSort('publishedToTrialSponsorDate')}>Published</Table.HeaderCell>
						<Table.HeaderCell>Published By</Table.HeaderCell>
						<Table.HeaderCell>Published To</Table.HeaderCell>
						<Table.HeaderCell>Publishing</Table.HeaderCell>
						{/* {(dataSource.length > 0 && dataSource.filter(ds => ds.publishedToTrialSponsorClientId === userAuth.currentClientId || ds.publishedToTrialSponsorUserId === userAuth.user.userInfo.basic.userId).length > 0) && <Table.HeaderCell>Actions</Table.HeaderCell>} */}
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{
						_.map(tableData, ({ flightId, flightName, isPublishedToTrialSponsor, flightDate, publishedToTrialSponsorDate, publishedToTrialOwnerFirstName, publishedToTrialOwnerEmailAddress, publishedToTrialOwnerLastName, publishedToTrialOwnerClientName, publishedToTrialSponsorFirstName, publishedToTrialSponsorLastName, publishedToTrialSponsorEmailAddress, isPending, uasName, doNotPublish }, index) => {
							return (
								<Table.Row key={index}>
									<Table.Cell>{uasName}</Table.Cell>
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={
											<>
												Flight Date:{" "}
												<span style={{ opacity: 0.75 }}>{moment(flightDate).local().format(DATE_FORMAT)}</span>
											</>
										}
										transitionName="rc-tooltip-zoom"
									>
										<Table.Cell>
											{userAuth.isApAdmin ||
											userAuth.hasApplicationArea(ApplicationArea.FlightViewer, userAuth.currentClientId) ? (
												<Link to={moduleNavigation.createFlightLink(false, flightId)} target="_blank">
													{flightName}
												</Link>
											) : (
												<>{flightName}</>
											)}
										</Table.Cell>
									</Tooltip>
									{isPublishedToTrialSponsor ? (
										<Table.Cell>{moment(publishedToTrialSponsorDate).local().format(DATE_FORMAT)}</Table.Cell>
									) : (
										<Table.Cell>-</Table.Cell>
									)}
									{isPublishedToTrialSponsor ? (
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<>
													Publisher:{" "}
													<span style={{ opacity: 0.75 }}>
														{publishedToTrialOwnerFirstName} {publishedToTrialOwnerLastName}
													</span>
												</>
											}
											transitionName="rc-tooltip-zoom"
										>
											<Table.Cell>
												{publishedToTrialOwnerClientName ? (
													<a href={`mailto:${publishedToTrialOwnerEmailAddress}`}>{publishedToTrialOwnerClientName}</a>
												) : (
													<a href={`mailto:${publishedToTrialOwnerEmailAddress}`}>
														{publishedToTrialOwnerFirstName} {publishedToTrialOwnerLastName}
													</a>
												)}
											</Table.Cell>
										</Tooltip>
									) : (
										<Table.Cell>-</Table.Cell>
									)}
									{isPublishedToTrialSponsor ? (
										<Table.Cell>
											{
												<a href={`mailto:${publishedToTrialSponsorEmailAddress}`}>
													{publishedToTrialSponsorFirstName} {publishedToTrialSponsorLastName}
												</a>
											}
											{isPending ? "(Pending)" : null}
										</Table.Cell>
									) : (
										<Table.Cell>-</Table.Cell>
									)}
									<Table.Cell>
										{doNotPublish ? "Do Not Publish" : isPublishedToTrialSponsor ? "Published" : " Not Published"}
									</Table.Cell>
									{/* <Table.Cell textAlign="center">
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={<p>Edit</p>}
											transitionName="rc-tooltip-zoom"
										>
											<Link
												to={moduleNavigation.createTrialLink(
													false,
													trialId,
													"edit",
													userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)
														? fieldId
														: null
												)}
												target="_blank"
											>
												<Icon.Group>
													<Icon name="leaf" corner="top left" style={{ fontSize: 11, textShadow: "unset" }} />
													<Icon name="pencil" corner="bottom right" style={{ fontSize: 6 }} />
												</Icon.Group>
											</Link>
										</Tooltip>
										<span style={{ display: "inline-block", width: 7 }} />
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={<p>Heatmap</p>}
											transitionName="rc-tooltip-zoom"
										>
											<Link
												to={moduleNavigation.createTrialLink(
													false,
													trialId,
													"heatmap",
													userAuth.hasApplicationArea(ApplicationArea.TrialHeatMap, userAuth.currentClientId)
														? fieldId
														: null
												)}
												target="_blank"
											>
												<Icon name="map" />
											</Link>
										</Tooltip>
										<span style={{ display: "inline-block", width: 5 }} />
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={<p>Analysis</p>}
											transitionName="rc-tooltip-zoom"
										>
											<Link
												to={moduleNavigation.createTrialLink(
													false,
													trialId,
													"analysis",
													userAuth.hasApplicationArea(ApplicationArea.TrialAnalysis, userAuth.currentClientId)
														? fieldId
														: null
												)}
												target="_blank"
											>
												<Icon name="chart bar outline" />
											</Link>
										</Tooltip>
										<span style={{ display: "inline-block", width: 5 }} />
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={<p>Outline</p>}
											transitionName="rc-tooltip-zoom"
										>
											<Link
												to={moduleNavigation.createTrialLink(
													false,
													trialId,
													"outline",
													userAuth.hasApplicationArea(ApplicationArea.TrialOutline, userAuth.currentClientId)
														? fieldId
														: null
												)}
												target="_blank"
											>
												<Icon name="table" />
											</Link>
										</Tooltip>
									</Table.Cell> */}
								</Table.Row>
							);
						})
					}
				</Table.Body>

				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="13">
							<span style={{ marginRight: 10 }}>{dataSource.length === 1 ? `${dataSource.length} flight` : `${dataSource.length} flights`}</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 10, value: 10 },
									{ key: 2, text: 15, value: 15 },
									{ key: 3, text: 25, value: 25 },
									{ key: 4, text: 50, value: 50 },
									{ key: 5, text: 100, value: 100 },
									{ key: 6, text: 1000, value: 1000 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>

			</Table>
		</Segment>)
};

FlightsForTrialSponsorTable.propTypes = {
	trialFlights: PropTypes.array.isRequired
};

export default FlightsForTrialSponsorTable;
