import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { Grid, Icon, Loader, Segment, Table } from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as protocolActions from "../../../../redux/actions/protocolActions";
import * as orthoViewerActions from "../../../../redux/actions/orthoViewerActions";
import Ortho from "./OverviewMapboxComponent";

import TrialMetaFactorsModal from "../../Trials/TrialMetaTags/TrialMetaFactorsModal.tsx";

const ProtocolOverview = () => {
	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	//-- Data Sources
	const [protocolData, setProtocolData] = useState(null);
	const [protocolTrials, setProtocolTrials] = useState([]);
	const [protocolApplications, setProtocolApplications] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [failedToLoad, setFailedToLoad] = useState(false);
	const [metaFactorModalOpen, setMetaFactorsModalOpen] = useState(false);
	const [modalTrialId, setModalTrialId] = useState("");
	const [modalTrialName, setModalTrialName] = useState("");

	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));

	useEffect(() => {
		if (userAuth.isReady) {
			if (
				(reduxProtocolData?.id === null && moduleNavigation.protocolId !== null) ||
				reduxProtocolData?.id !== moduleNavigation.protocolId ||
				reduxProtocolData?.owner === null
			) {
				getProtocolData();
			}

			if (protocolTrials.length === 0 || protocolApplications.length === 0) {
				getProtocolOverviewInfo();
			}
		}
	}, [userAuth.isReady]);

	async function getProtocolData() {
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocolData(moduleNavigation.protocolId, userAuth.currentClientId, accessToken)).catch(
			() => {
				setFailedToLoad(true);
				setLoading(false);
			}
		);
	}

	async function getProtocolOverviewInfo() {
		const accessToken = await getTokenSilently();
		dispatch(
			protocolActions.getProtocolOverviewInfo(moduleNavigation.protocolId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				let sortedTrials = _.sortBy(res.trials, ["state", "city"]);

				setProtocolTrials(sortedTrials);

				let applications = _.map(res.applications, (a) => {
					if (a.groundDataTimingMethodType) {
						if (a.groundDataTimingMethodType === "Date") {
							a.plannedTiming = moment(new Date(a.growthPhaseDateUtc)).local().format("MM/DD/YYYY");
						} else if (a.groundDataTimingMethodType === "Text") {
							a.plannedTiming = a.growthPhaseText;
						} else if (a.groundDataTimingMethodType === "StartEnd") {
							a.plannedTiming = `${a.growthStageStart} - ${a.growthStageEnd}`;
						} else if (a.groundDataTimingMethodType === "int") {
							a.plannedTiming = `${a.growthPhaseInteger} ${a.groundDataTimingName}`;
						} else if (a.groundDataTimingMethodType === "None") {
							a.plannedTiming = a.growthPhaseName ?? "TBD";
						} else if (a.groundDataTimingMethodType === "Start") {
							a.plannedTiming = a.growthStageStart;
						} else {
							a.plannedTiming = a.growthStage ?? "TBD";
						}
					} else {
						a.plannedTiming = null;
					}

					return a;
				});

				setProtocolApplications(applications);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	useEffect(() => {
		if (reduxProtocolData) {
			setProtocolData(reduxProtocolData);
		}
	}, [reduxProtocolData]);

	const toggleMetaFactorModal = (trialId, trialName, open = true) => {
		if (trialId) {
			setModalTrialId(trialId);
			setModalTrialName(trialName);
			setMetaFactorsModalOpen(open);
		}
	};

	return failedToLoad ? (
		<Segment style={{ margin: 15 }}>
			<h2>{"Failed to load protocol data. Please try again"}</h2>
		</Segment>
	) : loading ? (
		<Loader active />
	) : (
		<>
			<Segment id="protocol-overview" style={{ margin: 15, paddingBottom: "unset" }}>
				<Grid>
					<Grid.Row>
						<Grid.Column width={6} style={{ fontSize: "1.2rem" }}>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										ID
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.protocolId ?? ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Name
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.protocolName ?? ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Owner
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.owner ?? ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Crop
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.cropName ?? ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Growing Season
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.growingSeasonName ?? ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Plot Size
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.plotSizeId
											? `${protocolData.plotSizeName} (${protocolData.plotSizeDescription})`
											: ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Variables Tested
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{_.filter(protocolData?.protocolVariables, (pv) => pv.checked)?.length > 0
											? _.map(
													_.filter(protocolData.protocolVariables, (pv) => pv.checked),
													({ name }, i) => {
														if (i !== 0) {
															return <div key={i}>{name}</div>;
														} else {
															return name;
														}
													}
											  )
											: "No variables tested"}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Applications
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolApplications?.length > 0
											? _.map(
													protocolApplications,
													({ applicationMethodName, applicationTypeName, plannedTiming }, i) => {
														if (i !== 0) {
															return (
																<div
																	key={i}
																>{`${applicationMethodName} ${applicationTypeName} - ${plannedTiming}`}</div>
															);
														} else {
															return `${applicationMethodName} ${applicationTypeName} - ${plannedTiming}`;
														}
													}
											  )
											: "No applications tested"}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Objectives
									</Grid.Column>
									<Grid.Column width={12} textAlign="left">
										{protocolData?.objectives?.length > 0 ? protocolData?.objectives : "No objectives set"}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Treatments
									</Grid.Column>
									<Grid.Column width={2} textAlign="left">
										{protocolData?.treatments ?? ""}
									</Grid.Column>
									<Grid.Column width={3} textAlign="right" verticalAlign="middle" style={{ fontWeight: "bold" }}>
										Replicates
									</Grid.Column>
									<Grid.Column width={4} textAlign="left">
										{protocolData?.replicates ?? ""}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column width={10}>
							<Ortho trials={protocolTrials} />
						</Grid.Column>
					</Grid.Row>
					<br />
					<Grid.Row>
						<Grid.Column>
							<Table celled striped selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell width={3} textAlign="center">
											Trial Name
										</Table.HeaderCell>
										<Table.HeaderCell width={3} textAlign="center">
											Cooperator
										</Table.HeaderCell>
										<Table.HeaderCell width={2} textAlign="center">
											City
										</Table.HeaderCell>
										<Table.HeaderCell width={2} textAlign="center">
											State
										</Table.HeaderCell>
										<Table.HeaderCell width={2} textAlign="center">
											Plant Date
										</Table.HeaderCell>
										<Table.HeaderCell width={2} textAlign="center">
											Flights
										</Table.HeaderCell>
										<Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(protocolTrials, (trial, i) => {
										return (
											<Table.Row key={i} textAlign="center">
												<Table.Cell>
													<Link to={moduleNavigation.createTrialLink(false, trial.trialId, "edit")} target="_blank">
														{trial.name}
													</Link>
												</Table.Cell>
												<Table.Cell>{trial.cooperatorName ?? ""}</Table.Cell>
												<Table.Cell>{trial.city ?? ""}</Table.Cell>
												<Table.Cell>{trial.state ?? ""}</Table.Cell>
												<Table.Cell>
													{trial.plantDate ? moment(new Date(trial.plantDate)).local().format("MM/DD/YYYY") : ""}
												</Table.Cell>
												<Table.Cell>
													{trial.flights.length > 0
														? _.map(_.sortBy(trial.flights, "date"), (flight, ii) => {
																return (
																	<div key={ii}>
																		<Link
																			to={moduleNavigation.createTrialLink(
																				false,
																				trial.trialId,
																				"viewer",
																				null,
																				null,
																				flight.id
																			)}
																			target="_blank"
																			onClick={() => orthoViewerActions.setCurrentFlightId(flight.id)}
																		>
																			{moment(new Date(flight.date)).local().format("MM/DD/YYYY")}
																		</Link>
																	</div>
																);
														  })
														: ""}
												</Table.Cell>
												<Table.Cell>
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={<p>Edit</p>}
														transitionName="rc-tooltip-zoom"
													>
														<Link to={moduleNavigation.createTrialLink(false, trial.trialId, "edit")} target="_blank">
															<Icon.Group>
																<Icon name="leaf" corner="top left" style={{ fontSize: 11, textShadow: "unset" }} />
																<Icon name="pencil" corner="bottom right" style={{ fontSize: 6 }} />
															</Icon.Group>
														</Link>
													</Tooltip>
													<span style={{ display: "inline-block", width: 7 }} />
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={<p>Heatmap</p>}
														transitionName="rc-tooltip-zoom"
													>
														<Link
															to={moduleNavigation.createTrialLink(false, trial.trialId, "heatmap")}
															target="_blank"
														>
															<Icon name="map" />
														</Link>
													</Tooltip>
													<span style={{ display: "inline-block", width: 5 }} />
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={<p>Analysis</p>}
														transitionName="rc-tooltip-zoom"
													>
														<Link
															to={moduleNavigation.createTrialLink(false, trial.trialId, "analysis")}
															target="_blank"
														>
															<Icon name="chart bar outline" />
														</Link>
													</Tooltip>
													<span style={{ display: "inline-block", width: 5 }} />
													<Tooltip
														placement="bottom"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={`Meta-Tags & Annotations`}
														transitionName="rc-tooltip-zoom"
													>
														<a
															style={{ cursor: "pointer" }}
															onClick={() => {
																toggleMetaFactorModal(trial.trialId, trial.name, true);
															}}
														>
															<Icon name="tags" />
														</a>
													</Tooltip>
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
			<TrialMetaFactorsModal
				open={metaFactorModalOpen}
				setOpen={setMetaFactorsModalOpen}
				trialId={modalTrialId}
				trialName={modalTrialName}
				isForProtocol={true}
			/>
		</>
	);
};

export default ProtocolOverview;
