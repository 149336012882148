import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Loader, Message, Modal, Table } from "semantic-ui-react";
import { TrialContext } from "../../../../hooks/TrialContext";
import * as entityApi from "../../../../apis/entityApi.js";
import _ from "lodash";
import moment from "moment";
import { useAuth0 } from "../../../../auth/auth0";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

type Props = {
	trialId: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	trialName?: string;
	isForProtocol?: boolean;
};

const TrialMetaFactorsModal: FC<Props> = ({ trialId, open, setOpen, trialName, isForProtocol }) => {
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	const { getMetaTagOptionsQuery, getMetaFactorDataQuery, updateMetaTagsMutator, getAnnotationDataQuery } =
		useContext(TrialContext);

	const getEntityNames = async (trialId) => {
		const accessToken = await getTokenSilently();
		const json = entityApi.getEntityNamesAsync(undefined, undefined, trialId, undefined, accessToken);
		return json;
	};

	const { data: entityTrialName, refetch: refetchTrialName } = useQuery({
		queryKey: ["meta tag options", trialId],
		queryFn: () => getEntityNames(trialId),
		select: (data) => data.trialName,
		enabled: !!trialId && !trialName && open
	});

	const { data: metaTagOptions } = getMetaTagOptionsQuery([open]);
	const { isFetching: dataLoading, data: metaFactorData } = getMetaFactorDataQuery(trialId, [metaTagOptions, open]);
	const { mutate: updateMetaTags, isPending: saving } = updateMetaTagsMutator({
		onSuccess: () => {
			setOpen(false);
		}
	});
	const { data: annotationData, isLoading: annotationLoading } = getAnnotationDataQuery(trialId, [open]);

	const [trialDataQualityId, setTrialDataQualityId] = useState("");
	const [trialExclusionStatusId, setTrialExclusionStatusId] = useState("");
	const [trialSiteResponseId, setTrialSiteResponseId] = useState("");
	const [userReviewStatusId, setUserReviewStatusId] = useState("");
	const [trialNotes, setTrialNotes] = useState("");
	const [notesError, setNotesError] = useState(false);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState<"ascending" | "descending" | undefined>();
	const [annotationSummaryData, setAnnotationSummaryData] = useState([] as any[]);
	const [localTrialName, setLocalTrialName] = useState(trialName);
	const DATE_FORMAT = "M/D/YYYY";

	const dataStatusOptions = useMemo(
		() =>
			metaTagOptions?.trialExclusionStatuses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const dataQualityOptions = useMemo(
		() =>
			metaTagOptions?.trialDataQualities.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const responseCategoriesOptions = useMemo(
		() =>
			metaTagOptions?.trialSiteResponses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const userReviewStatusOptions = useMemo(
		() =>
			metaTagOptions?.userReviewStatuses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	useEffect(() => {
		if (trialNotes) {
			setNotesError(trialNotes.length > 200);
		}
	}, [trialNotes]);

	useEffect(() => {
		if (trialId) {
			refetchTrialName();
		}
	}, [trialId]);

	useEffect(() => {
		if (entityTrialName) {
			setLocalTrialName(entityTrialName);
		}
	}, [entityTrialName]);

	useEffect(() => {
		resetInputs();
	}, [metaFactorData]);

	useEffect(() => {
		if (annotationData) {
			setAnnotationSummaryData(annotationData);
		}
	}, [annotationData]);

	const resetInputs = () => {
		if (metaFactorData) {
			setTrialDataQualityId(metaFactorData.trialDataQualityId);
			setTrialExclusionStatusId(metaFactorData.trialExclusionStatusId);
			setTrialSiteResponseId(metaFactorData.trialSiteResponseId);
			setUserReviewStatusId(metaFactorData.userReviewStatusId);
			setTrialNotes(metaFactorData.notes ?? "");
		}
	};

	const save = async () => {
		if (!notesError) {
			updateMetaTags({
				trialId,
				trialDataQualityId,
				trialExclusionStatusId,
				trialSiteResponseId,
				userReviewStatusId: userReviewStatusId === metaFactorData?.userReviewStatusId ? null : userReviewStatusId,
				trialNotes,
				trialName,
				isForProtocol
			});
			return true;
		} else {
			toast.error("Trial notes cannot exceed 200 characters.");
			return false;
		}
	};

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setAnnotationSummaryData(
					_.sortBy(
						annotationSummaryData,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|plotId|rep|altitude|total|treatment/i)) {
				setAnnotationSummaryData(_.sortBy(annotationSummaryData, [clickedColumn]));
			} else {
				setAnnotationSummaryData(
					_.sortBy(annotationSummaryData, [
						(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])
					])
				);
			}

			setSortDirection("ascending");
			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setAnnotationSummaryData(annotationSummaryData.reverse());
	};

	return (
		<Modal open={open} style={{ width: "90%" }}>
			<Modal.Header>{`Annotation Summary${localTrialName ? ` for ${localTrialName}` : ""}`}</Modal.Header>
			<Modal.Content>
				{notesError && <Message error content="Trial notes cannot exceed 200 characters." />}
				<Form error={notesError} style={{ padding: "1em", display: "flex" }}>
					<div style={{ display: "flex", flexDirection: "column", paddingRight: "1em" }}>
						<Form.Field style={{ flexGrow: 1 }}>
							<Form.Select
								id="modal-trial-meta-tags-exclusion-status"
								label="Exclusion Status"
								options={dataStatusOptions}
								loading={dataLoading}
								value={trialExclusionStatusId}
								onChange={(_e, { value }) => {
									setTrialExclusionStatusId(value as string);
								}}
							/>
						</Form.Field>
						<Form.Field style={{ flexGrow: 1 }}>
							<Form.Select
								id="modal-trial-meta-tags-data-quality"
								label="Data Quality"
								options={dataQualityOptions}
								loading={dataLoading}
								value={trialDataQualityId}
								onChange={(_e, { value }) => {
									setTrialDataQualityId(value as string);
								}}
							/>
						</Form.Field>
						<Form.Field style={{ flexGrow: 1 }}>
							<Form.Select
								id="modal-trial-meta-tags-site-response"
								label="Site Response"
								options={responseCategoriesOptions}
								loading={dataLoading}
								value={trialSiteResponseId}
								onChange={(_e, { value }) => {
									setTrialSiteResponseId(value as string);
								}}
							/>
						</Form.Field>
					</div>
					<div style={{ flexGrow: 2 }}>
						<Form.TextArea
							id="modal-form-text-area-notes"
							label="Trial Notes"
							// defaultValue={trialNotes}
							value={dataLoading ? "" : trialNotes}
							onChange={(_, { value }) => {
								setTrialNotes(value as string);
							}}
							style={notesError ? { borderColor: "#e0b4b4", background: "#FFF6F6", minHeight: 98 } : { minHeight: 98 }}
						/>
						<div style={{ display: "flex" }}>
							<Form.Field>
								<Form.Select
									id="modal-trial-meta-tags-annotation-status"
									label="Plot Annotation Review Status"
									clearable
									options={userReviewStatusOptions}
									loading={dataLoading}
									value={userReviewStatusId}
									onChange={(_e, { value }) => {
										setUserReviewStatusId(value as string);
									}}
								/>
							</Form.Field>
							{metaFactorData?.userReviewStatusId && (
								<div style={{ alignSelf: "end", paddingBottom: "1em" }}>
									Last updated by {metaFactorData?.reviewedByUserName} on{" "}
									{moment.utc(metaFactorData?.reviewedDateTimeUtc).local().format("lll")}
								</div>
							)}
						</div>
					</div>
				</Form>
				{annotationSummaryData.length > 0 && (
					<>
						<Divider />
						<div className="tableFixHead">
							{annotationLoading ? (
								<Loader />
							) : (
								<Table celled striped sortable textAlign="center">
									<Table.Header>
										<Table.Row id="annotationSummaryModal">
											<Table.HeaderCell
												sorted={sortColumn === "treatment" ? sortDirection : undefined}
												onClick={() => handleSort("treatment")}
											>
												Treatment
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={sortColumn === "plotId" ? sortDirection : undefined}
												onClick={() => handleSort("plotId")}
											>
												Plot
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={sortColumn === "rep" ? sortDirection : undefined}
												onClick={() => handleSort("rep")}
											>
												Rep
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={sortColumn === "annotationType" ? sortDirection : undefined}
												onClick={() => handleSort("annotationType")}
											>
												Annotation Type
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={sortColumn === "annotationClass" ? sortDirection : undefined}
												onClick={() => handleSort("annotationClass")}
											>
												Annotation Class
											</Table.HeaderCell>
											<Table.HeaderCell>Subsamples</Table.HeaderCell>
											<Table.HeaderCell>Date Range</Table.HeaderCell>
											<Table.HeaderCell
												sorted={sortColumn === "user" ? sortDirection : undefined}
												onClick={() => handleSort("user")}
											>
												User
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={sortColumn === "addedOn" ? sortDirection : undefined}
												onClick={() => handleSort("addedOn")}
											>
												Added On
											</Table.HeaderCell>
											<Table.HeaderCell style={{ minWidth: "400px" }}>Notes</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(annotationSummaryData, (asd) => {
											// map each row
											return (
												<Table.Row>
													<Table.Cell>{asd.treatment}</Table.Cell>
													<Table.Cell>{asd.plotId}</Table.Cell>
													<Table.Cell>{asd.rep}</Table.Cell>
													<Table.Cell>{asd.annotationType}</Table.Cell>
													<Table.Cell>{asd.annotationClass}</Table.Cell>
													<Table.Cell>{asd.subsample}</Table.Cell>
													<Table.Cell>{asd.dateRange}</Table.Cell>
													<Table.Cell>{asd.user}</Table.Cell>
													<Table.Cell>{asd.addedOn}</Table.Cell>
													<Table.Cell
														fixed
														style={{
															maxWidth: "400px",
															textOverflow: "ellipsis",
															overflow: "hidden"
														}}
													>
														{asd.note}
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							)}
						</div>
					</>
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="Cancel"
					onClick={() => {
						resetInputs();
						setOpen(false);
					}}
					disabled={saving}
				/>
				<Button id="modal-trial-meta-tags-save" primary content="Save" onClick={save} loading={saving} disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialMetaTagModalCRUD, userAuth.currentClientId)} />
			</Modal.Actions>
		</Modal>
	);
};

export default TrialMetaFactorsModal;
