import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import { Segment, Table, Pagination, Dropdown } from "semantic-ui-react";

import { CSVLink } from "react-csv";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import { useUserAuth } from "../../../../hooks/useUserAuth";

const FieldOutlineTable = ({ fieldOutlineData }) => {
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();

	const DATE_FORMAT = "M/D/YYYY";

	//-- Data source
	const [dataSource, setDataSource] = useState(fieldOutlineData);

	//-- Paging
	const [itemsPerPage, setItemsPerPage] = useState(100);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	//-- Sorting
	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- CSV
	const [fileName, setFileName] = useState("TrialOutline.csv");
	const [csvData, setCsvData] = useState(null);

	//-- Initial view load
	useEffect(() => {
		if (fieldOutlineData) {
			setTotalPages(Math.ceil(fieldOutlineData.length / itemsPerPage));
			setDataSource(fieldOutlineData);
			setTableData(dataSource);

			setCsvInfo();
		}
	}, [fieldOutlineData]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|plot|trialTreatmentId|rep/i)) {
				setDataSource(_.sortBy(dataSource, (d) => parseInt(d[clickedColumn])));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	};

	function setCsvInfo() {
		if (fieldOutlineData && fieldOutlineData.length > 0) {
			const fieldName = fieldOutlineData[0].field;

			setFileName(`${fieldName}_TrialOutline_${moment().format("YYYYMMDD_hhmma")}.csv`);

			setCsvData(
				fieldOutlineData.map((fod) => {
					return {
						Field: fod.field,
						Range: fod.range,
						Column: fod.column,
						Company: fod.company,
						Trial: fod.trial,
						Treatment: fod.trialTreatmentId,
						Plot: fod.plot,
						Rep: fod.rep,
						Seed: fod.seed
					};
				})
			);
		}
	}

	// prettier-ignore
	return (
		<Segment basic style={{ padding: 0 }}>
			<span style={{ float: "right", textAlign: "right" }}>
				{dataSource.length === 1 ? `${dataSource.length} plot` : `${dataSource.length} plots`}
				{csvData && (
					<>
						<br />
						<CSVLink filename={fileName} data={csvData} enclosingCharacter={""}>
							Download
						</CSVLink>
					</>
				)}
			</span>
			<Table celled striped sortable selectable color="blue" textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={sortColumn === "field" ? sortDirection : null}
							onClick={() => handleSort("field")}
						>
							Field
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "range" ? sortDirection : null}
							onClick={() => handleSort("range")}
						>
							Range
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "column" ? sortDirection : null}
							onClick={() => handleSort("column")}
						>
							Column
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "company" ? sortDirection : null}
							onClick={() => handleSort("company")}
						>
							Company
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "trial" ? sortDirection : null}
							onClick={() => handleSort("trial")}
						>
							Trial
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "trialTreatmentId" ? sortDirection : null}
							onClick={() => handleSort("trialTreatmentId")}
						>
							Treatment
						</Table.HeaderCell>
						<Table.HeaderCell sorted={sortColumn === "plot" ? sortDirection : null} onClick={() => handleSort("plot")}>
							Plot
						</Table.HeaderCell>
						<Table.HeaderCell sorted={sortColumn === "rep" ? sortDirection : null} onClick={() => handleSort("rep")}>
							Rep
						</Table.HeaderCell>
						<Table.HeaderCell sorted={sortColumn === "seed" ? sortDirection : null} onClick={() => handleSort("seed")}>
							Seed
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{_.map(
						tableData,
						({ fieldId, field, range, column, company, trialId, trial, trialTreatmentId, plot, rep, seed }, index) => {
							return (
								<Table.Row key={index}>
									<Table.Cell>
										{userAuth.hasApplicationArea(ApplicationArea.FieldHeatMap, userAuth.currentClientId) ? (
											<Link to={moduleNavigation.createFieldLink(true, fieldId, "heatmap")} target="_blank">
												{field}
											</Link>
										) : (
											<>{field}</>
										)}
									</Table.Cell>
									<Table.Cell>{range}</Table.Cell>
									<Table.Cell>{column}</Table.Cell>
									<Table.Cell>{company}</Table.Cell>
									<Table.Cell>
										{userAuth.hasApplicationArea(ApplicationArea.TrialOutline, userAuth.currentClientId) ? (
											<Link to={moduleNavigation.createTrialLink(true, trialId, "outline")} target="_blank">
												{trial}
											</Link>
										) : (
											<>{trial}</>
										)}
									</Table.Cell>
									<Table.Cell>{trialTreatmentId}</Table.Cell>
									<Table.Cell>{plot}</Table.Cell>
									<Table.Cell>{rep}</Table.Cell>
									<Table.Cell>{seed}</Table.Cell>
								</Table.Row>
							);
						}
					)}
				</Table.Body>

				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="11">
							<span style={{ marginRight: 10 }}>
								{dataSource.length === 1 ? `${dataSource.length} plot` : `${dataSource.length} plots`}
							</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 50, value: 50 },
									{ key: 2, text: 100, value: 100 },
									{ key: 3, text: 150, value: 150 },
									{ key: 4, text: 300, value: 300 },
									{ key: 5, text: 500, value: 500 },
									{ key: 6, text: 1000, value: 1000 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</Segment>
	);
};

FieldOutlineTable.propTypes = {
	fieldOutlineData: PropTypes.array.isRequired
};

export default FieldOutlineTable;
