import _ from "lodash";
import * as farmTypes from "./types/farmTypes";
import { AerialPlotResponse } from "./types";

//@ts-expect-error
const aerialPlotApi: string = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getFarms(accessToken: string, clientId: string) {
	return fetch(`${aerialPlotApi}api/v1/Farms/GetFarmsForSearch?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data.data as farmTypes.FarmData[];
		});
}

export async function getFarmsAsync(
	accessToken: string,
	clientId: string
): Promise<AerialPlotResponse<farmTypes.FarmData[]>> {
	const res = await fetch(`${aerialPlotApi}api/v1/Farms/GetFarmsForSearch?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	});

	if (!res.ok) {
		throw new Error("failed to fetch farms");
	}
	const data = res.json();
	return data;
}

export function getFarmInfo(accessToken, clientId, farmId) {
	return fetch(`${aerialPlotApi}api/v1/Farms/GetFarmInfo?clientId=${clientId}&farmId=${farmId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createFarm(accessToken, newFarm) {
	const farmFormData = new FormData();

	for (const name in newFarm) {
		let val = newFarm[name] === "" ? null : newFarm[name];

		if (val) {
			if (name.match(/date/gim) && !!val) {
				val = val.toLocaleDateString();
			}

			if (typeof val === "string") val = val?.trim();
			farmFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Farms`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: farmFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateFarm(accessToken, farmUpdate) {
	const farmFormData = new FormData();

	for (const name in farmUpdate) {
		let val = farmUpdate[name] === "" ? null : farmUpdate[name];

		if (val) {
			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			if (typeof val === "string") val = val?.trim();
			farmFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Farms`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: farmFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteFarm(accessToken, clientId, farmId) {
	return fetch(`${aerialPlotApi}api/v1/Farms?clientId=${clientId}&farmId=${farmId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createFarmParcels(accessToken, clientId, farmId, parcels) {
	const parcelsForm = new FormData();

	parcelsForm.set("FarmId", farmId);
	parcelsForm.set("ClientId", clientId);

	const features = parcels.features;
	for (let i = 0; i < features.length; i++) {
		const feat = features[i];
		parcelsForm.append(`FarmParcels[${i}].Name`, feat.properties.headline);
		if (feat.properties.fields.address)
			parcelsForm.append(`FarmParcels[${i}].Address1`, feat.properties.fields.address);
		if (feat.properties.fields.address2)
			parcelsForm.append(`FarmParcels[${i}].Address2`, feat.properties.fields.address2);
		if (feat.properties.fields.city) parcelsForm.append(`FarmParcels[${i}].City`, feat.properties.fields.city);
		if (feat.properties.fields.stateId) parcelsForm.append(`FarmParcels[${i}].StateId`, feat.properties.fields.stateId);
		if (feat.properties.fields.szip5) parcelsForm.append(`FarmParcels[${i}].ZipCode`, feat.properties.fields.szip5);
		if (feat.properties.fields.county) parcelsForm.append(`FarmParcels[${i}].County`, feat.properties.fields.county);
		if (feat.properties.fields.ll_gisacre)
			parcelsForm.append(`FarmParcels[${i}].Size`, feat.properties.fields.ll_gisacre);
		if (feat.properties.fields.owner) parcelsForm.append(`FarmParcels[${i}].OwnerName`, feat.properties.fields.owner);

		const truncated = _.cloneDeep(feat);
		truncated.properties = {
			headline: feat.properties.headline,
			ll_uuid: feat.properties.ll_uuid,
			fields: {
				lat: feat.properties.fields.lat,
				lon: feat.properties.fields.lon
			}
		};
		parcelsForm.append(`FarmParcels[${i}].GeoJSON`, JSON.stringify(truncated));
	}

	return fetch(`${aerialPlotApi}api/v1/Farms/CreateFarmParcels`, {
		method: "POST",
		headers: { Authorization: `Bearer ${accessToken}` },
		body: parcelsForm
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getFarmParcels(accessToken, clientId, farmId) {
	return fetch(`${aerialPlotApi}api/v1/Farms/GetFarmParcels?clientId=${clientId}&farmId=${farmId}`, {
		method: "GET",
		headers: { Authorization: `Bearer ${accessToken}` }
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateFarmBounds(accessToken, clientId, farmId, LL, UL, UR, LR) {
	const body = {
		clientId,
		farmId,
		ll: LL,
		ul: UL,
		ur: UR,
		lr: LR
	};

	return fetch(`${aerialPlotApi}api/v1/Farms/UpdateFarmBounds`, {
		method: "POST",
		headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
		body: JSON.stringify(body)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getFarmSummary(accessToken, clientId, farmId) {
	return fetch(`${aerialPlotApi}api/v1/Farms/GetFarmSummary?clientId=${clientId}&farmId=${farmId}`, {
		method: "GET",
		headers: { Authorization: `Bearer ${accessToken}` }
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getFlightsForFarm(farmId, clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/Farms/GetFlightsForFarm?farmId=${farmId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}
