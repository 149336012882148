import FarmManagement from "./Content/Settings/FarmManagement";
import ConfigureUases from "./Content/Settings/ConfigureUases";
import PersonManagement from "./Content/Settings/PersonManagement";
import CompanyManagement from "./Content/Settings/CompanyManagement";
import LogoManagement from "./Content/Settings/LogoManagement";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const settingsRoutesClient = [
	{
		id: "settings-farm-management",
		name: "farm-management",
		enabled: false, // disabled with new farms module
		login: false,
		logout: false,
		home: false,
		linkText: "Farm Management",
		pageTitle: "Farm Management Settings",
		icon: "object group",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/client-settings/farm-management`],
		exact: true,
		component: FarmManagement,
		module: "client-settings",
		applicationAreas: [ApplicationArea.ClientSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-configure-uases",
		name: "configure-uases",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Configre UASes",
		pageTitle: "UAS Settings",
		icon: "drone",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/client-settings`, `/client-settings/configure-uases`],
		exact: true,
		component: ConfigureUases,
		module: "client-settings",
		applicationAreas: [ApplicationArea.ClientSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-person-management",
		name: "person-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Person Management",
		pageTitle: "Person Management Settings",
		icon: "user settings",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/client-settings/person-management`],
		exact: true,
		component: PersonManagement,
		module: "client-settings",
		applicationAreas: [ApplicationArea.ClientSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-company-management",
		name: "company-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Company Management",
		pageTitle: "Company Management Settings",
		icon: "briefcase",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/client-settings/company-management`],
		exact: true,
		component: CompanyManagement,
		module: "client-settings",
		applicationAreas: [ApplicationArea.ClientSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-logo-management",
		name: "logo-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Logo Management",
		pageTitle: "Logo Management Settings",
		icon: "image edit",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/client-settings/logo-management`],
		exact: true,
		component: LogoManagement,
		module: "client-settings",
		applicationAreas: [ApplicationArea.ClientSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	}
];

export default {
	settingsRoutesClient
};
