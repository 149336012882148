import * as types from "./actionTypes";

/*
 *  Action Creators
 *
 */
export function setCurrentFlightIdSuccess(flightId) {
	return { type: types.OV_SET_CURRENT_FLIGHT_ID, flightId };
}

export function setCurrentFlightDateSuccess(flightDate) {
	return { type: types.OV_SET_CURRENT_FLIGHT_DATE, flightDateAndTime: flightDate };
}

export function setCurrentFlightYearSuccess(year) {
	return { type: types.OV_SET_CURRENT_FLIGHT_YEAR, year: year };
}

export function updateFiltersSuccess(filters) {
	return { type: types.OV_UPDATE_FILTERS, filters: filters };
}

export function setImageLoadedSuccess(loaded) {
	return { type: types.OV_SET_IMAGE_LOADED, imageLoaded: loaded };
}

/*
 *  Thunks
 *
 */
export function setCurrentFlightId(flightId) {
	return function (dispatch) {
		dispatch(setCurrentFlightIdSuccess(flightId));
	};
}

export function setCurrentFlightDate(flightDate) {
	return function (dispatch) {
		dispatch(setCurrentFlightDateSuccess(flightDate));
	};
}

export function setCurrentFlightYear(year) {
	return function (dispatch) {
		dispatch(setCurrentFlightYearSuccess(year));
	};
}

export function updateFilters(filters) {
	return function (dispatch) {
		dispatch(updateFiltersSuccess(filters));
	};
}

export function setImageLoaded(loaded) {
	return function (dispatch) {
		dispatch(setImageLoadedSuccess(loaded));
	};
}
