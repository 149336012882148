import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Segment, Loader } from "semantic-ui-react";

import { useDispatch } from "react-redux";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import moment from "moment";

import * as groundDataActions from "../../../../redux/actions/groundDataActions";
import NewGroundData from "../../../Lumber/GroundData/NewGroundData";

import { toast } from "react-toastify";

const FieldGroundData = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const history = useHistory();

	//-- Data Sources
	const [groundDataQuestions, setGroundDataQuestions] = useState(null);
	const [growthStages, setGrowthStages] = useState([]);
	const [persons, setPersons] = useState([]);
	const [fieldOutlineData, setFieldOutlineData] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userAuth.currentClientId && !groundDataQuestions) {
			getGroundDataInfo();
		}
	}, [userAuth.currentClientId]);

	async function getGroundDataInfo() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(groundDataActions.getGroundDataInfo(moduleNavigation.fieldId, "", userAuth.currentClientId, accessToken))
			.then((res) => {
				//TODO need to add grwth phases here
				setGroundDataQuestions(res.data.groundDataQuestions);
				setGrowthStages(res.data.growthStages);
				setPersons(res.data.persons);
				setFieldOutlineData(res.data.fieldOutlineData);
				setLoading(false);
			})
			.catch((err) => {
				toast.error("Error loading field ground data info. Please try again.");
				console.log(err);
				setLoading(false);
			});
	}

	function onSave(continueProcess) {
		if (continueProcess) {
			history.push(moduleNavigation.createFieldLink(true, null, "heatmap"));
		} else {
			history.push(moduleNavigation.createFieldLink(true, null, "ground-data"));
		}
	}

	return loading || !groundDataQuestions ? (
		<Loader active />
	) : (
		<Segment basic>
			<NewGroundData
				groundDataQuestions={groundDataQuestions}
				growthStages={growthStages}
				persons={persons}
				outlineData={fieldOutlineData}
				fileName={`${fieldOutlineData[0]?.field}_GroundData_${moment().format("YYYYMMDD_hhmma")}.csv`}
				onSave={onSave}
				level="field"
			/>
		</Segment>
	);
};

export default FieldGroundData;
