import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import * as queryString from "query-string";

import { Loader, Segment, Menu } from "semantic-ui-react";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useHistory, useLocation } from "react-router-dom";

import TrialWeatherGraphs from "../../Trials/TrialWeather/TrialWeatherGraphs";
import UploadWeatherDataCSV from "./UploadWeatherDataCSV";

const TrialWeather = () => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const location = useLocation();
	const history = useHistory();
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	const trialWeatherGraphs = "trial-weather-graphs";
	const trialWeatherDeviations = "trial-weather-deviations";
	const trialWeatherEvents = "trial-weather-events";
	const trialWeatherUpload = "trial-weather-upload";

	const urlQueryStrings = queryString.parse(location.search);
	const qsAt = urlQueryStrings.at;

	//-- Data
	const [trialName, setTrialName] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [activeItem, setActiveItem] = useState(trialWeatherGraphs);

	useEffect(() => {
		if (userAuth.isReady) {
			setActiveItem(getAnalysisTypeFromQueryString());
		}
	}, [userAuth.isReady, location.search]);

	function getAnalysisTypeFromQueryString() {
		switch (qsAt) {
			case trialWeatherDeviations:
				return trialWeatherDeviations;
			case trialWeatherEvents:
				return trialWeatherEvents;
			case trialWeatherUpload:
				return trialWeatherUpload;
			default:
			case trialWeatherGraphs:
				return trialWeatherGraphs;
		}
	}

	useEffect(() => {
		if (entityNames.trialId && moduleNavigation.trialId?.toUpperCase() === entityNames.trialId?.toUpperCase()) {
			setTrialName(
				`${entityNames.trialName} (${entityNames.cooperatorName}, ${entityNames.city}, ${entityNames.state})`
			);
			setLoading(false);
		}
	}, [entityNames]);

	return loading || !userAuth.isReady ? (
		<Loader active />
	) : !loading && !trialName && userAuth.isReady ? (
		<Segment style={{ margin: 15 }}>
			<p>{"We could not find historical weather data for this trial."}</p>
		</Segment>
	) : (
		<Segment basic>
			<Menu pointing>
				<Menu.Item
					name="Graphs"
					active={activeItem === trialWeatherGraphs}
					onClick={() => {
						history.push(`?at=${trialWeatherGraphs}`);
					}}
				/>
				<Menu.Item
					name="Deviations"
					active={activeItem === trialWeatherDeviations}
					onClick={() => {
						history.push(`?at=${trialWeatherDeviations}`);
					}}
					disabled
				/>
				<Menu.Item
					name="Events"
					active={activeItem === trialWeatherEvents}
					onClick={() => {
						history.push(`?at=${trialWeatherEvents}`);
					}}
					disabled
				/>
				<Menu.Item
					name="Upload"
					active={activeItem === trialWeatherUpload}
					onClick={() => {
						history.push(`?at=${trialWeatherUpload}`);
					}}
					disabled
				/>
			</Menu>
			{activeItem === trialWeatherGraphs ? <TrialWeatherGraphs trialName={entityNames.trialName} /> : null}
			{activeItem === trialWeatherUpload ? <UploadWeatherDataCSV /> : null}
		</Segment>
	);
};

export default TrialWeather;
