import React, { useState, useEffect } from "react";

import { Segment, Form, Grid, Accordion, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

import ProtocolSearchResults from "./protocolSearchResults";
import _ from "lodash";

import "./style.css";

const ProtocolSearch = ({
	unfilteredProtocolList,
	filteredProtocolList,
	setProtocolName,
	setProtocolOwner,
	loading,
	reset
}) => {
	//-- Search Options
	const [name, setName] = useState(null);
	const [owner, setOwner] = useState(null);

	//-- UI Control
	const [accordionActive, setAccordionActive] = useState(true);

	//-- Resets all fields
	useEffect(() => {
		setName(null);
		setOwner(null);
	}, [reset]);

	//-- Sends protocol name back up to parent search page
	useEffect(() => {
		setProtocolName(name);
	}, [name]);

	//-- Sends protocol owner back up to parent search page
	useEffect(() => {
		setProtocolOwner(owner);
	}, [owner]);

	//-- Toggles accordian
	const handleAccordionClick = () => {
		setAccordionActive(!accordionActive);
	};

	return (
		<Segment style={{ backgroundColor: "var(--primary-coffee-900)", border: "none", borderRadius: "8px" }}>
			<Accordion fluid>
				<Accordion.Title active={accordionActive} style={{ cursor: "unset", paddingBottom: 0 }}>
					<Grid>
						<Grid.Row style={{ paddingTop: 0, color: "white", gap: "12px" }}>
							<div
								onClick={() => handleAccordionClick()}
								style={{ cursor: "pointer", alignSelf: "end", margin: "0 1em" }}
							>
								<h2>
									<Icon name="dropdown" />
									Protocols
								</h2>
							</div>
							<Form.Dropdown
								fluid
								openOnFocus
								className="dashboardInput solid"
								onChange={(event, { value }) => {
									setName(value);
								}}
								onSearchChange={(event, { searchQuery }) => {
									setName(searchQuery);
								}}
								options={_.uniqBy(
									_.map(loading ? [] : unfilteredProtocolList, (p) => {
										return { text: p.protocolName, value: p.protocolName };
									}),
									"value"
								).sort((a, b) => (a.value > b.value ? 1 : -1))}
								placeholder="Protocol Name/ID"
								search
								searchQuery={name ?? ""}
								selection
								selectOnBlur={false}
								type="text"
								value={name ?? ""}
								disabled={loading}
								onOpen={() => {
									setName("");
								}}
							/>

							<Form.Dropdown
								fluid
								openOnFocus
								className="dashboardInput solid"
								onChange={(event, { value }) => {
									setOwner(value);
								}}
								onSearchChange={(event, { searchQuery }) => {
									setOwner(searchQuery);
								}}
								options={_.uniqBy(
									_.map(loading ? [] : unfilteredProtocolList, (p) => {
										return { text: p.owner, value: p.owner };
									}),
									"value"
								).sort((a, b) => (a.value > b.value ? 1 : -1))}
								placeholder="Protocol Owner"
								search
								searchQuery={owner ?? ""}
								selection
								selectOnBlur={false}
								type="text"
								value={owner ?? ""}
								disabled={loading}
								onOpen={() => {
									setOwner("");
								}}
							/>
						</Grid.Row>
					</Grid>
				</Accordion.Title>
				<Accordion.Content active={accordionActive} style={{ maxHeight: "500px", overflowY: "auto", paddingTop: 0 }}>
					<ProtocolSearchResults protocolList={loading ? [] : filteredProtocolList} />
				</Accordion.Content>
			</Accordion>
		</Segment>
	);
};

ProtocolSearch.propTypes = {
	unfilteredProtocolList: PropTypes.array,
	filteredProtocolList: PropTypes.array.isRequired,
	setProtocolName: PropTypes.func.isRequired,
	setProtocolOwner: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	reset: PropTypes.bool.isRequired
};

export default ProtocolSearch;
