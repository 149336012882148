import React, { useState, useEffect } from "react";
import { Segment, Button, Form, Popup, Icon, Input, Loader, Message } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import ShapeFileUpload from "../NewEditField/shapeFileUpload";
import * as fieldActions from "../../../../redux/actions/fieldActions";
import { validateGps } from "./Validation";
import DeleteConfirmationModal from "../FieldWizard/deleteModal";

const FieldGpsSetup = ({ fieldId, fromViewer, handleFinish, handleCancel, navigateToNextStep, navigateBack }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { getTokenSilently } = useAuth0();

	//-- Data
	const [field, setField] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [validation, setValidation] = useState({ error: false, errorList: [] });
	const [gps, setGps] = useState({});
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	useEffect(() => {
		if (fieldId) {
			getFieldData(fieldId);
		}
	}, [fieldId]);

	useEffect(() => {
		if (field) {
			let fieldGps = {
				llLat: field.llLat,
				ulLat: field.ulLat,
				llLong: field.llLong,
				ulLong: field.ulLong,
				degreeHeading: field.degreeHeading
			};
			setGps(fieldGps);
		}
	}, [field]);

	async function getFieldData(fieldId) {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getField(fieldId, accessToken))
			.then((res) => {
				setField(res);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}

	async function save(continueToNextPage, finish) {
		setSaving(true);
		let validate = validateGps(gps);
		setValidation(validate);

		if (validate.error === true) {
			setSaving(false);
			return;
		}
		const accessToken = await getTokenSilently();
		dispatch(
			fieldActions.saveFieldCoordinates(
				fieldId,
				gps.llLat,
				gps.ulLat,
				gps.llLong,
				gps.ulLong,
				gps.degreeHeading,
				clientId,
				accessToken
			)
		).then(() => {
			setSaving(false);
			if (finish && handleFinish) {
				handleFinish(fieldId);
			} else {
				toast.success("Field Layout updated successfully.");

				if (continueToNextPage) {
					navigateToNextStep();
				}
			}
		});
	}

	async function deleteField() {
		setDeleting(true);
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.deleteField(fieldId, clientId, accessToken))
			.then(() => {
				setDeleting(false);

				if (fromViewer) {
					handleCancel();
				} else {
					history.push("/fields");
				}
			})
			.catch((err) => {
				console.log(err);
				//TODO: add the validation if the field is linked to a flight
				toast.error("Field failed to delete");
			});
	}

	function saveShapeFileCoordinates(lowerLeftPoint, upperLeftPoint) {
		if (lowerLeftPoint?.length > 0) {
			setGps((gps) => ({ ...gps, llLat: lowerLeftPoint[1] }));
			setGps((gps) => ({ ...gps, llLong: lowerLeftPoint[0] }));
		}

		if (upperLeftPoint?.length > 0) {
			setGps((gps) => ({ ...gps, ulLat: upperLeftPoint[1] }));
			setGps((gps) => ({ ...gps, ulLong: upperLeftPoint[0] }));
		}
	}

	return loading ? (
		<Loader active inline="centered" />
	) : (
		<div>
			<div style={{ display: "flex", width: "100%", marginBottom: 50 }}>
				<Button
					id="form-button-back"
					color="black"
					compact
					content={"Back"}
					disabled={saving || deleting}
					onClick={() => {
						navigateBack();
					}}
				/>
				<div style={{ flexGrow: 1 }} />
				{fromViewer && (
					<Button
						id="form-button-cancel"
						type="button"
						compact
						disabled={saving}
						onClick={() => {
							setSaving(true);
							handleCancel();
						}}
					>
						Cancel
					</Button>
				)}
				{!fromViewer && (
					<DeleteConfirmationModal
						handleDeleteField={() => deleteField()}
						isDeleting={deleting}
						disabled={saving || deleting}
						loading={deleting}
					/>
				)}
				<Button
					id="form-button-save"
					color="blue"
					compact
					content={"Save"}
					onClick={() => {
						save(false, false);
					}}
					disabled={saving || deleting}
					loading={saving}
				/>
				<Button
					id="form-button-save-and-continue"
					primary
					compact
					content={"Save & Continue"}
					onClick={() => {
						save(true, false);
					}}
					disabled={saving || deleting}
					loading={saving}
				/>
			</div>
			<Segment basic>
				<Form error={validation.error}>
					<Form.Field width="10" style={{ marginBottom: 10 }}>
						<Message error list={validation.errorList} />
					</Form.Field>
					<Form.Group>
						<Form.Field inline error={validation.llLatError}>
							<label>LL Corner</label>
							<Input
								value={gps.llLat}
								label="Lat"
								type="number"
								onChange={(e, { value }) => {
									setGps((gps) => ({ ...gps, llLat: value }));
								}}
							/>
						</Form.Field>
						<Form.Field width="3" error={validation.llLongError}>
							<Input
								label="Long"
								value={gps.llLong}
								type="number"
								onChange={(e, { value }) => {
									setGps((gps) => ({ ...gps, llLong: value }));
								}}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group>
						<Form.Field inline error={validation.ulLatError}>
							<label>UL Corner</label>
							<Input
								label="Lat"
								value={gps.ulLat}
								type="number"
								onChange={(e, { value }) => {
									setGps((gps) => ({ ...gps, ulLat: value }));
								}}
							/>
						</Form.Field>
						<Form.Field width="3" error={validation.ulLongError}>
							<Input
								label="Long"
								value={gps.ulLong}
								type="number"
								onChange={(e, { value }) => {
									setGps((gps) => ({ ...gps, ulLong: value }));
								}}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Field inline error={validation.degreeHeadingError}>
						<label>Degree Heading</label>
						<Popup
							content="The degree heading value between -180 and 180."
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input
							value={gps.degreeHeading}
							type="number"
							onChange={(e, { value }) => {
								setGps((gps) => ({ ...gps, degreeHeading: value }));
							}}
						/>
					</Form.Field>
					<Form.Field width="8">
						<ShapeFileUpload saveShapeFileCoordinates={saveShapeFileCoordinates} />
					</Form.Field>
				</Form>
			</Segment>
		</div>
	);
};

FieldGpsSetup.propTypes = {
	fieldId: PropTypes.string,
	fromViewer: PropTypes.bool,
	handleFinish: PropTypes.func,
	handleCancel: PropTypes.func,
	navigateToNextStep: PropTypes.func,
	navigateBack: PropTypes.func
};

export default FieldGpsSetup;
