import _ from "lodash";
import { AerialPlotResponse, Option } from "./types";

//@ts-expect-error
const aerialApiUri: string = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getTrialSearchDataOptions(clientId, searchAllClients, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/TrialSearchDataOptions?clientId=${clientId}&searchAllClients=${searchAllClients}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getTrialFavorites(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetTrialFavorites?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getTrialFavoriteGuids(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetTrialFavoriteGuids?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getRecentlyPublishedTrials(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetRecentlyPublishedTrials?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createTrialFavorite(clientId, accessToken, trialId) {
	return fetch(`${aerialApiUri}api/v1/Trials/CreateTrialFavorite?clientId=${clientId}&trialId=${trialId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(trialId)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteTrialFavorite(clientId, accessToken, trialId) {
	return fetch(`${aerialApiUri}api/v1/Trials/DeleteTrialFavorite?clientId=${clientId}&trialId=${trialId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getTrialAnalysisDataOptions(clientId, trialId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/TrialAnalysisDataOptions?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getRecentlyUpdatedTrials(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetRecentlyUpdatedTrials?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getSummaryTableDataOptions(clientId, trialId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/SummaryTableDataOptions?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function searchForTrials(searchParams, clientId, searchAllClients, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/SearchForTrials?clientId=${clientId}&plantDateFrom=${searchParams.plantDateFrom}&plantDateTo=${searchParams.plantDateTo}&searchAllClients=${searchAllClients}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getTrialsInField(fieldId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/TrialsInField?fieldId=${fieldId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getTrialHeatmapData(clientId, trialId, accessToken) {
	return fetch(aerialApiUri + `api/v1/Trials/GetTrialHeatmapData?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getTrialOptions(clientId, trialId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/getTrialOptions?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getTrialOutlineData(clientId, trialId, accessToken) {
	return fetch(aerialApiUri + `api/v1/Trials/GetTrialOutlineData?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getTrialData(clientId, trialId, accessToken) {
	return fetch(aerialApiUri + `api/v1/Trials?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function saveAssessmentSelectionForSummaryTable(summaryTableSelectionData, accessToken, clientId, trialId) {
	const flightSelectionFormData = new FormData();
	let index = 0;
	for (const selection of summaryTableSelectionData) {
		if (selection.assessments?.length > 0) {
			for (const assessment of selection.assessments) {
				if (assessment.enabled) {
					if (assessment.type != "grounddata") {
						flightSelectionFormData.append(`Selections[${index}].AnalysisTypeId`, selection.id);
						flightSelectionFormData.append(`Selections[${index}].FlightId`, assessment.id);
						flightSelectionFormData.append(`Selections[${index}].GroundDataAssessmentId`, "");
						flightSelectionFormData.append(`Selections[${index}].GroundDataSetId`, "");
					} else {
						flightSelectionFormData.append(
							`Selections[${index}].GroundDataAssessmentId`,
							assessment.groundDataAssessmentId
						);
						flightSelectionFormData.append(`Selections[${index}].GroundDataSetId`, assessment.id);
						flightSelectionFormData.append(`Selections[${index}].FlightId`, "");
						flightSelectionFormData.append(`Selections[${index}].AnalysisTypeId`, "");
					}
					flightSelectionFormData.append(`Selections[${index}].AnalysisId`, "");
					flightSelectionFormData.append(`Selections[${index}].CurveModelAnalysisId`, "");
					flightSelectionFormData.append(`Selections[${index}].ClientId`, clientId);
					flightSelectionFormData.append(`Selections[${index}].TrialId`, trialId);
					flightSelectionFormData.append(`Selections[${index}].Enabled`, assessment.enabled);
					flightSelectionFormData.append(`Selections[${index}].Checked`, assessment.checked);
					flightSelectionFormData.append(
						`Selections[${index}].QuantifiedRegionTypeId`,
						selection.quantifiedRegionTypeOptionId ?? ""
					);
					index++;
				}
			}
		} else if (selection.curveModelAnalysisId) {
			flightSelectionFormData.append(`Selections[${index}].AnalysisTypeId`, selection.analysisTypeId);
			flightSelectionFormData.append(`Selections[${index}].FlightId`, "");
			flightSelectionFormData.append(`Selections[${index}].GroundDataAssessmentId`, "");
			flightSelectionFormData.append(`Selections[${index}].GroundDataSetId`, "");
			flightSelectionFormData.append(`Selections[${index}].AnalysisId`, selection.analysisId);
			flightSelectionFormData.append(`Selections[${index}].CurveModelAnalysisId`, selection.curveModelAnalysisId);
			flightSelectionFormData.append(`Selections[${index}].ClientId`, clientId);
			flightSelectionFormData.append(`Selections[${index}].TrialId`, trialId);
			flightSelectionFormData.append(`Selections[${index}].Enabled`, selection.enabled);
			flightSelectionFormData.append(`Selections[${index}].Checked`, selection.checked);
			index++;
		}
	}

	return fetch(`${aerialApiUri}api/v1/Trials/AssessmentSelectionForSummaryTable`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: flightSelectionFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function updateTrial(trial, accessToken) {
	const trialFormData = new FormData();
	for (const name in trial) {
		if (name !== "treatments") {
			let val = trial[name] === "" ? null : trial[name];

			//-- Need to specifically check for the name isEstimatedPlantDate because it has the word "date" in it but it should not be treated as a date
			if (name.match(/date/gim) && name !== "isEstimatedPlantDate" && val) {
				val = val.toLocaleDateString();
			}

			if (val) {
				if (typeof val === "string") val = val?.trim();
				trialFormData.set(name, val);
			}
		}
	}

	return fetch(`${aerialApiUri}api/v1/Trials`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: trialFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

// export function updateTrialTreatments(treatments, clientId, accessToken) {
// 	let index = 0;
// 	const treatmentFormData = new FormData();

// 	for (const tr of treatments) {
// 		treatmentFormData.append(`Treatments[${index}].TreatmentId`, tr.treatmentId);
// 		treatmentFormData.append(`Treatments[${index}].TrialTreatmentId`, tr.trialTreatmentId);
// 		treatmentFormData.append(`Treatments[${index}].TreatmentName`, tr.treatmentName);
// 		treatmentFormData.append(`Treatments[${index}].TreatmentDescription`, tr.treatmentDescription ?? "");
// 		treatmentFormData.append(
// 			`Treatments[${index}].TreatmentTypeId`,
// 			!tr.treatmentTypeId || tr.treatmentTypeId === "" || tr.treatmentTypeId === "null" ? null : tr.treatmentTypeId
// 		);
// 		index++;
// 	}

// 	return fetch(`${aerialApiUri}api/v1/Trials/UpdateTrialTreatments?clientId=${clientId}`, {
// 		method: "PUT",
// 		headers: {
// 			Authorization: `Bearer ${accessToken}`
// 		},
// 		body: treatmentFormData
// 	})
// 		.then((res) => res.json())
// 		.then((data) => data);
// }

export function publishTrialFlight(clientId, trialId, flightId, trialOwnerUserId, accessToken) {
	let apiUrl = `${aerialApiUri}api/v1/Trials/PublishTrialFlight?clientId=${clientId}&trialId=${trialId}&flightId=${flightId}&trialOwnerUserId=${trialOwnerUserId}`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function publishTrialFlights(
	clientId,
	trialId,
	trialName,
	flights,
	trialOwnerUserId,
	flightScheduleId,
	doNotPublishTrialFlights,
	accessToken
) {
	let obj = {
		clientId: clientId,
		trialId: trialId,
		trialName: trialName,
		trialOwnerUserId: trialOwnerUserId,
		trialFlightsToPublish: flights,
		doNotPublishTrialFlights: doNotPublishTrialFlights,
		flightScheduleId: flightScheduleId
	};

	let apiUrl = `${aerialApiUri}api/v1/Trials/PublishTrialFlights`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(obj)
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function publishTrialFlightsToTrialSponsor(
	clientId,
	trialId,
	trialName,
	flights,
	trialSponsorUserId,
	flightScheduleId,
	doNotPublishTrialFlights,
	accessToken
) {
	let obj = {
		clientId: clientId,
		trialId: trialId,
		trialName: trialName,
		trialSponsorUserId: trialSponsorUserId,
		trialFlightsToPublish: flights,
		doNotPublishTrialFlights: doNotPublishTrialFlights,
		flightScheduleId: flightScheduleId
	};

	let apiUrl = `${aerialApiUri}api/v1/Trials/PublishTrialFlightsToTrialSponsor`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(obj)
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function publishTrialFlightToTrialSponsor(clientId, trialId, flightId, trialSponsorId, accessToken) {
	let apiUrl = `${aerialApiUri}api/v1/Trials/PublishTrialFlightToTrialSponsor?clientId=${clientId}&trialId=${trialId}&flightId=${flightId}&trialSponsorUserId=${trialSponsorId}`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function unpublishTrialFlights(clientId, trialId, flightIds, accessToken) {
	let obj = { clientId: clientId, trialId: trialId, flightIds: flightIds };
	console.log(obj);

	let apiUrl = `${aerialApiUri}api/v1/Trials/UnpublishTrialsFlights`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(obj)
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function unpublishTrialFlight(clientId, trialId, flightId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/UnpublishTrialFlight?clientId=${clientId}&trialId=${trialId}&flightId=${flightId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function unpublishTrialFlightForTrialSponsor(clientId, trialId, flightId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/UnpublishTrialFlightForTrialSponsor?clientId=${clientId}&trialId=${trialId}&flightId=${flightId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function unpublishTrialFlightsForTrialSponsor(clientId, trialId, flightIds, accessToken) {
	let obj = { clientId: clientId, trialId: trialId, flightIds: flightIds };
	console.log(obj);

	let apiUrl = `${aerialApiUri}api/v1/Trials/UnpublishTrialFlightsForTrialSponsor`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(obj)
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getTrialsPublishedToClientOrUser(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetTrialsPublishedToClientOrUser?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function savePublishedTrialFlightClientIdAssignments(assignments, accessToken) {
	const assignmentsFormData = new FormData();

	let index = 0;
	for (const ass of assignments) {
		assignmentsFormData.append(`Assignments[${index}].TrialId`, ass.trialId);
		assignmentsFormData.append(`Assignments[${index}].FlightId`, ass.flightId);
		assignmentsFormData.append(`Assignments[${index}].PublishedToClientId`, ass.assignedToClientId);
		index++;
	}

	return fetch(`${aerialApiUri}api/v1/Trials/SetPublishedTrialFlightClientId`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: assignmentsFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getTrialsPublishedByClient(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetTrialsPublishedByClient?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function createDefaultViewerSettingForTrial(
	clientId,
	trialId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	const newDefaultViewerSettingFormData = new FormData();
	newDefaultViewerSettingFormData.set("trialId", trialId ?? "");
	newDefaultViewerSettingFormData.set("toggleHeatmapData", toggleHeatmapData ?? "");
	newDefaultViewerSettingFormData.set("toggleImageType", toggleImageType ?? "");
	newDefaultViewerSettingFormData.set("viewableFlightId", viewableFlightId ?? "");
	newDefaultViewerSettingFormData.set("viewableOrthoImageTypeId", viewableOrthoImageTypeId ?? "");
	newDefaultViewerSettingFormData.set("groundDatasetId", groundDatasetId ?? "");
	newDefaultViewerSettingFormData.set("groundDataAssessmentId", groundDataAssessmentId ?? "");
	newDefaultViewerSettingFormData.set("curveModelAnalysisId", curveModelAnalysisId ?? "");
	newDefaultViewerSettingFormData.set("analysisIdForCurveModel", analysisIdForCurveModel ?? "");
	newDefaultViewerSettingFormData.set("analysisId", analysisId ?? "");
	newDefaultViewerSettingFormData.set("analysisFlightId", analysisFlightId ?? "");

	return fetch(`${aerialApiUri}api/v1/Trials/CreateDefaultViewerSettingForTrial?clientId=${clientId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: newDefaultViewerSettingFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function updateDefaultViewerSettingForTrial(
	clientId,
	id,
	trialId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	const targetDefaultViewerSettingFormData = new FormData();
	targetDefaultViewerSettingFormData.set("id", id ?? "");
	targetDefaultViewerSettingFormData.set("trialId", trialId ?? "");
	targetDefaultViewerSettingFormData.set("toggleHeatmapData", toggleHeatmapData ?? "");
	targetDefaultViewerSettingFormData.set("toggleImageType", toggleImageType ?? "");
	targetDefaultViewerSettingFormData.set("viewableFlightId", viewableFlightId ?? "");
	targetDefaultViewerSettingFormData.set("viewableOrthoImageTypeId", viewableOrthoImageTypeId ?? "");
	targetDefaultViewerSettingFormData.set("groundDatasetId", groundDatasetId ?? "");
	targetDefaultViewerSettingFormData.set("groundDataAssessmentId", groundDataAssessmentId ?? "");
	targetDefaultViewerSettingFormData.set("curveModelAnalysisId", curveModelAnalysisId ?? "");
	targetDefaultViewerSettingFormData.set("analysisIdForCurveModel", analysisIdForCurveModel ?? "");
	targetDefaultViewerSettingFormData.set("analysisId", analysisId ?? "");
	targetDefaultViewerSettingFormData.set("analysisFlightId", analysisFlightId ?? "");

	return fetch(`${aerialApiUri}api/v1/Trials/UpdateDefaultViewerSettingForTrial?clientId=${clientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: targetDefaultViewerSettingFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function setPublishedTrialFlightViewed(qsPtfk, accessToken) {
	let apiUrl = `${aerialApiUri}api/v1/Trials/SetPublishedTrialFlightViewed?publishedKey=${qsPtfk}`;

	return fetch(apiUrl, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getTrialPublishingData(clientId, trialId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetTrialPublishingData?clientId=${clientId}&trialId=${trialId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getBulkTrialPublishingData(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetBulkTrialPublishingData?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export async function publishBulkTrialFlights(clientId: string, filteredBulkTrialData: any, accessToken: string) {
	let bulkTrialDataList: any[] = [];
	_.map(filteredBulkTrialData, (fbtd) => {
		let obj = {
			clientId: clientId,
			trialId: fbtd.trialId,
			trialName: fbtd.trialName,
			trialOwnerUserId: fbtd.trialOwnerUserId,
			trialSponsorUserId: fbtd.trialSponsorUserId,
			trialFlightsToPublish: fbtd.unPublishedTrialFlights,
			flightScheduleId: fbtd.flightScheduleId
		};
		bulkTrialDataList.push(obj);
	});

	let apiUrl = `${aerialApiUri}api/v1/Trials/PublishBulkTrialFlights`;

	const res = await fetch(apiUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(bulkTrialDataList)
	});
	const data = await res.json();
	return data;
}

export function setTrialOwnerEmailAddress(
	emailAddress,
	trialId,
	clientId,
	trialOwnerClientId,
	trialOwnerChangingTrialOwner,
	publishedToTrialOwner,
	fieldResearcherUserId,
	trialSponsorUserId,
	accessToken
) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/SetTrialOwnerEmailAddress
			?emailAddress=${emailAddress}
			&trialId=${trialId}
			&clientId=${clientId}
			${trialOwnerClientId ? `&trialOwnerClientId=${trialOwnerClientId}` : ""}
			&trialOwnerChangingTrialOwner=${trialOwnerChangingTrialOwner}
			&published=${publishedToTrialOwner}
			&fieldResearcherUserId=${fieldResearcherUserId}
			${trialSponsorUserId ? `&trialSponsorUserId=${trialSponsorUserId}` : ""}`,
		{
			method: "PUT",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function setTrialSponsorEmailAddress(
	emailAddress,
	trialId,
	clientId,
	trialSponsorClientId,
	trialSponsorChangingTrialSponsor,
	publishedToTrialSponsor,
	fieldResearcherUserId,
	trialOwnerUserId,
	accessToken
) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/SetTrialSponsorEmailAddress
			?emailAddress=${emailAddress}
			&trialId=${trialId}
			&clientId=${clientId}
			${trialSponsorClientId ? `&trialSponsorClientId=${trialSponsorClientId}` : ""}
			&trialSponsorChangingTrialSponsor=${trialSponsorChangingTrialSponsor}
			&published=${publishedToTrialSponsor}
			&fieldResearcherUserId=${fieldResearcherUserId}
			${trialOwnerUserId ? `&trialOwnerUserId=${trialOwnerUserId}` : ""}`,
		{
			method: "PUT",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function sendNewUserEmail(emailAddress, clientName, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/SendNewUserEmail?emailAddress=${emailAddress}&clientName=${clientName}&clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

// export function getSoilFertilityOptions(clientId, accessToken) {
// 	return fetch(`${aerialApiUri}api/v1/Trials/GetSoilFertilityOptions?clientId=${clientId}`, {
// 		method: "GET",
// 		headers: {
// 			Authorization: `Bearer ${accessToken}`
// 		}
// 	})
// 		.then((res) => res.json())
// 		.then((data) => data);
// }

// export function getSoilFertilityDataForTrial(trialId, clientId, accessToken) {
// 	return fetch(`${aerialApiUri}api/v1/Trials/GetSoilFertilityDataForTrial?trialId=${trialId}&clientId=${clientId}`, {
// 		method: "GET",
// 		headers: {
// 			Authorization: `Bearer ${accessToken}`
// 		}
// 	})
// 		.then((res) => res.json())
// 		.then((data) => data);
// }

// export function createSoilFertilityDataFromTrial(soilFertility, trialId, clientId, accessToken) {
// 	const soilFertilityFormData = new FormData();

// 	soilFertilityFormData.set("FertilityId", soilFertility.fertilityId);
// 	soilFertilityFormData.set("DrainageId", soilFertility.drainageId);
// 	soilFertilityFormData.set("SoilSeriesName", soilFertility.soilSeriesName);
// 	soilFertilityFormData.set("TextureId", soilFertility.textureId);
// 	soilFertilityFormData.set("SandRatio", soilFertility.sandRatio);
// 	soilFertilityFormData.set("SiltRatio", soilFertility.siltRatio);
// 	soilFertilityFormData.set("ClayRatio", soilFertility.clayRatio);
// 	soilFertilityFormData.set("OMRatio", soilFertility.omRatio);
// 	soilFertilityFormData.set("pHLevel", soilFertility.pHLevel);
// 	soilFertilityFormData.set("CECLevel", soilFertility.cecLevel);
// 	soilFertilityFormData.set("nitrogenLevel", soilFertility.nitrogenLevel);
// 	soilFertilityFormData.set("phosphorusLevel", soilFertility.phosphorusLevel);
// 	soilFertilityFormData.set("potassiumLevel", soilFertility.potassiumLevel);

// 	return fetch(
// 		`${aerialApiUri}api/v1/Trials/CreateSoilFertilityDataFromTrial?trialId=${trialId}&clientId=${clientId}`,
// 		{
// 			method: "POST",
// 			headers: {
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: soilFertilityFormData
// 		}
// 	)
// 		.then((res) => res.json())
// 		.then((data) => data);
// }

// export function updateSoilFertilityData(soilFertility, soilFertilityId, clientId, accessToken) {
// 	const soilFertilityFormData = new FormData();

// 	soilFertilityFormData.set("FertilityId", soilFertility.fertilityId);
// 	soilFertilityFormData.set("DrainageId", soilFertility.drainageId);
// 	soilFertilityFormData.set("SoilSeriesName", soilFertility.soilSeriesName);
// 	soilFertilityFormData.set("TextureId", soilFertility.textureId);
// 	soilFertilityFormData.set("SandRatio", soilFertility.sandRatio);
// 	soilFertilityFormData.set("SiltRatio", soilFertility.siltRatio);
// 	soilFertilityFormData.set("ClayRatio", soilFertility.clayRatio);
// 	soilFertilityFormData.set("OMRatio", soilFertility.omRatio);
// 	soilFertilityFormData.set("pHLevel", soilFertility.pHLevel);
// 	soilFertilityFormData.set("CECLevel", soilFertility.cecLevel);
// 	soilFertilityFormData.set("nitrogenLevel", soilFertility.nitrogenLevel);
// 	soilFertilityFormData.set("phosphorusLevel", soilFertility.phosphorusLevel);
// 	soilFertilityFormData.set("potassiumLevel", soilFertility.potassiumLevel);

// 	return fetch(
// 		`${aerialApiUri}api/v1/Trials/UpdateSoilFertilityData?soilFertilityId=${soilFertilityId}&clientId=${clientId}`,
// 		{
// 			method: "PUT",
// 			headers: {
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: soilFertilityFormData
// 		}
// 	)
// 		.then((res) => res.json())
// 		.then((data) => data);
// }

export function getTrialPlotsForMapping(trialId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetTrialPlotsForMapping?trialId=${trialId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getMetadataForTrials(trials, currentClientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/GetMetadataForTrials?clientId=${currentClientId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(trials)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getPlotAnalysisStatusForTrial(
	trialId,
	clientId,
	analysisId,
	curveModelAnalysisId,
	checkFlights,
	checkCurveModels,
	accessToken
) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/GetPlotAnalysisStatusForTrial
		?trialId=${trialId}
		&clientId=${clientId}
		&analysisId=${analysisId ?? ""}
		&curveModelAnalysisId=${curveModelAnalysisId ?? ""}
		&checkFlights=${checkFlights}
		&checkCurveModels=${checkCurveModels}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function updateTrialTreatments(clientId, treatments, accessToken) {
	const treatmentFormData = new FormData();

	treatmentFormData.set("ClientId", clientId);
	let index = 0;
	for (const tr of treatments) {
		treatmentFormData.append(`Treatments[${index}].TreatmentId`, tr.treatmentId);
		treatmentFormData.append(`Treatments[${index}].TrialTreatmentId`, tr.trialTreatmentId);
		treatmentFormData.append(`Treatments[${index}].TreatmentName`, tr.treatmentName);
		treatmentFormData.append(`Treatments[${index}].TreatmentDescription`, tr.treatmentDescription);
		treatmentFormData.append(
			`Treatments[${index}].TreatmentTypeId`,
			!tr.treatmentTypeId || tr.treatmentTypeId === "" || tr.treatmentTypeId === "null" ? null : tr.treatmentTypeId
		);
		index++;
	}

	return fetch(`${aerialApiUri}api/v1/Trials/UpdateTrialTreatments`, {
		method: "PUT",
		headers: { Authorization: `Bearer ${accessToken}` },
		body: treatmentFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

// For use with redux action
export function getAnnotationSummaryForTrial(trialId, currentClientId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/Trials/GetAnnotationSummaryForTrial?trialId=${trialId}&clientId=${currentClientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

// For use with useQuery
export async function getAnnotationSummaryForTrialAsync(
	trialId: string,
	currentClientId: string,
	accessToken: string
): Promise<AerialPlotResponse<any>> {
	const res = await fetch(
		`${aerialApiUri}api/v1/Trials/GetAnnotationSummaryForTrial?trialId=${trialId}&clientId=${currentClientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	);
	if (!res.ok) {
		throw new Error("failed to fetch annotation summary");
	}
	const data = res.json();

	return data;
}

export type MetaTagOptions = {
	trialDataQualities: Option[];
	trialExclusionStatuses: Option[];
	trialSiteResponses: Option[];
	userReviewStatuses: Option[];
};

export async function getTrialMetaTagOptions(
	clientId: string,
	accessToken: string
): Promise<AerialPlotResponse<MetaTagOptions>> {
	const res = await fetch(`${aerialApiUri}api/v1/Trials/GetTrialMetaTagOptions?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	});
	if (!res.ok) {
		throw new Error("failed to fetch options");
	}
	const data = res.json();
	return data;
}

export type MetaFactorData = {
	trialDataQualityId: string;
	trialExclusionStatusId: string;
	trialSiteResponseId: string;
	userReviewStatusId: string;
	reviewedByUserName: string;
	reviewedDateTimeUtc: Date;
	notes: string;
	hasExcludedSubsample: boolean;
	countPlotsFlagged: number;
	countPlotsExcluded: number;
	countRepsExcluded: number;
};

export async function getTrialMetaFactorData(
	trialId: string,
	clientId: string,
	accessToken: string
): Promise<AerialPlotResponse<MetaFactorData>> {
	const res = await fetch(
		`${aerialApiUri}api/v1/Trials/GetTrialMetaFactorData?clientId=${clientId}&trialId=${trialId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	);
	if (!res.ok) {
		throw new Error("failed to fetch meta factors");
	}
	const data = res.json();
	return data;
}

export type MetaTagsUpdateInfo = {
	trialId: string;
	trialDataQualityId: string;
	trialExclusionStatusId: string;
	trialSiteResponseId: string;
	userReviewStatusId: string | null;
	trialNotes: string;
	trialName?: string;
	isForProtocol?: boolean;
};

export async function updateTrialMetaTags(
	updateInfo: MetaTagsUpdateInfo,
	clientId: string,
	accessToken: string
): Promise<AerialPlotResponse<void>> {
	const updateInfoFormData = new FormData();
	for (const name in updateInfo) {
		if (name !== "treatments") {
			let val = updateInfo[name] === "" ? null : updateInfo[name];

			//-- Need to specifically check for the name isEstimatedPlantDate because it has the word "date" in it but it should not be treated as a date
			if (name.match(/date/gim) && name !== "isEstimatedPlantDate" && val) {
				val = val.toLocaleDateString();
			}

			if (val) {
				updateInfoFormData.set(name, val);
			}
		}
	}

	const res = await fetch(`${aerialApiUri}api/v1/Trials/UpdateTrialMetaTags?clientId=${clientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: updateInfoFormData
	});
	if (!res.ok) {
		throw new Error("failed to update meta tags");
	}
	const data = res.json();
	return data;
}

export function removeTrialOwner(trialId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/RemoveTrialOwner?trialId=${trialId}&clientId=${clientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function removeTrialSponsor(trialId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Trials/RemoveTrialSponsor?trialId=${trialId}&clientId=${clientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}
