import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import { Segment, Table, Button, Grid, Loader, Modal, Message, Checkbox, Form } from "semantic-ui-react";

import _ from "lodash";

import * as curveModelsActions from "../../../../redux/actions/curveModelsActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";

import * as areauUnderTheCurveFunctions from "../../../Lumber/AreaUnderTheCurve";

import TimecourseComponent from "../../../Lumber/Timecourse";
import FlightSelection from "./FlightSelection";
import * as tableShader from "../../../Lumber/TableShader";
import { toast } from "react-toastify";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const CurveData = ({
	curveModelAnalyses,
	plotDataResults,
	timecourseData,
	trialData,
	viewPlantFlight,
	viewHarvestFlight,
	currentAnalysis,
	currentAnalysisType,
	allTimecourseData,
	flightsInCurveModel,
	curveFitFunctions,
	analysisTypes,
	curveModelBatchAssessments,
	curveModels,
	activeItem,
	getCurveModelData,
	allMeanComparisonOptions,
	flightSelections,
	gduByDays,
	useGDUCurveData,
	quantifiedRegionTypeOptions,
	currentCurveModelFitFunctionId
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();

	//-- Datasources
	const [analyses, setAnalyses] = useState([]);
	const [plotData, setPlotData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [selectedFlights, setSelectedFlights] = useState(null);
	const [errorInPlotData, setErrorInPlotData] = useState(false);
	const [failedInPlotData, setFailedInPlotData] = useState(false);
	const [warningInPlotData, setWarningInPlotData] = useState(false);
	const [flightSelectionAnalysisId, setFlightSelectionAnalysisId] = useState(null);
	const [flightSelectionCurveFitFunctionId, setFlightSelectionCurveFitFunctionId] = useState(null);
	const [hasGduFormula, setHasGduFormula] = useState(false);
	const [selectedMeanComparisonId, setSelectedMeanComparisonId] = useState(null);
	const [selectedAlphaId, setSelectedAlphaId] = useState(null);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [meanComparisonData, setMeanComparisonData] = useState([]);
	const [flightSelectionQuantifiedRegionTypeId, setFlightSelectionQuantifiedRegionTypeId] = useState(null);
	const [flightSelectionAnalysisTypeId, setFlightSelectionAnalysisTypeId] = useState(null);
	const [quantifiedRegionTypeName, setQuantifiedRegionTypeName] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(false);
	const [curveFitModalOpen, setCurveFitModalOpen] = useState(false);
	const [deleteCurveModalOpen, setDeleteCurveModalOpen] = useState(false);
	const [showFlightsWarning, setShowFlightsWarning] = useState(false);
	const [useGDU, setUseGDU] = useState(false);
	const [meanComparisonLoading, setMeanComparisonLoading] = useState(true);
	//constant to enable/disable Weather Data UI Elements
	const weatherDataEnabled = true;

	useEffect(() => {
		if (allMeanComparisonOptions) {
			let initialMeanComparisonId =
				trialData.trialInfo.meanComparisonId ??
				_.find(allMeanComparisonOptions.data.meanComparisonOptions, { name: "Tukey HSD" })?.id;

			let initialAlphaId =
				trialData.trialInfo.alphaId ?? _.find(allMeanComparisonOptions.data.alphaOptions, { value: 0.1 })?.id;

			setSelectedMeanComparisonId(initialMeanComparisonId);
			setSelectedAlphaId(initialAlphaId);

			initializeMeanComparionsOptions();
			initializeAlphaOptions();
		}
	}, [allMeanComparisonOptions]);

	useEffect(() => {
		if (curveModelAnalyses) {
			setAnalyses(curveModelAnalyses);
		}
	}, [curveModelAnalyses]);

	useEffect(() => {
		if (weatherDataEnabled) {
			setUseGDU(useGDUCurveData);
		}
	}, [useGDUCurveData]);

	useEffect(() => {
		if (plotDataResults) {
			setPlotData(plotDataResults);

			//-- Warning Message
			let warningMessage = _.map(
				_.filter(plotDataResults, (pdr) => {
					return pdr.warningMessage !== "" && pdr.warningMessage !== null;
				}),
				"warningMessage"
			);
			warningMessage = _.uniq(warningMessage);

			setWarningInPlotData(warningMessage);

			let allValuesAreZero = _.every(plotDataResults, (pdr) => pdr.value === 0);
			let failedPlotCount = _.filter(plotDataResults, (pdr) => {
				return (pdr.failed && !pdr.isAUCCurveFit && !pdr.isGDU) || allValuesAreZero;
			});
			if (failedPlotCount.length > 0) {
				setFailedInPlotData(true);
			}

			//-- Selected Quantified Region Value
			setQuantifiedRegionTypeName(plotDataResults[0]?.quantifiedRegionTypeName);
			setFlightSelectionQuantifiedRegionTypeId(
				flightSelectionQuantifiedRegionTypeId ?? plotDataResults[0]?.quantifiedRegionTypeId
			);
			setFlightSelectionAnalysisTypeId(
				flightSelectionAnalysisTypeId ?? _.uniqBy(curveModelBatchAssessments, "analysisTypeId")[0]?.analysisTypeId
			);
		}
	}, [plotDataResults]);

	useEffect(() => {
		let error =
			flightSelections?.length === 0 ||
			_.some(flightSelections, (sf) => {
				return sf.error === true;
			});
		setErrorInPlotData(error);
	}, [flightSelections]);

	useEffect(() => {
		if (analyses?.length > 0 && plotData?.length > 0) {
			let treatmentValues = [];
			const orderedPlotData = _.groupBy(plotData, "trialTreatmentId");
			_.map(orderedPlotData, (opd) => {
				let treatment = {
					trialTreatmentId: opd[0]?.trialTreatmentId,
					treatmentName: opd[0]?.treatmentName,
					values: []
				};
				_.map(analyses, (a) => {
					const analysisPlotData = _.filter(opd, (plot) => {
						return plot.curveModelAnalysisId === a.curveModelAnalysisId;
					});
					const value =
						analysisPlotData?.length > 0 ? Number(_.meanBy(analysisPlotData, (apd) => apd.value)).toFixed(5) : "-";
					const treatmentValue = { curveModelAnalysisId: a.curveModelAnalysisId, value: value };
					treatment.values.push(treatmentValue);
				});

				treatmentValues.push(treatment);
			});

			if (treatmentValues?.length > 0) {
				_.map(analyses, (a) => {
					let analysisValues = [];
					_.map(treatmentValues, (tv) => {
						analysisValues = analysisValues.concat(
							_.find(tv.values, (v) => {
								return v.curveModelAnalysisId === a.curveModelAnalysisId;
							})
						);
					});
					if (a.curveModelAnalysisAbbreviation === "MG50") {
						tableShader.calculateShadingForTreatmentReversed(analysisValues, "value", "90, 138, 198");
					} else {
						tableShader.calculateShadingForTreatment(analysisValues, "value", "90, 138, 198");
					}
				});
			}

			setTableData(treatmentValues);
		}
	}, [analyses, plotData]);

	useEffect(() => {
		if (selectedAlphaId && selectedMeanComparisonId && meanComparisonData.length === 0) {
			runMeanComparisonTest();
		}
	}, [selectedAlphaId, selectedMeanComparisonId]);

	useEffect(() => {
		if (currentCurveModelFitFunctionId) {
			setFlightSelectionCurveFitFunctionId(currentCurveModelFitFunctionId);
		}
	}, [currentCurveModelFitFunctionId]);

	function initializeMeanComparionsOptions() {
		let options = _.map(allMeanComparisonOptions.data.meanComparisonOptions, ({ id, name }) => {
			return {
				key: id,
				value: id,
				text: name
			};
		});

		setMeanComparisonOptions(options);
	}

	function initializeAlphaOptions() {
		let options = _.map(allMeanComparisonOptions.data.alphaOptions, ({ id, value }) => {
			return {
				key: id,
				value: id,
				text: value
			};
		});

		setAlphaOptions(options);
	}

	function handleCurveFitModalOpen() {
		setCurveFitModalOpen(!curveFitModalOpen);
	}

	function handleDeleteCurveModalOpen() {
		setDeleteCurveModalOpen(!deleteCurveModalOpen);
	}

	function getSelectedFlights(flights) {
		setSelectedFlights(flights);
		checkFlightWarnings(flights);
	}

	function checkFlightWarnings(flights) {
		if (_.filter(flights, (s) => s.checked)?.length < 5) {
			setShowFlightsWarning(true);
		} else {
			setShowFlightsWarning(false);
		}
	}

	function getHasGduFormula(gdu) {
		setHasGduFormula(gdu);
	}

	function getFlightSelectionAnalysisId(value) {
		setFlightSelectionAnalysisId(value);
	}

	function getFlightSelectionCurveFitFunctionId(value) {
		setFlightSelectionCurveFitFunctionId(value);
	}

	function getFlightSelectionQuantifiedRegionTypeId(value) {
		setFlightSelectionQuantifiedRegionTypeId(value);
	}

	function getFlightSelectionAnalysisTypeId(value) {
		setFlightSelectionAnalysisTypeId(value);
	}

	async function update() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		let flightsToSave = _.filter(selectedFlights, "checked");
		let activeItemName = _.find(curveModels, { id: activeItem })?.name;
		if (flightsToSave?.length > 1) {
			await dispatch(
				curveModelsActions.saveCurveAnalysis(
					moduleNavigation.trialId,
					flightSelectionAnalysisId,
					flightSelectionAnalysisTypeId,
					_.find(analysisTypes, { id: flightSelectionAnalysisTypeId })?.name,
					activeItem,
					userAuth.currentClientId,
					plotDataResults[0]?.curveModelDataAnalysisId,
					flightSelectionCurveFitFunctionId,
					flightsToSave,
					activeItemName,
					useGDU,
					flightSelectionQuantifiedRegionTypeId,
					accessToken
				)
			);
			await dispatch(
				curveModelsActions.runCurveModels(moduleNavigation.trialId, activeItem, userAuth.currentClientId, accessToken)
			)
				.then(() => {
					getCurveModelData();
					handleCurveFitModalOpen();
					toast.success("Curve model data saved successfully.");

					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					if (err?.data?.Message) {
						toast.error(err.data.Message);
					} else {
						toast.error("There was a problem saving this curve model.");
					}
					setLoading(false);
				});
		} else {
			toast.error("Please select two or more flights.");
			setLoading(false);
		}
	}

	async function deleteCurve() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(
			curveModelsActions.deleteCurveAnalysis(
				flightSelections?.find((flight) => flight.analysisId === currentAnalysis.analysisId).curveModelDataAnalysisId,
				userAuth.currentClientId,
				accessToken
			)
		)
			.then(() => {
				getCurveModelData();
				handleCurveFitModalOpen();
				toast.success("Curve model data deleted successfully.");
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("There was a problem deleting this curve model.");
				setLoading(false);
			});
	}

	async function runMeanComparisonTest(fromClick = false) {
		//-- Set table data is getting triggered too many times on load, adding fromClick
		if (meanComparisonData.length === 0 || fromClick) {
			setMeanComparisonLoading(true);

			let requestData = [];

			_.map(analyses, (a) => {
				let analysisSpecificData = _.filter(
					plotData,
					(p) => p.curveModelAnalysisId === a.curveModelAnalysisId && p.analysisId === currentAnalysis.analysisId
				);

				if (analysisSpecificData.length > 0) {
					let analysisData = {
						curveModelAnalysisId: a.curveModelAnalysisId,
						data: _.map(analysisSpecificData, (asd) => {
							return {
								trialTreatmentId: asd.trialTreatmentId,
								plotReplicate: asd.plotReplicate,
								totalAuc: asd.value
							};
						})
					};

					requestData.push(analysisData);
				}
			});

			if (requestData.length > 0) {
				const accessToken = await getTokenSilently();

				await Promise.all(
					_.map(requestData, (r) => {
						return dispatch(
							statisticsActions.getMeanComparisonForAuc(
								{ data: r.data },
								userAuth.currentClientId,
								selectedMeanComparisonId,
								selectedAlphaId,
								false,
								accessToken
							)
						).then((res) => {
							return { curveModelAnalysisId: r.curveModelAnalysisId, data: res };
						});
					})
				)
					.then((res) => {
						setupMeanComparisonData(res);
						setMeanComparisonLoading(false);
					})
					.catch((err) => {
						console.error(err);
						toast.error("Could not run mean comparison tests.");
						setMeanComparisonLoading(false);
					});
			}
		}
	}

	function setupMeanComparisonData(data) {
		let meanComparisons = [];
		_.map(data, (d) => {
			_.map(d.data.letteringPairs, ({ treatmentId, letter }) => {
				meanComparisons.push({
					curveModelAnalysisId: d.curveModelAnalysisId,
					trialTreatmentId: treatmentId,
					letter: letter,
					pValue: d.data.pValue,
					mean: d.data.mean,
					cv: d.data.cv
				});
			});
		});

		checkAllLettersA(meanComparisons);
		setMeanComparisonData(meanComparisons);
	}

	function checkAllLettersA(meanComparisons) {
		_.map(analyses, (a) => {
			let analysisSpecificData = _.filter(meanComparisons, (m) => m.curveModelAnalysisId === a.curveModelAnalysisId);

			if (_.every(_.map(analysisSpecificData, "letter"), (l) => l === "a")) {
				_.map(analysisSpecificData, (asd) => {
					let matchingIndex = _.findIndex(meanComparisons, {
						curveModelAnalysisId: asd.curveModelAnalysisId,
						trialTreatmentId: asd.trialTreatmentId
					});

					meanComparisons[matchingIndex].letter = "-";
				});
			}
		});
	}

	return (
		<Segment basic>
			<Grid>
				<Grid.Row>
					<Grid.Column width="8">
						<Grid>
							<Grid.Row>
								{userAuth.hasApplicationArea(ApplicationArea.TrialCurveModels, userAuth.currentClientId) ||
								userAuth.hasApplicationArea(ApplicationArea.TrialCurveModelsCRUD, userAuth.currentClientId) ? (
									<>
										<Grid.Column width="1">
											<Modal
												open={curveFitModalOpen}
												trigger={
													<Button
														circular
														color="black"
														icon="pencil"
														onClick={handleCurveFitModalOpen}
														disabled={
															(timecourseData?.length === 0 && !trialData) ||
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialCurveModelsCRUD,
																userAuth.currentClientId
															)
														}
													/>
												}
												size="fullscreen"
												centered
											>
												<Modal.Header>Choose Flights for Growth Curve Analysis</Modal.Header>
												<Modal.Content>
													{showFlightsWarning && selectedFlights && (
														<Message warning>
															<Message.Header>
																Selecting less than 5 flights can result in curve fit data being inaccurate or not
																running at all.
															</Message.Header>
														</Message>
													)}
													<FlightSelection
														timecourseData={allTimecourseData}
														trialData={trialData}
														analyses={[].concat(currentAnalysis)}
														getSelectedFlights={getSelectedFlights}
														flightsInCurveModel={flightsInCurveModel}
														curveFitFunctions={curveFitFunctions}
														analysisTypes={analysisTypes}
														curveModelBatchAssessments={curveModelBatchAssessments}
														getFlightSelectionAnalysisId={getFlightSelectionAnalysisId}
														getFlightSelectionCurveFitFunctionId={getFlightSelectionCurveFitFunctionId}
														allMeanComparisonOptions={allMeanComparisonOptions}
														gduByDay={gduByDays}
														quantifiedRegionTypeOptions={quantifiedRegionTypeOptions}
														quantifiedRegionTypeValue={flightSelectionQuantifiedRegionTypeId}
														getFlightSelectionQuantifiedRegionTypeId={getFlightSelectionQuantifiedRegionTypeId}
														getFlightSelectionAnalysisTypeId={getFlightSelectionAnalysisTypeId}
														fitFunctionValue={currentCurveModelFitFunctionId}
													/>
												</Modal.Content>
												<Modal.Actions>
													<Button
														type="button"
														floated="right"
														primary
														onClick={update}
														loading={loading}
														disabled={
															loading ||
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialCurveModelsCRUD,
																userAuth.currentClientId
															)
														}
													>
														Save & Run Curve Fit
													</Button>
													<Button
														type="button"
														floated="right"
														onClick={handleCurveFitModalOpen}
														loading={loading}
														disabled={loading}
													>
														Cancel
													</Button>
													<br style={{ clear: "both" }} />
												</Modal.Actions>
											</Modal>
										</Grid.Column>
										<Grid.Column width="1">
											<Modal
												open={deleteCurveModalOpen}
												trigger={
													<Button
														circular
														icon="minus"
														color="black"
														onClick={handleDeleteCurveModalOpen}
														disabled={
															(timecourseData?.length === 0 && !trialData) ||
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialCurveModelsCRUD,
																userAuth.currentClientId
															)
														}
													/>
												}
												centered
											>
												<Modal.Header>Delete Curve Model Analysis</Modal.Header>
												<Modal.Content>
													<p>Are you sure you want to delete this curve model analysis?</p>
												</Modal.Content>
												<Modal.Actions>
													<Button
														type="button"
														floated="right"
														negative
														onClick={deleteCurve}
														loading={loading}
														disabled={
															loading ||
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialCurveModelsCRUD,
																userAuth.currentClientId
															)
														}
													>
														Delete
													</Button>
													<Button
														type="button"
														floated="right"
														onClick={handleDeleteCurveModalOpen}
														loading={
															loading ||
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialCurveModelsCRUD,
																userAuth.currentClientId
															)
														}
														disabled={loading}
													>
														Cancel
													</Button>
													<br style={{ clear: "both" }} />
												</Modal.Actions>
											</Modal>
										</Grid.Column>
									</>
								) : null}
								<Grid.Column width="12" verticalAlign="middle">
									<h3>
										{currentAnalysis?.orthoImageTypeName} - {quantifiedRegionTypeName} - {currentAnalysisType} -{" "}
										{_.find(curveFitFunctions, { id: currentCurveModelFitFunctionId })?.name}
									</h3>
								</Grid.Column>
							</Grid.Row>
							{weatherDataEnabled && hasGduFormula && trialData?.trialInfo?.plantDate ? (
								<Grid.Column width="7">
									<Checkbox
										toggle
										checked={useGDU}
										label={"Use Growing Degree Units For Graph"}
										onChange={(e, { checked }) => {
											setUseGDU(checked);
										}}
									/>
								</Grid.Column>
							) : (
								<Grid.Column width="7">
									<Grid.Row>
										<Grid.Column width={"2"}>
											<Checkbox toggle readOnly={true} label={"Use Growing Degree Units For Graph"} />
											{areauUnderTheCurveFunctions.buildGduErrorPopup(hasGduFormula, trialData?.trialInfo?.plantDate)}
										</Grid.Column>
									</Grid.Row>
								</Grid.Column>
							)}
						</Grid>
					</Grid.Column>
					<Grid.Column width="3" floated="right" verticalAlign="bottom">
						<Form>
							<Form.Select
								id="select-mean-comparison"
								label="Mean Comparison"
								selection
								disabled={meanComparisonLoading}
								options={meanComparisonOptions}
								value={selectedMeanComparisonId}
								onChange={(e, { value }) => {
									setSelectedMeanComparisonId(value);
								}}
							/>
						</Form>
					</Grid.Column>
					<Grid.Column width="3" verticalAlign="bottom">
						<Form>
							<Form.Select
								id="select-alpha-value"
								label="Alpha Value"
								selection
								disabled={meanComparisonLoading}
								options={alphaOptions}
								value={selectedAlphaId}
								onChange={(e, { value }) => {
									setSelectedAlphaId(value);
								}}
							/>
						</Form>
					</Grid.Column>
					<Grid.Column width="2" verticalAlign="bottom">
						<Button
							primary
							disabled={meanComparisonLoading}
							onClick={() => runMeanComparisonTest(true)}
							style={{ marginBottom: "2px" }}
						>
							Run Mean Comparison Test
						</Button>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="16">
						{errorInPlotData ? (
							<Message error content="Growth Curve Analysis failed to run." style={{ marginTop: "unset" }} />
						) : failedInPlotData ? (
							<Message
								warning
								content="Growth Curve Analysis ran with some failed plots."
								style={{ marginTop: "unset" }}
							/>
						) : null}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="16">
						{warningInPlotData?.length > 0 ? (
							<Message warning list={warningInPlotData} style={{ marginTop: "unset" }} />
						) : null}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="8" style={{ marginTop: -60 }}>
						{timecourseData?.length > 0 && trialData && allMeanComparisonOptions ? (
							<TimecourseComponent
								showAnalysisSelection={false}
								showTable={false}
								showLineChart={true}
								showBoxWhisker={false}
								runMeanComparison={false}
								analysisDataResults={timecourseData}
								trialDataResults={trialData}
								viewPlantFlight={viewPlantFlight}
								viewHarvestFlight={viewHarvestFlight}
								allMeanComparisonOptions={allMeanComparisonOptions}
								gduList={gduByDays}
								useGDUTimecourse={useGDU}
								getHasGduFormula={getHasGduFormula}
								parentSelectedAnalysisType={timecourseData[0]?.analysisTypeId}
								isForAnalysisTimecoursePage={false}
							/>
						) : (
							<Loader active />
						)}
					</Grid.Column>
					<Grid.Column width="8" style={{ overflow: "auto", maxHeight: "500px", marginTop: "-46px" }}>
						<Table celled striped sortable selectable color="blue" textAlign="center">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Trt</Table.HeaderCell>
									<Table.HeaderCell>Name</Table.HeaderCell>
									{_.map(analyses, (a) => {
										return (
											<Table.HeaderCell key={a.curveModelAnalysisId}>
												{a.curveModelAnalysisName}
												<br />({a.curveModelAnalysisAbbreviation}_{plotData[0]?.orthoImageTypeName})
											</Table.HeaderCell>
										);
									})}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(tableData, ({ trialTreatmentId, treatmentName, values }) => {
									return (
										<Table.Row key={trialTreatmentId}>
											<Table.Cell>{trialTreatmentId}</Table.Cell>
											<Table.Cell>{treatmentName}</Table.Cell>
											{_.map(values, ({ curveModelAnalysisId, value, color }) => {
												return (
													<Table.Cell
														key={curveModelAnalysisId}
														style={{ backgroundColor: color, paddingTop: "2px", paddingBottom: "2px" }}
													>
														<Grid>
															<Grid.Column width="6">{value}</Grid.Column>
															<Grid.Column width="6" floated="right">
																{meanComparisonLoading ? (
																	<Loader active size="mini" />
																) : (
																	_.find(meanComparisonData, {
																		curveModelAnalysisId: curveModelAnalysisId,
																		trialTreatmentId: trialTreatmentId
																	})?.letter
																)}
															</Grid.Column>
														</Grid>
													</Table.Cell>
												);
											})}
										</Table.Row>
									);
								})}
								<Table.Row>
									<Table.Cell colSpan="2" textAlign="left">
										Mean
									</Table.Cell>
									{_.map(tableData[0]?.values, (v) => {
										return (
											<Table.Cell key={v.curveModelAnalysisId}>
												{/* Conditional formatting to <0.00001 if number exists and is 0 */}
												{meanComparisonLoading
													? "-"
													: Number(
															Number(
																_.find(meanComparisonData, { curveModelAnalysisId: v.curveModelAnalysisId })?.mean
															).toFixed(5)
													  ) !== 0
													? Number(
															_.find(meanComparisonData, { curveModelAnalysisId: v.curveModelAnalysisId })?.mean
													  ).toFixed(5)
													: "<0.00001"}
											</Table.Cell>
										);
									})}
								</Table.Row>
								<Table.Row>
									<Table.Cell colSpan="2" textAlign="left">
										CV
									</Table.Cell>
									{_.map(tableData[0]?.values, (v) => {
										return (
											<Table.Cell key={v.curveModelAnalysisId}>
												{/* Conditional formatting to <0.00001 if number exists and is 0 */}
												{meanComparisonLoading
													? "-"
													: Number(
															Number(
																_.find(meanComparisonData, { curveModelAnalysisId: v.curveModelAnalysisId })?.cv
															).toFixed(5)
													  ) !== 0
													? Math.abs(
															Number(_.find(meanComparisonData, { curveModelAnalysisId: v.curveModelAnalysisId })?.cv)
													  ).toFixed(5)
													: "<0.00001"}
											</Table.Cell>
										);
									})}
								</Table.Row>
								<Table.Row>
									<Table.Cell colSpan="2" textAlign="left">
										P-Value
									</Table.Cell>
									{_.map(tableData[0]?.values, (v) => {
										return (
											<Table.Cell key={v.curveModelAnalysisId}>
												{/* Conditional formatting to <0.00001 if number exists and is 0 */}
												{meanComparisonLoading
													? "-"
													: Number(
															Number(
																_.find(meanComparisonData, { curveModelAnalysisId: v.curveModelAnalysisId })?.pValue
															).toFixed(5)
													  ) !== 0
													? Number(
															_.find(meanComparisonData, { curveModelAnalysisId: v.curveModelAnalysisId })?.pValue
													  ).toFixed(5)
													: "<0.00001"}
											</Table.Cell>
										);
									})}
								</Table.Row>
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

CurveData.propTypes = {
	curveModelAnalyses: PropTypes.array,
	plotDataResults: PropTypes.array,
	timecourseData: PropTypes.array,
	trialData: PropTypes.object,
	viewPlantFlight: PropTypes.bool,
	viewHarvestFlight: PropTypes.bool,
	currentAnalysis: PropTypes.object,
	currentAnalysisType: PropTypes.string,
	allTimecourseData: PropTypes.array,
	flightsInCurveModel: PropTypes.array,
	curveFitFunctions: PropTypes.array,
	curveModels: PropTypes.array,
	activeItem: PropTypes.string,
	getCurveModelData: PropTypes.func,
	allMeanComparisonOptions: PropTypes.object,
	flightSelections: PropTypes.array,
	gduByDays: PropTypes.array,
	useGDUCurveData: PropTypes.bool,
	quantifiedRegionTypeOptions: PropTypes.array,
	analysisTypes: PropTypes.array,
	curveModelBatchAssessments: PropTypes.array,
	currentCurveModelFitFunctionId: PropTypes.string
};

export default CurveData;
