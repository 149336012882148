import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, Loader, Message, Segment, TextArea } from "semantic-ui-react";
import { TrialContext } from "../../../../hooks/TrialContext";

type Props = {
	trialId: string;
	trialName: string;
	navigateToNextStep: () => {};
	navigateToPreviousStep: () => {};
	setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
	setSaveAction: React.Dispatch<React.SetStateAction<() => Promise<any>>>;
};

const TrialMetaTags: FC<Props> = ({
	trialId,
	navigateToNextStep,
	navigateToPreviousStep,
	setIsDirty,
	setSaveAction
}) => {
	const { getMetaTagOptionsQuery, getMetaFactorDataQuery, updateMetaTagsMutator } = useContext(TrialContext);

	const { isLoading: optionsLoading, data: metaTagOptions } = getMetaTagOptionsQuery();
	const { isFetching: dataLoading, data: metaFactorData } = getMetaFactorDataQuery(trialId, [metaTagOptions]);
	const { mutate: updateMetaTags, isPending: saving } = updateMetaTagsMutator();

	const [trialDataQualityId, setTrialDataQualityId] = useState("");
	const [trialExclusionStatusId, setTrialExclusionStatusId] = useState("");
	const [trialSiteResponseId, setTrialSiteResponseId] = useState("");
	const [userReviewStatusId, setUserReviewStatusId] = useState("");
	const [trialNotes, setTrialNotes] = useState("");
	const [notesError, setNotesError] = useState(false);

	const loading = useMemo(() => optionsLoading && dataLoading, [optionsLoading, dataLoading]);

	const dataStatusOptions = useMemo(
		() =>
			metaTagOptions?.trialExclusionStatuses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const dataQualityOptions = useMemo(
		() =>
			metaTagOptions?.trialDataQualities.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const responseCategoriesOptions = useMemo(
		() =>
			metaTagOptions?.trialSiteResponses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const userReviewStatusOptions = useMemo(
		() =>
			metaTagOptions?.userReviewStatuses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const save = useCallback(async () => {
		if (!notesError) {
			updateMetaTags({
				trialId,
				trialDataQualityId,
				trialExclusionStatusId,
				trialSiteResponseId,
				userReviewStatusId: userReviewStatusId === metaFactorData?.userReviewStatusId ? null : userReviewStatusId,
				trialNotes
			});
			setIsDirty(false);
			return true;
		} else {
			toast.error("Trial notes cannot exceed 200 characters.");
			return false;
		}
	}, [
		trialId,
		trialDataQualityId,
		trialExclusionStatusId,
		trialSiteResponseId,
		userReviewStatusId,
		trialNotes,
		updateMetaTags,
		notesError
	]);

	useEffect(() => {
		if (trialNotes) {
			setNotesError(trialNotes.length > 200);
		}
	}, [trialNotes]);

	useEffect(() => {
		setSaveAction(() => save);
	}, [save]);

	useEffect(() => {
		if (metaFactorData) {
			setTrialDataQualityId(metaFactorData.trialDataQualityId);
			setTrialExclusionStatusId(metaFactorData.trialExclusionStatusId);
			setTrialSiteResponseId(metaFactorData.trialSiteResponseId);
			setUserReviewStatusId(metaFactorData.userReviewStatusId);
			setTrialNotes(metaFactorData.notes);
		}
	}, [metaFactorData]);

	return loading ? (
		<Loader active />
	) : (
		<>
			<div style={{ marginBottom: "1em" }}>
				<div style={{ display: "flex", alignItems: "end", whiteSpace: "nowrap", overflow: "hidden" }}>
					<Button
						style={{ marginRight: 10 }}
						compact
						color="black"
						content="Back"
						disabled={saving}
						onClick={() => {
							navigateToPreviousStep();
						}}
					/>

					<div style={{ flexGrow: 1, minWidth: 10 }} />

					<Button
						id="form-button-save"
						compact
						color="blue"
						content="Save"
						loading={optionsLoading || saving}
						disabled={optionsLoading}
						onClick={() => save()}
					/>
					<Button
						id="form-button-continue"
						compact
						primary
						content="Save and Continue"
						loading={optionsLoading || saving}
						disabled={optionsLoading}
						onClick={() =>
							save().then((res) => {
								if (res) navigateToNextStep();
							})
						}
					/>
				</div>
			</div>
			<Form style={{ padding: "0" }} error={notesError}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Form.Field>
						<Form.Select
							id="trial-meta-tags-exclusion-status"
							label={
								<label>
									Exclusion Status{" "}
									{/* <span>
										<Icon name="info circle" size="small" style={{ position: "relative", top: -8 }} />
									</span> */}
								</label>
							}
							options={dataStatusOptions}
							loading={dataLoading}
							value={trialExclusionStatusId}
							onChange={(_e, { value }) => {
								setIsDirty(true);
								setTrialExclusionStatusId(value as string);
							}}
						/>
					</Form.Field>
					<Form.Field>
						<Form.Select
							id="trial-meta-tags-data-quality"
							label={
								<label>
									Data Quality{" "}
									{/* <span>
										<Icon name="info circle" size="small" style={{ position: "relative", top: -8 }} />
									</span> */}
								</label>
							}
							options={dataQualityOptions}
							loading={dataLoading}
							value={trialDataQualityId}
							onChange={(_e, { value }) => {
								setIsDirty(true);
								setTrialDataQualityId(value as string);
							}}
						/>
					</Form.Field>
					<Form.Field>
						<Form.Select
							id="trial-meta-tags-site-response"
							label={
								<label>
									Site Response{" "}
									{/* <span>
										<Icon name="info circle" size="small" style={{ position: "relative", top: -8 }} />
									</span> */}
								</label>
							}
							options={responseCategoriesOptions}
							loading={dataLoading}
							value={trialSiteResponseId}
							onChange={(_e, { value }) => {
								setIsDirty(true);
								setTrialSiteResponseId(value as string);
							}}
						/>
					</Form.Field>
				</div>
				<div style={{ display: "flex", flexDirection: "row", marginTop: 25 }}>
					<div style={{ fontWeight: "bold", textAlign: "right" }}>
						<div>Has Flags?</div>
						<div>Subsamples Excluded?</div>
						<div>Whole Plots Excluded?</div>
						<div>Whole Reps Excluded?</div>
					</div>
					<div style={{ marginLeft: 15 }}>
						<div>
							{isNaN(metaFactorData?.countPlotsFlagged!) || metaFactorData?.countPlotsFlagged! < 1
								? "No"
								: `Yes, ${metaFactorData?.countPlotsFlagged} plot(s) is/are marked as flagged`}
						</div>
						<div>{metaFactorData?.hasExcludedSubsample ? "Yes" : "No"}</div>
						<div>
							{isNaN(metaFactorData?.countPlotsExcluded!) || metaFactorData?.countPlotsExcluded! < 1
								? "No"
								: `Yes, ${metaFactorData?.countPlotsExcluded} plot(s) is/are marked with exclusions`}
						</div>
						<div>
							{isNaN(metaFactorData?.countRepsExcluded!) || metaFactorData?.countRepsExcluded! < 1
								? "No"
								: `Yes, ${metaFactorData?.countRepsExcluded} replicate(s) is/are marked with exclusions`}
						</div>
					</div>
				</div>
				<div style={{ display: "flex", marginTop: 25 }}>
					<Form.Field>
						<Form.Select
							id="trial-meta-tags-annotation-status"
							label={
								<label>
									Plot Annotation Review Status{" "}
									{/* <span>
										<Icon name="info circle" size="small" style={{ position: "relative", top: -8 }} />
									</span> */}
								</label>
							}
							clearable
							options={userReviewStatusOptions}
							loading={dataLoading}
							value={userReviewStatusId}
							onChange={(_e, { value }) => {
								setIsDirty(true);
								setUserReviewStatusId(value as string);
							}}
						/>
					</Form.Field>
					{metaFactorData?.userReviewStatusId && (
						<div style={{ alignSelf: "end", paddingBottom: "1em" }}>
							Last updated by {metaFactorData?.reviewedByUserName} on{" "}
							{moment.utc(metaFactorData?.reviewedDateTimeUtc).local().format("lll")}
						</div>
					)}
				</div>
				{notesError && <Message error content="Trial notes cannot exceed 200 characters." />}
				<div style={{ fontWeight: "bold", fontSize: 18, lineHeight: 2, marginTop: 15 }}>Trial Notes</div>
				<TextArea
					id="form-text-area-notes"
					defaultValue={trialNotes}
					value={trialNotes}
					onChange={(_, { value }) => {
						setIsDirty(true);
						setTrialNotes(value as string);
					}}
					rows="5"
					style={notesError ? { borderColor: "#e0b4b4", background: "#FFF6F6" } : undefined}
				/>
			</Form>
		</>
	);
};

export default TrialMetaTags;
