import React, { Children, PropsWithChildren, useState } from "react";
import { Button } from "semantic-ui-react";

interface ControlButtonsProps {
	label: string;
}

const ControlButtons = ({ children, label }: PropsWithChildren<ControlButtonsProps>) => {
	const [open, setOpen] = useState(true);

	return (
		<>
			<div className={`controlButtonsLabel${open ? " open" : ""}`} onClick={() => setOpen((prev) => !prev)}>
				{label}
			</div>
			<div className={`controlButtonsContainer${open ? " open" : ""}`}>
				<Button.Group className="controlButtons">{children}</Button.Group>
			</div>
		</>
	);
};

export default ControlButtons;
