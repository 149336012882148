import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Accordion, Dropdown, Grid, Icon, Segment } from "semantic-ui-react";

import * as AnalysisDropDownListFunctions from "./AnalysisDropDownListFunctions";
import { toast } from "react-toastify";

const AnalysisDropDownLists = ({
	rawPresetOptions,
	allAssessmentTypes,
	displayAssessmentTypes = null,
	quantifiedRegionTypes,
	updateSelectedAnalysis,
	updatedSelectedQuantifiedRegion,
	updatedSelectedAnalysisType,
	hideAnalyticTypes = false,
	updatePresetList,
	index,
	defaultAssessment = null,
	useSummaryTableStyles = false,
	initialLoad = false,
	setSelectedAssessmentName,
	isProtocolTimecourse = false,
	isApAdmin = false, //-- including here since admin flag will never be reset without refreshing page
	setSummaryTablePreset = null,
	summaryTableSelections = null,
	heatmap = false,
	loadingStats,
	allMeanComparisonData,
	uniqueAnalysesCount,
	isForAnalysisTimecoursePage,
	setNeedToUpdateAnalytics,
	setNeedToReloadData,
	defaultSelectedAnalysisType,
	defaultSelectedAnalysisOption,
	defaultSelectedQuantifiedRegionTypeOption,
	style = {},
	shouldUpdateAnalysisTypeId = true,
	children = [],
	afterElement
}) => {
	//-- Constants
	const timeSeries = "Aerial Time Series";
	const growthParameters = "Growth Parameters";

	//-- DDL Options
	const [assessmentCategories, setAssessmentCategories] = useState([]);
	const [analysisTypes, setAnalysisTypes] = useState([]);
	const [analysisPresets, setAnalysisPresets] = useState([]);
	const [orthoTypes, setOrthoTypes] = useState([]);

	//-- Filtered DDL Options
	const [filteredAssessmentCategories, setFilteredAssessmentCategories] = useState([]);
	const [filteredAnalysisPresets, setFilteredAnalysisPresets] = useState([]);

	//-- UI Control
	const [accordionActiveIndex, setAccordionActiveIndex] = useState(false);

	//-- Selected Options
	const [selectedPresetAnalyticType, setSelectedPresetAnalyticType] = useState(null);
	const [selectedPreset, setSelectedPreset] = useState(null);
	const [selectedAnalysisType, setSelectedAnalysisType] = useState(null);
	const [selectedOrthoType, setSelectedOrthoType] = useState(null);
	const [selectedQuantifiedRegionType, setSelectedQuantifiedRegionType] = useState(null);

	//-- Intial Load event
	//-- 1. Initiate Categories
	//-- 2. Initiate Analysis Types
	useEffect(() => {
		if (!rawPresetOptions || rawPresetOptions.length === 0) {
			toast.error("Error loading data");
		}
		const categories = getAssessmentCategoryOptions(rawPresetOptions?.categories);
		setAssessmentCategories(categories);

		const analysisTypes = getAnalysisTypeOptions(rawPresetOptions?.analysisTypes);
		setAnalysisTypes(analysisTypes);
	}, []);

	//----------- Initial Load EVENTS -----------
	//-- Stage ddls
	useEffect(() => {
		if (allAssessmentTypes?.length > 0 && !selectedPreset && analysisPresets?.length === 0) {
			const timeSeriesPresets = getAssessmentPresets(rawPresetOptions.timeSeriesPresets);
			const groundDataPresets = getAssessmentPresetsForGroundData(rawPresetOptions.groundDataPresets);
			const growthCurveAssessmentTypes = _.filter(allAssessmentTypes, (aat) => {
				return aat.type === "growthCurve";
			});
			const growthCurvePresets = getAssessmentPresetsForGrowthCurves(
				rawPresetOptions.growthCurvePresets,
				growthCurveAssessmentTypes,
				rawPresetOptions.categories,
				rawPresetOptions.curveModelAnalysisDisplayNames
			);
			const presets = [].concat(timeSeriesPresets).concat(groundDataPresets).concat(growthCurvePresets);

			setAnalysisPresets(presets);
			const p = filterPresetAssessments(presets, allAssessmentTypes);
			setFilteredAnalysisPresets(p);
			fitlerCategories(presets, allAssessmentTypes, getAssessmentCategoryOptions(rawPresetOptions.categories));

			if (updatePresetList) {
				updatePresetList(timeSeriesPresets, growthCurvePresets);
			}

			let clonedAssessmentTypes = _.cloneDeep(allAssessmentTypes);
			const ot = _.uniqBy(
				_.filter(clonedAssessmentTypes, (ot) => {
					return ot.type === "timeSeries";
				}),
				"key"
			);
			setOrthoTypes(ot);
		} else if (allAssessmentTypes?.length > 0) {
			const timeSeriesPresets = getAssessmentPresets(rawPresetOptions.timeSeriesPresets);
			const groundDataPresets = getAssessmentPresetsForGroundData(rawPresetOptions.groundDataPresets);
			const growthCurveAssessmentTypes = _.filter(displayAssessmentTypes ?? allAssessmentTypes, (aat) => {
				return aat.type === "growthCurve";
			});
			const growthCurvePresets = getAssessmentPresetsForGrowthCurves(
				rawPresetOptions.growthCurvePresets,
				growthCurveAssessmentTypes,
				rawPresetOptions.categories,
				rawPresetOptions.curveModelAnalysisDisplayNames
			);
			const presets = [].concat(timeSeriesPresets).concat(groundDataPresets).concat(growthCurvePresets);

			const p = filterPresetAssessments(presets, displayAssessmentTypes ?? allAssessmentTypes);
			setFilteredAnalysisPresets(p);
		}
	}, [allAssessmentTypes, displayAssessmentTypes]);

	useEffect(() => {
		if (filteredAssessmentCategories?.length > 0 && analysisPresets?.length > 0 && !defaultAssessment) {
			let presetToSelect = filteredAnalysisPresets[0];

			if (summaryTableSelections) {
				let currentSelectionIds = _.map(summaryTableSelections, "id");
				presetToSelect = _.find(filteredAnalysisPresets, (fap) => !currentSelectionIds.includes(fap.key));
			}

			let initialPreset =
				_.find(filteredAnalysisPresets, (fap) => {
					return fap.text === "Vegetation (NDVI)";
				}) ?? presetToSelect;

			setSelectedPreset(initialPreset?.value);
			setSelectedPresetAnalyticType(initialPreset?.assessmentcategoryid);
		}
	}, [filteredAssessmentCategories, analysisPresets, quantifiedRegionTypes]);

	useEffect(() => {
		if (defaultAssessment && allAssessmentTypes.length > 0 && analysisPresets.length > 0) {
			const assessment = _.find(allAssessmentTypes, (at) => {
				return at.key === defaultAssessment;
			});

			const preset = findMatchingPreset(assessment.analysistypeid, assessment.key, assessment.quantifiedregiontypeid);
			if (preset) {
				setSelectedPresetAnalyticType(preset.assessmentcategoryid);
				setSelectedPreset(preset.key);
			}
		}
	}, [defaultAssessment, analysisPresets]);

	//----------- END: Initial Load EVENTS -----------

	//------------ Onchange EVENTS --------------

	//-- selectedPresetAnalyticType on change event
	useEffect(() => {
		if (analysisPresets && selectedPresetAnalyticType && allAssessmentTypes) {
			updatePresetOptions(selectedPresetAnalyticType, analysisPresets, allAssessmentTypes, heatmap);
			setAccordionActiveIndex(false);
		}
	}, [selectedPresetAnalyticType]);

	//-- selectedPreset on change event
	useEffect(() => {
		let preset = null;
		if (selectedPreset) {
			preset = getPresetData(selectedPreset);
			switch (preset?.type) {
				case "groundData":
					groundDataPresetSelected(preset.value);
					break;
				case "growthCurve":
					growthCurvePresetSelected(preset.value);
					break;
				case "timeSeries":
					timeSeriesPresetSelected(preset);
					break;
			}
		}
		if (setSelectedAssessmentName) {
			setSelectedAssessmentName(preset?.text ?? null);
		}
	}, [selectedPreset]);

	function getPresetByAnalysisId(analysisId) {
		var assessment = _.find(rawPresetOptions.timeSeriesPresets, (tsp) => {
			return tsp.analysisId === analysisId;
		});

		return assessment;
	}

	function timeSeriesPresetSelected(preset) {
		setSelectedAnalysisType(preset.analysistypeid);
		//Some pages dont have a function to update analysis type because they dont need it
		if (shouldUpdateAnalysisTypeId) {
			updatedSelectedAnalysisType(preset.analysistypeid);
		}
		let analysis = getPresetByAnalysisId(preset?.analysisid);
		setSelectedOrthoType(analysis.orthoImageTypeId);
		updateSelectedAnalysis(analysis.analysisId, index, analysis.quantifiedRegionTypeId);

		if (quantifiedRegionTypes?.length > 0) {
			const qr = getQuantifiedRegionTypeForAssessment(
				preset?.quantifiedregiontypeid,
				preset?.secondaryquantifiedregiontypeid
			);
			setSelectedQuantifiedRegionType(qr);
			updatedSelectedQuantifiedRegion(qr);
		}
	}

	function getQuantifiedRegionTypeForAssessment(primaryQuantifiedRegionType, secondaryQuantifiedRegionType) {
		const primary = _.find(quantifiedRegionTypes, (qrt) => {
			return qrt.value === primaryQuantifiedRegionType;
		});

		const secondary = _.find(quantifiedRegionTypes, (qrt) => {
			return qrt.value === secondaryQuantifiedRegionType;
		});

		return primary?.value ?? secondary?.value;
	}

	function growthCurvePresetSelected(growthCurvePresetId) {
		const preset = _.find(analysisPresets, (gcp) => {
			return gcp.id === growthCurvePresetId || gcp.key === growthCurvePresetId;
		});

		const assessment = _.find(displayAssessmentTypes ?? allAssessmentTypes, (aat) => {
			return (
				(preset.curvemodelanalysisid &&
					aat.curvemodelanalysisid === preset.curvemodelanalysisid &&
					aat.analysisid === preset.analysisid &&
					aat.analysistypeid === preset.analysistypeid &&
					(aat.quantifiedregiontypeid === preset.quantifiedregiontypeid ||
						aat.quantifiedregiontypeid === preset.secondaryquantifiedregiontypeid)) ||
				preset.key === aat.key
			);
		});
		updateSelectedAnalysis(assessment.value, index, preset.text, initialLoad);
	}

	function groundDataPresetSelected(groundDataId) {
		const preset = _.find(analysisPresets, (gcp) => {
			return gcp.key === groundDataId;
		});
		updateSelectedAnalysis(groundDataId, index, preset.text, initialLoad);
	}

	//-- selectedAnalysisType, selectedOrthoType, selectedQuantifiedRegionType on change event
	useEffect(() => {
		if (selectedOrthoType && selectedAnalysisType && selectedQuantifiedRegionType) {
			const preset = findMatchingPreset(selectedAnalysisType, selectedOrthoType, selectedQuantifiedRegionType);
			switch (preset?.type) {
				case "timeSeries":
				case "growthCurve":
				case "groundData":
					setSelectedPreset(preset.key);
					break;
				default:
					setSelectedPreset(null);
			}
		}
	}, [selectedAnalysisType, selectedOrthoType, selectedQuantifiedRegionType]);

	function getPresetData(presetId) {
		const preset = _.find(analysisPresets, (fap) => {
			return fap.key === presetId;
		});

		return preset;
	}

	function findMatchingPreset(selectedAnalysisTypeId, selectedOrthoTypeId, selectedQuantifiedRegionTypeId) {
		const analysisId = getAnalysisId(selectedOrthoTypeId, selectedQuantifiedRegionTypeId, selectedAnalysisTypeId);

		const preset = _.find(analysisPresets, (fap) => {
			return (
				(fap.analysisid === analysisId &&
					fap.analysistypeid === selectedAnalysisTypeId &&
					(fap.orthoimagetypeid === selectedOrthoTypeId || fap.text === "Stand Count (Automated)") &&
					(fap.quantifiedregiontypeid === selectedQuantifiedRegionTypeId ||
						fap.secondaryquantifiedregiontypeid === selectedQuantifiedRegionTypeId)) ||
				fap.key === selectedOrthoTypeId
			);
		});

		return preset;
	}

	function getAnalysisId(orthoImageTypeId, quantifiedRegionType, analysisTypeId) {
		let analysisType = _.find(analysisTypes, (at) => {
			return at.key === analysisTypeId;
		});
		if (analysisType?.text === AnalysisDropDownListFunctions.UNIFORMITY) {
			analysisType = _.find(analysisTypes, (at) => {
				return at.name === AnalysisDropDownLists.AERIAL;
			});
		}

		let assessment = _.find(displayAssessmentTypes ?? allAssessmentTypes, (aat) => {
			return (
				aat.orthoimagetypeid === orthoImageTypeId &&
				aat.quantifiedregiontypeid === quantifiedRegionType &&
				aat.analysistypeid === analysisType?.key
			);
		});

		if (!assessment) {
			assessment = _.find(displayAssessmentTypes ?? allAssessmentTypes, (aat) => aat.key === orthoImageTypeId);
		}

		if (!assessment?.analysisid && assessment?.text.includes("Stand Count")) {
			assessment.analysisid = assessment.key;
		}

		return assessment?.analysisid;
	}

	//------------ END: Onchange EVENTS --------------

	//---------- UI CONTROL -------------

	//-- Open or close the accordion on click
	const accordionClick = () => {
		if (canShowAdditionalDropdownOptions()) {
			const newIndex = !accordionActiveIndex;
			setAccordionActiveIndex(newIndex);
		}
	};

	function canShowAdditionalDropdownOptions() {
		const category = _.find(assessmentCategories, (c) => {
			return c.key === selectedPresetAnalyticType;
		})?.text;
		const result = category === timeSeries || !category;

		return result;
	}

	//---------- END: UI CONTROL -------------

	//---------- Setup DDL Options -------------

	function getAssessmentCategoryOptions(categories) {
		const options = _.map(categories, (c) => {
			return {
				key: c.id,
				value: c.id,
				text: c.name
			};
		});

		return options;
	}

	function getAnalysisTypeOptions(analysisTypes) {
		const options = _.map(analysisTypes, (at) => {
			return {
				key: at.id,
				value: at.id,
				text: at.name
			};
		});

		return options;
	}

	function getAssessmentPresets(presets) {
		const options = _.map(presets, (p) => {
			return {
				key: p.id,
				value: p.id,
				text: p.name,
				assessmentcategoryid: p.assessmentCategoryId,
				analysisid: p.analysisId,
				analysistypeid: p.analysisTypeId,
				quantifiedregiontypeid: p.quantifiedRegionTypeId,
				secondaryquantifiedregiontypeid: p.secondaryQuantifiedRegionTypeId,
				order: p.order,
				orthoimagetypeid: p.orthoImageTypeId,
				type: "timeSeries"
			};
		});

		return options;
	}

	function getAssessmentPresetsForGrowthCurves(presets, nonPresetAssessments, categories, displayNames) {
		const filteredPresets = _.filter(presets, (p) => {
			return _.some(nonPresetAssessments, (npa) => {
				return (
					npa.analysisid === p.analysisId &&
					npa.curvemodelanalysisid === p.curveModelAnalysisId &&
					npa.analysistypeid === p.analysisTypeId &&
					npa.curvemodelid === p.curveModelId &&
					(npa.quantifiedregiontypeid === p.quantifiedRegionTypeId ||
						npa.quantifiedregiontypeid === p.secondaryQuantifiedRegionTypeId)
				);
			});
		});
		const presetOptions = _.map(filteredPresets, (p) => {
			let matchingPreset = _.find(
				nonPresetAssessments,
				(npa) =>
					npa.analysisid === p.analysisId &&
					npa.curvemodelanalysisid === p.curveModelAnalysisId &&
					npa.analysistypeid === p.analysisTypeId &&
					npa.curvemodelid === p.curveModelId &&
					(npa.quantifiedregiontypeid === p.quantifiedRegionTypeId ||
						npa.quantifiedregiontypeid === p.secondaryQuantifiedRegionTypeId)
			);

			return {
				key: matchingPreset.text,
				value: matchingPreset.text,
				text: `${p.name} (${matchingPreset.text})`,
				assessmentcategoryid: p.assessmentCategoryId,
				analysisid: p.analysisId,
				analysistypeid: p.analysisTypeId,
				quantifiedregiontypeid: p.quantifiedRegionTypeId,
				secondaryquantifiedregiontypeid: p.secondaryQuantifiedRegionTypeId,
				curvemodelanalysisid: p.curveModelAnalysisId,
				order: p.order,
				assessmentname: p.key,
				type: "growthCurve"
			};
		});

		const growthParametersId = _.find(categories, (c) => {
			return c.name === growthParameters;
		})?.id;
		const filteredAssessments = _.filter(nonPresetAssessments, (npa) => {
			return !_.some(presets, (p) => {
				return (
					p.curveModelAnalysisId === npa.curvemodelanalysisid &&
					p.analysisId === npa.analysisid &&
					p.analysisTypeId === npa.analysistypeid &&
					(p.quantifiedRegionTypeId === npa.quantifiedregiontypeid ||
						p.secondaryQuantifiedRegionTypeId === npa.quantifiedregiontypeid)
				);
			});
		});
		let nonPresetOptions = _.map(
			_.filter(filteredAssessments, (fa) => isApAdmin || !fa.text.includes("AUC")),
			(npa) => {
				//--  IMPORTANT!!!!!! JY: Save for GDUS
				// let splitAnalysisName = [...npa.text.replace("_DAP", "").replace("_GDU", "").split("_")];
				// let doesGDUAnalysisExist =
				// 	_.filter(
				// 		filteredAssessments,
				// 		(fa) =>
				// 			_.every(
				// 				[...fa.text.replace("_DAP", "").replace("_GDU", "").split("_")],
				// 				(str, i) => splitAnalysisName[i] === str
				// 			) && //-- find the assessment that includes the current assessment text and the GDU form
				// 			fa.analysisid === npa.analysisid &&
				// 			fa.analysistypeid === npa.analysistypeid &&
				// 			fa.quantifiedregiontypeid === npa.quantifiedregiontypeid
				// 	)?.length == 2;

				let displayName = _.find(
					displayNames,
					(dn) =>
						dn.primaryCurveModelAnalysisId === npa.curvemodelanalysisid &&
						//	(!doesGDUAnalysisExist && dn.secondaryCurveModelAnalysisId === npa.curvemodelanalysisid)) &&
						dn.analysisId === npa.analysisid &&
						dn.analysisTypeId === npa.analysistypeid &&
						(dn.primaryQuantifiedRegionTypeId === npa.quantifiedregiontypeid ||
							dn.secondaryQuantifiedRegionTypeId === npa.quantifiedregiontypeid)
				);

				return {
					key: npa.key,
					value: npa.value,
					text: displayName ? `${displayName.name} (${npa.text})` : npa.text,
					analysisid: npa.analysisid,
					analysistypeid: npa.analysistypeid,
					curvemodelanalysisid: npa.curvemodelanalysisid,
					quantifiedregiontypeid: npa.quantifiedregiontypeid,
					secondaryquantifiedregiontypeid: npa.secondaryquantifiedregiontypeid,
					assessmentcategoryid: growthParametersId,
					type: "growthCurve",
					order: displayName?.order
				};
			}
		);

		if (!isApAdmin) {
			nonPresetOptions = _.filter(nonPresetOptions, (npo) => npo.order);
		}

		nonPresetOptions = _.sortBy(nonPresetOptions, [(opt) => opt.order || Infinity, "text"], ["desc"]);

		const options = [].concat(presetOptions).concat(nonPresetOptions);

		return options;
	}

	function getAssessmentPresetsForGroundData(presets) {
		const options = _.map(presets, (p) => {
			return {
				key: p.groundDataAssessmentId,
				value: p.groundDataAssessmentId,
				text: p.name,
				assessmentcategoryid: p.assessmentCategoryId,
				type: "groundData"
			};
		});

		return options;
	}

	function updatePresetOptions(selectedPresetType, presets, assessmentTypes, heatmap = false) {
		const tPresets = filterPresetAssessments(presets, assessmentTypes);
		let fPresets = _.filter(tPresets, (ap) => {
			return ap.assessmentcategoryid === selectedPresetType;
		});

		//-- Find out if the new selected preset options contains the new selected preset
		let keepSelectedPreset = _.find(filteredAnalysisPresets, { key: fPresets[0]?.key });

		setFilteredAnalysisPresets(fPresets);
		if (!keepSelectedPreset && heatmap) {
			setSelectedPreset(fPresets[0]?.key);
		}
	}

	//---------- END: Setup DDL Options -------------

	//---------- Filter Functions -----------

	function filterPresetAssessments(presets, assessmentTypes) {
		const result = _.filter(presets, (p) => {
			return _.some(assessmentTypes, (at) => {
				return (
					(at.type === "timeSeries" &&
						// at.key === p.analysisid &&
						(at.analysisid === p.analysisid || at.key === p.analysisid) &&
						at.analysistypeid === p.analysistypeid &&
						(at.quantifiedregiontypeid === p.quantifiedregiontypeid ||
							at.quantifiedregiontypeid === p.secondaryquantifiedregiontypeid)) ||
					(at.type === "groundData" && at.key === p.key) ||
					(at.type === "growthCurve" &&
						at.analysisid === p.analysisid &&
						at.curvemodelanalysisid === p.curvemodelanalysisid)
				);
			});
		});
		return _.orderBy(result, ["order", "text"]);
	}

	function fitlerCategories(presets, assessmentTypes, categories) {
		const tPresets = filterPresetAssessments(presets, assessmentTypes);
		const growthCurvePresets = _.filter(tPresets, (p) => {
			return p.type === "growthCurve";
		});
		const growthCurveAssessmentTypes = _.filter(assessmentTypes, (at) => {
			return at.type === "growthCurve";
		});
		const filteredCategories = _.filter(_.cloneDeep(categories), (c) => {
			return (
				_.some(tPresets, (p) => {
					return c.value === p.assessmentcategoryid;
				}) ||
				//-- Time Series
				(c.text === timeSeries &&
					_.some(assessmentTypes, (at) => {
						return at.type === "timeSeries";
					})) ||
				//-- Growth Parameters
				(c.text === growthParameters && showGrowthParameters(growthCurvePresets, growthCurveAssessmentTypes))
			);
		});
		setFilteredAssessmentCategories(filteredCategories);
	}

	function showGrowthParameters(presets, assessmentTypes) {
		const result =
			assessmentTypes.length > 0 &&
			!_.every(assessmentTypes, (at) => {
				return _.some(presets, (p) => {
					return (
						at.type === "growthCurve" &&
						at.analysisid === p.analysisid &&
						at.curvemodelanalysisid === p.curvemodelanalysisid
					);
				});
			});

		return result;
	}

	//---------- END: Filter Functions -----------

	//---------- START: Render Functions ---------

	function normalRender() {
		return !useSummaryTableStyles ? (
			<Segment className="ribbonBanner" style={style}>
				<Accordion fluid>
					<Accordion.Title active={accordionActiveIndex} style={{ cursor: "unset" }}>
						<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
							{canShowAdditionalDropdownOptions() &&
							((loadingStats !== true &&
								allMeanComparisonData?.length === uniqueAnalysesCount?.length &&
								isForAnalysisTimecoursePage) ||
								!isForAnalysisTimecoursePage) ? (
								<div
									onClick={accordionClick}
									style={{ fontSize: "1.28571429rem", fontWeight: "700", cursor: "pointer" }}
								>
									<Icon name="dropdown" />
								</div>
							) : (
								<div />
							)}
							{hideAnalyticTypes ? null : (
								<div>
									<label htmlFor="dropdown-select-analytic-type" style={{ display: "unset" }}>
										Data Type
									</label>
									<Dropdown
										id="dropdown-select-analytic-type"
										selection
										fluid
										search
										options={filteredAssessmentCategories}
										onChange={(event, { value }) => {
											setSelectedPresetAnalyticType(value);
										}}
										value={selectedPresetAnalyticType}
									/>
								</div>
							)}
							<div width="7" verticalAlign="top">
								<label htmlFor="dropdown-select-preset" style={{ display: "unset" }}>
									Assessment
								</label>
								<Dropdown
									id="dropdown-select-preset"
									selection
									fluid
									search
									options={filteredAnalysisPresets}
									onChange={(event, { value }) => {
										if (allMeanComparisonData?.length === uniqueAnalysesCount?.length) {
											setSelectedPreset(value);
										}
									}}
									value={selectedPreset}
									style={{
										color:
											loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
												? "gray"
												: "unset"
									}}
									disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
								/>
							</div>
							{children}
						</div>
						{afterElement}
					</Accordion.Title>
					<Accordion.Content active={accordionActiveIndex} style={{ marginLeft: "55px" }}>
						<Grid>
							<Grid.Row>
								<Grid.Column width="5">
									<label htmlFor="dropdown-select-analysis-type" style={{ display: "unset" }}>
										Analysis Type
									</label>
									<Dropdown
										id="dropdown-select-analysis-type"
										selection
										fluid
										search
										options={analysisTypes}
										onChange={(event, { value }) => {
											setSelectedAnalysisType(value);
											updatedSelectedAnalysisType(value);
											const analysisId = getAnalysisId(
												selectedOrthoType ?? defaultSelectedAnalysisOption,
												selectedQuantifiedRegionType ?? defaultSelectedQuantifiedRegionTypeOption,
												value
											);
											updateSelectedAnalysis(analysisId, index);
											if (isForAnalysisTimecoursePage) {
												setNeedToUpdateAnalytics(true);
												setNeedToReloadData(true);
											}
										}}
										value={selectedAnalysisType ?? defaultSelectedAnalysisType}
										style={{
											color:
												loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
													? "gray"
													: "unset"
										}}
										disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									/>
								</Grid.Column>
								<Grid.Column width="5">
									<label htmlFor="dropdown-select-ortho" style={{ display: "unset" }}>
										Ortho
									</label>
									<Dropdown
										id="dropdown-select-ortho"
										selection
										fluid
										search
										options={orthoTypes}
										onChange={(event, { value }) => {
											setSelectedOrthoType(value);
											const analysisId = getAnalysisId(
												value,
												selectedQuantifiedRegionType ?? defaultSelectedQuantifiedRegionTypeOption,
												selectedAnalysisType ?? defaultSelectedAnalysisType
											);
											updateSelectedAnalysis(analysisId, index);
											if (isForAnalysisTimecoursePage) {
												setNeedToUpdateAnalytics(true);
												setNeedToReloadData(true);
											}
										}}
										value={
											selectedOrthoType ??
											_.find(orthoTypes, (ot) => {
												return ot.analysisid === defaultSelectedAnalysisOption;
											})?.value
										}
										style={{
											color:
												loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
													? "gray"
													: "unset"
										}}
										disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									/>
								</Grid.Column>
								<Grid.Column width="6">
									<label htmlFor="dropdown-select-quantified-region" style={{ display: "unset" }}>
										Quantified Region
									</label>
									<Dropdown
										id="dropdown-select-quantified-region"
										selection
										fluid
										search
										options={quantifiedRegionTypes}
										onChange={(event, { value }) => {
											setSelectedQuantifiedRegionType(value);
											updatedSelectedQuantifiedRegion(value);
											const analysisId = getAnalysisId(
												selectedOrthoType ?? defaultSelectedAnalysisOption,
												value,
												selectedAnalysisType ?? defaultSelectedAnalysisType
											);
											updateSelectedAnalysis(analysisId, index);
											if (isForAnalysisTimecoursePage) {
												setNeedToUpdateAnalytics(true);
												setNeedToReloadData(true);
											}
										}}
										value={selectedQuantifiedRegionType ?? defaultSelectedQuantifiedRegionTypeOption}
										style={{
											color:
												loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
													? "gray"
													: "unset"
										}}
										disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Accordion.Content>
				</Accordion>
			</Segment>
		) : (
			<div style={{ display: "flex", ...style }}>
				<div style={{ width: "200px", marginLeft: "10px" }}>
					{/* <label htmlFor="dropdown-select-analytic-type" style={{ display: "unset" }}>
						Data Type
					</label> */}
					<Dropdown
						id="dropdown-select-analytic-type"
						selection
						fluid
						search
						options={filteredAssessmentCategories}
						onChange={(event, { value }) => {
							setSelectedPresetAnalyticType(value);

							if (setSummaryTablePreset) setSummaryTablePreset("none");
						}}
						value={selectedPresetAnalyticType}
					/>
				</div>
				<div style={{ width: "200px", marginLeft: "10px" }}>
					{/* <label htmlFor="dropdown-select-preset" style={{ display: "unset" }}>
						Assessment
					</label> */}
					<Dropdown
						id="dropdown-select-preset"
						selection
						fluid
						search
						options={filteredAnalysisPresets}
						onChange={(event, { value }) => {
							setSelectedPreset(value);

							if (setSummaryTablePreset) setSummaryTablePreset("none");
						}}
						value={selectedPreset}
					/>
				</div>
			</div>
		);
	}

	function protocolTimecourseRender() {
		return (
			<Segment className="ribbonBanner" style={style}>
				<Accordion fluid>
					<Accordion.Title active={accordionActiveIndex} style={{ cursor: "unset" }}>
						<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
							{canShowAdditionalDropdownOptions() &&
							loadingStats !== true &&
							allMeanComparisonData?.length === uniqueAnalysesCount?.length ? (
								<div
									onClick={accordionClick}
									verticalAlign="bottom"
									style={{ fontSize: "1.28571429rem", fontWeight: "700", cursor: "pointer" }}
								>
									<Icon name="dropdown" />
								</div>
							) : (
								<div />
							)}
							{hideAnalyticTypes ? null : (
								<div>
									<label htmlFor="dropdown-select-analytic-type" style={{ display: "unset" }}>
										Data Type
									</label>
									<Dropdown
										id="dropdown-select-analytic-type"
										selection
										fluid
										search
										options={filteredAssessmentCategories}
										onChange={(event, { value }) => {
											setSelectedPresetAnalyticType(value);
										}}
										value={selectedPresetAnalyticType}
									/>
								</div>
							)}
							<div verticalAlign="top">
								<label htmlFor="dropdown-select-preset" style={{ display: "unset" }}>
									Assessment
								</label>
								<Dropdown
									id="dropdown-select-preset"
									selection
									fluid
									search
									options={filteredAnalysisPresets}
									onChange={(event, { value }) => {
										setSelectedPreset(value);
									}}
									value={selectedPreset}
									style={{
										color:
											loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
												? "gray"
												: "unset"
									}}
									disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
								/>
							</div>
							{children}
						</div>
					</Accordion.Title>
					<Accordion.Content active={accordionActiveIndex} style={{ marginLeft: "55px" }}>
						<Grid>
							<Grid.Row>
								<Grid.Column width="5">
									<label htmlFor="dropdown-select-analysis-type" style={{ display: "unset" }}>
										Analysis Type
									</label>
									<Dropdown
										id="dropdown-select-analysis-type"
										selection
										fluid
										search
										options={analysisTypes}
										onChange={(event, { value }) => {
											setSelectedAnalysisType(value);
											updatedSelectedAnalysisType(value);
											const analysisId = getAnalysisId(
												selectedOrthoType ?? defaultSelectedAnalysisOption,
												selectedQuantifiedRegionType ?? defaultSelectedQuantifiedRegionTypeOption,
												value
											);
											updateSelectedAnalysis(analysisId, index);
											setNeedToUpdateAnalytics(true);
										}}
										value={selectedAnalysisType ?? defaultSelectedAnalysisType}
										style={{
											color:
												loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
													? "gray"
													: "unset"
										}}
										disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									/>
								</Grid.Column>
								<Grid.Column width="5">
									<label htmlFor="dropdown-select-ortho" style={{ display: "unset" }}>
										Ortho
									</label>
									<Dropdown
										id="dropdown-select-ortho"
										selection
										fluid
										search
										options={orthoTypes}
										onChange={(event, { value }) => {
											setSelectedOrthoType(value);
											const analysisId = getAnalysisId(
												value,
												selectedQuantifiedRegionType ?? defaultSelectedQuantifiedRegionTypeOption,
												selectedAnalysisType ?? defaultSelectedAnalysisType
											);
											updateSelectedAnalysis(analysisId, index);
											setNeedToUpdateAnalytics(true);
										}}
										value={
											selectedOrthoType ??
											_.find(orthoTypes, (ot) => {
												return ot.analysisid === defaultSelectedAnalysisOption;
											})?.value
										}
										style={{
											color:
												loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
													? "gray"
													: "unset"
										}}
										disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									/>
								</Grid.Column>
								<Grid.Column width="6">
									<label htmlFor="dropdown-select-quantified-region" style={{ display: "unset" }}>
										Quantified Region
									</label>
									<Dropdown
										id="dropdown-select-quantified-region"
										selection
										fluid
										search
										options={quantifiedRegionTypes}
										onChange={(event, { value }) => {
											setSelectedQuantifiedRegionType(value);
											updatedSelectedQuantifiedRegion(value);
											const analysisId = getAnalysisId(
												selectedOrthoType ?? defaultSelectedAnalysisOption,
												value,
												selectedAnalysisType ?? defaultSelectedAnalysisType
											);
											updateSelectedAnalysis(analysisId, index);
											setNeedToUpdateAnalytics(true);
										}}
										value={selectedQuantifiedRegionType ?? defaultSelectedQuantifiedRegionTypeOption}
										style={{
											color:
												loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length
													? "gray"
													: "unset"
										}}
										disabled={loadingStats === true || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Accordion.Content>
				</Accordion>
			</Segment>
		);
	}
	//---------- END: Rend Functions -----------

	//-- return statement is a function because the styles are wildly different
	return isProtocolTimecourse ? protocolTimecourseRender() : normalRender();
};

AnalysisDropDownLists.propTypes = {
	rawPresetOptions: PropTypes.object.isRequired,
	allAssessmentTypes: PropTypes.array,
	displayAssessmentTypes: PropTypes.array,
	quantifiedRegionTypes: PropTypes.array,
	updateSelectedAnalysis: PropTypes.func,
	updatedSelectedQuantifiedRegion: PropTypes.func,
	updatedSelectedAnalysisType: PropTypes.func,
	hideAnalyticTypes: PropTypes.bool,
	updatePresetList: PropTypes.func,
	index: PropTypes.number,
	defaultAssessment: PropTypes.string,
	useSummaryTableStyles: PropTypes.bool,
	initialLoad: PropTypes.bool,
	setSelectedAssessmentName: PropTypes.string,
	isProtocolTimecourse: PropTypes.bool,
	setSummaryTablePreset: PropTypes.func,
	summaryTableSelections: PropTypes.array,
	heatmap: PropTypes.bool,
	loadingStats: PropTypes.bool,
	allMeanComparisonData: PropTypes.array,
	uniqueAnalysesCount: PropTypes.array,
	isForAnalysisTimecoursePage: PropTypes.bool,
	setNeedToUpdateAnalytics: PropTypes.func,
	setNeedToReloadData: PropTypes.func,
	defaultSelectedAnalysisType: PropTypes.string,
	defaultSelectedAnalysisOption: PropTypes.string,
	defaultSelectedQuantifiedRegionTypeOption: PropTypes.string,
	style: PropTypes.object,
	shouldUpdateAnalysisTypeId: PropTypes.bool,
	children: PropTypes.node,
	afterElement: PropTypes.node
};

export default AnalysisDropDownLists;
