import React, { useState, useImperativeHandle } from "react";
//import PropTypes from "prop-types";
import { Confirm } from "semantic-ui-react";
// import { useHistory } from "react-router-dom";
// import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
// import { useDispatch, useSelector } from "react-redux";
// import { useAuth0 } from "../../../auth/auth0";

import * as OrthoControlFunctions from "./orthoControlFunctions";

//Underscore because we need to pass in an empty object
const OrthoAnalyzedModal = React.forwardRef((_, modalRef) => {
	OrthoAnalyzedModal.displayName = "OrthoAnalyzedModal";

	const [modalOpen, setModalOpen] = useState(false);
	const [fieldId, setFieldId] = useState("");
	const [activeElement, setActiveElement] = useState({});
	const [draw, setDraw] = useState({});
	const [feature, setFeature] = useState(false);

	useImperativeHandle(modalRef, () => {
		return {
			updateFieldId: updateFieldId,
			openModal: openModal,
			updateActiveElement: updateActiveElement,
			updateDraw: updateDraw,
			updateFeature: updateFeature
		};
	});

	function updateFieldId(id) {
		setFieldId(id);
	}

	function updateActiveElement(element) {
		setActiveElement(element);
	}

	function openModal() {
		setModalOpen(true);
	}

	function updateDraw(passedInDraw) {
		setDraw(passedInDraw);
	}

	function updateFeature(passedInFeature) {
		setFeature(passedInFeature);
	}

	function handleConfirm() {
		OrthoControlFunctions.deleteAnalyzedData(fieldId, draw, feature);
		setModalOpen(false);
		activeElement.focus();
	}

	return (
		<>
			<Confirm
				open={modalOpen}
				content={
					"You are about to update a field that already has analysis data. If you update the field, all analysis data for this field will be lost. Do you want to continue?"
				}
				cancelButton={"Cancel"}
				confirmButton={"Confirm"}
				onCancel={() => {
					setModalOpen(false);
					activeElement.focus();
				}}
				onConfirm={(e) => handleConfirm(e)}
			/>
		</>
	);
});

export default OrthoAnalyzedModal;
