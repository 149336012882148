import React, { useEffect, useState } from "react";
import { Checkbox, Segment, Table, TableCell, Grid, Loader, Popup, Icon } from "semantic-ui-react";
import * as tableShader from "../../../Lumber/TableShader";
import * as valuePercisionConverter from "../../../Lumber/ValuePercisionConverter";

import { std } from "mathjs";
import _ from "lodash";

import PropTypes from "prop-types";
const ProtocolDataSummaryTable = ({
	compiledData,
	tukeyData,
	updateCheckedTreatment,
	treatmentsChecked,
	selectedAssessmentOptions,
	disableCheckBoxes,
	compiledMeanComparisonsRunning,
	selectedAssessmentPresetOption,
	presetData,
	trialsChecked,
	hideUncheckedTreatmentData,
	hideLetters
}) => {
	const [tableData, setTableData] = useState(null);
	const [meanData, setMeanData] = useState(null);
	const [tukeyTableData, setTukeyTableData] = useState(null);
	const [sectionHeaders, setSectionHeaders] = useState(null);
	const [reorganizedSelectedAssessmentOptions, setReorganizedSelectedAssessmentOptions] = useState(null);
	const [displaySectionHeaders, setDisplaySectionHeaders] = useState(false);

	useEffect(() => {
		if (compiledData?.length > 0) {
			calculateMeanAndStandardDeviation(compiledData);
			setTableData(compiledData);
		}
	}, [compiledData]);

	useEffect(() => {
		if (tukeyData) {
			setTukeyTableData(tukeyData);
		}
	}, [tukeyData]);

	useEffect(() => {
		if (selectedAssessmentPresetOption) {
			setupTableWithPreset();
		}
	}, [selectedAssessmentPresetOption]);

	useEffect(() => {
		if (selectedAssessmentOptions?.length > 0 && compiledData) {
			//Filter out unchecked treatments to re-color cells
			let filteredCompiledData = [];
			if (treatmentsChecked?.length > 0) {
				filteredCompiledData = _.filter(compiledData, (cd) => {
					return _.find(treatmentsChecked, (tc) => tc.trialTreatmentId === cd.trialTreatmentId)?.treatmentChecked;
				});
			}
			calculateMeanAndStandardDeviation(filteredCompiledData?.length > 0 ? filteredCompiledData : compiledData);
			setupTableWithPreset();
		}
	}, [selectedAssessmentOptions]);

	function calculateMeanAndStandardDeviation(compiledData) {
		let compiledAssessmentData = [];
		let meanDataObjs = [];
		//get all of the assessment Data into one list
		_.map(compiledData, (cData) => {
			cData.treatmentAverages = [];
			_.map(selectedAssessmentOptions, (sao) => {
				const assessmentAtColumn = _.filter(cData.assessmentData, (ad) => {
					return (
						ad.assessmentId == sao.assessmentId && ad.plannedTimingId == sao.plannedTimingId && ad.plots?.length > 0
					);
				});
				const assessmentAtColumnForStrikeThrough = _.filter(cData.assessmentDataForStrikeThrough, (ad) => {
					return (
						ad.assessmentId == sao.assessmentId && ad.plannedTimingId == sao.plannedTimingId && ad.plots?.length > 0
					);
				});
				const treatmentAssessmentAverageValue = _.meanBy(_.map(assessmentAtColumn, "assessmentAverage"));
				const treatmentAssessmentAverageValueForStrikeThrough = _.meanBy(
					_.map(assessmentAtColumnForStrikeThrough, "assessmentAverage")
				);
				const treatmentAssessmentStandardDeviation = _.meanBy(_.map(assessmentAtColumn, "standardDeviation"));
				const treatmentAssessmentObj = {
					assessmentId: sao.assessmentId,
					plannedTimingId: sao.plannedTimingId,
					average: treatmentAssessmentAverageValue,
					averageForStrikeThrough: treatmentAssessmentAverageValueForStrikeThrough,
					standardDeviation: treatmentAssessmentStandardDeviation,
					reverseTableShading: sao.assessmentName.includes("MG50")
				};
				cData.treatmentAverages.push(treatmentAssessmentObj);
			});

			_.map(cData.assessmentData, (aData) => {
				compiledAssessmentData.push(aData);
			});
		});
		_.map(selectedAssessmentOptions, (sao) => {
			let similarAssessments = [];
			_.map(compiledData, (cData) => {
				const assessments = _.find(cData.treatmentAverages, (ta) => {
					return ta.assessmentId == sao.assessmentId && ta.plannedTimingId == sao.plannedTimingId;
				});
				if (isNaN(assessments.average) === false) {
					similarAssessments.push(assessments);
				}
			});
			const selectedDataNormalizationName = sao.dataNormalizationName.toLowerCase();

			if (selectedDataNormalizationName === "rank order") {
				getRankOrder(similarAssessments);
				_.map(similarAssessments, (sa) => {
					sa.reverseTableShading = true;
				});
			}
			similarAssessments[0]?.reverseTableShading
				? tableShader.calculateShadingForTreatmentReversed(similarAssessments, "average", "90, 138, 198")
				: tableShader.calculateShadingForTreatment(similarAssessments, "average", "90, 138, 198");
			const averages = _.map(similarAssessments, "average");
			const average = _.meanBy(averages);

			const standardDeviation = isNaN(average) ? null : (std(averages) / average) * 100;

			const averageObj = {
				assessmentId: sao.assessmentId,
				plannedTimingId: sao.plannedTimingId,
				unitName: sao.unitName,
				average: valuePercisionConverter.getPercision(average),
				standardDeviation: valuePercisionConverter.getPercision(standardDeviation)
			};
			meanDataObjs.push(averageObj);
		});
		setMeanData(meanDataObjs);
	}

	function getRankOrder(averageData) {
		averageData = _.orderBy(averageData, ["average"], ["desc"]);
		_.map(averageData, (ad, index) => {
			ad.average = index + 1;
		});

		return _.sortBy(averageData, "trialTreatmentId");
	}

	function setupTableWithPreset() {
		//-- User is customizing if option is none
		if (selectedAssessmentPresetOption !== "none") {
			//-- Turn on headers
			setDisplaySectionHeaders(true);

			//-- Find matching preset
			let matchingPreset = _.find(presetData, { id: selectedAssessmentPresetOption });

			if (matchingPreset) {
				let updatedSectionHeaders = [];
				let sortedAssessments = [];
				_.map(_.orderBy(matchingPreset.sections, "sectionOrder"), (section) => {
					if (section.displaySectionName) {
						//-- Get collection of assessments in section
						let assessmentsInSection = [];
						assessmentsInSection = [
							...assessmentsInSection,
							..._.map(_.orderBy(section.presetAnalyses, "assessmentOrder"), "analysisId")
						];
						assessmentsInSection = [
							...assessmentsInSection,
							..._.map(_.orderBy(section.presetCurveModels, "assessmentOrder"), "curveModelAnalysisId")
						];
						assessmentsInSection = [
							...assessmentsInSection,
							..._.map(_.orderBy(section.presetGroundData, "assessmentOrder"), "groundDataAssessmentId")
						];

						//-- Reorganize assessments
						let matchingAssessments = _.filter(
							selectedAssessmentOptions,
							(sao) =>
								assessmentsInSection.includes(sao.assessmentId) ||
								assessmentsInSection.includes(sao.curveModelAnalysisId)
						);

						sortedAssessments = [...sortedAssessments, ...matchingAssessments];

						//-- Get column length of section header
						let colLength = matchingAssessments?.length;

						//-- Create section header object
						let obj = {
							id: section.id,
							name: section.sectionName,
							assessments: assessmentsInSection,
							colLength: colLength
						};

						//-- Skip headers with no assessments
						if (obj.colLength !== 0) {
							updatedSectionHeaders.push(obj);
						}
					}
				});

				setReorganizedSelectedAssessmentOptions(sortedAssessments);
				setSectionHeaders(updatedSectionHeaders);
			}
		} else {
			//-- Remove headers
			setDisplaySectionHeaders(false);
		}
	}

	return (
		<Segment basic style={{ padding: "0" }}>
			{selectedAssessmentOptions && (
				<>
					<Table structured celled striped selectable color="blue" textAlign="center" collapsing>
						<Table.Header>
							{displaySectionHeaders && (
								<Table.Row>
									<Table.HeaderCell colSpan={4} />
									{sectionHeaders &&
										_.map(sectionHeaders, (sh) => {
											return (
												<Table.HeaderCell key={sh.id} colSpan={sh.colLength}>
													{sh.name}
												</Table.HeaderCell>
											);
										})}
								</Table.Row>
							)}
							<Table.Row>
								<Table.HeaderCell colSpan={4} textAlign="right">
									Assessment
								</Table.HeaderCell>
								{selectedAssessmentOptions &&
									_.map(
										displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
										(sao, index) => {
											return <Table.HeaderCell key={index}>{sao.assessmentName}</Table.HeaderCell>;
										}
									)}
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell colSpan={4} textAlign="right">
									Planned Timing
								</Table.HeaderCell>
								{selectedAssessmentOptions &&
									_.map(
										displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
										(sao, index) => {
											return <Table.HeaderCell key={index}>{sao.plannedTimingName}</Table.HeaderCell>;
										}
									)}
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell colSpan={4} textAlign="right">
									Normalization
								</Table.HeaderCell>
								{selectedAssessmentOptions &&
									_.map(
										displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
										(sao, index) => {
											return <Table.HeaderCell key={index}>{sao.dataNormalizationName}</Table.HeaderCell>;
										}
									)}
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell colSpan={4} textAlign="right">
									Units
								</Table.HeaderCell>
								{selectedAssessmentOptions &&
									_.map(
										displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
										(sao, index) => {
											return (
												<Table.HeaderCell key={index}>
													{sao.unitName ? sao.unitName : sao.dataNormalizationName?.includes("%") ? "%" : ""}
												</Table.HeaderCell>
											);
										}
									)}
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell>#</Table.HeaderCell>
								<Table.HeaderCell>Type</Table.HeaderCell>
								<Table.HeaderCell>Name</Table.HeaderCell>
								{_.map(
									displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
									(temp, index) => {
										return <Table.HeaderCell colSpan="1" textAlign="center" key={index}></Table.HeaderCell>;
									}
								)}
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(tableData, (treatment) => {
								return (
									<Table.Row key={treatment.trialTreatmentId}>
										<Table.Cell>
											<Checkbox
												checked={
													_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
														?.treatmentChecked
												}
												onChange={() => {
													updateCheckedTreatment(
														!_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
															?.treatmentChecked,
														treatment.trialTreatmentId
													);
												}}
												disabled={disableCheckBoxes}
											/>
										</Table.Cell>
										<Table.Cell>{treatment.trialTreatmentId}</Table.Cell>
										<Table.Cell>{treatment.treatmentTypeName}</Table.Cell>
										<Table.Cell style={{ minWidth: "505px" }} textAlign="left">
											{treatment.treatmentName}
											{treatment.hasMissingTreatments ? (
												<span style={{ float: "right" }}>
													<Popup
														trigger={<Icon name="info circle" />}
														content="This treatment is missing from some of the trials on this protocol."
													/>
												</span>
											) : null}
										</Table.Cell>
										{_.map(
											displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
											(options, index) => {
												return _.some(treatment.assessmentData, (tad) => {
													return tad.assessmentId == options.assessmentId;
												}) ? (
													<Table.Cell
														key={index}
														style={{
															backgroundColor: !_.find(
																treatmentsChecked,
																(tc) => tc.trialTreatmentId === treatment.trialTreatmentId
															)?.treatmentChecked
																? "lightGray"
																: _.find(treatment.treatmentAverages, (ta) => {
																		return (
																			ta.assessmentId == options.assessmentId &&
																			ta.plannedTimingId == options.plannedTimingId
																		);
																  })?.color,
															minWidth: "150px"
														}}
													>
														<Grid style={{ width: "inherit", height: "inherit" }}>
															<Grid.Row style={{ padding: "inherit" }}>
																<Grid.Column width="1" />
																<Grid.Column width="9" style={{ padding: 0 }}>
																	{_.every(
																		_.filter(
																			treatment.assessmentData,
																			(tad) => tad.assessmentId == options.assessmentId
																		),
																		(tad) => tad.plots.length > 0
																	) &&
																	_.find(treatment.assessmentData, (tad) => tad.assessmentId == options.assessmentId)
																		.assessmentAverage
																		? !_.find(
																				treatmentsChecked,
																				(tc) => tc.trialTreatmentId === treatment.trialTreatmentId
																		  )?.treatmentChecked
																			? ""
																			: valuePercisionConverter.getPercision(
																					_.find(treatment.treatmentAverages, (ta) => {
																						return (
																							ta.assessmentId == options.assessmentId &&
																							ta.plannedTimingId == options.plannedTimingId
																						);
																					})?.average
																			  ) +
																			  "" +
																			  (options.dataNormalizationName?.includes("%") ? "%" : "")
																		: "N/A"}
																</Grid.Column>

																<Grid.Column width="3" style={{ padding: 0 }}>
																	{disableCheckBoxes && compiledMeanComparisonsRunning ? (
																		<Loader active size="tiny" />
																	) : !_.find(
																			treatmentsChecked,
																			(tc) => tc.trialTreatmentId === treatment.trialTreatmentId
																	  )?.treatmentChecked ||
																	  (_.some(trialsChecked, (tc) => !tc.trialChecked) && hideLetters) ||
																	  hideLetters ||
																	  (_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) &&
																			hideUncheckedTreatmentData) ||
																	  hideUncheckedTreatmentData ? (
																		""
																	) : (
																		_.find(
																			tukeyData[options.assessmentId ?? options.plannedTimingId],
																			(td) => td.trialTreatmentId === treatment.trialTreatmentId
																		)?.tukeyLetters
																	)}
																</Grid.Column>
															</Grid.Row>
														</Grid>
													</Table.Cell>
												) : (
													<Table.Cell key={index} style={{ backgroundColor: "lightGray", minWidth: "150px" }}>
														{_.find(treatment.assessmentDataForStrikeThrough, (tad) => {
															return tad.assessmentId == options.assessmentId;
														})?.assessmentAverage
															? !_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
																	?.treatmentChecked
																? ""
																: valuePercisionConverter.getPercision(
																		_.find(treatment.treatmentAverages, (ta) => {
																			return (
																				ta.assessmentId == options.assessmentId &&
																				ta.plannedTimingId == options.plannedTimingId
																			);
																		})?.averageForStrikeThrough
																  ) +
																  "" +
																  (options.dataNormalizationName?.includes("%") ? "%" : "")
															: "N/A"}
													</Table.Cell>
												);
											}
										)}
									</Table.Row>
								);
							})}
							<Table.Row>
								<Table.Cell textAlign="right" colSpan={4}>
									Global Mean
								</Table.Cell>
								{_.map(
									displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
									(sao, index) => {
										return _.find(meanData, (md) => {
											return sao.assessmentId == md.assessmentId && md.plannedTimingId == sao.plannedTimingId;
										})?.average ? (
											<Table.Cell key={index}>
												{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
												hideUncheckedTreatmentData
													? ""
													: _.find(meanData, (md) => {
															return sao.assessmentId == md.assessmentId && md.plannedTimingId == sao.plannedTimingId;
													  }).average + (sao.dataNormalizationName?.includes("%") ? "%" : "")}
											</Table.Cell>
										) : (
											<TableCell key={index} style={{ backgroundColor: "lightGray" }}>
												N/A
											</TableCell>
										);
									}
								)}
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign="right" colSpan={4}>
									CV
								</Table.Cell>
								{_.map(
									displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
									(sao, index) => {
										return _.find(meanData, (md) => {
											return sao.assessmentId == md.assessmentId && md.plannedTimingId == sao.plannedTimingId;
										})?.standardDeviation ? (
											<Table.Cell key={index}>
												{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
												hideUncheckedTreatmentData ||
												(_.some(trialsChecked, (tc) => !tc.trialChecked) && hideLetters) ||
												hideLetters
													? ""
													: Math.abs(
															_.meanBy(
																_.map(
																	_.filter(meanData, (tad) => {
																		return (
																			tad.assessmentId == sao.assessmentId && tad.plannedTimingId == sao.plannedTimingId
																		);
																	}),
																	"standardDeviation"
																)
															)
													  )}
												{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
												hideUncheckedTreatmentData ||
												(_.some(trialsChecked, (tc) => !tc.trialChecked) && hideLetters) ||
												hideLetters
													? ""
													: "%"}
											</Table.Cell>
										) : (
											<TableCell key={index} style={{ backgroundColor: "lightGray" }}>
												N/A
											</TableCell>
										);
									}
								)}
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign="right" colSpan={4}>
									P-Value
								</Table.Cell>
								{_.map(
									displaySectionHeaders ? reorganizedSelectedAssessmentOptions : selectedAssessmentOptions,
									(sao, index) => {
										return tukeyTableData && tukeyTableData[sao.assessmentId]?.length > 0 ? (
											<Table.Cell key={index}>
												{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
												hideUncheckedTreatmentData ||
												(_.some(trialsChecked, (tc) => !tc.trialChecked) && hideLetters) ||
												hideLetters
													? ""
													: Number(Number(tukeyTableData[sao.assessmentId][0]?.pValue).toFixed(5)) === 0
													? "<0.00001"
													: Number(Number(tukeyTableData[sao.assessmentId][0]?.pValue).toFixed(5))}
											</Table.Cell>
										) : (
											<Table.Cell key={index} style={{ backgroundColor: "lightGray" }}>
												N/A
											</Table.Cell>
										);
									}
								)}
							</Table.Row>
						</Table.Body>
					</Table>
				</>
			)}
		</Segment>
	);
};

ProtocolDataSummaryTable.propTypes = {
	compiledData: PropTypes.array,
	tukeyData: PropTypes.array,
	dataNormalization: PropTypes.string,
	updateCheckedTreatment: PropTypes.func,
	treatmentsChecked: PropTypes.array,
	selectedAssessmentOptions: PropTypes.array,
	disableCheckBoxes: PropTypes.bool,
	compiledMeanComparisonsRunning: PropTypes.bool,
	selectedAssessmentPresetOption: PropTypes.string,
	presetData: PropTypes.array,
	trialsChecked: PropTypes.array,
	hideUncheckedTreatmentData: PropTypes.bool,
	hideLetters: PropTypes.bool
};

export default ProtocolDataSummaryTable;
