import PropTypes from "prop-types";
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";

import { getDistinctObjects } from "../../../utilities/jsUtils";
import { getAnalysisOptions } from "./orthoControlFunctions";

import * as fieldActions from "../../../redux/actions/fieldActions";
import * as orthoViewerActions from "../../../redux/actions/orthoViewerActions";

import Timelineslider from "../../Lumber/TimelineSlider";

const OrthoTimeline = React.forwardRef(
	({ level, selectedOrthoImageTypeId, imageFound, heatmapTimelineRef, updateTimelineDomain }, parentRef) => {
		OrthoTimeline.displayName = "OrthoTimeline";
		const dispatch = useDispatch();
		const { getTokenSilently } = useAuth0();
		const moduleNavigation = useModuleNavigation();
		const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
		const currentReduxFlightId = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightId : null));
		const currentReduxFlightDate = useSelector((state) =>
			state.orthoViewer ? state.orthoViewer.currentFlightDate : null
		);
		const reduxFlightYear = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightYear : null));
		const reduxFilteredFlightDates = useSelector((state) => state.orthoViewer?.filters.flightDates ?? null);

		useImperativeHandle(parentRef, () => {
			return {
				refresh: () => {
					setRefresh(refresh + 1);
				},
				currentFlightId: flightId,
				getCurrentFlightId: () => {
					return currentFlightId;
				},
				setCurrentFlightId: (targetFlightId) => {
					setCurrentFlightId(targetFlightId);
				},
				getCurrentFlightDate: () => {
					return flightDate;
				},
				setFlightOptions: (flightOptions) => {
					setFlights(flightOptions);
				},
				setDomain: (domain) => {
					return setDomain(domain);
				}
			};
		});

		//-- Refresh mechanism
		const [refresh, setRefresh] = useState(0);

		//-- Data source
		const [flights, setFlights] = useState(null);
		const [currentFlightId, setCurrentFlightId] = useState(null);

		//-- Timeline nodes
		const [flightNodes, setFlightNodes] = useState(null);
		const [tempFlightNodes, setTempFlightNodes] = useState([]);

		//-- Selected values
		const [flightId, setFlightId] = useState(null);
		const [flightDate, setFlightDate] = useState(null);

		//-- Lists of nodes for ortho/sensor combinations
		const [filteredNodes, setFilteredNodes] = useState([]);

		//-- Domain to be passed to timeline slider
		const [domain, setDomain] = useState(null);

		useEffect(() => {
			if (clientId && (moduleNavigation.fieldId || moduleNavigation.trialId)) {
				getFlightsWithFieldOrTrial();
			}
		}, [clientId]);

		useEffect(() => {
			if (currentReduxFlightId) {
				setCurrentFlightId(currentReduxFlightId);
			}
		}, [currentReduxFlightId]);

		useEffect(() => {
			if (currentReduxFlightDate) {
				setFlightDate(currentReduxFlightDate);
			}
		}, [currentReduxFlightDate]);

		useEffect(() => {
			if ((currentFlightId || flightDate) && filteredNodes.length > 0 && selectedOrthoImageTypeId()) {
				setRefresh(refresh + 1);
			}
		}, [currentFlightId, flightDate, reduxFilteredFlightDates]);

		async function getFlightsWithFieldOrTrial() {
			const accessToken = await getTokenSilently();
			dispatch(
				fieldActions.getFlightsWithFieldOrTrial(
					clientId,
					moduleNavigation.fieldId,
					moduleNavigation.trialId,
					accessToken
				)
			)
				.then((res) => {
					setFlights(res);
				})
				.catch((err) => {
					imageFound(false);
					console.log(err);
				});
		}

		useEffect(() => {
			if (flights && flights.length > 0) {
				let fn = moduleNavigation.farmId ? setupNodesForFarm(flights) : setupNodesForFieldTrial(flights);

				if (level === "trial") {
					fn = fn.filter((f) => f.trialId === moduleNavigation.trialId);
				}

				let filteredNodesClone = [];
				_.forEach(fn, (node) => {
					// If ortho/sensor combination hasn't been filtered, create new node obj
					// else add node to filtered list
					let filteredIndex = _.findIndex(filteredNodesClone, {
						orthoImageTypeId: node.orthoImageTypeId
					});
					if (filteredIndex === -1) {
						filteredNodesClone.push({
							orthoImageTypeId: node.orthoImageTypeId,
							orthoImageType: node.orthoImageType,
							nodes: [node]
						});
					} else {
						filteredNodesClone[filteredIndex].nodes.push(node);
					}
				});

				if (level === "field") {
					_.forEach(filteredNodesClone, (nodeList) => {
						nodeList.nodes = getDistinctObjects(nodeList.nodes);
					});
				}

				//-- Sets up defaulting to a different ortho type
				let nodeIndex = 0;
				if (moduleNavigation.timelineFlightId && filteredNodes?.length === 0) {
					let matchingOrtho = _.find(fn, (f) => f.id === moduleNavigation.timelineFlightId)?.orthoImageTypeId;
					if (matchingOrtho) {
						nodeIndex = _.findIndex(filteredNodesClone, (f) => f.orthoImageTypeId === matchingOrtho);
					}
				}

				//-- If no default set, find first based on our ordering
				if (nodeIndex === 0) {
					let ndviIndex = _.findIndex(filteredNodesClone, { orthoImageType: "NDVI" });
					let ndvim3mIndex = _.findIndex(filteredNodesClone, { orthoImageType: "NDVI_M3M" });
					let sndviIndex = _.findIndex(filteredNodesClone, { orthoImageType: "SNDVI" });
					let rgbIndex = _.findIndex(filteredNodesClone, { orthoImageType: "RGB" });
					let rapidIndex = _.findIndex(filteredNodesClone, { orthoImageType: "Rapid_RGB" });

					nodeIndex =
						ndviIndex !== -1
							? ndviIndex
							: ndvim3mIndex !== -1
							? ndvim3mIndex
							: sndviIndex !== -1
							? sndviIndex
							: rgbIndex !== -1
							? rgbIndex
							: rapidIndex !== -1
							? rapidIndex
							: 0;
				}

				setFilteredNodes(filteredNodesClone);
				setFlightNodes(filteredNodesClone[nodeIndex]?.nodes ?? null);
			}
		}, [flights]);

		useEffect(() => {
			if (filteredNodes.length > 0) {
				let filteredNodesSet = _.find(filteredNodes, {
					orthoImageTypeId: selectedOrthoImageTypeId()
				})?.nodes;

				// remove nodes that are not in the filtered dates
				filteredNodesSet = filteredNodesSet.filter(
					(node) => !reduxFilteredFlightDates?.length || reduxFilteredFlightDates.includes(+new Date(node.id))
				);

				if (filteredNodesSet?.length > 0) {
					if (reduxFlightYear && level === "farm") {
						filteredNodesSet = _.filter(filteredNodesSet, (n) =>
							reduxFlightYear.includes(new Date(n.id)?.getFullYear())
						);
					}

					filteredNodesSet.forEach((fns) => {
						if (fns.id === currentFlightId || fns.id === flightDate) {
							fns.firstActive = true;
						} else {
							fns.firstActive = false;
						}
					});

					// Refresh if nodes are the same
					let originalIds = _.map(flightNodes, "id");
					let filteredIds = _.map(filteredNodesSet, "id");

					if (_.isEqual(originalIds, filteredIds)) {
						setTempFlightNodes(filteredNodesSet);
						setFlightNodes([]);
					} else {
						setFlightNodes(filteredNodesSet);
					}
				}
			}
		}, [refresh]);

		useEffect(() => {
			if (flightNodes?.length === 0) {
				setFlightNodes(tempFlightNodes);
				setTempFlightNodes([]);
			}

			if (flightNodes?.length > 0) {
				const sortedNodes = _.orderBy(flightNodes, "date");
				updateTimelineDomain(
					sortedNodes.length > 1
						? [
								sortedNodes[0].timelineDate ?? sortedNodes[0].date.valueOf(),
								sortedNodes[sortedNodes.length - 1].timelineDate ?? sortedNodes[sortedNodes.length - 1].date.valueOf()
						  ]
						: [
								sortedNodes[0].timelineDate ?? sortedNodes[0].date.valueOf() - 1,
								sortedNodes[0].timelineDate ?? sortedNodes[0].date.valueOf()
						  ]
				);
			}
		}, [flightNodes]);

		useEffect(() => {
			if (flightId) {
				dispatch(orthoViewerActions.setCurrentFlightId(flightId));
			}

			return function cleanup() {
				dispatch(orthoViewerActions.setCurrentFlightId(null));
			};
		}, [flightId]);

		useEffect(() => {
			if (flightDate) {
				dispatch(orthoViewerActions.setCurrentFlightDate(flightDate));
			}

			return function cleanup() {
				dispatch(orthoViewerActions.setCurrentFlightDate(null));
			};
		}, [flightDate]);

		// useEffect(() => {
		// 	//-- For getting rid of the lint error for now...will need to do something when we implement trial viewer
		// }, [level]);

		function setupNodesForFieldTrial(flights) {
			return _.map(
				flights,
				({
					flightId,
					flightDateAndTime,
					trialId,
					orthoImageTypeId,
					orthoImageType,
					sensorId,
					view,
					publishedToTrialOwner,
					publishedToTrialSponsor,
					isUploading
				}) => {
					const opt = {
						id: flightId,
						date: moment(flightDateAndTime).local(),
						published:
							view === "Researcher" ? publishedToTrialOwner : view === "Owner" ? publishedToTrialSponsor : false,
						color: isUploading
							? "var(--negative)"
							: view === "Researcher"
							? publishedToTrialOwner
								? "var(--primary)"
								: "var(--accent-alternate-UI-500)"
							: view === "Owner"
							? publishedToTrialSponsor
								? "var(--primary)"
								: "var(--accent-alternate-UI-500)"
							: "var(--accent-alternate-UI-500)",
						clickable: true,
						firstActive: moduleNavigation.flightId === flightId || moduleNavigation.timelineFlightId === flightId,
						trialId: trialId,
						orthoImageTypeId: orthoImageTypeId,
						orthoImageType: orthoImageType,
						sensorId: sensorId,
						tooltip: <>{moment(flightDateAndTime).format("MMMM DD, YYYY @ HH:mm")}</>
					};

					return opt;
				}
			);
		}

		function setupNodesForFarm(flights) {
			return _.map(
				_.uniqBy(flights, (f) => `${f.flightDateAndTime}${f.orthoImageTypeId}`),
				({ flightDateAndTime, orthoImageTypeId, orthoImageType, sensorId }) => {
					const opt = {
						id: flightDateAndTime,
						date: moment(flightDateAndTime).local(),
						published: false,
						color: "var(--accent-alternate-UI-500)",
						clickable: true,
						firstActive: flightDateAndTime === flightDate,
						orthoImageTypeId: orthoImageTypeId,
						orthoImageType: orthoImageType,
						sensorId: sensorId,
						tooltip: <>{moment(flightDateAndTime).format("MMMM DD, YYYY")}</>
					};

					return opt;
				}
			);
		}

		const updateSelected = useCallback(
			_.debounce((selected, heatmap, flights) => {
				if (selected.trialId) {
					setFlightId(selected.id);
				} else {
					setFlightDate(selected.id);
				}
				updateHeatmapAnalysisId(selected.id, heatmap, flights);
			}, 200),
			[]
		);

		function updateHeatmapAnalysisId(selectedFlightId, heatmap, flights) {
			if (heatmap?.current) {
				//-- Used to check if heatmap has been setup
				let analysisOpts = getAnalysisOptions().analysisOptions;

				//-- Get list of analyses on sensor
				let matchingFlights = _.filter(flights, (f) => f.flightId === selectedFlightId);
				let matchingAnalyses = _.filter(analysisOpts, (ao) =>
					_.map(matchingFlights, "orthoImageTypeId").includes(ao.orthoImageTypeId)
				);

				//-- If heatmap has been setup and ortho/heatmap don't live on same sensor
				if (
					level !== "farm" &&
					analysisOpts &&
					!_.some(matchingAnalyses, (ma) => ma.analysisId === heatmap.current.getSelectedAnalysisId()?.split("/")[0])
				) {
					//-- Try to find flight with matching date instead
					const flightDate = new Date(matchingFlights[0]?.flightDateAndTime);
					const formattedDate = `${flightDate.getFullYear()}-${flightDate.getMonth()}-${flightDate.getDate()}`;

					let matchingFlightIdx = _.findIndex(flights, (fi) => {
						const localDate = new Date(fi.flightDateAndTime);
						const formattedLocalDate = `${localDate.getFullYear()}-${localDate.getMonth()}-${localDate.getDate()}`;

						if (formattedDate === formattedLocalDate && fi.flightId !== selectedFlightId) {
							return true;
						}
					});

					selectedFlightId = matchingFlightIdx !== -1 ? flights[matchingFlightIdx].flightId : selectedFlightId;
				}

				level !== "farm"
					? heatmap.current.setSelectedFlightId(selectedFlightId)
					: heatmap.current.setSelectedFlightDate(selectedFlightId);
			}
		}

		return (
			flightNodes && (
				<>
					<Segment basic style={{ paddingTop: 0, paddingLeft: "unset", paddingRight: "unset", paddingBottom: 0 }}>
						<Timelineslider
							dates={flightNodes}
							updateSelected={(selected) => updateSelected(selected, heatmapTimelineRef, flights)}
							domain={domain}
						/>
					</Segment>
				</>
			)
		);
	}
);

OrthoTimeline.propTypes = {
	level: PropTypes.oneOf(["field", "trial", "farm"]),
	selectedOrthoImageTypeId: PropTypes.func.isRequired,
	imageFound: PropTypes.func.isRequired,
	heatmapTimelineRef: PropTypes.object,
	updateTimelineDomain: PropTypes.func
};

export default OrthoTimeline;
