import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader, Message, Segment } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as fieldActions from "../../../../redux/actions/fieldActions";

import Ortho from "../../../Lumber/OrthoViewer";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

const FieldViewer = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();

	//-- Data
	const [fieldName, setFieldName] = useState(null);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [imageFound, setImageFound] = useState(null);
	const [dataDone, setDataDone] = useState(true);

	useEffect(() => {
		if (entityNames?.fieldId && moduleNavigation.fieldId.toUpperCase() === entityNames.fieldId.toUpperCase()) {
			setFieldName(entityNames.fieldName);
			getPlotAnalysisStatus();
			setLoading(false);
		}
	}, [entityNames]);

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			fieldActions.getPlotAnalysisStatusForField(moduleNavigation.fieldId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	return loading ? (
		<Loader active />
	) : (!loading && !fieldName) || imageFound === false ? (
		<Segment style={{ margin: 15 }}>
			<p>{"This field could not be found."}</p>
		</Segment>
	) : (
		<Segment basic style={{ height: "100%" }}>
			{!dataDone && (
				<Message warning>
					Plot analysis data for this field is currently being recalculated, so data may not be complete or up to date.
					Please refresh the page in a few minutes.
				</Message>
			)}
			<Ortho
				level="field"
				edit={true}
				viewer={true}
				setOrthoImageTypes={() => {}}
				setDraw={() => {}}
				setImageFound={(truth) => setImageFound(truth)}
			/>
		</Segment>
	);
};

export default FieldViewer;
