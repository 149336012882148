export const parcelLayer = {
	id: "parcels",
	type: "fill",
	paint: {
		"fill-color": "rgba(35, 185, 139, 0.35)"
	}
};

export const outlineParcelLayer = {
	id: "outlines",
	type: "line",
	paint: {
		"line-color": "#23B98B",
		"line-width": 2
	}
};

export const boundsLayer = {
	id: "bounds",
	type: "line",
	paint: {
		"line-color": "#45ccff",
		"line-width": 2
	}
};
