import _ from "lodash";
import "mapbox-gl/dist/mapbox-gl.css";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Icon, Input, List } from "semantic-ui-react";
import * as uuid from "uuid";
import * as mapboxSearchActions from "../../../../redux/actions/mapboxSearchActions";
import * as regridActions from "../../../../redux/actions/regridActions";
import ParcelMapWrapper from "./ParcelMapWrapper";
import "./viewer.css";
import { center, combine, polygon } from "@turf/turf";

// import { example } from "./ExampleResponse";

const ParcelMapViewer = ({
	userId,
	parcelList,
	setParcelList,
	hoveredParcel,
	setHoveredParcel,
	selectedParcelList,
	setSelectedParcelList,
	focusedParcel,
	setFocusedParcel,
	initCoords,
	setInitCoords,
	visibleLayers,
	onAddParcel
}) => {
	const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
	const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

	const mapRef = useRef();
	const drawRef = useRef();
	const dispatch = useDispatch();

	const [isDrawing, setIsDrawing] = useState(false);
	const [toggledControl, setToggledControl] = useState("");
	const [mapSearchQuery, setMapSearchQuery] = useState("");
	const [mapSearchSuggestions, setMapSearchSuggestions] = useState([]);
	const [mapSearchResult, setMapSearchResult] = useState({});
	const [searchOpen, setSearchOpen] = useState(false);

	useEffect(() => {
		if (mapSearchResult?.geometry) {
			setInitCoords({
				latitude: mapSearchResult.geometry.coordinates[1],
				longitude: mapSearchResult.geometry.coordinates[0],
				zoom: 15
			});
		}
	}, [mapSearchResult]);

	useEffect(() => {
		if (searchOpen) {
			window.addEventListener("click", closeSearchResults);
		} else {
			window.removeEventListener("click", closeSearchResults);
		}

		return () => {
			window.removeEventListener("click", closeSearchResults);
		};
	}, [searchOpen]);

	const onSearchThisArea = () => {
		toast.success("Searching area...");
		const mapBounds = mapRef.current.getBounds();
		const sw = mapBounds._sw;
		const ne = mapBounds._ne;
		const boundsPoly = polygon([
			[
				[sw.lng, sw.lat],
				[sw.lng, ne.lat],
				[ne.lng, ne.lat],
				[ne.lng, sw.lat],
				[sw.lng, sw.lat]
			]
		]);

		dispatch(regridActions.getParcelsInArea(boundsPoly)).then((res) => {
			if (res?.status === "error") {
				toast.error(res.message);
				console.error(res.message);
				return;
			}

			setParcelList((prev) => {
				const copy = _.cloneDeep(prev);
				const newFeatures = res.parcels.features.filter((f) => !selectedParcelList.includes(f.properties.ll_uuid));
				copy.features = [...copy.features, ...newFeatures];
				return copy;
			});
		});
	};

	const onDrawPolygon = useCallback(() => {
		if (drawRef.current) {
			if (toggledControl === "polygon") {
				drawRef.current.changeMode("simple_select");
				setToggledControl("none");
			} else {
				drawRef.current.changeMode("draw_polygon");
				setToggledControl("polygon");
			}
		}
	}, [toggledControl]);

	const onCancelDrawPolygon = () => {
		if (drawRef.current) {
			drawRef.current.changeMode("simple_select");
			setIsDrawing(false);
			drawRef.current.deleteAll();
			setToggledControl("none");
		}
	};

	const onFinishDrawPolygon = () => {
		if (drawRef.current) {
			drawRef.current.changeMode("simple_select");
			const polygons = drawRef.current.getAll();
			const multiPolygon = combine(polygons);
			const multiId = uuid.v4();
			const centerPt = center(multiPolygon);
			multiPolygon.features[0].properties.ll_uuid = multiId;
			multiPolygon.features[0].properties.fields = {};
			multiPolygon.features[0].properties.fields.lat = centerPt.geometry.coordinates[1];
			multiPolygon.features[0].properties.fields.lon = centerPt.geometry.coordinates[0];
			setIsDrawing(false);
			drawRef.current.deleteAll();
			setToggledControl("none");
			setParcelList((prev) => {
				const copy = _.cloneDeep(prev);
				copy.features = [...copy.features, multiPolygon.features[0]];
				return copy;
			});
			setFocusedParcel(multiId);
			onAddParcel(multiId);
			// setSelectedParcelList((prev) => [...prev, multiId]);
		}
	};

	const query = (query) => {
		query = query.split(" ").join("+");
		setSearchOpen(true);
		dispatch(mapboxSearchActions.getSearchSuggestions(query, userId, { types: "address,poi,city" })).then((res) => {
			setMapSearchSuggestions(res.suggestions);
		});
	};

	const retrieveSuggestion = (suggestionId) => {
		dispatch(mapboxSearchActions.retrieveFromSearchSuggestion(suggestionId, userId)).then((res) => {
			setMapSearchResult(res.features[0]);
		});
	};

	const throttledQuery = useCallback(_.debounce(query, 500), []);

	const closeSearchResults = useCallback(() => {
		setSearchOpen(false);
	}, []);

	function check_lat_lon(lat, lon) {
		let validLat = regexLat.test(lat);
		let validLon = regexLon.test(lon);
		return validLat && validLon;
	}

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				backgroundColor: "#aaa",
				borderRadius: 8,
				overflow: "hidden",
				position: "relative"
			}}
		>
			<ParcelMapWrapper
				mapRef={mapRef}
				drawRef={drawRef}
				parcelList={parcelList}
				hoveredParcel={hoveredParcel}
				setHoveredParcel={setHoveredParcel}
				focusedParcel={focusedParcel}
				setFocusedParcel={setFocusedParcel}
				selectedParcelList={selectedParcelList}
				setSelectedParcelList={setSelectedParcelList}
				isDrawing={isDrawing}
				initCoords={initCoords}
				visibleLayers={visibleLayers}
				onAddParcel={onAddParcel}
			/>
			{!isDrawing ? (
				<>
					<div
						style={{
							position: "absolute",
							top: 16,
							left: 16,
							display: "flex",
							flexDirection: "column"
						}}
					>
						<Button
							className="mapButton"
							onClick={() => {
								setIsDrawing(true);
								onDrawPolygon();
							}}
						>
							<Icon name="pencil" />
							Draw Bounds
						</Button>
						{/* <Button className="mapButton" style={{ marginTop: 8 }}>
						<Icon name="point" />
						Drop a Pin
					</Button> */}
					</div>

					<div
						className={`mapTopRight${searchOpen ? " open" : ""}`}
						onClick={(e) => {
							e.stopPropagation();
							setSearchOpen(true);
						}}
						// onBlur={() => {
						// 	setSearchOpen(false);
						// }}
					>
						<Input className="mapSearch" icon placeholder="Search...">
							<input
								value={mapSearchQuery}
								onChange={(e) => {
									const value = e.target.value;
									let coords = [];
									setMapSearchQuery(value);
									if (value.trim().length !== 0) {
										if ((coords = value.split(",")).length === 2 && check_lat_lon(coords[0].trim(), coords[1].trim())) {
											setMapSearchSuggestions([{ type: "coords", coords: coords.map((c) => parseFloat(c)) }]);
										} else {
											throttledQuery(value);
										}
									} else {
										setMapSearchSuggestions([]);
									}
								}}
							/>
							<Icon name="search" />
						</Input>
						{!!mapSearchSuggestions?.length && (
							<List className="mapSearchSuggestions">
								{_.map(mapSearchSuggestions, (s) => {
									if (s.type === "coords") {
										return (
											<List.Item
												key={"jump-to-coords"}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setInitCoords({ latitude: s.coords[0], longitude: s.coords[1], zoom: 15 });
												}}
												className="mapboxSearchItem"
											>
												<List.Content>
													<List.Header>Jump to Coordinates</List.Header>
													<List.Description>{s.coords.join(", ")}</List.Description>
												</List.Content>
											</List.Item>
										);
									} else {
										return (
											<List.Item
												key={s.mapbox_id}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setMapSearchQuery(s.name);
													retrieveSuggestion(s.mapbox_id);
													setSearchOpen(false);
												}}
												className="mapboxSearchItem"
											>
												<List.Content>
													<List.Header>{s.name}</List.Header>
													<List.Description>{s.full_address}</List.Description>
												</List.Content>
											</List.Item>
										);
									}
								})}
							</List>
						)}
					</div>

					<Button
						className="mapButton"
						style={{ position: "absolute", left: 0, right: 0, bottom: 16, margin: "0 auto" }}
						onClick={() => onSearchThisArea()}
					>
						<Icon className="parcels" style={{ height: "1em" }} />
						Fetch parcels in this area
					</Button>
				</>
			) : (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						display: "flex",
						background: "var(--background)",
						width: "100%"
						// borderRadius: "8px 8px 0 0"
					}}
				>
					<div style={{ display: "flex" }}>
						<Button
							className="drawButton"
							icon
							onClick={() => {
								onCancelDrawPolygon();
							}}
						>
							<Icon name="close" />
						</Button>
					</div>
					<div style={{ flexGrow: 2 }} />
					<div style={{ display: "flex" }}>
						<Button
							className={`drawButton ${toggledControl === "polygon" ? "toggled" : ""}`}
							icon
							onClick={() => {
								onDrawPolygon();
							}}
						>
							<Icon className="polygon" />
						</Button>
						<Button
							className="drawButton"
							icon
							onClick={() => {
								onFinishDrawPolygon();
							}}
						>
							<Icon name="check" />
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

ParcelMapViewer.propTypes = {
	userId: PropTypes.string,
	parcelList: PropTypes.object,
	setParcelList: PropTypes.func,
	hoveredParcel: PropTypes.string,
	setHoveredParcel: PropTypes.func,
	focusedParcel: PropTypes.string,
	setFocusedParcel: PropTypes.func,
	selectedParcelList: PropTypes.object,
	setSelectedParcelList: PropTypes.func,
	initCoords: PropTypes.object,
	setInitCoords: PropTypes.func,
	visibleLayers: PropTypes.array,
	onAddParcel: PropTypes.func
};

export default ParcelMapViewer;
