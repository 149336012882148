import React, { useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import OrthoDropdownComponent from "./orthoDropdownComponent";

const OrthoDropdownParentComponent = React.forwardRef(
	(
		{
			level,
			edit,
			viewer,
			setOrthoImageTypeId,
			heatmapTimelineRef,
			setOrthoTypes,
			buildMapImage,
			movePlot,
			handleFieldDropdownChange,
			removeFieldGrid,
			jumpToCoords,
			redrawPlots,
			drawFarmPlots,
			setOrthoTimelineFlights,
			imageFound,
			singleFeatureToggle,
			resetMap,
			setCropToFilterOn,
			fieldDropdownRef,
			trialDropdownRef,
			cropDropdownRef,
			dateRangeDropdownRef
		},
		parentRef
	) => {
		const [showDropdowns, setShowDropdowns] = useState(false);
		let dropdownRef = React.useRef(null);

		OrthoDropdownParentComponent.displayName = "OrthoDropdownParentComponent";

		useImperativeHandle(parentRef, () => {
			return {
				showDropdowns: toggleDropdowns,
				innerRef: dropdownRef
			};
		});

		function toggleDropdowns() {
			setShowDropdowns(true);
		}

		return (
			<>
				{showDropdowns && (
					<OrthoDropdownComponent
						ref={dropdownRef}
						level={level}
						edit={edit}
						viewer={viewer}
						setOrthoIdToPass={(id) => setOrthoImageTypeId(id)}
						heatmapTimelineRef={heatmapTimelineRef}
						setOrthoTypes={(orthoTypes) => setOrthoTypes(orthoTypes)}
						buildMapImage={(res, orthoId) => buildMapImage(res, orthoId)}
						movePlot={(key, e) => movePlot(key, e)}
						handleFieldDropdownChange={(option) => handleFieldDropdownChange(option)}
						removeFieldGrid={(fieldId) => removeFieldGrid(fieldId)}
						jumpToCoords={(coords) => jumpToCoords(coords)}
						redrawPlots={(flightId, fieldId, trialId, sensorChanged, res, orthoId, first) =>
							redrawPlots(flightId, fieldId, trialId, sensorChanged, res, orthoId, first)
						}
						drawFarmPlots={(flightDate, flightImages, analysisOptions, farmId, orthoId, first) =>
							drawFarmPlots(flightDate, flightImages, analysisOptions, farmId, orthoId, first)
						}
						setOrthoTimelineFlights={(flights) => setOrthoTimelineFlights(flights)}
						imageFound={(truth) => imageFound(truth)}
						singleFeatureToggle={(type, id) => singleFeatureToggle(type, id)}
						resetMap={() => resetMap()}
						setCropToFilterOn={(crop) => setCropToFilterOn(crop)}
						fieldDropdownRef={fieldDropdownRef}
						trialDropdownRef={trialDropdownRef}
						cropDropdownRef={cropDropdownRef}
						dateRangeDropdownRef={dateRangeDropdownRef}
					/>
				)}
			</>
		);
	}
);

OrthoDropdownParentComponent.propTypes = {
	level: PropTypes.string.isRequired,
	edit: PropTypes.bool.isRequired,
	viewer: PropTypes.bool.isRequired,
	buildMapImage: PropTypes.func.isRequired,
	movePlot: PropTypes.func.isRequired,
	handleFieldDropdownChange: PropTypes.func.isRequired,
	setOrthoImageTypeId: PropTypes.func.isRequired,
	heatmapTimelineRef: PropTypes.object,
	removeFieldGrid: PropTypes.func.isRequired,
	setOrthoTypes: PropTypes.func.isRequired,
	jumpToCoords: PropTypes.func.isRequired,
	redrawPlots: PropTypes.func.isRequired,
	drawFarmPlots: PropTypes.func,
	setOrthoTimelineFlights: PropTypes.func,
	imageFound: PropTypes.func.isRequired,
	singleFeatureToggle: PropTypes.func,
	resetMap: PropTypes.func,
	setCropToFilterOn: PropTypes.func,
	fieldDropdownRef: PropTypes.any,
	trialDropdownRef: PropTypes.any,
	cropDropdownRef: PropTypes.any,
	dateRangeDropdownRef: PropTypes.any
};

export default OrthoDropdownParentComponent;
