import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";
import createSupplementaryPoints from "@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points.js";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import MapboxGl from "mapbox-gl";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { Button, Checkbox, Grid, Icon, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";
// import * as fieldActions from "../../../redux/actions/fieldActions.js";
// import * as trialActions from "../../../redux/actions/trialActions.js";
import { ApplicationArea } from "../ApplicationAreas";
import AddFieldModal from "./AddFieldModal.js";
import ControlButtons from "./ControlButtons.tsx";
import LineMeasurementMode from "./DrawModes/LineMeasurementMode";
import NewFieldMode from "./DrawModes/NewFieldMode";
import PointMode from "./DrawModes/PointMode";
import PolygonMeasureMode from "./DrawModes/PolygonMeasureMode";
import RectangleMeasureMode from "./DrawModes/RectangleMeasurementMode";
import InfoModal from "./InfoModal.tsx";
import OrthoTimeline from "./OrthoTimeline";
import AddExistingFieldsModal from "./addExistingFieldsModal";
import AlignPlotsModal from "./alignPlotsModal.js";
import EditFieldModal from "./editFieldModal.js";
import HeatmapTimeline from "./heatmapTimeline";
import OrthoAnalyzedModal from "./orthoAnalyzedModal.js";
import {
	AddField,
	AlignPlots,
	ApplyShader,
	CoordinateTracker,
	DEFMarkerToggle,
	FieldBoundaryToggle,
	FieldLabelToggle,
	FieldPivotLlSet,
	FieldPivotToggle,
	FitToHeight,
	FitToWidth,
	FlagPlots,
	HeatmapOptions,
	HeatmapOverlayLabels,
	ImageLayers,
	LockToggle,
	LowerLeftToggle,
	MeasureDistance,
	MeasurePopup,
	PlotLabelToggle,
	PlotToggle,
	QuantifiedRegionToggle,
	ResetOrientation,
	RevertAlignment,
	Rotate,
	RotateLeft,
	//ViewerDataExportButton,
	RotateRight,
	RowToggle,
	ScaleInward,
	ScaleOutward,
	ShaderHistogram,
	TrialLabelToggle,
	TrialToggle,
	FarmBoundsToggle
} from "./orthoControlComponents.js";
import * as ControlFunctions from "./orthoControlFunctions.js";
import OrthoDropdownParentComponent from "./orthoDropdownParentComponent.js";
import { styles } from "./orthoStyles.js";
import OverrideSimpleSelect from "./overrideSimpleSelect";
import "./styles.css";
import ViewerDropdown from "./viewerDropdown.tsx";

const Ortho = ({ level, edit, viewer, handleBack, handleContinue, setOrthoImageTypes, setDraw, setImageFound }) => {
	const dispatch = useDispatch();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const queryClient = useQueryClient();
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	let canvas = null;
	let dropdownRef = React.createRef();
	let addExistingFieldsModal = React.createRef();
	let addFieldModal = React.createRef();
	let draw = React.createRef();
	let orthoImageTypeId = null;
	let plotPopup = null;
	let statusPopup = null;
	let selectedPlot = null;
	let selectedSubsample = null;
	let orthoAnalyzedModal = React.createRef();
	let alignPlotsModal = React.createRef();
	// let viewerDataExportModal = React.createRef();
	const [mapboxStyle] = useState("mapbox://styles/mapbox/satellite-streets-v11");

	let fieldDropdownRef = React.createRef();
	let trialDropdownRef = React.createRef();
	let cropDropdownRef = React.createRef();
	let dateRangeDropdownRef = React.createRef();
	let toolsRef = React.createRef();

	let controlComponentRefs = {
		llToggleRef: React.createRef(),
		fieldToggleRef: React.createRef(),
		plotToggleRef: React.createRef(),
		rowToggleRef: React.createRef(),
		fitToHeightRef: React.createRef(),
		fitToWidthRef: React.createRef(),
		fieldLabelToggleRef: React.createRef(),
		resetOrientationRef: React.createRef(),
		lockToggleRef: React.createRef(),
		fieldPivotToggleRef: React.createRef(),
		fieldPivotLlSetRef: React.createRef(),
		rotateLeftRef: React.createRef(),
		rotateRightRef: React.createRef(),
		scaleOutwardRef: React.createRef(),
		scaleInwardRef: React.createRef(),
		trialToggle: React.createRef(),
		trialLabelToggleRef: React.createRef(),
		plotLabelToggleRef: React.createRef(),
		defMarkerToggleRef: React.createRef(),
		flagPlotsRef: React.createRef(),
		alignPlotsRef: React.createRef(),
		measureDistanceRef: React.createRef(),
		quantifiedRegionToggleRef: React.createRef(),
		heatmapOverlayLabelsRef: React.createRef(),
		editFieldModal: React.createRef(),
		heatmapTimelineRef: React.createRef(),
		orthoTimelineRef: React.createRef(),
		applyShaderRef: React.createRef(),
		shaderHistogramRef: React.createRef(),
		heatmapOptionsRef: React.createRef(),
		imageLayersRef: React.createRef(),
		measurePopupRef: React.createRef(),
		coordinateTrackerRef: React.createRef(),
		revertAlignmentRef: React.createRef(),
		farmBoundsToggleRef: React.createRef()
	};

	const Map = ReactMapboxGl({
		accessToken: process.env.MAPBOX_ACCESS_TOKEN,
		maxZoom: 26,
		bearingSnap: 0
	});

	let overrideSimpleSelect = _.cloneDeep(MapboxDraw.modes.simple_select);
	let overrideDirectSelect = _.cloneDeep(MapboxDraw.modes.direct_select);

	if (level !== "flight" || (edit && viewer)) {
		overrideSimpleSelect = _.extend(overrideSimpleSelect, {
			onDrag(state, e) {
				if (this.getSelected().length && this.getSelected()[0].properties.meta === "drawn_feature") {
					if (state.canDragMove) return this.dragMove(state, e);
					if (this.drawConfig.boxSelect && state.canBoxSelect) return this.whileBoxSelect(state, e);
				}
			},
			toDisplayFeatures(state, geojson, display) {
				geojson.properties.active = this.isSelected(geojson.properties.id)
					? Constants.activeStates.ACTIVE
					: Constants.activeStates.INACTIVE;
				display(geojson);
				this.fireActionable();
				if (
					geojson.properties.active !== Constants.activeStates.ACTIVE ||
					geojson.geometry.type === Constants.geojsonTypes.POINT
				)
					return;
				if (geojson.properties?.user_meta === "drawn_feature") {
					createSupplementaryPoints(geojson).forEach(display);
				}
			}
		});

		overrideDirectSelect = _.extend(overrideDirectSelect, {
			onDrag(state, e) {
				if (
					state.canDragMove !== true ||
					(this.getSelected().length && this.getSelected()[0].properties.meta !== "drawn_feature")
				)
					return;
				state.dragMoving = true;
				e.originalEvent.stopPropagation();

				const delta = {
					lng: e.lngLat.lng - state.dragMoveLocation.lng,
					lat: e.lngLat.lat - state.dragMoveLocation.lat
				};
				if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
				else this.dragFeature(state, e, delta);

				state.dragMoveLocation = e.lngLat;
			},
			toDisplayFeatures(state, geojson, push) {
				if (state.featureId === geojson.properties.id) {
					geojson.properties.active = "true";
					push(geojson);
					if (state.feature?.properties?.meta === "drawn_feature") {
						createSupplementaryPoints(geojson, {
							map: this.map,
							midpoints: true,
							selectedPaths: state.selectedCoordPaths
						}).forEach(push);
					}
				} else {
					geojson.properties.active = "false";
					push(geojson);
				}
				this.fireActionable(state);
			}
		});
	} else {
		overrideSimpleSelect = OverrideSimpleSelect;
		overrideDirectSelect = _.extend(overrideDirectSelect, {
			toDisplayFeatures(state, geojson, push) {
				if (state.featureId === geojson.properties.id) {
					geojson.properties.active = "true";
					push(geojson);
					if (state.feature?.properties?.meta === "drawn_feature") {
						createSupplementaryPoints(geojson, {
							map: this.map,
							midpoints: true,
							selectedPaths: state.selectedCoordPaths
						}).forEach(push);
					}
				} else {
					geojson.properties.active = "false";
					push(geojson);
				}
				this.fireActionable(state);
			}
		});
	}

	const handleSelectRow = () => {
		if (selectedPlot) {
			ControlFunctions.selectRow(selectedPlot, draw);
			controlComponentRefs.flagPlotsRef?.current?.isSubsample(false);
		} else if (selectedSubsample) {
			ControlFunctions.selectSubsampleRow(selectedSubsample, draw);
			controlComponentRefs.flagPlotsRef?.current?.isSubsample(true);
		}

		controlComponentRefs.flagPlotsRef?.current?.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	const handleSelectColumn = () => {
		if (selectedPlot) {
			ControlFunctions.selectColumn(selectedPlot, draw);
			controlComponentRefs.flagPlotsRef?.current?.isSubsample(false);
		} else if (selectedSubsample) {
			ControlFunctions.selectSubsampleColumn(selectedSubsample, draw);
			controlComponentRefs.flagPlotsRef?.current?.isSubsample(true);
		}

		controlComponentRefs.flagPlotsRef?.current?.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	const handleSelectGrid = () => {
		if (selectedPlot) {
			ControlFunctions.selectGrid(selectedPlot, draw, level);
			controlComponentRefs.flagPlotsRef?.current?.isSubsample(false);
		} else if (selectedSubsample) {
			ControlFunctions.selectSubsampleGrid(selectedSubsample, draw);
			controlComponentRefs.flagPlotsRef?.current?.isSubsample(true);
		}

		controlComponentRefs.flagPlotsRef?.current?.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	const handleSelectRep = () => {
		ControlFunctions.selectRep(selectedPlot, selectedSubsample, draw);
		controlComponentRefs.flagPlotsRef?.current?.isSubsample(false);

		controlComponentRefs.flagPlotsRef?.current?.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	function setupControls(evt) {
		canvas = evt;
		ControlFunctions.resetControls();
		if (dropdownRef.current) {
			dropdownRef.current.showDropdowns();

			canvas.on("mousedown", (e) => {
				if (e.originalEvent.button === 2) {
					//-- Checking for a combined field being selected
					let featuresAtPoint = draw.current.draw.getSelected();
					if (featuresAtPoint?.features[0]?.properties.type === "field") {
						e.preventDefault();
						ControlFunctions.uncombinePlotsInField(draw);
					}
				}
			});

			if (level === "flight") {
				ControlFunctions.addExistingPlotsInFlight(
					moduleNavigation.flightId,
					getTokenSilently,
					dispatch,
					draw,
					canvas,
					controlComponentRefs,
					clientId,
					moduleNavigation,
					dropdownRef,
					level
				);

				setDraw(draw);

				ControlFunctions.fieldLabelToggle(draw, canvas, controlComponentRefs, moduleNavigation, dropdownRef, level);

				controlComponentRefs.shaderHistogramRef.current.setDisabled(false);
				controlComponentRefs.applyShaderRef.current.setDisabled(false);

				if (!edit && !viewer) {
					ControlFunctions.plotToggle(draw, controlComponentRefs);
					controlComponentRefs.plotToggleRef.current.setToggleState(false);
				}
			}
			if (level === "field") {
				if (!controlComponentRefs.trialToggle.current.checkToggleState) {
					ControlFunctions.trialToggle(draw);
					controlComponentRefs.trialToggle.current.setTrialToggled(true);
				}

				if (!controlComponentRefs.trialLabelToggleRef.current.checkToggleState) {
					ControlFunctions.trialLabelToggle(draw, canvas, controlComponentRefs, moduleNavigation, dropdownRef, level);
					controlComponentRefs.trialLabelToggleRef.current.setToggleState(true);
				}

				_.map(controlComponentRefs, (componentRef) => {
					if (
						componentRef &&
						componentRef.current &&
						componentRef !== controlComponentRefs.flagPlotsRef &&
						componentRef !== controlComponentRefs.quantifiedRegionToggleRef &&
						componentRef !== controlComponentRefs.heatmapOverlayLabelsRef &&
						componentRef !== controlComponentRefs.heatmapOptionsRef &&
						componentRef.current.setDisabled
					) {
						componentRef.current.setDisabled(false);
					}
				});
			}
			if (level === "trial") {
				ControlFunctions.fieldBoundaryToggle(draw);
				ControlFunctions.lowerLeftToggle(draw);

				if (!controlComponentRefs.plotLabelToggleRef.current.checkToggleState) {
					ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs);
					controlComponentRefs.plotLabelToggleRef.current.setToggleState(true);
				}

				if (!controlComponentRefs.defMarkerToggleRef.current.checkToggleState) {
					ControlFunctions.defMarkerToggle(canvas, controlComponentRefs);
				}

				_.map(controlComponentRefs, (componentRef) => {
					if (
						componentRef?.current?.setDisabled &&
						componentRef !== controlComponentRefs.flagPlotsRef &&
						componentRef !== controlComponentRefs.quantifiedRegionToggleRef &&
						componentRef !== controlComponentRefs.heatmapOverlayLabelsRef &&
						componentRef !== controlComponentRefs.heatmapOptionsRef &&
						componentRef.current.setDisabled
					) {
						componentRef.current.setDisabled(false);
					}
				});
			}
			if (level === "farm") {
				_.map(controlComponentRefs, (c) => {
					if (
						c.current?.setDisabled &&
						c !== controlComponentRefs.flagPlotsRef &&
						c !== controlComponentRefs.quantifiedRegionToggleRef &&
						c !== controlComponentRefs.heatmapOverlayLabelsRef &&
						c !== controlComponentRefs.heatmapOptionsRef
					) {
						c.current.setDisabled(false);
					}
				});

				ControlFunctions.applyClampedShader(canvas, 0, 1);
				controlComponentRefs.applyShaderRef.current.setSelectedShader("clamped");
				ControlFunctions.trialToggle(draw);
				controlComponentRefs.trialToggle.current.setTrialToggled(true);
			}

			canvas.on("contextmenu", (e) => {
				if (ControlFunctions.isFieldCombined()) {
					ControlFunctions.uncombinePlotsInField(draw);

					let featuresAtPoint = canvas.queryRenderedFeatures(e.point, {
						layers: ["gl-draw-polygon-fill-inactive.cold", "gl-draw-polygon-fill-active.hot"]
					});

					let indexOfPlot = _.findIndex(_.map(featuresAtPoint, "properties"), { user_type: "plot" });
					if (indexOfPlot !== -1) {
						selectedPlot =
							featuresAtPoint[indexOfPlot].properties.user_plotId ?? featuresAtPoint[indexOfPlot].properties.id;
					}

					draw.current.draw.changeMode("simple_select", {
						featureIds: [selectedPlot]
					});
				}

				let featuresAtPoint = canvas.queryRenderedFeatures(e.point, {
					layers: ["gl-draw-polygon-fill-inactive.cold", "gl-draw-polygon-fill-active.hot"]
				});

				let indexOfQuantifiedRegion = _.findIndex(_.map(featuresAtPoint, "properties"), { user_type: "subsample" });
				let indexOfPlot = _.findIndex(_.map(featuresAtPoint, "properties"), { user_type: "plot" });
				if (indexOfQuantifiedRegion !== -1) {
					selectedSubsample = featuresAtPoint[indexOfQuantifiedRegion].properties.id;
				} else if (indexOfPlot !== -1) {
					selectedPlot =
						featuresAtPoint[indexOfPlot].properties.user_plotId ?? featuresAtPoint[indexOfPlot].properties.id;
				}

				if (indexOfQuantifiedRegion !== -1 || indexOfPlot !== -1) {
					if (plotPopup !== null) {
						plotPopup.remove();
					}

					if (level !== "trial") {
						plotPopup = new MapboxGl.Popup({ closeButton: false })
							.setLngLat(e.lngLat)
							.setHTML(
								`<div class="ui grid" style="font-size: medium" id="plotPopup">
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-row">Select Range</button>
										</div>
									</div>
									<div class="row">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-column">Select Column</button>
										</div>
									</div>
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-grid">Select Grid</button>
										</div>
									</div>
								</div>`
							)
							.addTo(canvas);
					} else {
						plotPopup = new MapboxGl.Popup({ closeButton: false })
							.setLngLat(e.lngLat)
							.setHTML(
								`<div class="ui grid" style="font-size: medium" id="plotPopup">
									<div class="row">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-rep">Select Replicate</button>
										</div>
									</div>
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-row">Select Range</button>
										</div>
									</div>
									<div class="row">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-column">Select Column</button>
										</div>
									</div>
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-grid">Select Grid</button>
										</div>
									</div>
								</div>`
							)
							.addTo(canvas);
					}

					document.getElementById("button-select-row").addEventListener("click", () => {
						handleSelectRow();
						selectedPlot = null;
						selectedSubsample = null;
						plotPopup.remove();
					});
					document.getElementById("button-select-column").addEventListener("click", () => {
						handleSelectColumn();
						selectedPlot = null;
						selectedSubsample = null;
						plotPopup.remove();
					});
					document.getElementById("button-select-grid").addEventListener("click", () => {
						handleSelectGrid();
						selectedPlot = null;
						selectedSubsample = null;
						plotPopup.remove();
					});
					if (level === "trial") {
						document.getElementById("button-select-rep").addEventListener("click", () => {
							handleSelectRep();
							selectedPlot = null;
							selectedSubsample = null;
							plotPopup.remove();
						});
					}
				}
			});

			canvas.on("mouseleave", "plotPopup", () => {
				plotPopup.remove();
			});

			canvas.on("draw.liveUpdate", () => {
				ControlFunctions.handleDragging(draw);
			});

			canvas.on("mouseenter", `def-labels`, (e) => {
				statusPopup = new MapboxGl.Popup({ closeOnClick: false, closeButton: false, anchor: "top-left" })
					.setLngLat(e.features[0].geometry.coordinates)
					.setOffset([32, 0])
					.setMaxWidth("500px")
					.setHTML(
						`<div class="ui" style="font-size: 18px">
							<h2><u>Annotation Summary for Plot ${e.features[0].properties.plotName ?? ""}</u></h2>
							${_.reduce(
								Object.entries(JSON.parse(e.features[0].properties.statuses)),
								(acc, lst) => {
									return (
										acc +
										`<div>
											<u>${
												new Date(parseInt(lst[0])).toLocaleString() === new Date(Date.UTC(0, 0)).toLocaleString()
													? "All Dates"
													: moment(parseInt(lst[0])).format("MM/DD/YY")
											}:</u>
											${_.reduce(
												_.flatten(lst[1]),
												(a, ls) => {
													if (ls.level === "Partial") {
														return (
															a +
															_.reduce(
																Object.keys(ls.quantifiedRegionNames),
																(ac) => {
																	return (
																		ac +
																		`<div>
																	<p><b>Partially ${ls.status}:</b> ${ls.annotationClassifier ? ls.annotationClassifier : ""} ${
																			ls.notes ? " - " + ls.notes : ""
																		}${ls.notes || ls.annotationClassifier ? " - " : ""}${ls.modifiedBy} on ${
																			ls.modifiedDateTime
																		}</p>
																</div>`
																	);
																},
																""
															)
														);
													}
													return (
														a +
														`<div>
													<p><b>${ls.status}:</b> ${ls.annotationClassifier ? ls.annotationClassifier : ""} ${ls.notes ? " - " + ls.notes : ""}${
															ls.notes || ls.annotationClassifier ? " - " : ""
														}${ls.modifiedBy} on ${ls.modifiedDateTime}</p>
												</div>`
													);
												},
												""
											)}
								</div><br />`
									);
								},
								""
							)}
					</div>`
					);
				statusPopup.addTo(canvas);
			});

			canvas.on("mouseleave", `def-labels`, () => {
				statusPopup.remove();
			});
		}
	}

	function SetControlsToDefaultViewerSetting() {
		let existingDefaultViewerSetting = ControlFunctions.getDefaultViewerSetting();

		// if (existingDefaultViewerSetting === null) {
		// 	document.getElementById("reset-button").disabled = true;
		// } else {
		// 	document.getElementById("reset-button").disabled = false;
		if (existingDefaultViewerSetting) {
			controlComponentRefs.orthoTimelineRef?.current?.setCurrentFlightId(existingDefaultViewerSetting.viewableFlightId);
			dropdownRef?.current?.innerRef?.current?.setSelectedOrthoId(
				existingDefaultViewerSetting.viewableOrthoImageTypeId
			);
			if (existingDefaultViewerSetting.groundDataAssessmentId !== null) {
				//Ground Data
				controlComponentRefs.heatmapTimelineRef.current.setSelectedFlightId(
					existingDefaultViewerSetting.groundDataSetId
				);
				controlComponentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(
					existingDefaultViewerSetting.groundDataAssessmentId
				);
			} else if (existingDefaultViewerSetting.curveModelAnalysisId !== null) {
				// Curve Model
				controlComponentRefs.heatmapTimelineRef.current.setSelectedFlightId(null);
				controlComponentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(
					existingDefaultViewerSetting.analysisIdForCurveModel + "/" + existingDefaultViewerSetting.curveModelAnalysisId
				);
			} else {
				// Analysis
				controlComponentRefs.heatmapTimelineRef.current.setSelectedFlightId(
					existingDefaultViewerSetting.analysisFlightId
				);
				controlComponentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(existingDefaultViewerSetting.analysisId);
			}

			controlComponentRefs.heatmapTimelineRef.current.setHeatmapEnabled(existingDefaultViewerSetting.toggleHeatmapData);
		}
	}

	// async function handleResetButtonClicked() {
	// 	// document.getElementById("reset-button").disabled = false;

	// 	const accessToken = await getTokenSilently();
	// 	var selectedAnalysisType = controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisType();
	// 	if (level === "field") {
	// 		var defaultViewerSettingForField = {
	// 			fieldId: moduleNavigation.fieldId,
	// 			toggleHeatmapData: controlComponentRefs.heatmapTimelineRef.current.getHeatmapEnabled(),
	// 			toggleImageType: true,
	// 			viewableFlightId: controlComponentRefs.orthoTimelineRef.current.getCurrentFlightId(),
	// 			viewableOrthoImageTypeId: dropdownRef.current.innerRef.current.getSelectedOrthoId(),
	// 			groundDataSetId:
	// 				selectedAnalysisType == "Assessment"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
	// 					: null,
	// 			groundDataAssessmentId:
	// 				selectedAnalysisType == "Assessment"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
	// 					: null,
	// 			curveModelAnalysisId:
	// 				selectedAnalysisType == "CurveModel"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[1]
	// 					: null,
	// 			analysisIdForCurveModel:
	// 				selectedAnalysisType == "CurveModel"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
	// 					: null,
	// 			analysisId:
	// 				selectedAnalysisType == "Analysis"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
	// 					: null,
	// 			analysisFlightId:
	// 				selectedAnalysisType == "Analysis"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
	// 					: null
	// 		};

	// 		var currentDefaultViewerSettingForField = ControlFunctions.getDefaultViewerSetting();
	// 		if (currentDefaultViewerSettingForField === null) {
	// 			dispatch(
	// 				fieldActions.createDefaultViewerSettingForField(
	// 					clientId,
	// 					defaultViewerSettingForField.fieldId,
	// 					defaultViewerSettingForField.toggleHeatmapData,
	// 					defaultViewerSettingForField.toggleImageType,
	// 					defaultViewerSettingForField.viewableFlightId,
	// 					defaultViewerSettingForField.viewableOrthoImageTypeId,
	// 					defaultViewerSettingForField.groundDatasetId,
	// 					defaultViewerSettingForField.groundDataAssessmentId,
	// 					defaultViewerSettingForField.curveModelAnalysisId,
	// 					defaultViewerSettingForField.analysisIdForCurveModel,
	// 					defaultViewerSettingForField.analysisId,
	// 					defaultViewerSettingForField.analysisFlightId,
	// 					accessToken
	// 				)
	// 			).then((res) => {
	// 				defaultViewerSettingForField.id = res.data;
	// 				ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForField);
	// 				toast.success("Default viewer settings created successfully.");
	// 			});
	// 		} else {
	// 			dispatch(
	// 				fieldActions.updateDefaultViewerSettingForField(
	// 					clientId,
	// 					currentDefaultViewerSettingForField.id,
	// 					defaultViewerSettingForField.fieldId,
	// 					defaultViewerSettingForField.toggleHeatmapData,
	// 					defaultViewerSettingForField.toggleImageType,
	// 					defaultViewerSettingForField.viewableFlightId,
	// 					defaultViewerSettingForField.viewableOrthoImageTypeId,
	// 					defaultViewerSettingForField.groundDatasetId,
	// 					defaultViewerSettingForField.groundDataAssessmentId,
	// 					defaultViewerSettingForField.curveModelAnalysisId,
	// 					defaultViewerSettingForField.analysisIdForCurveModel,
	// 					defaultViewerSettingForField.analysisId,
	// 					defaultViewerSettingForField.analysisFlightId,
	// 					accessToken
	// 				)
	// 			).then(() => {
	// 				defaultViewerSettingForField.id = currentDefaultViewerSettingForField.id;
	// 				ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForField);
	// 				toast.success("Default viewer settings updated successfully.");
	// 			});
	// 		}

	// 		ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForTrial);
	// 	} else if (level === "trial") {
	// 		var defaultViewerSettingForTrial = {
	// 			trialId: moduleNavigation.trialId,
	// 			toggleHeatmapData: controlComponentRefs.heatmapTimelineRef.current.getHeatmapEnabled(),
	// 			toggleImageType: true,
	// 			viewableFlightId: controlComponentRefs.orthoTimelineRef.current.getCurrentFlightId(),
	// 			viewableOrthoImageTypeId: dropdownRef.current.innerRef.current.getSelectedOrthoId(),
	// 			groundDataSetId:
	// 				selectedAnalysisType == "Assessment"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
	// 					: null,
	// 			groundDataAssessmentId:
	// 				selectedAnalysisType == "Assessment"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
	// 					: null,
	// 			curveModelAnalysisId:
	// 				selectedAnalysisType == "CurveModel"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[1]
	// 					: null,
	// 			analysisIdForCurveModel:
	// 				selectedAnalysisType == "CurveModel"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
	// 					: null,
	// 			analysisId:
	// 				selectedAnalysisType == "Analysis"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
	// 					: null,
	// 			analysisFlightId:
	// 				selectedAnalysisType == "Analysis"
	// 					? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
	// 					: null
	// 		};

	// 		var currentDefaultViewerSettingForTrial = ControlFunctions.getDefaultViewerSetting();
	// 		if (currentDefaultViewerSettingForTrial === null) {
	// 			dispatch(
	// 				trialActions.createDefaultViewerSettingForTrial(
	// 					clientId,
	// 					defaultViewerSettingForTrial.trialId,
	// 					defaultViewerSettingForTrial.toggleHeatmapData,
	// 					defaultViewerSettingForTrial.toggleImageType,
	// 					defaultViewerSettingForTrial.viewableFlightId,
	// 					defaultViewerSettingForTrial.viewableOrthoImageTypeId,
	// 					defaultViewerSettingForTrial.groundDatasetId,
	// 					defaultViewerSettingForTrial.groundDataAssessmentId,
	// 					defaultViewerSettingForTrial.curveModelAnalysisId,
	// 					defaultViewerSettingForTrial.analysisIdForCurveModel,
	// 					defaultViewerSettingForTrial.analysisId,
	// 					defaultViewerSettingForTrial.analysisFlightId,
	// 					accessToken
	// 				)
	// 			).then((res) => {
	// 				defaultViewerSettingForTrial.id = res.data;
	// 				ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForTrial);
	// 				toast.success("Default viewer settings created successfully.");
	// 			});
	// 		} else {
	// 			dispatch(
	// 				trialActions.updateDefaultViewerSettingForTrial(
	// 					clientId,
	// 					currentDefaultViewerSettingForTrial.id,
	// 					defaultViewerSettingForTrial.trialId,
	// 					defaultViewerSettingForTrial.toggleHeatmapData,
	// 					defaultViewerSettingForTrial.toggleImageType,
	// 					defaultViewerSettingForTrial.viewableFlightId,
	// 					defaultViewerSettingForTrial.viewableOrthoImageTypeId,
	// 					defaultViewerSettingForTrial.groundDatasetId,
	// 					defaultViewerSettingForTrial.groundDataAssessmentId,
	// 					defaultViewerSettingForTrial.curveModelAnalysisId,
	// 					defaultViewerSettingForTrial.analysisIdForCurveModel,
	// 					defaultViewerSettingForTrial.analysisId,
	// 					defaultViewerSettingForTrial.analysisFlightId,
	// 					accessToken
	// 				)
	// 			).then(() => {
	// 				defaultViewerSettingForTrial.id = currentDefaultViewerSettingForTrial.id;
	// 				ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForTrial);
	// 				toast.success("Default viewer settings updated successfully.");
	// 			});
	// 		}
	// 	}
	// }

	return (
		<Segment basic className="orthoViewerContainer">
			<div className={`mapContainer ${level}`}>
				<div id="distance" className="distance-container"></div>
				<Map
					style={mapboxStyle}
					containerStyle={{
						height: "100%",
						width: "100%"
					}}
					center={[0, 0]}
					zoom={[1]}
					onMouseMove={(_, e) => {
						controlComponentRefs.coordinateTrackerRef.current.setCoords(e.lngLat);
					}}
					onClick={(_, event) =>
						ControlFunctions.handleClickEvent(
							draw,
							canvas,
							dropdownRef,
							controlComponentRefs,
							level,
							event,
							moduleNavigation
						)
					}
					onStyleLoad={(e) => {
						if (e && !canvas) {
							setupControls(e);
						}
						//Check to see if the style was manually toggled
						if (ControlFunctions.checkStyleToggle()) {
							canvas = e;
							dropdownRef.current.innerRef.current.getFlightImage();
							ControlFunctions.refreshToggles(draw, canvas, controlComponentRefs, moduleNavigation, dropdownRef, level);

							ControlFunctions.fitToHeight(canvas, level);
							ControlFunctions.styleToggle();
							setupControls(e);
						}

						const nav = new MapboxGl.NavigationControl({
							showZoom: false,
							visualizePitch: true
						});
						e.addControl(nav);
					}}
				>
					<DrawControl
						displayControlsDefault={false}
						userProperties={true}
						controls={{}}
						styles={styles()}
						modes={{
							...MapboxDraw.modes,
							new_field_mode: NewFieldMode,
							simple_select: overrideSimpleSelect,
							direct_select: overrideDirectSelect,
							line_measure: LineMeasurementMode,
							polygon_measure: PolygonMeasureMode,
							rectangle_measure: RectangleMeasureMode,
							point: PointMode
						}}
						ref={draw}
						onDrawUpdate={(e) =>
							ControlFunctions.handleUpdate(e, draw, false, orthoAnalyzedModal, controlComponentRefs.measurePopupRef)
						}
						onDrawCreate={(e) =>
							ControlFunctions.handleCreate(
								e,
								draw,
								controlComponentRefs,
								addFieldModal,
								addExistingFieldsModal,
								clientId,
								getTokenSilently,
								dispatch,
								moduleNavigation.flightId,
								moduleNavigation
							)
						}
						onDrawCombine={(e) => {
							ControlFunctions.combine(e);
						}}
						onDrawUncombine={(e) => {
							ControlFunctions.uncombine(e, draw, canvas, controlComponentRefs, moduleNavigation, dropdownRef, level);
						}}
					/>
				</Map>
			</div>
			<Grid id="orthoViewerGrid" stackable={false}>
				<Grid.Row style={{ paddingBottom: "unset", paddingTop: "unset", zIndex: 10 }}>
					<Grid.Column width="16">
						<Grid className="orthoControlsContainer">
							{level === "farm" && (
								<>
									<div className="orthoControlsColumn">
										<div className="orthoControlsRow">
											<div style={{ width: 164 }}>
												<ViewerDropdown
													ref={dateRangeDropdownRef}
													label="Date Range"
													updateCallback={(val) =>
														dropdownRef?.current?.innerRef?.current?.setSelectedDateRangeOption(val)
													}
												/>
											</div>
											<div style={{ width: 200 }}>
												<ViewerDropdown
													ref={fieldDropdownRef}
													label="Fields"
													updateCallback={(val) => dropdownRef?.current?.innerRef?.current?.setSelectedFieldOption(val)}
												/>
											</div>
										</div>
										<div className="orthoControlsRow">
											<div style={{ width: 164 }}>
												<ViewerDropdown
													ref={cropDropdownRef}
													label="Crops"
													updateCallback={(val) => dropdownRef?.current?.innerRef?.current?.setSelectedCropOption(val)}
												/>
											</div>
											<div style={{ width: 200 }}>
												<ViewerDropdown
													ref={trialDropdownRef}
													label="Trials"
													updateCallback={(val) => dropdownRef?.current?.innerRef?.current?.setSelectedTrialOption(val)}
												/>
											</div>
											{/* <div>
												<Button onClick={() => {
													dispatch(orthoViewerActions)
												}}><Icon name="filter"/></Button>
											</div> */}
										</div>
										{/* <div className="orthoControlsColumnFooter">Map Filters</div> */}
									</div>
								</>
							)}
							<div className="orthoControlsColumn" style={{ flexGrow: 1 }}>
								<div className="orthoControlsRow">
									{level === "flight" && !viewer && (
										<Button
											id="form-button-back"
											floated="left"
											secondary
											compact
											content={"Back"}
											onClick={() => {
												handleBack();
											}}
										/>
									)}
									<OrthoDropdownParentComponent
										ref={dropdownRef}
										edit={edit}
										level={level}
										viewer={viewer}
										setOrthoImageTypeId={(id) => {
											orthoImageTypeId = id;
											if (level !== "flight") {
												controlComponentRefs.orthoTimelineRef.current.refresh();
											}
										}}
										heatmapTimelineRef={controlComponentRefs.heatmapTimelineRef}
										setOrthoTypes={(types) => setOrthoImageTypes(types)}
										buildMapImage={(res, orthoId) => ControlFunctions.buildMapImage(res, orthoId, canvas, edit)}
										movePlot={(key, e) =>
											ControlFunctions.movePlot(key, draw, canvas, orthoAnalyzedModal, level, edit, viewer, e)
										}
										handleFieldDropdownChange={(option) =>
											ControlFunctions.handleFieldDropdownChange(
												draw,
												canvas,
												edit,
												option,
												moduleNavigation.flightId,
												getTokenSilently,
												dispatch,
												orthoImageTypeId,
												clientId,
												controlComponentRefs,
												moduleNavigation,
												dropdownRef,
												level
											)
										}
										removeFieldGrid={(fieldId) =>
											ControlFunctions.removeFieldGrid(
												moduleNavigation.flightId,
												fieldId,
												clientId,
												draw,
												canvas,
												getTokenSilently,
												dispatch,
												dropdownRef,
												edit,
												controlComponentRefs,
												moduleNavigation
											)
										}
										jumpToCoords={(coords) =>
											canvas.jumpTo({
												bearing: 0,
												center: coords,
												zoom: level === "field" ? 18 : level === "trial" ? 19 : 17,
												pitch: 0
											})
										}
										redrawPlots={(flightId, fieldId, trialId, sensorChanged, res, orthoId, first) =>
											ControlFunctions.redrawPlots(
												flightId,
												fieldId,
												trialId,
												sensorChanged,
												res,
												orthoId,
												getTokenSilently,
												dispatch,
												draw,
												canvas,
												edit,
												first,
												controlComponentRefs,
												clientId,
												moduleNavigation,
												dropdownRef,
												level,
												SetControlsToDefaultViewerSetting
											)
										}
										drawFarmPlots={(flightDate, flightImages, analysisOptions, farmId, orthoId, first) => {
											ControlFunctions.drawFarmPlots(
												flightDate,
												flightImages,
												analysisOptions,
												farmId,
												orthoId,
												clientId,
												draw,
												canvas,
												getTokenSilently,
												dispatch,
												controlComponentRefs,
												first,
												moduleNavigation,
												dropdownRef,
												level
											);
										}}
										setOrthoTimelineFlights={(flights) =>
											controlComponentRefs?.orthoTimelineRef?.current?.setFlightOptions(flights)
										}
										setOrthoId={ControlFunctions.setOrthoId}
										imageFound={(truth) => setImageFound(truth)}
										singleFeatureToggle={(type, id) => {
											if (type === "field") {
												ControlFunctions.setSelectedFieldId(id);
												ControlFunctions.setSelectedTrialId(null);
											} else {
												ControlFunctions.setSelectedFieldId(null);
												ControlFunctions.setSelectedTrialId(id);
												ControlFunctions.findTrialBoundaries(id);
											}
											ControlFunctions.toggleSingleFieldTrial(
												draw,
												canvas,
												type,
												controlComponentRefs,
												moduleNavigation,
												dropdownRef,
												level
											);
											ControlFunctions.fitToHeight(canvas, type, true);
										}}
										resetMap={() => {
											ControlFunctions.resetOrientation(
												canvas,
												level,
												draw,
												dropdownRef,
												controlComponentRefs,
												moduleNavigation,
												dropdownRef?.current?.innerRef?.current?.imageBounds
											);
										}}
										setCropToFilterOn={(crop) =>
											ControlFunctions.setCropToFilterOn(
												crop,
												draw,
												canvas,
												controlComponentRefs,
												moduleNavigation,
												dropdownRef,
												level
											)
										}
										fieldDropdownRef={fieldDropdownRef}
										trialDropdownRef={trialDropdownRef}
										cropDropdownRef={cropDropdownRef}
										dateRangeDropdownRef={dateRangeDropdownRef}
									/>

									{level !== "flight" && (
										<>
											<div style={{ flexGrow: 1 }}>
												<OrthoTimeline
													ref={controlComponentRefs.orthoTimelineRef}
													level={level}
													selectedOrthoImageTypeId={() => {
														return orthoImageTypeId;
													}}
													imageFound={(truth) => setImageFound(truth)}
													heatmapTimelineRef={controlComponentRefs.heatmapTimelineRef}
													updateTimelineDomain={(domain) => {
														ControlFunctions.updateTimelineDomain(domain, controlComponentRefs, "ortho");
													}}
												/>
											</div>
										</>
									)}
									{level === "flight" && (
										<label htmlFor="layerToggle" style={{ fontSize: "16px" }}>
											Show Imagery
										</label>
									)}
									<div style={{ height: 21 }}>
										<Checkbox
											id="layerToggle"
											toggle
											defaultChecked
											onClick={(_e, { checked }) => {
												let layers =
													level === "farm"
														? _.flatten(_.map(controlComponentRefs.imageLayersRef?.current?.getFlights(), "layers"))
														: null;
												return checked
													? ControlFunctions.showLayers(canvas, layers)
													: ControlFunctions.hideLayers(canvas, layers);
											}}
										/>
									</div>
									{level === "flight" && !viewer && (
										<>
											<div style={{ flexGrow: 1 }} />
											<Button
												id="form-button-continue"
												primary
												compact
												content={"Save & Continue"}
												onClick={() => {
													handleContinue();
												}}
											/>
										</>
									)}
								</div>
								{level !== "flight" && (
									<div className="orthoControlsRow">
										{level === "farm" && <></>}
										<HeatmapTimeline
											ref={controlComponentRefs.heatmapTimelineRef}
											level={level}
											heatmapOverlayShading={() =>
												ControlFunctions.heatmapOverlayShading(
													draw,
													canvas,
													controlComponentRefs,
													moduleNavigation,
													dropdownRef,
													level
												)
											}
											heatmapOverlayLabels={() =>
												ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs)
											}
											setPlotAnalysisResults={(values) =>
												ControlFunctions.setPlotAnalysisResults(values, controlComponentRefs)
											}
											heatmapOverlayLabelButton={controlComponentRefs.heatmapOverlayLabelsRef}
											heatmapOptionsButton={controlComponentRefs.heatmapOptionsRef}
											plotLabels={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
											plotLabelButton={controlComponentRefs.plotLabelToggleRef}
											updateTimelineDomain={(domain) => {
												ControlFunctions.updateTimelineDomain(domain, controlComponentRefs, "heatmap");
											}}
										/>
									</div>
								)}
							</div>
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="controlPanelContainer">
					<Grid.Column style={{ padding: 0 }}>
						<div className="controlPanel">
							<Button
								icon
								// labelPosition="right"
								className="controlPanelHeader"
								onClick={() => {
									let height = toolsRef.current.style.height;
									if (height === "0px") {
										toolsRef.current.style.height = "unset";
									} else {
										toolsRef.current.style.height = "0px";
									}
								}}
							>
								Tool Box
								<Icon className="tools" />
							</Button>
							<div
								className="scrollableContent"
								ref={toolsRef}
								style={level === "flight" && !viewer ? { height: "unset" } : { height: "0px" }}
							>
								{level === "flight" ? (
									<>
										<ControlButtons
											label="Field Controls"
											trigger={
												<Icon.Group>
													<Icon name="th large" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											{!viewer && (
												<>
													<Rotate
														ref={controlComponentRefs.rotateRightRef}
														rotate={(e) => ControlFunctions.rotateRight(draw, orthoAnalyzedModal, e)}
														flip={false}
													/>
													<Rotate
														ref={controlComponentRefs.rotateLeftRef}
														rotate={(e) => ControlFunctions.rotateLeft(draw, orthoAnalyzedModal, e)}
														flip={true}
													/>
													<ScaleOutward
														ref={controlComponentRefs.scaleOutwardRef}
														scaleOutward={(e) => ControlFunctions.scaleOutward(draw, orthoAnalyzedModal, e)}
													/>
													<ScaleInward
														ref={controlComponentRefs.scaleInwardRef}
														scaleInward={(e) => ControlFunctions.scaleInward(draw, orthoAnalyzedModal, e)}
													/>
													<FieldPivotToggle
														ref={controlComponentRefs.fieldPivotToggleRef}
														fieldPivotToggle={() => ControlFunctions.fieldPivotToggle(draw)}
													/>
													<FieldPivotLlSet
														ref={controlComponentRefs.fieldPivotLlSetRef}
														fieldPivotLlSet={() => ControlFunctions.fieldPivotLlSet(draw)}
													/>
													<LockToggle
														ref={controlComponentRefs.lockToggleRef}
														lockToggle={() => {
															ControlFunctions.lockToggle(
																draw,
																canvas,
																dropdownRef,
																controlComponentRefs,
																moduleNavigation,
																level
															);
															controlComponentRefs.alignPlotsRef.current.setDisabled(true);
														}}
													/>
													{edit && (
														<AddField
															addField={() => ControlFunctions.addField(draw, controlComponentRefs.measureDistanceRef)}
														/>
													)}
													<AlignPlots
														ref={controlComponentRefs.alignPlotsRef}
														alignPlotsModal={alignPlotsModal}
														dropdownRef={dropdownRef}
														level={level}
													/>
												</>
											)}
											<EditFieldModal
												ref={controlComponentRefs.editFieldModal}
												validateSelectedField={() => ControlFunctions.validateSelectedField(draw)}
												deselectField={() => ControlFunctions.uncombinePlotsInField(draw, false)}
												alignPlotsRef={controlComponentRefs.alignPlotsRef}
												dropdownRef={dropdownRef}
											/>
										</ControlButtons>
										<ControlButtons
											label="Map Controls"
											trigger={
												<Icon.Group size="large">
													<Icon name="map" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<FitToHeight
												ref={controlComponentRefs.fitToHeightRef}
												fitToHeight={() => ControlFunctions.fitToHeight(canvas, "field")}
											/>
											<FitToWidth
												ref={controlComponentRefs.fitToWidthRef}
												fitToWidth={() => ControlFunctions.fitToWidth(canvas, "field")}
											/>
											<ResetOrientation
												ref={controlComponentRefs.resetOrientationRef}
												resetOrientation={() =>
													ControlFunctions.resetOrientation(
														canvas,
														level,
														draw,
														dropdownRef,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef?.current?.innerRef?.current?.imageBounds
													)
												}
											/>
											<MeasureDistance
												ref={controlComponentRefs.measureDistanceRef}
												togglePopup={() => ControlFunctions.toggleMeasurePopup(controlComponentRefs.measurePopupRef)}
											/>
										</ControlButtons>
										<ControlButtons
											label="Adjust Images"
											trigger={
												<Icon.Group size="large">
													<Icon name="paint brush" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<ApplyShader
												removeShader={() => ControlFunctions.removeShader(canvas)}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyClampedShader={(minThreshold, maxThreshold) => {
													ControlFunctions.applyClampedShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													);
												}}
												ref={controlComponentRefs.applyShaderRef}
											/>
											<ShaderHistogram
												applyClampedShader={(minThreshold, maxThreshold) => {
													if (canvas) {
														ControlFunctions.applyClampedShader(
															canvas,
															minThreshold,
															maxThreshold,
															dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
														);
													}
												}}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												dropdownRef={dropdownRef}
												ref={controlComponentRefs.shaderHistogramRef}
											/>
										</ControlButtons>
										<ControlButtons
											label="Overlays"
											trigger={
												<Icon.Group size="large">
													<Icon name="pencil" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<LowerLeftToggle
												ref={controlComponentRefs.llToggleRef}
												lowerLeftToggle={() => ControlFunctions.lowerLeftToggle(draw)}
											/>
											<FieldBoundaryToggle
												ref={controlComponentRefs.fieldToggleRef}
												fieldBoundaryToggle={() => ControlFunctions.fieldBoundaryToggle(draw)}
											/>
											<TrialToggle
												ref={controlComponentRefs.trialToggle}
												trialToggle={() => ControlFunctions.trialToggle(draw)}
											/>
											<PlotToggle
												ref={controlComponentRefs.plotToggleRef}
												plotToggle={() => ControlFunctions.plotToggle(draw, controlComponentRefs)}
											/>
											<RowToggle
												ref={controlComponentRefs.rowToggleRef}
												rowToggle={() => ControlFunctions.rowToggle(draw)}
											/>
										</ControlButtons>
										<ControlButtons label="Labels" trigger={<Icon name="align left" size="large" />}>
											<FieldLabelToggle
												ref={controlComponentRefs.fieldLabelToggleRef}
												fieldLabelToggle={() =>
													ControlFunctions.fieldLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													)
												}
											/>
											<TrialLabelToggle
												ref={controlComponentRefs.trialLabelToggleRef}
												trialLabelToggle={() => {
													ControlFunctions.trialLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
											<PlotLabelToggle
												ref={controlComponentRefs.plotLabelToggleRef}
												plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
											/>
											<DEFMarkerToggle
												ref={controlComponentRefs.defMarkerToggleRef}
												defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
											/>
										</ControlButtons>
									</>
								) : level === "field" ? (
									<>
										<ControlButtons
											label="Map Controls"
											trigger={
												<Icon.Group size="large">
													<Icon name="map" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<FitToHeight
												ref={controlComponentRefs.fitToHeightRef}
												fitToHeight={() => ControlFunctions.fitToHeight(canvas, level)}
											/>
											<FitToWidth
												ref={controlComponentRefs.fitToWidthRef}
												fitToWidth={() => ControlFunctions.fitToWidth(canvas, level)}
											/>
											<RotateRight
												rotateRight={() => ControlFunctions.rotateFeatureRight(canvas, level)}
												level={level}
											/>
											<RotateLeft rotateLeft={() => ControlFunctions.rotateFeatureLeft(canvas, level)} level={level} />
											<ResetOrientation
												ref={controlComponentRefs.resetOrientationRef}
												resetOrientation={() =>
													ControlFunctions.resetOrientation(
														canvas,
														level,
														draw,
														dropdownRef,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef?.current?.innerRef?.current?.imageBounds
													)
												}
											/>
											<MeasureDistance
												ref={controlComponentRefs.measureDistanceRef}
												togglePopup={() => ControlFunctions.toggleMeasurePopup(controlComponentRefs.measurePopupRef)}
											/>
										</ControlButtons>
										<ControlButtons
											label="Adjust Images"
											trigger={
												<Icon.Group size="large">
													<Icon name="paint brush" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<ApplyShader
												removeShader={() => ControlFunctions.removeShader(canvas)}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyClampedShader={(minThreshold, maxThreshold) => {
													ControlFunctions.applyClampedShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													);
												}}
												ref={controlComponentRefs.applyShaderRef}
											/>
											<ShaderHistogram
												applyClampedShader={(minThreshold, maxThreshold) => {
													if (canvas) {
														ControlFunctions.applyClampedShader(
															canvas,
															minThreshold,
															maxThreshold,
															dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
														);
													}
												}}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												dropdownRef={dropdownRef}
												ref={controlComponentRefs.shaderHistogramRef}
											/>
											<HeatmapOptions
												ref={controlComponentRefs.heatmapOptionsRef}
												setHeatmapDomain={(domain) => {
													ControlFunctions.setHeatmapDomain(
														domain,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
												setHeatmapOpacity={(opacity) => {
													ControlFunctions.setHeatmapOpacity(
														opacity,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
												setHeatmapGradient={(gradient) => {
													ControlFunctions.setHeatmapGradient(
														gradient,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
										</ControlButtons>
										<ControlButtons
											label="Overlays"
											trigger={
												<Icon.Group size="large">
													<Icon name="pencil" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<LowerLeftToggle
												ref={controlComponentRefs.llToggleRef}
												lowerLeftToggle={() => ControlFunctions.lowerLeftToggle(draw)}
											/>
											<FieldBoundaryToggle
												ref={controlComponentRefs.fieldToggleRef}
												fieldBoundaryToggle={() => ControlFunctions.fieldBoundaryToggle(draw)}
											/>
											<TrialToggle
												ref={controlComponentRefs.trialToggle}
												trialToggle={() => ControlFunctions.trialToggle(draw)}
											/>
											<PlotToggle
												ref={controlComponentRefs.plotToggleRef}
												plotToggle={() => ControlFunctions.plotToggle(draw, controlComponentRefs)}
											/>
											<QuantifiedRegionToggle
												quantifiedRegionToggle={(type) => ControlFunctions.quantifiedRegionToggle(draw, false, type)}
												ref={controlComponentRefs.quantifiedRegionToggleRef}
											/>
											<RowToggle
												ref={controlComponentRefs.rowToggleRef}
												rowToggle={() => ControlFunctions.rowToggle(draw)}
											/>
										</ControlButtons>
										<ControlButtons label="Labels" trigger={<Icon name="align left" size="large" />}>
											<FieldLabelToggle
												ref={controlComponentRefs.fieldLabelToggleRef}
												fieldLabelToggle={() =>
													ControlFunctions.fieldLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													)
												}
											/>
											<TrialLabelToggle
												ref={controlComponentRefs.trialLabelToggleRef}
												trialLabelToggle={() => {
													ControlFunctions.trialLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
											<PlotLabelToggle
												ref={controlComponentRefs.plotLabelToggleRef}
												plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
											/>
											<DEFMarkerToggle
												ref={controlComponentRefs.defMarkerToggleRef}
												defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
											/>
											{userAuth.hasApplicationArea(ApplicationArea.FieldViewer, userAuth.currentClientId) &&
												userAuth.hasApplicationArea(
													ApplicationArea.FieldVisualHeatmapValues,
													userAuth.currentClientId
												) && (
													<HeatmapOverlayLabels
														ref={controlComponentRefs.heatmapOverlayLabelsRef}
														heatmapOverlayLabels={() =>
															ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs)
														}
													/>
												)}
										</ControlButtons>
									</>
								) : level === "trial" ? (
									<>
										<ControlButtons
											label="Map Controls"
											trigger={
												<Icon.Group size="large">
													<Icon name="map" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<FitToHeight
												ref={controlComponentRefs.fitToHeightRef}
												fitToHeight={() => ControlFunctions.fitToHeight(canvas, level)}
											/>
											<FitToWidth
												ref={controlComponentRefs.fitToWidthRef}
												fitToWidth={() => ControlFunctions.fitToWidth(canvas, level)}
											/>
											<RotateRight
												rotateRight={() => ControlFunctions.rotateFeatureRight(canvas, level)}
												level={level}
											/>
											<RotateLeft rotateLeft={() => ControlFunctions.rotateFeatureLeft(canvas, level)} level={level} />
											<ResetOrientation
												ref={controlComponentRefs.resetOrientationRef}
												resetOrientation={() =>
													ControlFunctions.resetOrientation(
														canvas,
														level,
														draw,
														dropdownRef,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef?.current?.innerRef?.current?.imageBounds
													)
												}
											/>
											<MeasureDistance
												ref={controlComponentRefs.measureDistanceRef}
												togglePopup={() => ControlFunctions.toggleMeasurePopup(controlComponentRefs.measurePopupRef)}
											/>
										</ControlButtons>
										<ControlButtons
											label="Adjust Images"
											trigger={
												<Icon.Group size="large">
													<Icon name="paint brush" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<ApplyShader
												removeShader={() => ControlFunctions.removeShader(canvas)}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyClampedShader={(minThreshold, maxThreshold) => {
													ControlFunctions.applyClampedShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													);
												}}
												ref={controlComponentRefs.applyShaderRef}
											/>
											<ShaderHistogram
												applyClampedShader={(minThreshold, maxThreshold) => {
													if (canvas) {
														ControlFunctions.applyClampedShader(
															canvas,
															minThreshold,
															maxThreshold,
															dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
														);
													}
												}}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												dropdownRef={dropdownRef}
												ref={controlComponentRefs.shaderHistogramRef}
											/>
											<HeatmapOptions
												ref={controlComponentRefs.heatmapOptionsRef}
												setHeatmapDomain={(domain) => {
													ControlFunctions.setHeatmapDomain(
														domain,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
												setHeatmapOpacity={(opacity) => {
													ControlFunctions.setHeatmapOpacity(
														opacity,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
												setHeatmapGradient={(gradient) => {
													ControlFunctions.setHeatmapGradient(
														gradient,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
										</ControlButtons>
										<ControlButtons
											label="Overlays"
											trigger={
												<Icon.Group size="large">
													<Icon name="pencil" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<PlotToggle
												ref={controlComponentRefs.plotToggleRef}
												plotToggle={() => ControlFunctions.plotToggle(draw, controlComponentRefs)}
											/>
											<RowToggle
												ref={controlComponentRefs.rowToggleRef}
												rowToggle={() => ControlFunctions.rowToggle(draw)}
											/>
											<QuantifiedRegionToggle
												quantifiedRegionToggle={(type) => ControlFunctions.quantifiedRegionToggle(draw, false, type)}
												ref={controlComponentRefs.quantifiedRegionToggleRef}
											/>
										</ControlButtons>
										<ControlButtons label="Labels" trigger={<Icon name="align left" size="large" />}>
											<TrialLabelToggle
												ref={controlComponentRefs.trialLabelToggleRef}
												trialLabelToggle={() => {
													ControlFunctions.trialLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
											<PlotLabelToggle
												ref={controlComponentRefs.plotLabelToggleRef}
												plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
											/>
											{userAuth.hasApplicationArea(
												ApplicationArea.TrialVisualHeatmapValues,
												userAuth.currentClientId
											) && (
												<HeatmapOverlayLabels
													ref={controlComponentRefs.heatmapOverlayLabelsRef}
													heatmapOverlayLabels={() =>
														ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs)
													}
												/>
											)}
											<DEFMarkerToggle
												ref={controlComponentRefs.defMarkerToggleRef}
												defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
											/>
										</ControlButtons>
									</>
								) : level === "farm" ? (
									<>
										<ControlButtons
											label="Map Controls"
											trigger={
												<Icon.Group>
													<Icon name="map" size="large" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<RotateRight
												rotateRight={() => ControlFunctions.rotateFeatureRight(canvas, level)}
												level={level}
											/>
											<RotateLeft rotateLeft={() => ControlFunctions.rotateFeatureLeft(canvas, level)} level={level} />
											<ResetOrientation
												ref={controlComponentRefs.resetOrientationRef}
												resetOrientation={() =>
													ControlFunctions.resetOrientation(
														canvas,
														level,
														draw,
														dropdownRef,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef?.current?.innerRef?.current?.imageBounds
													)
												}
											/>
											<MeasureDistance
												ref={controlComponentRefs.measureDistanceRef}
												togglePopup={() => ControlFunctions.toggleMeasurePopup(controlComponentRefs.measurePopupRef)}
											/>
										</ControlButtons>
										<ControlButtons
											label="Adjust Images"
											trigger={
												<Icon.Group>
													<Icon name="paint brush" size="large" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<ApplyShader
												removeShader={() => ControlFunctions.removeShader(canvas)}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyClampedShader={(minThreshold, maxThreshold) => {
													ControlFunctions.applyClampedShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													);
												}}
												ref={controlComponentRefs.applyShaderRef}
											/>
											<ShaderHistogram
												applyClampedShader={(minThreshold, maxThreshold) => {
													if (canvas) {
														ControlFunctions.applyClampedShader(
															canvas,
															minThreshold,
															maxThreshold,
															dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
														);
													}
												}}
												applyThermalShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyThermalShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyRYGShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyRYGShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyGreenFireBlueShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applySpectralShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applySpectralShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												applyBlueYellowShader={(minThreshold, maxThreshold) =>
													ControlFunctions.applyBlueYellowShader(
														canvas,
														minThreshold,
														maxThreshold,
														dropdownRef.current.innerRef.current.getSelectedOrthoTypeName()
													)
												}
												dropdownRef={dropdownRef}
												ref={controlComponentRefs.shaderHistogramRef}
											/>
											<HeatmapOptions
												ref={controlComponentRefs.heatmapOptionsRef}
												setHeatmapDomain={(domain) => {
													ControlFunctions.setHeatmapDomain(
														domain,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
												setHeatmapOpacity={(opacity) => {
													ControlFunctions.setHeatmapOpacity(
														opacity,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
												setHeatmapGradient={(gradient) => {
													ControlFunctions.setHeatmapGradient(
														gradient,
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
											<ImageLayers
												ref={controlComponentRefs.imageLayersRef}
												showLayers={(l) => ControlFunctions.showLayers(canvas, l)}
												hideLayers={(l) => ControlFunctions.hideLayers(canvas, l)}
											/>
										</ControlButtons>
										<ControlButtons
											label="Overlays"
											trigger={
												<Icon.Group>
													<Icon name="pencil" size="large" />
													<Icon name="wrench" corner />
												</Icon.Group>
											}
										>
											<FarmBoundsToggle
												ref={controlComponentRefs.farmBoundsToggleRef}
												farmBoundsToggle={() => ControlFunctions.drawFarmBounds(draw)}
											/>
											<LowerLeftToggle
												ref={controlComponentRefs.llToggleRef}
												lowerLeftToggle={() => ControlFunctions.lowerLeftToggle(draw)}
											/>
											<FieldBoundaryToggle
												ref={controlComponentRefs.fieldToggleRef}
												fieldBoundaryToggle={() => ControlFunctions.fieldBoundaryToggle(draw)}
											/>
											<TrialToggle
												ref={controlComponentRefs.trialToggle}
												trialToggle={() => ControlFunctions.trialToggle(draw)}
											/>
											<PlotToggle
												ref={controlComponentRefs.plotToggleRef}
												plotToggle={() => ControlFunctions.plotToggle(draw, controlComponentRefs)}
											/>
											<RowToggle
												ref={controlComponentRefs.rowToggleRef}
												rowToggle={() => ControlFunctions.rowToggle(draw)}
											/>
											<QuantifiedRegionToggle
												quantifiedRegionToggle={(type) => ControlFunctions.quantifiedRegionToggle(draw, false, type)}
												ref={controlComponentRefs.quantifiedRegionToggleRef}
											/>
										</ControlButtons>
										<ControlButtons label="Labels" trigger={<Icon name="align left" size="large" />}>
											<FieldLabelToggle
												ref={controlComponentRefs.fieldLabelToggleRef}
												fieldLabelToggle={() =>
													ControlFunctions.fieldLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level,
														entityNames.farmClientId === userAuth.currentClientId
													)
												}
											/>
											<TrialLabelToggle
												ref={controlComponentRefs.trialLabelToggleRef}
												trialLabelToggle={() => {
													ControlFunctions.trialLabelToggle(
														draw,
														canvas,
														controlComponentRefs,
														moduleNavigation,
														dropdownRef,
														level
													);
												}}
											/>
											<PlotLabelToggle
												ref={controlComponentRefs.plotLabelToggleRef}
												plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
											/>
											{userAuth.hasApplicationArea(
												ApplicationArea.FarmVisualHeatmapValues,
												userAuth.currentClientId
											) && (
												<HeatmapOverlayLabels
													ref={controlComponentRefs.heatmapOverlayLabelsRef}
													heatmapOverlayLabels={() =>
														ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs)
													}
												/>
											)}
											<DEFMarkerToggle
												ref={controlComponentRefs.defMarkerToggleRef}
												defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
											/>
										</ControlButtons>
									</>
								) : (
									<></>
								)}

								{level !== "flight" && (
									<ControlButtons label="Tags">
										<FlagPlots
											ref={controlComponentRefs.flagPlotsRef}
											markedPlotOptions={() => ControlFunctions.getMarkedPlotOptions()}
											applyChanges={(checkedOptions, notes) =>
												ControlFunctions.handleApplyStatuses(
													checkedOptions,
													notes,
													draw,
													controlComponentRefs.orthoTimelineRef,
													controlComponentRefs.flagPlotsRef,
													clientId,
													dispatch,
													getTokenSilently,
													canvas,
													controlComponentRefs,
													null,
													dropdownRef.current.innerRef.current.getFlightOptions,
													queryClient
												)
											}
											draw={draw}
											markedPlots={() => ControlFunctions.getMarkedPlots()}
											flightId={() => ControlFunctions.getCurrentFlightId()}
											dropdownRef={dropdownRef}
											markedSubsamples={() => ControlFunctions.getMarkedSubsamples()}
											deleteExistingFeatures={(features) =>
												ControlFunctions.deleteExistingMarkedFeatures(
													features,
													draw,
													dispatch,
													getTokenSilently,
													clientId,
													canvas,
													controlComponentRefs,
													controlComponentRefs.flagPlotsRef,
													queryClient
												)
											}
											getSubsamples={() => ControlFunctions.getSubsamples()}
											annotationClassOptions={() => ControlFunctions.getAnnotationClassOptions()}
											getFlightId={() => controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()}
											level={level}
											checkAnnotationPermissions={(markedPlots, markedQuantifiedRegions, type) =>
												ControlFunctions.checkAnnotationPermissions(
													markedPlots,
													markedQuantifiedRegions,
													clientId,
													dispatch,
													getTokenSilently,
													controlComponentRefs.flagPlotsRef,
													type
												)
											}
										/>
										{/* //TASK: Add this button to all the viewers that can use it and move it to the correct category 
																Set up disabled check
										*/}

										{/* <Button
											onClick={() => {
												handleResetButtonClicked();
											}}
											icon
											compact
										>
											<Icon name="pin" size="large" />
											<span>Save Default Sliders</span>
										</Button> */}
									</ControlButtons>
								)}
							</div>
							{/* <Grid.Column width="1" style={{ position: "absolute", zIndex: "1", padding: "2%" }}>
								<ViewerDataExportButton viewerDataExportModalRef={viewerDataExportModal}></ViewerDataExportButton>
								<MapboxStyleToggle styleToggle={() => ControlFunctions.styleToggle()} setStyle={setStyle} />
								<Divider hidden />
								<ApplyShader
									removeShader={() => ControlFunctions.removeShader(canvas)}
									applyThermalShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyThermalShader(canvas, minThreshold, maxThreshold)
									}
									applyRYGShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyRYGShader(canvas, minThreshold, maxThreshold)
									}
									applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyGreenFireBlueShader(canvas, minThreshold, maxThreshold)
									}
									applySpectralShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applySpectralShader(canvas, minThreshold, maxThreshold)
									}
									applyBlueYellowShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyBlueYellowShader(canvas, minThreshold, maxThreshold)
									}
									applyClampedShader={(minThreshold, maxThreshold) => {
										ControlFunctions.applyClampedShader(canvas, minThreshold, maxThreshold);
									}}
									ref={controlComponentRefs.applyShaderRef}
								/>
								<ShaderHistogram
									applyClampedShader={(minThreshold, maxThreshold) => {
										if (canvas) {
											ControlFunctions.applyClampedShader(canvas, minThreshold, maxThreshold);
										}
									}}
									applyThermalShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyThermalShader(canvas, minThreshold, maxThreshold)
									}
									applyRYGShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyRYGShader(canvas, minThreshold, maxThreshold)
									}
									applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyGreenFireBlueShader(canvas, minThreshold, maxThreshold)
									}
									applySpectralShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applySpectralShader(canvas, minThreshold, maxThreshold)
									}
									applyBlueYellowShader={(minThreshold, maxThreshold) =>
										ControlFunctions.applyBlueYellowShader(canvas, minThreshold, maxThreshold)
									}
									dropdownRef={dropdownRef}
									ref={controlComponentRefs.shaderHistogramRef}
								/>
							</Grid.Column> */}
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{(!edit || !viewer) && (
				<>
					<AddExistingFieldsModal
						ref={addExistingFieldsModal}
						draw={draw}
						addFieldModal={addFieldModal}
						canvas={() => {
							return canvas;
						}}
						dropdownRef={dropdownRef}
						componentRefs={controlComponentRefs}
					/>
					<AddFieldModal
						ref={addFieldModal}
						draw={draw}
						canvas={() => {
							return canvas;
						}}
						dropdownRef={dropdownRef}
						componentRefs={controlComponentRefs}
					/>
					<OrthoAnalyzedModal ref={orthoAnalyzedModal} />
				</>
			)}
			{!viewer && (
				<AlignPlotsModal
					ref={alignPlotsModal}
					draw={draw}
					canvas={() => {
						return canvas;
					}}
					orthoImageTypeId={() => {
						return orthoImageTypeId;
					}}
					controlComponentRefs={controlComponentRefs}
				/>
			)}
			{/* <ViewerDataExportModal ref={viewerDataExportModal}></ViewerDataExportModal> */}
			{level === "flight" && (
				<InfoModal
					style={{
						position: "absolute",
						bottom: 49,
						right: 10
					}}
					context={!viewer && !edit ? "align-fields" : !viewer && edit ? "new-fields" : "map-controls"}
				/>
			)}
			<MeasurePopup
				ref={controlComponentRefs.measurePopupRef}
				measureLine={() => {
					ControlFunctions.measureLineMode(draw, controlComponentRefs.measurePopupRef);
				}}
				// measureBox={() => {
				// 	ControlFunctions.measureBoxMode(draw, controlComponentRefs.measurePopupRef);
				// }}
				measurePoly={() => {
					ControlFunctions.measurePolygonMode(draw, controlComponentRefs.measurePopupRef);
				}}
				coordAtPoint={() => {
					ControlFunctions.coordinateAtPointMode(draw, controlComponentRefs.measurePopupRef);
				}}
				clearMeasurement={() => {
					ControlFunctions.clearMeasurements(draw, controlComponentRefs.measurePopupRef, true);
				}}
				measureDistanceRef={controlComponentRefs.measureDistanceRef}
			/>
			<CoordinateTracker
				style={{ position: "absolute", bottom: 20, right: 0 }}
				ref={controlComponentRefs.coordinateTrackerRef}
			/>
			<RevertAlignment
				ref={controlComponentRefs.revertAlignmentRef}
				revertPlotUpdates={() =>
					ControlFunctions.revertPlotUpdates(draw, canvas, controlComponentRefs, moduleNavigation, dropdownRef, level)
				}
			/>
		</Segment>
	);
};

Ortho.propTypes = {
	level: PropTypes.oneOf(["flight", "field", "trial", "farm"]),
	edit: PropTypes.bool.isRequired,
	viewer: PropTypes.bool,
	handleBack: PropTypes.func,
	handleContinue: PropTypes.func,
	setOrthoImageTypes: PropTypes.func.isRequired,
	setDraw: PropTypes.func,
	setImageFound: PropTypes.func.isRequired
};

export default Ortho;
