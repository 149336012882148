import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Segment, Loader, Button } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as trialActions from "../../../../redux/actions/trialActions";

import TrialOutlineTable from "./TrialOutlineTable";

const TrialOutline = ({ navigateToPreviousStep, navigateToNextStep }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Query results
	const [trialOutlineData, setTrialOutlineData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (clientId) {
			getTrialOutlineData(clientId);
		}
	}, [clientId]);

	async function getTrialOutlineData(clientId) {
		if (moduleNavigation.trialId) {
			const accessToken = await getTokenSilently();
			setLoading(true);
			dispatch(trialActions.getTrialOutlineData(clientId, moduleNavigation.trialId, accessToken))
				.then((res) => {
					setTrialOutlineData(res);
					setLoading(false);
				})
				.catch((err) => {
					toast.error("Error loading Trial Outline. Please try again.");
					setLoading(false);
					console.log(err);
				});
		}
	}

	return loading ? (
		<Loader active />
	) : !loading && (!trialOutlineData || trialOutlineData.length === 0) ? (
		<Segment style={{ margin: 15 }}>
			<p>{"We could not find outline data for this trial."}</p>
		</Segment>
	) : (
		<div>
			<div basic style={{ marginBottom: "1em" }}>
				<div style={{ display: "flex", alignItems: "end", overflow: "hidden" }}>
					<Button
						style={{ marginRight: 10 }}
						compact
						color="black"
						content="Back"
						onClick={() => {
							navigateToPreviousStep();
						}}
					/>

					<div style={{ flexGrow: 1, minWidth: 10 }} />

					<Button id="form-button-save" primary compact content="Continue" onClick={() => navigateToNextStep()} />
				</div>
			</div>
			<TrialOutlineTable trialOutlineData={trialOutlineData} />
		</div>
	);
};

TrialOutline.propTypes = {
	navigateToNextStep: PropTypes.func,
	navigateToPreviousStep: PropTypes.func
};

export default TrialOutline;
