import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Layer, Map, Source } from "react-map-gl";
import * as styles from "./MapStyles";
import ParcelPopup from "./ParcelPopup";
import _ from "lodash";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const ParcelMapWrapper = ({
	mapRef,
	drawRef,
	parcelList,
	hoveredParcel,
	setHoveredParcel,
	focusedParcel,
	setFocusedParcel,
	selectedParcelList,
	setSelectedParcelList,
	isDrawing,
	initCoords,
	visibleLayers,
	onAddParcel
}) => {
	const [viewState, setViewState] = useState(initCoords);

	const [parcelFeatureList, setParcelFeatureList] = useState([]);

	useEffect(() => {
		setParcelFeatureList(parcelList);
		onAddToSelected(undefined);
	}, [parcelList]);

	useEffect(() => {
		setViewState(initCoords);
	}, [initCoords]);

	const addDrawControls = useCallback((e) => {
		const map = e.target;
		drawRef.current = new MapboxDraw({
			displayControlsDefault: false
		});

		map.addControl(drawRef.current);
	}, []);

	const onAddToSelected = useCallback((ll_uuid) => {
		if (ll_uuid) {
			setSelectedParcelList((prev) => {
				if (prev) {
					return [...prev, ll_uuid];
				}
				return [ll_uuid];
			});
		}
	}, []);

	const onHover = useCallback((e) => {
		const features = e.target.queryRenderedFeatures(e.point);
		const feature = features && features[0];
		//console.log(feature && feature.properties.ll_uuid);
		setHoveredParcel(feature && feature.properties.ll_uuid);
	}, []);

	const onClick = useCallback(
		(e) => {
			const features = e.target.queryRenderedFeatures(e.point);
			const feature = features && features[0];
			if (feature) {
				// setSelectedParcelList((selectedParcelList) => {
				// 	if (selectedParcelList) {
				// 		return [...selectedParcelList, feature.properties.ll_uuid];
				// 	}
				// 	return [feature.properties.ll_uuid];
				// });
				if (feature.properties.ll_uuid === focusedParcel) {
					setFocusedParcel(null);
				} else {
					setFocusedParcel(feature.properties.ll_uuid);
				}
			}
		},
		[focusedParcel]
	);

	const negativeFilter = useMemo(() => {
		if (!visibleLayers.includes("found")) {
			return ["boolean", false];
		}

		return [
			"all",
			["!=", "ll_uuid", focusedParcel || ""],
			["!=", "ll_uuid", hoveredParcel || ""],
			["!in", "ll_uuid", ...selectedParcelList]
		];
	}, [hoveredParcel, focusedParcel, visibleLayers]);

	const hoverFilter = useMemo(() => ["==", "ll_uuid", hoveredParcel || ""], [hoveredParcel]);
	const focusFilter = useMemo(() => ["==", "ll_uuid", focusedParcel || ""], [focusedParcel]);

	const selectedFilter = useMemo(() => {
		if (!visibleLayers.includes("selected")) {
			return ["boolean", false];
		}

		return ["in", "ll_uuid", ...selectedParcelList];
	}, [selectedParcelList, visibleLayers]);

	const focusedParcelFeature = useMemo(() => {
		if (focusedParcel === null) return null;
		if (parcelList === null) return null;
		const feature = _.find(parcelList.features, (f) => {
			return f.properties.ll_uuid === focusedParcel;
		});
		return feature;
	}, [focusedParcel, parcelList]);

	return (
		<Map
			ref={mapRef}
			{...viewState}
			onMove={(e) => setViewState(e.viewState)}
			mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
			mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
			onMouseMove={(e) => {
				onHover(e);
			}}
			onMouseDown={(e) => {
				onClick(e);
			}}
			doubleClickZoom={false}
			cursor={hoveredParcel ? "pointer" : "unset"}
			onLoad={(e) => {
				addDrawControls(e);
			}}
		>
			{/* states can overlap */}
			{!isDrawing && (
				<Source type="geojson" data={parcelFeatureList}>
					{/* no state */}
					<Layer {...styles.parcelLayer} filter={negativeFilter} />
					<Layer {...styles.outlineParcelLayer} filter={negativeFilter} />
					{/* hovered state */}
					<Layer {...styles.hoveredParcelLayer} filter={hoverFilter} />
					<Layer {...styles.hoveredOutlineParcelLayer} filter={hoverFilter} />
					{/* focused state */}
					<Layer {...styles.focusedParcelLayer} filter={focusFilter} />
					<Layer {...styles.focusedOutlineParcelLayer} filter={focusFilter} />
					{/* selected state */}
					<Layer {...styles.selectedParcelLayer} filter={selectedFilter} />
					<Layer {...styles.selectedOutlineParcelLayer} filter={selectedFilter} />
				</Source>
			)}

			{/* {isDrawing && (
				<DrawControl
					position="bottom-left"
					onCreate={onUpdate}
					onUpdate={onUpdate}
					onDelete={onDelete}
					options={{
						displayControlsDefault: false,
						controls: {
							polygon: true,
							trash: true
						},
						defaultMode: "draw_polygon"
					}}
				/>
			)} */}

			{focusedParcelFeature && (
				<ParcelPopup
					feature={focusedParcelFeature}
					selected={selectedParcelList.includes(focusedParcel)}
					onAddParcel={() => {
						onAddParcel(focusedParcel);
					}}
				/>
			)}
		</Map>
	);
};

ParcelMapWrapper.propTypes = {
	mapRef: PropTypes.any,
	drawRef: PropTypes.any,
	parcelList: PropTypes.object,
	hoveredParcel: PropTypes.string,
	setHoveredParcel: PropTypes.func,
	focusedParcel: PropTypes.string,
	setFocusedParcel: PropTypes.func,
	selectedParcelList: PropTypes.object,
	setSelectedParcelList: PropTypes.func,
	isDrawing: PropTypes.bool,
	initCoords: PropTypes.object,
	visibleLayers: PropTypes.array,
	onAddParcel: PropTypes.func
};

export default ParcelMapWrapper;
