import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import React, { createContext } from "react";
import * as trialApi from "../../apis/trialApi";
import { useAuth0 } from "../../auth/auth0";
import { AerialPlotResponse } from "../../apis/types";
import { toast } from "react-toastify";

type TrialData = {
	getMetaTagOptionsQuery: (deps?: any[]) => UseQueryResult<trialApi.MetaTagOptions, Error>;
	getMetaFactorDataQuery: (id: string, deps?: any[]) => UseQueryResult<trialApi.MetaFactorData, Error>;
	updateMetaTagsMutator: (options?: {
		onSuccess?: () => void;
		onError?: () => void;
	}) => UseMutationResult<AerialPlotResponse<void>, Error, trialApi.MetaTagsUpdateInfo, unknown>;
	getAnnotationDataQuery: (id: string, deps?: any[]) => UseQueryResult<any, Error>;
};

const TrialContext = createContext<TrialData>({} as TrialData);

const TrialProvider = ({ children, currentClientId }) => {
	const { getTokenSilently } = useAuth0();
	const queryClient = useQueryClient();

	const getOptions = async (clientId: string) => {
		const accessToken = await getTokenSilently();
		const json = trialApi.getTrialMetaTagOptions(clientId, accessToken);
		return json;
	};

	const getMetaFactors = async (trialId: string, clientId: string) => {
		const accessToken = await getTokenSilently();
		const json = trialApi.getTrialMetaFactorData(trialId, clientId, accessToken);
		return json;
	};

	const updateMetaTags = async (vars: trialApi.MetaTagsUpdateInfo) => {
		const accessToken = await getTokenSilently();
		return trialApi.updateTrialMetaTags(vars, currentClientId, accessToken);
	};

	const getAnnotationData = async (trialId: string, clientId: string) => {
		const accessToken = await getTokenSilently();
		const json = trialApi.getAnnotationSummaryForTrialAsync(trialId, clientId, accessToken);
		return json;
	};

	const getMetaTagOptionsQuery = (deps: any[] = []) =>
		useQuery({
			queryKey: ["meta tag options", currentClientId],
			queryFn: () => getOptions(currentClientId),
			select: (data) => data.data,
			enabled: !!currentClientId && deps.every((dep) => !!dep)
		});

	const getMetaFactorDataQuery = (id: string, deps: any[] = []) =>
		useQuery({
			queryKey: ["meta factors", id, currentClientId],
			queryFn: () => getMetaFactors(id, currentClientId),
			select: (data) => data.data,
			enabled: !!id && !!currentClientId && deps.every((dep) => !!dep)
		});

	const updateMetaTagsMutator = (options?: { onSuccess?: () => void; onError?: () => void }) =>
		useMutation({
			mutationFn: (vars: trialApi.MetaTagsUpdateInfo) => updateMetaTags(vars),
			onSuccess: (_, { trialId, trialName, isForProtocol }) => {
				toast.success(`Meta-Tags ${isForProtocol ? `for ${trialName} ` : ""}successfully saved!`);
				queryClient.invalidateQueries({ queryKey: ["meta factors", trialId, currentClientId] });
				options?.onSuccess?.();
			},
			onError: (error) => {
				toast.error("Meta-Tags failed to save.");
				console.error(error);
				options?.onError?.();
			}
		});

	const getAnnotationDataQuery = (id: string, deps: any[] = []) =>
		useQuery({
			queryKey: ["annotation data", id, currentClientId],
			queryFn: () => getAnnotationData(id, currentClientId),
			select: (data) => data.data,
			enabled: !!id && !!currentClientId && deps.every((dep) => !!dep)
		});

	const value = {
		getMetaTagOptionsQuery,
		getMetaFactorDataQuery,
		updateMetaTagsMutator,
		getAnnotationDataQuery
	};

	return <TrialContext.Provider value={value}>{children}</TrialContext.Provider>;
};

export { TrialProvider, TrialContext };
