import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Segment, Grid, Form } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";

const WeatherDataManagement = () => {
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
	const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 31));
	const [startDateHistorical, setStartDateHistorical] = useState(new Date(new Date().getFullYear(), 0, 1));
	const [endDateHistorical, setEndDateHistorical] = useState(new Date(new Date().getFullYear(), 11, 31));

	//-- UI Control
	const [loading, setLoading] = useState(false);

	async function updateWeatherData() {
		const accessToken = await getTokenSilently();

		const dateRange = {
			startDate: startDate.toUTCString(),
			endDate: endDate.toUTCString()
		};

		setLoading(true);

		dispatch(weatherDataActions.updateWeatherData(dateRange, userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Weather Data has been updated and Validated");
					setLoading(false);
				}
			})
			.catch((error) => {
				toast.error("An Error has occured while updating weather data");
				console.log(error);
				setLoading(false);
			});
	}

	async function updateHistoricalWeatherData() {
		const accessToken = await getTokenSilently();

		const dateRange = {
			startDate: startDateHistorical.toDateString(),
			endDate: endDateHistorical.toDateString()
		};

		setLoading(true);

		dispatch(weatherDataActions.updateHistoricalWeatherData(dateRange, userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Historical Weather Data has been updated and Validated");
					setLoading(false);
				}
			})
			.catch((error) => {
				toast.error("An Error has occured while updating historical weather data");
				console.log(error);
				setLoading(false);
			});
	}

	return (
		<Segment basic>
			<Grid id="flightLogGrid" divided>
				<Grid.Column width={4}>
					<Form.Group>
						<Grid.Row>
							<Grid.Column width="4">
								<Form.Field
									id="form-date-time-picker-start"
									control={DateTimePicker}
									includeTime={false}
									value={startDate}
									required
									error={!startDate}
									label={<b>Plant Date/Flight Date</b>}
									onChange={(date) => {
										if (date) {
											date.setHours(0, 0, 0, 0);
											setStartDate(date);
										} else {
											setStartDate(null);
										}
									}}
								/>
							</Grid.Column>
							<Grid.Column textAlign="center" style={{ padding: "unset", width: 10 }}>
								<Form.Field>
									<label htmlFor="form-date-time-picker-to">to*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="4">
								<Form.Field
									id="form-date-time-picker-end"
									control={DateTimePicker}
									includeTime={false}
									value={endDate}
									required
									error={!endDate}
									onChange={(date) => {
										if (date) {
											date.setHours(0, 0, 0, 0);
											setEndDate(date);
										} else {
											setEndDate(null);
										}
									}}
								/>
							</Grid.Column>
						</Grid.Row>
					</Form.Group>
					<br />
					<Grid.Row>
						<Grid.Column width="4">
							<Form.Button
								floated="left"
								primary
								content="Update Daily Weather Data"
								loading={loading}
								onClick={updateWeatherData}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid.Column>
				<Grid.Column width={4}>
					<Form.Group>
						<Grid.Row>
							<Grid.Column width="4">
								<Form.Field
									id="form-date-time-historical-picker-start"
									control={DateTimePicker}
									includeTime={false}
									value={startDateHistorical}
									required
									error={!startDateHistorical}
									label={<b>Plant Date/Flight Date</b>}
									onChange={(date) => {
										if (date) {
											date.setHours(0, 0, 0, 0);
											setStartDateHistorical(date);
										} else {
											setStartDateHistorical(null);
										}
									}}
								/>
							</Grid.Column>
							<Grid.Column textAlign="center" style={{ padding: "unset", width: 10 }}>
								<Form.Field>
									<label htmlFor="form-date-time-historical-picker-to">to*</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="4">
								<Form.Field
									id="form-date-time--historical-picker-end"
									control={DateTimePicker}
									includeTime={false}
									value={endDateHistorical}
									required
									error={!endDateHistorical}
									onChange={(date) => {
										if (date) {
											date.setHours(0, 0, 0, 0);
											setEndDateHistorical(date);
										} else {
											setEndDateHistorical(null);
										}
									}}
								/>
							</Grid.Column>
						</Grid.Row>
					</Form.Group>
					<br />
					<Grid.Row>
						<Grid.Column width="4">
							<Form.Button
								floated="left"
								primary
								content="Update Historical Weather Data"
								loading={loading}
								onClick={updateHistoricalWeatherData}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid.Column>
			</Grid>
		</Segment>
	);
};

export default WeatherDataManagement;
