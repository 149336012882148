import { produce } from "immer";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";
import moment from "moment";
// import * as uuid from "uuid";

import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import { Button, Confirm, Form, Grid, Icon, Input, Loader, Message, Modal, Popup, Segment } from "semantic-ui-react";

import "./style.css";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as trialActions from "../../../../redux/actions/trialActions";
import * as editTrialAuth from "./editTrialAuth";
import { validateTrial } from "./trialValidation";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

import RemoveTrialOwnerSponsorModal from "./RemoveTrialOwnerSponsorModal";

const EditTrial = ({
	navigateToNextStep,
	initOriginalTrial,
	initWorkingTrial,
	initTrialOwnerEmailAddress,
	initTrialSponsorEmailAddress,
	setSaveAction,
	setIsDirty
}) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "M/D/YYYY";

	//-- Dropdown options
	const [companyOptions, setCompanyOptions] = useState([]);
	const [clientOptions, setClientOptions] = useState([]);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [flightScheduleOptions, setFlightScheduleOptions] = useState([]);
	const [emailAddressOptions, setEmailAddressOptions] = useState([]);

	//-- Trial data
	const [originalTrial, setOriginalTrial] = useState(initOriginalTrial);
	const [workingTrial, setWorkingTrialSilently] = useState(initWorkingTrial);
	const [trialOwnerEmailAddress, setTrialOwnerEmailAddress] = useState(initTrialOwnerEmailAddress);
	const [trialSponsorEmailAddress, setTrialSponsorEmailAddress] = useState(initTrialSponsorEmailAddress);

	//-- Validation
	const [trialIsValid, setTrialIsValid] = useState(true);
	const [trialValidation, setTrialValidation] = useState({});

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [modalLoading, setModalLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	// const [showEditCancelConfirm, setShowEditCancelConfirm] = useState(false);
	// const [resetKey, setResetKey] = useState(uuid.v4());
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [emailModalType, setEmailModalType] = useState("");
	const [trialOwnerChangingTrialOwner, setTrialOwnerChangingTrialOwner] = useState(false);
	const [trialSponsorChangingTrialSponsor, setTrialSponsorChangingTrialSponsor] = useState(false);
	const [showSendEmailConfirm, setShowSendEmailConfirm] = useState(false);
	const [isTrialDirty, setIsTrialDirty] = useState(false);

	//For Admin dropdown
	const [emailAddressForOwner, setEmailAddressForOwner] = useState(null);
	const [emailAddressForSponsor, setEmailAddressForSponsor] = useState(null);

	const [openRemoveTrialOwnerSponsorModal, setOpenRemoveTrialOwnerSponsorModal] = useState(false);
	const [removeTrialOwnerOrSponsor, setRemoveTrialOwnerOrSponsor] = useState("");

	const save = useCallback(async () => {
		const trialValidation = validateTrial(workingTrial);
		setTrialIsValid(trialValidation.isValid);
		setTrialValidation(trialValidation);

		if (!trialValidation.isValid) {
			window.scrollTo(0, 0);
			return false;
		}

		const trialUpdateObj = getUpdateObject();

		setIsSaving(true);

		const accessToken = await getTokenSilently();
		await dispatch(trialActions.updateTrial(trialUpdateObj, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Trial saved successfully");
					setOriginalTrial({ ...originalTrial, trialName: trialUpdateObj.trialName });
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}

				setIsSaving(false);
				setIsTrialDirty(false);
				return true;
			})
			.catch((error) => {
				setIsSaving(false);
				setIsTrialDirty(false);
				if (error && error.errors) {
					error.errors.forEach((e) => toast.error(e));
				} else {
					toast.error("Trial failed to update");
				}
				return false;
			});
		return true;
	}, [workingTrial, userAuth.currentClientId]);

	useEffect(() => {
		setIsDirty(isTrialDirty);
	}, [isTrialDirty]);

	useEffect(() => {
		if (userAuth.currentClientId && moduleNavigation.trialId) {
			getTrialOptions(userAuth.currentClientId);
		}
	}, [userAuth.currentClientId]);

	useEffect(() => {
		setSaveAction(() => save);
	}, [save]);

	const setWorkingTrial = (newState) => {
		setIsTrialDirty(true);
		setWorkingTrialSilently(newState);
	};

	async function getTrialOptions(clientId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(trialActions.getTrialOptions(clientId, moduleNavigation.trialId, accessToken))
			.then((res) => {
				initializeCompanyOptions(res.companies);
				initializeClientOptions(userAuth.user.userInfo.clientMemberships);
				initializeMeanComparisons(res.meanComparisons);
				initializeAlphas(res.alphas);
				initializeFlightScheduleOptions(res.flightScheduleOptions);
				initializeEmailAddressOptions(res.emailAddresses);
				setLoading(false);
			})
			.catch((err) => {
				console.log("Error loading trial options");
				console.log(err);
				setLoading(false);
			});
	}

	const initializeCompanyOptions = (companies) => {
		const companyOpts = _.map(companies, (c) => {
			const opt = {
				key: c.id,
				value: c.id,
				text: c.name
			};
			return opt;
		});
		let filteredCompanyOpts = _.filter(companyOpts, (c) => {
			return c.key;
		});
		setCompanyOptions(filteredCompanyOpts);
	};

	const initializeClientOptions = (clients) => {
		const clientOpts = _.map(clients, (c) => {
			const opt = {
				key: c.clientId,
				value: c.clientId,
				text: c.clientName
			};
			return opt;
		});
		setClientOptions(clientOpts);
	};

	function initializeMeanComparisons(meanComparisons) {
		const meanComparisonOpts = _.map(meanComparisons, (mc) => {
			const opt = {
				key: mc.id,
				value: mc.id,
				text: mc.name
			};
			return opt;
		});
		setMeanComparisonOptions(meanComparisonOpts);
	}

	function initializeAlphas(alphas) {
		alphas = _.sortBy(alphas, "value");
		const alphaOpts = _.map(alphas, (a) => {
			const opt = {
				key: a.id,
				value: a.id,
				text: a.value
			};
			return opt;
		});
		setAlphaOptions(alphaOpts);
	}

	const initializeFlightScheduleOptions = (flightScheduleData) => {
		let flightScheduleOpts = _.map(flightScheduleData, (fs) => {
			const opt = {
				key: fs.id,
				value: fs.id,
				text: fs.name
			};
			return opt;
		});
		let filteredFlightScheduleOpts = _.filter(flightScheduleOpts, (c) => {
			return c.key;
		});
		filteredFlightScheduleOpts = _.orderBy(filteredFlightScheduleOpts, ["text"]);
		setFlightScheduleOptions(filteredFlightScheduleOpts);
	};

	const initializeEmailAddressOptions = (emailAddressData) => {
		let emailAddressOpts = _.map(emailAddressData, (ea) => {
			const opt = {
				key: ea.emailAddress,
				value: ea.emailAddress,
				text: ea.emailAddress
			};
			return opt;
		});
		let filteredEmailAddressOpts = _.filter(emailAddressOpts, (c) => {
			return c.key;
		});
		filteredEmailAddressOpts = _.orderBy(filteredEmailAddressOpts, (filteredEmailAddressOpts) =>
			filteredEmailAddressOpts.text.toLowerCase()
		);
		setEmailAddressOptions(filteredEmailAddressOpts);
	};

	// const undoChanges = () => {
	// 	setResetKey(uuid.v4());
	// 	setWorkingTrialSilently(originalTrial);
	// 	setIsTrialDirty(false);
	// 	setTrialIsValid(true);
	// 	setTrialValidation({});
	// };

	const getUpdateObject = () => {
		const trialUpdateObj = {
			clientId: userAuth.currentClientId,
			trialId: moduleNavigation.trialId,
			trialName: editTrialAuth.userCanEditTrialName(userAuth) ? workingTrial.name : originalTrial.name,
			trialOwnerCompanyId: workingTrial.trialOwnerCompanyId,
			trialOwnerClientId: workingTrial.trialOwnerClientId,
			trialSponsorCompanyId: workingTrial.trialSponsorCompanyId,
			trialSponsorClientId: workingTrial.trialSponsorClientId,
			plantDate: editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)
				? workingTrial.plantDate
				: originalTrial.plantDate,
			harvestDate: editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId)
				? workingTrial.harvestDate
				: originalTrial.harvestDate,
			treatments: editTrialAuth.userCanEditTreatments(userAuth) ? workingTrial.treatments : originalTrial.treatments,
			meanComparisonId: workingTrial.meanComparisonId,
			alphaId: workingTrial.alphaId,
			isEstimatedPlantDate: workingTrial.isEstimatedPlantDate,
			flightScheduleId: workingTrial.flightScheduleId
		};

		return trialUpdateObj;
	};

	const handleSaveTrialOwnerEmailAddress = async () => {
		setModalLoading(true);
		if (
			(userAuth.isApAdmin && _.isEmpty(emailAddressForOwner)) ||
			(!userAuth.isApAdmin && _.isEmpty(trialOwnerEmailAddress))
		) {
			toast.error("Please enter a valid email address");
			setModalLoading(false);
			return;
		}
		const accessToken = await getTokenSilently();
		dispatch(
			trialActions.setTrialOwnerEmailAddress(
				userAuth.isApAdmin ? emailAddressForOwner : trialOwnerEmailAddress,
				workingTrial.id,
				userAuth.currentClientId,
				workingTrial.trialOwnerClientId,
				trialOwnerChangingTrialOwner,
				workingTrial.publishedToTrialOwner,
				workingTrial.fieldResearcherId,
				workingTrial.trialSponsorUserId,
				accessToken
			)
		).then((res) => {
			if (res.errors && res.errors.length > 0) {
				toast.error(res.errors[0]);

				if (res.errors[0].includes("A user does not exist")) {
					setShowSendEmailConfirm(true);
				}
			} else {
				const newWorkingTrial = produce(workingTrial, (draft) => {
					draft.trialOwnerUserId = res.userId;
					draft.trialOwner = `${res.firstName} ${res.lastName}`;
					draft.trialOwnerClientId = res.singleClientId ?? null;
					draft.trialOwnerClientName = res.singleClientName ?? null;
					draft.trialOwnerIsPending = res.isPending;
				});
				setWorkingTrialSilently(newWorkingTrial);

				toast.success(res.message);
				setShowEmailModal(false);
			}

			setModalLoading(false);
		});
	};

	const handleSaveTrialSponsorEmailAddress = async () => {
		setModalLoading(true);
		if (
			(userAuth.isApAdmin && _.isEmpty(emailAddressForSponsor)) ||
			(!userAuth.isApAdmin && _.isEmpty(trialSponsorEmailAddress))
		) {
			toast.error("Please enter a valid email address");
			setModalLoading(false);
			return;
		}
		const accessToken = await getTokenSilently();
		dispatch(
			trialActions.setTrialSponsorEmailAddress(
				userAuth.isApAdmin ? emailAddressForSponsor : trialSponsorEmailAddress,
				workingTrial.id,
				userAuth.currentClientId,
				workingTrial.trialSponsorClientId,
				trialSponsorChangingTrialSponsor,
				workingTrial.publishedToTrialSponsor,
				workingTrial.fieldResearcherId,
				workingTrial.trialOwnerUserId,
				accessToken
			)
		).then((res) => {
			if (res.errors && res.errors.length > 0) {
				toast.error(res.errors[0]);

				if (res.errors[0].includes("A user does not exist")) {
					setShowSendEmailConfirm(true);
				}
			} else {
				const newWorkingTrial = produce(workingTrial, (draft) => {
					draft.trialSponsorUserId = res.userId;
					draft.trialSponsor = `${res.firstName} ${res.lastName}`;
					draft.trialSponsorClientId = res.singleClientId ?? null;
					draft.trialSponsorClientName = res.singleClientName ?? null;
					draft.trialSponsorIsPending = res.isPending;
				});
				setWorkingTrialSilently(newWorkingTrial);

				toast.success(res.message);
				setShowEmailModal(false);
			}

			setModalLoading(false);
		});
	};

	const sendNewUserEmail = async (emailAddress) => {
		// Same splitting logic as below
		let clientName = workingTrial.fieldResearcher;
		if (
			(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
				userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
			workingTrial.publishedToTrialOwner
		) {
			clientName = workingTrial.trialOwnerClientName ? workingTrial.trialOwnerClientName : userAuth.currentClient;
		} else if (
			(userAuth.currentClientId === workingTrial.trialSponsorClientId ||
				userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
			workingTrial.publishedToTrialSponsor
		) {
			clientName = workingTrial.trialSponsorClientName ? workingTrial.trialSponsorClientName : userAuth.currentClient;
		}

		const accessToken = await getTokenSilently();
		dispatch(trialActions.sendNewUserEmail(emailAddress, clientName, userAuth.currentClientId, accessToken))
			.then(() => {
				toast.success("Email was sent to that address");
				setShowSendEmailConfirm(false);
				setShowEmailModal(false);
			})
			.catch((error) => {
				if (error && error.errors) {
					error.errors.forEach((e) => toast.error(e));
				}
				setShowSendEmailConfirm(false);
			});
	};

	function setPlantDate(date) {
		if (date) {
			date.setHours(0, 0, 0, 0);
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.plantDate = date;
			});
			setWorkingTrial(newWorkingTrial);
		} else {
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.plantDate = null;
			});
			setWorkingTrial(newWorkingTrial);
		}
	}

	function setHarvestDate(date) {
		if (date) {
			date.setHours(0, 0, 0, 0);
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.harvestDate = date;
			});
			setWorkingTrial(newWorkingTrial);
		} else {
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.harvestDate = null;
			});
			setWorkingTrial(newWorkingTrial);
		}
	}

	return loading ? (
		<Loader active />
	) : !loading && !originalTrial ? (
		<Segment style={{ margin: 15 }}>
			<p>{"We could not find this trial."}</p>
		</Segment>
	) : (
		<>
			<div>
				<div
					style={{ display: "flex", alignItems: "end", textWrap: "nowrap", overflow: "hidden", marginBottom: "1em" }}
				>
					<div style={{ flexGrow: 1, minWidth: 10 }} />
					{/* <Button compact id="form-button-cancel" content="Cancel" onClick={() => setShowEditCancelConfirm(true)} /> */}
					<Button
						compact
						id="form-button-save"
						color="blue"
						content="Save"
						loading={isSaving}
						disabled={isSaving}
						onClick={() => save()}
					/>
					<Button
						compact
						id="form-button-save"
						primary
						content="Save and Continue"
						loading={isSaving}
						disabled={isSaving}
						onClick={() => {
							save().then(() => navigateToNextStep());
						}}
					/>
				</div>

				{/* <Confirm
					open={showEditCancelConfirm}
					header="Discard Changes"
					content="Are you sure you want to cancel? All changes will be lost."
					onCancel={() => setShowEditCancelConfirm(false)}
					onConfirm={() => {
						undoChanges();
						setShowEditCancelConfirm(false);
					}}
				/> */}
			</div>
			<div>
				{/* HEADER */}

				{/* FORM */}
				<Form error={!trialIsValid} style={{ gap: ".5em", display: "flex", flexDirection: "column" }}>
					{!trialIsValid && (
						<Form.Group>
							<Form.Field width="16" style={{ marginBottom: 10 }}>
								<Message error list={trialValidation.messages} />
							</Form.Field>
						</Form.Group>
					)}
					{/* FORM INPUTS */}
					<Form.Group>
						<Form.Input
							label="Trial Name"
							width="6"
							// key={resetKey}
							id="form-input-trial-name"
							defaultValue={workingTrial.name}
							onBlur={(event) => {
								const newWorkingTrial = produce(workingTrial, (draft) => {
									draft.name = event.target.value;
								});
								setWorkingTrial(newWorkingTrial);
							}}
							error={trialValidation.nameError}
							readOnly={!editTrialAuth.userCanEditTrialName(userAuth)}
						/>
						<Form.Input
							label="Field Researcher"
							width="6"
							id="form-input-field-researcher"
							value={workingTrial.fieldResearcher ?? ""}
							readOnly
						/>
					</Form.Group>
					<Form.Group>
						{/* If user is in client that trial is published to or user is the trial owner */}
						{(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
							userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
							workingTrial.publishedToTrialOwner ? (
							<>
								{userAuth.user.userInfo.basic.userId !== workingTrial.trialOwnerUserId && !userAuth.isApAdmin ? (
									<Form.Input label="Trial Owner Org" width="6" value={workingTrial.trialOwnerClientName} readOnly />
								) : (
									<Form.Select
										label="Trial Owner Org"
										width="6"
										id="form-select-client"
										search
										options={clientOptions}
										value={workingTrial.trialOwnerClientId}
										onChange={(event, { value }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.trialOwnerClientId = value;
											});
											setWorkingTrial(newWorkingTrial);
										}}
										loading={!clientOptions || clientOptions.length === 0}
										error={trialValidation.clientError}
										readOnly={
											userAuth.user.userInfo.basic.userId !== workingTrial.trialOwnerUserId && !userAuth.isApAdmin
										}
									/>
								)}
							</>
						) : (userAuth.currentClientId === workingTrial.trialSponsorClientId ||
							userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
							workingTrial.publishedToTrialSponsor ? (
							<>
								{/* Above but for trial sponsor */}
								{userAuth.user.userInfo.basic.userId !== workingTrial.trialSponsorUserId && !userAuth.isApAdmin ? (
									<Form.Input
										label="Trial Sponsor Org"
										width="6"
										value={workingTrial.trialSponsorClientName}
										readOnly
									/>
								) : (
									<Form.Select
										label="Trial Sponsor Org"
										width="6"
										id="form-select-client"
										search
										options={clientOptions}
										value={workingTrial.trialSponsorClientId}
										onChange={(event, { value }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.trialSponsorClientId = value;
											});
											setWorkingTrial(newWorkingTrial);
										}}
										loading={!clientOptions || clientOptions.length === 0}
										error={trialValidation.trialSponsorClientId}
									/>
								)}
							</>
						) : (
							userAuth.currentClientId === workingTrial.clientId && (
								<>
									{/* If user is in the client that published trial */}
									<Form.Select
										label="Company"
										width="6"
										id="form-select-company"
										search
										options={companyOptions}
										value={workingTrial.trialOwnerCompanyId}
										onChange={(event, { value }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.trialOwnerCompanyId = value;
											});
											setWorkingTrial(newWorkingTrial);
										}}
										loading={loading && (!companyOptions || companyOptions.length === 0)}
										error={trialValidation.companyError}
									/>
								</>
							)
						)}
						<Form.Input
							width="6"
							label="Location"
							id="form-input-location"
							value={workingTrial.location ?? ""}
							readOnly
						/>
					</Form.Group>
					<Form.Group>
						{(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
							userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
							workingTrial.publishedToTrialOwner ? (
							<Form.Field width="6">
								<label htmlFor="form-select-trial-owner">
									Trial Owner{" "}
									{workingTrial.trialOwnerIsPending && (
										<Popup
											trigger={<Icon name="info circle" />}
											content="This user does not have the correct license to view this trial."
										/>
									)}
								</label>
								<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Input
										style={{ flexGrow: 1 }}
										value={`${workingTrial.trialOwner} ${workingTrial.trialOwnerIsPending ? "(Pending)" : ""}`}
										readOnly
									/>
									<Button
										style={{ margin: 0 }}
										primary
										compact
										content="Change"
										onClick={() => {
											setTrialOwnerChangingTrialOwner(true);
											setEmailModalType("Owner");
											setShowEmailModal(true);
										}}
									/>
									<Button
										style={{ margin: 0 }}
										negative
										compact
										content="Remove"
										onClick={() => {
											if (workingTrial.trialSponsorUserId) {
												toast.error("Cannot remove trial owner when trial sponsor is set");
											} else {
												setOpenRemoveTrialOwnerSponsorModal(true);
												setRemoveTrialOwnerOrSponsor("Owner");
											}
										}}
									/>
								</div>
							</Form.Field>
						) : (userAuth.currentClientId === workingTrial.trialSponsorClientId ||
							userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
							workingTrial.publishedToTrialSponsor ? (
							<Form.Field width="6">
								<label htmlFor="form-select-trial-owner">
									Trial Sponsor{" "}
									{workingTrial.trialSponsorIsPending && (
										<Popup
											trigger={<Icon name="info circle" />}
											content="This user does not have the correct license to view this trial."
										/>
									)}
								</label>
								<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Input
										fluid
										style={{ flexGrow: 1 }}
										value={`${workingTrial.trialSponsor} ${workingTrial.trialSponsorIsPending ? "(Pending)" : ""}`}
										readOnly
									/>
									<Button
										style={{ margin: 0 }}
										primary
										compact
										content="Change"
										onClick={() => {
											setTrialSponsorChangingTrialSponsor(true);
											setEmailModalType("Sponsor");
											setShowEmailModal(true);
										}}
									/>
									<Button
										style={{ margin: 0 }}
										negative
										compact
										content="Remove"
										onClick={() => {
											setOpenRemoveTrialOwnerSponsorModal(true);
											setRemoveTrialOwnerOrSponsor("Sponsor");
										}}
									/>
								</div>
							</Form.Field>
						) : (
							userAuth.currentClientId === workingTrial.clientId && (
								<Form.Field width="6">
									<label htmlFor="form-select-trial-owner">
										Trial Owner{" "}
										{workingTrial.trialOwnerIsPending && (
											<Popup
												trigger={<Icon name="info circle" />}
												content="This user does not have the correct license to view this trial."
											/>
										)}
									</label>
									{workingTrial.trialOwnerUserId ? (
										<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
											<Input
												style={{ flexGrow: 1 }}
												fluid
												value={`${workingTrial.trialOwner} ${workingTrial.trialOwnerIsPending ? " (Pending)" : ""}`}
												readOnly
											/>
											<Button
												style={{ margin: 0 }}
												primary
												compact
												content="Change"
												onClick={() => {
													setTrialOwnerChangingTrialOwner(false);
													setEmailModalType("Owner");
													setShowEmailModal(true);
												}}
											/>
											<Button
												style={{ margin: 0 }}
												negative
												compact
												content="Remove"
												onClick={() => {
													if (workingTrial.trialSponsorUserId) {
														toast.error("Cannot remove trial owner when trial sponsor is set");
													} else {
														setOpenRemoveTrialOwnerSponsorModal(true);
														setRemoveTrialOwnerOrSponsor("Owner");
													}
												}}
											/>
										</div>
									) : (
										<>
											<Button
												primary
												fluid
												content="Assign Trial Owner"
												onClick={() => {
													setTrialOwnerChangingTrialOwner(false);
													setEmailModalType("Owner");
													setShowEmailModal(true);
												}}
											/>
										</>
									)}
								</Form.Field>
							)
						)}
						<Form.Input
							label="GPS Coordinates (Plot 101)"
							width="6"
							id="form-input-gps-coordinates"
							value={workingTrial.gpsCoordinates ?? ""}
							readOnly
						/>
					</Form.Group>
					{(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
						userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
						workingTrial.publishedToTrialOwner ? (
						<>
							<Form.Group>
								<Form.Select
									label="Sponsor Company"
									width="6"
									id="select-trial-sponsor-company"
									search
									options={companyOptions}
									value={workingTrial.trialSponsorCompanyId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.trialSponsorCompanyId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={loading && (!companyOptions || companyOptions.length === 0)}
									error={trialValidation.companyError}
									disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAssignSponsor, userAuth.currentClientId)}
								/>
								<Form.Input
									label="Treatment Count"
									width="3"
									id="form-input-treatments"
									value={workingTrial.treatments?.length ?? ""}
									readOnly
								/>

								<Form.Input
									label="Rep Count"
									width="3"
									id="form-input-reps"
									value={workingTrial.repCount ?? ""}
									readOnly
								/>
							</Form.Group>
							<Form.Group>
								<Form.Field width="6">
									<label htmlFor="form-select-trial-owner">
										Trial Sponsor{" "}
										{workingTrial.trialSponsorIsPending && (
											<Popup
												trigger={<Icon name="info circle" />}
												content="This user does not have the correct license to view this trial."
											/>
										)}
									</label>
									{workingTrial.trialSponsorUserId ? (
										<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
											<Input
												style={{ flexGrow: 1 }}
												fluid
												value={`${workingTrial.trialSponsor} ${workingTrial.trialSponsorIsPending ? "(Pending)" : ""}`}
												readOnly
											/>
											<Button
												style={{ margin: 0 }}
												primary
												compact
												content="Change"
												onClick={() => {
													setTrialSponsorChangingTrialSponsor(false);
													setEmailModalType("Sponsor");
													setShowEmailModal(true);
												}}
												disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAssignSponsor, userAuth.currentClientId)}
											/>
											<Button
												style={{ margin: 0 }}
												negative
												compact
												content="Remove"
												onClick={() => {
													setOpenRemoveTrialOwnerSponsorModal(true);
													setRemoveTrialOwnerOrSponsor("Sponsor");
												}}
												disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAssignSponsor, userAuth.currentClientId)}
											/>
										</div>
									) : (
										<>
											<Button
												primary
												fluid
												content="Assign Trial Sponsor"
												onClick={() => {
													setTrialSponsorChangingTrialSponsor(false);
													setEmailModalType("Sponsor");
													setShowEmailModal(true);
												}}
												disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAssignSponsor, userAuth.currentClientId)}
											/>
										</>
									)}
								</Form.Field>
								<Form.Field width="6">
									<label htmlFor="form-dateinput-plant-date">Plant Date</label>
									{editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId) ? (
										<div style={{ flexGrow: 1 }}>
											<Form.Field
												id="form-date-time-picker-plant-date"
												control={DateTimePicker}
												includeTime={false}
												value={workingTrial.plantDate}
												onChange={(date) => {
													setPlantDate(date);
												}}
												error={trialValidation.plantDate}
											/>
										</div>
									) : (
										<Form.Input
											id="form-input-plant-date"
											value={workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""}
											readOnly
										/>
									)}
								</Form.Field>
								<Form.Checkbox
									label="Is Estimated Plant Date"
									style={{ marginTop: "2.5em" }}
									id="form-is-estimate-plant-date"
									readOnly={!editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)}
									checked={workingTrial.isEstimatedPlantDate}
									onChange={(e, { checked }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.isEstimatedPlantDate = checked;
										});
										setWorkingTrial(newWorkingTrial);
									}}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Input
									label="Trial Sponsor Org"
									width="6"
									fluid
									id="input-trial-sponsor-org"
									value={workingTrial.trialSponsorClientName ? workingTrial.trialSponsorClientName : "Not Assigned"}
									readOnly
								/>
								<Form.Field width="6">
									<label htmlFor="form-dateinput-harvest-date">Harvest Date</label>
									{editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId) ? (
										<Form.Field
											id="form-date-time-picker-harvest-date"
											control={DateTimePicker}
											includeTime={false}
											value={workingTrial.harvestDate}
											onChange={(date) => {
												setHarvestDate(date);
											}}
											error={trialValidation.harvestDate}
										/>
									) : (
										<Form.Input
											id="form-input-harvest-date"
											value={
												workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""
											}
											readOnly
										/>
									)}
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Select
									label="Mean Comparison"
									width="3"
									id="form-select-mean-comparison"
									search
									style={{ minWidth: "unset" }}
									options={meanComparisonOptions}
									value={workingTrial.meanComparisonId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.meanComparisonId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={!meanComparisonOptions || meanComparisonOptions.length === 0}
								/>
								<Form.Select
									label="Alpha"
									width="3"
									id="form-select-alpha"
									search
									style={{ minWidth: "unset" }}
									options={alphaOptions}
									value={workingTrial.alphaId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.alphaId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={!alphaOptions || alphaOptions.length === 0}
								/>
								<Form.Select
									label="Flight Schedule"
									width="6"
									id="form-select-flight-schedule"
									search
									style={{ minWidth: "unset" }}
									options={flightScheduleOptions}
									value={workingTrial.flightScheduleId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.flightScheduleId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={!flightScheduleOptions || flightScheduleOptions.length === 0}
								/>
							</Form.Group>
						</>
					) : (userAuth.currentClientId === workingTrial.trialSponsorClientId ||
						userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
						workingTrial.publishedToTrialSponsor ? (
						<>
							<Form.Group>
								<Form.Input
									label="Trial Owner Org"
									width="6"
									id="form-trial-owner-org"
									value={workingTrial.trialOwnerClientName ? workingTrial.trialOwnerClientName : "Not Assigned"}
									readOnly
								/>
								<Form.Input
									label="Treatment Count"
									width="3"
									id="form-input-treatments"
									value={workingTrial.treatments?.length ?? ""}
									readOnly
								/>
								<Form.Input
									label="Rep Count"
									width="3"
									id="form-input-reps"
									value={workingTrial.repCount ?? ""}
									readOnly
								/>
							</Form.Group>
							<Form.Group>
								<Form.Input
									label={
										<label htmlFor="form-select-trial-owner">
											Trial Owner{" "}
											{workingTrial.trialOwnerIsPending && (
												<Popup
													trigger={<Icon name="info circle" />}
													content="This user does not have the correct license to view this trial."
												/>
											)}
										</label>
									}
									width="6"
									id="form-trial-owner"
									value={`${workingTrial.trialOwner} ${workingTrial.trialOwnerIsPending ? "(Pending)" : ""}`}
									readOnly
								/>
								<Form.Field width="6">
									<label htmlFor="form-dateinput-plant-date">Plant Date</label>
									{editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId) ? (
										<div style={{ flexGrow: 1 }}>
											<Form.Field
												id="form-date-time-picker-plant-date"
												control={DateTimePicker}
												includeTime={false}
												value={workingTrial.plantDate}
												onChange={(date) => {
													setPlantDate(date);
												}}
												error={trialValidation.plantDate}
											/>
										</div>
									) : (
										<Form.Input
											id="form-input-plant-date"
											value={workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""}
											readOnly
										/>
									)}
								</Form.Field>
								<Form.Checkbox
									label="Is Estimated Plant Date"
									style={{ marginTop: "2.5em" }}
									id="form-is-estimate-plant-date"
									readOnly={!editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)}
									checked={workingTrial.isEstimatedPlantDate}
									onChange={(e, { checked }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.isEstimatedPlantDate = checked;
										});
										setWorkingTrial(newWorkingTrial);
									}}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Input
									label="Trial Sponsor Org"
									width="6"
									fluid
									id="input-trial-sponsor-org"
									value={workingTrial.trialSponsorClientName ? workingTrial.trialSponsorClientName : "Not Assigned"}
									readOnly
								/>

								<Form.Field width="6">
									<label htmlFor="form-dateinput-harvest-date">Harvest Date</label>
									{editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId) ? (
										<Form.Field
											id="form-date-time-picker-harvest-date"
											control={DateTimePicker}
											includeTime={false}
											value={workingTrial.harvestDate}
											onChange={(date) => {
												setHarvestDate(date);
											}}
											error={trialValidation.harvestDate}
										/>
									) : (
										<Form.Input
											id="form-input-harvest-date"
											value={
												workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""
											}
											readOnly
										/>
									)}
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Select
									label="Mean Comparison"
									width="3"
									id="form-select-mean-comparison"
									search
									style={{ minWidth: "unset" }}
									options={meanComparisonOptions}
									value={workingTrial.meanComparisonId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.meanComparisonId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={!meanComparisonOptions || meanComparisonOptions.length === 0}
								/>
								<Form.Select
									label="Alpha"
									width="3"
									id="form-select-alpha"
									search
									style={{ minWidth: "unset" }}
									options={alphaOptions}
									value={workingTrial.alphaId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.alphaId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={!alphaOptions || alphaOptions.length === 0}
								/>
								<Form.Select
									label="Flight Schedule"
									width="6"
									id="form-select-flight-schedule"
									search
									style={{ minWidth: "unset" }}
									options={flightScheduleOptions}
									value={workingTrial.flightScheduleId}
									onChange={(event, { value }) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.flightScheduleId = value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									loading={!flightScheduleOptions || flightScheduleOptions.length === 0}
								/>
							</Form.Group>
						</>
					) : (
						userAuth.currentClientId === workingTrial.clientId && (
							<>
								<Form.Group>
									<Form.Input
										label="Trial Owner Org"
										width="6"
										fluid
										id="input-trial-owner-org"
										value={workingTrial.trialOwnerClientName ? workingTrial.trialOwnerClientName : "Not Assigned"}
										readOnly
									/>
									<Form.Field width="6">
										<label htmlFor="form-dateinput-plant-date">Plant Date</label>
										{editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId) ? (
											<div style={{ flexGrow: 1 }}>
												<Form.Field
													id="form-date-time-picker-plant-date"
													control={DateTimePicker}
													includeTime={false}
													value={workingTrial.plantDate}
													onChange={(date) => {
														setPlantDate(date);
													}}
													error={trialValidation.plantDate}
												/>
											</div>
										) : (
											<Form.Input
												id="form-input-plant-date"
												value={workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""}
												readOnly
											/>
										)}
									</Form.Field>
									<Form.Checkbox
										label="Is Estimated Plant Date"
										style={{ marginTop: "2.5em" }}
										id="form-is-estimate-plant-date"
										readOnly={!editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)}
										checked={workingTrial.isEstimatedPlantDate}
										onChange={(e, { checked }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.isEstimatedPlantDate = checked;
											});
											setWorkingTrial(newWorkingTrial);
										}}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Input
										label="Treatment Count"
										width="3"
										id="form-input-treatments"
										value={workingTrial.treatments?.length ?? ""}
										readOnly
									/>
									<Form.Input
										label="Rep Count"
										width="3"
										id="form-input-reps"
										value={workingTrial.repCount ?? ""}
										readOnly
									/>
									<Form.Field width="6">
										<label>Harvest Date</label>
										{editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId) ? (
											<Form.Field
												id="form-date-time-picker-harvest-date"
												control={DateTimePicker}
												includeTime={false}
												value={workingTrial.harvestDate}
												onChange={(date) => {
													setHarvestDate(date);
												}}
												error={trialValidation.harvestDate}
											/>
										) : (
											<Form.Input
												id="form-input-harvest-date"
												value={
													workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""
												}
												readOnly
											/>
										)}
									</Form.Field>
								</Form.Group>
								<Form.Group>
									<Form.Select
										label="Mean Comparison"
										width="3"
										id="form-select-mean-comparison"
										search
										style={{ minWidth: "unset" }}
										options={meanComparisonOptions}
										value={workingTrial.meanComparisonId}
										onChange={(event, { value }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.meanComparisonId = value;
											});
											setWorkingTrial(newWorkingTrial);
										}}
										loading={!meanComparisonOptions || meanComparisonOptions.length === 0}
									/>
									<Form.Select
										label="Alpha"
										width="3"
										id="form-select-alpha"
										search
										style={{ minWidth: "unset" }}
										options={alphaOptions}
										value={workingTrial.alphaId}
										onChange={(event, { value }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.alphaId = value;
											});
											setWorkingTrial(newWorkingTrial);
										}}
										loading={!alphaOptions || alphaOptions.length === 0}
									/>
									<Form.Select
										label="Flight Schedule"
										width="6"
										id="form-select-flight-schedule"
										search
										style={{ minWidth: "unset" }}
										options={flightScheduleOptions}
										value={workingTrial.flightScheduleId}
										onChange={(event, { value }) => {
											const newWorkingTrial = produce(workingTrial, (draft) => {
												draft.flightScheduleId = value;
											});
											setWorkingTrial(newWorkingTrial);
										}}
										loading={!flightScheduleOptions || flightScheduleOptions.length === 0}
										error={trialValidation.flightScheduleId}
									/>
								</Form.Group>
							</>
						)
					)}

					<Confirm
						open={showSendEmailConfirm}
						header="A user with that email does not exist in the system"
						content={`Do you want to send an email to '${emailModalType === "Owner" ? trialOwnerEmailAddress : trialSponsorEmailAddress
							}' informing them of this trial?`}
						onCancel={() => setShowSendEmailConfirm(false)}
						onConfirm={() => {
							sendNewUserEmail(emailModalType === "Owner" ? trialOwnerEmailAddress : trialSponsorEmailAddress);
						}}
					/>
					<Modal open={showEmailModal}>
						<Modal.Header>Assign Trial {emailModalType}</Modal.Header>
						<Modal.Content>
							<Modal.Description>
								<Grid>
									{userAuth.isApAdmin ? (
										<Grid.Row>
											<Grid.Column width={12}>
												<label>
													<b>Enter Email</b>
												</label>
												<Form.Dropdown
													style={{ width: "100%" }}
													id="form-select-email"
													search
													fluid
													options={emailAddressOptions}
													value={emailModalType === "Owner" ? emailAddressForOwner : emailAddressForSponsor}
													onChange={(event, { value }) => {
														emailModalType === "Owner"
															? setEmailAddressForOwner(value)
															: setEmailAddressForSponsor(value);
													}}
													onSearchChange={(event, { searchQuery }) => {
														emailModalType === "Owner"
															? setEmailAddressForOwner(searchQuery)
															: setEmailAddressForSponsor(searchQuery);
													}}
													loading={!emailAddressOptions || emailAddressOptions.length === 0}
													placeholder="Email Address"
													selection
													type="text"
													selectOnBlur={false}
													openOnFocus
													searchQuery={emailModalType === "Owner" ? emailAddressForOwner : emailAddressForSponsor}
												/>
											</Grid.Column>
											<Grid.Column width={2}>
												<br></br>
												<Button
													onClick={() =>
														emailModalType === "Owner" ? setEmailAddressForOwner("") : setEmailAddressForSponsor("")
													}
												>
													Clear
												</Button>
											</Grid.Column>
										</Grid.Row>
									) : (
										<Grid.Row>
											<Grid.Column width={12}>
												<label>
													<b>Enter Email</b>
												</label>
												<Input
													// key={resetKey}
													fluid
													id="input-email"
													value={emailModalType === "Owner" ? trialOwnerEmailAddress : trialSponsorEmailAddress}
													onChange={(event) => {
														emailModalType === "Owner"
															? setTrialOwnerEmailAddress(event.target.value)
															: setTrialSponsorEmailAddress(event.target.value);
													}}
													readOnly={modalLoading}
													placeholder="Email Address"
												/>
											</Grid.Column>
											<Grid.Column width={2}>
												<br></br>
												<Button
													onClick={() =>
														emailModalType === "Owner" ? setTrialOwnerEmailAddress("") : setTrialSponsorEmailAddress("")
													}
												>
													Clear
												</Button>
											</Grid.Column>
										</Grid.Row>
									)}
								</Grid>
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions>
							<Button
								type="button"
								floated="right"
								onClick={() =>
									emailModalType === "Owner" ? handleSaveTrialOwnerEmailAddress() : handleSaveTrialSponsorEmailAddress()
								}
								positive
								loading={modalLoading}
								disabled={modalLoading}
							>
								Save
							</Button>
							<Button type="button" floated="right" onClick={() => setShowEmailModal(false)} disabled={modalLoading}>
								Cancel
							</Button>
							<br style={{ clear: "both" }} />
						</Modal.Actions>
					</Modal>
				</Form>
			</div>
			<RemoveTrialOwnerSponsorModal
				open={openRemoveTrialOwnerSponsorModal}
				setOpen={(isOpen) => setOpenRemoveTrialOwnerSponsorModal(isOpen)}
				trialId={workingTrial.id}
				clientId={userAuth.currentClientId}
				removeTrialOwnerOrSponsor={removeTrialOwnerOrSponsor}
				setWorkingTrialSilently={setWorkingTrialSilently}
				workingTrial={workingTrial}
			/>
		</>
	);
};

EditTrial.propTypes = {
	navigateToNextStep: PropTypes.func.isRequired,
	initOriginalTrial: PropTypes.object,
	initWorkingTrial: PropTypes.object,
	initTrialOwnerEmailAddress: PropTypes.string,
	initTrialSponsorEmailAddress: PropTypes.string,
	setSaveAction: PropTypes.func,
	setIsDirty: PropTypes.func
};

export default EditTrial;
