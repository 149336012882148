import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, FC } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Button, Grid, Loader, Segment, Table } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0/index.js";
import { useUserAuth } from "../../../../hooks/useUserAuth.js";
import * as trialActions from "../../../../redux/actions/trialActions.js";
import TrialMap from "./TrialMap";

type props = {
	trialId: string;
	trialName: string;
	trialDetails: string;
	treatments: trialMapData[];
	navigateToNextStep: () => {};
	navigateToPreviousStep: () => {};
};

type trialMapData = {
	treatmentId: string;
	trialTreatmentId: number;
	treatmentName: string;
	treatmentDescription: string;
	treatmentTypeName: string;
	plotId: string;
	plotName: string;
	column: number;
	range: number;
	seed: string;
	replicate: number;
	color?: string;
	pattern?: string;
};

enum colors {
	"#a9a9a9",
	"#e6194B",
	"#3cb44b",
	"#ffe119",
	"#4363d8",
	"#f58231",
	"#911eb4",
	"#42d4f4",
	"#f032e6",
	"#bfef45",
	"#fabed4",
	"#dcbeff",
	"#469990",
	"#9A6324",
	"#fffac8",
	"#800000",
	"#aaffc3",
	"#808000",
	"#ffd8b1",
	"#000075"
}

enum patterns {
	"none",
	"cross",
	"hash",
	"solid"
}

const TrialMapping: FC<props> = ({
	trialId,
	trialName,
	trialDetails,
	treatments,
	navigateToNextStep,
	navigateToPreviousStep
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	const [plots, setPlots] = useState<trialMapData[][]>([]);

	const [loading, setLoading] = useState(true);

	const [colorMap, setColorMap] = useState<{ [key: string]: { color: string; pattern: string } }>({});

	useEffect(() => {
		(async () => {
			if (userAuth.currentClientId) {
				await retrieveTrialPlots(userAuth.currentClientId);
			}
			setLoading(false);
		})();
	}, []);

	const retrieveTrialPlots = async (clientId) => {
		try {
			const accessToken = await getTokenSilently();
			const res = await trialActions.getTrialPlotsForMapping(trialId, clientId, accessToken)(dispatch);
			parsePlots(res.data);
		} catch (err) {
			toast.error("Failed to retrieve trial data.");
			console.error(err);
		}
	};

	const getBackground = (pattern: string, color: string) => {
		switch (pattern) {
			case "cross":
				return `linear-gradient(-45deg, transparent, transparent 25px, ${color} 25px), repeating-linear-gradient(-45deg, black, black 5px, transparent 5px, transparent 10px)`;

			case "hash":
				return `linear-gradient(-45deg, transparent, transparent 25px, ${color} 25px), repeating-linear-gradient(-45deg, black, black 5px, transparent 5px, transparent 10px), repeating-linear-gradient(45deg, black, black 5px, transparent 5px, transparent 10px)`;

			case "solid":
				return `linear-gradient(-45deg, black, black 25px, ${color} 25px)`;

			default:
				return "";
		}
	};

	const parsePlots = (data: trialMapData[]) => {
		// first plot should be the top left corner
		// use this to get ending range/starting col

		let startingRange = 999,
			startingColumn = 999,
			endingRange = 0,
			endingColumn = 0;

		for (let plot of data) {
			startingRange = plot.range < startingRange ? plot.range : startingRange;
			startingColumn = plot.column < startingColumn ? plot.column : startingColumn;
			endingRange = plot.range > endingRange ? plot.range : endingRange;
			endingColumn = plot.column > endingColumn ? plot.column : endingColumn;
		}

		const parsedPlots = Array.from(
			Array(endingRange - startingRange + 1),
			() => new Array(endingColumn - startingColumn + 1)
		);

		let colorIdx = 1,
			controlIdx = 0,
			patternIdx = 0;
		const tempMap = { ...colorMap };

		for (let plot of data) {
			// build color map
			if (!(plot.treatmentId in tempMap)) {
				if (plot.treatmentTypeName === "Control") {
					const color = "#ffffff";
					tempMap[plot.treatmentId] = { color: color, pattern: getBackground(patterns[controlIdx % 4], color) };
					controlIdx++;
				} else {
					const color = colors[colorIdx];
					tempMap[plot.treatmentId] = { color: color, pattern: getBackground(patterns[patternIdx % 4], color) };
					colorIdx++;
					if (colorIdx >= 20) {
						colorIdx = 1;
						patternIdx++;
					}
				}
			}
			plot.color = tempMap[plot.treatmentId].color;
			plot.pattern = tempMap[plot.treatmentId].pattern;

			// range is descending while column is ascending
			parsedPlots[endingRange - plot.range][plot.column - startingColumn] = plot;
		}
		console.log(tempMap, parsedPlots);
		setColorMap(tempMap);
		setPlots(parsedPlots);
	};

	return loading ? (
		<Loader active />
	) : (
		<>
			<div style={{ marginBottom: "1em" }}>
				<div style={{ display: "flex", alignItems: "end", whiteSpace: "nowrap", overflow: "hidden" }}>
					<Button
						style={{ marginRight: 10 }}
						compact
						color="black"
						content="Back"
						onClick={() => {
							navigateToPreviousStep();
						}}
					/>
				</div>
			</div>
			<Grid columns="equal" padded>
				<Grid.Row>
					<Grid.Column>
						<Table celled structured>
							<Table.Header>
								<Table.Row textAlign="center">
									<Table.HeaderCell colSpan={3}>Treatment</Table.HeaderCell>
								</Table.Row>
								<Table.Row textAlign="center">
									<Table.HeaderCell width={1}>#</Table.HeaderCell>
									<Table.HeaderCell>Name</Table.HeaderCell>
									<Table.HeaderCell style={{ width: 50 }}>Color</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{_.map(treatments, (tr) => {
									return (
										<Table.Row>
											<Table.Cell textAlign="center">{tr.trialTreatmentId}</Table.Cell>
											<Table.Cell>
												{tr.treatmentName} {tr.treatmentTypeName === "Control" && "(Control)"}
											</Table.Cell>
											<Table.Cell
												style={{
													backgroundClip: "padding-box",
													backgroundColor: colorMap[tr.treatmentId].color,
													backgroundImage: colorMap[tr.treatmentId].pattern
												}}
											/>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</Grid.Column>
					<Grid.Column stretched>
						<div
							style={{
								border: "1px solid var(--border)",
								backgroundColor: "#ffffffe6",
								borderRadius: "8px",
								overflow: "hidden",
								flexGrow: 1,
								padding: "0"
							}}
						>
							<TransformWrapper limitToBounds={false} minScale={0.1} centerOnInit>
								{({ zoomIn, zoomOut, centerView }) => (
									<React.Fragment>
										<div className="tools" style={{ float: "left", display: "flex" }}>
											<Button icon="plus" onClick={() => zoomIn()} />
											<Button icon="minus" onClick={() => zoomOut()} />
											<Button icon="refresh" onClick={() => centerView(1)} />
										</div>
										<TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
											<TrialMap plots={plots} />
										</TransformComponent>
									</React.Fragment>
								)}
							</TransformWrapper>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

TrialMapping.propTypes = {
	trialId: PropTypes.string.isRequired,
	trialName: PropTypes.string.isRequired,
	treatments: PropTypes.array.isRequired,
	navigateToNextStep: PropTypes.func.isRequired,
	navigateToPreviousStep: PropTypes.func.isRequired
};

export default TrialMapping;
