import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { Button, Icon, Loader, Segment, Step } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import FarmGeoPositioning from "../FarmGeoPositioning";
import NewEditFarm from "../NewEditFarm";
import "../styles.css";
import ConfrimNavModal from "./ConfirmNavModal";
import DeleteModal from "./DeleteModal";
import * as farmActions from "../../../../redux/actions/farmActions.ts";
import { toast } from "react-toastify";
import FarmSummary from "../FarmSummary";
import Tooltip from "rc-tooltip";

const FarmWizard = ({ activeStep = "info", mode = "new" }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();

	const [farmId, setFarmId] = useState(null);
	const [farmData, setFarmData] = useState({
		clientId: "",
		name: "",
		abbreviation: "",
		address1: "",
		address2: "",
		city: "",
		stateId: "",
		zipCode: "",
		county: "",
		lat: "",
		long: "",
		cooperatorCompanyId: "",
		landOwner: "",
		isActive: true,
		notes: "",
		canDelete: true
	});

	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openConfirmNavModal, setOpenConfirmNavModal] = useState(false);

	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(mode !== "new");
	const [saving, setSaving] = useState(false);

	const [saveFunction, setSaveFunction] = useState(async () => {});
	const [isDirty, setIsDirty] = useState(false);
	const [target, setTarget] = useState("");

	const firstStep = "info";
	const lastStep = "summary";
	const steps = ["info", "geo", "summary"];

	useEffect(() => {
		if (mode === "edit") {
			setFarmId(moduleNavigation?.farmId);
		}
		if (!moduleNavigation?.farmId) {
			setLoading(false);
		}
	}, [moduleNavigation]);

	useEffect(() => {
		if (farmId) {
			getFarmInfo();
		}
	}, [farmId]);

	useEffect(() => {
		if (openConfirmNavModal && !isDirty && target !== "") {
			history.push(target);
		}
	}, [isDirty, openConfirmNavModal, target]);

	/**
	 * Navigates to passed page
	 * @param {"info" | "geo" | "summary"} page
	 */
	const navigateToStep = useCallback(
		(page, id) => {
			// if (isDirty) {
			// 	setTarget({ type: "step", value: page });
			// 	setOpenConfirmNavModal(true);
			// 	return;
			// }
			switch (page) {
				case "info":
					history.push(moduleNavigation.createFarmLink(true, id, "edit"));
					break;
				case "geo":
					history.push(moduleNavigation.createFarmLink(true, id, "geo"));
					break;
				case "summary":
					history.push(moduleNavigation.createFarmLink(true, id, "summary"));
					break;
				default:
					break;
			}
		},
		[isDirty]
	);

	const navigateToNextStep = useCallback(
		(id) => {
			const index = steps.indexOf(activeStep);
			return navigateToStep(steps[index + 1], id);
		},
		[activeStep]
	);

	const navigateToPreviousPage = useCallback(
		(id) => {
			const index = steps.indexOf(activeStep);
			return navigateToStep(steps[index - 1], id);
		},
		[activeStep]
	);

	const handleSave = useCallback(
		async (cont = false) => {
			setSaving(true);
			try {
				const id = await saveFunction();
				if (cont) navigateToNextStep(id);
				else if (mode === "new") navigateToStep("info", id);
			} catch (err) {
				toast.error(err);
				console.error(err);
			} finally {
				setSaving(false);
			}
		},
		[saveFunction]
	);

	const handleNavigateOut = useCallback(
		async (action) => {
			switch (action) {
				case "saveCont":
					await handleSave(false);
					setIsDirty(false);
					break;
				case "save":
					await handleSave(false);
					setTarget("");
					setOpenConfirmNavModal(false);
					setIsDirty(false);
					break;
				case "discard":
					setIsDirty(false);
					break;
				default:
					setTarget("");
					setOpenConfirmNavModal(false);
			}
		},
		[handleSave]
	);

	const handleDelete = useCallback(async () => {
		setDeleting(true);
		const accessToken = await getTokenSilently();
		await dispatch(farmActions.deleteFarm(accessToken, userAuth.currentClientId, farmId))
			.then(() => {
				toast.success("Farm deleted successfully.");
				history.push(moduleNavigation.createFarmLink(false, null, "search"));
			})
			.catch((err) => {
				toast.error("Unable to delete farm, please try again.");
				console.error(err);
			});

		setDeleting(false);
	}, [farmId, userAuth.currentClientId]);

	const getFarmInfo = useCallback(async () => {
		setLoading(true);
		try {
			const accessToken = await getTokenSilently();
			const res = await dispatch(farmActions.getFarmInfo(accessToken, userAuth.currentClientId, farmId));
			setFarmDataMember(res.data);
		} catch (err) {
			console.warn(err);
			toast.error("Unable to get farm info.");
		}
		setLoading(false);
	}, [farmId, userAuth.currentClientId]);

	const setFarmDataMember = useCallback((data) => {
		setFarmData((prev) => ({ ...prev, ...data }));
	});

	return (
		<>
			<Segment basic className="container">
				<Step.Group style={{ width: "100%" }}>
					<Step link active={activeStep === "info"} onClick={() => navigateToStep("info")}>
						<Icon style={{ width: "1em" }} className="farm" />
						<Step.Content>
							<Step.Title>Farm Information</Step.Title>
							<Step.Description>Capture farm meta-data</Step.Description>
						</Step.Content>
					</Step>
					<Step link active={activeStep === "geo"} disabled={farmId === null} onClick={() => navigateToStep("geo")}>
						<Icon name="globe" />
						<Step.Content>
							<Step.Title>Geo-Positioning</Step.Title>
							<Step.Description>Define farm boundaries</Step.Description>
						</Step.Content>
					</Step>
					<Step
						link
						active={activeStep === "summary"}
						disabled={farmId === null}
						onClick={() => navigateToStep("summary")}
					>
						<Icon name="map" />
						<Step.Content>
							<Step.Title>Farm Summary</Step.Title>
							<Step.Description>Verify farm set-up</Step.Description>
						</Step.Content>
					</Step>
				</Step.Group>
				<div className="buttonGroup">
					{activeStep !== firstStep && (
						<Button
							compact
							color="black"
							disabled={deleting || saving || loading}
							content="Back"
							onClick={() => navigateToPreviousPage()}
						/>
					)}
					<div style={{ flexGrow: 2 }} />
					{!farmData.canDelete ? (
						<Tooltip placement="bottom" overlay={<span>Farm is in use</span>}>
							<div>
								<Button compact negative content="Delete" disabled />
							</div>
						</Tooltip>
					) : (
						<Button
							compact
							negative
							content="Delete"
							disabled={farmId === null || deleting || saving || loading || !farmData.canDelete}
							loading={deleting}
							onClick={() => setOpenDeleteModal(true)}
						/>
					)}
					{activeStep !== lastStep ? (
						<>
							<Button
								compact
								primary
								loading={saving}
								disabled={deleting || saving || loading}
								content="Save and Continue"
								onClick={() => {
									handleSave(true);
								}}
							/>

							<Button
								compact
								color="blue"
								loading={saving}
								disabled={deleting || saving || loading}
								content="Save"
								onClick={() => {
									handleSave();
								}}
							/>
						</>
					) : (
						<>
							<Button
								compact
								primary
								loading={saving}
								disabled={deleting || saving || loading}
								content="Finalize and Exit"
								onClick={() => {
									history.push(moduleNavigation.createFarmLink(true, null, "viewer"));
								}}
							/>
						</>
					)}
				</div>
				<Segment basic className="container" style={{ padding: 0 }}>
					{loading && (
						<div className="loaderOverlay">
							<Loader active />
						</div>
					)}
					{(() => {
						switch (activeStep) {
							default:
							case "info":
								return (
									<NewEditFarm
										setSaveFunction={(f) =>
											setSaveFunction(() => {
												return f;
											})
										}
										farmId={farmId}
										clientId={userAuth.currentClientId}
										filledForm={farmData}
										setFilledFormMember={setFarmDataMember}
										userId={userAuth.user.userInfo.basic.userId}
										getTokenSilently={getTokenSilently}
										setIsDirty={setIsDirty}
										mode={mode}
									/>
								);
							case "geo":
								return (
									<FarmGeoPositioning
										clientId={userAuth.currentClientId}
										userId={userAuth.user.userInfo.basic.userId}
										farmId={farmId}
										farmData={farmData}
										getTokenSilently={getTokenSilently}
										setSaveFunction={(f) =>
											setSaveFunction(() => {
												return f;
											})
										}
										setIsDirty={setIsDirty}
									/>
								);
							case "summary":
								return (
									<FarmSummary
										farmId={farmId}
										clientId={userAuth.currentClientId}
										getTokenSilently={getTokenSilently}
									/>
								);
						}
					})()}
				</Segment>
			</Segment>
			<DeleteModal
				open={openDeleteModal}
				setOpen={(isOpen) => setOpenDeleteModal(isOpen)}
				onDelete={handleDelete}
				deleting={deleting}
			/>
			<Prompt
				when={isDirty}
				message={(location) => {
					setTarget(location.pathname);
					setOpenConfirmNavModal(true);
					return false;
				}}
			/>
			<ConfrimNavModal
				open={openConfirmNavModal}
				setOpen={setOpenConfirmNavModal}
				onSave={() => {
					handleNavigateOut("saveCont");
				}}
				onDiscard={() => {
					handleNavigateOut("discard");
				}}
			/>
		</>
	);
};

export default FarmWizard;

FarmWizard.propTypes = {
	activeStep: PropTypes.string,
	mode: PropTypes.string
};
