import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Form, Icon, Modal } from "semantic-ui-react";

interface Company {
	userId: string;
	name: string;
	companyCode: string;
	clientId: string;
	companyTypeIds: string;
	enabled: boolean;
}

interface CooperatorModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSaveCompany: () => Promise<void>;
	onGenerateCompanyCode: () => Promise<void>;
	newCompany?: Company;
	setNewCompany: React.Dispatch<React.SetStateAction<Company>>;
	saving: boolean;
	generating: boolean;
}

const CooperatorModal = ({
	open,
	setOpen,
	onSaveCompany,
	onGenerateCompanyCode,
	newCompany,
	setNewCompany,
	saving,
	generating
}: CooperatorModalProps) => {
	const [deleting] = useState(false);

	return (
		<Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
			<Modal.Header>
				New Cooperator Company
				<Icon name="close" link onClick={() => setOpen(false)} />
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Group widths={"equal"}>
						<Form.Input
							id="form-company-name"
							label="Company Name"
							value={newCompany?.name ?? ""}
							required
							maxLength="50"
							onChange={(event) => setNewCompany((prev) => ({ ...prev, name: event.target.value }))}
						/>

						<Form.Input
							id="form-company-code"
							label="Company Code"
							value={newCompany?.companyCode ?? ""}
							required
							maxLength="7"
							onChange={(event) =>
								setNewCompany((prev) => ({ ...prev, companyCode: event.target.value.toUpperCase() }))
							}
							action={
								<Button
									width="5"
									color="blue"
									compact
									content="Generate Company Code"
									onClick={onGenerateCompanyCode}
									loading={generating}
									disabled={generating}
								/>
							}
						/>
					</Form.Group>

					<Divider />

					<Form.Group style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginTop: 15, marginBottom: 0 }}>
						<Form.Button
							negative
							compact
							content="Reset"
							loading={deleting}
							disabled={saving || deleting}
							onClick={(e) => {
								e.preventDefault();
								console.log("reset");
							}}
						/>
						<Form.Button
							style={{ margin: 0 }}
							positive
							compact
							content="Save"
							loading={saving}
							disabled={saving || deleting}
							onClick={() => {
								onSaveCompany();
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Content>
		</Modal>
	);
};

CooperatorModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};

export default CooperatorModal;
