import ClientManagement from "./Content/Settings/ClientManagement";
import UserManagement from "./Content/Settings/UserManagement";
import LicenseManagement from "./Content/Settings/LicenseManagement";
import OrthoImageTypeManagement from "./Content/Settings/OrthoImageTypeManagement";
import SensorManagement from "./Content/Settings/SensorManagement";
import UavManagement from "./Content/Settings/UavManagement";
import CropManagement from "./Content/Settings/CropManagement";
import GrowingSeasonManagement from "./Content/Settings/GrowingSeasonManagement";
import WeatherDataManagement from "./Content/Settings/WeatherDataManagement";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const settingsRoutesAp = [
	{
		id: "settings-client-management",
		name: "client-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Client Management",
		pageTitle: "Client Management Settings",
		icon: "briefcase",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings`, `/ap-settings/client-management`],
		exact: true,
		component: ClientManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-user-management",
		name: "user-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "User Management",
		pageTitle: "User Management Settings",
		icon: "user group",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/user-management`],
		exact: true,
		component: UserManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-license-management",
		name: "license-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "License Management",
		pageTitle: "License Management Settings",
		icon: "license",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/license-management`],
		exact: true,
		component: LicenseManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-uav-management",
		name: "uav-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "UAV Management",
		pageTitle: "UAV Management Settings",
		icon: "gamepad",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/uav-management`],
		exact: true,
		component: UavManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "settings-sensor-management",
		name: "sensor-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Sensor Management",
		pageTitle: "Sensor Management Settings",
		icon: "eye",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/sensor-management`],
		exact: true,
		component: SensorManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "ortho-image-type-management",
		name: "ortho-image-type-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Ortho Image Type Management",
		pageTitle: "Ortho Image Type Management Settings",
		icon: "image edit",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/ortho-image-type-management`],
		exact: true,
		component: OrthoImageTypeManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "crop-management",
		name: "crop-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Crop Management",
		pageTitle: "Crop Management Settings",
		icon: "crop",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/crop-management`],
		exact: true,
		component: CropManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		id: "growing-season-management",
		name: "growing-season-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Growing Season Management",
		pageTitle: "Growing Season Management Settings",
		icon: "sun",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/growing-season-management`],
		exact: true,
		component: GrowingSeasonManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	},
	{
		//Weather Data Settings. set Enable to true
		id: "weather-data-management",
		name: "weather-data-management",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Weather Data Management",
		pageTitle: "Weather Data Management Settings",
		icon: "sun haze",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/ap-settings/weather-data-management`],
		exact: true,
		component: WeatherDataManagement,
		module: "ap-settings",
		applicationAreas: [ApplicationArea.ApSettings],
		requiresContext: false,
		showModuleIdInBreadcrumb: false,
		settings: true
	}
];

export default {
	settingsRoutesAp
};
