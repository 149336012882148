import _ from "lodash";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "semantic-ui-react";
import "./panel.css";
import { FilterOption } from "./types";

interface DropDownProps {
	label?: string;
	placeholder?: string;
	options: FilterOption[];
	setFilters: (filter: (string | null)[], applied: boolean) => void;
	style?: React.CSSProperties;
	size?: "small" | "default";
}

const SearchPanelDropdown = <T,>({
	label,
	placeholder = "Filters",
	options,
	setFilters,
	style,
	size
}: DropDownProps) => {
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState("");
	const [optionsToShow, setOptionsToShow] = useState(options);
	const [checkedFilters, setCheckedFilters] = useState<{
		"checked-all": boolean;
		[key: string]: boolean;
	}>({
		"checked-all": true
	});

	const checkedCount = useMemo(() => {
		if (checkedFilters["checked-all"]) {
			return -1; // negative one to signal that none should be shown
		}

		return _.countBy(checkedFilters).true ?? 0;
	}, [checkedFilters]);

	const allRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const filteredValues = _.reduce(
			options,
			(acc, red) => {
				if (red.key !== "checked-all" && checkedFilters[red.key]) {
					acc.push(red.value);
				}
				return acc;
			},
			[] as (string | null)[]
		);

		const applied = !checkedFilters["checked-all"];

		setFilters(filteredValues, applied);
	}, [checkedFilters]);

	useEffect(() => {
		setOptionsToShow(options);
		setQuery("");

		if (allRef.current) {
			allRef.current.indeterminate = false;
		}

		setCheckedFilters({
			"checked-all": true,
			..._.reduce(options, (acc, red) => ({ ...acc, [red.key]: true }), {})
		});
	}, [options]);

	useEffect(() => {
		if (open) {
			window.addEventListener("click", closeDropdown);
		} else {
			window.removeEventListener("click", closeDropdown);
			setQuery("");
		}

		return () => {
			window.removeEventListener("click", closeDropdown);
		};
	}, [open]);

	useEffect(() => {
		searchOpts(query);
	}, [query]);

	const searchOpts = useCallback(
		(query: string) => {
			const re = RegExp(_.escapeRegExp(query), "i");
			setOptionsToShow(_.filter(options, (opt) => re.test(opt.name)));
		},
		[options]
	);

	const closeDropdown = useCallback(() => {
		setOpen(false);
	}, []);

	const handleCheck = useCallback(
		(key: keyof typeof checkedFilters, checked: boolean) => {
			if (key === "checked-all") {
				// reducer used to set everything to the checked value
				setCheckedFilters({
					"checked-all": checked,
					...options.reduce((acc, red) => ({ ...acc, [red.key]: checked }), {})
				});
			} else {
				// reverse behavior when a user is searching for a column
				if (checkedFilters["checked-all"] && query.length > 0) {
					if (allRef.current) {
						allRef.current.indeterminate = true;
					}

					setCheckedFilters({
						"checked-all": false,
						...options.reduce((acc, red) => ({ ...acc, [red.key]: false }), {}),
						[key]: true
					});
				} else {
					// check to see if all are checked
					// to do this we reset the "checked-all" value to true so it doesn't count in the reducer
					// don't need this to mutate/dispatch a setState yet so we make a clone
					const clonedChecked = _.cloneDeep(checkedFilters);
					clonedChecked["checked-all"] = true;
					clonedChecked[key] = checked;
					// check if all are true
					const allChecked = _.every(clonedChecked);
					clonedChecked["checked-all"] = allChecked;
					// check if select all should be indeterminate
					if (allRef.current) {
						if (!allChecked) {
							// case where there is at least one unchecked
							// check if there is at least one true
							if (_.some(clonedChecked)) {
								// case where at least one is checked
								allRef.current.indeterminate = true;
							} else {
								// case where none are selected
								allRef.current.indeterminate = false;
							}
						} else {
							// case where all are checked
							allRef.current.indeterminate = false;
						}
					}

					setCheckedFilters(clonedChecked);
				}
			}
		},
		[options, checkedFilters, allRef, query]
	);

	return (
		<div className="dropdownWrapper">
			{label && <label>{label}</label>}
			<div
				className={`panelInput${open ? " open" : ""}${size === "small" ? " small" : ""}`}
				onClick={() => setOpen((prev) => !prev)}
				style={style}
			>
				{!open && (
					<span title={placeholder}>
						{placeholder}
						{checkedCount >= 0 ? ` (${checkedCount})` : ""}
					</span>
				)}
				{open && (
					<input
						title={placeholder}
						placeholder={size === "small" ? "Search..." : "Search Filters..."}
						className="dropdownInput"
						value={query}
						onChange={({ target }) => {
							setQuery(target.value);
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
						autoFocus
					/>
				)}
			</div>

			<div style={{ position: "relative" }}>
				<div
					className={`dropdownMenu${open ? " open" : ""}`}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<label key={"checked-all"}>
						<div className="dropdownItem">
							<input
								ref={allRef}
								type="checkbox"
								checked={checkedFilters["checked-all"]}
								onChange={({ target }) => {
									handleCheck("checked-all", target.checked);
								}}
							/>
							<span>Show All</span>
						</div>
					</label>
					{_.map(optionsToShow, (o) => (
						<label key={o.key}>
							<div className="dropdownItem" title={o.name}>
								<input
									type="checkbox"
									checked={checkedFilters[o.key] ?? false}
									onChange={({ target }) => {
										handleCheck(o.key, target.checked);
									}}
								/>
								{/* <div className="checkbox" /> */}
								<span>{o.name ?? "Not Set"}</span>
							</div>
						</label>
					))}
				</div>
			</div>
		</div>
	);
};

interface SearchBarProps {
	// label?: string;
	placeholder?: string;
	searchTerm: string;
	setSearchTerm: (query: string) => void;
	style?: React.CSSProperties;
	size?: "small" | "default";
}

const SearchPanelBar = ({ size, style, placeholder, searchTerm, setSearchTerm }: SearchBarProps) => {
	return (
		<div className={`panelInput${size === "small" ? " small" : ""}`} style={{ marginRight: "0.25em", ...style }}>
			<input
				title={placeholder}
				placeholder={placeholder}
				className="dropdownInput"
				value={searchTerm}
				onChange={({ target }) => {
					setSearchTerm(target.value);
				}}
			/>
		</div>
	);
};

interface PanelProps {
	onReset?: () => void;
}

const SearchPanel = ({ onReset, children }: PropsWithChildren<PanelProps>) => {
	return (
		<div className="panelBackground">
			{children}
			{onReset && (
				<Button
					style={{ margin: 0 }}
					negative
					onClick={() => {
						onReset();
					}}
				>
					Reset
				</Button>
			)}
		</div>
	);
};

// SearchPanel.Title = SearchPanelTitle;
SearchPanel.Dropdown = SearchPanelDropdown;
SearchPanel.Bar = SearchPanelBar;

export default SearchPanel;
