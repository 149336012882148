// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#field-heatmap-grid {\n\tmargin: unset;\n}\n\n.ui.grid > .row.transformContainer {\n\tborder: 1px solid var(--border);\n\tbackground-color: #ffffffe6;\n\tborder-radius: 8px;\n\theight: 0;\n\toverflow: hidden;\n\tflex-grow: 1;\n\tpadding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Fields/FieldHeatmap/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,+BAA+B;CAC/B,2BAA2B;CAC3B,kBAAkB;CAClB,SAAS;CACT,gBAAgB;CAChB,YAAY;CACZ,UAAU;AACX","sourcesContent":["#field-heatmap-grid {\n\tmargin: unset;\n}\n\n.ui.grid > .row.transformContainer {\n\tborder: 1px solid var(--border);\n\tbackground-color: #ffffffe6;\n\tborder-radius: 8px;\n\theight: 0;\n\toverflow: hidden;\n\tflex-grow: 1;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-heatmap-grid": "field-heatmap-grid",
	"ui": "ui",
	"grid": "grid",
	"row": "row",
	"transformContainer": "transformContainer"
};
export default ___CSS_LOADER_EXPORT___;
