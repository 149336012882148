import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef } from "react";
import { Layer, Map, Source } from "react-map-gl";
import * as styles from "./PreviewStyles";
import { bbox } from "@turf/turf";
import _ from "lodash";

const ParcelPreviewWrapper = ({ parcelList }) => {
	const mapRef = useRef();

	useEffect(() => {
		if (mapRef?.current && parcelList.features.length) {
			const [minLng, minLat, maxLng, maxLat] = bbox(_.find(parcelList.features, (f) => f.properties.isBounds));
			mapRef.current.fitBounds(
				[
					[minLng, minLat],
					[maxLng, maxLat]
				],
				{ padding: 40, duration: 1500 }
			);
		}
	}, [parcelList, mapRef]);

	const parcelFilter = useMemo(() => ["!has", "isBounds"], []);
	const boundsFitler = useMemo(() => ["has", "isBounds"], []);

	return (
		<Map
			ref={mapRef}
			initialViewState={{
				latitude: 39.8097343,
				longitude: -98.5556199,
				zoom: 3
			}}
			mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
			mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
			dragPan={false}
			dragRotate={false}
			doubleClickZoom={false}
			scrollZoom={false}
			keyboard={false}
			touchZoomRotate={false}
			interactive={false}
		>
			<Source type="geojson" data={parcelList}>
				<Layer {...styles.parcelLayer} filter={parcelFilter} />
				<Layer {...styles.outlineParcelLayer} filter={parcelFilter} />
				<Layer {...styles.boundsLayer} filter={boundsFitler} />
			</Source>
		</Map>
	);
};

ParcelPreviewWrapper.propTypes = {
	parcelList: PropTypes.object
};

export default ParcelPreviewWrapper;
