import * as turf from "@turf/turf";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Icon } from "semantic-ui-react";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import * as farmActions from "../../../redux/actions/farmActions.ts";
import * as fieldActions from "../../../redux/actions/fieldActions";
import * as flightActions from "../../../redux/actions/flightActions";
import * as orthoActions from "../../../redux/actions/orthoActions";
import * as orthoViewerActions from "../../../redux/actions/orthoViewerActions";
import { applyLastAppliedShader } from "./orthoControlFunctions";

const OrthoDropdownComponent = React.forwardRef(
	(
		{
			level,
			edit,
			viewer,
			setOrthoIdToPass,
			setOrthoTypes,
			heatmapTimelineRef,
			buildMapImage,
			movePlot,
			handleFieldDropdownChange,
			removeFieldGrid,
			jumpToCoords,
			redrawPlots,
			drawFarmPlots,
			setOrthoTimelineFlights,
			imageFound,
			singleFeatureToggle,
			resetMap,
			setCropToFilterOn,
			fieldDropdownRef,
			trialDropdownRef,
			cropDropdownRef,
			dateRangeDropdownRef
		},
		ref
	) => {
		const { getTokenSilently } = useAuth0();
		const dispatch = useDispatch();
		const moduleNavigation = useModuleNavigation();
		const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
		const reduxFlightId = useSelector((state) => state?.orthoViewer?.currentFlightId);
		const reduxFlightDate = useSelector((state) => state?.orthoViewer?.currentFlightDate);
		const reduxFilteredFlightDates = useSelector((state) => state?.orthoViewer.filters.flightDates);
		const reduxImageLoaded = useSelector((state) => state?.orthoViewer.imageLoaded ?? false);

		const [orthoOptions, setOrthoOptions] = useState(null);
		const [analysisOptions, setAnalysisOptions] = useState([]);
		let sensorChanged = false;
		const [orthoId, setOrthoId] = useState(null);
		const [orthoTypeName, setOrthoTypeName] = useState(null);
		const [thirdDropdownOptions, setThirdDropdownOptions] = useState([]);
		const [thirdDropdownId, setThirdDropdownId] = useState("all");
		const [imageBounds, setImageBounds] = useState([]);
		const [coords, setCoords] = useState(null);
		const [flightImages, setFlightImages] = useState([]);
		const [imageBands, setImageBands] = useState(null);
		const [imageHistogram, setImageHistogram] = useState([]);
		const [allFlightOptions, setAllFlightOptions] = useState([]);
		const [displayedFlightOptions, setDisplayedFlightOptions] = useState([]);
		const [currentFlightDate, setCurrentFlightDate] = useState(null);
		const [cropDatesMap, setCropDatesMap] = useState({});

		const [rawOptions, setRawOptions] = useState(null);
		const [rawOrthoOptions, setRawOrthoOptions] = useState(null);

		const [trialOptions, setTrialOptions] = useState([]);
		const [fieldOptions, setFieldOptions] = useState([]);
		const [cropOptions, setCropOptions] = useState([]);
		const [dateRangeOptions, setDateRangeOptions] = useState([]);

		const [selectedTrialOption, setSelectedTrialOption] = useState(null);
		const [selectedFieldOption, setSelectedFieldOption] = useState(null);
		const [selectedCropOption, setSelectedCropOption] = useState(null);
		const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(null);

		// Expose a few properties from this layer to the parent component.
		useImperativeHandle(ref, () => {
			return {
				setThirdDropdownOptions: updateThirdDropdownOptions,
				currentThirdDropdownOptions: getThirdDropdownOptions,
				setThirdDropdownId: (id) => {
					setThirdDropdownId(id);
				},
				handleDeleteClick: handleDeleteClick,
				getFlightImage: getFlightImage,
				coords: coords,
				imageBounds: imageBounds,
				getSelectedOrthoId: () => {
					return orthoId;
				},
				setSelectedOrthoId: (selectedOrthoId) => {
					setOrthoId(selectedOrthoId);
				},
				getSelectedOrthoTypeName: () => orthoTypeName,
				getImageHistogram: () => {
					return imageHistogram;
				},
				getFlightOptions: displayedFlightOptions,
				setSelectedFieldOption: (opt) => updateFieldSelection(opt),
				setSelectedTrialOption: (opt) => updateTrialSelection(opt),
				setSelectedCropOption: (opt) => updateCropSelection(opt),
				setSelectedDateRangeOption: (opt) => updateDateRangeSelection(opt),
				resetSelectedOptions: () => resetSelectedOptions()
			};
		});

		function updateThirdDropdownOptions(options) {
			setThirdDropdownOptions(options);
		}

		function getThirdDropdownOptions() {
			return thirdDropdownOptions;
		}

		useEffect(() => {
			if (selectedDateRangeOption) {
				handleDateRangeUpdate(selectedDateRangeOption.flightyears);
			}
		}, [selectedDateRangeOption]);

		useEffect(() => {
			if (orthoId && (reduxFlightId || moduleNavigation.flightId)) {
				getFlightImage();
			}
		}, [orthoId, reduxFlightId]);

		useEffect(() => {
			if (orthoId && reduxFlightDate) {
				//need check if ortho exists in flight range and vice versa
				if (verifyOrthoMatchesDate(orthoId, reduxFlightDate)) {
					getFlightImagesForDate();
				}
			}
		}, [orthoId, reduxFlightDate]);

		useEffect(() => {
			if (reduxFlightDate && reduxImageLoaded) {
				if (selectedTrialOption && selectedTrialOption !== "all") {
					const trialExistsOnFlight = _.find(
						trialOptions,
						(t) => t.key === selectedTrialOption && _.map(t.flights, "flightDateAndTime").includes(reduxFlightDate)
					);

					trialExistsOnFlight && singleFeatureToggle("trial", selectedTrialOption);
				} else if (selectedFieldOption && selectedFieldOption !== "all") {
					const fieldExistsOnFlight = _.find(
						fieldOptions,
						(f) => f.key === selectedFieldOption && _.map(f.flights, "flightDateAndTime").includes(reduxFlightDate)
					);

					fieldExistsOnFlight && singleFeatureToggle("field", selectedFieldOption);
				}
			}
		}, [reduxFlightDate, selectedFieldOption, fieldOptions, selectedTrialOption, trialOptions, reduxImageLoaded]);

		useEffect(() => {
			if (orthoId) {
				setOrthoIdToPass(orthoId);
				let displayName = _.find(allFlightOptions, (afo) => afo.orthoImageTypeId === orthoId)?.displayName;
				setOrthoTypeName(displayName);

				if (level !== "flight") {
					updateHeatmapAnalysisId();
				}

				let inverse = displayName?.includes("RGB") ?? false;
				applyLastAppliedShader(0, 1, inverse);
			}
		}, [orthoId]);

		useEffect(() => {
			if (imageBands) {
				let band = _.find(imageBands, (e) => e.colorInterpretation === "Gray" || e.colorInterpretation === "Green");
				if (band?.histogram) {
					setImageHistogram(band.histogram.buckets);
				} else {
					setImageHistogram([]);
				}
			}
		}, [imageBands]);

		useEffect(() => {
			if (rawOptions) {
				setupTrialOptions(rawOptions.flights, rawOptions.trials);
				setupFieldOptions(rawOptions.flights);
				setupCropOptions(rawOptions.crops);
				//setupDateRangeOptions(rawOptions.flights);
			}
		}, [
			rawOptions,
			selectedDateRangeOption,
			selectedCropOption,
			selectedFieldOption,
			selectedTrialOption,
			reduxFilteredFlightDates
		]);

		useEffect(() => {
			if (level === "farm" && rawOrthoOptions && selectedDateRangeOption) {
				setOrthoOptions(
					rawOrthoOptions.filter((roo) => roo.years.some((yr) => selectedDateRangeOption.flightyears.includes(yr)))
				);
			}
		}, [rawOrthoOptions, selectedDateRangeOption]);

		useEffect(() => {
			if (imageBounds?.length > 0 && (level === "farm" || level === "flight")) {
				resetMap();
			}
		}, [imageBounds]);

		function updateHeatmapAnalysisId() {
			if (heatmapTimelineRef?.current) {
				let analysisOpts = heatmapTimelineRef.current.getAnalysisOptions();

				let matchingOrtho = _.find(orthoOptions, { key: orthoId });
				if (matchingOrtho) {
					//-- Set analysis not to stand counts at first
					let matchingAnalysis = _.find(
						analysisOpts,
						(ao) => ao.text.includes(matchingOrtho.text) && !ao.text.includes("Stand Count")
					);

					if (!matchingAnalysis) {
						matchingAnalysis = _.find(analysisOpts, (ao) => ao.text.includes(matchingOrtho.text));
					}

					if (matchingAnalysis) {
						heatmapTimelineRef.current.setSelectedAnalysisId(matchingAnalysis.key);
					}
				}
			}
		}

		async function getFlightImage() {
			//-- not searching for sensor since flightId-orthoImageTypeId is unique already
			let flightImageId = _.find(flightImages, {
				flightId: reduxFlightId ?? moduleNavigation.flightId,
				orthoImageTypeId: orthoId,
				fieldId: level === "field" ? moduleNavigation.fieldId : null,
				trialId: level === "trial" ? moduleNavigation.trialId : null
			})?.flightImageId;

			if (flightImageId) {
				const accessToken = await getTokenSilently();
				dispatch(
					orthoActions.getViewerFlightImage(
						reduxFlightId ?? moduleNavigation.flightId,
						flightImageId,
						clientId,
						accessToken
					)
				)
					.then((res) => {
						let first = false;
						if (imageBounds.length === 0) {
							jumpToCoords(getCenterCoordinates(res).geometry.coordinates);
							setImageBounds([
								[parseFloat(res.llLong), parseFloat(res.llLat)],
								[parseFloat(res.ulLong), parseFloat(res.ulLat)],
								[parseFloat(res.urLong), parseFloat(res.urLat)],
								[parseFloat(res.lrLong), parseFloat(res.lrLat)],
								[parseFloat(res.llLong), parseFloat(res.llLat)]
							]);

							setCoords(getCenterCoordinates(res).geometry.coordinates);
							first = true;
						}

						if (level === "field") {
							redrawPlots(reduxFlightId, moduleNavigation.fieldId, null, sensorChanged, res, orthoId, first);
						} else if (level === "trial") {
							redrawPlots(reduxFlightId, null, moduleNavigation.trialId, sensorChanged, res, orthoId, first);
						} else {
							buildMapImage(res, orthoId);
						}
						setImageBands(res.imageBands);
					})
					.catch((err) => {
						//-- If the user navigates to a page with this component and navigates away before the image loads, this error would appear
						//--	Changed it from a toast notification to a console error so the user doesn't see it in this case
						imageFound(false);
						console.log("Error loading flight image.");
						console.log(err);
					});
			}
		}

		const getFlightImagesForDate = async () => {
			const accessToken = await getTokenSilently();
			let fieldIds = _.uniq(
				_.map(
					_.filter(flightImages, (fi) => fi.flightDateAndTime === (reduxFlightDate ?? currentFlightDate) && fi.fieldId),
					"fieldId"
				)
			);
			let trialIds = _.uniq(
				_.map(
					_.filter(flightImages, (fi) => fi.flightDateAndTime === (reduxFlightDate ?? currentFlightDate) && fi.trialId),
					"trialId"
				)
			);

			dispatch(
				orthoActions.getFlightImagesForDate(
					moduleNavigation.farmId,
					orthoId,
					reduxFlightDate ?? currentFlightDate,
					fieldIds,
					trialIds,
					clientId,
					accessToken
				)
			)
				.then((res) => {
					let first = false;
					if (imageBounds.length === 0) {
						let features = [];

						//-- Default to farm bounds if exist
						if (res.farmViewerInfo?.ll.lat) {
							features.push(
								turf.polygon([
									[
										[parseFloat(res.farmViewerInfo.ll.long), parseFloat(res.farmViewerInfo.ll.lat)],
										[parseFloat(res.farmViewerInfo.ul.long), parseFloat(res.farmViewerInfo.ul.lat)],
										[parseFloat(res.farmViewerInfo.ur.long), parseFloat(res.farmViewerInfo.ur.lat)],
										[parseFloat(res.farmViewerInfo.lr.long), parseFloat(res.farmViewerInfo.lr.lat)],
										[parseFloat(res.farmViewerInfo.ll.long), parseFloat(res.farmViewerInfo.ll.lat)]
									]
								])
							);
							//-- Otherwise center on all images
						} else {
							_.map(res.flightImages, (fi) => {
								features.push(
									turf.polygon([
										[
											[parseFloat(fi.llLong), parseFloat(fi.llLat)],
											[parseFloat(fi.ulLong), parseFloat(fi.ulLat)],
											[parseFloat(fi.urLong), parseFloat(fi.urLat)],
											[parseFloat(fi.lrLong), parseFloat(fi.lrLat)],
											[parseFloat(fi.llLong), parseFloat(fi.llLat)]
										]
									])
								);
							});
						}

						let bbox = turf.bbox(turf.featureCollection(features));
						let bboxPolygon = turf.bboxPolygon(bbox);

						//-- Mutate coords so bbox is north facing
						let mutate = [
							[bboxPolygon.geometry.coordinates[0][4][0], bboxPolygon.geometry.coordinates[0][4][1]],
							[bboxPolygon.geometry.coordinates[0][3][0], bboxPolygon.geometry.coordinates[0][3][1]],
							[bboxPolygon.geometry.coordinates[0][2][0], bboxPolygon.geometry.coordinates[0][2][1]],
							[bboxPolygon.geometry.coordinates[0][1][0], bboxPolygon.geometry.coordinates[0][1][1]],
							[bboxPolygon.geometry.coordinates[0][0][0], bboxPolygon.geometry.coordinates[0][0][1]]
						];

						jumpToCoords(turf.centroid(bboxPolygon).geometry.coordinates);
						setImageBounds(mutate);
						setCoords(turf.centroid(bboxPolygon).geometry.coordinates);
						first = true;
					}

					setImageBands(res.imageBands);

					drawFarmPlots(
						reduxFlightDate ?? currentFlightDate,
						res.flightImages,
						analysisOptions,
						moduleNavigation.farmId,
						orthoId,
						first
					);
					setOrthoTimelineFlights(displayedFlightOptions);
				})
				.catch((err) => {
					imageFound(false);
					console.log("Error loading flight image.");
					console.log(err);
				});
		};

		// 1. Setup Event Listeners and get flights.
		const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
		useEffect(() => {
			let map = document.querySelector(".mapboxgl-canvas");
			map.addEventListener(
				"keydown",
				(e) => {
					if (arrowKeys.includes(e.key) || e.key === "Shift") {
						e.preventDefault();
						e.stopPropagation();
						movePlot(e.key, e);
					}
				},
				false
			);

			if (level === "field" || level === "trial") {
				getFlights();
			} else if (level === "flight") {
				getOrthosForFlight(moduleNavigation.flightId);
			} else if (level === "farm") {
				getFlightsForFarm();
			}
		}, []);

		async function getFlights() {
			const accessToken = await getTokenSilently();
			dispatch(
				fieldActions.getFlightsWithFieldOrTrial(
					clientId,
					moduleNavigation.fieldId,
					moduleNavigation.trialId,
					accessToken
				)
			).then((res) => {
				if (res.length === 0) {
					imageFound(false);
				}

				let uniqueFlights = res;

				if (level === "trial") {
					uniqueFlights = uniqueFlights.filter((uf) => uf.trialId === moduleNavigation.trialId);
				}

				uniqueFlights = _.uniqBy(uniqueFlights, "flightId");
				uniqueFlights = _.orderBy(uniqueFlights, (f) => new Date(f.flightDateAndTime).valueOf(), "desc");

				setAllFlightOptions(uniqueFlights);
				setDisplayedFlightOptions(uniqueFlights);

				if (!reduxFlightId) {
					let multispecFlights = _.orderBy(
						_.filter(uniqueFlights, (uf) => uf.multispectral),
						(f) => [f.order || Infinity],
						["asc"]
					);
					let rgbFlights = _.orderBy(
						_.filter(uniqueFlights, (uf) => uf.rgb),
						(f) => [f.order || Infinity],
						["asc"]
					);

					//-- Search for multispec flights first, then rgb, then any
					//-- This will match our ordering of ndvi > rgb
					dispatch(
						orthoViewerActions.setCurrentFlightId(
							moduleNavigation.flightId ??
								multispecFlights[0]?.flightId ??
								rgbFlights[0]?.flightId ??
								uniqueFlights[0]?.flightId
						)
					);
					getOrthosForFlight(
						moduleNavigation.flightId ??
							multispecFlights[0]?.flightId ??
							rgbFlights[0]?.flightId ??
							uniqueFlights[0]?.flightId
					);
				} else {
					getOrthosForFlight();
				}
			});
		}

		async function getOrthosForFlight(flightId = null) {
			const accessToken = await getTokenSilently();
			dispatch(
				flightActions.getViewerOrthoOptions(
					level === "flight" ? moduleNavigation.flightId : "",
					level === "field" ? moduleNavigation.fieldId : "",
					level === "trial" ? moduleNavigation.trialId : "",
					clientId,
					accessToken
				)
			)
				.then((res) => {
					setFlightImages(res.flightImages);
					setOrthoTypes(_.uniqBy(res.flightImages, "orthoImageTypeId"));

					let orthoOpts = _.map(_.uniqBy(res.flightImages, "orthoImageTypeId"), ({ orthoImageTypeId }) => {
						let orthoImageType = _.find(res.flightSensors, { orthoImageTypeId: orthoImageTypeId });
						const opt = {
							key: orthoImageTypeId,
							value: orthoImageTypeId,
							text: orthoImageType?.displayName ?? orthoImageType?.orthoImageType ?? ""
						};
						return opt;
					});

					orthoOpts = _.sortBy(
						orthoOpts,
						[(opt) => _.find(res.flightSensors, { orthoImageTypeId: opt.key }).order || Infinity, "text"],
						["desc"]
					);

					setOrthoOptions(orthoOpts);

					let imagesOnSelectedFlight = _.filter(res.flightImages, (fi) => fi.flightId === (flightId ?? reduxFlightId));
					let ndviIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "NDVI" })?.orthoImageTypeId
					});
					let ndvim3mIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "NDVI_M3M" })?.orthoImageTypeId
					});
					let sndviIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "SNDVI" })?.orthoImageTypeId
					});
					let rgbIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "RGB" })?.orthoImageTypeId
					});
					let rapidIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "Rapid_RGB" })?.orthoImageTypeId
					});

					let imageIndex =
						ndviIndex !== -1
							? ndviIndex
							: ndvim3mIndex !== -1
							? ndvim3mIndex
							: sndviIndex !== -1
							? sndviIndex
							: rgbIndex !== -1
							? rgbIndex
							: rapidIndex !== -1
							? rapidIndex
							: 0;

					setOrthoId(imagesOnSelectedFlight[imageIndex]?.orthoImageTypeId);
				})
				.catch(() => {
					imageFound(false);
				});
		}

		const getFlightsForFarm = async () => {
			const accessToken = await getTokenSilently();

			dispatch(farmActions.getFlightsForFarm(moduleNavigation.farmId, clientId, accessToken))
				.then((res) => {
					if (res.length === 0) {
						imageFound(false);
					}

					setRawOptions(res);
					let flights = res.flights;
					setFlightImages(flights);
					setOrthoTypes(_.uniqBy(flights, "orthoImageTypeId"));
					setAnalysisOptions(res.analysisOptions);

					setupTrialOptions(res.flights, res.trials);
					setupFieldOptions(res.flights);
					setupCropOptions(res.crops);
					setupDateRangeOptions(res.flights);

					if (res.trials) {
						const cropsFields = res.crops.reduce(
							(acc, crop) => ({ ...acc, [crop.cropId]: _.uniq(crop.fieldAndTrialIds.map((ftid) => ftid.fieldId)) }),
							{}
						);

						const groupedFlights = _.groupBy(
							_.uniqBy(res.flights, (f) => f.fieldId + "/" + f.flightDateAndTime),
							"fieldId"
						);

						const cropsDates = {};
						for (const [cropId, fields] of Object.entries(cropsFields)) {
							const fieldDates = _.uniq(fields.map((f) => groupedFlights[f].map((gf) => gf.flightDateAndTime)).flat());

							cropsDates[cropId] = fieldDates;
						}

						setCropDatesMap(cropsDates);
					} else {
						const cropsTrials = res.crops.reduce(
							(acc, crop) => ({ ...acc, [crop.cropId]: _.uniq(crop.fieldAndTrialIds.map((ftid) => ftid.trialId)) }),
							{}
						);

						const groupedFlights = _.groupBy(
							_.uniqBy(res.flights, (f) => f.trialId + "/" + f.flightDateAndTime),
							"trialId"
						);

						const cropsDates = {};
						for (const [cropId, trials] of Object.entries(cropsTrials)) {
							const trialDates = _.uniq(trials.map((f) => groupedFlights[f].map((gf) => gf.flightDateAndTime)).flat());

							cropsDates[cropId] = trialDates;
						}

						setCropDatesMap(cropsDates);
					}

					//-- Create list of orthos types pulled back
					const orthoOpts = _.chain(flights)
						.groupBy("orthoImageTypeId")
						.map((group, orthoImageTypeId) => ({
							key: orthoImageTypeId,
							value: orthoImageTypeId,
							text: group[0]?.displayName ?? group[0]?.orthoImageType ?? "",
							order: group[0].order,
							years: _.uniq(_.map(group, (f) => Number(f.flightDateAndTime.slice(-4))))
						}))
						.sortBy(["order", "text"])
						.value();

					setOrthoOptions(orthoOpts);
					setRawOrthoOptions(orthoOpts);

					//-- Create lists of flights to be used across all ortho types
					let uniqueFlights = _.uniqBy(flights, "flightId");
					uniqueFlights = _.orderBy(flights, (f) => new Date(f.flightDateAndTime).valueOf(), "desc");

					setAllFlightOptions(uniqueFlights);

					//-- Create list of flights on latest year
					let latestYearObj = _.max(uniqueFlights, (f) => new Date(f.flightDateAndTime).getFullYear());
					let latestYear = new Date(latestYearObj.flightDateAndTime).getFullYear();
					setDisplayedFlightOptions(
						_.filter(uniqueFlights, (f) => new Date(f.flightDateAndTime).getFullYear() === latestYear)
					);
					dispatch(orthoViewerActions.setCurrentFlightYear([latestYear]));

					//-- Set selected flight date
					if (!reduxFlightDate) {
						let filteredUniqueFlights = _.filter(
							uniqueFlights,
							(f) => new Date(f.flightDateAndTime).getFullYear() === latestYear
						);
						let multispecFlights = _.orderBy(
							_.filter(filteredUniqueFlights, (uf) => uf.multispectral),
							(f) => f.order || Infinity,
							["asc"]
						);
						let rgbFlights = _.orderBy(
							_.filter(filteredUniqueFlights, (uf) => uf.rgb),
							(f) => f.order || Infinity,
							["asc"]
						);

						//-- Search for multispec flights first, then rgb, then any
						//-- This will match our ordering of ndvi > rgb
						dispatch(
							orthoViewerActions.setCurrentFlightDate(
								multispecFlights[0]?.flightDateAndTime ??
									rgbFlights[0]?.flightDateAndTime ??
									filteredUniqueFlights[0]?.flightDateAndTime
							)
						);
						setCurrentFlightDate(
							multispecFlights[0]?.flightDateAndTime ??
								rgbFlights[0]?.flightDateAndTime ??
								filteredUniqueFlights[0]?.flightDateAndTime
						);
						dispatch(orthoViewerActions.setCurrentFlightId(null));
					}

					//-- Determine which ortho type to default to
					let filteredFlights = _.filter(flights, (f) => new Date(f.flightDateAndTime).getFullYear() === latestYear);
					let ndviIndex = _.findIndex(filteredFlights, { orthoImageType: "NDVI" });
					let ndvim3mIndex = _.findIndex(filteredFlights, { orthoImageType: "NDVI_M3M" });
					let sndviIndex = _.findIndex(filteredFlights, { orthoImageType: "SNDVI" });
					let rgbIndex = _.findIndex(filteredFlights, { orthoImageType: "RGB" });
					let rapidIndex = _.findIndex(filteredFlights, { orthoImageType: "Rapid_RGB" });

					let imageIndex =
						ndviIndex !== -1
							? ndviIndex
							: ndvim3mIndex !== -1
							? ndvim3mIndex
							: sndviIndex !== -1
							? sndviIndex
							: rgbIndex !== -1
							? rgbIndex
							: rapidIndex !== -1
							? rapidIndex
							: 0;

					setOrthoId(filteredFlights[imageIndex]?.orthoImageTypeId);
				})
				.catch((error) => {
					console.error(error);
				});
		};

		function setupTrialOptions(rawFlights, rawTrials) {
			if (rawFlights || rawTrials) {
				let opts = [{ key: "all", value: "all", text: "All Trials", flights: [], disabled: false }];

				if (rawTrials?.length > 0) {
					_.map(
						_.orderBy(
							_.filter(rawTrials, (ts) =>
								_.some(
									ts,
									(t) =>
										(!selectedFieldOption || selectedFieldOption === "all" || selectedFieldOption === t.fieldId) &&
										(!selectedCropOption ||
											selectedCropOption === "all" ||
											cropOptions.find((co) => co.key === selectedCropOption).trials.includes(t.trialId)) &&
										(!selectedDateRangeOption ||
											selectedDateRangeOption.flightyears.includes(new Date(t.flightDateAndTime).getFullYear()))
								)
							),
							(t) => t[0]?.trialName
						),
						(t) => {
							let opt = {
								key: t[0]?.trialId,
								value: t[0]?.trialId,
								text: t[0]?.trialName,
								flights: t,
								disabled: false
							};

							opts = [...opts, opt];
						}
					);
				} else if (rawFlights?.length > 0) {
					let groupedFlights = _.groupBy(
						_.orderBy(rawFlights, (f) => f[0]?.trialName),
						(f) => f.trialId
					);

					_.forEach(groupedFlights, (f) => {
						if (
							(!selectedFieldOption || selectedFieldOption === "all" || selectedFieldOption === f[0]?.fieldId) &&
							(!selectedCropOption ||
								selectedCropOption === "all" ||
								cropOptions.find((co) => co.key === selectedCropOption).trials.includes(f[0]?.trialId)) &&
							(!selectedDateRangeOption ||
								_.some(f, (flight) =>
									selectedDateRangeOption.flightyears.includes(new Date(flight.flightDateAndTime).getFullYear())
								))
						) {
							let opt = {
								key: f[0]?.trialId,
								value: f[0]?.trialId,
								text: f[0]?.trialName,
								flights: f,
								disabled: false
							};

							opts = [...opts, opt];
						}
					});
				}

				setTrialOptions(opts);
				setSelectedTrialOption(selectedTrialOption ?? "all");

				trialDropdownRef?.current?.setOptions(opts);
				trialDropdownRef?.current?.setSelected(selectedTrialOption ?? "all");
			}
		}

		function setupFieldOptions(rawFlights) {
			if (rawFlights) {
				let opts = [{ key: "all", value: "all", text: "All Fields", flights: [], disabled: false }];
				// let divider = { key: "divider", value: "", text: "Fields on other nodes", flights: [], disabled: true };

				let groupedFlights = _.groupBy(
					_.orderBy(
						_.filter(
							rawFlights,
							(f) =>
								(!selectedCropOption ||
									selectedCropOption === "all" ||
									cropOptions.find((co) => co.key === selectedCropOption).fields.includes(f.fieldId)) &&
								(!selectedDateRangeOption ||
									selectedDateRangeOption.flightyears.includes(new Date(f.flightDateAndTime).getFullYear())) &&
								f.fieldName
						),
						(f) => f.fieldName
					),
					(f) => f.fieldId
				);

				const nodeOpts = _.map(groupedFlights, (f) => {
					return { key: f[0]?.fieldId, value: f[0]?.fieldId, text: f[0]?.fieldName, flights: f, disabled: false };
				});
				opts = [...opts, ...nodeOpts];

				setFieldOptions(opts);
				setSelectedFieldOption(selectedFieldOption ?? "all");

				fieldDropdownRef?.current?.setOptions(opts);
				fieldDropdownRef?.current?.setSelected(selectedFieldOption ?? "all");
			}
		}

		function setupCropOptions(rawCrops) {
			if (rawCrops) {
				let opts = [
					{ key: "all", value: "all", text: "All Crops", fields: [], trials: [], years: [], disabled: false }
				];

				_.map(
					_.orderBy(rawCrops, (c) => c.cropName),
					(c) => {
						let cropFields = _.map(
							_.filter(c.fieldAndTrialIds, (f) => f.fieldId),
							"fieldId"
						);
						let cropTrials = _.map(
							_.filter(c.fieldAndTrialIds, (t) => t.trialId),
							"trialId"
						);

						let opt = {
							key: c.cropId,
							value: c.cropId,
							text: c.cropName,
							fields: cropFields,
							trials: cropTrials,
							years: c.years,
							disabled: false
						};

						opts = [...opts, opt];
					}
				);

				const filteredOpts = opts.filter(
					(opt) =>
						opt.key === "all" ||
						((!selectedFieldOption || selectedFieldOption === "all" || opt.fields.includes(selectedFieldOption)) &&
							(!selectedTrialOption || selectedTrialOption === "all" || opt.trials.includes(selectedTrialOption)))
				);

				setCropOptions(filteredOpts);
				setSelectedCropOption(selectedCropOption ?? "all");

				cropDropdownRef?.current?.setOptions(filteredOpts);
				cropDropdownRef?.current?.setSelected(selectedCropOption ?? "all");
			}
		}

		const setupDateRangeOptions = (flights) => {
			if (flights) {
				let opts = []; //[{ key: "all", value: "all", text: "All Flights", flightyears: [] }];

				let dateOpts = [];
				let splitByYear = _.groupBy(flights, (f) => new Date(f.flightDateAndTime).getFullYear());
				_.map(Object.keys(splitByYear).sort().reverse(), (year) => {
					let opt = { key: year, value: year, text: year, flightyears: [parseFloat(year)] };

					dateOpts = [...dateOpts, opt];
					opts = [...opts, opt];
				});

				//-- Commenting out multi year ranges
				// _.map(dateOpts, (d) => {
				// 	_.map(dateOpts, (dd) => {
				// 		if (d.key !== dd.key) {
				// 			let start = Math.min(parseFloat(d.key), parseFloat(dd.key));
				// 			let end = Math.max(parseFloat(d.key), parseFloat(dd.key));
				// 			let range = `${start} - ${end}`;

				// 			let years = [];
				// 			for (let i = start; i <= end; i++) years = [...years, i];

				// 			let keys = _.map(opts, "key");
				// 			if (!keys.includes(range)) {
				// 				let opt = { key: range, value: range, text: range, flightyears: years };

				// 				opts = [...opts, opt];
				// 			}
				// 		}
				// 	});
				// });

				// opts[0].flightyears = _.uniq(_.flatten(_.map(opts, "flightyears")));

				setDateRangeOptions(opts);
				setSelectedDateRangeOption(opts[0]);

				dateRangeDropdownRef?.current?.setOptions(opts);
				dateRangeDropdownRef?.current?.setSelected(opts[0].key);
			}
		};

		const updateFieldSelection = (fieldId) => {
			if (fieldOptions?.length > 0) {
				if (fieldId === "all" && selectedTrialOption === "all") {
					resetMap();
					fieldDropdownRef?.current?.setSelected(fieldId);
					setSelectedFieldOption(fieldId);
					dispatch(orthoViewerActions.updateFilters({ fieldDates: [] }));
				} else if (fieldId) {
					const fieldDates = fieldOptions
						.find((fo) => fo.key === fieldId)
						.flights.reduce((a, f) => (f.orthoImageTypeId === orthoId ? [...a, f.flightDateAndTime] : a), []);

					dispatch(orthoViewerActions.updateFilters({ fieldDates }));

					if (selectedTrialOption !== "all") {
						dispatch(orthoViewerActions.updateFilters({ trialDates: [] }));
						trialDropdownRef?.current?.setSelected("all");
						setSelectedTrialOption("all");
					}
					fieldDropdownRef?.current?.setSelected(fieldId);
					setSelectedFieldOption(fieldId);

					// if (fieldExistsOnFlight) {
					// 	//-- Need to reset trial selection when field is selected

					// 	singleFeatureToggle("field", fieldId);
					// } else {
					// 	// updateFieldSelection(fieldId);
					// 	toast.warning("This field doesn't exist on flight");
					// }
				}
			}
		};

		const updateTrialSelection = (trialId) => {
			if (trialOptions?.length > 0) {
				if (trialId === "all" && selectedFieldOption === "all") {
					resetMap();
					trialDropdownRef?.current?.setSelected(trialId);
					setSelectedTrialOption(trialId);
					dispatch(orthoViewerActions.updateFilters({ trialDates: [] }));
				} else if (trialId) {
					const trialDates = trialOptions
						.find((to) => to.key === trialId)
						.flights.reduce((a, f) => (f.orthoImageTypeId === orthoId ? [...a, f.flightDateAndTime] : a), []);

					dispatch(orthoViewerActions.updateFilters({ trialDates }));

					// //-- Need to reset field selection when trial is selected
					// if (selectedFieldOption !== "all") {
					// 	fieldDropdownRef?.current?.setSelected("all");
					// 	setSelectedFieldOption("all");
					// }

					trialDropdownRef?.current?.setSelected(trialId);
					setSelectedTrialOption(trialId);
					// singleFeatureToggle("trial", trialId);
				}
			}
		};

		const updateCropSelection = (cropId) => {
			if (cropOptions?.length > 0) {
				cropDropdownRef?.current?.setSelected(cropId);

				let cropName = cropId;
				if (cropId !== "all") {
					cropName = _.find(cropOptions, (c) => c.key === cropId)?.text;
					dispatch(orthoViewerActions.updateFilters({ cropDates: cropDatesMap[cropId] }));
				} else {
					dispatch(orthoViewerActions.updateFilters({ cropDates: [] }));
				}

				setSelectedCropOption(cropId);
				setCropToFilterOn(cropName);
			}
		};

		const updateDateRangeSelection = (dateRange) => {
			if (dateRangeOptions?.length > 0) {
				dateRangeDropdownRef?.current?.setSelected(dateRange);

				//-- This will reset ortho timeline nodes
				let matchingDateRange = _.find(dateRangeOptions, (opt) => opt.key === dateRange);
				if (matchingDateRange) {
					dispatch(orthoViewerActions.setCurrentFlightYear(matchingDateRange.flightyears));

					let flightsInYear = _.filter(allFlightOptions, (f) =>
						matchingDateRange.flightyears.includes(new Date(f.flightDateAndTime).getFullYear())
					);
					flightsInYear = _.orderBy(flightsInYear, (f) => new Date(f.flightDateAndTime), "desc");

					let isCurrentFlightInYear = matchingDateRange.flightyears.includes(
						new Date(reduxFlightDate ?? currentFlightDate).getFullYear()
					);
					if (!isCurrentFlightInYear) {
						//-- Find matching flight on year and on ortho
						let matchingOrthoFlight = _.find(flightsInYear, (f) => f.orthoImageTypeId === orthoId);
						if (matchingOrthoFlight) {
							dispatch(orthoViewerActions.setCurrentFlightDate(matchingOrthoFlight.flightDateAndTime));
							setCurrentFlightDate(matchingOrthoFlight.flightDateAndTime);
						} else {
							//-- If no matching ortho types exist on selected years, select new ortho type
							setOrthoId(flightsInYear[0].orthoImageTypeId);
						}
					}

					setDisplayedFlightOptions(flightsInYear);
					setOrthoTimelineFlights(flightsInYear);
					if (selectedFieldOption && selectedFieldOption !== "all") updateFieldSelection("all");
					if (selectedTrialOption && selectedTrialOption !== "all") updateTrialSelection("all");
					if (selectedCropOption && selectedCropOption !== "all") updateCropSelection("all");

					setSelectedDateRangeOption(matchingDateRange);
				}
			}
		};

		const handleDateRangeUpdate = (years) => {
			if (fieldOptions?.length > 0 && trialOptions?.length > 0) {
				//-- Get fields within years
				let filteredFields = _.filter(
					fieldOptions,
					(fo) =>
						fo.key === "all" ||
						_.some(_.map(fo.flights, (f) => years.includes(new Date(f.flightDateAndTime).getFullYear())))
				);

				fieldDropdownRef.current.setOptions(filteredFields);

				//-- Repeat for trials
				let filteredTrials = _.filter(
					trialOptions,
					(to) =>
						to.key === "all" ||
						_.some(_.map(to.flights, (f) => years.includes(new Date(f.flightDateAndTime).getFullYear())))
				);

				trialDropdownRef.current.setOptions(filteredTrials);

				//-- Repeat for crops
				let filteredCrops = _.filter(
					cropOptions,
					(co) => co.key === "all" || _.some(co.years, (y) => years.includes(parseInt(y)))
				);

				cropDropdownRef.current.setOptions(filteredCrops);
			}
		};

		const resetSelectedOptions = () => {
			if (cropDropdownRef && trialDropdownRef && fieldDropdownRef) {
				cropDropdownRef?.current?.setSelected("all");
				trialDropdownRef?.current?.setSelected("all");
				fieldDropdownRef?.current?.setSelected("all");

				setCropToFilterOn("all");
				setSelectedTrialOption("all");
				setSelectedFieldOption("all");
			}
		};

		const verifyOrthoMatchesDate = (ortho, date) => {
			if (ortho && date) {
				return _.find(flightImages, (fi) => fi.orthoImageTypeId === ortho && fi.flightDateAndTime === date);
			}
		};

		useEffect(() => {
			if (orthoId) {
				getThirdDropdown();
			}
		}, [orthoId]);

		async function getThirdDropdown() {
			const accessToken = await getTokenSilently();
			if (level === "flight") {
				if (!edit) {
					dispatch(fieldActions.getUsedFields(moduleNavigation.flightId, orthoId, clientId, accessToken)).then(
						(res) => {
							setThirdDropdownOptions(
								[{ key: "all", value: "all", text: "All" }].concat(
									_.map(res, (record) => {
										const opt = {
											key: record.fieldId,
											value: record.fieldId,
											text: record.name,
											content: (
												<div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
													<span style={{ flexGrow: "1", overflow: "hidden", wordWrap: "break-word" }}>
														{record.name}
													</span>
													<Icon style={{ margin: 0 }} name={record.existsOnFlight ? "check" : "warning"} />
													<Icon
														style={{ margin: 0 }}
														name="trash"
														link
														onClick={(e) => handleDeleteClick(e, record.fieldId)}
													/>
												</div>
											)
										};
										return opt;
									})
								)
							);
						}
					);
				} else if (!viewer) {
					dispatch(fieldActions.getFields(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
						setThirdDropdownOptions(
							[{ key: "all", value: "all", text: "All" }].concat(
								_.map(res, (record) => {
									const opt = {
										key: record.id,
										value: record.id,
										text: record.name,
										icon: (
											<>
												<Icon
													style={{ float: "right" }}
													name="trash"
													link
													onClick={(e) => handleDeleteClick(e, record.id)}
												/>
												<Icon style={{ float: "right" }} name="check" />
											</>
										)
									};
									return opt;
								})
							)
						);
					});
				} else {
					dispatch(fieldActions.getFields(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
						setThirdDropdownOptions(
							[{ key: "all", value: "all", text: "All" }].concat(
								_.map(res, (record) => {
									const opt = {
										key: record.id,
										value: record.id,
										text: record.name
									};
									return opt;
								})
							)
						);
					});
				}
			}
		}

		useEffect(() => {
			if (orthoId && (reduxFlightId || moduleNavigation.flightId)) {
				//-- Check if new ortho is on current flight
				let imagesWithFlight = _.filter(
					flightImages,
					(fi) => fi.flightId === (reduxFlightId ?? moduleNavigation.flightId)
				);
				let orthosWithFlight = _.map(imagesWithFlight, "orthoImageTypeId");
				if (!orthosWithFlight.includes(orthoId)) {
					//-- Try to find flight with matching date instead
					const flightDate = new Date(imagesWithFlight[0].flightDateAndTime);
					const formattedDate = `${flightDate.getFullYear()}-${flightDate.getMonth()}-${flightDate.getDate()}`;

					let matchingFlightIdx = _.findIndex(flightImages, (fi) => {
						const localDate = new Date(fi.flightDateAndTime);
						const formattedLocalDate = `${localDate.getFullYear()}-${localDate.getMonth()}-${localDate.getDate()}`;

						if (formattedDate === formattedLocalDate && fi.flightId !== imagesWithFlight[0].flightId) {
							return true;
						}
					});

					let foundFlightId =
						matchingFlightIdx !== -1 ? flightImages[matchingFlightIdx].flightId : imagesWithFlight[0].flightId;

					dispatch(orthoViewerActions.setCurrentFlightId(foundFlightId));
				}
			}

			if (orthoId && (reduxFlightDate || currentFlightDate)) {
				//-- Check if new ortho is on current flight date
				let imagesWithFlight = _.filter(
					flightImages,
					(fi) => fi.flightDateAndTime === (reduxFlightDate ?? currentFlightDate)
				);
				let orthosWithFlight = _.map(imagesWithFlight, "orthoImageTypeId");
				if (!orthosWithFlight.includes(orthoId)) {
					dispatch(orthoViewerActions.setCurrentFlightDate(imagesWithFlight[0].flightDateAndTime));
				}
			}
		}, [orthoId]);

		useEffect(() => {
			if (thirdDropdownId && thirdDropdownOptions.length > 0) {
				let option = _.find(thirdDropdownOptions, { key: thirdDropdownId });
				if (level === "flight") {
					handleFieldDropdownChange(option);
				}
			}
		}, [thirdDropdownId]);

		useEffect(() => {
			if (flightImages?.length > 0 && reduxFlightId && orthoId) {
				let imagesWithFlight = _.filter(
					flightImages,
					(fi) => fi.flightId === (reduxFlightId ?? moduleNavigation.flightId)
				);
				let imageTypesWithFlight = _.map(imagesWithFlight, "orthoImageTypeId");

				if (!imageTypesWithFlight.includes(orthoId)) {
					setOrthoId(imageTypesWithFlight[0]);
				}
			}
		}, [reduxFlightId]);

		useEffect(() => {
			if (flightImages?.length > 0 && reduxFlightDate && orthoId) {
				let imagesWithFlight = _.filter(
					flightImages,
					(fi) => fi.flightDateAndTime === (reduxFlightDate ?? currentFlightDate)
				);
				let imageTypesWithFlight = _.map(imagesWithFlight, "orthoImageTypeId");

				if (!imageTypesWithFlight.includes(orthoId)) {
					setOrthoId(imageTypesWithFlight[0]);
				}
			}
		}, [reduxFlightDate]);

		const getCenterCoordinates = (res) => {
			let center = turf.midpoint(turf.point([res.llLong, res.llLat]), turf.point([res.urLong, res.urLat]));

			return center;
		};

		const handleDeleteClick = (e, fieldId) => {
			removeFieldGrid(fieldId);
			e.preventDefault();
			e.stopPropagation();
		};

		return (
			<div style={level !== "flight" ? { width: 232 } : {}}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "10px",
						justifyContent: "flex-end"
					}}
				>
					<div style={{ width: 64 }}>
						<label style={{ fontSize: "16px" }} htmlFor="select-ortho">
							Imagery
						</label>
					</div>
					<div style={level !== "flight" ? { flexGrow: "1", width: "0" } : {}}>
						<Dropdown
							id="select-ortho"
							selection
							fluid={level !== "flight"}
							search
							options={orthoOptions}
							value={orthoId}
							trigger={
								<div className="text" title={_.find(orthoOptions, { key: orthoId })?.text}>
									{_.find(orthoOptions, { key: orthoId })?.text}
								</div>
							}
							onChange={(event, { value }) => {
								setOrthoId(value);
							}}
							loading={!orthoOptions}
							placeholder={"None found"}
						/>
					</div>
					{level === "flight" && (
						<>
							<div>
								<label style={{ fontSize: "16px" }} htmlFor="select-field">
									Fields
								</label>
							</div>
							<div>
								<Dropdown
									id="select-field"
									style={{ width: "300px" }}
									selection
									search
									options={thirdDropdownOptions}
									placeholder={"None found"}
									value={thirdDropdownOptions && thirdDropdownOptions.length === 0 ? null : thirdDropdownId}
									onChange={(event, { value }) => {
										setThirdDropdownId(value);
									}}
									//-- Admittedly this is a bandaid, but it works well enough
									loading={!orthoOptions}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
);

OrthoDropdownComponent.propTypes = {
	level: PropTypes.string.isRequired,
	edit: PropTypes.bool.isRequired,
	viewer: PropTypes.bool.isRequired,
	buildMapImage: PropTypes.func.isRequired,
	movePlot: PropTypes.func.isRequired,
	handleFieldDropdownChange: PropTypes.func.isRequired,
	setOrthoIdToPass: PropTypes.func.isRequired,
	removeFieldGrid: PropTypes.func.isRequired,
	setOrthoTypes: PropTypes.func.isRequired,
	jumpToCoords: PropTypes.func.isRequired,
	redrawPlots: PropTypes.func.isRequired,
	drawFarmPlots: PropTypes.func,
	setOrthoTimelineFlights: PropTypes.func,
	imageFound: PropTypes.func.isRequired,
	singleFeatureToggle: PropTypes.func,
	resetMap: PropTypes.func,
	setCropToFilterOn: PropTypes.func,
	heatmapTimelineRef: PropTypes.object,
	fieldDropdownRef: PropTypes.any,
	trialDropdownRef: PropTypes.any,
	cropDropdownRef: PropTypes.any,
	dateRangeDropdownRef: PropTypes.any
};

OrthoDropdownComponent.displayName = "OrthoDropdownComponent";

export default OrthoDropdownComponent;
