import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

import colorGradient from "./colorGradient";

import "./styles.css";

const Heatmap = ({ data, showTrialOutline = false, isField = false }) => {
	//-- Set numbers to a specfic percision
	data = _.map(data, (d) => {
		if (d.value === null || d.value === -1 || isNaN(d.value)) {
			return d;
		}

		if (Number(d.value) >= 1000) {
			d.value = Number(Number(d.value).toFixed(0));
		} else if (Number(d.value) < 1000 && Number(d.value) > 1) {
			d.value = Number(Number(d.value).toFixed(2));
		} else {
			d.value = Number(Number(d.value).toFixed(5));
		}
		return d;
	});

	const uniqueMapValues = _.map(
		_.filter(data, (d) => !d.excludeFromAssessment),
		"value"
	);

	const minValue = Math.min(...uniqueMapValues.filter((x) => x !== null));
	const maxValue = Math.max(...uniqueMapValues.filter((x) => x !== null));
	let midValue = median(
		_.map(
			_.filter(data, (hmd) => hmd.value !== null && !hmd.excludeFromAssessment),
			"value"
		)
	);

	if (midValue === maxValue) {
		midValue = (minValue + maxValue) / 2;
	}

	const cg = new colorGradient(
		minValue,
		midValue,
		maxValue,
		null,
		data[0]?.analysis?.includes("MG50") ||
		_.some(
			_.filter(data, (d) => !d.excludeFromAssessment),
			(d) => {
				return d.value < 0;
			}
		)
			? true
			: false
	);

	function renderHeaderAndFooter() {
		const columns = getColumns();

		const tableHeader = (
			<tr key={"header"}>
				{/* top left and lower left corners */}
				<td
					style={
						{
							// backgroundColor: "#000000",
							// color: "#FFFFFF",
							// minWidth: 50,
							// height: 25
						}
					}
				/>
				{columns.map((thv) => (
					<td
						key={thv}
						style={{
							// backgroundColor: "#000000",
							// color: "#FFFFFF",
							// minWidth: 50,
							// height: 25,
							textAlign: "center",
							verticalAlign: "middle"
						}}
					>
						{thv}
					</td>
				))}
				{/* top right and lower right corners */}
				<td
					style={
						{
							// backgroundColor: "#000000",
							// color: "#FFFFFF",
							// minWidth: 50,
							// height: 25
						}
					}
				/>
			</tr>
		);
		return tableHeader;
	}

	function renderRows() {
		const rows = getRows();
		// console.log(`rows`);uniqueMapValues
		// console.log(rows);

		const columns = getColumns();
		// console.log(`columns`);
		// console.log(columns);

		let tableRows = [];

		let i = -1;
		for (i = rows.length - 1; i >= 0; i--) {
			const rIndex = i;
			const rowNumber = rows[i];
			let newRow = (
				<tr key={`${rIndex}`}>
					{/* Range numbers on left */}
					<td
						style={{
							// backgroundColor: "#000000",
							// color: "#FFFFFF",
							// height: 50,
							minWidth: 21,
							textAlign: "center",
							verticalAlign: "middle"
						}}
					>
						{rowNumber}
					</td>
					{columns.map((colNumber, cIndex) => {
						const plotCell = data.find((pc) => {
							return pc.x === rowNumber && pc.y === colNumber;
						});
						if (plotCell) {
							return (
								<td
									className="rotatePlotValues"
									key={`${rIndex}_${cIndex}`}
									style={{
										border: showTrialOutline && plotCell.trialId ? "solid 2px #000000" : "dotted 1px rgb(0, 0, 0, .5)",
										backgroundColor:
											plotCell.value !== null && !plotCell.excludeFromAssessment && !plotCell?.failed
												? cg.getColorAtValue(plotCell.value)
												: "rgb(128, 128, 128, .5)",
										height: 100,
										minWidth: 25,
										textAlign: "center",
										verticalAlign: "middle",
										fontWeight: "bolder"
									}}
								>
									{plotCell.tooltipText && Object.keys(plotCell.tooltipText).length > 0 ? (
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0.37}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={_.map(Object.keys(plotCell.tooltipText), (key, index) => {
												return (
													<div key={index}>{`${key}${plotCell.tooltipText[key] === "" ? "" : ": "}${
														plotCell.tooltipText[key]
													}`}</div>
												);
											})}
											transitionName="rc-tooltip-zoom"
										>
											<div>
												<span>
													{plotCell.value === null || plotCell?.failed || plotCell.value === -1 ? "-" : plotCell.value}
												</span>
											</div>
										</Tooltip>
									) : (
										<div>
											<span>{plotCell?.failed || plotCell.value === -1 ? "-" : plotCell.value}</span>
										</div>
									)}
								</td>
							);
						} else {
							return (
								<td
									className="rotatePlotValues"
									key={`${rIndex}_${cIndex}`}
									style={{
										border: "dotted 1px rgb(0, 0, 0, .5)",
										backgroundColor: "transparent",
										height: 100,
										minWidth: 25,
										textAlign: "center",
										verticalAlign: "middle",
										fontWeight: "bolder"
									}}
								>
									<div>
										<span></span>
									</div>
								</td>
							);
						}
					})}
					{/* Range numbers on right */}
					<td
						style={{
							// backgroundColor: "#000000",
							// color: "#FFFFFF",
							// height: 50,
							minWidth: 21,
							textAlign: "center",
							verticalAlign: "middle"
						}}
					>
						{rowNumber}
					</td>
				</tr>
			);

			tableRows.push(newRow);
		}
		return tableRows;
	}

	function getColumns() {
		const potentiallyFracturedColumnNumbers = [...new Set(data.map((hmd) => hmd.y))];
		// console.log(`potentiallyFracturedColumnNumbers`);
		// console.log(potentiallyFracturedColumnNumbers);

		let unfracturedColumnNumbers = [];

		let i = -1;

		const minColNumber = isField ? 1 : _.min(potentiallyFracturedColumnNumbers);
		const maxColNumber = isField ? data[0].maxColumns : _.max(potentiallyFracturedColumnNumbers);

		for (i = minColNumber; i <= maxColNumber; i++) {
			unfracturedColumnNumbers.push(i);
		}

		// console.log(`unfracturedColumnNumbers`);
		// console.log(unfracturedColumnNumbers);

		return unfracturedColumnNumbers;
	}

	function getRows() {
		const potentiallyFracturedRowNumbers = [...new Set(data.map((hmd) => hmd.x))];
		// console.log(`potentiallyFracturedRowNumbers`);
		// console.log(potentiallyFracturedRowNumbers);

		let unfracturedRowNumbers = [];

		let i = -1;
		const minRowNumber = isField ? 1 : _.min(potentiallyFracturedRowNumbers);
		const maxRowNumber = isField ? data[0].maxRanges : _.max(potentiallyFracturedRowNumbers);

		for (i = minRowNumber; i <= maxRowNumber; i++) {
			unfracturedRowNumbers.push(i);
		}

		// console.log(`unfracturedRowNumbers`);
		// console.log(unfracturedRowNumbers);

		return unfracturedRowNumbers;
	}

	return (
		<table style={{ borderCollapse: "collapse" }}>
			<thead>{renderHeaderAndFooter()}</thead>
			<tbody>{renderRows()}</tbody>
			<tfoot>{renderHeaderAndFooter()}</tfoot>
		</table>
	);
};

function median(numbers) {
	const numsLen = numbers.length;
	let median = 0;

	numbers.sort();

	if (numsLen % 2 === 0) {
		median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
	} else {
		median = numbers[(numsLen - 1) / 2];
	}

	return median;
}

Heatmap.propTypes = {
	data: PropTypes.array.isRequired,
	showTrialOutline: PropTypes.bool,
	isField: PropTypes.bool
};

export default Heatmap;
