export const ApplicationArea = {
	HomeOverview: "HomeOverview",
	HomeFavorites: "HomeFavorites",
	HomeRecentlyUpdated: "HomeRecentlyUpdated",
	HomeRecentlyPublished: "HomeRecentlyPublished",
	HomeBulkPublishing: "HomeBulkPublishing",
	FlightSearch: "FlightSearch",
	FlightNew: "FlightNew",
	FlightNewNoDelete: "FlightNewNoDelete",
	FlightViewer: "FlightViewer",
	FlightInfo: "FlightInfo",
	FlightInfoNoDelete: "FlightInfoNoDelete",
	FlightEdit: "FlightEdit",
	FlightEditNoDelete: "FlightEditNoDelete",
	FlightOrthoUpload: "FlightOrthoUpload",
	FlightOrthoUploadNoDelete: "FlightOrthoUploadNoDelete",
	FlightGenerateOrtho: "FlightGenerateOrtho",
	FlightDownloadOrtho: "FlightDownloadOrtho",
	FlightOrthoFileshare: "FlightOrthoFileshare",
	FlightAlignFields: "FlightAlignFields",
	FlightAddNewFields: "FlightAddNewFields",
	FlightPlotAnalysis: "FlightPlotAnalysis",
	FlightPlotAnalysisCRUD: "FlightPlotAnalysisCRUD",
	FieldSearch: "FieldSearch",
	FieldNew: "FieldNew",
	FieldEdit: "FieldEdit",
	FieldViewer: "FieldViewer",
	FieldHeatMap: "FieldHeatMap",
	FieldVisualHeatmapShading: "FieldVisualHeatmapShading",
	FieldVisualHeatmapValues: "FieldVisualHeatmapValues",
	FieldExportFunctionality: "FieldExportFunctionality",
	FieldExcludePlotsCRUD: "FieldExcludePlotsCRUD",
	FieldFlagPlotsCRUD: "FieldFlagPlotsCRUD",
	FieldOutline: "FieldOutline",
	FieldGroundData: "FieldGroundData",
	FieldGroundDataCRUD: "FieldGroundDataCRUD",
	FieldGroundDataTemplate: "FieldGroundDataTemplate",
	TrialSearch: "TrialSearch",
	TrialPublishing: "TrialPublishing",
	TrialViewer: "TrialViewer",
	TrialEdit: "TrialEdit",
	TrialAssignSponsor: "TrialAssignSponsor",
	TrialEditSponsorCompany: "TrialEditSponsorCompany",
	TrialHeatMap: "TrialHeatMap",
	TrialVisualHeatmapShading: "TrialVisualHeatmapShading",
	TrialVisualHeatmapValues: "TrialVisualHeatmapValues",
	TrialExportFunctionality: "TrialExportFunctionality",
	TrialDamagePlots: "TrialDamagePlots",
	TrialDamagePlotsCRUD: "TrialDamagePlotsCRUD",
	TrialExcludePlots: "TrialExcludePlots",
	TrialExcludePlotsCRUD: "TrialExcludePlotsCRUD",
	TrialFlagPlots: "TrialFlagPlots",
	TrialFlagPlotsCRUD: "TrialFlagPlotsCRUD",
	TrialAnalysis: "TrialAnalysis",
	TrialAnalysisTimecourse: "TrialAnalysisTimecourse",
	TrialAnalysisBoxWhisker: "TrialAnalysisBoxWhisker",
	TrialAnalysisSummaryTable: "TrialAnalysisSummaryTable",
	TrialAnalysisCorrelationMatrix: "TrialAnalysisCorrelationMatrix",
	TrialAnalysisCorrelationPlots: "TrialAnalysisCorrelationPlots",
	TrialOutline: "TrialOutline",
	TrialGroundData: "TrialGroundData",
	TrialGroundDataCRUD: "TrialGroundDataCRUD",
	TrialGroundDataTemplate: "TrialGroundDataTemplate",
	TrialCurveModels: "TrialCurveModels",
	TrialCurveModelsCRUD: "TrialCurveModelsCRUD",
	TrialWeather: "TrialWeather",
	TrialWeatherCRUD: "TrialWeatherCRUD",
	ProtocolSearch: "ProtocolSearch",
	ProtocolNew: "ProtocolNew",
	ProtocolEdit: "ProtocolEdit",
	ProtocolOverview: "ProtocolOverview",
	ProtocolAnalysis: "ProtocolAnalysis",
	ClientSettings: "ClientSettings",
	ApSettings: "ApSettings",
	ExcludeAnnotation: "ExcludeAnnotation",
	FarmSearch: "FarmSearch",
	FarmViewer: "FarmViewer",
	FarmVisualHeatmapShading: "FarmVisualHeatmapShading",
	FarmVisualHeatmapValues: "FarmVisualHeatmapValues",
	FarmExcludePlotsCRUD: "FarmExcludePlotsCRUD",
	FarmFlagPlotsCRUD: "FarmFlagPlotsCRUD",
	FarmNew: "FarmNew",
	FarmEdit: "FarmEdit",
	FarmInfo: "FarmInfo",
	FarmGeo: "FarmGeo",
	FarmSummary: "FarmSummary",
	FarmWeather: "FarmWeather",
	TrialMetaTagModalCRUD: "TrialMetaTagModalCRUD",
	TrialMetaTagModal: "TrialMetaTagModal"
};
