import Tooltip from "rc-tooltip";
import React, { FC, useState } from "react";
import { Icon, Menu } from "semantic-ui-react";
import TrialMetaFactorsModal from "./TrialMetaFactorsModal";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import { useUserAuth } from "../../../../hooks/useUserAuth";

type Props = {
	trialId: string;
	trialName: string;
};

const TrialMetaFactorsModalMenuIcon: FC<Props> = ({ trialId }) => {
	const [open, setOpen] = useState(false);
	const userAuth = useUserAuth();

	return (
		<>
			<Tooltip
				placement="right"
				align={{ offset: [-8, 0] }}
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				trigger="hover"
				overlay="Meta-Tags & Annotations"
				transitionName="rc-tooltip-zoom"
			>
				<Menu.Item
					key={"metaFactorModal"}
					className={!trialId ? "disabledNavItem" : undefined}
					disabled={!trialId || (!userAuth.hasApplicationArea(ApplicationArea.TrialMetaTagModalCRUD, userAuth.currentClientId) && !userAuth.hasApplicationArea(ApplicationArea.TrialMetaTagModal, userAuth.currentClientId))}
					active={open}
					onClick={() => {
						setOpen((prev) => !prev);
					}}
				>
					<Icon style={{ margin: 0 }} name="tags" size="large" />
				</Menu.Item>
			</Tooltip>
			<TrialMetaFactorsModal trialId={trialId} open={open} setOpen={setOpen} />
		</>
	);
};

export default TrialMetaFactorsModalMenuIcon;
