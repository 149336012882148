import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState, useRef } from "react";
import * as uuid from "uuid";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { ApplicationArea } from "../../Lumber/ApplicationAreas";

import Tooltip from "rc-tooltip";
import {
	Button,
	Confirm,
	Divider,
	Dropdown,
	Form,
	Grid,
	Header,
	Icon,
	Input,
	List,
	Modal,
	Popup,
	Segment
} from "semantic-ui-react";

import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import Histogram from "../Histogram";
import "./controlStyle.css";
import {
	getActiveShader,
	getMaxThreshold,
	getMinThreshold,
	setMaxThreshold,
	setMinThreshold
} from "./orthoControlFunctions";
import VerticalSlider from "../VerticalSlider/index.tsx";

export const LowerLeftToggle = React.forwardRef(({ lowerLeftToggle }, ref) => {
	const [llToggle, setLLToggle] = useState(true);
	const [disabled, setDisabled] = useState(true);
	LowerLeftToggle.displayName = "LowerLeftToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={llToggle}
			onClick={() => {
				lowerLeftToggle();
				setLLToggle(!llToggle);
			}}
			disabled={disabled}
		>
			<Icon name="external square alternate" size="large" />
			<span>Lower Left Corners</span>
		</Button>
	);
});

LowerLeftToggle.propTypes = {
	lowerLeftToggle: PropTypes.func.isRequired
};

export const FarmBoundsToggle = React.forwardRef(({ farmBoundsToggle }, ref) => {
	const [active, setActive] = useState(false);
	const [disabled, setDisabled] = useState(true);
	FarmBoundsToggle.displayName = "FarmBoundsToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={active}
			onClick={() => {
				farmBoundsToggle();
				setActive(!active);
			}}
			disabled={disabled}
		>
			<Icon name="farm" size="large" />
			<span>Farm Bounds</span>
		</Button>
	);
});

FarmBoundsToggle.propTypes = {
	farmBoundsToggle: PropTypes.func.isRequired
};

export const FieldBoundaryToggle = React.forwardRef(({ fieldBoundaryToggle }, ref) => {
	const [fieldToggled, setFieldToggled] = useState(true);
	const [disabled, setDisabled] = useState(true);
	FieldBoundaryToggle.displayName = "FieldBoundaryToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={fieldToggled}
			onClick={() => {
				fieldBoundaryToggle();
				setFieldToggled(!fieldToggled);
			}}
			disabled={disabled}
		>
			<Icon name="square outline" size="large" />
			<span>Field Boundaries</span>
		</Button>
	);
});

FieldBoundaryToggle.propTypes = {
	fieldBoundaryToggle: PropTypes.func.isRequired
};

export const PlotToggle = React.forwardRef(({ plotToggle }, ref) => {
	const [plotToggled, setPlotToggled] = useState(true);
	const [disabled, setDisabled] = useState(true);
	PlotToggle.displayName = "PlotToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled,
			setToggleState: (val) => {
				setPlotToggled(val);
			},
			checkToggleState: plotToggled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={plotToggled}
			onClick={() => {
				plotToggle();
				setPlotToggled(!plotToggled);
			}}
			disabled={disabled}
		>
			<Icon name="table body" size="large" />
			<span>Plot Boundaries</span>
		</Button>
	);
});

PlotToggle.propTypes = {
	plotToggle: PropTypes.func.isRequired
};

export const RowToggle = React.forwardRef(({ rowToggle }, ref) => {
	const [rowToggled, setRowToggled] = useState(false);
	const [disabled, setDisabled] = useState(true);
	RowToggle.displayName = "RowToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={rowToggled}
			onClick={() => {
				rowToggle();
				setRowToggled(!rowToggled);
			}}
			disabled={disabled}
		>
			<Icon className="rows" size="large" />
			<span>Crop Rows</span>
		</Button>
	);
});

RowToggle.propTypes = {
	rowToggle: PropTypes.func.isRequired
};

export const ResetOrientation = React.forwardRef(({ resetOrientation }, ref) => {
	const [disabled, setDisabled] = useState(true);
	ResetOrientation.displayName = "ResetOrientation";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button icon compact onClick={resetOrientation} disabled={disabled}>
			<Icon name="compass outline" size="large" />
			<span>Re-center</span>
		</Button>
	);
});

ResetOrientation.propTypes = {
	resetOrientation: PropTypes.func.isRequired
};

export const MeasureDistance = React.forwardRef(({ togglePopup }, ref) => {
	const [disabled, setDisabled] = useState(true);
	const [open, setOpen] = useState(false);

	MeasureDistance.displayName = "MeasureDistance";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled,
			setActive: (value) => {
				setOpen(value);
			}
		};
	});

	return (
		<Button
			icon
			compact
			onClick={() => {
				togglePopup();
			}}
			toggle
			active={open}
		>
			<Icon className="ruler" size="large" />
			<span>Measurement Tools</span>
		</Button>
	);
});

MeasureDistance.propTypes = {
	togglePopup: PropTypes.func.isRequired
};

export const MeasurePopup = React.forwardRef(
	({ measureLine, /*measureBox,*/ measurePoly, coordAtPoint, clearMeasurement, measureDistanceRef }, ref) => {
		MeasurePopup.displayName = "MeasurePopup";

		const [open, setOpen] = useState(false);
		const [isDraggingWindow, setIsDraggingWindow] = useState(false);
		const [relativeCoords, setRelativeCoords] = useState([0, 0]);
		const [disabled, setDisabled] = useState(false);

		const [currentTool, setCurrentTool] = useState("Measurement Tools");
		const [measuredInfo, setMeasuredInfo] = useState("");
		const [coords, setCoords] = useState(null);
		const [infoCopied, setInfoCopied] = useState(false);

		const handlePointerUp = useCallback((e) => {
			setIsDraggingWindow(false);
			e.stopPropagation();
			e.preventDefault();
		}, []);

		const handlePointerMove = useCallback(
			(e) => {
				if (isDraggingWindow) {
					setRelativeCoords((prev) => [prev[0] + e.movementX, prev[1] + e.movementY]);
				}
				e.stopPropagation();
				e.preventDefault();
			},
			[isDraggingWindow]
		);

		useEffect(() => {
			window.addEventListener("pointerup", handlePointerUp);
			window.addEventListener("pointermove", handlePointerMove);

			return () => {
				window.removeEventListener("pointerup", handlePointerUp);
				window.removeEventListener("pointermove", handlePointerMove);
			};
		}, [isDraggingWindow]);

		useEffect(() => {
			if (!open) {
				setRelativeCoords([0, 0]);
				clearMeasurement();
			} else {
				measureLine();
			}
			measureDistanceRef.current.setActive(open);
		}, [open]);

		useImperativeHandle(ref, () => {
			return {
				setDisabled: (val) => {
					setDisabled(val);
				},
				checkDisabled: disabled,
				open: open,
				setOpen: (value) => {
					setOpen(value);
				},
				toggleOpen: () => {
					setOpen((prev) => !prev);
				},
				setMeasuredInfo: (measurement, unit) => {
					setMeasuredInfo(`${measurement.toLocaleString()} ${unit}`);
				},
				setCurrentTool: (tool) => {
					if (tool) {
						setCurrentTool(tool);
					} else {
						setCurrentTool("Measurement Tools");
					}
				},
				setCoords: (c) => {
					setCoords(c);
				},
				reset: () => {
					setCoords(null);
					setMeasuredInfo("");
				}
			};
		});

		return open ? (
			<div
				className={`draggableContainer${isDraggingWindow ? " dragging" : ""}`}
				style={{ translate: `${relativeCoords[0]}px ${relativeCoords[1]}px` }}
			>
				<div
					className="popupBar"
					onPointerDown={() => {
						setIsDraggingWindow(true);
					}}
				>
					<span style={{ fontSize: 14, fontWeight: 500, marginLeft: 7, pointerEvents: "none", lineHeight: "25px" }}>
						{currentTool}
					</span>
					<div />
					<Icon
						name="close"
						onClick={() => {
							setOpen(false);
						}}
					/>
				</div>
				<div className="draggableContent" style={{ justifyContent: "start" }}>
					<Button.Group>
						<Button
							icon
							compact
							onClick={measureLine}
							toggle
							active={currentTool === "Line Measure"}
							disabled={disabled}
						>
							<Icon className="line measure" size="large" />
							<span>Line</span>
						</Button>
						{/* <Button
							icon
							compact
							onClick={measureBox}
							toggle
							active={currentTool === "Rectangle Measure"}
							disabled={disabled}
						>
							<Icon name="square outline" size="large" />
							<span>Square</span>
						</Button> */}
						<Button
							icon
							compact
							onClick={measurePoly}
							toggle
							active={currentTool === "Polygon Measure"}
							disabled={disabled}
						>
							<Icon className="polygon" size="large" />
							<span>Polygon</span>
						</Button>
						<Button
							icon
							compact
							onClick={coordAtPoint}
							toggle
							active={currentTool === "Coordinate at Point"}
							disabled={disabled}
						>
							<Icon className="point" size="large" />
							<span>Coordinate</span>
						</Button>

						<Button icon compact onClick={clearMeasurement}>
							<Icon className="trash" size="large" />
							<span>Clear</span>
						</Button>
					</Button.Group>

					<div style={{ cursor: "pointer", paddingTop: 10 }}>
						{(measuredInfo || coords) && (
							<>
								{measuredInfo && (
									<Tooltip
										placement="top"
										align={{ offset: [0, 2] }}
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={!infoCopied ? "Copy Measurements" : "Measurements Copied"}
									>
										<p
											onClick={() => {
												setInfoCopied(true);
												navigator.clipboard.writeText(measuredInfo);
											}}
											onMouseLeave={() => {
												setInfoCopied(false);
											}}
										>
											Measured Info: {measuredInfo}
										</p>
									</Tooltip>
								)}
								{coords && (
									<Tooltip
										placement="top"
										align={{ offset: [0, 2] }}
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={!infoCopied ? "Copy Coordinates" : "Coordinates Copied"}
									>
										<p
											onClick={() => {
												setInfoCopied(true);
												navigator.clipboard.writeText(
													coords.reduce((acc, cur) => acc + `${cur[0]},${cur[1]},\n`, "").slice(0, -1)
												);
											}}
											onMouseLeave={() => {
												setInfoCopied(false);
											}}
										>
											Coords:{" "}
											{coords.map((c, i) => (
												<p key={i} style={{ margin: 0 }}>
													{i + 1}: ({c[0]}, {c[1]})
												</p>
											))}
										</p>
									</Tooltip>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		) : (
			<></>
		);
	}
);

MeasurePopup.propTypes = {
	measureLine: PropTypes.func.isRequired,
	measureBox: PropTypes.func,
	measurePoly: PropTypes.func.isRequired,
	coordAtPoint: PropTypes.func.isRequired,
	clearMeasurement: PropTypes.func.isRequired,
	measureDistanceRef: PropTypes.object
};

export const MapboxStyleToggle = ({ styleToggle, setStyle }) => {
	ResetOrientation.displayName = "MapboxStyleToggle";
	const mapboxStyleOptions = [
		{ key: "bright-v9", text: "mapbox://styles/mapbox/bright-v9", value: "mapbox://styles/mapbox/bright-v9" },
		{
			key: "satellite-streets-v11",
			text: "mapbox://styles/mapbox/satellite-streets-v11",
			value: "mapbox://styles/mapbox/satellite-streets-v11"
		}
	];
	const [brightActive, setBrightActive] = useState(false);
	const [satelliteActive, setSatelliteActive] = useState(true);

	const toggleActiveControl = (style) => {
		if (style.key == "bright-v9") {
			setBrightActive(true);
			setSatelliteActive(false);
			setStyle(mapboxStyleOptions[0].value);
		} else if (style.key == "satellite-streets-v11") {
			setSatelliteActive(true);
			setBrightActive(false);
			setStyle(mapboxStyleOptions[1].value);
		}
	};

	return (
		<>
			<Tooltip
				placement="bottom"
				align={{ offset: [0, -2] }}
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				trigger="hover"
				overlay={
					<>
						<p>Street-View Background</p>
					</>
				}
			>
				<Button
					icon
					compact
					toggle
					active={brightActive}
					onClick={() => {
						toggleActiveControl(mapboxStyleOptions[0]);
						styleToggle();
					}}
				>
					<Icon name="road" size="large" />
					<span>Street-View</span>
				</Button>
			</Tooltip>

			<Tooltip
				placement="bottom"
				align={{ offset: [0, -2] }}
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				trigger="hover"
				overlay={
					<>
						<p>Satellite-View Background</p>
					</>
				}
			>
				<Button
					icon
					compact
					toggle
					active={satelliteActive}
					onClick={() => {
						toggleActiveControl(mapboxStyleOptions[1]);
						styleToggle();
					}}
				>
					<Icon name="globe" size="large" />
				</Button>
			</Tooltip>
		</>
	);
};

MapboxStyleToggle.propTypes = {
	styleToggle: PropTypes.func.isRequired,
	setStyle: PropTypes.func.isRequired
};

export const TrialToggle = React.forwardRef(({ trialToggle }, ref) => {
	const [trialToggled, setTrialToggled] = useState(false);
	const [disabled, setDisabled] = useState(true);

	TrialToggle.displayName = "TrialToggle";

	useImperativeHandle(ref, () => {
		return {
			setTrialToggled: (val) => {
				setTrialToggled(val);
			},
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkToggleState: trialToggled,
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			disabled={disabled}
			toggle
			active={trialToggled}
			onClick={() => {
				trialToggle();
				setTrialToggled(!trialToggled);
			}}
		>
			<Icon name="expand" size="large" />
			<span>Trials</span>
		</Button>
	);
});

TrialToggle.propTypes = {
	trialToggle: PropTypes.func.isRequired
};

export const FitToHeight = React.forwardRef(({ fitToHeight }, ref) => {
	const [disabled, setDisabled] = useState(true);
	FitToHeight.displayName = "FitToHeight";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button icon compact onClick={fitToHeight} disabled={disabled}>
			<Icon className="fit height" size="large" />
			<span>Fit to Height</span>
		</Button>
	);
});

FitToHeight.propTypes = {
	fitToHeight: PropTypes.func.isRequired
};

export const FitToWidth = React.forwardRef(({ fitToWidth }, ref) => {
	const [disabled, setDisabled] = useState(true);
	FitToWidth.displayName = "FitToWidth";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button icon compact onClick={fitToWidth} disabled={disabled}>
			<Icon className="fit width" size="large" />
			<span>Fit to Width</span>
		</Button>
	);
});

FitToWidth.propTypes = {
	fitToWidth: PropTypes.func.isRequired
};

export const PlotLabelToggle = React.forwardRef(({ plotLabelToggle }, ref) => {
	const [toggleState, setToggleState] = useState(false);
	const [disabled, setDisabled] = useState(true);
	PlotLabelToggle.displayName = "PlotLabelToggle";

	useImperativeHandle(ref, () => {
		return {
			setToggleState: (val) => {
				setToggleState(val);
			},
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled,
			checkToggleState: toggleState
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			disabled={disabled}
			active={toggleState}
			onClick={() => {
				plotLabelToggle();
				setToggleState(!toggleState);
			}}
		>
			<Icon name="table" size="large" />
			<span>Plot</span>
		</Button>
	);
});

PlotLabelToggle.propTypes = {
	plotLabelToggle: PropTypes.func.isRequired
};

export const DEFMarkerToggle = React.forwardRef(({ defMarkerToggle }, ref) => {
	const [toggleState, setToggleState] = useState(false);
	const [disabled, setDisabled] = useState(true);
	DEFMarkerToggle.displayName = "DEFMarkerToggle";

	useImperativeHandle(ref, () => {
		return {
			setToggleState: (val) => {
				setToggleState(val);
			},
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkToggleState: toggleState,
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			disabled={disabled}
			active={toggleState}
			onClick={() => {
				defMarkerToggle();
			}}
		>
			<Icon name="flag" size="large" />
			<span>Status Markers</span>
		</Button>
	);
});

DEFMarkerToggle.propTypes = {
	defMarkerToggle: PropTypes.func.isRequired
};

// Components for Editing

export const AddField = React.forwardRef(({ addField }, ref) => {
	const [disabled, setDisabled] = useState(false);
	AddField.displayName = "AddField";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button icon compact onClick={addField} disabled={disabled}>
			<Icon name="add" size="large" />
			<span>Add Field</span>
		</Button>
	);
});

AddField.propTypes = {
	addField: PropTypes.func.isRequired
};

export const Rotate = React.forwardRef(({ rotate, flip }, ref) => {
	const [disabled, setDisabled] = useState(true);
	Rotate.displayName = "Rotate";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			onClick={(e) => {
				rotate(e);
			}}
			disabled={disabled}
		>
			<Icon className={flip ? "rotate left" : "rotate right"} size="large" />
			<span>{flip ? "Rotate Left" : "Rotate Right"}</span>
		</Button>
	);
});

Rotate.propTypes = {
	rotate: PropTypes.func.isRequired,
	flip: PropTypes.bool.isRequired
};

export const LockToggle = React.forwardRef(({ lockToggle }, ref) => {
	const [disabled, setDisabled] = useState(true);
	const [locked, setLocked] = useState(false);
	LockToggle.displayName = "LockToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled,
			setLocked: (val) => {
				setLocked(val);
			}
		};
	});

	return (
		<Button
			icon
			compact
			onClick={() => {
				lockToggle();
			}}
			disabled={disabled}
		>
			<Icon name={locked ? "lock" : "unlock"} size="large" />
			<span>Lock Alignment</span>
		</Button>
	);
});

LockToggle.propTypes = {
	lockToggle: PropTypes.func.isRequired
};

export const FieldLabelToggle = React.forwardRef(({ fieldLabelToggle }, ref) => {
	const [toggleState, setToggleState] = useState(false);
	const [disabled, setDisabled] = useState(true);
	FieldLabelToggle.displayName = "FieldLabelToggle";

	useImperativeHandle(ref, () => {
		return {
			setToggleState: (val) => {
				setToggleState(val);
			},
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={toggleState}
			onClick={() => {
				fieldLabelToggle();
				setToggleState(!toggleState);
			}}
			disabled={disabled}
		>
			<Icon name="th large" size="large" />
			<span>Field</span>
		</Button>
	);
});

FieldLabelToggle.propTypes = {
	fieldLabelToggle: PropTypes.func.isRequired
};

export const TrialLabelToggle = React.forwardRef(({ trialLabelToggle }, ref) => {
	const [toggleState, setToggleState] = useState(false);
	const [disabled, setDisabled] = useState(true);
	TrialLabelToggle.displayName = "TrialLabelToggle";

	useImperativeHandle(ref, () => {
		return {
			setToggleState: (val) => {
				setToggleState(val);
			},
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkToggleState: toggleState,
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={toggleState}
			onClick={() => {
				trialLabelToggle();
				setToggleState(!toggleState);
			}}
		>
			<Icon name="leaf" size="large" />
			<span>Trial</span>
		</Button>
	);
});

TrialLabelToggle.propTypes = {
	trialLabelToggle: PropTypes.func.isRequired
};

export const FieldPivotToggle = React.forwardRef(({ fieldPivotToggle }, ref) => {
	const [toggleState, setToggleState] = useState(false);
	const [disabled, setDisabled] = useState(true);
	FieldPivotToggle.displayName = "FieldPivotToggle";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			toggle
			active={toggleState}
			onClick={() => {
				fieldPivotToggle();
				setToggleState(!toggleState);
			}}
			disabled={disabled}
		>
			<Icon name="target" size="large" />
			<span>Unlock Pivot Points</span>
		</Button>
	);
});

FieldPivotToggle.propTypes = {
	fieldPivotToggle: PropTypes.func.isRequired
};

export const FieldPivotReset = React.forwardRef(({ fieldPivotReset }, ref) => {
	const [disabled, setDisabled] = useState(true);
	FieldPivotReset.displayName = "FieldPivotReset";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			onClick={() => {
				fieldPivotReset();
			}}
			disabled={disabled}
		>
			<Icon.Group size="large">
				<Icon name="target" />
				<Icon corner="bottom right" name="sync" style={{ bottom: "-5px", right: "-7px" }} />
			</Icon.Group>
			<span>Reset Pivot Points</span>
		</Button>
	);
});

FieldPivotReset.propTypes = {
	fieldPivotReset: PropTypes.func.isRequired
};

export const FieldPivotLlSet = React.forwardRef(({ fieldPivotLlSet }, ref) => {
	const [disabled, setDisabled] = useState(true);
	FieldPivotLlSet.displayName = "FieldPivotLlSet";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			onClick={() => {
				fieldPivotLlSet();
			}}
			disabled={disabled}
		>
			<Icon className="bottom left circle" size="large" />
			<span>Set Pivots to Corner</span>
		</Button>
	);
});

FieldPivotLlSet.propTypes = {
	fieldPivotLlSet: PropTypes.func.isRequired
};

export const QuantifiedRegionToggle = React.forwardRef(({ quantifiedRegionToggle }, ref) => {
	QuantifiedRegionToggle.displayName = "QuantifiedRegionToggle";

	useImperativeHandle(ref, () => {
		return {
			setActiveList: (list) => setActiveList(list)
		};
	});

	const [activeList, setActiveList] = useState([]);
	const [toggleState, setToggleState] = useState(false);
	const [pcToggleState, setPcToggleState] = useState(false);
	const [pcSsToggleState, setPcSsToggleState] = useState(false);
	const [rcToggleState, setRcToggleState] = useState(false);
	const [brToggleState, setBrToggleState] = useState(false);
	const [frToggleState, setFrToggleState] = useState(false);
	const [scToggleState, setScToggleState] = useState(false);
	const [modalState, setModalState] = useState(false);

	const popupRef = useRef();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				popupRef?.current?.elementRef?.current?.innerText !== event.target?.innerText &&
				popupRef?.current?.triggerRef?.current?.innerText !== event.target?.innerText
			) {
				setModalState(false);
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setToggleState(
			pcSsToggleState || pcToggleState || rcToggleState || brToggleState || frToggleState || scToggleState
		);
	}, [pcSsToggleState, pcToggleState, rcToggleState, brToggleState, frToggleState, scToggleState]);

	useEffect(() => {
		if (pcToggleState) {
			setPcSsToggleState(false);
			setRcToggleState(false);
			setBrToggleState(false);
			setFrToggleState(false);
			setScToggleState(false);
		}
	}, [pcToggleState]);

	useEffect(() => {
		if (pcSsToggleState) {
			setPcToggleState(false);
			setRcToggleState(false);
			setBrToggleState(false);
			setFrToggleState(false);
			setScToggleState(false);
		}
	}, [pcSsToggleState]);

	useEffect(() => {
		if (rcToggleState) {
			setPcToggleState(false);
			setPcSsToggleState(false);
			setBrToggleState(false);
			setFrToggleState(false);
			setScToggleState(false);
		}
	}, [rcToggleState]);

	useEffect(() => {
		if (brToggleState) {
			setPcToggleState(false);
			setRcToggleState(false);
			setPcSsToggleState(false);
			setFrToggleState(false);
			setScToggleState(false);
		}
	}, [brToggleState]);

	useEffect(() => {
		if (frToggleState) {
			setPcToggleState(false);
			setRcToggleState(false);
			setBrToggleState(false);
			setPcSsToggleState(false);
			setScToggleState(false);
		}
	}, [frToggleState]);

	useEffect(() => {
		if (scToggleState) {
			setPcToggleState(false);
			setRcToggleState(false);
			setBrToggleState(false);
			setPcSsToggleState(false);
			setFrToggleState(false);
		}
	}, [scToggleState]);

	//-- Only handles button click if 1 qr type exists or if a qr is already toggled
	const handleClick = () => {
		if (activeList?.length === 1 || toggleState) {
			let activeItem = activeList[0];

			if (toggleState) {
				activeItem = pcToggleState
					? "Plot-Centered"
					: pcSsToggleState
					? "Plot-Centered with Subsamples"
					: rcToggleState
					? "Row-Centered"
					: brToggleState
					? "Between-Row"
					: scToggleState
					? "Stand Count"
					: "Full Row";
			}

			quantifiedRegionToggle(activeItem);

			switch (activeItem) {
				case "Plot-Centered":
					setPcToggleState(!pcToggleState);
					break;
				case "Plot-Centered with Subsamples":
					setPcSsToggleState(!pcSsToggleState);
					break;
				case "Row-Centered":
					setRcToggleState(!rcToggleState);
					break;
				case "Between-Row":
					setBrToggleState(!brToggleState);
					break;
				case "Full Row":
					setFrToggleState(!frToggleState);
					break;
				case "Stand Count":
					setScToggleState(!scToggleState);
					break;
				default:
					break;
			}
		}
	};

	return (
		<>
			<Popup
				ref={popupRef}
				position="right center"
				offset={[0, 14]}
				flowing
				style={{
					margin: "0",
					position: "relative",
					padding: "16px",
					zIndex: "unset",
					backgroundColor: "var(--background)",
					boxShadow: "var(--map-box-shadow)",
					borderColor: "var(--border)"
				}}
				disabled={activeList?.length === 1}
				basic
				trigger={
					<Button
						icon
						compact
						onClick={() => {
							handleClick();
							setModalState(!modalState);
						}}
						disabled={activeList?.length === 0}
						toggle
						active={toggleState}
					>
						<Icon name="align vertical" size="large" />
						<span>Quantified Regions</span>
					</Button>
				}
				open={modalState}
			>
				<Grid verticalAlign="middle" columns="equal">
					{activeList.includes("Plot-Centered") && (
						<Grid.Column style={{ padding: "0 5px 0 5px" }}>
							<Button
								fluid
								content="Plot-Centered"
								toggle
								active={pcToggleState}
								onClick={() => {
									quantifiedRegionToggle("Plot-Centered");
									setPcToggleState(!pcToggleState);
									setModalState(false);
								}}
							/>
						</Grid.Column>
					)}
					{activeList.includes("Plot-Centered with Subsamples") && (
						<Grid.Column style={{ padding: "0 5px 0 5px" }}>
							<Button
								fluid
								content="Plot-Centered with Subsamples"
								toggle
								active={pcSsToggleState}
								onClick={() => {
									quantifiedRegionToggle("Plot-Centered with Subsamples");
									setPcSsToggleState(!pcSsToggleState);
									setModalState(false);
								}}
							/>
						</Grid.Column>
					)}
					{activeList.includes("Row-Centered") && (
						<Grid.Column style={{ padding: "0 5px 0 5px" }}>
							<Button
								fluid
								content="Row-Centered"
								toggle
								active={rcToggleState}
								onClick={() => {
									quantifiedRegionToggle("Row-Centered");
									setRcToggleState(!rcToggleState);
									setModalState(false);
								}}
							/>
						</Grid.Column>
					)}
					{activeList.includes("Between-Row") && (
						<Grid.Column style={{ padding: "0 5px 0 5px" }}>
							<Button
								fluid
								content="Between-Row"
								toggle
								active={brToggleState}
								onClick={() => {
									quantifiedRegionToggle("Between-Row");
									setBrToggleState(!brToggleState);
									setModalState(false);
								}}
							/>
						</Grid.Column>
					)}
					{activeList.includes("Full Row") && (
						<Grid.Column style={{ padding: "0 5px 0 5px" }}>
							<Button
								fluid
								content="Full Row"
								toggle
								active={frToggleState}
								onClick={() => {
									quantifiedRegionToggle("Full Row");
									setFrToggleState(!frToggleState);
									setModalState(false);
								}}
							/>
						</Grid.Column>
					)}
					{activeList.includes("Stand Count") && (
						<Grid.Column style={{ padding: "0 5px 0 5px" }}>
							<Button
								fluid
								content="Stand Count"
								toggle
								active={scToggleState}
								onClick={() => {
									quantifiedRegionToggle("Stand Count");
									setScToggleState(!scToggleState);
									setModalState(false);
								}}
							/>
						</Grid.Column>
					)}
				</Grid>
			</Popup>
		</>
	);
});

QuantifiedRegionToggle.propTypes = {
	quantifiedRegionToggle: PropTypes.func.isRequired
};

export const ScaleOutward = React.forwardRef(({ scaleOutward }, ref) => {
	const [disabled, setDisabled] = useState(true);
	ScaleOutward.displayName = "ScaleOutward";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			onClick={(e) => {
				scaleOutward(e);
			}}
			disabled={disabled}
		>
			<Icon className="maximize" size="large" />
			<span>Scale Out</span>
		</Button>
	);
});

ScaleOutward.propTypes = {
	scaleOutward: PropTypes.func.isRequired
};

export const ScaleInward = React.forwardRef(({ scaleInward }, ref) => {
	const [disabled, setDisabled] = useState(true);
	ScaleInward.displayName = "ScaleInward";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Tooltip content="Test">
			<Button
				icon
				compact
				onClick={(e) => {
					scaleInward(e);
				}}
				disabled={disabled}
			>
				<Icon className="minimize" size="large" />
				<span>Scale In</span>
			</Button>
		</Tooltip>
	);
});

ScaleInward.propTypes = {
	scaleInward: PropTypes.func.isRequired
};

export const FlagPlots = React.forwardRef(
	(
		{
			markedPlotOptions,
			applyChanges,
			draw,
			markedPlots,
			dropdownRef,
			markedSubsamples,
			deleteExistingFeatures,
			getSubsamples,
			annotationClassOptions,
			level = null,
			getFlightId,
			checkAnnotationPermissions
		},
		ref
	) => {
		const steps = Object.freeze({
			DeleteFlaggedFeatures: "DeleteFlaggedFeatures",
			DeleteExcludedFeatures: "DeleteExcludedFeatures",
			ApplyChanges: "ApplyChanges",
			None: "None"
		});

		const [disabled, setDisabled] = useState(true);
		const [flagDisplayModal, setFlagDisplayModal] = useState(false);
		const [excludeDisplayModal, setExcludeDisplayModal] = useState(false);
		const [subsample, setSubsample] = useState(null);
		const [modalLoading, setModalLoading] = useState(false);
		const [confirm, setConfirm] = useState(false);
		const [confirmExcluded, setConfirmExcluded] = useState(false);
		const [notes, setNotes] = useState(null);

		const [statusOptions, setStatusOptions] = useState([]);
		const [annotationClassOpts, setAnnotationClassOpts] = useState([]);
		const [timeframeOptionsForFlagModal, setTimeframeOptionsForFlagModal] = useState([]);
		const [timeframeOptionsForExcludeModal, setTimeframeOptionsForExcludeModal] = useState([]);

		const [selectedStatus, setSelectedStatus] = useState(null);
		const [annotationClass, setAnnotationClass] = useState(null);
		const [selectedTimeframe, setSelectedTimeframe] = useState(null);
		const [selectedDateTime, setSelectedDateTime] = useState(null);

		const [highlightedFeatures, setHighlightedFeatures] = useState([]);
		const [existingFlaggedMarkedFeatures, setExistingFlaggedMarkedFeatures] = useState([]);
		const [existingExcludedMarkedFeatures, setExistingExcludedMarkedFeatures] = useState([]);
		const [featuresForDeletion, setFeaturesForDeletion] = useState({});
		const [featuresForExcludedDeletion, setFeaturesForExcludedDeletion] = useState({});

		const [permissionsStep, setPermissionsStep] = useState(steps.None);
		const [stepData, setStepData] = useState({});
		const [blockModal, setBlockModal] = useState(false);
		const [ownerModal, setOwnerModal] = useState(false);
		const [overwriteModal, setOverwriteModal] = useState(false);

		FlagPlots.displayName = "FlagPlots";
		const userAuth = useUserAuth();

		useEffect(() => {
			setSubsample(isSubsampleSelected());
		}, [draw?.current?.draw.getSelected()]);

		useEffect(() => {
			if (flagDisplayModal || excludeDisplayModal) {
				setupStatusOptions();
				setupAnnotationClassOptions();
				setupTimeframeOptionsForFlagModal();
				setupTimeframeOptionsForExcludeModal();

				setupHighlightedFeatures();
				isSubsampleSelected() ? setupExistingMarkedSubsampleList() : setupExistingMarkedPlotList();
			}
		}, [flagDisplayModal, excludeDisplayModal, markedPlotOptions, annotationClassOptions]);

		useImperativeHandle(ref, () => {
			return {
				setDisabled: (val) => {
					setDisabled(val);
				},
				checkDisabled: disabled,
				closeModal: () => {
					setFlagDisplayModal(false);
					setExcludeDisplayModal(false);
					setModalLoading(false);
				},
				isSubsample: (val) => {
					setSubsample(val);
				},
				doneDeleting: () => {
					setModalLoading(false);
					setFeaturesForDeletion([]);
					setFeaturesForExcludedDeletion([]);
				},
				removeFromList: (index) => {
					const localExistingFlaggedFeatures = _.cloneDeep(existingFlaggedMarkedFeatures);
					if (localExistingFlaggedFeatures.length > 0) {
						localExistingFlaggedFeatures.splice(index, 1);
					}
					setExistingFlaggedMarkedFeatures(localExistingFlaggedFeatures);

					const localExistingExcludedFeatures = _.cloneDeep(existingExcludedMarkedFeatures);
					if (localExistingExcludedFeatures.length > 0) {
						localExistingExcludedFeatures.splice(index, 1);
					}
					setExistingExcludedMarkedFeatures(localExistingExcludedFeatures);
				},
				showBlockModal: () => {
					setConfirmExcluded(false);
					setConfirm(false);
					setBlockModal(true);
				},
				showOwnerModal: () => {
					setConfirmExcluded(false);
					setConfirm(false);
					setOwnerModal(true);
				},
				showOverwriteModal: () => {
					setConfirmExcluded(false);
					setConfirm(false);
					setOverwriteModal(true);
				},
				passCreator: () => {
					setConfirmExcluded(false);
					setConfirm(false);
					permissionsConfirm();
				}
			};
		});

		const setupStatusOptions = () => {
			let opts = _.map(markedPlotOptions(), (mpo) => {
				return {
					text: mpo.name,
					key: mpo.id,
					value: mpo.id
				};
			});

			setStatusOptions(opts);
		};

		const setupAnnotationClassOptions = () => {
			let opts = _.map(annotationClassOptions(), (aco) => {
				return {
					text: aco.annotationClassifier,
					key: aco.id,
					value: aco.id
				};
			});

			opts.push(opts.splice(opts.indexOf(opts.find((x) => x.text === "Unknown")), 1)[0]);
			setAnnotationClassOpts(opts);
		};

		const setupTimeframeOptionsForFlagModal = () => {
			let opts = [
				{ key: "All Data", value: "All Data", text: "All Data" },
				{ key: "This Flight Onward", value: "This Flight Onward", text: "This Flight Onward" },
				{ key: "Custom Date", value: "Custom Date", text: "Custom Date" }
			];

			setTimeframeOptionsForFlagModal(opts);
		};

		const setupTimeframeOptionsForExcludeModal = () => {
			let opts = [
				{ key: "All Data", value: "All Data", text: "All Data" },
				{ key: "This Flight Onward", value: "This Flight Onward", text: "This Flight Onward" },
				{ key: "Custom Date", value: "Custom Date", text: "Custom Date" },
				{ key: "One Time", value: "One Time", text: "One Time", disabled: false }
			];

			setTimeframeOptionsForExcludeModal(opts);
		};

		const setupExistingMarkedPlotList = () => {
			let localMarkedPlots = markedPlots();
			let subsamples = getSubsamples();
			let localMarkedSubsamples = markedSubsamples();

			let lmsIds = _.map(localMarkedSubsamples, "quantifiedRegionId");
			let filteredSubsamples = _.filter(subsamples, (s) => lmsIds.includes(s.properties.quantifiedRegionId));
			localMarkedSubsamples = _.map(localMarkedSubsamples, (lms) => {
				let found = _.find(filteredSubsamples, { properties: { quantifiedRegionId: lms.quantifiedRegionId } });

				return {
					...lms,
					plotId: found.properties.plotId,
					plotName: found.properties.plotName,
					quantifiedRegionTypeName: found.properties.quantifiedRegionTypeName,
					quantifiedRegionName: found.properties.quantifiedRegionTypeName + " " + found.properties.quantifiedRegionName
				};
			});

			let selectedFeatures = draw.current.draw.getSelected().features;
			let plotFeatures = _.filter(selectedFeatures, (feature) => {
				return feature.properties.type === "plot";
			});

			let flightOptions = dropdownRef.current.innerRef.current.getFlightOptions;
			let selectedMarkedPlots = _.filter(localMarkedPlots, (lmp) => _.map(plotFeatures, "id").includes(lmp.plotId));
			let selectedMarkedSubsamples = _.filter(localMarkedSubsamples, (lms) =>
				_.map(plotFeatures, "id").includes(lms.plotId)
			);

			let existingMarkedPlots = _.map(selectedMarkedPlots, (smp) => {
				return {
					dateAndTime: smp.dateAndTime,
					displayDateAndTime: _.find(flightOptions, { flightId: smp.flightId })?.flightDateAndTime,
					plotId: smp.plotId,
					flightId: smp.flightId,
					isAllData: smp.isAllData,
					notes: smp.notes,
					plotStatusId: smp.plotStatusId,
					plotStatusName: smp.plotStatusName,
					featureName: "Plot " + (_.find(plotFeatures, { id: smp.plotId })?.properties.name ?? ""),
					trialId: smp.trialId,
					annotationClassifier: smp.annotationClassifier,
					oneTime: smp.oneTime,
					modifiedBy: smp.modifiedBy,
					modifiedDateTime: moment(smp.modifiedDateTime).local().format("MM/DD/YY")
				};
			});

			let existingMarkedSubsamples = _.map(selectedMarkedSubsamples, (sms) => {
				return {
					dateAndTime: sms.dateAndTime,
					displayDateAndTime: _.find(flightOptions, { flightId: sms.flightId })?.flightDateAndTime,
					quantifiedRegionId: sms.quantifiedRegionId,
					flightId: sms.flightId,
					isAllData: sms.isAllData,
					notes: sms.notes,
					plotStatusId: sms.quantifiedRegionStatusId,
					plotStatusName: sms.quantifiedRegionStatusName,
					featureName: "Plot " + (sms.plotName ?? "") + " " + sms.quantifiedRegionName,
					parentPlotId: sms.plotId,
					trialId: sms.trialId,
					annotationClassifier: sms.annotationClassifier,
					oneTime: sms.oneTime,
					modifiedBy: sms.modifiedBy,
					modifiedDateTime: moment(sms.modifiedDateTime).local().format("MM/DD/YY")
				};
			});

			let flaggedExistingMarkedPlots = _.filter(existingMarkedPlots, (emp) => {
				return emp.plotStatusName === "Flagged";
			});
			let flaggedExistingMarkedSubsamples = _.filter(existingMarkedSubsamples, (emss) => {
				return emss.plotStatusName === "Flagged";
			});

			let excludedExistingMarkedPlots = _.filter(existingMarkedPlots, (emp) => {
				return emp.plotStatusName === "Excluded";
			});
			let excludedExistingMarkedSubsamples = _.filter(existingMarkedSubsamples, (emss) => {
				return emss.plotStatusName === "Excluded";
			});

			flaggedExistingMarkedPlots = _.orderBy(flaggedExistingMarkedPlots, ["featureName", "plotStatusName"]);
			flaggedExistingMarkedSubsamples = _.orderBy(flaggedExistingMarkedSubsamples, ["featureName", "plotStatusName"]);

			excludedExistingMarkedPlots = _.orderBy(excludedExistingMarkedPlots, ["featureName", "plotStatusName"]);
			excludedExistingMarkedSubsamples = _.orderBy(excludedExistingMarkedSubsamples, ["featureName", "plotStatusName"]);

			setSelectedTimeframe("All Data");

			let dateTime = moment(plotFeatures[0]?.properties.flightDate).local();
			setSelectedDateTime(dateTime?._d);
			setExistingFlaggedMarkedFeatures([...flaggedExistingMarkedPlots, ...flaggedExistingMarkedSubsamples]);
			setExistingExcludedMarkedFeatures([...excludedExistingMarkedPlots, ...excludedExistingMarkedSubsamples]);
		};

		const setupExistingMarkedSubsampleList = () => {
			let localMarkedSubsamples = markedSubsamples();

			let selectedFeatures = draw.current.draw.getSelected().features;
			let subsampleFeatures = _.filter(selectedFeatures, (feature) => {
				return feature.properties.type === "subsample";
			});

			let flightOptions = dropdownRef.current.innerRef.current.getFlightOptions;
			let selectedMarkedSubsamples = _.filter(localMarkedSubsamples, (lmp) =>
				_.map(subsampleFeatures, "properties.quantifiedRegionId").includes(lmp.quantifiedRegionId)
			);

			let existingMarkedSubsamples = _.map(selectedMarkedSubsamples, (smp) => {
				let matchingSubsample = _.find(
					subsampleFeatures,
					(ems) => ems.properties.quantifiedRegionId === smp.quantifiedRegionId
				);
				let subsampleName = `${matchingSubsample.properties.quantifiedRegionTypeName} ${matchingSubsample.properties.quantifiedRegionName}`;

				return {
					dateAndTime: smp.dateAndTime,
					displayDateAndTime: _.find(flightOptions, { flightId: smp.flightId })?.flightDateAndTime,
					quantifiedRegionId: smp.quantifiedRegionId,
					flightId: smp.flightId,
					isAllData: smp.isAllData,
					notes: smp.notes,
					plotStatusId: smp.quantifiedRegionStatusId,
					plotStatusName: smp.quantifiedRegionStatusName,
					featureName: subsampleName,
					parentPlotId: matchingSubsample?.properties.plotId,
					annotationClassifier: smp.annotationClassifier,
					oneTime: smp.oneTime,
					modifiedBy: smp.modifiedBy,
					modifiedDateTime: moment(smp.modifiedDateTime).local().format("MM/DD/YY")
				};
			});

			let existingFlaggedMarkedSubsamples = _.filter(existingMarkedSubsamples, (emss) => {
				return emss.plotStatusName === "Flagged";
			});
			let existingExcludedMarkedSubsamples = _.filter(existingMarkedSubsamples, (emss) => {
				return emss.plotStatusName === "Excluded";
			});
			existingFlaggedMarkedSubsamples = _.orderBy(existingFlaggedMarkedSubsamples, ["featureName", "plotStatusName"]);
			existingExcludedMarkedSubsamples = _.orderBy(existingExcludedMarkedSubsamples, ["featureName", "plotStatusName"]);

			setSelectedTimeframe("All Data");

			let dateTime = moment(subsampleFeatures[0]?.properties.flightDate).local();
			setSelectedDateTime(dateTime?._d);
			setExistingFlaggedMarkedFeatures(existingFlaggedMarkedSubsamples);
			setExistingExcludedMarkedFeatures(existingExcludedMarkedSubsamples);
		};

		const setupHighlightedFeatures = () => {
			let selectedFeatures = draw.current.draw.getSelected().features;

			let plotFeatures = _.filter(selectedFeatures, { properties: { type: "plot" } });
			let subsampleFeatures = _.filter(selectedFeatures, { properties: { type: "subsample" } });

			let plotNames = [];
			let subsampleNames = [];

			for (let plot of plotFeatures) {
				plotNames.push("Plot " + (plot.properties.name ?? "") + " (whole plot)");
			}

			const splitSubsampleFeatures = _.groupBy(subsampleFeatures, "properties.plotId");
			for (let plotId of Object.keys(splitSubsampleFeatures)) {
				let subsampleNumbers = _.map(splitSubsampleFeatures[plotId], "properties.quantifiedRegionNumber");

				subsampleNumbers.sort((a, b) => a - b);

				// taken from https://stackoverflow.com/questions/2270910/how-to-reduce-consecutive-integers-in-an-array-to-hyphenated-range-expressions
				const getRanges = (array) => {
					var ranges = [],
						rstart,
						rend;
					for (var i = 0; i < array.length; i++) {
						rstart = array[i];
						rend = rstart;
						while (array[i + 1] - array[i] == 1) {
							rend = array[i + 1]; // increment the index if the numbers sequential
							i++;
						}
						ranges.push(rstart == rend ? "S" + rstart + "" : "S" + rstart + "–S" + rend);
					}
					return ranges;
				};

				subsampleNames.push(
					`Plot ${splitSubsampleFeatures[plotId][0].properties.plotName ?? ""} (${getRanges(subsampleNumbers).join(
						", "
					)})`
				);
			}

			setHighlightedFeatures([...plotNames, ...subsampleNames].sort());
		};

		const isSubsampleSelected = () => {
			if (draw.current) {
				let selectedFeatures = draw.current.draw.getSelected().features;
				if (selectedFeatures.length > 0) {
					let featureTypes = _.map(selectedFeatures, "properties.type");
					return _.some(featureTypes, (ft) => ft === "subsample");
				} else {
					return false;
				}
			}
		};

		const handleDeleteFlaggedFeatures = (features) => {
			const localExistingFeatures = _.cloneDeep(existingFlaggedMarkedFeatures);
			const indices = Object.keys(features);
			indices.sort((a, b) => b - a);

			for (let index of indices) {
				localExistingFeatures.splice(index, 1);
			}
			deleteExistingFeatures(Object.values(features));

			setExistingFlaggedMarkedFeatures(localExistingFeatures);
		};

		const handleDeleteExcludedFeatures = (features) => {
			const localExistingFeatures = _.cloneDeep(existingExcludedMarkedFeatures);
			const indices = Object.keys(features);
			indices.sort((a, b) => b - a);

			for (let index of indices) {
				localExistingFeatures.splice(index, 1);
			}
			deleteExistingFeatures(Object.values(features));

			setExistingExcludedMarkedFeatures(localExistingFeatures);
		};

		const selectFeatureForDeletion = (feature, index) => {
			let localFeaturesForDeletion = { ...featuresForDeletion };
			localFeaturesForDeletion[index] = feature;
			setFeaturesForDeletion(localFeaturesForDeletion);
		};

		const unselectFeatureForDeletion = (index) => {
			let localFeaturesForDeletion = { ...featuresForDeletion };
			delete localFeaturesForDeletion[index];
			setFeaturesForDeletion(localFeaturesForDeletion);
		};

		const selectFeatureForExcludedDeletion = (feature, index) => {
			let localFeaturesForDeletion = { ...featuresForExcludedDeletion };
			localFeaturesForDeletion[index] = feature;
			setFeaturesForExcludedDeletion(localFeaturesForDeletion);
		};

		const unselectFeatureForExcludedDeletion = (index) => {
			let localFeaturesForDeletion = { ...featuresForExcludedDeletion };
			delete localFeaturesForDeletion[index];
			setFeaturesForExcludedDeletion(localFeaturesForDeletion);
		};

		const checkDeleteAnnotationPermissions = (features) => {
			let plotsToDelete = _.filter(Object.values(features), (f) => f.plotId);
			let quantifiedRegionsToDelete = _.filter(Object.values(features), (f) => f.quantifiedRegionId);
			checkAnnotationPermissions(plotsToDelete, quantifiedRegionsToDelete, "delete");
		};

		const checkSaveAnnotationPermissions = (featureProps) => {
			let selectedFeatures = draw.current.draw.getSelected().features;
			let plotIds = _.map(
				_.filter(selectedFeatures, (f) => f.properties.type === "plot"),
				"id"
			);
			let quantifiedRegionIds = _.map(
				_.filter(selectedFeatures, (f) => f.properties.type === "subsample"),
				"properties.quantifiedRegionId"
			);

			let plots = _.map(plotIds, (p) => {
				return {
					plotId: p,
					plotStatusId: featureProps.plotStatusId,
					dateAndTime: featureProps.timeframe === "All Data" ? null : featureProps.dateAndTime,
					isAllData: featureProps.timeframe === "All Data",
					flightId: featureProps.timeframe === "All Data" || featureProps.timeframe === "Custom" ? null : getFlightId()
				};
			});

			let quantifiedRegions = _.map(quantifiedRegionIds, (qr) => {
				return {
					quantifiedRegionId: qr,
					plotStatusId: featureProps.plotStatusId,
					dateAndTime: featureProps.timeframe === "All Data" ? null : featureProps.dateAndTime,
					isAllData: featureProps.timeframe === "All Data",
					flightId: featureProps.timeframe === "All Data" || featureProps.timeframe === "Custom" ? null : getFlightId()
				};
			});

			checkAnnotationPermissions(plots, quantifiedRegions, "add");
		};

		const permissionsConfirm = () => {
			if (permissionsStep === steps.DeleteExcludedFeatures) {
				handleDeleteExcludedFeatures(featuresForExcludedDeletion);
			} else if (permissionsStep === steps.DeleteFlaggedFeatures) {
				handleDeleteFlaggedFeatures(featuresForDeletion);
			} else if (permissionsStep === steps.ApplyChanges) {
				let { dataToPass, notesToPass } = stepData;

				applyChanges(dataToPass, notesToPass);
				setNotes(null);
				setSubsample(null);
				setSelectedStatus(null);
				setSelectedTimeframe(null);
				setSelectedDateTime(null);
				setAnnotationClass(null);
			}

			setOwnerModal(false);
			setBlockModal(false);
			setOverwriteModal(false);
			setPermissionsStep(steps.None);
			setStepData({});
		};

		return (
			<>
				{(level === "trial" &&
					userAuth.hasApplicationArea(ApplicationArea.TrialFlagPlotsCRUD, userAuth.currentClientId)) ||
				(level === "field" &&
					userAuth.hasApplicationArea(ApplicationArea.FieldFlagPlotsCRUD, userAuth.currentClientId)) ||
				(level === "farm" &&
					userAuth.hasApplicationArea(ApplicationArea.FarmFlagPlotsCRUD, userAuth.currentClientId)) ? (
					<Button
						className="flagPlotsButton"
						icon
						compact
						onClick={() => {
							setFlagDisplayModal(true);
							let status = _.find(statusOptions, (so) => {
								return so.text === "Flagged";
							});
							setSelectedStatus(status?.key);
							setFeaturesForDeletion({});
						}}
						disabled={disabled}
					>
						<Icon name="flag" size="large" />
						<span>Assign Flags</span>
					</Button>
				) : null}
				<Modal open={flagDisplayModal} key={uuid.v4()}>
					<Modal.Header>
						Assign a Flag to Selected {subsample ? "Quantified Region" : "Plot"} Statuses
						<Icon
							link
							name="close"
							onClick={() => {
								setFlagDisplayModal(false);
							}}
						/>
					</Modal.Header>
					<Modal.Content>
						<Grid divided>
							<Grid.Column width="8">
								<div>
									<Header style={{ marginBottom: 0 }}>Current Selection</Header>
									<p>{highlightedFeatures.length > 0 ? highlightedFeatures.join(", ") : "None"}</p>
								</div>
								<Divider />
								<Form>
									<Grid>
										<Grid.Row>
											<Grid.Column>
												<Form.Field>
													<label>Flag</label>
													<Dropdown
														search
														selection
														fluid
														options={timeframeOptionsForFlagModal}
														value={selectedTimeframe ?? timeframeOptionsForFlagModal[0]?.key}
														disabled={selectedTimeframe === "One Time"}
														onChange={(e, { value }) => {
															setSelectedTimeframe(value);

															if (value !== "Custom Date") {
																setSelectedDateTime(null);
															}
															let status = _.find(statusOptions, (so) => {
																return so.text === "Flagged";
															});
															setSelectedStatus(status?.key);
														}}
													/>
												</Form.Field>
											</Grid.Column>
										</Grid.Row>
										{selectedTimeframe === "Custom Date" && (
											<Grid.Row>
												<Grid.Column>
													<Form.Field>
														<DateTimePicker
															value={selectedDateTime}
															onChange={(date) => {
																if (date) {
																	setSelectedDateTime(date);
																} else {
																	setSelectedDateTime(null);
																}
															}}
														/>
													</Form.Field>
												</Grid.Column>
											</Grid.Row>
										)}
										<Grid.Row>
											<Grid.Column>
												<Form.Field>
													<label>Annotation Class</label>
													<Dropdown
														search
														selection
														fluid
														options={annotationClassOpts}
														value={
															annotationClass ??
															_.find(annotationClassOpts, (aco) => {
																return aco.text === "Unassigned";
															})?.key
														}
														onChange={(e, { value }) => {
															setAnnotationClass(value);
														}}
													/>
												</Form.Field>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column>
												<Input
													id="flagged-notes"
													fluid
													placeholder="Notes..."
													defaultValue={notes ? notes : ""}
													disabled={modalLoading}
													onBlur={(event) => {
														setNotes(event.target.value);
													}}
												/>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Form>
							</Grid.Column>
							<Grid.Column width="8">
								<Segment basic style={{ padding: "0px", display: "flex", flexDirection: "column", height: "100%" }}>
									<div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
										<Header style={{ marginBottom: 0 }}>Existing Statuses in Selection</Header>
										<div>
											{
												<Button
													loading={modalLoading}
													content={
														Object.keys(featuresForDeletion).length === 0
															? "Delete"
															: "Delete " +
															  (Object.keys(featuresForDeletion).length === existingFlaggedMarkedFeatures.length
																	? "All"
																	: Object.keys(featuresForDeletion).length)
													}
													negative
													disabled={Object.keys(featuresForDeletion).length === 0}
													onClick={() => {
														setConfirm(true);
													}}
												/>
											}
											{Object.keys(featuresForDeletion).length === 0 ? (
												<Icon
													name="circle outline"
													size="large"
													onClick={() => {
														setFeaturesForDeletion({ ...existingFlaggedMarkedFeatures });
													}}
												/>
											) : (
												<Icon
													name="minus circle"
													size="large"
													onClick={() => {
														setFeaturesForDeletion({});
													}}
												/>
											)}
										</div>
									</div>
									<Divider />
									<div style={{ flexGrow: 1, minHeight: "150px" }}>
										<List
											divided
											selection
											style={{ position: "absolute", overflowY: "auto", width: "100%", height: "calc(100% - 66px)" }}
										>
											{_.map(existingFlaggedMarkedFeatures, (emf, i) => (
												<List.Item style={{ display: "flex", alignItems: "center" }} key={i}>
													<List.Content>
														<List.Header>
															{emf.featureName ? `${emf.featureName} - ` : ""}
															{emf.plotStatusName}
														</List.Header>
														<List.Description>
															{emf.isAllData
																? "All Data"
																: moment(emf.dateAndTime ?? emf.displayDateAndTime)
																		.local()
																		.format("MM/DD/YY")}{" "}
															{!emf.isAllData ? "(onward)" : null} - {emf.annotationClassifier}
															{emf.notes ? " - " + emf.notes : ""}
														</List.Description>
														<List.Description>
															{`Added by: ${emf.modifiedBy} on ${emf.modifiedDateTime}`}
														</List.Description>
													</List.Content>
													<div style={{ flexGrow: 2 }} />
													<List.Content>
														{featuresForDeletion[i] === undefined ? (
															<Icon
																name="circle outline"
																size="large"
																onClick={() => selectFeatureForDeletion(emf, i)}
															/>
														) : (
															<Icon name="minus circle" size="large" onClick={() => unselectFeatureForDeletion(i)} />
														)}
													</List.Content>
												</List.Item>
											))}
										</List>
									</div>
								</Segment>
							</Grid.Column>
						</Grid>
					</Modal.Content>
					<Modal.Actions>
						<Button
							loading={modalLoading}
							disabled={modalLoading}
							type="button"
							primary
							onMouseDown={() => {
								let tempNote = document.getElementById("flagged-notes").value;
								setModalLoading(true);
								let selectedStatusClone = _.cloneDeep(selectedStatus);
								let status = _.find(statusOptions, (so) => {
									return so.text === "Flagged";
								});
								selectedStatusClone = status;

								let dataToPass = {
									plotStatusId: _.cloneDeep(
										_.find(statusOptions, (so) => {
											return so.text === "Flagged";
										})?.key
									),
									plotStatusName: _.find(markedPlotOptions(), { id: selectedStatusClone })?.text ?? "Flagged",
									timeframe: _.cloneDeep(selectedTimeframe ?? timeframeOptionsForFlagModal[0]?.key),
									dateAndTime:
										selectedTimeframe === "All Data"
											? new Date(Date.UTC(0, 0)).toLocaleString()
											: _.cloneDeep(selectedDateTime),
									annotationClassId: _.cloneDeep(
										annotationClass ??
											_.find(annotationClassOpts, (aco) => {
												return aco.text === "Unassigned";
											})?.key
									),
									annotationClassifier: _.cloneDeep(
										_.find(annotationClassOpts, (aco) => {
											return (
												aco.key ===
												(annotationClass ??
													_.find(annotationClassOpts, (aco) => {
														return aco.text === "Unassigned";
													})?.key)
											);
										})?.text
									),
									modifiedBy: `${userAuth.user.userInfo.basic.firstName} ${userAuth.user.userInfo.basic.lastName}`,
									modifiedDateTime: moment().utc().local().format("MM/DD/YY")
								};
								let notesToPass = _.cloneDeep(notes);
								if (!notesToPass) {
									notesToPass = tempNote;
								}
								setPermissionsStep(steps.ApplyChanges);
								setStepData({ dataToPass: dataToPass, notesToPass: notesToPass });
								checkSaveAnnotationPermissions(dataToPass);
							}}
							floated="right"
						>
							Add/Update Status
						</Button>
						<Button
							loading={modalLoading}
							disabled={modalLoading}
							type="button"
							onClick={() => {
								setFlagDisplayModal(false);
								setNotes(null);
								setSubsample(null);
								setSelectedStatus(null);
								setSelectedTimeframe(null);
								setSelectedDateTime(null);
								document.getElementById("flagged-notes").value = "";
							}}
							floated="right"
						>
							Cancel
						</Button>
						<br style={{ clear: "both" }} />
					</Modal.Actions>
				</Modal>
				<Confirm
					key={uuid.v4()}
					open={confirm}
					content="Delete all existing marked features in selection?"
					onCancel={() => {
						setConfirm(false);
					}}
					onConfirm={() => {
						//setConfirm(false);
						setModalLoading(true);
						setPermissionsStep(steps.DeleteFlaggedFeatures);
						checkDeleteAnnotationPermissions(featuresForDeletion);
					}}
				/>
				{(level === "trial" &&
					userAuth.hasApplicationArea(ApplicationArea.TrialExcludePlotsCRUD, userAuth.currentClientId)) ||
				(level === "field" &&
					userAuth.hasApplicationArea(ApplicationArea.FieldExcludePlotsCRUD, userAuth.currentClientId)) ||
				(level === "farm" &&
					userAuth.hasApplicationArea(ApplicationArea.FarmExcludePlotsCRUD, userAuth.currentClientId)) ? (
					<Button
						icon
						compact
						onClick={() => {
							setExcludeDisplayModal(true);
							let status = _.find(statusOptions, (so) => {
								return so.text === "Excluded";
							});
							setSelectedStatus(status?.key);
							setFeaturesForExcludedDeletion({});
						}}
						disabled={disabled}
					>
						<Icon style={{ color: "#DA0B0B" }} name="exclamation triangle" size="large" />
						<span style={{ color: "#DA0B0B" }}>Assign Exclusions</span>
					</Button>
				) : null}
				<Modal open={excludeDisplayModal} key={uuid.v4()}>
					<Modal.Header>
						Assign an Exclusion to Selected {subsample ? "Quantified Region" : "Plot"} Statuses
						<Icon
							link
							name="close"
							onClick={() => {
								setExcludeDisplayModal(false);
							}}
						/>
					</Modal.Header>
					<Modal.Content>
						<Grid divided>
							<Grid.Column width="8">
								<div>
									<Header style={{ marginBottom: 0 }}>Current Selection</Header>
									<p>{highlightedFeatures.length > 0 ? highlightedFeatures.join(", ") : "None"}</p>
								</div>
								<Divider />
								<Grid>
									<Grid.Row>
										<Grid.Column>
											<Form.Field>
												<label>Exclude</label>
												<Dropdown
													search
													selection
													fluid
													options={timeframeOptionsForExcludeModal}
													value={selectedTimeframe ?? timeframeOptionsForExcludeModal[0]?.key}
													onChange={(e, { value }) => {
														setSelectedTimeframe(value);

														if (value !== "Custom Date") {
															setSelectedDateTime(null);
														}
														let status = _.find(statusOptions, (so) => {
															return so.text === "Excluded";
														});
														setSelectedStatus(status?.key);
													}}
												/>
											</Form.Field>
										</Grid.Column>
									</Grid.Row>
									{selectedTimeframe === "Custom Date" && (
										<Grid.Row>
											<Grid.Column>
												<Form.Field>
													<DateTimePicker
														value={selectedDateTime}
														onChange={(date) => {
															if (date) {
																setSelectedDateTime(date);
															} else {
																setSelectedDateTime(null);
															}
														}}
													/>
												</Form.Field>
											</Grid.Column>
										</Grid.Row>
									)}
									<Grid.Row>
										<Grid.Column>
											<Form.Field>
												<label>Annotation Class</label>
												<Dropdown
													search
													selection
													fluid
													options={annotationClassOpts}
													value={
														annotationClass ??
														_.find(annotationClassOpts, (aco) => {
															return aco.text === "Unassigned";
														})?.key
													}
													onChange={(e, { value }) => {
														setAnnotationClass(value);
													}}
												/>
											</Form.Field>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<Input
												id="excluded-notes"
												fluid
												placeholder="Notes..."
												defaultValue={notes ? notes : ""}
												disabled={modalLoading}
												onBlur={(event) => {
													setNotes(event.target.value);
												}}
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
							<Grid.Column width="8">
								<Segment basic style={{ padding: "0px", display: "flex", flexDirection: "column", height: "100%" }}>
									<div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
										<Header style={{ marginBottom: 0 }}>Existing Statuses in Selection</Header>
										<div>
											{
												<Button
													loading={modalLoading}
													content={
														Object.keys(featuresForExcludedDeletion).length === 0
															? "Delete"
															: "Delete " +
															  (Object.keys(featuresForExcludedDeletion).length ===
															  existingExcludedMarkedFeatures.length
																	? "All"
																	: Object.keys(featuresForExcludedDeletion).length)
													}
													negative
													disabled={Object.keys(featuresForExcludedDeletion).length === 0}
													onClick={() => {
														setConfirmExcluded(true);
													}}
												/>
											}
											{Object.keys(featuresForExcludedDeletion).length === 0 ? (
												<Icon
													name="circle outline"
													size="large"
													onClick={() => {
														setFeaturesForExcludedDeletion({ ...existingExcludedMarkedFeatures });
													}}
												/>
											) : (
												<Icon
													name="minus circle"
													size="large"
													onClick={() => {
														setFeaturesForExcludedDeletion({});
													}}
												/>
											)}
										</div>
									</div>
									<Divider />
									<div style={{ flexGrow: 1, minHeight: "150px" }}>
										<List
											divided
											selection
											style={{ position: "absolute", overflowY: "auto", width: "100%", height: "calc(100% - 66px)" }}
										>
											{_.map(existingExcludedMarkedFeatures, (emf, i) => (
												<List.Item style={{ display: "flex", alignItems: "center" }} key={i}>
													<List.Content>
														<List.Header>
															{emf.featureName ? `${emf.featureName} - ` : ""}
															{emf.plotStatusName}
														</List.Header>
														<List.Description>
															{emf.isAllData
																? "All Data"
																: moment(emf.dateAndTime ?? emf.displayDateAndTime)
																		.local()
																		.format("MM/DD/YY")}{" "}
															{!emf.oneTime && !emf.isAllData ? "(onward)" : null} - {emf.annotationClassifier}
															{emf.notes ? " - " + emf.notes : ""}
														</List.Description>
														<List.Description>
															{`Added by: ${emf.modifiedBy} on ${emf.modifiedDateTime}`}
														</List.Description>
													</List.Content>
													<div style={{ flexGrow: 2 }} />
													<List.Content>
														{featuresForExcludedDeletion[i] === undefined ? (
															<Icon
																name="circle outline"
																size="large"
																onClick={() => selectFeatureForExcludedDeletion(emf, i)}
															/>
														) : (
															<Icon
																name="minus circle"
																size="large"
																onClick={() => unselectFeatureForExcludedDeletion(i)}
															/>
														)}
													</List.Content>
												</List.Item>
											))}
										</List>
									</div>
								</Segment>
							</Grid.Column>
						</Grid>
					</Modal.Content>
					<Modal.Actions>
						<Button
							loading={modalLoading}
							disabled={modalLoading}
							type="button"
							primary
							onMouseDown={() => {
								setModalLoading(true);
								let tempNote = document.getElementById("excluded-notes").value;
								let selectedStatusClone = _.cloneDeep(selectedStatus);
								let status = _.find(statusOptions, (so) => {
									return so.text === "Excluded";
								});
								selectedStatusClone = status;

								let dataToPass = {
									plotStatusId: _.cloneDeep(
										_.find(statusOptions, (so) => {
											return so.text === "Excluded";
										})?.key
									),
									plotStatusName: _.find(markedPlotOptions(), { id: selectedStatusClone })?.text ?? "Excluded",
									timeframe: _.cloneDeep(selectedTimeframe ?? timeframeOptionsForExcludeModal[0]?.key),
									dateAndTime:
										selectedTimeframe === "All Data"
											? new Date(Date.UTC(0, 0)).toLocaleString()
											: _.cloneDeep(selectedDateTime),
									annotationClassId: _.cloneDeep(
										annotationClass ??
											_.find(annotationClassOpts, (aco) => {
												return aco.text === "Unassigned";
											})?.key
									),
									annotationClassifier: _.cloneDeep(
										_.find(annotationClassOpts, (aco) => {
											return (
												aco.key ===
												(annotationClass ??
													_.find(annotationClassOpts, (aco) => {
														return aco.text === "Unassigned";
													})?.key)
											);
										})?.text
									),
									oneTime:
										_.cloneDeep(selectedTimeframe ?? timeframeOptionsForExcludeModal[0]?.text) === "One Time"
											? true
											: false,
									modifiedBy: `${userAuth.user.userInfo.basic.firstName} ${userAuth.user.userInfo.basic.lastName}`,
									modifiedDateTime: moment().utc().local().format("MM/DD/YY")
								};
								let notesToPass = _.cloneDeep(notes);
								if (!notesToPass && tempNote) {
									notesToPass = tempNote;
								}
								setPermissionsStep(steps.ApplyChanges);
								setStepData({ dataToPass: dataToPass, notesToPass: notesToPass });
								checkSaveAnnotationPermissions(dataToPass);
							}}
							floated="right"
						>
							Add/Update Status
						</Button>
						<Button
							loading={modalLoading}
							disabled={modalLoading}
							type="button"
							onClick={() => {
								setExcludeDisplayModal(false);
								setNotes(null);
								setSubsample(null);
								setSelectedStatus(null);
								setSelectedTimeframe(null);
								setSelectedDateTime(null);
								setAnnotationClass(null);
								document.getElementById("excluded-notes").value = "";
							}}
							floated="right"
						>
							Cancel
						</Button>
						<br style={{ clear: "both" }} />
					</Modal.Actions>
				</Modal>
				<Confirm
					key={uuid.v4()}
					open={confirmExcluded}
					content="Delete all existing marked features in selection?"
					onCancel={() => {
						setConfirmExcluded(false);
					}}
					onConfirm={() => {
						setModalLoading(true);
						setPermissionsStep(steps.DeleteExcludedFeatures);
						checkDeleteAnnotationPermissions(featuresForExcludedDeletion);
					}}
				/>
				<Modal open={blockModal}>
					<Modal.Header>Annotation Permissions</Modal.Header>
					<Modal.Content>
						Sorry, you are not allowed to overwrite/delete annotations that you did not create. Please contact the trial
						owner or an aerialPLOT admin if you wish to make a change to these annotations.
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								setBlockModal(false);
								setModalLoading(false);
							}}
						>
							Cancel
						</Button>
					</Modal.Actions>
				</Modal>
				<Modal open={ownerModal}>
					<Modal.Header>Annotation Permissions</Modal.Header>
					<Modal.Content>
						Attempting to overwrite/delete annotations that you did not create. Are you sure you want to make these
						changes?
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								setOwnerModal(false);
								setModalLoading(false);
							}}
						>
							Cancel
						</Button>
						<Button positive onClick={() => permissionsConfirm()}>
							Confirm
						</Button>
					</Modal.Actions>
				</Modal>
				<Modal open={overwriteModal}>
					<Modal.Header>Annotation Permissions</Modal.Header>
					<Modal.Content>
						Attempting to overwrite existing annotations. Cannot have multiple annotations of the same type on the same
						region and date range. Do you want to continue with these changes?
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								setOverwriteModal(false);
								setModalLoading(false);
							}}
						>
							Cancel
						</Button>
						<Button positive onClick={() => permissionsConfirm()}>
							Confirm
						</Button>
					</Modal.Actions>
				</Modal>
			</>
		);
	}
);

FlagPlots.propTypes = {
	markedPlotOptions: PropTypes.func.isRequired,
	applyChanges: PropTypes.func.isRequired,
	draw: PropTypes.object,
	markedPlots: PropTypes.func.isRequired,
	flightId: PropTypes.func.isRequired,
	dropdownRef: PropTypes.object,
	markedSubsamples: PropTypes.func,
	deleteExistingFeatures: PropTypes.func,
	getSubsamples: PropTypes.func,
	annotationClassOptions: PropTypes.func.isRequired,
	level: PropTypes.string,
	getFlightId: PropTypes.func,
	checkAnnotationPermissions: PropTypes.func
};

export const AlignPlots = React.forwardRef(({ alignPlotsModal }, ref) => {
	const [disabled, setDisabled] = useState(true);
	AlignPlots.displayName = "AlignPlots";
	const userAuth = useUserAuth();

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			checkDisabled: disabled
		};
	});

	return (
		<Button
			icon
			compact
			onClick={() => {
				alignPlotsModal.current.handleClick();
			}}
			disabled={disabled || !userAuth.hasApplicationArea(ApplicationArea.FlightAlignFields, userAuth.currentClientId)}
		>
			<Icon name={"magic"} size="large" />
			<span>Align Plots</span>
		</Button>
	);
});

AlignPlots.propTypes = {
	alignPlotsModal: PropTypes.object.isRequired
};

export const ApplyShader = React.forwardRef(
	(
		{
			removeShader,
			applyThermalShader,
			applyRYGShader,
			applyGreenFireBlueShader,
			applySpectralShader,
			applyBlueYellowShader,
			applyClampedShader
		},
		ref
	) => {
		const [open, setOpen] = useState(false);
		const [selectedShader, setSelectedShader] = useState(null);
		const [disabled, setDisabled] = useState(true);

		useImperativeHandle(ref, () => {
			return {
				setDisabled: (val) => {
					setDisabled(val);
				},
				setSelectedShader: (val) => {
					setSelectedShader(val);
				}
			};
		});

		ApplyShader.displayName = "ApplyShader";

		const isNumeric = (n) => {
			return !isNaN(parseFloat(n)) && isFinite(n);
		};

		const validateThresholds = () => {
			let valid = true;
			const min = getMinThreshold();
			const max = getMaxThreshold();

			if (!isNumeric(min) || !isNumeric(max)) {
				toast.error("Min and max thresholds must be numeric");
				return false;
			}

			if (min < 0 || min > 1) {
				toast.error("Min threshold must be between 0 and 1");
				valid = false;
			}

			if (max < 0 || max > 1) {
				toast.error("Max threshold must be between 0 and 1");
				valid = false;
			}

			if (min > max) {
				toast.error("Max threshold must be higher than min threshold");
				valid = false;
			}

			return valid;
		};

		return (
			<>
				<Popup
					position="right center"
					offset={[0, 14]}
					flowing
					style={{
						margin: "0px",
						position: "relative",
						padding: "0px",
						zIndex: "2",
						backgroundColor: "var(--background)",
						boxShadow: "var(--map-box-shadow)",
						border: "none",
						borderRadius: "8px",
						overflow: "hidden"
					}}
					basic
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					trigger={
						<Button
							icon
							compact
							onClick={() => {
								setSelectedShader(getActiveShader());
							}}
							disabled={disabled}
							toggle
							active={open}
						>
							<Icon name={"paint brush"} size="large" />
							<span>Image Shaders</span>
						</Button>
					}
					on="click"
				>
					<div
						className="popupBar"
						// onPointerDown={() => {
						// 	setIsDraggingWindow(true);
						// }}
					>
						<span style={{ fontSize: 14, fontWeight: 500, marginLeft: 7, pointerEvents: "none", lineHeight: "25px" }}>
							Apply Shaders
						</span>
						<div />
						<Icon
							name="close"
							onClick={() => {
								setOpen(false);
							}}
						/>
					</div>
					<Grid verticalAlign="middle" columns="equal">
						<Grid.Row divided style={{ padding: "14px 16px 16px" }}>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Original Image</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"repeating-linear-gradient(45deg, rgba(0,0,0,0), rgba(0,0,0,0) 5%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 10%)",
											height: 36
										}}
										onClick={() => {
											setMinThreshold(0);
											setMaxThreshold(1);
											removeShader();
											setSelectedShader("");
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Grayscale</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "clamped" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%)",
											height: 36
										}}
										onClick={() => {
											if (validateThresholds()) {
												applyClampedShader(getMinThreshold(), getMaxThreshold());
												setSelectedShader("clamped");
											}
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Red Yellow Green</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "ryg" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 50%, rgba(0,255,0,1) 100%)",
											height: 36
										}}
										onClick={() => {
											if (validateThresholds()) {
												applyRYGShader(getMinThreshold(), getMaxThreshold());
												setSelectedShader("ryg");
											}
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Thermal</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "thermal" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(75,0,130,1) 25%, rgba(255,0,0,1) 50%, rgba(255,255,0,1) 75%, rgba(255,255,255,1) 100%)",
											height: 36
										}}
										onClick={() => {
											if (validateThresholds()) {
												applyThermalShader(getMinThreshold(), getMaxThreshold());
												setSelectedShader("thermal");
											}
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Green Fire Blue</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "gfb" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,169,255,1) 25%, rgba(0,255,0,1) 50%, rgba(255,255,0,1) 75%, rgba(255,255,255,1) 100%)",
											height: 36
										}}
										onClick={() => {
											if (validateThresholds()) {
												applyGreenFireBlueShader(getMinThreshold(), getMaxThreshold());
												setSelectedShader("gfb");
											}
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Spectral</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "spectral" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"linear-gradient(90deg, rgba(102,0,0,1) 0%, rgba(255,0,0,1) 12%, rgba(255,136,0,1) 25%, rgba(255,255,0,1) 50%, rgba(0,255,0,1) 68%, rgba(0,255,255,1) 85%, rgba(0,0,255,1) 100%)",
											height: 36
										}}
										onClick={() => {
											if (validateThresholds()) {
												applySpectralShader(getMinThreshold(), getMaxThreshold());
												setSelectedShader("spectral");
											}
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Blue Yellow</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedShader === "by" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(157,2,215,1) 17%, rgba(205,52,181,1) 33%, rgba(234,95,148,1) 50%, rgba(250,135,117,1) 67%, rgba(255,177,78,1) 83%, rgba(255,215,0,1) 100%)",
											height: 36
										}}
										onClick={() => {
											if (validateThresholds()) {
												applyBlueYellowShader(getMinThreshold(), getMaxThreshold());
												setSelectedShader("by");
											}
										}}
									/>
								</Tooltip>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Popup>
			</>
		);
	}
);

ApplyShader.propTypes = {
	removeShader: PropTypes.func,
	applyRYGShader: PropTypes.func,
	applyThermalShader: PropTypes.func,
	applyGreenFireBlueShader: PropTypes.func,
	applySpectralShader: PropTypes.func,
	applyBlueYellowShader: PropTypes.func,
	applyClampedShader: PropTypes.func
};

export const ShaderHistogram = React.forwardRef(
	(
		{
			applyClampedShader,
			applyRYGShader,
			applyThermalShader,
			applyGreenFireBlueShader,
			applySpectralShader,
			applyBlueYellowShader,
			dropdownRef
		},
		ref
	) => {
		const initAutoThresh = 5000;

		const [open, setOpen] = useState(false);
		const [minClamp, setMinClamp] = useState(0.0);
		const [maxClamp, setMaxClamp] = useState(1.0);
		const [minInput, setMinInput] = useState(0);
		const [maxInput, setMaxInput] = useState(1);
		const [minInputErr, setMinInputErr] = useState(false);
		const [maxInputErr, setMaxInputErr] = useState(false);
		const [histogramBuckets, setHistogramBuckets] = useState([]);
		const [autoThresh, setAutoThresh] = useState(initAutoThresh);

		const [disabled, setDisabled] = useState(true);

		useImperativeHandle(ref, () => {
			return {
				setDisabled: (val) => {
					setDisabled(val);
				}
			};
		});

		ShaderHistogram.displayName = "ShaderHistogram";

		const autoAdjust = useCallback(() => {
			let autoMin = 0;
			let autoMax = 0;
			let size = _.reduce(histogramBuckets, (sum, a) => sum + a, 0);
			setAutoThresh(parseInt(autoThresh < 10 ? initAutoThresh : autoThresh / 2));
			let lim = parseInt(size / 10);
			let thresh = parseInt(size / autoThresh);

			let count = 0;
			let i = 0;
			while (i < 255 && count <= thresh) {
				i++;
				count = histogramBuckets[i];
				if (count > lim) {
					count = 0;
				}
			}
			autoMin = i;

			count = 0;
			i = 255;
			while (i > 0 && count <= thresh) {
				i--;
				count = histogramBuckets[i];
				if (count > lim) {
					count = 0;
				}
			}
			autoMax = i;

			if (autoMax < autoMin) {
				autoMin = 0;
				autoMax = 255;
				setAutoThresh(initAutoThresh);
			}

			return [autoMin, autoMax];
		}, [histogramBuckets, autoThresh]);

		const applyClamp = (min, max) => {
			const activeShader = getActiveShader();
			if (validateThresholds()) {
				switch (activeShader) {
					case "thermal":
						applyThermalShader(min, max);
						break;
					case "ryg":
						applyRYGShader(min, max);
						break;
					case "gfb":
						applyGreenFireBlueShader(min, max);
						break;
					case "spectral":
						applySpectralShader(min, max);
						break;
					case "by":
						applyBlueYellowShader(min, max);
						break;
					case "clamped":
						applyClampedShader(min, max);
						break;
					default:
						applyClampedShader(min, max);
						break;
				}
			}
		};

		const resetAdjust = () => {
			setAutoThresh(initAutoThresh);
			setMinClamp(0.0);
			setMaxClamp(1.0);
			setMinInput(0);
			setMaxInput(1);
			applyClamp(0, 1);
		};

		const isNumeric = (n) => {
			return !isNaN(parseFloat(n)) && isFinite(n);
		};

		const validateThresholds = () => {
			let valid = true;

			if (!isNumeric(minClamp) || !isNumeric(maxClamp)) {
				toast.error("Min and max thresholds must be numeric");
				return false;
			}

			if (minClamp < 0 || minClamp > 1) {
				toast.error("Min threshold must be between 0 and 1");
				valid = false;
			}

			if (maxClamp < 0 || maxClamp > 1) {
				toast.error("Max threshold must be between 0 and 1");
				valid = false;
			}

			if (minClamp > maxClamp) {
				toast.error("Max threshold must be higher than min threshold");
				valid = false;
			}

			return valid;
		};

		const handleSet = () => {
			if (dropdownRef.current) {
				setHistogramBuckets(dropdownRef.current.innerRef.current.getImageHistogram()); // only works for 8-bit color
			}

			const max = getMaxThreshold();
			const min = getMinThreshold();

			setMinClamp(min);
			setMinInput(min);
			setMaxClamp(max);
			setMaxInput(max);
			setMinInputErr(false);
			setMaxInputErr(false);
			if (min === 0 && max === 1) setAutoThresh(initAutoThresh);
		};

		return (
			<>
				<Popup
					position="right center"
					offset={[0, 14]}
					flowing
					style={{
						margin: "0px",
						position: "relative",
						padding: "0px",
						zIndex: "2",
						backgroundColor: "var(--background)",
						boxShadow: "var(--map-box-shadow)",
						border: "none",
						borderRadius: "8px",
						overflow: "hidden"
					}}
					basic
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					trigger={
						<Button
							icon
							compact
							onClick={() => {
								handleSet();
							}}
							disabled={disabled}
							toggle
							active={open}
						>
							<Icon name={"chart area"} size="large" />
							<span>Image Levels</span>
						</Button>
					}
					on="click"
				>
					<div
						className="popupBar"
						// onPointerDown={() => {
						// 	setIsDraggingWindow(true);
						// }}
					>
						<span style={{ fontSize: 14, fontWeight: 500, marginLeft: 7, pointerEvents: "none", lineHeight: "25px" }}>
							Adjust Histogram Levels
						</span>
						<div />
						<Icon
							name="close"
							onClick={() => {
								setOpen(false);
							}}
						/>
					</div>
					<Form onSubmit={() => {}}>
						<Grid style={{ padding: "14px 16px 16px" }}>
							<Grid.Row>
								<Grid.Column>
									<Histogram
										skipLow
										skipHigh
										buckets={histogramBuckets}
										slider
										sliderValues={[minClamp * 255, maxClamp * 255]}
										onSliderChange={(v) => {
											setMinClamp(v[0] / 255);
											setMaxClamp(v[1] / 255);
											setMinInput(Math.round((v[0] / 255) * 100) / 100);
											setMaxInput(Math.round((v[1] / 255) * 100) / 100);
											setMinInputErr(false);
											setMaxInputErr(false);
											applyClamp(v[0] / 255, v[1] / 255);
										}}
										rootStyle={{ height: histogramBuckets.length > 0 && 150 }}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row columns={2}>
								<Grid.Column>
									<Form.Input
										size="mini"
										style={{ width: 50 }}
										type="text"
										value={minInput}
										error={minInputErr}
										onChange={(e) => {
											setMinInput(e.target.value);
											let v = null;
											if (e.target.value) {
												v = Number(e.target.value);
												if (isNaN(v) || v > 1 || v < 0) {
													setMinInputErr(true);
												} else {
													setMinInputErr(false);
													setMinClamp(v);
													applyClamp(v, maxClamp);
												}
											}
										}}
									/>
								</Grid.Column>
								<Grid.Column textAlign="right">
									<Form.Input
										size="mini"
										style={{ width: 50 }}
										type="text"
										value={maxInput}
										error={maxInputErr}
										onChange={(e) => {
											setMaxInput(e.target.value);
											let v = null;
											if (e.target.value) {
												v = Number(e.target.value);
												if (isNaN(v) || v > 1 || v < 0) {
													setMaxInputErr(true);
												} else {
													setMaxInputErr(false);
													setMaxClamp(v);
													applyClamp(minClamp, v);
												}
											}
										}}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row columns={2} style={{ padding: 0 }}>
								<Grid.Column>
									<div style={{ display: "inline-flex", alignItems: "center" }}>
										<Button
											style={{ marginRight: "8px" }}
											color="blue"
											compact
											floated="left"
											content="Auto"
											disabled={histogramBuckets.length === 0}
											onClick={() => {
												const [autoMin, autoMax] = autoAdjust();
												setMinClamp(autoMin / 255);
												setMaxClamp(autoMax / 255);
												setMinInput(Math.round((autoMin / 255) * 100) / 100);
												setMaxInput(Math.round((autoMax / 255) * 100) / 100);
												applyClamp(autoMin / 255, autoMax / 255);
											}}
										/>
										{histogramBuckets.length > 0 && (
											<Tooltip
												placement="right"
												align={{ offset: [-3, 0] }}
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={
													<>
														<p style={{ textAlign: "center" }}>
															Automatically adjusts the histogram to improve contrast
															<br />
															Repeated presses will further adjust the histogram
														</p>
													</>
												}
											>
												<Icon circular inverted name="info" size="small" link />
											</Tooltip>
										)}
									</div>
								</Grid.Column>
								<Grid.Column>
									<Button
										negative
										compact
										floated="right"
										content="Reset"
										onClick={() => {
											resetAdjust();
										}}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>

					<div></div>
				</Popup>
			</>
		);
	}
);

ShaderHistogram.propTypes = {
	applyClampedShader: PropTypes.func,
	applyRYGShader: PropTypes.func,
	applyThermalShader: PropTypes.func,
	applyGreenFireBlueShader: PropTypes.func,
	applySpectralShader: PropTypes.func,
	applyBlueYellowShader: PropTypes.func,
	dropdownRef: PropTypes.object
};

export const HeatmapOverlayLabels = React.forwardRef(({ heatmapOverlayLabels }, ref) => {
	const [disabled, setDisabled] = useState(true);
	const [toggleState, setToggleState] = useState(false);
	HeatmapOverlayLabels.displayName = "HeatmapOverlayLabels";

	useImperativeHandle(ref, () => {
		return {
			setDisabled: (val) => {
				setDisabled(val);
			},
			setToggleState: (val) => {
				setToggleState(val);
			},
			checkDisabled: disabled,
			checkToggleState: toggleState
		};
	});

	return disabled ? (
		<Tooltip
			align={{ offset: [-25, 0] }}
			overlay={
				<>
					<p style={{ textAlign: "center" }}>
						Heatmap values need to be toggled on in the top right corner in order to use this
					</p>
				</>
			}
		>
			<div style={{ display: "flex" }}>
				<Button
					icon
					compact
					toggle
					active={toggleState}
					onClick={() => {
						heatmapOverlayLabels();
					}}
					disabled={disabled}
				>
					<Icon size="large" name="map" />
					<span>Analysis Values</span>
				</Button>
			</div>
		</Tooltip>
	) : (
		<Button
			icon
			compact
			toggle
			active={toggleState}
			onClick={() => {
				heatmapOverlayLabels();
			}}
			disabled={disabled}
		>
			<Icon size="large" name="map" />
			<span>Analysis Values</span>
		</Button>
	);
});

HeatmapOverlayLabels.propTypes = {
	heatmapOverlayLabels: PropTypes.func
};

export const ViewerDataExportButton = ({ viewerDataExportModalRef }) => {
	return (
		<>
			<Tooltip
				placement="bottom"
				align={{ offset: [0, -2] }}
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				trigger="hover"
				overlay={<p>Export Data</p>}
			>
				<Button
					icon
					compact
					onClick={() => {
						viewerDataExportModalRef?.current?.handleClick();
					}}
				>
					<Icon size="large" name="download" />
				</Button>
			</Tooltip>
		</>
	);
};

ViewerDataExportButton.propTypes = {
	viewerDataExportModalRef: PropTypes.object
};

export const RotateRight = ({ rotateRight, level }) => {
	return (
		<Button icon compact onClick={() => rotateRight()}>
			<Icon className="rotate right" size="large" />
			<span>Rotate {level} Right</span>
		</Button>
	);
};

RotateRight.propTypes = {
	rotateRight: PropTypes.func,
	level: PropTypes.string
};

export const RotateLeft = ({ rotateLeft, level }) => {
	return (
		<Button icon compact onClick={() => rotateLeft()}>
			<Icon className="rotate left" size="large" />
			<span>Rotate {level} Left</span>
		</Button>
	);
};

RotateLeft.propTypes = {
	rotateLeft: PropTypes.func,
	level: PropTypes.string
};

export const HeatmapOptions = React.forwardRef(({ setHeatmapDomain, setHeatmapOpacity, setHeatmapGradient }, ref) => {
	HeatmapOptions.displayName = "HeatmapOptions";
	const numBins = 50;
	const initAutoThresh = 200;

	const [open, setOpen] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [heatmapValues, setHeatmapValues] = useState([]);
	const [domain, setDomain] = useState([0, 1]);
	const [opacity, setOpacity] = useState(0.65);
	const [selectedGradient, setSelectedGradient] = useState("ryg");
	const [autoThresh, setAutoThresh] = useState(initAutoThresh);
	const [minClamp, setMinClamp] = useState(0);
	const [maxClamp, setMaxClamp] = useState(0);
	const [minInput, setMinInput] = useState(0);
	const [maxInput, setMaxInput] = useState(0);
	const [minInputErr, setMinInputErr] = useState(false);
	const [maxInputErr, setMaxInputErr] = useState(false);

	useEffect(() => {
		if (heatmapValues.length > 0) {
			const temp = [Math.min(...heatmapValues), Math.max(...heatmapValues)];
			setDomain(temp);
			setHeatmapDomain(temp);
		}
	}, [heatmapValues]);

	useEffect(() => {
		setMinClamp(domain[0]);
		setMinInput(domain[0]);
		setMaxClamp(domain[1]);
		setMaxInput(domain[1]);
	}, [domain]);

	useEffect(() => {
		setHeatmapOpacity(opacity);
	}, [opacity]);

	useEffect(() => {
		setHeatmapGradient(selectedGradient);
	}, [selectedGradient]);

	const bins = useMemo(() => {
		const arr = {};
		const min = Math.min(...heatmapValues);
		const max = Math.max(...heatmapValues);
		const interval = (max - min) / (numBins - 1);

		for (let i = 0; i < numBins; i++) {
			const binRange = [min + interval * i, min + interval * (i + 1)];
			arr[binRange[0]] = heatmapValues.reduce((acc, curr) => {
				if (curr >= binRange[0] && curr < binRange[1]) {
					return acc + 1;
				} else {
					return acc;
				}
			}, 0);
		}

		return arr;
	}, [heatmapValues]);

	useImperativeHandle(ref, () => {
		return {
			setHeatmapValues: (hv) => {
				if (!_.isEqual(heatmapValues.sort(), hv.sort())) {
					setHeatmapValues(hv);
				}
			},
			setDisabled: (d) => {
				setDisabled(d);
			},
			getHeatmapValues: heatmapValues
		};
	});

	//TASK: (i made this task for searchability for the tasks below)
	/*
		TASKS:
		The functionality should be all complete with this component
		
		1. Add Labels to each slider
		2. The commented code is the text boxes for manually inputting min/max clamp values
		4. Consider adding a gradient to the graph to make it look cooler: https://canvasjs.com/forums/topic/gradient-area-color/
	
	*/

	const autoAdjust = useCallback(() => {
		const binRanges = Object.keys(bins);
		const binCounts = Object.values(bins);

		let autoMin = 0;
		let autoMax = 0;
		let size = _.reduce(binCounts, (sum, a) => sum + a, 0);
		setAutoThresh(parseInt(autoThresh < 10 ? initAutoThresh : autoThresh / 2));
		let lim = parseInt(size / 10);
		let thresh = parseInt(size / autoThresh);

		let count = 0;
		let i = 0;
		while (i < numBins - 1 && count <= thresh) {
			i++;
			count = binCounts[i];
			if (count > lim) {
				count = 0;
			}
		}
		autoMin = i;

		count = 0;
		i = numBins - 1;
		while (i > 0 && count <= thresh) {
			i--;
			count = binCounts[i];
			if (count > lim) {
				count = 0;
			}
		}
		autoMax = i;

		if (autoMax < autoMin) {
			autoMin = 0;
			autoMax = numBins - 1;
			setAutoThresh(initAutoThresh);
		}

		return [Number(binRanges[autoMin]), Number(binRanges[autoMax])];
	}, [bins, autoThresh]);

	const resetAdjust = () => {
		setAutoThresh(initAutoThresh);
		const temp = [Math.min(...heatmapValues), Math.max(...heatmapValues)];
		setDomain(temp);
		setHeatmapDomain(temp);
		setOpacity(0.65);
	};

	return disabled ? (
		<Tooltip
			align={{ offset: [-25, 0] }}
			overlay={
				<>
					<p style={{ textAlign: "center" }}>
						Heatmap values need to be toggled on in the top right corner in order to use this
					</p>
				</>
			}
		>
			<div style={{ display: "flex" }}>
				<Button icon compact disabled={disabled} toggle active={open}>
					<Icon size="large" name="map" />
					<span>Heatmap Options</span>
				</Button>
			</div>
		</Tooltip>
	) : (
		<>
			<Popup
				position="right center"
				offset={[0, 14]}
				flowing
				style={{
					margin: "0px",
					position: "relative",
					padding: "0px",
					zIndex: "unset",
					backgroundColor: "var(--background)",
					boxShadow: "var(--map-box-shadow)",
					border: "none",
					borderRadius: "8px",
					overflow: "hidden"
				}}
				basic
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				trigger={
					<Button icon compact disabled={disabled} toggle active={open}>
						<Icon size="large" name="map" />
						<span>Heatmap Options</span>
					</Button>
				}
				on="click"
			>
				<div
					className="popupBar"
					// onPointerDown={() => {
					// 	setIsDraggingWindow(true);
					// }}
				>
					<span style={{ fontSize: 14, fontWeight: 500, marginLeft: 7, pointerEvents: "none", lineHeight: "25px" }}>
						Adjust Heatmap Options
					</span>
					<div />
					<Icon
						name="close"
						onClick={() => {
							setOpen(false);
						}}
					/>
				</div>
				<Form onSubmit={() => {}}>
					<Grid style={{ padding: "14px 16px 4px", width: 328 }}>
						<Grid.Row>
							<Grid.Column style={{ width: "fit-content" }}>
								<Histogram
									buckets={bins}
									min={Math.min(...heatmapValues)}
									max={Math.max(...heatmapValues)}
									slider
									sliderValues={domain}
									count={bins.length}
									onSliderChange={(v) => {
										setDomain(v);
										setHeatmapDomain(v);
									}}
									rootStyle={{ height: 150, width: 200 }}
								/>
							</Grid.Column>
							<Grid.Column>
								<VerticalSlider
									reversed
									step={0.05}
									domain={[0, 1]}
									mode={2}
									values={[opacity]}
									onChange={(v) => {
										let rounded = Math.round(v[0] * 100) / 100;
										setOpacity(rounded);
									}}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Form.Input
									size="mini"
									style={{ width: 75 }}
									type="number"
									step={0.01}
									value={_.floor(minInput, 2)}
									error={minInputErr}
									onChange={(e) => {
										setMinInput(e.target.value);
										let v = null;
										if (e.target.value) {
											v = Number(e.target.value);
											if (isNaN(v) || v < Math.min(...heatmapValues) || v > Math.max(...heatmapValues)) {
												setMinInputErr(true);
											} else {
												setMinInputErr(false);
												if (v === domain[0]) return;
												setMinClamp(v);
												setDomain([v, maxClamp]);
												setHeatmapDomain([v, maxClamp]);
											}
										}
									}}
								/>
							</Grid.Column>
							<Grid.Column textAlign="right">
								<Form.Input
									size="mini"
									style={{ width: 75 }}
									type="number"
									step={0.01}
									value={_.ceil(maxInput, 2)}
									error={maxInputErr}
									onChange={(e) => {
										setMaxInput(e.target.value);
										let v = null;
										if (e.target.value) {
											v = Number(e.target.value);
											if (isNaN(v) || v < Math.min(...heatmapValues) || v > Math.max(...heatmapValues)) {
												setMaxInputErr(true);
											} else {
												setMaxInputErr(false);
												if (v === domain[1]) return;
												setMaxClamp(v);
												setDomain([minClamp, v]);
												setHeatmapDomain([minClamp, v]);
											}
										}
									}}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column>
								<div style={{ display: "inline-flex", alignItems: "center" }}>
									<Button
										color="blue"
										compact
										floated="left"
										content="Auto"
										disabled={!bins}
										onClick={() => {
											const autoDomain = autoAdjust();
											setDomain(autoDomain);
											setHeatmapDomain(autoDomain);
										}}
									/>
									{bins && (
										<Tooltip
											placement="right"
											align={{ offset: [-3, 0] }}
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<>
													<p style={{ textAlign: "center" }}>
														Automatically adjusts the heatmap to improve contrast
														<br />
														Repeated presses will further adjust the heatmap
													</p>
												</>
											}
										>
											<Icon circular inverted name="info" size="small" link />
										</Tooltip>
									)}
								</div>
							</Grid.Column>
							<Grid.Column>
								<Button
									negative
									compact
									floated="right"
									content="Reset"
									onClick={() => {
										resetAdjust();
									}}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Monochromatic</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedGradient === "mono" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(32,183,241,1) 100%)",
											height: 22,
											borderRadius: 4
										}}
										onClick={() => {
											setSelectedGradient("mono");
										}}
									/>
								</Tooltip>
							</Grid.Column>
							<Grid.Column>
								<Tooltip
									placement="bottom"
									align={{ offset: [0, -2] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<>
											<p>Red-Yellow-Green</p>
										</>
									}
								>
									<Button
										fluid
										style={{
											outline: selectedGradient === "ryg" ? "#0080FF solid 3px" : "",
											outlineOffset: 2,
											background:
												"linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 50%, rgba(0,255,0,1) 100%)",
											height: 22,
											borderRadius: 4
										}}
										onClick={() => {
											setSelectedGradient("ryg");
										}}
									/>
								</Tooltip>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>

				<div></div>
			</Popup>
		</>
	);
});

HeatmapOptions.propTypes = {
	disabled: PropTypes.bool,
	setHeatmapDomain: PropTypes.func,
	setHeatmapOpacity: PropTypes.func,
	setHeatmapGradient: PropTypes.func
};

export const ImageLayers = React.forwardRef(({ showLayers, hideLayers }, ref) => {
	ImageLayers.displayName = "ImageLayers";

	const [open, setOpen] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [flights, setFlights] = useState([]);

	useImperativeHandle(ref, () => {
		return {
			setFlights: (f) => setFlights(f),
			getFlights: () => flights,
			setDisabled: (d) => {
				setDisabled(d);
			}
		};
	});

	return (
		<>
			<Popup
				position="right center"
				offset={[0, 14]}
				flowing
				style={{
					margin: "0px",
					position: "relative",
					padding: "0px",
					zIndex: "unset",
					backgroundColor: "var(--background)",
					boxShadow: "var(--map-box-shadow)",
					border: "none",
					borderRadius: "8px",
					overflow: "hidden"
				}}
				basic
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				trigger={
					<Button icon compact disabled={disabled || flights.length === 0} toggle active={open}>
						<Icon size="large" className="layers" />
						<span>Image Layers</span>
					</Button>
				}
				on="click"
			>
				<div
					className="popupBar"
					// onPointerDown={() => {
					// 	setIsDraggingWindow(true);
					// }}
				>
					<span
						style={{
							fontSize: 14,
							fontWeight: 500,
							marginLeft: 7,
							marginRight: 7,
							pointerEvents: "none",
							lineHeight: "25px",
							width: "200px"
						}}
					>
						Show/Hide Layers
					</span>
					<div />
					<Icon
						name="close"
						onClick={() => {
							setOpen(false);
						}}
					/>
				</div>
				<Grid style={{ padding: "14px 16px 16px", maxWidth: "300px" }}>
					{_.map(flights, (f) => {
						return (
							<Grid.Row style={{ padding: 0 }} key={f.name}>
								<Grid.Column
									style={{ width: "fit-content", display: "flex", alignItems: "center", padding: "0px 4px" }}
								>
									<label style={{ fontSize: "medium", cursor: "pointer" }}>
										<input
											type="checkbox"
											style={{ marginRight: 6 }}
											checked={f.checked}
											onChange={({ target }) => {
												let tempFlights = _.cloneDeep(flights);
												tempFlights[_.findIndex(tempFlights, (tf) => tf.name === f.name)].checked = target.checked;
												setFlights(tempFlights);

												target.checked ? showLayers(f.layers) : hideLayers(f.layers);
											}}
										/>
										{f.name}
									</label>
								</Grid.Column>
							</Grid.Row>
						);
					})}
				</Grid>

				<div></div>
			</Popup>
		</>
	);
});

ImageLayers.propTypes = {
	showLayers: PropTypes.func,
	hideLayers: PropTypes.func
};

export const CoordinateTracker = React.forwardRef(({ style }, ref) => {
	CoordinateTracker.displayName = "CoordinateTracker";

	const [coords, setCoords] = useState({ lng: 0, lat: 0 });

	useImperativeHandle(ref, () => {
		return {
			setCoords: (c) => {
				setCoords(c);
			}
		};
	});

	return (
		<div style={{ background: "#ffffff80", ...style }}>
			<span style={{ padding: 5 }}>
				{Math.round(coords.lat * 1000000) / 1000000}, {Math.round(coords.lng * 1000000) / 1000000}
			</span>
		</div>
	);
});

CoordinateTracker.propTypes = {
	style: PropTypes.object
};

export const RevertAlignment = React.forwardRef(({ revertPlotUpdates }, ref) => {
	RevertAlignment.displayName = "RevertAlignment";

	const [open, setOpen] = useState(false);

	useImperativeHandle(ref, () => {
		return {
			setOpen: (val) => {
				setOpen(val);
			}
		};
	});

	return (
		open && (
			<>
				<div className={`staticContainer`}>
					<div className="popupBar">
						<span style={{ fontSize: 14, fontWeight: 500, marginLeft: 7, pointerEvents: "none", lineHeight: "25px" }}>
							Undo alignment changes?
						</span>
						<div />
						<Icon
							name="close"
							onClick={() => {
								setOpen(false);
							}}
						/>
					</div>
					<div className="staticContent">
						<Button.Group>
							<Button icon compact onClick={() => setOpen(false)} toggle primary style={{ height: "24px" }}>
								<span>Keep Changes</span>
							</Button>
							<Button
								icon
								compact
								onClick={() => {
									revertPlotUpdates();
									setOpen(false);
								}}
								toggle
								primary
								style={{ height: "24px" }}
							>
								<span>Undo</span>
							</Button>
						</Button.Group>
					</div>
				</div>
			</>
		)
	);
});

RevertAlignment.propTypes = {
	revertPlotUpdates: PropTypes.func.isRequired
};
