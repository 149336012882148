import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Dropdown, Grid, Icon, Loader, Popup, Segment } from "semantic-ui-react";

import _ from "lodash";

import moment from "moment";
import { toast } from "react-toastify";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";

import * as analysisActions from "../../../redux/actions/analysisActions";
import * as statisticsActions from "../../../redux/actions/statisticsActions";
import AreaUnderTheCurveTable from "../../Content/Widgets/AreaUnderTheCurveTable";
import AucBoxWhiskerChart from "../../Content/Widgets/Charts/aucBoxWhiskerChart";
import LineChart from "../../Content/Widgets/Charts/lineChart";
import DataAnalysisCriteria from "../../Lumber/DataAnalysisCriteria";
import AnalysisDropDownLists from "../AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../AnalysisDropDownLists/AnalysisDropDownListFunctions";
import * as timecourseCalculations from "../AreaUnderTheCurve";

import styles from "./styles.css";

const TimecourseComponent = ({
	showAnalysisSelection = true,
	showTable = true,
	showLineChart = true,
	showBoxWhisker = true,
	runMeanComparison = true,
	analysisDataResults,
	trialDataResults,
	viewPlantFlight = true,
	viewHarvestFlight = true,
	getSelectedFlights = null,
	getAucData = null,
	getOtherAucData = null,
	analysis = null,
	flightsInCurveModel = null,
	dapStart = null,
	dapEnd = null,
	plantDate = null,
	allMeanComparisonOptions,
	setCheckedTreatmentData,
	gduList,
	useGDUTimecourse,
	getHasGduFormula = null,
	quantifiedRegionTypes = null,
	quantifiedRegionTypeValue = null,
	rawPresetOptions = null,
	parentSelectedAnalysisType = null,
	isForAnalysisTimecoursePage
}) => {
	const dispatch = useDispatch();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();

	//-- Dropdown Options
	const [selectedAnalysisOption, setSelectedAnalysisOption] = useState(null);
	const [selectedMeanComparisonOption, setSelectedMeanComparisonOption] = useState(null);
	const [selectedAlphaOption, setSelectedAlphaOption] = useState(null);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState(quantifiedRegionTypes ?? []);
	const [selectedQuantifiedRegionTypeOption, setSelectedQuantifiedRegionTypeOption] = useState(
		quantifiedRegionTypeValue ?? null
	);
	//-- This is the aerial or uniformity ddl options
	const [selectedAnalysisType, setSelectedAnalysisType] = useState(null);

	//-- Data
	const [rawData, setRawData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [analysisResults, setAnalysisResults] = useState([]);
	const [filteredAnalysisResults, setFilteredAnalysisResults] = useState([]);
	const [aucData, setAucData] = useState([]);
	const [allAucData, setAllAucData] = useState([]);
	const [combinedAucData, setCombinedAucData] = useState([]);
	const [chartAnalysisResults, setChartAnalysisResults] = useState([]);
	const [footerData, setFooterData] = useState([]);
	//const [allFooterData, setAllFooterData] = useState([]);
	const [meanComparisonData, setMeanComparisonData] = useState([]);
	const [allMeanComparisonData, setAllMeanComparisonData] = useState([]);
	const reduxMeanComparisonData = useSelector((state) => (state.meanComparisonData ? state.meanComparisonData : null));
	const [gduByDay, setGDUByDay] = useState([]);
	const [cumulativeGdus, setCumulativeGDUs] = useState(null);
	const [selectedAssessmentName, setSelectedAssessmentName] = useState(null);
	const [meanComparisonDataAuc, setMeanComparisonDataAuc] = useState(null);
	const [boxWhiskerMeanComparisonDataAuc, setBoxWhiskerMeanComparisonDataAuc] = useState(null);
	const [boxWhiskerAllMeanComparisonDataAuc, setBoxWhiskerAllMeanComparisonDataAuc] = useState([]);
	const [allMeanComparisonDataAuc, setAllMeanComparisonDataAuc] = useState([]);

	//-- Area Under the Curve Table Data
	const [groupedTrialData, setGroupedTrialData] = useState([]);
	const [groundTrialDataChecked, setGroundTrialDataChecked] = useState([]);
	const [flights, setFlights] = useState([]);
	const [flightDataChecked, setFlightDataChecked] = useState([]);
	const [initialGroupTrialDataCheckedCount, setInitialGroupTrialDataCheckedCount] = useState([]);
	const [initialFlightDataCheckedCount, setInitialFlightDataCheckedCount] = useState([]);
	const [hideLetters, setHideLetters] = useState(false);
	const [hideJustAUCLetters, setHideJustAUCLetters] = useState(false);
	//Used for mean comparison and alpha changes
	const [needToRerunStats, setNeedToRerunStats] = useState(false);

	//-- UI Control
	const [loading, setLoading] = useState(false);
	const [hideChartsAndTables, setHideChartsAndTables] = useState(false);
	const [loadingStats, setLoadingStats] = useState(false);
	const [useGDU, setUseGDU] = useState(false);
	const [hasNoData, setHasNoData] = useState(false);
	const [previousAssessmentName, setPreviousAssessmentName] = useState(null);
	const [previousRemoveDamagedOrExcludedFlag, setPreviousRemoveDamagedOrExcludedFlag] = useState(true);
	const [aucDataInitialRun, setAucDataInitialRun] = useState(true);
	const [initialDataMessageSent, setInitialDataMessageSent] = useState(false);
	const [isBoxWhiskerChartLoading, setIsBoxWhiskerChartLoading] = useState(false);
	const [disableColors, setDisableColors] = useState(false);

	//Weather Data States to re enable
	const weatherDataEnabled = true;
	const [hasGduFormula, setHasGduFormula] = useState(false);

	//Data Analysis Critera
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);
	const [needToReloadData, setNeedToReloadData] = useState(false);
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	useEffect(() => {
		if (allMeanComparisonOptions) {
			initializeMeanComparisonOptions(
				allMeanComparisonOptions.data.meanComparisonOptions,
				allMeanComparisonOptions.data.defaultMeanComparisonId
			);
			initializeAlphaOptions(allMeanComparisonOptions.data.alphaOptions, allMeanComparisonOptions.data.defaultAlphaId);
		}
	}, [allMeanComparisonOptions]);

	useEffect(() => {
		if (dapStart != null && dapEnd != null && plantDate != null) {
			if (analysisDataResults?.length >= 0 && trialDataResults) {
				let flightDateFilteredResults = _.filter(analysisDataResults, (adr) => {
					return (
						dapEnd == 0 ||
						(moment(adr.flightDate) <= moment(plantDate).add(dapEnd, "d") &&
							moment(adr.flightDate) >= moment(plantDate).add(dapStart, "d"))
					);
				});
				if (flightDateFilteredResults?.length > 0) {
					setHideChartsAndTables(false);
					stageTreatmentData(flightDateFilteredResults, trialDataResults);
				} else {
					setHideChartsAndTables(true);
				}
			}
		}
	}, [dapStart, dapEnd, plantDate]);

	useEffect(() => {
		if (!needToUpdateAnalytics) {
			if (reduxMeanComparisonData && reduxMeanComparisonData.length > 0) {
				setupMeanComparisonData(reduxMeanComparisonData, true);
			}
		}
	}, [reduxMeanComparisonData]);

	useEffect(() => {
		if (!needToUpdateAnalytics) {
			if (meanComparisonData && meanComparisonData.length > 0 && filteredAnalysisResults) {
				setupMeanComparisonData(null, true);
			}
		}
	}, [meanComparisonData]);

	useEffect(() => {
		if (previousAssessmentName === null && !needToUpdateAnalytics) {
			if (!selectedQuantifiedRegionTypeOption) {
				let qrTypes = _.map(_.uniqBy(analysisDataResults, "quantifiedRegionTypeId"), (q) => {
					return { key: q.quantifiedRegionTypeId, value: q.quantifiedRegionTypeId, text: q.quantifiedRegionTypeName };
				});

				qrTypes = _.filter(qrTypes, (qr) => {
					return qr.text !== null;
				});

				setQuantifiedRegionTypeOptions(qrTypes);

				const defaultQr =
					_.find(rawData, (r) => {
						return r.isDefaultQuantifiedRegion === true;
					})?.quantifiedRegionTypeId ?? analysisDropDownListFunctions.getDefaultQuantifiedRegionType(qrTypes);
				setSelectedQuantifiedRegionTypeOption(defaultQr);
			} else if (!selectedAnalysisType) {
				setSelectedAnalysisType(parentSelectedAnalysisType ?? rawPresetOptions?.analysisTypes[0]?.id);
			} else if (!analysisResults || (analysisResults.length === 0 && !hasNoData)) {
				getAnalysisData();
			} else if (weatherDataEnabled) {
				//WeatherData element to check if crop type is supported
				checkForGDUFormula(analysisDataResults[0]?.cropName);
			}
		}
	}, [analysisResults, analysisDataResults, hasNoData, selectedQuantifiedRegionTypeOption, selectedAnalysisType]);

	useEffect(() => {
		if (analysisDataResults?.length > 0 && analysisResults?.length > 0) {
			getAnalysisData();
		}
	}, [analysisDataResults]);

	useEffect(() => {
		if (previousAssessmentName === null && !needToUpdateAnalytics) {
			if (selectedAnalysisType && selectedQuantifiedRegionTypeOption) {
				getAnalysisData();

				if (runMeanComparison) {
					getMeanComparisonData(selectedAnalysisOption, true, selectedAnalysisType, selectedQuantifiedRegionTypeOption);
				} else {
					setHideChartsAndTables(false);
				}
			}
		}
	}, [selectedQuantifiedRegionTypeOption, selectedAnalysisType]);

	useEffect(() => {
		if (previousAssessmentName === null && !needToUpdateAnalytics) {
			const qrDataMatches =
				filteredData.length > 0 &&
				_.some(filteredData, (fd) => {
					return (
						fd.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption &&
						fd.analysisTypeId === selectedAnalysisType
					);
				});
			if (
				selectedAnalysisOption &&
				filteredData.length > 0 &&
				selectedMeanComparisonOption &&
				selectedAlphaOption &&
				qrDataMatches
			) {
				setHideChartsAndTables(false);

				if (_.isEmpty(aucData)) {
					stageAucData(
						_.filter(filteredData, (fd) => {
							return fd.analysisTypeId == selectedAnalysisType || fd.analysisTypeId === null;
						}),
						selectedAnalysisOption,
						true
					);
				}

				let uniqueAnalyses = _.uniqBy(filteredData, (rd) =>
					[rd.analysisId, rd.analysisTypeId, rd.quantifiedRegionTypeId].join()
				);
				uniqueAnalyses = _.filter(uniqueAnalyses, (ua) => {
					return ua.analysisTypeId !== null;
				});

				//Get all AUC data on initial load
				_.map(uniqueAnalyses, (analysis) => {
					let aucValues = timecourseCalculations.getAreaUnderTheCurve(
						_.filter(filteredData, (fd) => {
							return fd.analysisTypeId == analysis.analysisTypeId || fd.analysisTypeId === null;
						}),
						analysis.analysisId,
						trialDataResults,
						useGDU,
						cumulativeGdus,
						removeDamagedOrExcluded,
						removeOutliers,
						outlierType
					);
					aucValues.analysisTypeId = analysis.analysisTypeId;
					setAllAucData((allAucData) => [...allAucData, aucValues]);
					stageAllAucData(
						filteredData,
						analysis.analysisId,
						true,
						analysis.quantifiedRegionTypeId,
						analysis.analysisTypeId
					);
				});

				if (runMeanComparison) {
					getMeanComparisonData(selectedAnalysisOption, true, selectedAnalysisType, selectedQuantifiedRegionTypeOption);

					//Get mean comparison data for all analysis types upon initial load
					_.map(uniqueAnalyses, (analysis) => {
						let findAnalysisTypeId = _.find(rawData, (rd) => {
							return (
								rd.analysisId === analysis.analysisId &&
								rd.analysisTypeId === analysis.analysisTypeId &&
								rd.quantifiedRegionTypeId === analysis.quantifiedRegionTypeId
							);
						});

						let tempQuantifiedRegionTypeId = _.find(rawPresetOptions.timeSeriesPresets, (tsp) => {
							return (
								tsp.analysisId === findAnalysisTypeId.analysisId &&
								tsp.analysisTypeId === findAnalysisTypeId.analysisTypeId &&
								tsp.quantifiedRegionTypeId === findAnalysisTypeId.quantifiedRegionTypeId
							);
						});

						getMeanComparisonData(
							analysis.analysisId,
							false,
							findAnalysisTypeId?.analysisTypeId ?? analysis.analysisTypeId,
							tempQuantifiedRegionTypeId?.quantifiedRegionTypeId
						);
					});
				} else {
					setHideChartsAndTables(false);
				}
			}
		}
	}, [selectedAnalysisOption]);

	useEffect(() => {
		if (!needToUpdateAnalytics) {
			if (meanComparisonData.length > 0 && filteredAnalysisResults.length > 0) {
				let tempFilteredAnalysisResults = _.cloneDeep(filteredAnalysisResults);
				setupMeanComparisonData(tempFilteredAnalysisResults, true);
				setHideChartsAndTables(false);
			}
		}
	}, [meanComparisonData]);

	useEffect(() => {
		let checkedList = _.map(groupedTrialData, (trialData) => {
			return trialData.checked;
		});

		setGroundTrialDataChecked(checkedList);

		if (setCheckedTreatmentData) {
			setCheckedTreatmentData(checkedList);
		}
	}, [groupedTrialData]);

	useEffect(() => {
		let flightsChecked = [];

		_.uniqBy(flights, "flightId").map(function (flight) {
			flightsChecked.push({
				flightId: flight.flightId,
				analysisName: flight.analysisName,
				checked: flight.checked,
				isHarvestFlight: flight.isHarvestFlight,
				isPlantFlight: flight.isPlantFlight
			});
		});
		setFlightDataChecked(flightsChecked);

		if (getSelectedFlights !== null) {
			getSelectedFlights(flightsChecked);
		}

		let trialDataResultsClone = _.cloneDeep(trialDataResults);

		if (!_.isEmpty(groundTrialDataChecked)) {
			let treatmentIds = [];
			_.filter(groundTrialDataChecked, (item, index) => {
				if (!item) {
					treatmentIds.push(index + 1);
				}
			});

			if (!_.isEmpty(trialDataResultsClone)) {
				trialDataResultsClone.treatments = _.filter(trialDataResultsClone.treatments, (tdr) => {
					return !_.includes(treatmentIds, tdr.trialTreatmentId);
				});
			}
			if (!_.isEmpty(analysisDataResults)) {
				analysisDataResults = _.filter(analysisDataResults, (adr) => {
					return !_.includes(treatmentIds, adr.trialTreatmentId);
				});
			}
		}

		//-- ParentSelectedAnalysisType is an attribute that only exists on the curve model pages
		//-- This block will only run on flight update in curve model pages
		if (flightsChecked.length > 0) {
			//parentSelectedAnalysisType !== null && flightsChecked.length > 0) {
			let checkedFlights = _.filter(flightsChecked, "checked");
			updateChartAnalysisResultsOnly(analysisDataResults, trialDataResultsClone, checkedFlights);
		}
	}, [flights, groundTrialDataChecked]);

	//-- Basically stripping stageTreatmentData without any of the data setting
	function updateChartAnalysisResultsOnly(res, trialData, checkedFlights) {
		const analyses = _.uniqBy(analysisDataResults, "analysisId").map(function (analysis) {
			return { key: analysis.analysisId, value: analysis.analysisId, text: analysis.analysisName };
		});
		let analysisIndex = 0;
		analysisIndex = timecourseCalculations.getAnalysisIndex(analysis ?? selectedAnalysisOption, analyses);

		res = createEmptyPlantAndHarvestFlights(res, analyses);

		res = filterRawAnalysisData(res);

		let avgObjects = [];
		avgObjects = timecourseCalculations.setupTrialAverages(
			res,
			analyses,
			analysisIndex,
			trialData,
			false,
			false,
			removeDamagedOrExcluded,
			removeOutliers,
			outlierType
		);

		let checkedFlightIds = _.map(checkedFlights, "flightId");
		let plantFlightChecked = _.some(checkedFlights, (cf) => cf.isPlantFlight);
		let harvestFlightChecked = _.some(checkedFlights, (cf) => cf.isHarvestFlight);
		let filteredAnalysisData = _.filter(
			avgObjects,
			(a) =>
				a.analysisId == analyses[analysisIndex]?.value &&
				(a.analysisTypeId === selectedAnalysisType || a.analysisTypeId === null) &&
				(checkedFlightIds.includes(a.flightId) ||
					(plantFlightChecked && a.isPlantFlight) ||
					(harvestFlightChecked && a.isHarvestFlight))
		);

		let treatmentIds = [];
		_.filter(groundTrialDataChecked, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});

		if (!_.isEmpty(filteredAnalysisData)) {
			filteredAnalysisData = _.filter(filteredAnalysisData, (tdr) => {
				return !_.includes(treatmentIds, tdr.trialTreatmentId);
			});
		}
		setChartAnalysisResults(filteredAnalysisData);
		recalculateGDUs(filteredAnalysisData);
	}

	//Weather Data UseEffect
	useEffect(() => {
		if (getAucData !== null && aucData && aucData.length > 0) {
			getAucData(aucData);
		}
	}, [aucData]);

	//Weather Data UseEffect
	useEffect(() => {
		if (weatherDataEnabled) {
			if (getHasGduFormula !== null) {
				getHasGduFormula(hasGduFormula);
			}
		}
	}, [hasGduFormula]);

	useEffect(() => {
		if (getOtherAucData !== null && combinedAucData) {
			getOtherAucData(combinedAucData);
		}
	}, [combinedAucData]);

	useEffect(() => {
		if (analysis && analysisResults?.length > 0) {
			setSelectedAnalysisOption(analysis);
		}
	}, [analysis]);

	//This use effect is for updating the values in the area under the curve table upon assessment changes
	useEffect(() => {
		if (selectedAssessmentName !== null && selectedAssessmentName !== undefined) {
			setPreviousAssessmentName(selectedAssessmentName);
		}

		if (
			selectedAssessmentName !== undefined &&
			selectedAssessmentName !== null &&
			selectedAssessmentName !== "" &&
			previousAssessmentName !== null
		) {
			let analysisTypeId = _.find(
				rawPresetOptions.timeSeriesPresets,
				(rpo) => rpo.name === selectedAssessmentName
			)?.analysisTypeId;
			let newDataStaged = stageFilteredAnalysisResults(analysisResults, selectedAnalysisOption, analysisTypeId);

			if (newDataStaged) {
				setAucDataInitialRun(false);
				const filteredAnalysisData = _.filter(analysisResults, (ar) => {
					return (
						ar.analysisId === selectedAnalysisOption &&
						(ar.analysisTypeId === analysisTypeId || ar.analysisTypeId === null)
					);
				});
				setupMeanComparisonData(filteredAnalysisData, false);
				runMeanComparison = false;
				let filteredFooterDataByAnalyisType = [];
				let filteredFooterData = [];

				filteredFooterDataByAnalyisType = _.filter(allMeanComparisonData, (ar) => {
					return ar.analysisTypeId === analysisTypeId;
				});

				_.filter(filteredFooterDataByAnalyisType, (ar) => {
					_.filter(ar, (analysis) => {
						if (analysis.analysisId === selectedAnalysisOption) {
							filteredFooterData.push(analysis);
						}
					});
				});

				setupFooterData(filteredFooterData);
				let filteredAucDataByAnalysisType = [];
				let filteredAucData = [];

				filteredAucDataByAnalysisType = _.filter(allAucData, (ar) => {
					return ar.analysisTypeId === analysisTypeId;
				});

				_.filter(filteredAucDataByAnalysisType, (ad) => {
					_.filter(ad, (a) => {
						if (a.analysisId === selectedAnalysisOption) {
							filteredAucData.push(a);
						}
					});
				});

				setAucData(filteredAucData);

				setLoadingStats(false);
			}
		}
	}, [selectedAssessmentName]);

	useEffect(() => {
		if (quantifiedRegionTypeValue) {
			setSelectedQuantifiedRegionTypeOption(quantifiedRegionTypeValue);
		}
	}, [quantifiedRegionTypeValue]);

	//Not sure why this is commented out so I'm going to leave it
	//Weather Data UseEffect to set the cumulative GDU data
	/*useEffect(() => {
		if (weatherDataEnabled) {
			if (gduByDay.length > 0 && !cumulativeGdus) {
				let calcGDUS = timecourseCalculations.calculateCumulativeGdu(chartAnalysisResults, gduByDay);
				setCumulativeGDUs(calcGDUS);
			}
			flights.length > 0 && groupedTrialData.length > 0
				? updateFlightAndTreatmentSelections(flights, groupedTrialData)
				: stageAucData(filteredData, selectedAnalysisOption);
		}
	}, [useGDU]);*/

	//GDUs need to be recalculated when a different vegetative index is selected
	function recalculateGDUs(analysisDataResults) {
		if (weatherDataEnabled && gduByDay.length > 0) {
			let calcGDUS = timecourseCalculations.calculateCumulativeGdu(analysisDataResults, gduByDay);
			setCumulativeGDUs(calcGDUS);
		}
	}

	// Weather Data useEffect to Set GDU data if the component has it, and to set the list of GDUs by Day
	useEffect(() => {
		if (weatherDataEnabled) {
			if (useGDUTimecourse !== null) {
				setUseGDU(useGDUTimecourse);
			}
			if (gduList?.length > 0) {
				setGDUByDay(gduList);
			}
		}
	}, [gduList, useGDUTimecourse]);

	//Weather Data UseEffect
	useEffect(() => {
		if (weatherDataEnabled) {
			if (gduByDay.length > 0 && chartAnalysisResults.length > 0 && (!cumulativeGdus || cumulativeGdus.length === 0)) {
				let calcGDUS = timecourseCalculations.calculateCumulativeGdu(chartAnalysisResults, gduByDay);
				setCumulativeGDUs(calcGDUS);
			}
		}
	}, [gduByDay]);

	useEffect(() => {
		if (parentSelectedAnalysisType) {
			//-- parentSelectedAnalysisType is the name of the analysis type
			// const analysisType = _.find(rawPresetOptions.analysisTypes, (at) => {
			// 	return at.name === parentSelectedAnalysisType;
			// });

			setSelectedAnalysisType(parentSelectedAnalysisType);
		}
	}, [parentSelectedAnalysisType]);

	useEffect(() => {
		if (filteredData.length > 0 && allMeanComparisonData.length > 0) {
			if (
				!loadingStats &&
				!isBoxWhiskerChartLoading &&
				_.filter(
					_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
					(u) => {
						return u.analysisTypeId !== null;
					}
				).length === allMeanComparisonData.length &&
				_.filter(
					_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
					(u) => {
						return u.analysisTypeId !== null;
					}
				).length === allMeanComparisonDataAuc.length &&
				initialDataMessageSent
			) {
				toast.success("All data has finished loading.");
			} else {
				if (
					aucData.length > 0 &&
					meanComparisonData.length > 0 &&
					!loadingStats &&
					!initialDataMessageSent &&
					!isBoxWhiskerChartLoading
				) {
					toast.info("Selected assessment data has finished loading. Remaining assessment data is still loading.", {
						autoClose: 8000
					});
					setInitialDataMessageSent(true);
				}
			}
		}
	}, [allMeanComparisonData, isBoxWhiskerChartLoading, meanComparisonData, loadingStats, initialDataMessageSent]);

	function initializeAlphaOptions(alphas, defaultAlphaId) {
		let alphaOptionsTemp = [];
		_.map(alphas, (a) => {
			alphaOptionsTemp.push({ key: a.id, value: a.id, text: a.value });
		});
		setAlphaOptions(alphaOptionsTemp);
		let defaultAlphaOption =
			defaultAlphaId ??
			trialDataResults.trialInfo.alphaId ??
			_.find(alphaOptionsTemp, (ao) => {
				return ao.text == 0.1;
			})?.key;

		setSelectedAlphaOption(defaultAlphaOption);
	}

	function initializeMeanComparisonOptions(meanComparisons, defaultMeanComparisonId) {
		let meanComparisonsTemp = [];
		_.map(meanComparisons, (mc) => {
			meanComparisonsTemp.push({ key: mc.id, value: mc.id, text: mc.name });
		});
		setMeanComparisonOptions(meanComparisonsTemp);

		let defaultMeanComparison =
			defaultMeanComparisonId ??
			trialDataResults.trialInfo.meanComparisonId ??
			_.find(meanComparisonsTemp, (mct) => {
				return mct.text == "Tukey HSD";
			})?.key;

		setSelectedMeanComparisonOption(defaultMeanComparison);
	}

	function getAnalysisData() {
		if (analysisDataResults?.length >= 0 && trialDataResults) {
			return stageTreatmentData(analysisDataResults, trialDataResults);
		}
	}

	function stageTreatmentData(res, trialData) {
		const analyses = _.uniqBy(res, "analysisId").map(function (analysis) {
			return { key: analysis.analysisId, value: analysis.analysisId, text: analysis.analysisName };
		});
		let analysisIndex = 0;
		analysisIndex = timecourseCalculations.getAnalysisIndex(analysis ?? selectedAnalysisOption, analyses);

		res = createEmptyPlantAndHarvestFlights(res, analyses);

		setRawData(res);
		res = filterRawAnalysisData(res);

		setFilteredData(res);
		let avgObjects = [];
		avgObjects = timecourseCalculations.setupTrialAverages(
			res,
			analyses,
			analysisIndex,
			trialData,
			false,
			false,
			removeDamagedOrExcluded,
			removeOutliers,
			outlierType
		);

		setSelectedAnalysisOption(analyses[analysisIndex]?.value);
		if (avgObjects.length === 0) {
			setHasNoData(true);
		} else {
			setHasNoData(false);
			setAnalysisResults(avgObjects);
		}
		let newDataStaged = stageFilteredAnalysisResults(avgObjects, analyses[analysisIndex]?.value, selectedAnalysisType);
		return newDataStaged ? res : null;
	}

	//-- Reusable function to filter raw data from getPlotAnalysisResultsForTrial
	function filterRawAnalysisData(rawData) {
		let skipOutlierFilter = !_.some(rawData, (rd) => rd?.isOutlier3 !== undefined || rd?.isOutlier1_5 !== undefined);

		let filteredData = _.filter(rawData, (r) => {
			return (
				(removeDamagedOrExcluded ? r.excludeFromAssessment === false : true) &&
				(skipOutlierFilter ||
					(removeOutliers && outlierType === 3
						? r.isOutlier3 === false
						: removeOutliers && outlierType === 1.5
						? r.isOutlier1_5 === false
						: true)) &&
				(r.quantifiedRegionTypeId ===
					(_.find(
						rawPresetOptions?.timeSeriesPresets,
						(rpo) =>
							(rpo.analysisId === r.analysisId && (rpo.analysisTypeId === r.analysisTypeId)?.quantifiedRegionTypeId) ||
							(rpo.analysisTypeId === r.analysisTypeId)?.secondaryQuantifiedRegionTypeId
					) || selectedQuantifiedRegionTypeOption) ||
					r.isPlantFlight ||
					r.isHarvestFlight) &&
				(r.analysisTypeId ===
					(_.find(
						rawPresetOptions?.timeSeriesPresets,
						(rpo) => rpo.analysisId === r.analysisId && rpo.analysisTypeId === r.analysisTypeId
					)?.analysisTypeId || selectedAnalysisType) ||
					r.isPlantFlight ||
					r.isHarvestFlight)
			);
		});

		return filteredData;
	}

	//-- Reusable function to create fake plant and harvest flights
	function createEmptyPlantAndHarvestFlights(res, analyses) {
		if (viewHarvestFlight) {
			const harvestTreatments = timecourseCalculations.addEmptyFlightForPlantOrHarvestDate(
				trialDataResults.trialInfo.harvestDate,
				res,
				trialDataResults.treatments,
				analyses,
				true,
				false,
				userAuth.currentClientId
			);
			if (harvestTreatments) {
				//-- Mechanism to uncheck fake plant/harvest flight
				if (
					_.findIndex(flights, (f) => f.isHarvestFlight && !f.checked) !== -1 &&
					_.some(res, (r) => r.curveModelDataAnalysisId)
				) {
					_.map(harvestTreatments, (ht) => (ht.uncheckFlight = true));
				}

				res = res.concat(harvestTreatments);
			}
		}

		if (viewPlantFlight) {
			const plantTreatments = timecourseCalculations.addEmptyFlightForPlantOrHarvestDate(
				trialDataResults.trialInfo.plantDate,
				res,
				trialDataResults.treatments,
				analyses,
				false,
				true,
				userAuth.currentClientId
			);

			if (plantTreatments) {
				//-- Mechanism to uncheck fake plant/harvest flight
				if (
					_.findIndex(flights, (f) => f.isPlantFlight && !f.checked) !== -1 &&
					_.some(res, (r) => r.curveModelDataAnalysisId)
				) {
					_.map(plantTreatments, (pt) => (pt.uncheckFlight = true));
				}

				res = res.concat(plantTreatments);
			}
		}
		return res;
	}

	//Weather Data function to check if crop type is supported.
	function checkForGDUFormula(cropName) {
		switch (cropName) {
			case "Soybeans":
			case "Corn":
			case "Sweet Corn":
				setHasGduFormula(true);
				break;
			default:
				break;
		}
	}

	async function stageAucData(data, analysisValue, isInitialLoad) {
		//-- Filter based on qr type (include plant/harvest flight)
		data = _.filter(data, (d) => {
			return (
				(d.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption &&
					d.analysisTypeId === selectedAnalysisType) ||
				d.isPlantFlight ||
				d.isHarvestFlight
			);
		});

		//-- Filter based on checked flights
		data = _.filter(data, (d) => {
			return (
				_.find(
					flightDataChecked,
					(fdc) =>
						fdc.flightId === d.flightId ||
						(fdc.isPlantFlight && d.isPlantFlight) ||
						(fdc.isHarvestFlight && d.isHarvestFlight)
				)?.checked ?? true
			);
		});

		//Only stage new data when initial load is true. Upon swapping assessment data this is handled by filtering out the data from allAUCData object for each analysis type
		if (isInitialLoad) {
			let aucValues = timecourseCalculations.getAreaUnderTheCurve(
				data,
				analysisValue,
				trialDataResults,
				useGDU,
				cumulativeGdus,
				removeDamagedOrExcluded,
				removeOutliers,
				outlierType
			);

			let isDataUnbalanced = getIsDataUnbalanced(data);

			setAucData(aucValues);

			//-- Wrapping this so that it doesn't get called in curve model pages
			if (parentSelectedAnalysisType === null) {
				const requestData = {
					data: _.map(aucValues, (d) => {
						return { trialTreatmentId: d.trialTreatmentId, plotReplicate: d.plotReplicate, totalAuc: d.totalAuc };
					})
				};

				const accessToken = await getTokenSilently();
				dispatch(
					statisticsActions.getMeanComparisonForAuc(
						requestData,
						userAuth.currentClientId,
						selectedMeanComparisonOption,
						selectedAlphaOption,
						isDataUnbalanced,
						accessToken
					)
				)
					.then((res) => {
						const tempRes = _.cloneDeep(res);
						setBoxWhiskerMeanComparisonDataAuc(tempRes);
						let allLettersAreA = _.map(res.letteringPairs, "letter").every((letters) => letters === "a");
						if (allLettersAreA) {
							_.map(res.letteringPairs, (d) => {
								d.letter = "-";
							});
						}
						setMeanComparisonDataAuc(res);
					})
					.catch((e) => {
						console.error(e);
						toast.error("Could not load mean comparison auc data");
					});
			}

			//Weather Data logic to force Curve Models to get both DAP and GDU calcs for AUC
			if (weatherDataEnabled && getOtherAucData !== null && !hasGduFormula) {
				setCombinedAucData(aucValues);
			}
			if (weatherDataEnabled && getOtherAucData !== null && cumulativeGdus) {
				aucValues = aucValues.concat(
					timecourseCalculations.getAreaUnderTheCurve(
						data,
						analysisValue,
						trialDataResults,
						!useGDU,
						cumulativeGdus,
						removeDamagedOrExcluded,
						removeOutliers,
						outlierType
					)
				);
				aucValues = _.sortBy(aucValues, ["trialTreatmentId"]);
				setCombinedAucData(aucValues);
			}
			if (!weatherDataEnabled && getOtherAucData !== null) {
				setCombinedAucData(aucValues);
			}
		}
	}

	async function stageAllAucData(data, analysisValue, isInitialLoad, quantifiedRegionTypeId, analysisTypeId) {
		//let tempQuantifiedRegionTypeId = _.find(rawPresetOptions.timeSeriesPresets)
		data = _.filter(data, (d) => {
			return (
				(d.quantifiedRegionTypeId === quantifiedRegionTypeId && d.analysisTypeId === analysisTypeId) ||
				d.isPlantFlight ||
				d.isHarvestFlight
			);
		});

		//-- Filter based on checked flights
		data = _.filter(data, (d) => {
			return (
				_.find(
					flightDataChecked,
					(fdc) =>
						fdc.flightId === d.flightId ||
						(fdc.isPlantFlight && d.isPlantFlight) ||
						(fdc.isHarvestFlight && d.isHarvestFlight)
				)?.checked ?? true
			);
		});

		let isDataUnbalanced = getIsDataUnbalanced(data);

		let aucValues = timecourseCalculations.getAreaUnderTheCurve(
			data,
			analysisValue,
			trialDataResults,
			useGDU,
			cumulativeGdus,
			removeDamagedOrExcluded,
			removeOutliers,
			outlierType
		);

		//-- Wrapping this so that it doesn't get called in curve model pages
		if (parentSelectedAnalysisType === null && isInitialLoad) {
			const requestData = {
				data: _.map(aucValues, (d) => {
					return { trialTreatmentId: d.trialTreatmentId, plotReplicate: d.plotReplicate, totalAuc: d.totalAuc };
				})
			};

			const accessToken = await getTokenSilently();
			dispatch(
				statisticsActions.getMeanComparisonForAuc(
					requestData,
					userAuth.currentClientId,
					selectedMeanComparisonOption,
					selectedAlphaOption,
					isDataUnbalanced,
					accessToken
				)
			)
				.then((res) => {
					const tempRes = _.cloneDeep(res);

					res.analysisId = analysisValue;
					res.analysisTypeId = analysisTypeId;

					let newRes = [analysisValue, analysisTypeId, tempRes];

					setBoxWhiskerAllMeanComparisonDataAuc((boxWhiskerAllMeanComparisonDataAuc) => [
						...boxWhiskerAllMeanComparisonDataAuc,
						newRes
					]);

					let allLettersAreA = _.map(res.letteringPairs, "letter").every((letters) => letters === "a");
					if (allLettersAreA) {
						_.map(res.letteringPairs, (d) => {
							d.letter = "-";
						});
					}
					setAllMeanComparisonDataAuc((allMeanComparisonDataAuc) => [...allMeanComparisonDataAuc, res]);
				})
				.catch((e) => {
					console.error(e);
					toast.error("Could not load mean comparison auc data");
				});
		}
	}

	function getIsDataUnbalanced(treatmentData) {
		let unbalancedData = false;
		if (treatmentData.length === 0) {
			return true;
		}

		let skipOutlierFilter = !_.some(
			treatmentData,
			(rd) => rd?.isOutlier3 !== undefined || rd?.isOutlier1_5 !== undefined
		);

		//-- Filter out exclusions if toggled and filter out outliers if toggled
		let filteredData = _.filter(
			treatmentData,
			(rd) =>
				!(
					(removeDamagedOrExcluded && rd.excludeFromAssessment === true) ||
					skipOutlierFilter ||
					(removeOutliers && outlierType === 3
						? rd.isOutlier3 === true && rd.isOutlier1_5 === false
						: removeOutliers && outlierType === 1.5
						? rd.isOutlier1_5 === true && rd.isOutlier3 === false
						: "")
				) && rd.analysisTypeId
		);

		//-- Grouping by treatment/replicate but will miss if only 1 flight is excluded
		let groupedTreatmentReplicateData = _.groupBy(
			filteredData,
			(rd) => `${rd.trialTreatmentId}|${rd.plotReplicate}|${rd.flightId}|${rd.analysisId}|${rd.analysisTypeId}`
		);

		let groupedTreatmentReplicateDataLength = Object.values(groupedTreatmentReplicateData)[0]?.length;
		_.forEach(Object.values(groupedTreatmentReplicateData), (d) => {
			if (d.length !== groupedTreatmentReplicateDataLength) {
				unbalancedData = true;
				return;
			}
		});

		//-- Grouping by treatments only to see if replicate length is unbalanced
		if (!unbalancedData) {
			let groupedTreatmentData = _.groupBy(
				filteredData,
				(rd) => `${rd.trialTreatmentId}|${rd.flightId}|${rd.analysisId}|${rd.analysisTypeId}`
			);

			let groupedTreatmentDataLength = Object.values(groupedTreatmentData)[0]?.length;
			_.forEach(Object.values(groupedTreatmentData), (d) => {
				if (d.length !== groupedTreatmentDataLength) {
					unbalancedData = true;
					return;
				}
			});
		}

		//-- Grouping by replicates only to see if treatment length is unbalanced
		if (!unbalancedData) {
			let groupedReplicateData = _.groupBy(
				filteredData,
				(rd) => `${rd.plotReplicate}|${rd.flightId}|${rd.analysisId}|${rd.analysisTypeId}`
			);

			let groupedReplicateDataLength = Object.values(groupedReplicateData)[0]?.length;
			_.forEach(Object.values(groupedReplicateData), (d) => {
				if (d.length !== groupedReplicateDataLength) {
					unbalancedData = true;
					return;
				}
			});
		}

		return unbalancedData;
	}

	function stageFilteredAnalysisResults(trialData, analysisId, analysisTypeId = null) {
		let res = _.filter(trialData, (trial) => {
			return (
				trial.analysisId === analysisId && (trial.analysisTypeId === analysisTypeId || trial.analysisTypeId === null)
			);
		});

		const wasThereAnUpdate = didAnalysisResultsUpdate(res);
		if (wasThereAnUpdate) {
			setChartAnalysisResults(res);
			recalculateGDUs(res);
			setFilteredAnalysisResults(res);
		}

		return wasThereAnUpdate;
	}

	function didAnalysisResultsUpdate(updatedAnalysisResults) {
		if (updatedAnalysisResults.length !== filteredAnalysisResults.length) {
			return true;
		}

		const equalCheck = _.filter(updatedAnalysisResults, (uar) => {
			return _.some(filteredAnalysisResults, (far) => {
				return _.isEqual(far, uar);
			});
		});

		if (equalCheck.length !== filteredAnalysisResults.length) {
			return true;
		}

		return false;
	}

	function updateFlightAndTreatmentSelections(flights, treatments) {
		let updatedAnalysisResults = [];
		let aucValues = [];

		_.map(flights, (flight) => {
			_.map(treatments, (treatment) => {
				if (treatment.checked === true) {
					const filteredAnalysisData = _.filter(analysisResults, (ar) => {
						return (
							ar.analysisId === selectedAnalysisOption &&
							ar.trialTreatmentId === treatment.trialTreatmentId &&
							ar.flightId === flight.flightId &&
							(ar.analysisTypeId === selectedAnalysisType || ar.analysisTypeId === null)
						);
					});

					_.map(filteredAnalysisData, (fad) => {
						fad.checked = flight.checked;
					});

					updatedAnalysisResults = updatedAnalysisResults.concat(filteredAnalysisData);
					if (flight.checked) {
						const aucFilteredValues = _.filter(rawData, (ad) => {
							return (
								ad.flightId === flight.flightId &&
								ad.trialTreatmentId === treatment.trialTreatmentId &&
								ad.analysisId === selectedAnalysisOption &&
								(ad.analysisTypeId === selectedAnalysisType ||
									ad.analysisTypeId === null ||
									ad.isPlantFlight ||
									ad.isHarvestFlight) &&
								(ad.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption ||
									ad.isPlantFlight ||
									ad.isHarvestFlight)
							);
						});
						aucValues = aucValues.concat(aucFilteredValues);
					}
				}
			});
		});

		setupMeanComparisonData(updatedAnalysisResults, true);
		setChartAnalysisResults(
			_.filter(updatedAnalysisResults, (uar) => {
				return uar.checked === true;
			})
		);
		recalculateGDUs(
			_.filter(updatedAnalysisResults, (uar) => {
				return uar.checked === true;
			})
		);
	}

	async function getMeanComparisonData(
		analysisId,
		isInitialLoad,
		analysisTypeId,
		quantifiedRegionTypeId,
		filteredRawData = null
	) {
		if (isInitialLoad) {
			setLoading(false);
			setLoadingStats(true);
		}

		//Set unchecked treatment Ids to filter them out
		let treatmentIds = [];
		_.filter(groundTrialDataChecked, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});

		let filteredData = _.orderBy(
			_.filter(
				filteredRawData ?? rawData,
				(d) =>
					d.analysisId === analysisId &&
					d.quantifiedRegionTypeId === quantifiedRegionTypeId &&
					(d.analysisTypeId === analysisTypeId || (d.analysisId === analysisId && d.analysisTypeId === null)) &&
					!_.includes(treatmentIds, d.trialTreatmentId)
			),
			["flightDate"],
			["asc"]
		);
		const requestData = {
			data: _.map(filteredData, (d) => {
				return {
					flightId: d.flightId,
					trialTreatmentId: d.trialTreatmentId,
					range: d.plotRange,
					plotReplicate: d.plotReplicate,
					value: d.plotAnalysisResultValue,
					excludeFromAssessment: d.excludeFromAssessment,
					isOutlier3: d.isOutlier3,
					isOutlier1_5: d.isOutlier1_5
				};
			})
		};

		let meanComparisonDataExists = _.some(reduxMeanComparisonData, (reduxMCData) => {
			return (
				selectedMeanComparisonOption == reduxMCData.meanComparisonId &&
				reduxMCData.alphaId == selectedAlphaOption &&
				reduxMCData.analysisId == analysisId
			);
		});

		if (meanComparisonDataExists) {
			let storedMeanComparisonData = _.find(reduxMeanComparisonData, (reduxMCData) => {
				return (
					selectedMeanComparisonOption == reduxMCData.meanComparisonId &&
					reduxMCData.alphaId == selectedAlphaOption &&
					reduxMCData.analysisId == analysisId
				);
			});

			setupFooterData(storedMeanComparisonData.letterData.data);
			setMeanComparisonData(storedMeanComparisonData.letterData.data);
			setLoading(false);
			setLoadingStats(false);
		} else if (!!analysisId && !!selectedMeanComparisonOption && !!selectedAlphaOption) {
			const accessToken = await getTokenSilently();
			dispatch(
				statisticsActions.getMeanComparisonForTimecourse(
					requestData,
					userAuth.currentClientId,
					analysisId,
					selectedMeanComparisonOption,
					selectedAlphaOption,
					accessToken,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType
				)
			)
				.then((res) => {
					if (isInitialLoad) {
						res.data.analysisTypeId = analysisTypeId;
						setMeanComparisonData(res.data);
						setupFooterData(res.data);
						setLoadingStats(false);
					} else {
						res.data.analysisTypeId = analysisTypeId;
						setAllMeanComparisonData((allMeanComparisonData) => [...allMeanComparisonData, res.data]);
						//setAllFooterData(allFooterData => [...allFooterData, res.data]);
					}

					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					toast.error("Could not get mean comparison data");
					setLoading(false);
					setLoadingStats(false);
				});
		}
	}

	function setupFooterData(data) {
		const hasPlantOrHarvest = data[0]?.treatments?.length !== data[1]?.treatments?.length ? true : false;
		const mean = _.map(data, (d) => {
			// footer data is same across all trials per flight
			return {
				data: d.treatments[0]?.mean,
				footerName: "Global Mean",
				include: hasPlantOrHarvest && d.treatments.length === 0 ? false : true
			};
		});
		const cv = _.map(data, (d) => {
			return {
				data: d.treatments[0]?.cv,
				footerName: "CV",
				include: hasPlantOrHarvest && d.treatments.length === 0 ? false : true
			};
		});
		const pValue = _.map(data, (d) => {
			return {
				data: d.treatments[0]?.letter !== "" ? d.treatments[0]?.pValue : null,
				footerName: "P-Value",
				include: hasPlantOrHarvest && d.treatments.length === 0 ? false : true
			};
		});

		setFooterData([mean, cv, pValue]);
	}

	function setupMeanComparisonData(updatedAnalysisResults, isInitialLoad) {
		let analysisResults = updatedAnalysisResults ?? _.cloneDeep(filteredAnalysisResults);
		let filteredAllMeanComparisonData = [];
		let filteredAllMeanComparisonDataByAnalysisId = [];

		filteredAllMeanComparisonDataByAnalysisId = _.filter(allMeanComparisonData, (ar) => {
			return ar.analysisTypeId === selectedAnalysisType;
		});

		_.filter(filteredAllMeanComparisonDataByAnalysisId, (ar) => {
			_.filter(ar, (analysis) => {
				if (analysis.analysisId === selectedAnalysisOption) {
					filteredAllMeanComparisonData.push(analysis);
				}
			});
		});

		const tempMeanComparisonData = isInitialLoad ? meanComparisonData : filteredAllMeanComparisonData;
		_.map(analysisResults, (uar) => {
			if (tempMeanComparisonData.length > 0) {
				const flightAnalysis = _.find(tempMeanComparisonData, (t) => {
					return t.analysisId === uar.analysisId && t.flightId === uar.flightId;
				});

				if (flightAnalysis) {
					const allMeanComparisonLetterA = _.every(flightAnalysis.treatments, (t) => {
						return t.letter === "a";
					});

					let treatment = "-";
					if (!allMeanComparisonLetterA) {
						treatment = _.find(flightAnalysis.treatments, (t) => {
							return t.trialTreatmentId === uar.trialTreatmentId;
						})?.letter;
					}
					if (uar.isPlantFlight || uar.isHarvestFlight) {
						uar.letter = "";
					} else {
						uar.letter = treatment ? treatment : "";
					}
				}
			}
		});

		if (!updatedAnalysisResults) {
			setFilteredAnalysisResults(analysisResults);
		}
	}

	function updateSelectedAnalysis(value) {
		setSelectedAnalysisOption(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setSelectedQuantifiedRegionTypeOption(value);
	}

	function updateSelectedAnalysisType(value) {
		setSelectedAnalysisType(value);
	}

	//-- Function will handle whether we need to re-pull non/excluded plot values or not
	async function handleGetRemovedOrExcludedAnalytics() {
		if (removeDamagedOrExcluded !== previousRemoveDamagedOrExcludedFlag || needToReloadData) {
			setLoadingStats(true);
			const accessToken = await getTokenSilently();

			dispatch(
				analysisActions.getAnalysisResultsForTrial(
					userAuth.currentClientId,
					moduleNavigation.trialId,
					accessToken,
					"",
					"",
					"",
					"",
					true,
					"",
					false,
					removeDamagedOrExcluded
				)
			).then((res) => {
				res.plotAnalysisResults = _.filter(res.plotAnalysisResults, (par) => !par.analysisName.includes("Stand Count"));
				let filteredPlotAnalysisResults = _.filter(res.plotAnalysisResults, (r) => r.curveModelDataAnalysisId === null);

				filteredPlotAnalysisResults = filterRawAnalysisData(filteredPlotAnalysisResults);

				stageTreatmentData(filteredPlotAnalysisResults, trialDataResults);

				updateAnalytics(filteredPlotAnalysisResults);
			});
		} else {
			updateAnalytics();
		}

		setPreviousRemoveDamagedOrExcludedFlag(removeDamagedOrExcluded);
	}

	function getFilteredAUCValues(analysis, analysisTypeId, analysisRes) {
		let filteredAucValues = [];
		let updatedAnalysisResults = [];
		_.map(flights, (flight) => {
			_.map(groupedTrialData, (treatment) => {
				if (treatment.checked === true) {
					const filteredAnalysisData = _.filter(analysisResults, (ar) => {
						return (
							ar.analysisId === analysis &&
							ar.trialTreatmentId === treatment.trialTreatmentId &&
							ar.flightId === flight.flightId &&
							(ar.analysisTypeId === analysisTypeId || ar.analysisTypeId === null)
						);
					});

					_.map(filteredAnalysisData, (fad) => {
						fad.checked = flight.checked;
					});

					updatedAnalysisResults = updatedAnalysisResults.concat(filteredAnalysisData);
					if (flight.checked) {
						const aucFilteredValues = _.filter(analysisRes, (ad) => {
							return (
								ad.flightId === flight.flightId &&
								ad.trialTreatmentId === treatment.trialTreatmentId &&
								ad.analysisId === analysis &&
								(ad.analysisTypeId === analysisTypeId ||
									ad.analysisTypeId === null ||
									ad.isPlantFlight ||
									ad.isHarvestFlight) &&
								(ad.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption ||
									ad.isPlantFlight ||
									ad.isHarvestFlight)
							);
						});
						filteredAucValues = filteredAucValues.concat(aucFilteredValues);
					}
				}
			});
		});
		return filteredAucValues;
	}

	//-- Overriding filteredData to not run into race condition with setting stateful obj
	function updateAnalytics(customFilteredData = null) {
		//-- Do not update analytics for 2 treatment x 2 replicate case
		let treatmentCount = _.filter(groundTrialDataChecked, (c) => c)?.length;
		let replicateCount = _.uniqBy(customFilteredData ?? filteredData, "plotReplicate")?.length;
		if (treatmentCount === 2 && replicateCount === 2) {
			toast.error(
				"Too few observations to run mean comparison tests. Please select more treatments or add more replicates.",
				{
					autoClose: 10000,
					toastId: "meanComparisonBlock"
				}
			);
			return;
		}

		//-- Removes long toast message above
		toast.dismiss("meanComparisonBlock");

		//Reinitialize states
		setHideLetters(false);
		setHideJustAUCLetters(false);
		setLoadingStats(true);
		setAllMeanComparisonData([]);
		setAllAucData([]);
		//setAllFooterData([]);
		setAllMeanComparisonDataAuc([]);
		setAucDataInitialRun(true);
		setInitialDataMessageSent(false);
		setBoxWhiskerMeanComparisonDataAuc(null);
		setBoxWhiskerAllMeanComparisonDataAuc([]);
		let treatmentIds = [];
		_.filter(groundTrialDataChecked, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});
		//-- This will handle race condition with filteredData being set in getAnalysisData function
		let localFilteredData = _.cloneDeep(filteredData);
		localFilteredData = _.filter(localFilteredData, (lfd) => {
			return !_.includes(treatmentIds, lfd.trialTreatmentId);
		});

		const qrDataMatches =
			(customFilteredData ?? localFilteredData.length > 0) &&
			_.some(customFilteredData ?? localFilteredData, (fd) => {
				return fd.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption;
			});
		if (
			selectedAnalysisOption &&
			(customFilteredData ?? localFilteredData.length > 0) &&
			selectedMeanComparisonOption &&
			selectedAlphaOption &&
			qrDataMatches
		) {
			setHideChartsAndTables(false);
			const analyses = _.uniqBy(customFilteredData ?? localFilteredData, "analysisId").map(function (analysis) {
				return { key: analysis.analysisId, value: analysis.analysisId, text: analysis.analysisName };
			});

			let filteredRawData = createEmptyPlantAndHarvestFlights(customFilteredData ?? localFilteredData, analyses);

			filteredRawData = filterRawAnalysisData(filteredRawData);

			//-- Filter based on checked flights
			let filteredFlightsFromFilteredRawData = _.filter(filteredRawData, (d) => {
				return (
					_.find(
						flightDataChecked,
						(fdc) =>
							fdc.flightId === d.flightId ||
							(fdc.isPlantFlight && d.isPlantFlight) ||
							(fdc.isHarvestFlight && d.isHarvestFlight)
					)?.checked ?? true
				);
			});
			let filteredAucValues = getFilteredAUCValues(
				selectedAnalysisOption,
				selectedAnalysisType,
				filteredFlightsFromFilteredRawData
			);

			stageAucData(
				_.filter(customFilteredData ? filteredFlightsFromFilteredRawData : filteredAucValues, (fd) => {
					return fd.analysisTypeId == selectedAnalysisType || fd.analysisTypeId === null;
				}),
				selectedAnalysisOption,
				true
			);

			let uniqueAnalyses = _.uniqBy(customFilteredData ?? localFilteredData, (rd) =>
				[rd.analysisId, rd.analysisTypeId].join()
			);
			uniqueAnalyses = _.filter(uniqueAnalyses, (ua) => {
				return ua.analysisTypeId !== null;
			});

			_.map(uniqueAnalyses, (analysis) => {
				let filteredAucValues = getFilteredAUCValues(
					analysis.analysisId,
					analysis.analysisTypeId,
					filteredFlightsFromFilteredRawData
				);

				let aucValues = timecourseCalculations.getAreaUnderTheCurve(
					_.filter(customFilteredData ? filteredFlightsFromFilteredRawData : filteredAucValues, (fd) => {
						return fd.analysisTypeId == analysis.analysisTypeId || fd.analysisTypeId === null;
					}),
					analysis.analysisId,
					trialDataResults,
					useGDU,
					cumulativeGdus,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType
				);
				aucValues.analysisTypeId = analysis.analysisTypeId;
				setAllAucData((allAucData) => [...allAucData, aucValues]);
				stageAllAucData(
					customFilteredData ? filteredFlightsFromFilteredRawData : filteredAucValues,
					analysis.analysisId,
					true,
					analysis.quantifiedRegionTypeId,
					analysis.analysisTypeId
				);
			});

			if (runMeanComparison) {
				getMeanComparisonData(
					selectedAnalysisOption,
					true,
					selectedAnalysisType,
					selectedQuantifiedRegionTypeOption,
					filteredRawData
				);

				let uniqueAnalyses = _.uniqBy(customFilteredData ?? localFilteredData, (rd) =>
					[rd.analysisId, rd.analysisTypeId].join()
				);
				uniqueAnalyses = _.filter(uniqueAnalyses, (ua) => {
					return ua.analysisTypeId !== null;
				});

				_.map(uniqueAnalyses, (analysis) => {
					let findAnalysisTypeId = _.find(rawData, (rd) => {
						return rd.analysisId === analysis.analysisId && rd.analysisTypeId === analysis.analysisTypeId;
					});

					let tempQuantifiedRegionTypeId = _.find(rawPresetOptions.timeSeriesPresets, (tsp) => {
						return (
							tsp.analysisId === findAnalysisTypeId?.analysisId &&
							tsp.analysisTypeId === findAnalysisTypeId?.analysisTypeId
						);
					});

					getMeanComparisonData(
						analysis.analysisId,
						false,
						findAnalysisTypeId?.analysisTypeId ?? analysis.analysisTypeId,
						tempQuantifiedRegionTypeId?.quantifiedRegionTypeId,
						filteredRawData
					);
				});
			} else {
				setHideChartsAndTables(false);
			}
		}

		if (weatherDataEnabled) {
			if (gduByDay.length > 0 && !cumulativeGdus) {
				let calcGDUS = timecourseCalculations.calculateCumulativeGdu(chartAnalysisResults, gduByDay);
				setCumulativeGDUs(calcGDUS);
			}
		} else {
			updateFlightAndTreatmentSelections(flights, groupedTrialData);
		}

		let treatmentsChecked = _.map(groupedTrialData, (trialData) => {
			return trialData.checked;
		});
		let flightsChecked = _.map(flightDataChecked, (fdc) => {
			return fdc.checked;
		});

		setInitialGroupTrialDataCheckedCount(treatmentsChecked);
		setInitialFlightDataCheckedCount(flightsChecked);
		setNeedToUpdateAnalytics(false);
		setNeedToReloadData(false);
		setNeedToRerunStats(false);
	}

	function buildGduErrorPopup() {
		if (!hasGduFormula) {
			return (
				<Popup
					content="There currently is no formula for Growing Degree Units for this type of crop."
					trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
				/>
			);
		} else if (!plantDate) {
			return (
				<Popup
					content="Plant Date is required to calculate Growing Degree Units. Please add a plant date to this trial."
					trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
				/>
			);
		}
	}

	return loading ? (
		<Loader className={styles.workaround} active inline="centered" />
	) : !loading && (_.isEmpty(analysisResults) || analysisDataResults?.length === 0) ? (
		<Segment style={{ width: "100%" }}>
			<p>{"We could not find summary data for this trial."}</p>
		</Segment>
	) : (
		<Segment basic>
			{showAnalysisSelection && filteredData?.length > 0 ? (
				<AnalysisDropDownLists
					rawPresetOptions={rawPresetOptions}
					allAssessmentTypes={analysisDropDownListFunctions.getOrthoTypes(rawData)}
					quantifiedRegionTypes={quantifiedRegionTypeOptions}
					defaultQuantifiedRegion={
						_.find(rawData, (r) => {
							return r.isDefaultQuantifiedRegion === true;
						})?.quantifiedRegionTypeId
					}
					updateSelectedAnalysis={updateSelectedAnalysis}
					updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
					updatedSelectedAnalysisType={updateSelectedAnalysisType}
					hideAnalyticTypes={true}
					setSelectedAssessmentName={setSelectedAssessmentName}
					isApAdmin={userAuth.isApAdmin}
					loadingStats={loadingStats}
					allMeanComparisonData={allMeanComparisonData}
					uniqueAnalysesCount={_.filter(
						_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
						(u) => {
							return u.analysisTypeId !== null;
						}
					)}
					isForAnalysisTimecoursePage={isForAnalysisTimecoursePage}
					setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
					setNeedToReloadData={setNeedToReloadData}
					defaultSelectedAnalysisType={selectedAnalysisType}
					defaultSelectedAnalysisOption={selectedAnalysisOption}
					defaultSelectedQuantifiedRegionTypeOption={selectedQuantifiedRegionTypeOption}
					afterElement={
						<div style={{ display: "flex", flexDirection: "column", paddingLeft: 55, paddingTop: 15 }}>
							<label htmlFor="checkbox-toggle-graph-colors" style={{ display: "unset", width: "fit-content" }}>
								Disable Graph Colors
							</label>
							<Checkbox
								id="checkbox-toggle-graph-colors"
								value={disableColors}
								onChange={() => setDisableColors(!disableColors)}
								toggle
							/>
						</div>
					}
				>
					<div>
						<label htmlFor="dropdown-select-mean-comparison" style={{ display: "unset" }}>
							Mean Comparisons
						</label>
						<Dropdown
							id="dropdown-select-mean-comparison"
							selection
							fluid
							search
							options={meanComparisonOptions}
							onChange={(event, { value }) => {
								if (
									_.filter(
										_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
										(u) => {
											return u.analysisTypeId !== null;
										}
									).length === allMeanComparisonData.length
								) {
									setSelectedMeanComparisonOption(value);
									setNeedToUpdateAnalytics(true);
									setNeedToRerunStats(true);
								}
							}}
							value={selectedMeanComparisonOption}
							disabled={
								loadingStats ||
								_.filter(
									_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
									(u) => {
										return u.analysisTypeId !== null;
									}
								).length !== allMeanComparisonData.length
							}
							style={{
								color:
									loadingStats ||
									_.filter(
										_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
										(u) => {
											return u.analysisTypeId !== null;
										}
									).length !== allMeanComparisonData.length
										? "gray"
										: "unset"
							}}
						/>
					</div>
					<div>
						<label htmlFor="dropdown-select-alpha" style={{ display: "unset" }}>
							Alpha
						</label>
						<Dropdown
							id="dropdown-select-alpha"
							selection
							fluid
							search
							options={alphaOptions}
							onChange={(event, { value }) => {
								if (
									_.filter(
										_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
										(u) => {
											return u.analysisTypeId !== null;
										}
									).length === allMeanComparisonData.length
								) {
									setSelectedAlphaOption(value);
									setNeedToUpdateAnalytics(true);
									setNeedToRerunStats(true);
								}
							}}
							value={selectedAlphaOption}
							disabled={
								loadingStats ||
								_.filter(
									_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
									(u) => {
										return u.analysisTypeId !== null;
									}
								).length !== allMeanComparisonData.length
							}
							style={{
								color:
									loadingStats ||
									_.filter(
										_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
										(u) => {
											return u.analysisTypeId !== null;
										}
									).length !== allMeanComparisonData.length
										? "gray"
										: "unset"
							}}
						/>
					</div>
					<div style={{ display: "grid" }}>
						<label htmlFor="checkbox-use-gdus-timecourse" style={{ display: "unset" }}>
							Use GDUs
						</label>
						{weatherDataEnabled && plantDate && hasGduFormula ? (
							<Checkbox
								toggle
								id="checkbox-use-gdus-timecourse"
								checked={useGDU}
								onChange={(e, { checked }) => {
									setUseGDU(checked);
									setNeedToUpdateAnalytics(true);
								}}
								disabled={
									loadingStats ||
									_.filter(
										_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
										(u) => {
											return u.analysisTypeId !== null;
										}
									).length !== allMeanComparisonData.length
								}
							/>
						) : (
							<>
								<Checkbox toggle readOnly={true} />
								{buildGduErrorPopup()}
							</>
						)}
					</div>
					<div style={{ display: "grid" }}>
						<label htmlFor="form-assessment">
							Remove Exclusions
							<Popup
								trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
								content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
							/>
						</label>
						<DataAnalysisCriteria
							removeDamagedOrExcluded={removeDamagedOrExcluded}
							removeOutliers={removeOutliers}
							outlierType={outlierType}
							setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
							setRemoveOutliers={setRemoveOutliers}
							setOutlierType={setOutlierType}
							setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
							disabled={
								loadingStats ||
								_.filter(
									_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
									(u) => {
										return u.analysisTypeId !== null;
									}
								).length !== allMeanComparisonData.length
							}
						/>
					</div>
					<div style={{ alignSelf: "end" }}>
						<Button
							primary={!needToUpdateAnalytics}
							negative={needToUpdateAnalytics}
							content="Update Analytics"
							onClick={() => (needToUpdateAnalytics ? handleGetRemovedOrExcludedAnalytics() : null)}
							disabled={
								loading ||
								loadingStats ||
								_.filter(
									_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
									(u) => {
										return u.analysisTypeId !== null;
									}
								).length !== allMeanComparisonData.length
							}
							loading={loadingStats}
						/>
					</div>
				</AnalysisDropDownLists>
			) : (
				<></>
			)}
			{showTable && !hideChartsAndTables ? (
				<AreaUnderTheCurveTable
					data={filteredAnalysisResults}
					aucData={aucData}
					updateFlightAndTreatmentSelections={updateFlightAndTreatmentSelections}
					groupedTrialData={groupedTrialData}
					setGroupedTrialData={setGroupedTrialData}
					groundTrialDataChecked={groundTrialDataChecked}
					flights={flights}
					setFlights={setFlights}
					flightDataChecked={flightDataChecked}
					footerData={footerData}
					flightsInCurveModel={flightsInCurveModel}
					runMeanComparison={runMeanComparison}
					gduByDay={gduByDay}
					loadingStats={loadingStats}
					setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
					meanComparisonDataAuc={
						_.isEmpty(allMeanComparisonDataAuc) ||
						selectedAssessmentName === null ||
						selectedAssessmentName === undefined
							? meanComparisonDataAuc
							: _.find(allMeanComparisonDataAuc, (amd) => {
									return amd.analysisId === selectedAnalysisOption && amd.analysisTypeId === selectedAnalysisType;
							  })
					}
					needToUpdateAnalytics={needToUpdateAnalytics}
					initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
					hideLetters={hideLetters}
					setHideLetters={setHideLetters}
					initialFlightDataCheckedCount={initialFlightDataCheckedCount}
					hideJustAUCLetters={hideJustAUCLetters}
					setHideJustAUCLetters={setHideJustAUCLetters}
					needToRerunStats={needToRerunStats}
					allMeanComparisonData={allMeanComparisonData}
					uniqueAnalysesCount={_.filter(
						_.uniqBy(filteredData, (rd) => [rd.analysisId, rd.analysisTypeId].join()),
						(u) => {
							return u.analysisTypeId !== null;
						}
					)}
					isForAnalysisTimecoursePage={isForAnalysisTimecoursePage}
				/>
			) : null}

			<Grid>
				<Grid.Row>
					{loadingStats ? (
						<Loader active size="small" />
					) : showLineChart &&
					  !hideChartsAndTables &&
					  (chartAnalysisResults.length > 0 ||
							!_.some(groundTrialDataChecked, (gtdc) => {
								return gtdc === true;
							})) ? (
						<Grid.Column width={showBoxWhisker === true ? "8" : "16"}>
							<LineChart
								data={chartAnalysisResults}
								analysisName={
									selectedAssessmentName ??
									_.find(analysisResults, (ar) => ar.analysisId === selectedAnalysisOption)?.analysisName
								}
								cumulativeGDUs={cumulativeGdus}
								useGDU={useGDU}
							/>
						</Grid.Column>
					) : null}

					{loadingStats ? (
						<Loader active size="small" />
					) : (
						showBoxWhisker &&
						!hideChartsAndTables &&
						aucData.length > 0 && (
							<Grid.Column width="8">
								<AucBoxWhiskerChart
									data={aucData}
									allData={[]}
									analysisName={
										selectedAssessmentName ??
										_.find(analysisResults, (ar) => ar.analysisId === selectedAnalysisOption)?.analysisName
									}
									meanComparison={selectedMeanComparisonOption}
									alphaValue={selectedAlphaOption}
									runMeanComparison={runMeanComparison}
									needToUpdateAnalytics={needToUpdateAnalytics}
									isInitialLoad={aucDataInitialRun}
									selectedAnalysisOption={selectedAnalysisOption}
									groundTrialDataChecked={groundTrialDataChecked}
									initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
									initialFlightDataCheckedCount={initialFlightDataCheckedCount}
									flightDataChecked={flightDataChecked}
									needToRerunStats={needToRerunStats}
									timeSeriesPresets={rawPresetOptions?.timeSeriesPresets}
									setIsBoxWhiskerChartLoading={setIsBoxWhiskerChartLoading}
									boxWhiskerMeanComparisonDataAuc={boxWhiskerMeanComparisonDataAuc}
									boxWhiskerAllMeanComparisonDataAuc={boxWhiskerAllMeanComparisonDataAuc}
									selectedAnalysisType={selectedAnalysisType}
									disableColors={disableColors}
								/>
							</Grid.Column>
						)
					)}
					{hideChartsAndTables ? (
						<Grid.Column width="8">There is no data to display for the DAP range</Grid.Column>
					) : (
						<></>
					)}
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

TimecourseComponent.propTypes = {
	showAnalysisSelection: PropTypes.bool,
	showTable: PropTypes.bool,
	showLineChart: PropTypes.bool,
	showBoxWhisker: PropTypes.bool,
	runMeanComparison: PropTypes.bool,
	analysisDataResults: PropTypes.array,
	trialDataResults: PropTypes.object,
	viewPlantFlight: PropTypes.bool,
	viewHarvestFlight: PropTypes.bool,
	getSelectedFlights: PropTypes.func,
	getAucData: PropTypes.func,
	getOtherAucData: PropTypes.func,
	analysis: PropTypes.string,
	flightsInCurveModel: PropTypes.array,
	dapStart: PropTypes.number,
	dapEnd: PropTypes.number,
	plantDate: PropTypes.string,
	allMeanComparisonOptions: PropTypes.object,
	setCheckedTreatmentData: PropTypes.func,
	gduList: PropTypes.array,
	useGDUTimecourse: PropTypes.bool,
	getHasGduFormula: PropTypes.func,
	quantifiedRegionTypes: PropTypes.array,
	quantifiedRegionTypeValue: PropTypes.string,
	rawPresetOptions: PropTypes.array,
	parentSelectedAnalysisType: PropTypes.string,
	isForAnalysisTimecoursePage: PropTypes.bool
};

export default TimecourseComponent;
