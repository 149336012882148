import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Divider, Form, Icon, Modal } from "semantic-ui-react";

interface ParcelModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSave: (props: GeoJSON.GeoJsonProperties) => void;
	parcelFeature: GeoJSON.Feature;
	stateOptions: { key: string; value: string; name: string }[];
	onDelete: (ll_uuid: string) => void;
	stateAbbreviationMap: { [key: string]: string };
}

const ParcelMetaDataModal = ({
	open,
	setOpen,
	onSave,
	parcelFeature,
	stateOptions,
	onDelete,
	stateAbbreviationMap
}: ParcelModalProps) => {
	const [featureProps, setFeatureProps] = useState(parcelFeature?.properties);

	useEffect(() => {
		console.log(parcelFeature, stateAbbreviationMap);
		if (parcelFeature?.properties && stateAbbreviationMap) {
			parcelFeature.properties.fields.stateId = stateAbbreviationMap[parcelFeature.properties.fields.state2];
			setFeatureProps(parcelFeature.properties);
		}
	}, [parcelFeature, stateAbbreviationMap]);

	useEffect(() => {
		// console.log(featureProps);
	}, [featureProps]);

	const setFeatureProp = useCallback((prop: typeof featureProps) => {
		setFeatureProps((prev) => {
			if (!prop) return prev;
			if (prev?.fields && prop.fields) {
				const fields = { ...prev.fields, ...prop.fields };
				return { ...prev, ...prop, fields };
			}
			return { ...prev, ...prop };
		});
	}, []);

	return (
		<Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
			<Modal.Header>
				Parcel Metadata
				<Icon name="close" link onClick={() => setOpen(false)} />
			</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Group widths="equal">
						<Form.Input
							label="Parcel Name"
							id="form-input-parcel-name"
							required
							value={featureProps?.headline}
							onChange={(_, data) => {
								setFeatureProp({ headline: data.value });
							}}
						/>
						<Form.Input
							label="Parcel Owner Name"
							id="form-input-parcel-ownr"
							value={featureProps?.fields.owner}
							onChange={(_, data) => {
								setFeatureProp({ fields: { owner: data.value } });
							}}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Input
							label="Parcel Address 1"
							id="form-input-parcel-add1"
							value={featureProps?.fields.address}
							onChange={(_, data) => {
								setFeatureProp({ fields: { address: data.value } });
							}}
						/>
						<Form.Input
							label="Parcel Address 2"
							id="form-input-parcel-add2"
							value={featureProps?.fields.address2}
							onChange={(_, data) => {
								setFeatureProp({ fields: { address2: data.value } });
							}}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Input
							label="Parcel City"
							id="form-input-parcel-city"
							value={featureProps?.fields.city}
							onChange={(_, data) => {
								setFeatureProp({ fields: { city: data.value } });
							}}
						/>
						<Form.Select
							label="Parcel State"
							id="form-input-parcel-state"
							value={featureProps?.fields.stateId}
							options={stateOptions}
							onChange={(_, data) => {
								setFeatureProp({ fields: { stateId: data.value } });
							}}
						/>
						<Form.Input
							label="Parcel Zip"
							id="form-input-parcel-zip"
							value={featureProps?.fields.szip5}
							onChange={(_, data) => {
								setFeatureProp({ fields: { szip5: data.value } });
							}}
						/>
						<Form.Input
							label="Parcel County"
							id="form-input-parcel-cnty"
							value={featureProps?.fields.county}
							onChange={(_, data) => {
								setFeatureProp({ fields: { county: data.value } });
							}}
						/>
					</Form.Group>
					<Form.Group widths={4}>
						<Form.Input
							label="Parcel Size (acres)"
							id="form-input-parcel-add1"
							value={featureProps?.fields.ll_gisacre}
							onChange={(_, data) => {
								setFeatureProp({ fields: { ll_gisacre: data.value } });
							}}
						/>
					</Form.Group>
					<Divider />
					<Form.Group style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginTop: 15, marginBottom: 0 }}>
						<Form.Button
							compact
							negative
							content="Delete"
							onClick={(e) => {
								e.preventDefault();
								//
								if (featureProps) {
									onDelete(featureProps.ll_uuid as string);
								}
								setOpen(false);
							}}
						/>
						<Form.Button
							compact
							positive
							content="Save"
							onClick={(e) => {
								e.preventDefault();
								if (!featureProps?.headline || featureProps.headline.trim() === "") {
									toast.error("Parcels must have a name");
									console.error("Parcels must have a name");
									return;
								}
								onSave(featureProps);
								setOpen(false);
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Content>
		</Modal>
	);
};

export default ParcelMetaDataModal;
