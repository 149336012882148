import React from "react";

import { Segment } from "semantic-ui-react";

import AssessmentLog from "../../../Lumber/AssessmentLog";

const FieldAssessmentLog = () => {
	return (
		<Segment style={{ margin: 15 }}>
			<AssessmentLog isTrial={false} isField={true} />
		</Segment>
	);
};

export default FieldAssessmentLog;
