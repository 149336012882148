import React from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, KeyboardHandle, Track, Tick } from "./sliderComponents";
import "./styles.css";

const sliderStyle: React.CSSProperties = {
	position: "relative",
	height: "150px",
	marginLeft: "45%",
	touchAction: "none"
};

interface VerticalSliderProps {
	domain: [number, number];
	mode: 1 | 2 | 3;
	step?: number;
	reversed?: boolean;
	onChange?: (v: readonly number[]) => void;
	onUpdate?: (v: readonly number[]) => void;
	values: number[];
}

const VerticalSlider = ({ domain, mode, step, reversed, onChange, onUpdate, values }: VerticalSliderProps) => {
	return (
		<Slider
			vertical
			reversed={reversed}
			mode={mode}
			step={step}
			domain={domain}
			rootStyle={sliderStyle}
			onChange={onChange}
			onUpdate={onUpdate}
			values={values}
		>
			<Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
			<Handles>
				{({ handles, getHandleProps }) => (
					<div className="slider-handles">
						{handles.map((handle) => (
							<KeyboardHandle key={handle.id} handle={handle} domain={domain} getHandleProps={getHandleProps} />
						))}
					</div>
				)}
			</Handles>
			<Tracks left={false} right={false}>
				{({ tracks, getTrackProps }) => (
					<div className="slider-tracks">
						{tracks.map(({ id, source, target }) => (
							<Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
						))}
					</div>
				)}
			</Tracks>
			<Ticks count={2}>
				{({ ticks }) => (
					<div className="slider-ticks">
						{ticks.map((tick) => (
							<Tick key={tick.id} tick={tick} format={(d) => (d * 100).toString() + "%"} />
						))}
					</div>
				)}
			</Ticks>
		</Slider>
	);
};

export default VerticalSlider;
