import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import doubleClickZoom from "@mapbox/mapbox-gl-draw/src/lib/double_click_zoom.js";
// import createVertex from "./createVertex";

const PolygonMeasureMode = MapboxDraw.modes.draw_polygon;

PolygonMeasureMode.onSetup = function () {
	const polygon = this.newFeature({
		type: Constants.geojsonTypes.FEATURE,
		properties: {
			meta: "drawn_feature"
		},
		geometry: {
			type: Constants.geojsonTypes.POLYGON,
			coordinates: [[]]
		}
	});

	this.addFeature(polygon);

	this.clearSelectedFeatures();
	doubleClickZoom.disable(this);
	this.updateUIClasses({ mouse: Constants.cursors.ADD });
	this.activateUIButton(Constants.types.POLYGON);
	this.setActionableState({
		trash: true
	});

	return {
		polygon,
		currentVertexPosition: 0
	};
};

// PolygonMeasureMode.toDisplayFeatures = function(state, geojson, display) {
//   const isActivePolygon = geojson.properties.id === state.polygon.id;
//   geojson.properties.active = (isActivePolygon) ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
//   if (!isActivePolygon) return display(geojson);

//   // Don't render a polygon until it has two positions
//   // (and a 3rd which is just the first repeated)
//   if (geojson.geometry.coordinates.length === 0) return;

//   const coordinateCount = geojson.geometry.coordinates[0].length;
//   // 2 coordinates after selecting a draw type
//   // 3 after creating the first point
//   if (coordinateCount < 3) {
//     return;
//   }
//   geojson.properties.meta = Constants.meta.FEATURE;
//   display(createVertex(state.polygon.id, geojson.geometry.coordinates[0][0], '0.0', false));
//   if (coordinateCount > 3) {
//     // Add a start position marker to the map, clicking on this will finish the feature
//     // This should only be shown when we're in a valid spot
//     const endPos = geojson.geometry.coordinates[0].length - 3;
//     display(createVertex(state.polygon.id, geojson.geometry.coordinates[0][endPos], `0.${endPos}`, false));
//   }
//   if (coordinateCount <= 4) {
//     // If we've only drawn two positions (plus the closer),
//     // make a LineString instead of a Polygon
//     const lineCoordinates = [
//       [geojson.geometry.coordinates[0][0][0], geojson.geometry.coordinates[0][0][1]], [geojson.geometry.coordinates[0][1][0], geojson.geometry.coordinates[0][1][1]]
//     ];
//     // create an initial vertex so that we can track the first point on mobile devices
//     display({
//       type: Constants.geojsonTypes.FEATURE,
//       properties: geojson.properties,
//       geometry: {
//         coordinates: lineCoordinates,
//         type: Constants.geojsonTypes.LINE_STRING
//       }
//     });
//     if (coordinateCount === 3) {
//       return;
//     }
//   }
//   // render the Polygon
//   return display(geojson);
// };

export default PolygonMeasureMode;
