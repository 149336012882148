// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card {\n\twidth: 300px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tcolor: var(--text);\n\tbox-shadow: var(--map-box-shadow);\n\tbackground-color: var(--background);\n\tborder: 1px solid var(--border);\n\tpadding: 15px;\n\tmargin: 5px;\n\tborder-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/AdminTools/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,sBAAsB;CACtB,kBAAkB;CAClB,iCAAiC;CACjC,mCAAmC;CACnC,+BAA+B;CAC/B,aAAa;CACb,WAAW;CACX,kBAAkB;AACnB","sourcesContent":[".card {\n\twidth: 300px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tcolor: var(--text);\n\tbox-shadow: var(--map-box-shadow);\n\tbackground-color: var(--background);\n\tborder: 1px solid var(--border);\n\tpadding: 15px;\n\tmargin: 5px;\n\tborder-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card"
};
export default ___CSS_LOADER_EXPORT___;
