import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import * as trialActions from "../../../../redux/actions/trialActions";

import { Message, Segment } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import Ortho from "../../../Lumber/OrthoViewer";

import { useAuth0 } from "../../../../auth/auth0";
import "./style.css";

const TrialViewer = () => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	//-- UI Control
	const [imageFound, setImageFound] = useState(null);
	const [dataDone, setDataDone] = useState(true);

	useEffect(() => {
		getPlotAnalysisStatus();
	}, []);

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			trialActions.getPlotAnalysisStatusForTrial(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	return imageFound === false ? (
		<Segment style={{ margin: 15 }}>
			<p>{"We could not find this trial."}</p>
		</Segment>
	) : (
		<Segment basic style={{ height: "100%" }}>
			{!dataDone && (
				<Message warning>
					Plot analysis data for this trial is currently being recalculated, so data may not be complete or up to date.
					Please refresh the page in a few minutes.
				</Message>
			)}
			<Ortho
				level="trial"
				edit={true}
				viewer={true}
				setOrthoImageTypes={() => {}}
				setDraw={() => {}}
				setImageFound={(truth) => setImageFound(truth)}
			/>
		</Segment>
	);
};

export default TrialViewer;
