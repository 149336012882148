import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { Segment, Table, Pagination, Dropdown, Icon } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const FieldSearchResults = ({ fieldList, trialData, userAuth }) => {
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "MM/DD/YYYY";

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState(fieldList);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- Initial view load
	useEffect(() => {
		if (fieldList) {
			setTotalPages(Math.ceil(fieldList.length / itemsPerPage));
			setDataSource(fieldList);
			setTableData(dataSource);
		}
	}, [fieldList]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/plantDate|lastFlightDate/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return d[clickedColumn];
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|plotWidth|totalFlights/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase().trim() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			return;
		}
		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
	};

	// prettier-ignore
	return (<Segment basic style={{ padding: 0 }}>
		<span style={{ float: "right" }}>{dataSource.length === 1 ? `${dataSource.length} field found` : `${dataSource.length} fields found`}</span>
		<div style={{ clear: "both" }} />
		<div style={{ overflowX: "auto" }}>
			<Table celled striped sortable selectable textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={sortColumn === 'farmName' ? sortDirection : null}
							onClick={() => handleSort('farmName')}>Farm</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'fieldName' ? sortDirection : null}
							onClick={() => handleSort('fieldName')}>Field</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'clientName' ? sortDirection : null}
							onClick={() => handleSort('clientName')}>Client</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'cooperatorName' ? sortDirection : null}
							onClick={() => handleSort('cooperatorName')}>Cooperator</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'city' ? sortDirection : null}
							onClick={() => handleSort('city')}>City</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'stateName' ? sortDirection : null}
							onClick={() => handleSort('stateName')}>State</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'cropName' ? sortDirection : null}
							onClick={() => handleSort('cropName')}>Crop</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'growingSeasonName' ? sortDirection : null}
							onClick={() => handleSort('growingSeasonName')}>Growing Season</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'plantDate' ? sortDirection : null}
							onClick={() => handleSort('plantDate')}>Plant Date</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'numOfColumns' ? sortDirection : null}
							onClick={() => handleSort('numOfColumns')}>Columns</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'numOfRanges' ? sortDirection : null}
							onClick={() => handleSort('numOfRanges')}>Ranges</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'plotWidth' ? sortDirection : null}
							onClick={() => handleSort('plotWidth')}>Plot Size</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'lastFlightDate' ? sortDirection : null}
							onClick={() => handleSort('lastFlightDate')}>Last Flight Date</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'totalFlights' ? sortDirection : null}
							onClick={() => handleSort('totalFlights')}>Total Flights</Table.HeaderCell>
						<Table.HeaderCell>Trials</Table.HeaderCell>
						<Table.HeaderCell>
							Actions
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{
						_.map(tableData, ({ fieldId, farmId, farmAbbr, fieldName, clientName, cooperatorName, stateName, city, clientId, cropName, growingSeasonName, plantDate, numOfColumns, numOfRanges, plotWidth, plotLength, lastFlightDate, totalFlights }) => {
							return (
								<Table.Row key={fieldId}>
									<Table.Cell>
										<Link to={moduleNavigation.createFarmLink(false, farmId, 'viewer')} target="_blank">
											{farmAbbr}
										</Link>
									</Table.Cell>
									<Table.Cell>
										<Link to={moduleNavigation.createFieldLink(false, fieldId)} target="_blank">
											{fieldName}
										</Link>
									</Table.Cell>
									<Table.Cell>{clientName}</Table.Cell>
									<Table.Cell>{cooperatorName}</Table.Cell>
									<Table.Cell>{city}</Table.Cell>
									<Table.Cell>{stateName}</Table.Cell>
									<Table.Cell>{cropName}</Table.Cell>
									<Table.Cell>{growingSeasonName}</Table.Cell>
									<Table.Cell>{plantDate ? moment(plantDate).local().format(DATE_FORMAT) : null}</Table.Cell>
									<Table.Cell>{numOfColumns}</Table.Cell>
									<Table.Cell>{numOfRanges}</Table.Cell>
									<Table.Cell>{`${plotWidth} x ${plotLength}`}</Table.Cell>
									<Table.Cell>{lastFlightDate ? moment(lastFlightDate).local().format(DATE_FORMAT) : null}</Table.Cell>
									<Table.Cell>{totalFlights}</Table.Cell>
									<Table.Cell>
										{_.map(trialData, (trial) => {
											if (trial.fieldId === fieldId) {
												return (
													<React.Fragment key={trial.trialId}>
														<Link
															to={moduleNavigation.createTrialLink(false, trial.trialId, "viewer", trial.fieldId)}
															target="_blank"
														>
															{trial.trialName}
														</Link>
														<br />
													</React.Fragment>
												);
											}
										})}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{userAuth.hasApplicationArea(ApplicationArea.FieldSearch, clientId) ? (
											<>
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Edit</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Link to={moduleNavigation.createFieldLink(false, fieldId, "edit", null)} target="_blank">
														<Icon name="pencil" disabled={!userAuth.hasApplicationArea(ApplicationArea.FieldEdit, clientId) && userAuth.currentClient !== clientId} />
													</Link>
												</Tooltip>
												<span style={{ display: "inline-block", width: 7 }} />
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Heatmap</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Link to={moduleNavigation.createFieldLink(false, fieldId, "heatmap", null)} target="_blank">
														<Icon name="map" />
													</Link>
												</Tooltip>
												<span style={{ display: "inline-block", width: 5 }} />
											</>
										) : null}
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={<p>Outline</p>}
											transitionName="rc-tooltip-zoom"
										>
											<Link to={moduleNavigation.createFieldLink(false, fieldId, "outline", null)} target="_blank">
												<Icon name="table" />
											</Link>
										</Tooltip>
									</Table.Cell>
								</Table.Row>
							);
						})
					}
				</Table.Body>

				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="16">
							<span style={{ marginRight: 10 }}>{dataSource.length === 1 ? `${dataSource.length} field found` : `${dataSource.length} fields found`}</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 5, value: 5 },
									{ key: 2, text: 10, value: 10 },
									{ key: 3, text: 15, value: 15 },
									{ key: 4, text: 25, value: 25 },
									{ key: 5, text: 50, value: 50 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>

			</Table>
		</div>
	</Segment>)
};

FieldSearchResults.propTypes = {
	fieldList: PropTypes.array,
	trialData: PropTypes.array,
	userAuth: PropTypes.object.isRequired
};

export default FieldSearchResults;
