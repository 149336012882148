import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import moment from "moment";
import _ from "lodash";
import Tooltip from "rc-tooltip";

import { Segment, Table, Pagination, Dropdown, Grid, Icon } from "semantic-ui-react";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";

const AssessmentLogResults = ({
	assessmentLogs,
	growthStageOptions,
	growthPhaseOptions,
	setSelectedDataset,
	setShowDeleteModal,
	setShowEraseModal,
	isTrial
}) => {
	const DATE_FORMAT = "MM/DD/YYYY hh:mm a";
	const moduleNavigation = useModuleNavigation();

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState(assessmentLogs);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	useEffect(() => {
		if (assessmentLogs) {
			setTotalPages(Math.ceil(assessmentLogs.length / itemsPerPage));
			setTableData(dataSource);
			setupInitialLoad(assessmentLogs);
		}
	}, [assessmentLogs]);

	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	function setupInitialLoad(assessmentLogs) {
		setDataSource(
			_.sortBy(assessmentLogs, function (d) {
				return moment.utc(d.dateAndTime).local().format(DATE_FORMAT);
			}).reverse()
		);
	}

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/dateCollected/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment.utc(d[clickedColumn]).local().format(DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/assessment/i)) {
				setDataSource(
					_.sortBy(dataSource, [
						(d) =>
							_.sortBy(
								d.assessment,
								[(assessment) => assessment.toLowerCase()],
								[sortDirection === "ascending" ? "asc" : "desc"]
							)[0].toLowerCase()
					])
				);
			} else if (clickedColumn.match(/growthPhaseId/i)) {
				let sortedList = [];
				_.map(growthPhaseOptions, (gpo) => {
					_.map(dataSource, (d) => {
						if (d.growthPhaseId === gpo.key) sortedList.push(d);
					});
				});

				_.map(dataSource, (d) => {
					if (d.growthPhaseId === null) sortedList.push(d);
				});

				setDataSource(sortedList);
			} else if (clickedColumn.match(/growthStageId/i)) {
				let sortedList = [];
				_.map(growthStageOptions, (gso) => {
					_.map(dataSource, (d) => {
						if (d.growthStageId === gso.key) sortedList.push(d);
					});
				});

				_.map(dataSource, (d) => {
					if (d.growthStageId === null) sortedList.push(d);
				});

				setDataSource(sortedList);
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	}

	return (
		<Segment basic>
			<span style={{ float: "right" }}>
				{dataSource.length === 1 ? `${dataSource.length} assessment found` : `${dataSource.length} assessments found`}
			</span>
			<Table celled striped sortable selectable color="blue">
				<Table.Header>
					<Table.Row textAlign="center">
						<Table.HeaderCell
							sorted={sortColumn === "datasetNumericalId" ? sortDirection : null}
							onClick={() => handleSort("datasetNumericalId")}
						>
							Dataset ID
						</Table.HeaderCell>
						{!isTrial && <Table.HeaderCell>Trials</Table.HeaderCell>}
						<Table.HeaderCell
							sorted={sortColumn === "assessment" ? sortDirection : null}
							onClick={() => handleSort("assessment")}
						>
							Assessments
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "growthPhaseId" ? sortDirection : null}
							onClick={() => handleSort("growthPhaseId")}
						>
							Growth Phase
						</Table.HeaderCell>
						<Table.HeaderCell>Planned Timing</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "growthStageId" ? sortDirection : null}
							onClick={() => handleSort("growthStageId")}
						>
							Actual Growth Stage
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "assessmentDate" ? sortDirection : null}
							onClick={() => handleSort("assessmentDate")}
						>
							Date Collected
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "collectedByName" ? sortDirection : null}
							onClick={() => handleSort("collectedByName")}
						>
							Collected By
						</Table.HeaderCell>
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(tableData, (td) => {
						return (
							<Table.Row key={td.groundDatasetAssessmentDataId}>
								<Table.Cell>{td.datasetNumericalId}</Table.Cell>
								{!isTrial && (
									<Table.Cell>
										{_.map(td.childDatasets, (dataset) => {
											return (
												<Grid.Row style={{ padding: 0 }}>
													<Grid.Column>
														<Link
															to={moduleNavigation.createTrialLink(
																true,
																dataset.id,
																"trial-assessment-log",
																null,
																null
															)}
															target="_blank"
														>
															{dataset.name}
														</Link>
													</Grid.Column>
												</Grid.Row>
											);
										})}
									</Table.Cell>
								)}
								<Table.Cell>
									{_.map(td.assessment, (assessment) => {
										return (
											<Grid.Row style={{ padding: 0 }}>
												<Grid.Column>{assessment}</Grid.Column>
											</Grid.Row>
										);
									})}
								</Table.Cell>
								<Table.Cell>{td.growthPhaseName ?? "TBD"}</Table.Cell>
								<Table.Cell>{td.plannedTiming ?? "TBD"}</Table.Cell>
								<Table.Cell>{td.growthStage ?? "TBD"}</Table.Cell>
								<Table.Cell>
									{td.assessmentDate ? moment.utc(td.assessmentDate).local().format(DATE_FORMAT) : "TBD"}
								</Table.Cell>
								<Table.Cell>{td.collectedByPersonId ? td.collectedByName : "TBD"}</Table.Cell>
								<Table.Cell textAlign="center">
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={<p>Edit</p>}
										transitionName="rc-tooltip-zoom"
									>
										<Link
											to={
												moduleNavigation.trialId
													? moduleNavigation.createTrialLink(true, null, "ground-data", null, null, td.datasetId)
													: moduleNavigation.createFieldLink(true, null, "ground-data", null, td.datasetId)
											}
											target="_blank"
										>
											<Icon color="black" size="large" name="upload" />
										</Link>
									</Tooltip>
									<span style={{ display: "inline-block", width: 7 }} />
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={<p>Erase</p>}
										transitionName="rc-tooltip-zoom"
									>
										<Icon
											name="eraser"
											size="large"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setSelectedDataset(td.datasetId);
												setShowEraseModal(true);
											}}
										/>
									</Tooltip>
									<span style={{ display: "inline-block", width: 5 }} />
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={<p>Delete</p>}
										transitionName="rc-tooltip-zoom"
									>
										<Icon
											name="trash alternate"
											size="large"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setSelectedDataset(td.datasetId);
												setShowDeleteModal(true);
											}}
										/>
									</Tooltip>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="11">
							<span style={{ marginRight: 10 }}>
								{dataSource.length === 1
									? `${dataSource.length} assessment found`
									: `${dataSource.length} assessments found`}
							</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 5, value: 5 },
									{ key: 2, text: 10, value: 10 },
									{ key: 3, text: 15, value: 15 },
									{ key: 4, text: 25, value: 25 },
									{ key: 5, text: 50, value: 50 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</Segment>
	);
};

AssessmentLogResults.propTypes = {
	assessmentLogs: PropTypes.array,
	growthPhaseOptions: PropTypes.array,
	growthStageOptions: PropTypes.array,
	setSelectedDataset: PropTypes.func,
	setShowDeleteModal: PropTypes.func,
	setShowEraseModal: PropTypes.func,
	isTrial: PropTypes.bool
};

export default AssessmentLogResults;
