import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, List, Popup } from "semantic-ui-react";
import "./actions.css";

type LinkAction = {
	hasArea: boolean;
	icon: string;
	title: string;
	to: string;
};

type CustomAction = {
	hasArea: boolean;
	component: React.ReactNode;
};

type Action = LinkAction | CustomAction;

type ActionMenuProps = {
	actions: Action[];
};

const ActionsMenu = ({ actions }: ActionMenuProps) => {
	const [open, setOpen] = useState(false);

	return (
		<Popup
			basic
			className="actionsPopup"
			trigger={<Icon className="actionsTrigger" style={{ cursor: "pointer" }} name="ellipsis horizontal" />}
			on="click"
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
		>
			<div onClick={() => setOpen(false)}>
				<List selection verticalAlign="middle">
					{actions.map((ac) => {
						// check type of action
						if ("to" in ac) {
							return (
								ac.hasArea && (
									<List.Item as={Link} to={ac.to} target="_blank">
										<Icon className={ac.icon} />
										<List.Content>{ac.title}</List.Content>
									</List.Item>
								)
							);
						} else if ("component" in ac) {
							return ac.hasArea && ac.component;
						}
					})}
				</List>
			</div>
		</Popup>
	);
};

export default ActionsMenu;
