import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Icon, Popup, Loader } from "semantic-ui-react";

import { CSVLink } from "react-csv";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import InspectPlotData from "../../../Lumber/InspectPlotData";

const FieldCsvUpload = ({ fieldInfo, fieldCsvData, fieldFileName, getCsvFileCallback, setFieldLayoutInspection }) => {
	const moduleNavigation = useModuleNavigation();

	const [field, setField] = useState(null);
	const [csvData, setCsvData] = useState(null);
	const [fileName, setFileName] = useState("");

	useEffect(() => {
		setField(fieldInfo);
	}, [fieldInfo]);

	useEffect(() => {
		setCsvData(fieldCsvData);
	}, [fieldCsvData]);

	useEffect(() => {
		setFileName(fieldFileName);
	}, [fieldFileName]);

	function fileSelectedHandler(file) {
		setField((field) => ({ ...field, fieldLayout: file }));
		getCsvFileCallback(file);
	}

	const fileRemovedHandler = () => {
		setField((field) => ({ ...field, fieldLayout: null }));
		getCsvFileCallback(null);
	};

	function trialDataInspectionSuccessHandler(inspectionResult) {
		setField((field) => ({ ...field, fieldLayoutInspection: inspectionResult }));
		setFieldLayoutInspection(inspectionResult);
	}

	return !field ? (
		<Loader active inline="centered" />
	) : (
		<>
			<Form>
				<Form.Group style={{ margin: 0 }}>
					<Form.Field>
						<label style={{ display: "unset" }}>Field Layout</label>
						<Popup
							content={
								!field.clientId || !field.name || !field.numOfColumns || !field.numOfRanges
									? "Field Name, Number of Columns, and Number of Ranges are required before uploading a field"
									: "Trial information associated with the field"
							}
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						{csvData && (
							<>
								<br />
								<CSVLink filename={fileName} data={csvData} enclosingCharacter={""}>
									Current field layout
								</CSVLink>
								<br />
							</>
						)}
						<InspectPlotData
							trialFieldData={{
								id: moduleNavigation.fieldId ?? "00000000-0000-0000-0000-000000000000",
								name: field.fieldName.trim(),
								numOfRanges: field.numOfRanges,
								numOfColumns: field.numOfColumns,
								clientId: field.clientId,
								fieldHasImportedData: csvData && csvData.length > 0
							}}
							onFileSelected={fileSelectedHandler}
							onFileRemoved={fileRemovedHandler}
							onInspectionSuccessful={trialDataInspectionSuccessHandler}
							groundData={false}
						/>
					</Form.Field>
				</Form.Group>
			</Form>
		</>
	);
};

FieldCsvUpload.propTypes = {
	fieldInfo: PropTypes.object,
	fieldCsvData: PropTypes.array,
	fieldFileName: PropTypes.string,
	getCsvFileCallback: PropTypes.func,
	setFieldLayoutInspection: PropTypes.func
};

export default FieldCsvUpload;
