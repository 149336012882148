import * as types from "./actionTypes";
import * as entityApi from "../../apis/entityApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getEntityNamesSuccess(entityNames) {
	return { type: types.ENTITIES_GET_ENTITY_NAMES_SUCCESS, entityNames };
}

export function getCheckClientSuccess() {
	return { type: types.CLIENT_GET_CHECK_CLIENT_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getEntityNames(flightId, fieldId, trialId, farmId, protocolId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return entityApi
				.getEntityNames(flightId, fieldId, trialId, farmId, protocolId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						resolve(res);
						dispatch(getEntityNamesSuccess(res));
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function checkClient(flightId, fieldId, trialId, protocolId, farmId, currentClientId, isStrict, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return entityApi
				.checkClient(flightId, fieldId, trialId, protocolId, farmId, currentClientId, isStrict, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						resolve(res);
						dispatch(getCheckClientSuccess());
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
