import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Header, Modal } from "semantic-ui-react";

const DeleteConfirmationModal = ({ isDeleting, handleDeleteField, disabled, loading, floated = "" }) => {
	const [open, setOpen] = useState(false);

	return (
		<Modal
			open={open}
			style={{ padding: 10 }}
			trigger={
				<Button negative compact onClick={() => setOpen(true)} floated={floated} disabled={disabled} loading={loading}>
					Delete
				</Button>
			}
		>
			<Modal.Header>Are you sure you want to delete this Field?</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ paddingBottom: "20px" }}>
					<Header>
						Are you sure you want to delete all the data for this field? This action <strong>cannot</strong> be undone.
					</Header>
					<Button
						onClick={() => {
							handleDeleteField();
							setOpen(false);
						}}
						negative
						compact
						floated="right"
						loading={isDeleting}
						disabled={isDeleting}
					>
						Delete
					</Button>
					<Button compact floated="right" onClick={() => setOpen(false)} disabled={isDeleting}>
						Cancel
					</Button>
				</Modal.Description>
			</Modal.Content>
		</Modal>
	);
};

DeleteConfirmationModal.propTypes = {
	isDeleting: PropTypes.bool,
	handleDeleteField: PropTypes.func.isRequired,
	floated: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool
};

export default DeleteConfirmationModal;
