import { guidRegex } from "../utilities/regex";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

import FarmSearch from "./Content/Farms/FarmSearch/index.tsx";
import FarmWizard from "./Content/Farms/FarmWizard";
import FarmViewer from "./Content/Farms/FarmViewer";

export const farmRoutes = [
	{
		id: "farm-search",
		name: "search",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Search",
		pageTitle: "Farm Search",
		icon: "search",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/farms`, `/farms/search`],
		exact: true,
		component: FarmSearch,
		module: "farms",
		applicationAreas: [ApplicationArea.FarmSearch],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "farm-new",
		name: "new",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "New",
		pageTitle: "Create New Farm",
		icon: "add",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/farms/new`],
		exact: true,
		component: () => FarmWizard({ activeStep: "info", mode: "new" }),
		module: "farms",
		applicationAreas: [ApplicationArea.FarmNew],
		requiresContext: false,
		showModuleIdInBreadcrumb: true,
		isStrict: true
	},
	{
		id: "farm-edit",
		navMatchingNames: ["info", "geo", "summary"],
		name: "edit",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		pageTitle: "Edit Farm",
		icon: "pencil",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/farms/:id${guidRegex}?/edit`],
		exact: true,
		component: () => FarmWizard({ activeStep: "info", mode: "edit" }),
		module: "farms",
		applicationAreas: [
			ApplicationArea.FarmEdit,
			ApplicationArea.FarmInfo,
			ApplicationArea.FarmGeo,
			ApplicationArea.FarmSummary
		],
		requiresContext: true,
		showModuleIdInBreadcrumb: true,
		isStrict: true
	},
	{
		id: "farm-geo",
		navMatchingNames: ["info", "geo", "summary"],
		name: "geo",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		pageTitle: "Edit Farm",
		icon: "farm",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/farms/:id${guidRegex}?/geo`],
		exact: true,
		component: () => FarmWizard({ activeStep: "geo", mode: "edit" }),
		module: "farms",
		applicationAreas: [ApplicationArea.FarmGeo],
		requiresContext: true,
		isStrict: true
	},
	{
		id: "farm-summary",
		navMatchingNames: ["info", "geo", "summary"],
		name: "summary",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		pageTitle: "Edit Farm",
		icon: "farm",
		cornerIcon: "pencil",
		showInNav: false,
		secured: true,
		paths: [`/farms/:id${guidRegex}?/summary`],
		exact: true,
		component: () => FarmWizard({ activeStep: "summary", mode: "edit" }),
		module: "farms",
		applicationAreas: [ApplicationArea.FarmSummary],
		requiresContext: true,
		isStrict: true
	},
	{
		id: "farm-viewer",
		name: "viewer",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Viewer",
		pageTitle: "Farm Viewer",
		icon: "binoculars",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/farms/:id${guidRegex}?/viewer`],
		exact: true,
		component: FarmViewer,
		module: "farms",
		applicationAreas: [
			ApplicationArea.FarmViewer,
			ApplicationArea.FarmVisualHeatmapShading,
			ApplicationArea.FarmVisualHeatmapValues,
			ApplicationArea.FarmExcludePlotsCRUD,
			ApplicationArea.FarmFlagPlotsCRUD
		],
		requiresContext: true,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "farm-weather",
		name: "weather",
		enabled: false,
		login: false,
		logout: false,
		home: false,
		linkText: "Weather Data",
		pageTitle: "Farm Weather Data",
		icon: "sun",
		cornerIcon: "cloud",
		showInNav: true,
		secured: true,
		paths: [`/farms/:id${guidRegex}?/weather`],
		exact: true,
		component: null,
		module: "farms",
		applicationAreas: [ApplicationArea.FarmWeather],
		requiresContext: true,
		showModuleIdInBreadcrumb: false
	}
];

export default {
	farmRoutes
};
