import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "semantic-ui-react";

const ConfrimNavModal = ({ open, onSave, onDiscard, onCancel, saving = false, discarding = false }) => {
	return (
		<Modal open={open}>
			<Modal.Header>Confirm Navigation</Modal.Header>
			<Modal.Content>
				<Modal.Description>Page has unsaved changes</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button content="Cancel" floated="left" onClick={onCancel} disabled={saving || discarding} />
				<Button
					negative
					content="Discard Changes"
					loading={discarding}
					disabled={saving || discarding}
					onClick={onDiscard}
				/>
				<Button primary content="Save and Continue" loading={saving} disabled={saving || discarding} onClick={onSave} />
			</Modal.Actions>
		</Modal>
	);
};

ConfrimNavModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onDiscard: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	saving: PropTypes.bool,
	discarding: PropTypes.bool
};

export default ConfrimNavModal;
