const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl + "api/v1/";

export function getExistingFlightsPlotsInFlight(flightId, clientId, accessToken) {
	return fetch(`${aerialApiUri}FlightsPlots/ExistingInFlight?flightId=${flightId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getExistingFlightsPlotsInField(flightId, fieldId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}FlightsPlots/ExistingInField?flightId=${flightId}&fieldId=${fieldId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function createFlightsPlots(plotsToAdd, accessToken) {
	return fetch(aerialApiUri + "FlightsPlots", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(plotsToAdd)
	})
		.then((res) => res)
		.then((data) => data);
}

export function updateFlightsPlots(plotsToUpdate, clientId, accessToken) {
	return fetch(`${aerialApiUri}FlightsPlots?clientId=${clientId}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(plotsToUpdate)
	});
}

export function getExistingFlightsPlotsInTrial(flightId, trialId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}FlightsPlots/ExistingInTrial?flightId=${flightId}&trialId=${trialId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getExistingFlightsPlotsInFarm(flightDate, farmId, orthoId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}FlightsPlots/ExistingInFarm?farmId=${farmId}&flightDate=${flightDate}&orthoImageTypeId=${orthoId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getRecutParameters(flightId, clientId, accessToken) {
	return fetch(`${aerialApiUri}FlightsPlots/GetRecutParameters?flightId=${flightId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}
