import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Loader, Form } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as growingSeasonActions from "../../../../redux/actions/growingSeasonActions";

const GrowingSeasonManagement = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	const seasonList = [
		{
			key: "Spring",
			text: "Spring",
			value: "Spring"
		},
		{
			key: "Summer",
			text: "Summer",
			value: "Summer"
		},
		{
			key: "Fall",
			text: "Fall",
			value: "Fall"
		},
		{
			key: "Winter",
			text: "Winter",
			value: "Winter"
		}
	];

	//-- Data Source
	const [growingSeasons, setGrowingSeasons] = useState(null);

	//-- Selected Data
	const [selectedGrowingSeason, setSelectedGrowingSeason] = useState(null);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			getData();
		}
		setMode("update");
	}, [userAuth.isReady, clientId]);

	const getData = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(growingSeasonActions.getGrowingSeasonDetails(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setGrowingSeasons(res.data);
				} else {
					toast.error("Unable to load Growing Season data. Please try again.");
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load Growing Season data. Please try again.");
			});
	};

	//-- Growing Seasons
	useEffect(() => {
		if (growingSeasons && growingSeasons.length > 0) {
			if (!selectedGrowingSeason) {
				GrowingSeasonSelected(growingSeasons[0].id, growingSeasons[0].name);
			} else {
				GrowingSeasonSelected(selectedGrowingSeason.id, selectedGrowingSeason.name);
			}
			setMode("update");
		}
	}, [growingSeasons]);

	useEffect(() => {
		if (growingSeasons !== null) {
			setLoading(false);
		}
	}, [growingSeasons, selectedGrowingSeason]);

	const GrowingSeasonSelected = (growingSeasonId, growingSeasonName) => {
		if (growingSeasonId || growingSeasonName) {
			const selSeason = growingSeasons.find((s) => s.id === growingSeasonId || s.name === growingSeasonName);
			setSelectedGrowingSeason(selSeason);
			setMode("update");
		}
	};

	function prepareNewGrowingSeasonForm() {
		setSelectedGrowingSeason(null);
		setMode("create");
	}

	const createGrowingSeason = async () => {
		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(growingSeasonActions.createGrowingSeason(accessToken, selectedGrowingSeason))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Growing Season created");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch((error) => {
				toast.error("Unable to update Growing Season. Please try again.");
				console.log(error);
				setSaving(false);
			});
	};

	const updateGrowingSeason = async () => {
		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(growingSeasonActions.updateGrowingSeason(accessToken, selectedGrowingSeason))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Growing Season updated");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch((error) => {
				toast.error("Unable to update Growing Season. Please try again.");
				console.log(error);
				setSaving(false);
			});
	};

	return (
		<Segment basic>
			{loading && (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			)}
			{!loading && (
				<Grid id="configure-growing-season-grid" columns="equal">
					<Grid.Row style={{ paddingBottom: "unset" }}>
						<Grid.Column width={4}>
							<label htmlFor="form-select-growing-season" className="fieldLabel">
								Growing Seasons
							</label>
							<Form.Button
								id="new-growing-season"
								color="blue"
								content="New Season"
								style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
								onClick={() => prepareNewGrowingSeasonForm()}
							/>
							<List
								id="select-growing-season"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 250,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid var(--border)",
									backgroundColor: "rgba(255, 255, 255, 0.5)",
									borderRadius: "8px"
								}}
							>
								{_.map(growingSeasons, ({ id, name }) => {
									return (
										<List.Item
											key={id}
											style={{
												border: "2px solid transparent",
												backgroundClip: "padding-box"
											}}
											active={selectedGrowingSeason?.id === id}
											onClick={() => {
												GrowingSeasonSelected(id, name);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
							<Form>
								<Grid id="configure-growing-season-form-grid" columns="equal">
									<Grid.Row style={{ paddingBottom: "unset" }}>
										<Grid.Column>
											<Form.Group widths={"equal"}>
												<Form.Input
													id="form-input-name"
													width={12}
													label="Name"
													value={selectedGrowingSeason?.name ?? ""}
													required
													onChange={(event) =>
														setSelectedGrowingSeason({ ...selectedGrowingSeason, name: event.target.value })
													}
												/>
											</Form.Group>
											<Form.Group style={{ marginBottom: 3 }}>
												<label htmlFor="form-text-area-description" className="fieldLabel" style={{ marginLeft: 7 }}>
													Description
												</label>
											</Form.Group>
											<Form.Group>
												<Form.TextArea
													id="form-text-area-description"
													width={16}
													value={selectedGrowingSeason?.description ?? ""}
													onChange={(event) =>
														setSelectedGrowingSeason({ ...selectedGrowingSeason, description: event.target.value })
													}
													rows="5"
												/>
											</Form.Group>
											<hr style={{ clear: "both" }} />
											<Form.Group>
												<Form.Checkbox
													id="form-growing-season-enabled"
													width={3}
													label="Growing Season Enabled?"
													style={{ marginTop: 33 }}
													checked={selectedGrowingSeason?.enabled ?? false}
													onChange={(event, data) => {
														setSelectedGrowingSeason({ ...selectedGrowingSeason, enabled: data.checked });
													}}
												/>
											</Form.Group>

											<Form.Group>
												<Form.Select
													id="form-growing-season-start-season"
													label="Season Start"
													placeholder="Season"
													required
													fluid
													style={{ textAlign: "left" }}
													options={seasonList}
													onChange={(event, data) =>
														setSelectedGrowingSeason({ ...selectedGrowingSeason, startSeason: data.value })
													}
													value={selectedGrowingSeason?.startSeason ?? ""}
												/>
												<Form.Input
													id="form-input-start-year"
													width={2}
													label="Year"
													value={selectedGrowingSeason?.startYear ?? ""}
													required
													onChange={(event, data) =>
														setSelectedGrowingSeason({ ...selectedGrowingSeason, startYear: data.value })
													}
												/>
											</Form.Group>
											<Form.Group>
												<Form.Select
													id="form-growing-season-end-season"
													label="Season End"
													placeholder="Season"
													required
													fluid
													style={{ textAlign: "left" }}
													options={seasonList}
													onChange={(event, data) =>
														setSelectedGrowingSeason({ ...selectedGrowingSeason, endSeason: data.value })
													}
													value={selectedGrowingSeason?.endSeason ?? ""}
												/>
												<Form.Input
													id="form-input-end-year"
													width={2}
													label="Year"
													value={selectedGrowingSeason?.endYear ?? ""}
													required
													onChange={(event, data) =>
														setSelectedGrowingSeason({ ...selectedGrowingSeason, endYear: data.value })
													}
												/>
											</Form.Group>
											<Form.Group style={{ float: "right" }}>
												{mode === "update"}
												<Form.Button
													floated="right"
													primary
													content={mode === "create" ? "Create" : "Update"}
													onClick={() => {
														if (mode === "create") {
															createGrowingSeason();
														} else {
															updateGrowingSeason();
														}
													}}
													disabled={saving}
													loading={saving}
												/>
											</Form.Group>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Segment>
	);
};

export default GrowingSeasonManagement;
