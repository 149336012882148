import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, Grid, Popup, Segment } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

//-- trialDataResults must be grouped by flight date, ground data date, or entire flight series (Curve Model data)
//-- The date must then be ordered by treatment id (1,2,3,etc)
const SummaryTableResults = ({
	trialDataResults,
	summaryTableSelections,
	footerData,
	DATE_FORMAT,
	selectedPresetOption,
	needToUpdateAnalytics
}) => {
	const [orderedSummaryTableSelections, setOrderedSummaryTableSelections] = useState([]);
	const [presetHeaders, setPresetHeaders] = useState([]);

	useEffect(() => {
		if (!needToUpdateAnalytics) {
			if (summaryTableSelections.length > 0) {
				let orderedAssessments = orderAllAssessments(summaryTableSelections);
				setOrderedSummaryTableSelections(orderedAssessments);
			}
		}
	}, [summaryTableSelections]);

	function orderAllAssessments(selections) {
		let allAssessments = flattenSummaryTableSelections(selections);

		//-- If there is a selected option (that is not "none") we want to order the assessments based off of the summary table preset
		//-- else, just order the assessments by date
		if (selectedPresetOption) {
			allAssessments = orderAssessmentsByPresetSelection(allAssessments);
		} else {
			allAssessments = _.orderBy(allAssessments, ["date"], ["asc"]);
		}

		return allAssessments;
	}

	//-- flatten the summary table results so we can display the results in the summary table
	function flattenSummaryTableSelections(selections) {
		let allAssessments = [];
		_.map(selections, (summarytableSelection) => {
			if (summarytableSelection.isCurveModel) {
				allAssessments.push(summarytableSelection);
			}
			//set some additional properties
			if (summarytableSelection.isAnalysis) {
				_.map(summarytableSelection.assessments, (a) => {
					if (!a?.analysisId || !a?.quantifiedRegionTypeId || !a?.displayName) {
						a = {
							...a,
							analysisId: a?.analysisId ?? summarytableSelection.analysisId,
							quantifiedRegionTypeId: a?.quantifiedRegionTypeId ?? summarytableSelection.quantifiedRegionTypeOptionId,
							displayName: a?.displayName ?? a?.name
						};
					}
				});
			}
		});
		_.map(_.map(selections, "assessments"), (innerArray) => {
			_.map(innerArray, (a) => {
				if (_.findIndex(allAssessments, a) === -1) {
					allAssessments.push(a);
				}
			});
		});

		return allAssessments;
	}

	//-- This method will flatten the nested preset data and return the order that the data in the summary table needs to be in
	function orderAssessmentsByPresetSelection(allAssessments) {
		let updatedSectionHeaders = [];
		let sortedAssessments = [];
		_.map(_.orderBy(selectedPresetOption.sections, "sectionOrder"), (section) => {
			if (section.displaySectionName) {
				//-- Get collection of assessments in section
				let assessmentsInSection = [];
				assessmentsInSection = [
					...assessmentsInSection,
					..._.map(_.orderBy(section.presetAnalyses, "assessmentOrder"), "analysisId")
				];
				assessmentsInSection = [
					...assessmentsInSection,
					..._.map(_.orderBy(section.presetCurveModels, "assessmentOrder"), "curveModelAnalysisId")
				];
				assessmentsInSection = [
					...assessmentsInSection,
					..._.map(_.orderBy(section.presetGroundData, "assessmentOrder"), "groundDataAssessmentId")
				];

				//-- Reorganize assessments
				let matchingAssessments = _.filter(
					allAssessments,
					(sao) =>
						(assessmentsInSection.includes(sao.analysisId) &&
							!sao.curveModelAnalysisId &&
							sao.checked === true &&
							sao.enabled === true) ||
						assessmentsInSection.includes(sao.curveModelAnalysisId) ||
						(assessmentsInSection.includes(sao.groundDataAssessmentId) && sao.checked === true && sao.enabled === true)
				);

				sortedAssessments = [...sortedAssessments, ...matchingAssessments];

				//-- Get column length of section header
				let colLength = matchingAssessments?.length;

				//-- Create section header object
				let obj = {
					id: section.id,
					name: section.sectionName,
					assessments: assessmentsInSection,
					colLength: colLength
				};

				//-- Skip headers with no assessments
				if (obj.colLength !== 0) {
					updatedSectionHeaders.push(obj);
				}
			}
		});
		setPresetHeaders(updatedSectionHeaders);
		return sortedAssessments;
	}

	//-- Create the header cell for the passed in preset section
	function getPresetHeaderCell(section) {
		return (
			<Table.HeaderCell key={section.id} colSpan={section.colLength}>
				{section.name}
			</Table.HeaderCell>
		);
	}

	return summaryTableSelections.length === 0 || trialDataResults.length === 0 ? (
		<Segment basic>
			<p>{"No assessments have been selected."}</p>
		</Segment>
	) : (
		<Segment basic style={{ width: "100%", overflowX: "auto", margin: "auto" }}>
			<Table collapsing celled striped sortable selectable color="blue" structured>
				<Table.Header>
					{presetHeaders.length === 0 || !selectedPresetOption ? null : (
						<Table.Row textAlign="center">
							<Table.HeaderCell colSpan="2"></Table.HeaderCell>
							{_.map(presetHeaders, (s) => {
								return getPresetHeaderCell(s);
							})}
						</Table.Row>
					)}
					<Table.Row textAlign="center">
						<Table.HeaderCell rowSpan="1" colSpan="2">
							Treatment
						</Table.HeaderCell>
						{_.map(orderedSummaryTableSelections, (currentSelections, index) => {
							const checked = currentSelections?.checked;
							return checked ? (
								<Table.HeaderCell colSpan={1} key={index}>
									{currentSelections.displayName}
								</Table.HeaderCell>
							) : null;
						})}
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell textAlign="center">#</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
						{_.map(orderedSummaryTableSelections, (a) => {
							return a.checked ? (
								<Table.HeaderCell colSpan="1" key={`${a.id}-${a.assessmentType}`} textAlign="center">
									{a.isCurveModel ? (
										<>
											{" "}
											{moment(a.startDate).local().format(DATE_FORMAT)} <>-</>{" "}
											{moment(a.endDate).local().format(DATE_FORMAT)}{" "}
										</>
									) : (
										moment(a.date).local().format(DATE_FORMAT)
									)}
								</Table.HeaderCell>
							) : null;
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(trialDataResults, (treatments, index) => {
						let ordered = [];
						_.map(orderedSummaryTableSelections, (asts) => {
							const newList = _.filter(treatments, (treatment) => {
								return (
									asts.checked &&
									(treatment.curveModelName === asts.name ||
										treatment.analysisId === asts.assessmentType ||
										treatment.groundDataAssessmentId === asts.assessmentType) &&
									(treatment.curveModelName === asts.name ||
										treatment.flightId === asts.id ||
										treatment.groundDatasetId === asts.id) &&
									(treatment.quantifiedRegionTypeId === asts.quantifiedRegionTypeId ||
										!treatment.quantifiedRegionTypeId)
								);
							});
							ordered = [...ordered, ...newList];
						});
						return (
							<Table.Row key={index}>
								<Table.Cell textAlign="center">{treatments[0].trialTreatmentId}</Table.Cell>
								<Table.Cell style={{ minWidth: "505px" }}>
									{/* This is fixed because it is the max number of characters that can fit in the name column*/}
									{treatments[0].treatmentName}
								</Table.Cell>
								{_.map(ordered, (treatment, subIndex) => {
									return (
										<React.Fragment key={subIndex}>
											<Popup
												content={
													treatment.hasNoPlotData === true
														? "N/A"
														: !isFinite(treatment.trialStandardDeviation) || isNaN(treatment.trialStandardDeviation)
														? "-"
														: `Standard Deviation: ${Number(treatment.trialStandardDeviation.toFixed(5))}`
												}
												trigger={
													// need to always have 5 digits .toPrecision
													<Table.Cell style={{ backgroundColor: treatment.color }} textAlign="right">
														<Grid colSpan={2} style={{ width: "150px" }} columns="equal">
															<Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
																{treatment.hasNoPlotData === true
																	? "N/A"
																	: Number(treatment.trialResultAverageValue) >= 1000
																	? Number(Number(treatment.trialResultAverageValue).toFixed(0))
																	: Number(treatment.trialResultAverageValue) < 1000 &&
																	  Number(treatment.trialResultAverageValue) > 1
																	? Number(Number(treatment.trialResultAverageValue).toFixed(2))
																	: Number(Number(treatment.trialResultAverageValue).toFixed(5)).toPrecision(5)}
															</Grid.Column>
															&ensp;&ensp;
															<Grid.Column
																style={{
																	paddingTop: 0,
																	paddingBottom: 0,
																	textAlign: "left",
																	overflowWrap: "break-word"
																}}
															>
																{treatment.hasNoPlotData === true ? "N/A" : treatment.letter ? treatment.letter : "-"}
															</Grid.Column>
														</Grid>
													</Table.Cell>
												}
											/>
										</React.Fragment>
									);
								})}
							</Table.Row>
						);
					})}
					{_.map(footerData, (treatments, index) => {
						let ordered = [];
						_.map(orderedSummaryTableSelections, (asts) => {
							const newList = _.filter(treatments, (treatment) => {
								return (
									(treatment.curveModelName === asts.name ||
										treatment.analysisId === asts.assessmentType ||
										treatment.groundDataAssessmentId === asts.assessmentType) &&
									(treatment.curveModelName === asts.name ||
										treatment.flightId === asts.id ||
										treatment.groundDatasetId === asts.id) &&
									(treatment.quantifiedRegionTypeId === asts.quantifiedRegionTypeId ||
										!treatment.quantifiedRegionTypeId)
								);
							});
							ordered = [...ordered, ...newList];
						});
						return (
							<Table.Row key={index}>
								<Table.Cell colSpan="2" textAlign="right">
									{treatments[0]?.footerName}
								</Table.Cell>
								{_.map(ordered, (treatment, subIndex) => {
									const analysesSelection = _.find(orderedSummaryTableSelections, function (x) {
										return (
											(treatment.curveModelName === x.name ||
												x.assessmentType === treatment.analysisId ||
												x.assessmentType === treatment.groundDataAssessmentId) &&
											(treatment.curveModelName === x.name ||
												x.id === treatment.flightId ||
												x.id === treatment.groundDatasetId)
										);
									});
									let checked = analysesSelection?.checked;
									return checked ? (
										<React.Fragment key={subIndex}>
											<Table.Cell textAlign="center" colSpan="1">
												{treatment.footerName === "Global Mean"
													? treatment.mean !== null
														? Number(Number(treatment.mean).toFixed(5))
														: "N/A"
													: treatment.footerName === "CV"
													? treatment.cv !== null && !isNaN(treatment.cv)
														? Number(Number(treatment.cv).toFixed(5))
														: "N/A"
													: treatment.pValue !== null
													? Number(Number(treatment.pValue).toFixed(5)) === 0
														? "<0.00001"
														: Number(Number(treatment.pValue).toFixed(5))
													: "N/A"}
											</Table.Cell>
										</React.Fragment>
									) : null;
								})}
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</Segment>
	);
};

SummaryTableResults.propTypes = {
	trialDataResults: PropTypes.array.isRequired,
	summaryTableSelections: PropTypes.array.isRequired,
	footerData: PropTypes.array.isRequired,
	DATE_FORMAT: PropTypes.string.isRequired,
	selectedPresetOption: PropTypes.object,
	needToUpdateAnalytics: PropTypes.bool
};

export default SummaryTableResults;
