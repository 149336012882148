import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";

const PointMode = {};

PointMode.onSetup = function () {
	const point = this.newFeature({
		type: Constants.geojsonTypes.FEATURE,
		properties: {
			meta: "drawn_feature"
		},
		geometry: {
			type: Constants.geojsonTypes.POINT,
			coordinates: []
		}
	});

	this.addFeature(point);
	return { point };
};

PointMode.onClick = PointMode.onTap = function (state, e) {
	state.point.updateCoordinate(0, e.lngLat.lng, e.lngLat.lat);
	return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.point.id] });
};

PointMode.onStop = function (state) {
	this.map.fire(Constants.events.CREATE, {
		features: [state.point.toGeoJSON()]
	});
};

PointMode.toDisplayFeatures = function (state, geojson, display) {
	if (geojson.properties.id !== state.point.id) {
		return display(geojson);
	}

	geojson.properties.meta = Constants.meta.FEATURE;
	return display(geojson);
};

export default PointMode;
