import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as farmApi from "../../../../apis/farmApi";
import { FarmData } from "../../../../apis/types/farmTypes";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation.js";
import { useUserAuth } from "../../../../hooks/useUserAuth.js";
import ResultsTable from "../../../Lumber/Search/ResultsTable";
import SearchPanel from "../../../Lumber/Search/SearchPanel";
import { AnyFilter, FilterOption } from "../../../Lumber/Search/types";

const FarmSearch = () => {
	const DATE_FORMAT = "MM/DD/YYYY h:mma";
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();

	const tableHeaders = {
		farmName: "Farm",
		code: "Code",
		cooperatorName: "Cooperator",
		city: "City",
		stateName: "State",
		cropNames: "Crops",
		lastFlightDate: "Last Flight Date",
		dateRange: "Date Range"
	};

	const getFarms = async (clientId: string) => {
		const accessToken = await getTokenSilently();
		const json = farmApi.getFarmsAsync(accessToken, clientId);
		return json;
	};

	const { data: searchedData, isFetching } = useQuery({
		queryKey: ["farms search", userAuth.currentClientId],
		queryFn: () => getFarms(userAuth.currentClientId),
		select: (data) => data.data
	});
	const [data, setData] = useState<FarmData[]>([]);
	const [filters, setFilters] = useState<{ [key in keyof FarmData]?: AnyFilter }>({});
	const [dropdownOpts, setDropdownOpts] = useState<{ [key in keyof FarmData]?: FilterOption[] }>({});
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		if (searchedData) {
			//-- Setting agnostic error flag
			_.map(searchedData, (sd) => {
				sd.error = !sd.parcelsExist;
			});

			setData(_.sortBy(searchedData, "code"));
		}
	}, [searchedData]);

	useEffect(() => {
		setDropdownOpts({
			cropNames: getCropOptions(data),
			cooperatorName: getCooperatorOptions(data),
			stateName: getStateOptions(data),
			city: getCityOptions(data)
		});
	}, [data]);

	const getCropOptions = useCallback((farmData: FarmData[]) => {
		const cropSet = farmData.reduce((acc, red) => {
			const union = _.union(
				acc,
				red.cropNames.filter((c) => c !== null)
			);
			return union;
		}, []);
		cropSet.sort();

		const cropOpts: FilterOption[] = cropSet.map((c, i) => ({
			name: c,
			key: "crops-" + i,
			value: c
		}));

		cropOpts.push({
			name: "No Crops",
			key: "crops-null",
			value: null
		});

		return cropOpts;
	}, []);

	const getCooperatorOptions = useCallback((farmData: FarmData[]) => {
		const cooperatorSet = _.sortBy(farmData, (f) => f.cooperatorName).reduce((acc, red) => {
			acc.add(red.cooperatorName);
			return acc;
		}, new Set<string>());

		const coopOps: FilterOption[] = Array.from(cooperatorSet).map((coop, i) => ({
			name: coop,
			key: "coops-" + i,
			value: coop
		}));

		return coopOps;
	}, []);

	const getStateOptions = useCallback((farmData: FarmData[]) => {
		const stateSet = _.sortBy(farmData, (f) => f.stateName).reduce((acc, red) => {
			acc.add(red.stateName);
			return acc;
		}, new Set<string>());

		const stateOps: FilterOption[] = Array.from(stateSet).map((coop, i) => ({
			name: coop,
			key: "states-" + i,
			value: coop
		}));

		return stateOps;
	}, []);

	const getCityOptions = useCallback((farmData: FarmData[]) => {
		const citySet = _.sortBy(farmData, (f) => f.city).reduce((acc, red) => {
			acc.add(red.city);
			return acc;
		}, new Set<string>());

		const cityOps: FilterOption[] = Array.from(citySet).map((coop, i) => ({
			name: coop,
			key: "cities-" + i,
			value: coop
		}));

		return cityOps;
	}, []);

	const handleFilters = {
		cropNames: useCallback((filter: string[], applied: boolean) => {
			setFilters((prev) => ({ ...prev, cropNames: { type: "string", names: filter, applied } }));
		}, []),
		cooperatorName: useCallback((filter: string[], applied: boolean) => {
			setFilters((prev) => ({ ...prev, cooperatorName: { type: "string", names: filter, applied } }));
		}, []),
		stateName: useCallback((filter: string[], applied: boolean) => {
			setFilters((prev) => ({ ...prev, stateName: { type: "string", names: filter, applied } }));
		}, []),
		city: useCallback((filter: string[], applied: boolean) => {
			setFilters((prev) => ({ ...prev, city: { type: "string", names: filter, applied } }));
		}, [])
	};

	const onResetFilters = () => {
		setDropdownOpts({
			cropNames: getCropOptions(data),
			cooperatorName: getCooperatorOptions(data),
			stateName: getStateOptions(data),
			city: getCityOptions(data)
		});
		setSearchTerm("");
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<SearchPanel onReset={onResetFilters}>
				<SearchPanel.Bar searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search Farms" />
				<SearchPanel.Dropdown
					placeholder="Cooperators"
					options={dropdownOpts.cooperatorName ?? []}
					setFilters={handleFilters.cooperatorName}
				/>
				<SearchPanel.Dropdown
					placeholder="Crops"
					size="small"
					options={dropdownOpts.cropNames ?? []}
					setFilters={handleFilters.cropNames}
				/>
				<SearchPanel.Dropdown
					placeholder="City"
					size="small"
					options={dropdownOpts.city ?? []}
					setFilters={handleFilters.city}
				/>
				<SearchPanel.Dropdown
					placeholder="State"
					size="small"
					options={dropdownOpts.stateName ?? []}
					setFilters={handleFilters.stateName}
				/>
			</SearchPanel>

			<ResultsTable<FarmData>
				level="farm"
				loading={isFetching}
				data={data}
				// setData={setData}
				headers={tableHeaders}
				onRenderDataColumn={{
					cropNames: (cn) => (cn[0] !== null ? cn : undefined),
					lastFlightDate: (lfd) => (lfd ? moment(lfd).format(DATE_FORMAT) : undefined),
					dateRange: (dr) => (dr !== "" ? dr : undefined)
				}}
				onSortColumn={{
					farmName: true,
					code: true,
					cooperatorName: true,
					city: true,
					stateName: true,
					lastFlightDate: (a, b) => moment(b).diff(moment(a))
				}}
				columnFilters={filters}
				actions={[
					{ icon: "binoculars", action: (f) => moduleNavigation.createFarmLink(false, f.farmId, "viewer") },
					{ icon: "pencil", action: (f) => moduleNavigation.createFarmLink(false, f.farmId, "edit") }
					// {
					// 	icon: "sun",
					// 	subIcon: "cloud",
					// 	action: (f) => moduleNavigation.createFarmLink(false, f.farmId, "weather")
					// }
				]}
				links={{
					farmName: (f) => moduleNavigation.createFarmLink(false, f.farmId, "viewer")
				}}
				searchTerm={[searchTerm, "farmName"]}
			/>
		</div>
	);
};

export default FarmSearch;
