import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import Tooltip from "rc-tooltip";
import { toast } from "react-toastify";
import {
	Button,
	Checkbox,
	Dropdown,
	Form,
	Grid,
	Icon,
	Loader,
	Modal,
	Pagination,
	Segment,
	Select,
	Table
} from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as trialActions from "../../../../redux/actions/trialActions";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import PubUnpubTrialFlights from "../../../Lumber/PubUnpubTrialFlights";
import "../../../Lumber/PubUnpubTrialFlights/loaderWorkaround.css";
import BulkPublicationSummary from "./BulkPublicationSummary";

const BulkFlightTrialsTable = ({
	trials,
	growingSeasonData,
	companyData,
	farmData,
	trialOwnerData,
	cropData,
	flightScheduleData,
	flightScheduleDataOptions,
	getBulkTrialPublishingData,
	loadingData
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "M/D/YYYY";

	//-- Data source
	const [dataSource, setDataSource] = useState(trials);

	//-- Paging
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	//-- Sorting
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//--Dropdown Options
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [growingSeasonId, setGrowingSeasonId] = useState(null);
	const [companyName, setCompanyName] = useState(null);
	const [companyNameOptions, setCompanyNameOptions] = useState([]);
	const [farmName, setFarmName] = useState(null);
	const [flightScheduleId, setFlightScheduleId] = useState(null);
	const [farmNameOptions, setFarmNameOptions] = useState([]);
	const [trialOwnerName, setTrialOwnerName] = useState(null);
	const [trialOwnerOptions, setTrialOwnerOptions] = useState([]);
	const [cropName, setCropName] = useState(null);
	const [cropOptions, setCropOptions] = useState([]);
	const [flightScheduleOptions, setFlightScheduleOptions] = useState([]);
	const [trialNameOptions, setTrialNameOptions] = useState([]);
	const [trialOptionId, setTrialOptionId] = useState(null);
	const [publishStateOptions, setPublishStateOptions] = useState([]);
	const [publishState, setPublishState] = useState(null);

	//--UI Control
	const [loading, setLoading] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [flightsToBePublishedCheckAll, setFlightsToBePublishedCheckAll] = useState(true);
	const [trialId, setTrialId] = useState(null);
	const [totalFilteredUnpublishedFlights, setTotalFilteredUnpublishedFlights] = useState(0);
	const [updatedTrialData, setUpdatedTrialData] = useState([]);

	//--Action Modal
	const [modalOpen, setModalOpen] = useState(false);
	const [innerModalOpen, setInnerModalOpen] = useState(false);
	const [openSummary, setOpenSummary] = useState(false);
	const [filteredBulkTrialData, setFilteredBulkTrialData] = useState([]);
	const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

	//-- Initial view load
	useEffect(() => {
		if (trials?.length > 0 && !loadingData) {
			setTotalPages(Math.ceil(trials.length / itemsPerPage));
			setDataSource(
				_.orderBy(
					trials,
					[
						(a) => {
							return a.flightScheduleName === "Undefined";
						},
						"companyName"
					],
					["desc", "desc"]
				)
			);
			setFilteredData(
				_.orderBy(
					dataSource,
					[
						(a) => {
							return a.flightScheduleName === "Undefined";
						},
						"companyName"
					],
					["desc", "desc"]
				)
			);
			setLoading(false);
			setUpdatedTrialData(trials);
		} else if (trials?.length === 0 && !loadingData) {
			setLoading(false);
			toast.warn("Client contains no flights");
		}
	}, [trials]);

	useEffect(() => {
		setDropdownOptions();
	}, [growingSeasonData, companyData, farmData, trialOwnerData, cropData, flightScheduleData]);

	function filterData() {
		setSortColumn("");
		let filteredData = _.cloneDeep(updatedTrialData);
		filteredData = _.filter(filteredData, (ptf) => {
			return (
				(growingSeasonId === "all" || ptf.growingSeasonId === growingSeasonId) &&
				(companyName === "all" || ptf.companyName === companyName) &&
				(farmName === "all" || ptf.farmName === farmName) &&
				(trialOwnerName === "all" || ptf.trialOwner === trialOwnerName) &&
				(cropName === "all" || ptf.cropName === cropName) &&
				(flightScheduleId === "all" || ptf.flightScheduleId === flightScheduleId) &&
				(trialOptionId === "all" || ptf.trialId === trialOptionId) &&
				(publishState === "all" ||
					(publishState === "published" && ptf.publishedTrialFlights.length > 0) ||
					(publishState === "unpublished" && ptf.unPublishedTrialFlights.length > 0) ||
					(publishState === "donotpublish" && ptf.doNotPublishTrialFlights.length > 0))
			);
		});
		setFilteredData(
			_.orderBy(
				filteredData,
				[
					(a) => {
						return a.flightScheduleName === "Undefined";
					},
					"companyName"
				],
				["desc", "desc"]
			)
		);
		let trialsWithUnPubFlights = _.filter(filteredData, (tnuf) => {
			return tnuf.unPublishedTrialFlights.length > 0;
		});
		let totalNumberOfUnpubFlights = 0;
		_.map(trialsWithUnPubFlights, (tnuf) => {
			totalNumberOfUnpubFlights += tnuf.unPublishedTrialFlights.length;
		});
		setTotalFilteredUnpublishedFlights(totalNumberOfUnpubFlights);
		setDataSource(
			_.orderBy(
				filteredData,
				[
					(a) => {
						return a.flightScheduleName === "Undefined";
					},
					"companyName"
				],
				["desc", "desc"]
			)
		);
	}

	function resetData() {
		setSortColumn("");
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(growingSeasonOptions, (gs) => gs.text.includes(currentGrowingSeason))?.value ??
			growingSeasonOptions[1]?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);
		setFarmName("all");
		setCompanyName("all");
		setTrialOwnerName("all");
		setFlightScheduleId("all");
		setTrialOptionId("all");
		setPublishState("all");
		setCropName("all");
		let filteredData = _.cloneDeep(updatedTrialData);
		filteredData = _.filter(filteredData, (ptf) => {
			return ptf.growingSeasonId === currentGrowingSeasonSelection;
		});
		setFilteredData(
			_.orderBy(
				filteredData,
				[
					(a) => {
						return a.flightScheduleName === "Undefined";
					},
					"companyName"
				],
				["desc", "desc"]
			)
		);
		setDataSource(
			_.orderBy(
				filteredData,
				[
					(a) => {
						return a.flightScheduleName === "Undefined";
					},
					"companyName"
				],
				["desc", "desc"]
			)
		);
		let trialsWithUnPubFlights = _.filter(filteredData, (tnuf) => {
			return tnuf.unPublishedTrialFlights.length > 0;
		});
		let totalNumberOfUnpubFlights = 0;
		_.map(trialsWithUnPubFlights, (tnuf) => {
			totalNumberOfUnpubFlights += tnuf.unPublishedTrialFlights.length;
		});
		setTotalFilteredUnpublishedFlights(totalNumberOfUnpubFlights);
		setFlightsToBePublishedCheckAll(true);
	}

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setFilteredData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	function setFlightsToBePublishedCheckAllBox(checked) {
		setFlightsToBePublishedCheckAll(checked);
		let copy = [...dataSource];
		_.map(copy, (trials) => {
			_.map(trials.unPublishedTrialFlights, (uptf) => {
				uptf.isChecked = uptf.disabled ? false : checked;
			});
		});
		setDataSource(copy);
	}

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total|treatmentId/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
	};

	function setDropdownOptions() {
		setInitialGrowingSeasonOptions(growingSeasonData);
		setInitialCompanyNameOptions(companyData);
		setInitialFarmNameOptions(farmData);
		setInitialTrialOwnerOptions(trialOwnerData);
		setInitialCropNameOptions(cropData);
		setInitialFlightScheduleNameOptions(flightScheduleData);
		setInitialTrialNameOptions(trials);
		setInitialPublishStateOptions();
	}

	function setInitialGrowingSeasonOptions(initialGrowingSeasonData) {
		let tempOptions = _.map(initialGrowingSeasonData, (growingSeason) => {
			const opt = {
				key: growingSeason.growingSeasonId,
				value: growingSeason.growingSeasonId,
				text: growingSeason.growingSeasonName
			};
			return opt;
		});
		tempOptions = _.orderBy(tempOptions, ["text"], ["desc"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setCompanyNameOptions(tempOptions);
		setGrowingSeasonOptions(tempOptions);

		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(tempOptions, (gs) => gs.text.includes(currentGrowingSeason))?.value ?? tempOptions[1]?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);

		//Make sure to filter by growing season upon page load
		filterData();
	}

	function setInitialCompanyNameOptions(initialCompanyNameData) {
		let tempOptions = _.map(initialCompanyNameData, (companyName) => {
			const opt = {
				key: companyName.companyName,
				value: companyName.companyName,
				text: companyName.companyName
			};
			return opt;
		});
		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setCompanyNameOptions(tempOptions);
		setCompanyName("all");
	}

	function setInitialFarmNameOptions(initialFarmNameData) {
		let tempOptions = _.map(initialFarmNameData, (farmName) => {
			const opt = {
				key: farmName.farmName,
				value: farmName.farmName,
				text: farmName.farmName
			};
			return opt;
		});

		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setFarmNameOptions(tempOptions);
		setFarmName("all");
	}

	function setInitialTrialOwnerOptions(initialTrialOwnerData) {
		let tempOptions = _.map(initialTrialOwnerData, (trialOwner) => {
			const opt = {
				key: trialOwner.trialOwner,
				value: trialOwner.trialOwner,
				text: trialOwner.trialOwner
			};
			return opt;
		});

		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setTrialOwnerOptions(tempOptions);
		setTrialOwnerName("all");
	}

	function setInitialCropNameOptions(initialCropNameData) {
		let tempOptions = _.map(initialCropNameData, (cropName) => {
			const opt = {
				key: cropName.cropName,
				value: cropName.cropName,
				text: cropName.cropName
			};
			return opt;
		});

		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setCropOptions(tempOptions);
		setCropName("all");
	}

	function setInitialFlightScheduleNameOptions(initialFlightScheduleNameData) {
		let tempOptions = _.map(initialFlightScheduleNameData, (flightScheduleName) => {
			const opt = {
				key: flightScheduleName.flightScheduleId,
				value: flightScheduleName.flightScheduleId,
				text: flightScheduleName.flightScheduleName
			};
			return opt;
		});

		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setFlightScheduleOptions(tempOptions);
		setFlightScheduleId("all");
	}

	function setInitialTrialNameOptions(initialTrialNameData) {
		let tempOptions = _.map(initialTrialNameData, (trialName) => {
			const opt = {
				key: trialName.trialId,
				value: trialName.trialId,
				text: trialName.trialName
			};
			return opt;
		});

		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setTrialNameOptions(tempOptions);
		setTrialOptionId("all");
	}

	function setInitialPublishStateOptions() {
		let tempOptions = [{ key: "published", value: "published", text: "Published" }].concat(
			[{ key: "unpublished", value: "unpublished", text: "Not Published" }].concat([
				{ key: "donotpublish", value: "donotpublish", text: "Do Not Publish" }
			])
		);

		tempOptions = _.orderBy(tempOptions, ["text"]);
		tempOptions.unshift({ key: "all", value: "all", text: "All" });
		setPublishStateOptions(tempOptions);
		setPublishState("all");
	}

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	function handlePublishUnpublishSave() {
		setModalOpen(false);
	}

	async function publishTrialFlights(filteredBulkTrialData) {
		if (filteredBulkTrialData?.length === 0) {
			toast.warn("Please select at least one flight for publishing");
			return;
		}
		setConfirmButtonLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(trialActions.publishBulkTrialFlights(userAuth.currentClientId, filteredBulkTrialData, accessToken))
			.then(() => {
				toast.success("Bulk Flights published");
				setLoading(true);
				setOpenSummary(false);
				setFlightsToBePublishedCheckAllBox(true);
				setConfirmButtonLoading(false);
				getBulkTrialPublishingData(userAuth.currentClientId);
			})
			.catch((err) => {
				toast.error("Error publishing bulk flights");
				console.log(err);
				setLoading(false);
				setConfirmButtonLoading(false);
			});
	}

	function updateFilteredData(
		trialId,
		flightScheduleId,
		publishTrialFlights,
		unpublishTrialFlights,
		doNotPublishTrialFlights
	) {
		let flightSchedule = _.find(flightScheduleDataOptions, (fso) => {
			return fso.id === flightScheduleId;
		});
		let copy = mapFilteredData(
			_.cloneDeep(updatedTrialData),
			trialId,
			publishTrialFlights,
			unpublishTrialFlights,
			doNotPublishTrialFlights,
			flightSchedule
		);

		let totalNumberOfUnpubFlights = 0;

		setUpdatedTrialData(copy);

		let filteredCopy = mapFilteredData(
			_.cloneDeep(dataSource),
			trialId,
			publishTrialFlights,
			unpublishTrialFlights,
			doNotPublishTrialFlights,
			flightSchedule
		);

		_.map(filteredCopy, (fc) => {
			totalNumberOfUnpubFlights += fc.unPublishedTrialFlights.length;
		});

		setTotalFilteredUnpublishedFlights(totalNumberOfUnpubFlights);
		setDataSource(filteredCopy);

		let newflightScheduleOptions = _.uniqBy(copy, (trial) => {
			return [trial.flightScheduleName].join();
		});

		let tempOptions = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(newflightScheduleOptions, (flightScheduleName) => {
				const opt = {
					key: flightScheduleName.flightScheduleId,
					value: flightScheduleName.flightScheduleId,
					text: flightScheduleName.flightScheduleName
				};
				return opt;
			})
		);
		setFlightScheduleOptions(tempOptions);
		setFlightScheduleId("all");
	}

	function mapFilteredData(
		dataCopy,
		trialId,
		publishTrialFlights,
		unpublishTrialFlights,
		doNotPublishTrialFlights,
		flightSchedule
	) {
		_.map(dataCopy, (fd) => {
			if (fd.trialId === trialId) {
				fd.flightScheduleId = flightSchedule.id;
				fd.flightScheduleName = flightSchedule.name;

				let allFlights = fd.publishedTrialFlights
					.concat(fd.unPublishedTrialFlights)
					.concat(fd.doNotPublishTrialFlights);

				fd.publishedTrialFlights = _.filter(publishTrialFlights, (ptf) => {
					return !ptf.doNotPublish;
				});

				fd.publishedTrialFlights = _.filter(allFlights, (af) => {
					return _.find(fd.publishedTrialFlights, (ptf) => {
						if (ptf.flightId === af.flightId) {
							af.isPublishedToTrialOwner = ptf.isPublishedToTrialOwner;
							af.isPublishedToTrialSponsor = ptf.isPublishedToTrialSponsor;
							return af;
						}
					});
				});

				_.map(fd.publishedTrialFlights, (ptf) => {
					ptf.doNotPublish = false;
				});

				fd.unPublishedTrialFlights = _.filter(unpublishTrialFlights, (uptf) => {
					return !uptf.doNotPublish;
				});

				fd.unPublishedTrialFlights = _.filter(allFlights, (af) => {
					return _.find(fd.unPublishedTrialFlights, (uptf) => {
						if (uptf.flightId === af.flightId) {
							af.isPublishedToTrialOwner = uptf.isPublishedToTrialOwner;
							af.isPublishedToTrialSponsor = uptf.isPublishedToTrialSponsor;
							af.disabled = uptf.disabled;
							return af;
						}
					});
				});

				_.map(fd.unPublishedTrialFlights, (uptf) => {
					uptf.isChecked = uptf.disabled ? false : true;
					uptf.doNotPublish = false;
				});

				fd.doNotPublishTrialFlights = doNotPublishTrialFlights;

				fd.doNotPublishTrialFlights = _.filter(allFlights, (af) => {
					return _.find(fd.doNotPublishTrialFlights, (dnptf) => {
						if (dnptf.flightId === af.flightId) {
							af.isPublishedToTrialOwner = dnptf.isPublishedToTrialOwner;
							af.isPublishedToTrialSponsor = dnptf.isPublishedToTrialSponsor;
							af.disabled = dnptf.disabled;
							return af;
						}
					});
				});

				_.map(fd.doNotPublishTrialFlights, (dnptf) => {
					dnptf.doNotPublish = true;
				});
			}
		});
		return dataCopy;
	}

	// prettier-ignore
	return loading ? (
		<Loader active />
	) : !loading ? (
		<Segment basic style={{ padding: 0 }}>
			<Grid className="searchGrid">
				<Form.Field
					id="form-select-growing-season"
					label="Growing Season"
					control={Select}
					placeholder="Growing Season"
					search
					disabled={loading}
					options={growingSeasonOptions}
					value={growingSeasonId}
					fluid
					onChange={(event, { value }) => {
						setGrowingSeasonId(value);
					}}
				/>
				<Form.Field
					id="form-select-crop"
					label="Crop"
					control={Select}
					placeholder="Crop"
					search
					disabled={loading}
					options={cropOptions}
					value={cropName}
					fluid
					onChange={(event, { value }) => {
						setCropName(value);
					}}
				/>
				<Form.Field
					id="form-select-farm"
					label="Farm"
					control={Select}
					placeholder="Farm"
					search
					disabled={loading}
					options={farmNameOptions}
					value={farmName}
					fluid
					onChange={(event, { value }) => {
						setFarmName(value);
					}}
				/>		
				<Form.Field
					id="form-select-company"
					label="Company"
					control={Select}
					placeholder="Company"
					search
					disabled={loading}
					options={companyNameOptions}
					value={companyName}
					fluid
					onChange={(event, { value }) => {
						setCompanyName(value);
					}}
				/>
				<Form.Field
					id="form-select-owner"
					label="Owner"
					control={Select}
					placeholder="Owner"
					search
					disabled={loading}
					options={trialOwnerOptions}
					value={trialOwnerName}
					fluid
					onChange={(event, { value }) => {
						setTrialOwnerName(value);
					}}
				/>
				<Form.Field
					id="form-select-flight-schedule"
					label="Flight Schedule"
					control={Select}
					placeholder="Flight Schedule"
					search
					disabled={loading}
					options={flightScheduleOptions}
					value={flightScheduleId}
					fluid
					onChange={(event, { value }) => {
						setFlightScheduleId(value);
					}}
				/>
				<Form.Field
					id="form-select-trial"
					label="Trial"
					control={Select}
					placeholder="Trial"
					search
					disabled={loading}
					options={trialNameOptions}
					value={trialOptionId}
					fluid
					onChange={(event, { value }) => {
						setTrialOptionId(value);
					}}
				/>
				<Form.Field
					id="form-select-company"
					label="Publish State"
					control={Select}
					placeholder="Company"
					search
					disabled={loading}
					options={publishStateOptions}
					value={publishState}
					fluid
					onChange={(event, { value }) => {
						setPublishState(value);
					}}
				/>
				<div className="buttons">
					<Button
						id="form-button-clear-filter"
						disabled={loading}
						negative
						content="Reset"
						onClick={() => resetData()}
					/>
					<Button id="form-button-filter" primary disabled={loading} content="Filter" onClick={() => filterData()} />
				</div>
				<Grid.Row textAlign="right">
					<Grid.Column>
						<Form.Field style={{ width: "auto" }}>
							<b>{totalFilteredUnpublishedFlights} Unpublished Flights</b> {" "}
							<Modal
								onClose={() => setOpenSummary(false)}
								onOpen={() => setOpenSummary(true)}
								open={openSummary}
								trigger={
									<Button
										id="form-button-publish"
										color="blue"
										content="Publish"
									/>}>
								<Modal.Header>Bulk Publication Summary</Modal.Header>
								<Modal.Content>
									<BulkPublicationSummary
										trialData={dataSource}
										setFilteredBulkTrialData={setFilteredBulkTrialData}
									/>
								</Modal.Content>
								<Modal.Actions>
									<Button color="yellow" loading={confirmButtonLoading} onClick={() => publishTrialFlights(filteredBulkTrialData)}>Confirm Publication</Button>
									<Button onClick={() => setOpenSummary(false)}>Close</Button>
								</Modal.Actions>
							</Modal>
						</Form.Field>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Table celled striped sortable selectable color="blue" textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={sortColumn === 'trialName' ? sortDirection : null}
							onClick={() => handleSort('trialName')}>Trial</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'companyName' ? sortDirection : null}
							onClick={() => handleSort('companyName')}>Company</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'trialOwner' ? sortDirection : null}
							onClick={() => handleSort('trialOwner')}>Owner</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'farmCity' ? sortDirection : null}
							onClick={() => handleSort('farmCity')}>Location</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'flightScheduleName' ? sortDirection : null}
							onClick={() => handleSort('flightScheduleName')}>Flight Schedule</Table.HeaderCell>
						<Table.HeaderCell>Do Not Publish Flights</Table.HeaderCell>
						<Table.HeaderCell>Published Flights</Table.HeaderCell>
						<Table.HeaderCell>
							<Checkbox style={{ paddingTop: "5px" }}
								checked={flightsToBePublishedCheckAll}
								onClick={(e, { checked }) => {
									setFlightsToBePublishedCheckAllBox(checked);
								}}
							/>
							&nbsp;&nbsp;Flights To Be Published</Table.HeaderCell>
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
						_.map(filteredData, (trial, index) => {
							return (
								<Table.Row key={index}>
									<Table.Cell>
										<Link target="_blank" to={moduleNavigation.createTrialLink(false, trial.trialId)}>
											{trial.trialName}
										</Link>
									</Table.Cell>
									<Table.Cell>{trial.companyName}</Table.Cell>
									<Table.Cell>{trial.trialOwner}</Table.Cell>
									<Table.Cell>
										{trial.farmAddress1 ? (
											<div>
												{trial.farmAddress1}
												<br />
											</div>
										) : null}
										{trial.farmAddress2 ? (
											<div>
												{trial.farmAddress2}
												<br />
											</div>
										) : null}
										{
											<div>
												{trial.farmCity !== null ? trial.farmCity + ", " + trial.farmState : trial.farmState}
												<br />
											</div>
										}
									</Table.Cell>
									<Table.Cell>{trial.flightScheduleName}</Table.Cell>
									<Table.Cell style={{ textAlign: "left" }}>
										{_.map(_.orderBy(trial.doNotPublishTrialFlights, ["flightDate"]), (ptf, index) => {
											return (
												<div key={index}>
													<Link target="_blank" to={moduleNavigation.createFlightLink(false, ptf.flightId)}>
														{moment(ptf.flightDate).local().format(DATE_FORMAT)}_{ptf.uasName}
													</Link>
												</div>
											);
										})}
									</Table.Cell>
									<Table.Cell style={{ textAlign: "left" }}>
										{_.map(_.orderBy(trial.publishedTrialFlights, ["flightDate"]), (ptf, index) => {
											return (
												<div key={index}>
													<Link target="_blank" to={moduleNavigation.createFlightLink(false, ptf.flightId)}>
														{moment(ptf.flightDate).local().format(DATE_FORMAT)}_{ptf.uasName}
													</Link>
												</div>
											);
										})}
									</Table.Cell>
									<Table.Cell style={{ textAlign: "left" }}>
										{_.map(_.orderBy(trial.unPublishedTrialFlights, ["flightDate"]), (uptf, index) => {
											return (
												<div key={index}>
													<Checkbox
														style={{ paddingTop: "4px" }}
														checked={uptf.isChecked}
														disabled={uptf.disabled}
														onClick={(e, { checked }) => {
															let copy = [...dataSource];
															let trialIndex = _.find(copy, (c) => {
																return c.trialId === trial.trialId;
															});
															let flightIndex = _.findIndex(trialIndex.unPublishedTrialFlights, (c) => {
																return c.flightId === uptf.flightId;
															});
															trialIndex.unPublishedTrialFlights[flightIndex].isChecked = trialIndex
																.unPublishedTrialFlights[flightIndex].disabled
																? false
																: checked;
															setDataSource(copy);
														}}
													/>
													&nbsp;&nbsp;
													<Link target="_blank" to={moduleNavigation.createFlightLink(false, uptf.flightId)}>
														{moment(uptf.flightDate).local().format(DATE_FORMAT)}_{uptf.uasName}
													</Link>
												</div>
											);
										})}
									</Table.Cell>
									<Table.Cell>
										<div style={{ display: "flex", gap: 5, flexWrap: "wrap", justifyContent: "center" }}>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={<p>Edit</p>}
												transitionName="rc-tooltip-zoom"
											>
												<Link
													target="_blank"
													to={moduleNavigation.createTrialLink(
														false,
														trial.trialId,
														"edit",
														userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)
															? trial.fieldId
															: null
													)}
												>
													<Icon name="pencil" />
												</Link>
											</Tooltip>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={<p>Analysis</p>}
												transitionName="rc-tooltip-zoom"
											>
												<Link
													target="_blank"
													to={moduleNavigation.createTrialLink(
														false,
														trial.trialId,
														"analysis",
														userAuth.hasApplicationArea(ApplicationArea.TrialAnalysis, userAuth.currentClientId)
															? trial.fieldId
															: null
													)}
												>
													<Icon name="chart bar outline" />
												</Link>
											</Tooltip>
											{
												<Modal
													trigger={
														<Tooltip
															placement="bottom"
															mouseEnterDelay={0}
															mouseLeaveDelay={0}
															trigger="hover"
															overlay={<p>Publishing</p>}
															transitionName="rc-tooltip-zoom"
														>
															<a
																style={{ cursor: "pointer" }}
																onClick={() => {
																	setModalOpen(true);
																	setTrialId(trial.trialId);
																	let trialOwnerCheck =
																		_.find(filteredData, (fd) => {
																			return fd?.trialId === trial.trialId;
																		})?.trialOwnerClientId === userAuth.currentClientId ||
																		trial.trialOwnerUserId === userAuth.user.userInfo.basic.userId;

																	let trialUserName = _.find(filteredData, (fd) => {
																		return fd?.trialId === trial.trialId;
																	})?.trialOwner;

																	let isPending = trialOwnerCheck
																		? _.find(filteredData, (fd) => {
																				return fd?.trialId === trial.trialId;
																		  })?.trialSponsorIsPending
																		: _.find(filteredData, (fd) => {
																				return fd?.trialId === trial.trialId;
																		  })?.trialOwnerIsPending;

																	if (trialUserName === "N/A" || trialUserName === " ") {
																		setModalOpen(false);
																		if (trialOwnerCheck) {
																			toast.warn("Trial Sponsor must be set before trials can be published.");
																		} else {
																			toast.warn("Trial Owner must be set before trials can be published.");
																		}
																	} else if (isPending) {
																		setModalOpen(false);
																		if (trialOwnerCheck) {
																			toast.warn(
																				"Trial Sponsor does not have a valid license to publish this trial to."
																			);
																		} else {
																			toast.warn("Trial Owner does not have a valid license to publish this trial to.");
																		}
																	} else {
																		setInnerModalOpen(true);
																	}
																}}
															>
																<Icon name="book" />
															</a>
														</Tooltip>
													}
													open={modalOpen}
												>
													{loading ? (
														<>
															<Modal.Header>
																<Loader active className="workaround" />
																Getting Publishing/Unpublishing Data...
															</Modal.Header>
														</>
													) : (
														<PubUnpubTrialFlights
															key={index}
															trialFlights={_.find(filteredData, (fd) => {
																return fd?.trialId === trialId;
															})
																?.publishedTrialFlights.concat(
																	_.find(filteredData, (fd) => {
																		return fd?.trialId === trialId;
																	})?.unPublishedTrialFlights
																)
																.concat(
																	_.find(filteredData, (fd) => {
																		return fd?.trialId === trialId;
																	})?.doNotPublishTrialFlights
																)}
															isTrialOwner={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.trialOwnerUserId === userAuth.user.userInfo.basic.userId ||
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.trialOwnerClientId === userAuth.currentClientId
															}
															handlePublishUnpublishSave={handlePublishUnpublishSave}
															trialUserName={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.trialOwner
															}
															isPending={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.trialOwnerUserId === userAuth.user.userInfo.basic.userId ||
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.trialOwnerClientId === userAuth.currentClientId
																	? _.find(filteredData, (fd) => {
																			return fd?.trialId === trialId;
																	  })?.trialSponsorIsPending
																	: _.find(filteredData, (fd) => {
																			return fd?.trialId === trialId;
																	  })?.trialOwnerIsPending
															}
															openModal={innerModalOpen}
															handleCancel={() => {
																setModalOpen(false);
																setInnerModalOpen(false);
															}}
															trialName={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.trialName
															}
															flightScheduleName={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.flightScheduleName
															}
															flightScheduleId={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.flightScheduleId
															}
															cooperatorName={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.cooperatorName
															}
															city={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.farmCity
															}
															state={
																_.find(filteredData, (fd) => {
																	return fd?.trialId === trialId;
																})?.farmState
															}
															flightScheduleOptions={flightScheduleDataOptions}
															updateFilteredData={updateFilteredData}
														/>
													)}
												</Modal>
											}
										</div>
									</Table.Cell>
								</Table.Row>
							);
						})
					}
				</Table.Body>
				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="13">
							<span style={{ marginRight: 10 }}>{dataSource.length === 1 ? `${dataSource.length} trial` : `${dataSource.length} trials`}</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 10, value: 10 },
									{ key: 2, text: 15, value: 15 },
									{ key: 3, text: 25, value: 25 },
									{ key: 4, text: 50, value: 50 },
									{ key: 5, text: 100, value: 100 },
									{ key: 6, text: 1000, value: 1000 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>

			</Table>
		</Segment>) : null
};

BulkFlightTrialsTable.propTypes = {
	trials: PropTypes.array,
	growingSeasonData: PropTypes.array,
	companyData: PropTypes.array,
	farmData: PropTypes.array,
	trialOwnerData: PropTypes.array,
	cropData: PropTypes.array,
	flightScheduleData: PropTypes.array,
	flightScheduleDataOptions: PropTypes.array,
	getBulkTrialPublishingData: PropTypes.func,
	loadingData: PropTypes.bool
};

export default BulkFlightTrialsTable;
