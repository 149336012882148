import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Icon, List } from "semantic-ui-react";

const FarmListItem = ({
	id,
	parcelName,
	subText,
	context,
	onClickIcon,
	onClickItem,
	onMouseEnter,
	onMouseLeave,
	hoveredParcel,
	focusedParcel
}) => {
	const className = useMemo(() => {
		let c = "";
		if (focusedParcel === id) {
			c += "focused ";
		}
		if (hoveredParcel === id) {
			c += "hovered ";
		}
		return c;
	}, [hoveredParcel, focusedParcel]);

	return (
		<List.Item style={{ padding: 0 }} key={id} className={className}>
			<div
				style={{ display: "flex", width: "100%", height: "100%", alignItems: "center" }}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<List.Content style={{ flexGrow: 2, padding: ".5em" }} onClick={() => onClickItem()}>
					<List.Header>{parcelName}</List.Header>
					<List.Description>{subText}</List.Description>
				</List.Content>
				<div
					style={{ display: "flex", alignItems: "center", padding: ".5em", height: "100%" }}
					onClick={() => {
						onClickIcon();
					}}
				>
					{context === "search" ? (
						<Icon name="plus" size="large" color="black" style={{ margin: 0 }} />
					) : (
						<Icon name="pencil" size="large" color="black" style={{ margin: 0 }} />
					)}
				</div>
			</div>
		</List.Item>
	);
};

FarmListItem.propTypes = {
	id: PropTypes.string,
	parcelName: PropTypes.string,
	subText: PropTypes.string,
	context: PropTypes.string,
	onClickIcon: PropTypes.func,
	onClickItem: PropTypes.func,
	/** Triggers when an object is hovered into, can optionally include a function in the return statement for clean-up i.e. hover out effect. */
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	hoveredParcel: PropTypes.string,
	focusedParcel: PropTypes.string
};

export default FarmListItem;
