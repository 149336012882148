import * as types from "./actionTypes";
import * as protocolApi from "../../apis/protocolApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function getProtocolInfoOptionsSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_INFO_OPTIONS_SUCCESS };
}
export function getProtocolFavoritesSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_FAVORITES_SUCCESS };
}
export function getProtocolFavoriteGuidsSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_FAVORITE_GUIDS_SUCCESS };
}
export function createProtocolFavoriteSuccess() {
	return { type: types.PROTOCOLS_CREATE_PROTOCOL_FAVORITE_SUCCESS };
}
export function deleteProtocolFavoriteSuccess() {
	return { type: types.PROTOCOLS_DELETE_PROTOCOL_FAVORITE_SUCCESS };
}
export function getProtocolQuestionsSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_QUESTIONS_SUCCESS };
}
export function getProtocolsSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOLS_SUCCESS };
}
export function getAssociatedTrialsSuccess() {
	return { type: types.PROTOCOLS_GET_ASSOCIATED_TRIALS_SUCCESS };
}

export function getRecentlyUpdatedProtocolsSuccess() {
	return { type: types.PROTOCOLS_GET_RECENTLY_UPDATED_PROTOCOLS_SUCCESS };
}

export function createAssociatedTrialsSuccess() {
	return { type: types.PROTOCOLS_CREATE_ASSOCIATED_TRIALS_SUCCESS };
}

export function getProtocolDataSuccess(protocolData) {
	return { type: types.PROTOCOLS_GET_PROTOCOL_DATA_SUCCESS, protocolData };
}

export function createProtocolSuccess(protocolData) {
	return { type: types.PROTOCOLS_CREATE_PROTOCOL_SUCCESS, protocolData };
}

export function deleteProtocolSuccess() {
	return { type: types.PROTOCOLS_DELETE_PROTOCOL_SUCCESS };
}

export function updateProtocolSuccess(protocolData) {
	return { type: types.PROTOCOLS_UPDATE_PROTOCOL_SUCCESS, protocolData };
}

export function getProtocolTreatmentDataSuccess(treatmentData) {
	return { type: types.PROTOCOLS_GET_PROTOCOL_TREATMENT_DATA_SUCCESS, treatmentData };
}

export function getProtocolTreatmentOptionsSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_TREATMENT_OPTIONS_SUCCESS };
}

export function createProtocolTreatmentDataSuccess(treatmentData) {
	return { type: types.PROTOCOLS_CREATE_PROTOCOL_TREATMENT_DATA_SUCCESS, treatmentData };
}

export function updateProtocolTreatmentDataSuccess(treatmentData) {
	return { type: types.PROTOCOLS_UPDATE_PROTOCOL_TREATMENT_DATA_SUCCESS, treatmentData };
}

export function getSummaryInfoSuccess() {
	return { type: types.PROTOCOLS_GET_SUMMARY_INFO_SUCCESS };
}

export function setSummaryInfoSuccess() {
	return { type: types.PROTOCOLS_SET_SUMMARY_INFO_SUCCESS };
}

export function saveAssessmentsSuccess() {
	return { type: types.PROTOCOLS_SAVE_ASSESSMENTS_SUCCESS };
}

export function getProtocolOverviewInfoSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_OVERVIEW_INFO_SUCCESS };
}

export function resetProtocolDataSuccess() {
	return { type: types.PROTOCOLS_RESET_PROTOCOL_DATA_SUCCESS };
}

export function getProtocolDashboardOptionsSuccess() {
	return { type: types.PROTOCOLS_GET_PROTOCOL_DASHBOARD_OPTIONS_SUCCESS };
}

export function getPlotAnalysisStatusForProtocolSuccess() {
	return { type: types.PROTOCOLS_GET_PLOT_ANALYSIS_STATUS_FOR_PROTOCOL_SUCCESS };
}

export function getAucMeanComparisonLettersForProtocolSuccess() {
	return { type: types.PROTOCOLS_GET_AUC_MEAN_COMPARISON_LETTERS_FOR_PROTOCOL_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function saveAssessments(assessments, protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.saveAssessments(assessments, protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(saveAssessmentsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolInfoOptions(clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolInfoOptions(clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolInfoOptionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolFavorites(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolFavorites(clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolFavoritesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolFavoriteGuids(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolFavoriteGuids(clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getProtocolFavoriteGuidsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createProtocolFavorite(clientId, accessToken, protocolId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.createProtocolFavorite(clientId, accessToken, protocolId)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createProtocolFavoriteSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteProtocolFavorite(clientId, accessToken, protocolId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.deleteProtocolFavorite(clientId, accessToken, protocolId)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(deleteProtocolFavoriteSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolQuestions(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolQuestions(clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolQuestionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocols(clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocols(clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolData(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolData(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolDataSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteProtocol(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.deleteProtocol(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(deleteProtocolSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createProtocol(protocolData, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.createProtocol(protocolData, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createProtocolSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateProtocol(protocolData, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.updateProtocol(protocolData, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(updateProtocolSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAssociatedTrials(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getAssociatedTrials(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getAssociatedTrialsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getRecentlyUpdatedProtocols(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getRecentlyUpdatedProtocols(clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getRecentlyUpdatedProtocolsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolTreatmentData(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolTreatmentData(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolTreatmentDataSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createAssociatedTrials(protocolId, trialIds, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.createAssociatedTrials(protocolId, trialIds, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createAssociatedTrialsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolTreatmentOptions(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolTreatmentOptions(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolTreatmentOptionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createProtocolTreatmentData(protocolTreatmentData, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.createProtocolTreatmentData(protocolTreatmentData, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createProtocolTreatmentDataSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateProtocolTreatmentData(protocolTreatmentData, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.updateProtocolTreatmentData(protocolTreatmentData, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(updateProtocolTreatmentDataSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getSummaryInfo(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getSummaryInfo(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getSummaryInfoSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function setSummaryInfo(protocolId, overwriteTreatmentNames, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.setSummaryInfo(protocolId, overwriteTreatmentNames, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(setSummaryInfoSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolOverviewInfo(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolOverviewInfo(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolOverviewInfoSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function resetProtocolData() {
	return (dispatch) => {
		dispatch(resetProtocolDataSuccess());
	};
}

export function getProtocolDashboardOptions(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getProtocolDashboardOptions(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getProtocolDashboardOptionsSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPlotAnalysisStatusForProtocol(protocolId, clientId, checkFlights, checkCurveModels, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getPlotAnalysisStatusForProtocol(protocolId, clientId, checkFlights, checkCurveModels, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getPlotAnalysisStatusForProtocolSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAucMeanComparisonLettersForProtocol(
	uncheckedTrials,
	uncheckedTreatments,
	protocolId,
	clientId,
	analysisId,
	analysisTypeId,
	assessmentId,
	groundDataGrowthPhaseId,
	curveModelAnalysisId,
	quantifiedRegionTypeId,
	meanComparisonId,
	alphaId,
	dataNormalizationId,
	useGdus,
	removeDamagedOrExcluded,
	isUniformity,
	startPeriod,
	endPeriod,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return protocolApi
				.getAucMeanComparisonLettersForProtocol(
					uncheckedTrials,
					uncheckedTreatments,
					protocolId,
					clientId,
					analysisId,
					analysisTypeId,
					assessmentId,
					groundDataGrowthPhaseId,
					curveModelAnalysisId,
					quantifiedRegionTypeId,
					meanComparisonId,
					alphaId,
					dataNormalizationId,
					useGdus,
					removeDamagedOrExcluded,
					isUniformity,
					startPeriod,
					endPeriod,
					accessToken
				)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getAucMeanComparisonLettersForProtocolSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
