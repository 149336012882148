import * as types from "./actionTypes";
import * as farmApi from "../../apis/farmApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as farmTypes from "../../apis/types/farmTypes";

/*
 *  Action Creators
 *
 */
export function getFarmsSuccess() {
	return { type: types.FARM_GET_FARMS_SUCCESS };
}

export function getFarmInfoSuccess() {
	return { type: types.FARM_GET_FARM_INFO_SUCCESS };
}

export function createFarmSuccess() {
	return { type: types.FARM_CREATE_FARM_SUCCESS };
}

export function updateFarmSuccess() {
	return { type: types.FARM_UPDATE_FARM_SUCCESS };
}

export function deleteFarmSuccess() {
	return { type: types.FARM_DELETE_FARM_SUCCESS };
}

export function updateFarmBoundsSuccess() {
	return { type: types.FARM_UPDATE_BOUNDS_SUCCESS };
}

export function createFarmParcelsSuccess() {
	return { type: types.FARM_CREATE_PARCELS_SUCCESS };
}

export function getFarmSummarySuccess() {
	return { type: types.FARM_GET_SUMMARY_SUCCESS };
}

export function getFarmParcelsSuccess() {
	return { type: types.FARM_GET_PARCELS_SUCCESS };
}

export function getFlightsForFarmSuccess() {
	return { type: types.FARM_GET_FLIGHTS_FOR_FARM_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getFarms(accessToken, clientId) {
	return (dispatch) => {
		return new Promise<farmTypes.FarmData[]>((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFarms(accessToken, clientId)
				.then((res) => {
					dispatch(getFarmsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function getFarmInfo(accessToken, clientId, farmId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFarmInfo(accessToken, clientId, farmId)
				.then((res) => {
					dispatch(getFarmInfoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function createFarm(accessToken, newFarm) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.createFarm(accessToken, newFarm)
				.then((res) => {
					dispatch(createFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function updateFarm(accessToken, updateFarm) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.updateFarm(accessToken, updateFarm)
				.then((res) => {
					dispatch(updateFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function deleteFarm(accessToken, clientId, farmId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.deleteFarm(accessToken, clientId, farmId)
				.then((res) => {
					dispatch(deleteFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function createFarmParcels(accessToken, clientId, farmId, parcels) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.createFarmParcels(accessToken, clientId, farmId, parcels)
				.then((res) => {
					dispatch(createFarmParcelsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function getFarmParcels(accessToken, clientId, farmId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFarmParcels(accessToken, clientId, farmId)
				.then((res) => {
					dispatch(getFarmParcelsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function updateFarmBounds(accessToken, clientId, farmId, LL, UL, UR, LR) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.updateFarmBounds(accessToken, clientId, farmId, LL, UL, UR, LR)
				.then((res) => {
					dispatch(updateFarmBoundsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function getFarmSummary(accessToken, clientId, farmId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFarmSummary(accessToken, clientId, farmId)
				.then((res) => {
					dispatch(getFarmSummarySuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}

export function getFlightsForFarm(farmId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFlightsForFarm(farmId, clientId, accessToken)
				.then((res) => {
					dispatch(getFlightsForFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError());
					reject(error);
				});
		});
	};
}
