import { reduxStore } from "../index";
import _ from "lodash";
import { toast } from "react-toastify";
import * as orthoActions from "../redux/actions/orthoActions";
import * as azure from "@azure/storage-file-share";

const imageApiUri = process.env.aerialPlotConnections.aerialImageApi.baseUrl + "api/v1/";
const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl + "api/v1/";

export function downloadOrtho(flightImageId, clientId, accessToken) {
	return fetch(`${imageApiUri}Download?flightImageId=${flightImageId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.blob())
		.then((data) => {
			return URL.createObjectURL(data);
		});
}

export function cutOrtho(cutParams, accessToken) {
	return fetch(imageApiUri + "Cut", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(cutParams)
	})
		.then((res) => res)
		.then((data) => data);
}

export async function handleSasToken(data, fileName) {
	toast.info("Ortho downloading...");

	const shareClient = new azure.ShareClient(data.data.uri);
	const fileClient = shareClient.getDirectoryClient("images").getFileClient(data.data.fileName);

	const downloadFileResponse = await fileClient.download(0);

	return Promise.resolve(downloadFileResponse.blobBody).then((r) => {
		const url = window.URL.createObjectURL(r);
		let tempLink = document.createElement("a");
		tempLink.href = url;
		tempLink.setAttribute("download", fileName);
		tempLink.click();
	});
}

export function downloadFlightElevationOrtho(flightElevationImageId, fileName, clientId, accessToken) {
	// eslint-disable-next-line
	const downloadOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(
				`${imageApiUri}Download/GetFileSASUri?flightElevationImageId=${flightElevationImageId}&clientId=${clientId}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			)
				.then((res) => {
					if (res.ok === false) {
						reject(res);
					} else {
						return res.json();
					}
				})
				.then(async (data) => {
					await handleSasToken(data, fileName);
				})
		);
	});

	return downloadOrthoPromise;
}

export function downloadFlightMultibandOrtho(flightMultibandImageId, fileName, clientId, accessToken) {
	// eslint-disable-next-line
	const downloadOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(
				`${imageApiUri}Download/GetFileSASUri?multibandFlightImageId=${flightMultibandImageId}&clientId=${clientId}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			)
				.then((res) => {
					if (res.ok === false) {
						reject(res);
					} else {
						return res.json();
					}
				})
				.then(async (data) => {
					await handleSasToken(data, fileName);
				})
		);
	});

	return downloadOrthoPromise;
}

export function deleteFlightMultibandImage(flightMultibandImageId, clientId, accessToken) {
	// eslint-disable-next-line
	const deleteOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(
				`${imageApiUri}Delete/FlightMultibandImage?flightMultibandImageId=${flightMultibandImageId}&clientId=${clientId}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			).then((res) => res)
		);
	});
	return deleteOrthoPromise;
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function getRetryTimeInSeconds(retryAttempt) {
	switch (retryAttempt) {
		case 1:
			return 15;
		case 2:
			return 30;
		case 3:
			return 60;
	}
}

export function downloadAzureFilesList(directoryName, accessToken) {
	// eslint-disable-next-line
	const downloadFilesListPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Download/GetFilesList?directoryName=${directoryName}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}).then((res) => {
				if (res.ok === false) {
					reject(res);
				} else {
					return res.json();
				}
			})
		);
	});
	return downloadFilesListPromise;
}

export function copyAzureFileToShare(azureFileData, accessToken, clientId) {
	// eslint-disable-next-line
	const copyAzureFileToSharePromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Upload/CopyAzureFileToShare?clientId=${clientId}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(azureFileData)
			}).then((res) => {
				if (res.ok === false) {
					reject(res);
				} else {
					return res.json();
				}
			})
		);
	});
	return copyAzureFileToSharePromise;
}

export function copyAzureFileToShareMultiband(azureFileData, accessToken, clientId) {
	// eslint-disable-next-line
	const copyAzureFileToSharePromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Upload/CopyAzureFileToShareMultiband?clientId=${clientId}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(azureFileData)
			}).then((res) => {
				if (res.ok === false) {
					reject(res);
				} else {
					return res.json();
				}
			})
		);
	});
	return copyAzureFileToSharePromise;
}

export function copyAzureFileToShareElevation(azureFileData, accessToken, clientId) {
	// eslint-disable-next-line
	const copyAzureFileToSharePromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Upload/CopyAzureFileToShareElevation?clientId=${clientId}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(azureFileData)
			}).then((res) => {
				if (res.ok === false) {
					reject(res);
				} else {
					return res.json();
				}
			})
		);
	});
	return copyAzureFileToSharePromise;
}

export function createInitialFlightImageRecord(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	accessToken
) {
	const startingChunkNumber = 0;
	return new Promise((resolve, reject) => {
		resolve(
			fetch(`${imageApiUri}Upload/CreateInitialFlightImageRecord?clientId=${clientId}`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				body: initialRecord
			})
				.then((res) => res.json())
				.then(async (data) => {
					await uploadOrthoImageChunks(
						initialRecord,
						clientId,
						selectedFile,
						chunkSize,
						selectedOrtho,
						data.data,
						startingChunkNumber,
						accessToken,
						reject
					);
				})
		);
	});
}

export function retryOrthoImageUploadChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightImageId,
	startingChunkNumber,
	accessToken
) {
	return new Promise((resolve, reject) => {
		resolve(
			uploadOrthoImageChunks(
				initialRecord,
				clientId,
				selectedFile,
				chunkSize,
				selectedOrtho,
				flightImageId,
				startingChunkNumber,
				accessToken,
				reject
			)
		);
	});
}

async function uploadOrthoImageChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightImageId,
	startingChunkNumber,
	accessToken,
	reject
) {
	let retryAttempt = 0;
	let maxNumberOfRetryAttempts = 3;
	if (!selectedOrtho.uploadedFlightImageId) {
		let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
		updatedSelectedOrtho.uploadedFlightImageId = flightImageId;
		reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
	}

	const totalChunkCount =
		selectedFile.size % chunkSize == 0 ? selectedFile.size / chunkSize : Math.floor(selectedFile.size / chunkSize) + 1;

	let beginningOfChunk = startingChunkNumber * chunkSize;
	let endOfChunk = startingChunkNumber * chunkSize + chunkSize;

	let mergeData = new FormData();
	mergeData.set("clientId", clientId);
	mergeData.set("flightImageId", flightImageId);
	mergeData.set("sensorId", initialRecord.get("sensorId"));
	mergeData.set("flightId", initialRecord.get("flightId"));
	mergeData.set("fileLength", selectedFile.size);

	let isDeleting = false;
	const unsbuscribe = reduxStore.subscribe(() => {
		const uploadingOrtho = _.find(reduxStore.getState().orthoUploads, (fe) => {
			return fe.uploadedFlightImageId === flightImageId;
		});
		if (!isDeleting) {
			isDeleting = uploadingOrtho?.isDeleting;
		}
	});

	for (let order = startingChunkNumber + 1; order <= totalChunkCount; order++) {
		if (isDeleting) {
			unsbuscribe();
			reduxStore.dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));
			return;
		}

		let chunkedFile = selectedFile.slice(beginningOfChunk, endOfChunk);

		let formData = new FormData();
		formData.append("formFile", chunkedFile, selectedFile.name);
		formData.set("order", order);
		formData.set("flightImageId", flightImageId);
		formData.set("clientId", clientId);
		formData.set("maxNumberOfChunks", totalChunkCount);

		let error = false;
		let maxNumberOfRetryAttemptsExceeded = false;
		await uploadImageChunk(formData, accessToken)
			.then(() => {
				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				updatedSelectedOrtho.uploadPercent = Math.ceil((order / totalChunkCount) * 100);
				updatedSelectedOrtho.retryingUpload = false;
				retryAttempt = 0;
				reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));

				if (order >= totalChunkCount) {
					mergeFlightImageChunks(mergeData, accessToken)
						.then((res) => {
							unsbuscribe();
							//-- Message only appears if there is an error
							if (res.data.Message) {
								toast.error(res.data.Message);

								let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
								updatedSelectedOrtho.isUploading = false;
								updatedSelectedOrtho.showTooltip = false;
								toast.error("Ortho failed to upload");

								reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
								reduxStore.dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));

								return false;
							}

							let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
							updatedSelectedOrtho.isUploaded = true;
							updatedSelectedOrtho.isUploading = false;
							toast.success("Ortho Uploaded");

							reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
							reduxStore.dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));

							return res.data;
						})
						.catch((e) => {
							if (e.response && (e.response.status === 500 || e.response.status === 600)) {
								let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
								updatedSelectedOrtho.isUploading = false;
								updatedSelectedOrtho.showTooltip = false;
								toast.error("Ortho failed to upload");

								reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
							}

							reduxStore.dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));

							reject(e);
						});
				}
			})
			.catch(async (err) => {
				console.error(err);
				error = true;
				reject(err);
				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				if (retryAttempt < maxNumberOfRetryAttempts) {
					//-- Retry the upload again
					retryAttempt++;
					order--;
					updatedSelectedOrtho.retryingUpload = true;
					updatedSelectedOrtho.retryAttempt = retryAttempt;
					updatedSelectedOrtho.maxNumberRetryAttempts = maxNumberOfRetryAttempts;
					updatedSelectedOrtho.uploadPercent = Math.ceil((order / totalChunkCount) * 100);
					updatedSelectedOrtho.retryInSeconds = getRetryTimeInSeconds(retryAttempt);
					reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
					//-- multiply by 1000 to get milliseconds
					await delay(updatedSelectedOrtho.retryInSeconds * 1000);
				} else {
					updatedSelectedOrtho.retryingUpload = false;
					updatedSelectedOrtho.isUploading = false;
					updatedSelectedOrtho.showTooltip = true;
					updatedSelectedOrtho.showRetryButton = true;
					//-- Order - 1 because the upload failed on the current iteration, so the number of chunks uploaded is the previous iteration order number
					updatedSelectedOrtho.numberOfChunksUploaded = order - 1;
					toast.error("Ortho failed to upload");

					reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
					reduxStore.dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));
					maxNumberOfRetryAttemptsExceeded = true;
					return;
				}
			});

		if (maxNumberOfRetryAttemptsExceeded === true) {
			return;
		}
		if (!error) {
			beginningOfChunk = endOfChunk;
			endOfChunk = endOfChunk + chunkSize;
		}
		//-- reset the error flag
		error = false;
	}
}

export function createInitialMultiBandFlightImageRecord(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	accessToken
) {
	const startingChunkNumber = 0;
	return new Promise((resolve, reject) => {
		resolve(
			fetch(`${imageApiUri}Upload/CreateInitialMultiBandFlightImageRecord?clientId=${clientId}`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				body: initialRecord
			})
				.then((res) => res.json())
				.then(async (data) => {
					await uploadMultiBandFlightImage(
						initialRecord,
						clientId,
						selectedFile,
						chunkSize,
						selectedOrtho,
						data.data,
						startingChunkNumber,
						accessToken,
						reject
					);
				})
		);
	});
}

export function retryUploadingMultibandFlightImageChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightMultibandImageId,
	startingChunkNumber,
	accessToken
) {
	return new Promise((resolve, reject) => {
		resolve(
			uploadMultiBandFlightImage(
				initialRecord,
				clientId,
				selectedFile,
				chunkSize,
				selectedOrtho,
				flightMultibandImageId,
				startingChunkNumber,
				accessToken,
				reject
			)
		);
	});
}

async function uploadMultiBandFlightImage(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightMultibandImageId,
	startingChunkNumber,
	accessToken,
	reject
) {
	let retryAttempt = 0;
	let maxNumberOfRetryAttempts = 3;
	if (!selectedOrtho.flightMultibandImageId) {
		let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
		updatedSelectedOrtho.flightMultibandImageId = flightMultibandImageId;
		reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));
	}

	const totalChunkCount =
		selectedFile.size % chunkSize == 0 ? selectedFile.size / chunkSize : Math.floor(selectedFile.size / chunkSize) + 1;

	let beginningOfChunk = startingChunkNumber * chunkSize;
	let endOfChunk = startingChunkNumber * chunkSize + chunkSize;

	let chunksUploaded = [];

	let mergeData = new FormData();
	mergeData.set("clientId", clientId);
	mergeData.set("multiBandFlightImageId", flightMultibandImageId);
	mergeData.set("sensorId", initialRecord.get("sensorId"));
	mergeData.set("flightId", initialRecord.get("flightId"));
	mergeData.set("fileLength", selectedFile.size);

	let isDeleting = false;
	const unsbuscribe = reduxStore.subscribe(() => {
		const uploadingOrtho = _.find(reduxStore.getState().flightMultibandOrthoUploads, (fe) => {
			return fe.flightMultibandImageId === flightMultibandImageId;
		});
		if (!isDeleting) {
			isDeleting = uploadingOrtho?.isDeleting;
		}
	});

	for (let order = startingChunkNumber + 1; order <= totalChunkCount; order++) {
		if (isDeleting) {
			unsbuscribe();
			reduxStore.dispatch(orthoActions.removeMultibandOrthoUpload(selectedOrtho.flightId));
			return;
		}
		let chunkedFile = selectedFile.slice(beginningOfChunk, endOfChunk);

		let formData = new FormData();
		formData.append("formFile", chunkedFile, selectedFile.name);
		formData.set("order", order);
		formData.set("multiBandFlightImageId", flightMultibandImageId);
		formData.set("clientId", clientId);
		formData.set("maxNumberOfChunks", totalChunkCount);

		let error = false;
		let maxNumberOfRetryAttemptsExceeded = false;
		await uploadImageChunk(formData, accessToken)
			.then(() => {
				chunksUploaded.push(order);
				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				updatedSelectedOrtho.uploadPercent = Math.ceil((order / totalChunkCount) * 100);
				updatedSelectedOrtho.retryingUpload = false;
				retryAttempt = 0;

				reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));

				if (order >= totalChunkCount) {
					mergeMultiBandFlightImageChunks(mergeData, accessToken)
						.then((res) => {
							unsbuscribe();
							//-- Message only appears if there is an error
							if (res.data.Message) {
								toast.error(res.data.Message);

								let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
								updatedSelectedOrtho.isUploading = false;
								updatedSelectedOrtho.showTooltip = false;

								reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));
								reduxStore.dispatch(orthoActions.removeMultibandOrthoUpload(selectedOrtho.flightId));

								return false;
							}

							let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
							updatedSelectedOrtho.isUploaded = true;
							updatedSelectedOrtho.isUploading = false;
							toast.success("Ortho Uploaded");

							reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));
							reduxStore.dispatch(orthoActions.removeMultibandOrthoUpload(res.data));

							let autoUploadOrthos = _.cloneDeep(res.data.flightImageToSaveList);

							if (autoUploadOrthos) {
								reduxStore.dispatch(orthoActions.autoOrthoUpload(autoUploadOrthos));
								reduxStore.dispatch(orthoActions.autoOrthoRemove(autoUploadOrthos));
							}

							return res.data;
						})
						.catch((e) => {
							if (e.response && (e.response.status === 500 || e.response.status === 600)) {
								let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
								updatedSelectedOrtho.isUploading = false;
								updatedSelectedOrtho.showTooltip = false;
								toast.error("Ortho failed to upload");

								reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));
							}

							reduxStore.dispatch(orthoActions.removeMultibandOrthoUpload(selectedOrtho.flightId));

							reject(e);
						});
				}
			})
			.catch(async (err) => {
				console.error(err);
				error = true;

				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				if (retryAttempt < maxNumberOfRetryAttempts) {
					//-- Retry the upload again
					retryAttempt++;
					order--;
					updatedSelectedOrtho.retryingUpload = true;
					updatedSelectedOrtho.retryAttempt = retryAttempt;
					updatedSelectedOrtho.maxNumberRetryAttempts = maxNumberOfRetryAttempts;
					updatedSelectedOrtho.uploadPercent = Math.ceil((order / totalChunkCount) * 100);
					updatedSelectedOrtho.retryInSeconds = getRetryTimeInSeconds(retryAttempt);
					reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));
					//-- multiply by 1000 to get milliseconds
					await delay(updatedSelectedOrtho.retryInSeconds * 1000);
				} else {
					updatedSelectedOrtho.retryingUpload = false;
					updatedSelectedOrtho.isUploading = false;
					updatedSelectedOrtho.showTooltip = false;
					updatedSelectedOrtho.showRetryButton = true;
					//-- Order - 1 because the upload failed on the current iteration, so the number of chunks uploaded is the previous iteration order number
					updatedSelectedOrtho.numberOfChunksUploaded = order - 1;
					toast.error("Ortho failed to upload");
					reduxStore.dispatch(orthoActions.updateUploadingMultibandOrtho(updatedSelectedOrtho));
					reduxStore.dispatch(orthoActions.removeMultibandOrthoUpload(selectedOrtho.flightId));
					maxNumberOfRetryAttemptsExceeded = true;
					return;
				}
			});

		if (maxNumberOfRetryAttemptsExceeded === true) {
			return;
		}
		if (!error) {
			beginningOfChunk = endOfChunk;
			endOfChunk = endOfChunk + chunkSize;
		}
		//-- reset the error flag
		error = false;
	}
}

export function createInitialFlightElevationImageRecord(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	accessToken
) {
	const startingChunkNumber = 0;
	return new Promise((resolve, reject) => {
		resolve(
			fetch(`${imageApiUri}Upload/CreateInitialFlightElevationImageRecord?clientId=${clientId}`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				body: initialRecord
			})
				.then((res) => res.json())
				.then(async (data) => {
					await uploadFlightElevationImageChunks(
						initialRecord,
						clientId,
						selectedFile,
						chunkSize,
						selectedOrtho,
						data.data,
						startingChunkNumber,
						accessToken,
						reject
					);
				})
				.finally(() => {})
		);
	});
}

export function retryUploadingFlightElevationImageChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightElevationImageId,
	startingChunkNumber,
	accessToken
) {
	return new Promise((resolve, reject) => {
		resolve(
			uploadFlightElevationImageChunks(
				initialRecord,
				clientId,
				selectedFile,
				chunkSize,
				selectedOrtho,
				flightElevationImageId,
				startingChunkNumber,
				accessToken,
				reject
			)
		);
	});
}

async function uploadFlightElevationImageChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightElevationImageId,
	startingChunkNumber,
	accessToken,
	reject
) {
	let retryAttempt = 0;
	let maxNumberOfRetryAttempts = 3;
	if (!selectedOrtho.flightElevationImageId) {
		let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
		updatedSelectedOrtho.flightElevationImageId = flightElevationImageId;
		reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));
	}

	const totalChunkCount =
		selectedFile.size % chunkSize == 0 ? selectedFile.size / chunkSize : Math.floor(selectedFile.size / chunkSize) + 1;

	let beginningOfChunk = startingChunkNumber * chunkSize;
	let endOfChunk = startingChunkNumber * chunkSize + chunkSize;

	let chunksUploaded = [];

	let mergeData = new FormData();
	mergeData.set("clientId", clientId);
	mergeData.set("flightElevationImageId", flightElevationImageId);
	mergeData.set("sensorId", initialRecord.get("sensorId"));
	mergeData.set("flightId", initialRecord.get("flightId"));
	mergeData.set("fileLength", selectedFile.size);

	let isDeleting = false;
	const unsbuscribe = reduxStore.subscribe(() => {
		const uploadingOrtho = _.find(reduxStore.getState().flightElevationOrthoUploads, (fe) => {
			return fe.flightElevationImageId === flightElevationImageId;
		});
		if (!isDeleting) {
			isDeleting = uploadingOrtho?.isDeleting;
		}
	});

	for (let order = startingChunkNumber + 1; order <= totalChunkCount; order++) {
		if (isDeleting) {
			unsbuscribe();
			reduxStore.dispatch(orthoActions.removeElevationOrthoUpload(selectedOrtho.flightId));
			return;
		}

		let chunkedFile = selectedFile.slice(beginningOfChunk, endOfChunk);

		let formData = new FormData();
		formData.append("formFile", chunkedFile, selectedFile.name);
		formData.set("order", order);
		formData.set("flightElevationImageId", flightElevationImageId);
		formData.set("clientId", clientId);
		formData.set("maxNumberOfChunks", totalChunkCount);
		let error = false;
		let maxNumberOfRetryAttemptsExceeded = false;
		await uploadImageChunk(formData, accessToken)
			.then(async () => {
				chunksUploaded.push(order);
				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				updatedSelectedOrtho.uploadPercent = Math.ceil((order / totalChunkCount) * 100);
				updatedSelectedOrtho.retryingUpload = false;
				retryAttempt = 0;

				reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));

				if (order >= totalChunkCount) {
					unsbuscribe();
					await processFlightElevationImage(
						selectedOrtho,
						flightElevationImageId,
						clientId,
						mergeData,
						reject,
						accessToken
					);
				}
			})
			.catch(async (err) => {
				console.error(err);
				error = true;
				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				if (retryAttempt < maxNumberOfRetryAttempts) {
					//-- Retry the upload again
					retryAttempt++;
					order--;
					updatedSelectedOrtho.retryingUpload = true;
					updatedSelectedOrtho.retryAttempt = retryAttempt;
					updatedSelectedOrtho.maxNumberRetryAttempts = maxNumberOfRetryAttempts;
					updatedSelectedOrtho.uploadPercent = Math.ceil((order / totalChunkCount) * 100);
					updatedSelectedOrtho.retryInSeconds = getRetryTimeInSeconds(retryAttempt);
					reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));
					//-- multiply by 1000 to get milliseconds
					await delay(updatedSelectedOrtho.retryInSeconds * 1000);
				} else {
					updatedSelectedOrtho.retryingUpload = false;
					updatedSelectedOrtho.isUploading = false;
					updatedSelectedOrtho.showTooltip = true;
					updatedSelectedOrtho.showRetryButton = true;
					//-- Order - 1 because the upload failed on the current iteration, so the number of chunks uploaded is the previous iteration order number
					updatedSelectedOrtho.numberOfChunksUploaded = order - 1;
					toast.error("Ortho failed to upload");
					reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));
					reduxStore.dispatch(orthoActions.removeElevationOrthoUpload(selectedOrtho.flightId));
					maxNumberOfRetryAttemptsExceeded = true;
					return;
				}

				reject(err);
			});

		if (maxNumberOfRetryAttemptsExceeded === true) {
			return;
		}
		if (!error) {
			beginningOfChunk = endOfChunk;
			endOfChunk = endOfChunk + chunkSize;
		}
		//-- reset the error flag
		error = false;
	}
}

async function processFlightElevationImage(
	selectedOrtho,
	flightElevationImageId,
	clientId,
	mergeData,
	reject,
	accessToken
) {
	return mergeFlightElevationImageChunks(mergeData, accessToken)
		.then((res) => {
			//-- Message only appears if there is an error
			if (res.data.Message) {
				toast.error(res.data.Message);

				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				updatedSelectedOrtho.isUploading = false;
				updatedSelectedOrtho.showTooltip = false;

				reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));
				reduxStore.dispatch(orthoActions.removeElevationOrthoUpload(selectedOrtho.flightId));

				return false;
			}

			let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
			updatedSelectedOrtho.isUploaded = true;
			updatedSelectedOrtho.isUploading = false;
			toast.success("Ortho Uploaded");

			reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));
			reduxStore.dispatch(orthoActions.removeElevationOrthoUpload(res.data));

			return res.data;
		})
		.catch((e) => {
			if (e.response && (e.response.status === 500 || e.response.status === 600)) {
				let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
				updatedSelectedOrtho.isUploading = false;
				updatedSelectedOrtho.showTooltip = false;
				toast.error("Ortho failed to upload");

				reduxStore.dispatch(orthoActions.updateUploadingElevationOrtho(updatedSelectedOrtho));
			}

			reduxStore.dispatch(orthoActions.removeElevationOrthoUpload(selectedOrtho.flightId));

			reject(e);
		});
}

export function uploadImageChunk(formData, accessToken) {
	return fetch(`${imageApiUri}Upload/UploadImageChunk`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	}).then((res) => res);
}

export function generateOrthoFromMultibandOrtho(clientId, selectedOrtho, accessToken) {
	const orthoData = new FormData();
	orthoData.set("flightId", selectedOrtho.flightId);
	orthoData.set("sensorId", selectedOrtho.sensorId);
	orthoData.set("orthoImageTypeId", selectedOrtho.orthoImageTypeId);
	orthoData.set("fileName", selectedOrtho.name);
	orthoData.set("clientId", clientId);
	orthoData.set("orthoImageTypeAbbreviation", selectedOrtho.orthoImageTypeAbbreviation);
	orthoData.set("sensorName", selectedOrtho.sensorName);
	// eslint-disable-next-line
	const generateOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Upload/GenerateOrthoFromMultibandOrtho`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				body: orthoData
			})
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					if (data?.statusCode !== 200) {
						reject(data);
					}
					let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
					updatedSelectedOrtho.isUploaded = true;
					updatedSelectedOrtho.isUploading = false;
					updatedSelectedOrtho.uploadedFlightImageId = data.data;
					reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
					reduxStore.dispatch(orthoActions.removeOrthoUpload(updatedSelectedOrtho.flightImageId));
					return data;
				})
				.catch((e) => {
					//if the status is not a 500 then it is assumed that the service timed out
					if (e.response && (e.response.status === 500 || e.response.status === 600)) {
						let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
						updatedSelectedOrtho.isUploading = false;
						updatedSelectedOrtho.showTooltip = false;
						reduxStore.dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
					}

					reduxStore.dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));
					reject(e);
				})
		);
	});
	return generateOrthoPromise;
}

export function mergeFlightImageChunks(imageChunkData, accessToken) {
	return fetch(`${imageApiUri}Upload/MergeFlightImageChunks`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: imageChunkData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function mergeMultiBandFlightImageChunks(imageChunkData, accessToken) {
	return fetch(`${imageApiUri}Upload/MergeMultiBandFlightImageChunks`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: imageChunkData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function mergeFlightElevationImageChunks(imageChunkData, accessToken) {
	return fetch(`${imageApiUri}Upload/MergeFlightElevationImageChunks`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: imageChunkData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function deleteFlightImageChunks(imageChunkData, accessToken) {
	return fetch(`${imageApiUri}Delete/FlightImageChunks`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: imageChunkData
	}).then((res) => res);
}

export function deleteMultiBandFlightImageChunks(imageChunkData, accessToken) {
	return fetch(`${imageApiUri}Delete/MultiBandFlightImageChunks`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: imageChunkData
	}).then((res) => res);
}

export function deleteFlightElevationImageChunks(imageChunkData, accessToken) {
	return fetch(`${imageApiUri}Delete/FlightElevationImageChunks`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: imageChunkData
	}).then((res) => res);
}

export function getViewerFlightImage(flightId, flightImageId, clientId, accessToken) {
	return fetch(
		`${aerialPlotApi}OrthoUploadData/GetViewerFlightImage?flightId=${flightId}&flightImageId=${flightImageId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			if (res.status === 204) {
				return {
					id: null,
					llLat: null,
					llLong: null,
					ulLat: null,
					ulLong: null,
					lrLat: null,
					lrLong: null,
					urLat: null,
					urLong: null,
					histogram: null
				};
			} else {
				return res.json();
			}
		})
		.then((data) => data.data);
}

export function getFlightImagesForDate(farmId, orthoId, flightDateAndTime, fieldIds, trialIds, clientId, accessToken) {
	let formData = new FormData();

	formData.set("farmId", farmId);
	formData.set("orthoId", orthoId);
	formData.set("flightDateAndTime", flightDateAndTime);

	_.map(fieldIds, (id, i) => {
		formData.append(`FieldIds[${i}]`, id);
	});

	_.map(trialIds, (id, i) => {
		formData.append(`TrialIds[${i}]`, id);
	});

	return fetch(`${aerialPlotApi}OrthoUploadData/GetFlightImagesForDate?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: "POST",
		body: formData
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function cutPlotImages(orthoToCut, accessToken) {
	const orthoData = new FormData();

	orthoData.append("FlightId", orthoToCut.flightId);
	orthoData.append("ClientId", orthoToCut.clientId);
	orthoData.append("SensorId", orthoToCut.sensorId);
	orthoData.append("OrthoImageTypeId", orthoToCut.orthoImageTypeId);

	let index = 0;
	for (let ortho of orthoToCut.plotImageData) {
		orthoData.append(`PlotImageCoordinates[${index}].FieldId`, ortho.fieldId);
		orthoData.append(`PlotImageCoordinates[${index}].PlotId`, ortho.plotId);
		orthoData.append(`PlotImageCoordinates[${index}].LlLat`, ortho.llLat);
		orthoData.append(`PlotImageCoordinates[${index}].LlLong`, ortho.llLong);
		orthoData.append(`PlotImageCoordinates[${index}].UlLat`, ortho.ulLat);
		orthoData.append(`PlotImageCoordinates[${index}].UlLong`, ortho.ulLong);
		orthoData.append(`PlotImageCoordinates[${index}].LrLat`, ortho.lrLat);
		orthoData.append(`PlotImageCoordinates[${index}].LrLong`, ortho.lrLong);
		orthoData.append(`PlotImageCoordinates[${index}].UrLat`, ortho.urLat);
		orthoData.append(`PlotImageCoordinates[${index}].UrLong`, ortho.urLong);

		index++;
	}

	return fetch(`${imageApiUri}Cut/CutPlotImages`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: orthoData
	})
		.then((res) => res)
		.then((data) => data);
}

export function isFieldImageUploaded(imageToCheck, accessToken) {
	return fetch(
		`${imageApiUri}FlightImages/IsFieldImageUploaded?flightId=${imageToCheck.flightId}&orthoImageTypeId=${imageToCheck.orthoImageTypeId}&sensorId=${imageToCheck.sensorId}&clientId=${imageToCheck.clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getPlotImagesUploadedStatus(plotImagesToGet, accessToken) {
	return fetch(
		`${imageApiUri}FlightImages/GetPlotImagesUploadedStatus?flightId=${plotImagesToGet.flightId}&orthoImageTypeId=${plotImagesToGet.orthoImageTypeId}&sensorId=${plotImagesToGet.sensorId}&clientId=${plotImagesToGet.clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function retryMapboxUpload(flightImageId, clientId, accessToken) {
	return fetch(`${imageApiUri}Upload/RetryMapboxUpload?flightImageId=${flightImageId}&clientId=${clientId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}
