import { produce } from "immer";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Accordion, Button, Checkbox, Dropdown, Icon, Popup, Segment, Table } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as trialActions from "../../../../redux/actions/trialActions";
import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";
import DataAnalysisCriteria from "../../../Lumber/DataAnalysisCriteria";
import "./dropdown.css";
import "./style.css";

const SummaryTableSelections = ({
	selectedAlphaOption,
	setSelectedAlphaOption,
	selectedMeanComparisonOption,
	setSelectedMeanComparisonOption,
	summaryTableSelections,
	setSummaryTableSelections,
	getSingleAssessmentTrialData,
	fetchingData,
	rawTrialOptions,
	assessmentData,
	meanComparisonOptions,
	alphaOptions,
	assessmentOptions,
	summaryTablePresetOptions,
	selectedPresetOption,
	setSelectedPresetOption,
	quantifiedRegionTypeOptions,
	rawPresetOptions,
	updateAnalytics,
	setNeedToUpdateAnalytics,
	needToUpdateAnalytics,
	removeDamagedOrExcluded,
	setRemoveDamagedOrExcluded,
	removeOutliers,
	setOutlierType,
	outlierType,
	setRemoveOutliers
}) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "M/D/YYYY";

	//-- UI Control
	const [accordionActiveIndex, setAccordionActiveIndex] = useState(false);
	const [savingAssessmentSelection, setSavingAssessmentSelection] = useState(false);
	const [checkAll, setCheckAll] = useState(false);

	//-- Data Sources
	const [allAssessmentTypes, setAllAssessmentTypes] = useState(null);

	useEffect(() => {
		if (rawPresetOptions) {
			let groundDataOpts = _.map(rawTrialOptions.groundDataOptions, ({ groundDataAssessments }) => {
				return groundDataAssessments;
			});
			groundDataOpts = _.flatten(groundDataOpts);

			//-- Ground Data Presets
			let groundDataPresets = analysisDropDownListFunctions.getGroundDataPresetsForTrialAnalysis(groundDataOpts);
			//-- Growth Curve Presets
			let growthCurvePresets = analysisDropDownListFunctions.getGrowthCurvePresets(rawTrialOptions.growthCurveOptions);
			let analyses = groundDataPresets.concat(growthCurvePresets);

			//-- Time series Presets for Stand Counts
			let analysesDataOpts = _.map(rawTrialOptions.trialAnalysisOptions, ({ analysisTypes }) => {
				return analysisTypes;
			});
			analysesDataOpts = _.flatten(analysesDataOpts);
			analysesDataOpts = _.filter(analysesDataOpts, (ado) => {
				return ado.name.includes("Stand Count");
			});
			let analysesPresets = analysisDropDownListFunctions.getTimeSeriesPresetsForTrialAnalysis(analysesDataOpts);
			analyses = analyses.concat(analysesPresets);
			setAllAssessmentTypes(analyses);
		}
	}, [rawPresetOptions]);

	//-- Open or close the accordion on click
	const accordionClick = () => {
		const newIndex = !accordionActiveIndex;
		setAccordionActiveIndex(newIndex);
	};

	//-- check all selected assessments to be checked or unchecked
	function toggleCheckAll() {
		let selections = _.cloneDeep(summaryTableSelections);
		selections.map((selection) => {
			selection.assessments?.map((assessment) => {
				if (assessment.enabled) {
					assessment.checked = !checkAll;
				}
			});
		});
		setSummaryTableSelections(selections);
	}

	//-- Add the selected growth curve option to the summary table selections and get the data
	function growthCurveOptionSelected(value, index, displayName) {
		if (!displayName.includes(summaryTableSelections[index]?.displayName !== displayName)) {
			let newSummaryTableSelections = _.cloneDeep(summaryTableSelections);
			const growthCurve = _.find(rawTrialOptions.growthCurveOptions, (gdo) => {
				return gdo.curveModelName === value;
			});
			//Only set the update analytics button if a selection is changed after initial load
			if (!newSummaryTableSelections[index].initialLoad) {
				if (!fetchingData) {
					setSelectedPresetOption("none");
					setNeedToUpdateAnalytics(true);
				}
			}
			newSummaryTableSelections[index].assessments = null;
			newSummaryTableSelections[index].id = value;
			newSummaryTableSelections[index].checked = true;
			newSummaryTableSelections[index].enabled = true;
			newSummaryTableSelections[index].isCurveModel = true;
			newSummaryTableSelections[index].isAnalysis = false;
			newSummaryTableSelections[index].startDate = growthCurve?.curveModelAnalysisStartDate;
			newSummaryTableSelections[index].endDate = growthCurve?.curveModelAnalysisEndDate;
			newSummaryTableSelections[index].analysisId = growthCurve?.analysisId;
			newSummaryTableSelections[index].assessmentType = value;
			newSummaryTableSelections[index].curveModelAnalysisId = growthCurve?.curveModelAnalysisId;

			newSummaryTableSelections[index].name = _.find(assessmentOptions, function (x) {
				return x.value === value;
			}).text;
			newSummaryTableSelections[index].displayName = displayName.split(" (")[0];
			newSummaryTableSelections[index].initialLoad = false;
			newSummaryTableSelections[index].analysisTypeId = growthCurve.analysisTypeId;
			setSummaryTableSelections(newSummaryTableSelections);
		}
	}

	//-- Add the selected growth flight option to the summary table selections and get the data
	function flightOptionSelected(value, index, quantifiedRegionTypeId) {
		let newSummaryTableSelections = _.cloneDeep(summaryTableSelections);
		const qrId = quantifiedRegionTypeId ?? quantifiedRegionTypeOptions[0].key;

		//this isnt a growth curve selection so lets clear that stuff out
		newSummaryTableSelections[index].curveModelAnalysisId = null;
		newSummaryTableSelections[index].isCurveModel = false;

		newSummaryTableSelections[index].id = value;
		newSummaryTableSelections[index].analysisValue = value;
		newSummaryTableSelections[index].isAnalysis = true;
		newSummaryTableSelections[index].quantifiedRegionTypeOptionId = qrId;

		let name = _.find(assessmentOptions, function (x) {
			return x.value === value;
		}).text;
		newSummaryTableSelections[index].name = name;
		newSummaryTableSelections[index].displayName = name;
		let assessmentsForTrial = _.cloneDeep(assessmentData);
		assessmentsForTrial = _.map(assessmentsForTrial, (aft) => {
			aft.enabled = _.some(rawTrialOptions.trialAnalysisOptions, (tao) => {
				return tao.flightId === aft.id && _.some(tao.analysisTypes, { id: value, quantifiedRegionTypeId: qrId });
			});
			aft.checked = _.some(rawTrialOptions.trialAnalysisOptions, (tao) => {
				return (
					tao.flightId === aft.id &&
					_.some(tao.analysisTypes, { id: value, checked: true, quantifiedRegionTypeId: qrId })
				);
			});
			aft.type = "analysis";
			aft.groundDataAssessmentId = "";
			aft.assessmentType = value;
			aft.name = name;
			aft.displayName = name;
			aft.quantifiedRegionTypeId = qrId;

			return aft;
		});

		newSummaryTableSelections[index].assessments = assessmentsForTrial;
		setSummaryTableSelections(newSummaryTableSelections);
		getSingleAssessmentTrialData(value, null, null, qrId, null);
	}

	//-- Add the selected ground data option to the summary table selections and get the data
	function groundDataOptionSelected(value, index) {
		if (summaryTableSelections[index]?.analysisValue !== value) {
			let newSummaryTableSelections = _.cloneDeep(summaryTableSelections);

			//Only set the update analytics button if a selection is changed after initial load
			if (!fetchingData) {
				setSelectedPresetOption("none");
				setNeedToUpdateAnalytics(true);
			}

			//this isnt a growth curve selection so lets clear that stuff out
			newSummaryTableSelections[index].curveModelAnalysisId = null;
			newSummaryTableSelections[index].isCurveModel = false;
			newSummaryTableSelections[index].isAnalysis = false;

			newSummaryTableSelections[index].id = value;
			newSummaryTableSelections[index].analysisValue = value;

			let name = _.find(assessmentOptions, function (x) {
				return x.value === value;
			}).text;
			newSummaryTableSelections[index].name = name;
			newSummaryTableSelections[index].displayName = name;
			let assessmentsForTrial = _.cloneDeep(assessmentData);
			assessmentsForTrial = _.map(assessmentsForTrial, (aft) => {
				aft.enabled = _.some(rawTrialOptions.groundDataOptions, (gdo) => {
					return gdo.groundDatasetId === aft.id && _.some(gdo.groundDataAssessments, { id: value });
				});
				aft.checked = _.some(rawTrialOptions.groundDataOptions, (gdo) => {
					return gdo.groundDatasetId === aft.id && _.some(gdo.groundDataAssessments, { id: value, checked: true });
				});
				aft.type = "grounddata";
				aft.groundDataAssessmentId = value;
				aft.assessmentType = value;
				aft.name = name;
				aft.displayName = name;

				//This is just to make sure the check box is checked by default
				if (aft.enabled && !aft.checked) {
					aft.checked = true;
				}

				return aft;
			});

			newSummaryTableSelections[index].assessments = assessmentsForTrial;
			setSummaryTableSelections(newSummaryTableSelections);
		}
	}

	//-- Adds an empty record to the summary table selections array on click
	function addData() {
		const newSummaryTableSelections = summaryTableSelections.concat({
			id: null,
			name: null,
			assessments: [],
			isGrowthCurveAnalysis: false
		});
		setSelectedPresetOption("none"); //verify
		setSummaryTableSelections(newSummaryTableSelections);
	}

	//-- Remove the selected option from the summary table selections array on click
	function removeData(index) {
		setSummaryTableSelections(
			_.filter(summaryTableSelections, (_, i) => {
				return i != index;
			})
		);
	}

	//-- Save the selected assessment options on click
	async function saveAssessmentSelection() {
		const accessToken = await getTokenSilently();
		setSavingAssessmentSelection(true);
		dispatch(
			trialActions.saveAssessmentSelectionForSummaryTable(
				summaryTableSelections,
				accessToken,
				userAuth.currentClientId,
				moduleNavigation.trialId
			)
		)
			.then(() => {
				setSavingAssessmentSelection(false);
				toast.success("Assessment selections have been saved");
			})
			.catch((err) => {
				setSavingAssessmentSelection(false);
				console.log(err);
				toast.error("Failed to save assessment selections.");
			});
	}

	//-- Updates the summary table selection assessments to be checked or unchecked on click
	function updateAssessmentChecked(index, subIndex) {
		const updatedSelection = produce(summaryTableSelections, (draft) => {
			const selectedSummary = draft[index];
			const assessments = selectedSummary.assessments;
			assessments[subIndex] = {
				...assessments[subIndex],
				["checked"]: !assessments[subIndex].checked
			};

			draft[index].assessments = assessments;
		});

		setSummaryTableSelections(updatedSelection);
	}

	function updateSelectedAnalysis(value, index, displayName) {
		if (_.some(rawTrialOptions.growthCurveOptions, { curveModelName: value })) {
			growthCurveOptionSelected(value, index, displayName);
		} else if (
			//-- check if selected value is ground data
			_.some(rawTrialOptions.groundDataOptions, (gdo) => {
				return _.some(gdo.groundDataAssessments, { id: value });
			})
		) {
			groundDataOptionSelected(value, index);
		} else if (
			_.some(rawTrialOptions.trialAnalysisOptions, (tao) => {
				return _.some(tao.analysisTypes, { id: value });
			})
		) {
			flightOptionSelected(value, index, displayName);
		}
	}

	return (
		<Segment className="ribbonBanner" style={{ margin: "0 -1em" }}>
			<Accordion fluid active={1}>
				<Accordion.Title active={accordionActiveIndex} style={{ cursor: "unset" }}>
					<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
						<div
							onClick={accordionClick}
							style={{ display: "flex", fontSize: "1.28571429rem", fontWeight: "700", cursor: "pointer" }}
						>
							<Icon name="dropdown" />
							Summary Table Selections
						</div>
						<div style={{ display: "grid" }}>
							<label htmlFor="dropdown-select-mean-comparison" style={{ display: "unset" }}>
								Mean Comparisons
							</label>
							<Dropdown
								id="dropdown-select-mean-comparison"
								selection
								search
								fluid
								disabled={fetchingData || savingAssessmentSelection}
								options={meanComparisonOptions}
								value={selectedMeanComparisonOption}
								onChange={(event, { value }) => {
									setSelectedMeanComparisonOption(value);
									setNeedToUpdateAnalytics(true);
								}}
							/>
						</div>
						<div style={{ display: "grid" }}>
							<label htmlFor="dropdown-select-alpha" style={{ display: "unset" }}>
								Alpha
							</label>
							<Dropdown
								id="dropdown-select-alpha"
								selection
								search
								fluid
								disabled={fetchingData || savingAssessmentSelection}
								options={alphaOptions}
								value={selectedAlphaOption}
								onChange={(event, { value }) => {
									setSelectedAlphaOption(value);
									setNeedToUpdateAnalytics(true);
								}}
							/>
						</div>
						<div style={{ display: "grid" }}>
							<label htmlFor="dropdown-select-preset" style={{ display: "unset" }}>
								Preset
							</label>
							<Dropdown
								id="dropdown-select-preset"
								selection
								search
								disabled={fetchingData || savingAssessmentSelection}
								options={summaryTablePresetOptions}
								value={selectedPresetOption}
								onChange={(event, { value }) => {
									setSelectedPresetOption(value);
									setNeedToUpdateAnalytics(true);
								}}
							/>
						</div>
						<div style={{ display: "grid" }}>
							<label htmlFor="form-assessment">Remove Exclusions</label>
							<div style={{ marginTop: 10 }}>
								<DataAnalysisCriteria
									removeDamagedOrExcluded={removeDamagedOrExcluded}
									removeOutliers={removeOutliers}
									outlierType={outlierType}
									setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
									setRemoveOutliers={setRemoveOutliers}
									setOutlierType={setOutlierType}
									setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
								/>
								<Popup
									trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
									content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
								/>
							</div>
						</div>
						{/*<div style={{flexGrow:"1"}}></div>*/}
						<div style={{ display: "grid", alignSelf: "center" }}>
							<Button //style={{marginTop:"20px"}}
								primary={!needToUpdateAnalytics}
								negative={needToUpdateAnalytics}
								content="Update Analytics"
								onClick={() => {
									updateAnalytics();
									setAccordionActiveIndex(false);
								}}
								disabled={fetchingData}
								loading={fetchingData}
							/>
						</div>
					</div>
				</Accordion.Title>
				<Accordion.Content active={accordionActiveIndex}>
					<div style={{ overflowY: "scroll", overflowX: "hidden", height: "50vh" }}>
						<Table selectable>
							<Table.Header>
								<Table.Row>
									{/* <Table.HeaderCell width={4} style={{ display: "flex" }}>
									<p style={{ marginLeft: "60px", width: "50px" }}>Data Type</p>
									<p style={{ marginLeft: "60px" }}>Assessment</p>
								</Table.HeaderCell> */}
									<Table.HeaderCell width={5} />
									<Table.HeaderCell />
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(summaryTableSelections, (selection, index) => {
									return (
										<Table.Row key={index}>
											<Table.Cell>
												<div style={{ display: "flex", flexDirection: "row" }}>
													<Button
														icon="minus"
														disabled={fetchingData || savingAssessmentSelection}
														onClick={() => {
															removeData(index);
															setSelectedPresetOption("none");
														}}
													/>
													{_.some(rawTrialOptions.trialAnalysisOptions, (tao) => {
														return _.some(tao.analysisTypes, { id: selection.id });
													}) && (
														<Dropdown
															selection
															fluid
															search
															disabled={fetchingData || savingAssessmentSelection}
															options={quantifiedRegionTypeOptions}
															value={selection.quantifiedRegionTypeOptionId}
															onChange={(event, { value }) => {
																const newState = [...summaryTableSelections];
																newState[index] = {
																	...newState[index],
																	quantifiedRegionTypeOptionId: value
																};
																setSummaryTableSelections(newState);
																flightOptionSelected(newState[index].analysisValue, index, value);
															}}
														/>
													)}
													{rawPresetOptions && allAssessmentTypes && userAuth.isReady ? (
														<AnalysisDropDownLists
															rawPresetOptions={rawPresetOptions}
															quantifiedRegionTypes={[]}
															displayAssessmentTypes={_.filter(allAssessmentTypes, function (at) {
																let analysisExists = summaryTableSelections.find((x) => x.id === at.value);
																return at.value === selection.id || !analysisExists;
															})}
															allAssessmentTypes={allAssessmentTypes}
															updateSelectedAnalysis={updateSelectedAnalysis}
															index={index}
															defaultAssessment={selection.id}
															initialLoad={selection.initialLoad}
															useSummaryTableStyles={true}
															isApAdmin={userAuth.isApAdmin}
															setSummaryTablePreset={setSelectedPresetOption}
															summaryTableSelections={summaryTableSelections}
															shouldUpdateAnalysisTypeId={false}
														/>
													) : (
														<></>
													)}
												</div>
											</Table.Cell>
											<Table.Cell>
												<div
													style={{
														display: "flex",
														width: "100%",
														height: "100%",
														justifyContent: "center",
														gap: "20px"
													}}
												>
													{_.map(selection.assessments, (a, subIndex) => {
														return (
															a.enabled && (
																<div>
																	<p style={{ display: "inline-block", margin: "0" }}>
																		{moment(a.date).local().format(DATE_FORMAT)}
																	</p>
																	<Checkbox
																		checked={a.checked}
																		disabled={fetchingData || savingAssessmentSelection}
																		onClick={() => {
																			updateAssessmentChecked(index, subIndex);
																			setSelectedPresetOption("none");
																			setNeedToUpdateAnalytics(true);
																		}}
																		style={{ display: "inline-block", marginLeft: "5px", marginBottom: "-2px" }}
																	/>
																</div>
															)
														);
													})}
												</div>
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									<Table.HeaderCell colSpan={assessmentData.length + 1}>
										{summaryTableSelections.length >=
										_.filter(assessmentOptions, (ao) => {
											return !ao.key.includes("Header");
										}).length ? null : (
											<Button
												disabled={fetchingData || savingAssessmentSelection}
												onClick={() => {
													//onClick={addData}>
													addData();
													setNeedToUpdateAnalytics(true);
												}}
											>
												Add Data
											</Button>
										)}
										<Button
											loading={savingAssessmentSelection}
											disabled={fetchingData || savingAssessmentSelection}
											onClick={saveAssessmentSelection}
										>
											Save Assessment Selection
										</Button>
										<Checkbox
											style={{ marginLeft: "5px" }}
											label="Check All"
											disabled={fetchingData || savingAssessmentSelection}
											defaultChecked={checkAll}
											onClick={() => {
												//-- If user manually clicks checkbox, turn off presets
												setSelectedPresetOption("none");
												setCheckAll(!checkAll);
												toggleCheckAll();
												setNeedToUpdateAnalytics(true);
											}}
										/>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Footer>
						</Table>
					</div>
				</Accordion.Content>
			</Accordion>
		</Segment>
	);
};

SummaryTableSelections.propTypes = {
	selectedAlphaOption: PropTypes.string.isRequired,
	setSelectedAlphaOption: PropTypes.func.isRequired,
	selectedMeanComparisonOption: PropTypes.string.isRequired,
	setSelectedMeanComparisonOption: PropTypes.func.isRequired,
	summaryTableSelections: PropTypes.array.isRequired,
	setSummaryTableSelections: PropTypes.func.isRequired,
	getSingleAssessmentTrialData: PropTypes.func.isRequired,
	fetchingData: PropTypes.bool.isRequired,
	rawTrialOptions: PropTypes.object.isRequired,
	assessmentData: PropTypes.array.isRequired,
	meanComparisonOptions: PropTypes.array.isRequired,
	alphaOptions: PropTypes.array.isRequired,
	assessmentOptions: PropTypes.array.isRequired,
	summaryTablePresetOptions: PropTypes.array.isRequired,
	selectedPresetOption: PropTypes.string,
	setSelectedPresetOption: PropTypes.func.isRequired,
	quantifiedRegionTypeOptions: PropTypes.array,
	rawPresetOptions: PropTypes.object,
	updateAnalytics: PropTypes.func,
	needToUpdateAnalytics: PropTypes.bool,
	setNeedToUpdateAnalytics: PropTypes.func,
	removeDamagedOrExcluded: PropTypes.bool,
	setRemoveDamagedOrExcluded: PropTypes.func,
	outlierType: PropTypes.number,
	setOutlierType: PropTypes.func,
	removeOutliers: PropTypes.bool,
	setRemoveOutliers: PropTypes.func
};

export default SummaryTableSelections;
