const statisticsApiUri = process.env.aerialPlotConnections.aerialStatisticsApi.baseUrl;

export function generateFieldHeatmapExcel(
	clientId,
	clientName,
	fieldId,
	currentDateTimeString,
	removeExcluded,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateFieldHeatmapExcel?clientId=${clientId}&clientName=${clientName}
			&fieldId=${fieldId}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateTrialHeatmapExcel(
	clientId,
	clientName,
	trialId,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateTrialHeatmapExcel?clientId=${clientId}&clientName=${clientName}
			&trialId=${trialId}&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateProtocolHeatmapExcel(
	trials,
	clientId,
	clientName,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateProtocolHeatmapExcel?clientId=${clientId}&clientName=${clientName}
			&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trials)
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function generateStackedTrialHeatmapExcel(
	clientId,
	clientName,
	trialId,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	quantifiedRegion,
	includeSubplots,
	isForCSV,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateStackedTrialHeatmapExcel?clientId=${clientId}&clientName=${clientName}
		&trialId=${trialId}&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}
		&quantifiedRegion=${quantifiedRegion}&includeSubplots=${includeSubplots}&isforCSV=${isForCSV}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateTabbedTrialHeatmapExcel(
	clientId,
	clientName,
	trialId,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	quantifiedRegion,
	includeSubplots,
	isForCSV,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateTabbedTrialHeatmapExcel?clientId=${clientId}&clientName=${clientName}
		&trialId=${trialId}&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}
		&quantifiedRegion=${quantifiedRegion}&includeSubplots=${includeSubplots}&isforCSV=${isForCSV}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateStackedProtocolHeatmapExcel(
	trials,
	clientId,
	clientName,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	quantifiedRegion,
	includeSubplots,
	isForCSV,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateStackedProtocolHeatmapExcel?clientId=${clientId}&clientName=${clientName}
			&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}
			&quantifiedRegion=${quantifiedRegion}&includeSubplots=${includeSubplots}&isforCSV=${isForCSV}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trials)
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function generateTabbedProtocolHeatmapExcel(
	trials,
	clientId,
	clientName,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	quantifiedRegion,
	includeSubplots,
	isForCSV,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateTabbedProtocolHeatmapExcel?clientId=${clientId}&clientName=${clientName}
			&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}
			&quantifiedRegion=${quantifiedRegion}&includeSubplots=${includeSubplots}&isforCSV=${isForCSV}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trials)
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function generateStackedFieldHeatmapExcel(
	clientId,
	clientName,
	fieldId,
	currentDateTimeString,
	removeExcluded,
	quantifiedRegion,
	includeSubplots,
	isForCSV,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateStackedFieldHeatmapExcel?clientId=${clientId}&clientName=${clientName}
		&fieldId=${fieldId}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}
		&quantifiedRegion=${quantifiedRegion}&includeSubplots=${includeSubplots}&isforCSV=${isForCSV}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateTabbedFieldHeatmapExcel(
	clientId,
	clientName,
	fieldId,
	currentDateTimeString,
	removeExcluded,
	quantifiedRegion,
	includeSubplots,
	isForCSV,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateTabbedFieldHeatmapExcel?clientId=${clientId}&clientName=${clientName}
		&fieldId=${fieldId}&currentDateTimeString=${currentDateTimeString}&removeExcluded=${removeExcluded}
		&quantifiedRegion=${quantifiedRegion}&includeSubplots=${includeSubplots}&isforCSV=${isForCSV}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}
