// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rotate180 {\n\ttransform: rotate(-180deg) !important;\n}\n\n.rotate90 {\n\ttransform: rotate(-90deg) !important;\n}\n\n.rotate45 {\n\ttransform: rotate(-45deg) !important;\n}\n\n.rotate225 {\n\ttransform: rotate(-225deg) !important;\n}\n\n.flip {\n\ttransform: scaleX(-1);\n}\n\n.ui.button.toggle.active {\n\tbackground-color: #49769c !important;\n}\n\n.ui.button.toggle.active:hover {\n\tbackground-color: #3d698e !important;\n\tcolor: #fff;\n\ttext-shadow: none;\n}\n\n.ui.icon.toggle.button,\n.ui.icon.button {\n\tmargin-right: unset;\n}\n\n.uniform-button-column > .ui.icon.button {\n\twidth: 58px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/OrthoViewer/controlStyle.css"],"names":[],"mappings":"AAAA;CACC,qCAAqC;AACtC;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,qCAAqC;AACtC;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,oCAAoC;CACpC,WAAW;CACX,iBAAiB;AAClB;;AAEA;;CAEC,mBAAmB;AACpB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".rotate180 {\n\ttransform: rotate(-180deg) !important;\n}\n\n.rotate90 {\n\ttransform: rotate(-90deg) !important;\n}\n\n.rotate45 {\n\ttransform: rotate(-45deg) !important;\n}\n\n.rotate225 {\n\ttransform: rotate(-225deg) !important;\n}\n\n.flip {\n\ttransform: scaleX(-1);\n}\n\n.ui.button.toggle.active {\n\tbackground-color: #49769c !important;\n}\n\n.ui.button.toggle.active:hover {\n\tbackground-color: #3d698e !important;\n\tcolor: #fff;\n\ttext-shadow: none;\n}\n\n.ui.icon.toggle.button,\n.ui.icon.button {\n\tmargin-right: unset;\n}\n\n.uniform-button-column > .ui.icon.button {\n\twidth: 58px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotate180": "rotate180",
	"rotate90": "rotate90",
	"rotate45": "rotate45",
	"rotate225": "rotate225",
	"flip": "flip",
	"ui": "ui",
	"button": "button",
	"toggle": "toggle",
	"active": "active",
	"icon": "icon",
	"uniform-button-column": "uniform-button-column"
};
export default ___CSS_LOADER_EXPORT___;
