import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as protocolActions from "../../../../redux/actions/protocolActions";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import SearchPanel from "../../../Lumber/Search/SearchPanel.tsx";
import ProtocolLogResults from "./ProtocolLogResults";

const ProtocolLog = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const allSelection = { key: "all", value: "all", text: "All" };

	//-- DataSource
	const [originalProtocols, setOriginalProtocols] = useState([]);
	const [protocols, setProtocols] = useState([]);

	//-- Dropdown Options
	const [protocolOwnerOptions, setProtocolOwnerOptions] = useState([allSelection]);
	const [cropOptions, setCropOptions] = useState([allSelection]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([allSelection]);
	const [geographyOptions, setGeographyOptions] = useState([allSelection]);
	const [plotSizeOptions, setPlotSizeOptions] = useState([allSelection]);
	const [regulatoryOptions, setRegulatoryOptions] = useState([]);
	const [originalRegulatoryOptions, setOriginalRegulatoryOptions] = useState([]);
	const [variablesTestedOptions, setVariablesTestedOptions] = useState([]);
	const [originalVariablesTestedOptions, setOriginalVariablesTestedOptions] = useState([]);
	const [searchAllClients, setSearchAllClients] = useState(false);
	const [hasSearchedAllClients, setHasSearchedAllClients] = useState(false);
	const [protocolNameOptions, setProtocolNameOptions] = useState([allSelection]);
	const [trialNameOptions, setTrialNameOptions] = useState([allSelection]);
	const [isInitialGSSet, setIsInitialGSSet] = useState(false);

	//-- Data
	const [protocolInfo, setProtocolInfo] = useState(setInitialProtocolInfo());
	const [protocolOptions, setProtocolOptions] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	const yieldOpts = useMemo(
		() => [
			{ name: "Yes", key: "yes", value: "yes" },
			{ name: "No", key: "no", value: "no" }
		],
		[]
	);

	const regulatoryOpts = useMemo(() => {
		const opts = regulatoryOptions.map((ro) => ({
			name: ro.text,
			key: ro.key,
			value: ro.value
		}));

		opts.push({ name: "No Regulatories", key: "regs-null", value: null });

		return opts;
	}, [regulatoryOptions]);

	const variablesTestedOpts = useMemo(() => {
		const opts = variablesTestedOptions.map((vto) => ({
			name: vto.text,
			key: vto.key,
			value: vto.value
		}));

		opts.push({ name: "No Variables", key: "vars-null", value: null });

		return opts;
	}, [variablesTestedOptions]);

	useEffect(() => {
		if (userAuth.isReady && userAuth.currentClientId) {
			getDropdownOptions();
		}
	}, [userAuth.isReady, userAuth.currentClientId]);

	useEffect(() => {
		if (_.some(protocolOptions)) {
			getProtocols();
		}
	}, [protocolOptions]);

	useEffect(() => {
		if (originalProtocols && isInitialGSSet) {
			filterProtocols();
		}
	}, [originalProtocols, isInitialGSSet]);

	function setInitialProtocolInfo(growingSeason = null) {
		return {
			id: "all",
			protocolId: "all", //-- user defined id
			protocolName: "all",
			protocolOwnerId: "all",
			cropId: "all",
			growingSeasonId: growingSeason ?? "all",
			geographyId: "all",
			treatments: "all",
			replicates: "all",
			plotSizeId: "all",
			designId: "all",
			meanComparisonId: "all",
			alphaId: "all",
			yieldRequired: true,
			yieldNotRequired: true,
			regulatories: _.cloneDeep(originalRegulatoryOptions),
			variablesTested: _.cloneDeep(originalVariablesTestedOptions),
			rotation: "all",
			genetics: "all",
			herbicides: "all",
			fertilizer: "all",
			pesticides: "all",
			other: "all",
			objectives: "all",
			keyword: "",
			trialId: "all"
		};
	}

	function setInitialProtocolOwnerOptions(options) {
		let filteredOpt = _.uniqBy(options, "protocolOwnerId");
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.protocolOwnerId, value: o.protocolOwnerId, text: o.owner };
		});

		let orderedOptionList = _.orderBy(optionList, [(optionList) => optionList.text.toLowerCase().trim()]);
		orderedOptionList.unshift(allSelection);
		setProtocolOwnerOptions(orderedOptionList);

		if (
			protocolInfo.protocolOwnerId &&
			!_.some(optionList, (o) => {
				return o.personId === protocolInfo.protocolOwnerId;
			})
		) {
			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolOwnerId: "all" }));
		}
	}

	function setInitialCropOptions(options) {
		let filteredOpt = _.uniqBy(options, "cropId");
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.cropId, value: o.cropId, text: o.cropName };
		});

		let orderedOptionList = _.orderBy(optionList, ["text"]);
		orderedOptionList.unshift(allSelection);
		setCropOptions(orderedOptionList);
	}

	function setInitialGrowingSeasonOptions(options) {
		let filteredOpt = _.uniqBy(options, "growingSeasonId");
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.growingSeasonId, value: o.growingSeasonId, text: o.growingSeasonName };
		});

		let orderedOptionList = _.orderBy(optionList, ["text"], ["desc"]);
		orderedOptionList.unshift(allSelection);
		setGrowingSeasonOptions(orderedOptionList);

		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(optionList, (gs) => gs.text.includes(currentGrowingSeason))?.value ?? optionList[0]?.value;
		setProtocolInfo((protocolInfo) => ({ ...protocolInfo, growingSeasonId: currentGrowingSeasonSelection }));
		if (currentGrowingSeasonSelection) {
			setIsInitialGSSet(true);
		}
	}

	function setInitialGeographyOptions(options) {
		let filteredOpt = _.uniqBy(options, "geographyId");
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.geographyId, value: o.geographyId, text: o.geographyName };
		});

		let orderedOptionList = _.orderBy(optionList, ["text"]);
		orderedOptionList.unshift(allSelection);
		setGeographyOptions(orderedOptionList);
	}

	function setInitialPlotSizeOptions(options) {
		let filteredOpt = _.uniqBy(options, "plotSizeId");
		let optionList = _.map(filteredOpt, (o) => {
			let plotSize = _.find(protocolOptions?.plotSizes, (po) => po.id === o.plotSizeId);
			if (plotSize) {
				return { key: o.plotSizeId, value: o.plotSizeId, text: `${plotSize.name} (${plotSize.description})` };
			}
		});

		let orderedOptionList = _.orderBy(optionList, ["text"]);
		orderedOptionList.unshift(allSelection);
		setPlotSizeOptions(orderedOptionList);
	}

	function setInitialRegulatoryOptions(options) {
		let filteredOpt = _.uniqBy(
			_.map(
				_.filter(
					_.map(options, (o) => {
						return _.filter(o, (fo) => fo?.checked);
					}),
					(t) => {
						return t.length > 0;
					}
				),
				(v) => {
					return v[0];
				}
			),
			(u) => u.id
		);
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.id, value: o.id, text: o.name, checked: false };
		});
		setProtocolInfo((protocolInfo) => ({ ...protocolInfo, regulatories: optionList }));
		let orderedOptionList = _.orderBy(optionList, ["text"]);
		setRegulatoryOptions(orderedOptionList);
		setOriginalRegulatoryOptions(optionList);
	}

	function setInitialVariablesTested(options) {
		let filteredOpt = _.uniqBy(
			_.map(
				_.filter(
					_.map(options, (o) => {
						return _.filter(o, (fo) => fo?.checked);
					}),
					(t) => {
						return t.length > 0;
					}
				),
				(v) => {
					return v[0];
				}
			),
			(u) => u.id
		);
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.id, value: o.id, text: o.name, checked: false };
		});

		setProtocolInfo((protocolInfo) => ({ ...protocolInfo, variablesTested: optionList }));
		let orderedOptionList = _.orderBy(optionList, ["text"]);
		setVariablesTestedOptions(orderedOptionList);
		setOriginalVariablesTestedOptions(optionList);
	}

	function setInitialProtocolNameOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.protocolName };
		});

		let orderedOptionList = _.orderBy(optionList, [(optionList) => optionList.text.toLowerCase().trim()]);
		orderedOptionList.unshift(allSelection);
		setProtocolNameOptions(orderedOptionList);
	}

	function setInitialTrialNameOptions(options) {
		let filteredOpt = _.uniqBy(
			_.flatten(
				_.filter(_.map(options), (pt) => {
					return pt.length > 0;
				})
			),
			(u) => u.trialId
		);
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.trialId, value: o.trialId, text: o.trialName };
		});

		let orderedOptionList = _.orderBy(optionList, [(optionList) => optionList.text.toLowerCase().trim()]);
		orderedOptionList.unshift(allSelection);
		setTrialNameOptions(orderedOptionList);
	}

	function filterProtocols(customProtocolInfo = null) {
		if (searchAllClients === true && hasSearchedAllClients === false) {
			getProtocols();
			getDropdownOptions();
			setHasSearchedAllClients(true);
		} else {
			var protocolsClone = _.cloneDeep(originalProtocols);
			let protocolInfoToFilter = customProtocolInfo ?? protocolInfo;

			// const checkedRegulatories = _.map(
			// 	_.filter(protocolInfoToFilter.regulatories, (reg) => {
			// 		return reg.checked;
			// 	}),
			// 	"key"
			// ); //return only the ids of checked regulatories
			// const checkedVariablesTested = _.map(
			// 	_.filter(protocolInfoToFilter.variablesTested, (vt) => {
			// 		return vt.checked;
			// 	}),
			// 	"key"
			// ); //return only the ids of checked regulatories

			let protocolsTemp = _.filter(protocolsClone, (protocol) => {
				return (
					(searchAllClients === true ||
						(searchAllClients === false && protocol.clientId === userAuth.currentClientId)) &&
					(protocolInfoToFilter?.cropId === "all" ? true : protocolInfoToFilter.cropId === protocol.cropId) &&
					((protocolInfoToFilter.yieldRequired === true && protocolInfoToFilter.yieldNotRequired === true) ||
						(protocolInfoToFilter.yieldNotRequired === true && protocol.yield === false) ||
						(protocolInfoToFilter.yieldRequired === true && protocol.yield === true)) &&
					(protocolInfoToFilter?.protocolOwnerId === "all"
						? true
						: protocolInfoToFilter.protocolOwnerId === protocol.protocolOwnerId) &&
					(protocolInfoToFilter?.growingSeasonId === "all"
						? true
						: protocolInfoToFilter.growingSeasonId === protocol.growingSeasonId) &&
					(protocolInfoToFilter?.geographyId === "all"
						? true
						: protocolInfoToFilter.geographyId === protocol.geographyId) &&
					(protocolInfoToFilter?.plotSizeId === "all"
						? true
						: protocolInfoToFilter.plotSizeId === protocol.plotSizeId) &&
					(protocolInfoToFilter?.id === "all" ? true : protocolInfoToFilter.id === protocol.id) &&
					(protocolInfoToFilter?.regulatoriesApplied
						? _.some(protocol.regulatories, (reg1) => {
								return reg1.checked === true && protocolInfoToFilter.regulatories.includes(reg1.id);
						  }) ||
						  (_.every(protocol.regulatories, (reg1) => !reg1.checked) &&
								protocolInfoToFilter.regulatories.includes(null))
						: true) &&
					(protocolInfoToFilter?.variablesTestedApplied
						? _.some(protocol.protocolVariables, (var1) => {
								return var1.checked === true && protocolInfoToFilter.variablesTested.includes(var1.id);
						  }) ||
						  (_.every(protocol.protocolVariables, (var1) => !var1.checked) &&
								protocolInfoToFilter.variablesTested.includes(null))
						: true) &&
					(protocolInfoToFilter?.trialId === "all"
						? true
						: _.map(protocol.protocolTrials, "trialId").includes(protocolInfoToFilter.trialId)) &&
					(protocolInfoToFilter.keyword == ""
						? true
						: //-- Keyword filtering
						  protocol.protocolId?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.protocolName?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.owner?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.cropName?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.treatments?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.replicates?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.rotation?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.genetics?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.herbicides?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.fertilizer?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.pesticides?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.other?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  protocol.objectives?.toString().toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase()) ||
						  _.some(
								protocol.protocolVariables,
								(pv) => pv.checked && pv.name?.toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase())
						  ) ||
						  _.some(protocol.protocolTrials, (pt) =>
								pt.trialName?.toLowerCase().includes(protocolInfoToFilter.keyword.toLowerCase())
						  ))
				);
			});
			setProtocols(protocolsTemp);
		}
	}

	async function getDropdownOptions() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocolInfoOptions(userAuth.currentClientId, searchAllClients, accessToken))
			.then((res) => {
				setProtocolOptions(res.data);
				setLoading(false);
			})
			.catch((err) => {
				toast.error(err);
				setLoading(false);
			});
	}

	async function getProtocols() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocols(userAuth.currentClientId, searchAllClients, accessToken)).then((res) => {
			res.data = _.filter(res.data, (d) => {
				if (userAuth.hasApplicationArea(ApplicationArea.ProtocolSearch, d.clientId)) {
					return d;
				}
			});
			//Set options
			setOriginalProtocols(res.data);
			setProtocols(res.data);
			setInitialProtocolNameOptions(res.data);
			setInitialCropOptions(res.data);
			setInitialGrowingSeasonOptions(res.data);
			setInitialGeographyOptions(res.data);
			setInitialProtocolOwnerOptions(res.data);
			setInitialPlotSizeOptions(res.data);
			setInitialRegulatoryOptions(
				_.map(res.data, (rd) => {
					return rd.regulatories;
				})
			);
			setInitialVariablesTested(
				_.map(res.data, (rd) => {
					return rd.protocolVariables;
				})
			);
			setInitialTrialNameOptions(
				_.map(res.data, (rd) => {
					return rd.protocolTrials;
				})
			);

			res.data = _.filter(res.data, (d) => {
				return d.clientId === userAuth.currentClientId;
			});

			setLoading(false);
		});
	}

	const handleReset = () => {
		let resetProtocolInfo = setInitialProtocolInfo("all");
		setProtocolInfo(resetProtocolInfo);

		filterProtocols(resetProtocolInfo);
	};

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	return (
		<>
			<div>
				<Segment basic id="protocol-log-segment" style={{ minHeight: 50, marginBottom: 30 }}>
					<Form>
						<Grid className="searchGrid">
							<Form.Select
								id="form-select-protocol-name"
								label="Protocol Name"
								search
								options={protocolNameOptions}
								value={protocolInfo?.id}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, id: value }));
								}}
							/>
							<Form.Select
								id="form-select-protocol-owner"
								label="Protocol Owner"
								search
								options={protocolOwnerOptions}
								value={protocolInfo?.protocolOwnerId}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolOwnerId: value }));
								}}
							/>
							<Form.Select
								id="form-select-crop"
								label="Crop"
								search
								options={cropOptions}
								value={protocolInfo?.cropId}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, cropId: value }));
								}}
							/>
							<Form.Select
								id="form-select-growing-season"
								label="Growing Season"
								search
								options={growingSeasonOptions}
								value={protocolInfo?.growingSeasonId ?? "all"}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, growingSeasonId: value }));
								}}
							/>
							<Form.Select
								id="form-select-geography"
								label="Geography"
								search
								options={geographyOptions}
								value={protocolInfo?.geographyId}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, geographyId: value }));
								}}
							/>
							<Form.Select
								id="form-select-plot-size"
								label="Plot Size"
								search
								options={plotSizeOptions}
								value={protocolInfo?.plotSizeId}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, plotSizeId: value }));
								}}
							/>
							<Form.Select
								id="form-select-trial-name"
								label="Trial Name"
								search
								options={trialNameOptions}
								value={protocolInfo?.trialId}
								loading={loading}
								onChange={(event, { value }) => {
									setProtocolInfo((protocolInfo) => ({ ...protocolInfo, trialId: value }));
								}}
							/>

							<SearchPanel.Dropdown
								label="Yield Required"
								options={yieldOpts}
								setFilters={(filters, applied) => {
									if (applied) {
										setProtocolInfo((prev) => ({
											...prev,
											yieldRequired: filters.includes("yes"),
											yieldNotRequired: filters.includes("no")
										}));
									} else {
										setProtocolInfo((prev) => ({
											...prev,
											yieldRequired: true,
											yieldNotRequired: true
										}));
									}
								}}
							/>
							<SearchPanel.Dropdown
								label="Regulatory"
								options={regulatoryOpts}
								setFilters={(filters, applied) => {
									if (applied) {
										setProtocolInfo((prev) => ({
											...prev,
											regulatories: filters,
											regulatoriesApplied: applied
										}));
									} else {
										setProtocolInfo((prev) => ({
											...prev,
											regulatoriesApplied: applied
										}));
									}
								}}
							/>
							<SearchPanel.Dropdown
								label="Variables Tested"
								options={variablesTestedOpts}
								setFilters={(filters, applied) => {
									if (applied) {
										setProtocolInfo((prev) => ({
											...prev,
											variablesTested: filters,
											variablesTestedApplied: applied
										}));
									} else {
										setProtocolInfo((prev) => ({
											...prev,
											variablesTestedApplied: applied
										}));
									}
								}}
							/>
							<Form.Input
								id="keyword-search"
								label="Keyword Search"
								value={protocolInfo?.keyword}
								onChange={(e, { value }) => {
									setProtocolInfo((protocolInfo) => ({
										...protocolInfo,
										keyword: value
									}));
								}}
							/>

							{/* <Form.Group>
								<p style={{ fontSize: "1.2em" }}>Yield Required?</p>
								<Form.Field>
									<Checkbox
										label="Yes"
										disabled={loading}
										checked={protocolInfo?.yieldRequired}
										onChange={(event, { checked }) => {
											setProtocolInfo((protocolInfo) => ({ ...protocolInfo, yieldRequired: checked }));
										}}
									/>
								</Form.Field>
								<Form.Field>
									<Checkbox
										label="No"
										disabled={loading}
										checked={protocolInfo?.yieldNotRequired}
										onChange={(event, { checked }) => {
											setProtocolInfo((protocolInfo) => ({ ...protocolInfo, yieldNotRequired: checked }));
										}}
										style={{ marginTop: "10px" }}
									/>
								</Form.Field>
							</Form.Group> */}

							{/* <Form.Group>
								<p style={{ fontSize: "1.2em" }}>Regulatory:</p>
								{_.map(regulatoryOptions, (option) => {
									return (
										<Grid.Row key={option.key}>
											<Checkbox
												label={option.text}
												style={{ marginTop: "10px" }}
												checked={_.find(protocolInfo?.regulatories, { key: option.value })?.checked}
												onChange={(event, { checked }) => {
													let matchingIndex = _.findIndex(protocolInfo?.regulatories, { key: option.value });
													let updatedRegulatory = _.cloneDeep(protocolInfo?.regulatories);
													if (matchingIndex > -1) {
														updatedRegulatory[matchingIndex].checked = checked;
														setProtocolInfo((protocolInfo) => ({
															...protocolInfo,
															regulatories: updatedRegulatory
														}));
													}
												}}
											/>
										</Grid.Row>
									);
								})}
							</Form.Group> */}

							{/* <Form.Group>
								<p style={{ fontSize: "1.2em" }}>Variables Tested:</p>
								<Grid>
									<Grid.Row>
										{_.map(variablesTestedOptions, (option) => {
											return (
												<Checkbox
													key={option.key}
													label={option.text}
													style={{ marginTop: "10px", marginLeft: "10px" }}
													checked={_.find(protocolInfo?.variablesTested, { key: option.value })?.checked}
													onChange={(event, { checked }) => {
														let matchingIndex = _.findIndex(protocolInfo?.variablesTested, { key: option.value });
														let updatedVariabledTested = _.cloneDeep(protocolInfo?.variablesTested);
														if (matchingIndex > -1) {
															updatedVariabledTested[matchingIndex].checked = checked;
															setProtocolInfo((protocolInfo) => ({
																...protocolInfo,
																variablesTested: updatedVariabledTested
															}));
														}
													}}
												/>
											);
										})}
									</Grid.Row>
									<Grid.Row>
										<Input
											id="keyword-search"
											label="Keyword Search:"
											value={protocolInfo?.keyword}
											onChange={(e, { value }) => {
												setProtocolInfo((protocolInfo) => ({
													...protocolInfo,
													keyword: value
												}));
											}}
										/>
									</Grid.Row>
								</Grid>
							</Form.Group> */}

							<Form.Checkbox
								label="Search All Clients"
								id="form-search-all-clients"
								checked={searchAllClients}
								onChange={(e, { checked }) => {
									setSearchAllClients(checked);
								}}
							/>
							<div className="buttons">
								<Button
									id="form-button-reset"
									negative
									content={"Reset"}
									onClick={() => {
										handleReset();
									}}
									disabled={loading}
									loading={loading}
								/>
								<Button
									id="form-button-filter"
									primary
									content={"Filter"}
									onClick={() => {
										filterProtocols();
									}}
									disabled={loading}
									loading={loading}
								/>
							</div>
						</Grid>
					</Form>
				</Segment>
				<Segment basic loading={loading}>
					<ProtocolLogResults
						protocolList={protocols}
						protocolOptions={protocolOptions}
						variablesTestedOptions={variablesTestedOptions}
						cropOptions={cropOptions}
						geographyOptions={geographyOptions}
						growingSeasonOptions={growingSeasonOptions}
					/>
				</Segment>
			</div>
		</>
	);
};

export default ProtocolLog;
