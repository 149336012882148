import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

interface InfoModalProps {
	context: "align-fields" | "new-fields" | "none";
	style: React.CSSProperties;
}

const InfoModal = ({ context = "none", style }: InfoModalProps) => {
	const [open, setOpen] = useState(false);

	const renderInfo = (context) => {
		switch (context) {
			case "align-fields":
				return (
					<div>
						<strong>Align Fields</strong>
						<ol>
							<li>Zoom to the area where the field resides.</li>
							<li>
								Align the plots in the field by selecting the plots and then using the arrow keys.
								<Tooltip
									placement="bottom"
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<div>
											Align the grid cells (i.e. plots) so the crop lines (toggled by using the toolbar to the left) lay
											on top of the rows of crops within the plot.
										</div>
									}
									transitionName="rc-tooltip-zoom"
								>
									<Icon name="info circle"></Icon>
								</Tooltip>
							</li>
							<li>
								<>
									Lock the field using the padlock button in the toolbar to the left. This will confirm your changes.
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={
											<div>
												The grid will appear orange until the field is locked. When the grid is aligned to your liking,
												locking the field will confirm the correct positioning of the grid.
												<br />
												Not locking the field will result in lost changes if you leave the page or Save and Continue.
											</div>
										}
										transitionName="rc-tooltip-zoom"
									>
										<Icon name="info circle"></Icon>
									</Tooltip>
								</>
							</li>
							<li>Add fields with existing location data by using the Fields dropdown list above the map.</li>
							<li>
								Remove fields from the flight by using the trash can button in the Fields dropdown list above the map.
							</li>
						</ol>
					</div>
				);
			//-- Add New Fields (i.e. Plot Segmentation)
			case "new-fields":
				return (
					<div>
						<strong>Add New Field</strong>
						<ol>
							<li>Zoom to the area where the field resides.</li>
							<li>In the toolbar to the left, click the Add Field button (+).</li>
							<li>
								<>
									Click the spot on the image where the Lower Left corner of the field resides.
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={"This identifies plot (1, 1) in the field."}
										transitionName="rc-tooltip-zoom"
									>
										<Icon name="info circle"></Icon>
									</Tooltip>
								</>
							</li>
							<li>Click the spot on the image where the Upper Left corner of the field resides.</li>
							<li>
								<>
									Add an existing field or create a new field at the marked location.
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={
											<div>A grid will be generated for you based on the field information you gave aerialPLOT.</div>
										}
										transitionName="rc-tooltip-zoom"
									>
										<Icon name="info circle"></Icon>
									</Tooltip>
								</>
							</li>
							<li>
								Align the plots in the field by selecting the plots and then using the arrow keys.
								<Tooltip
									placement="bottom"
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={
										<div>
											Align the grid cells (i.e. plots) so the crop lines (toggled by using the toolbar to the left) lay
											on top of the rows of crops within the plot.
											<br />
											Individual plots can be moved and rotated, or multiple plots can be manipulated at the same time.
										</div>
									}
									transitionName="rc-tooltip-zoom"
								>
									<Icon name="info circle"></Icon>
								</Tooltip>
							</li>
							<li>
								<>
									Lock the field using the padlock button in the toolbar to the left. This will confirm your changes.
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={
											<div>
												The grid will appear orange until the field is locked. When the grid is aligned to your liking,
												locking the field will confirm the correct positioning of the grid.
												<br />
												Not locking the field will result in lost changes if you leave the page or Save and Continue.
											</div>
										}
										transitionName="rc-tooltip-zoom"
									>
										<Icon name="info circle"></Icon>
									</Tooltip>
								</>
							</li>
							<li>
								Remove fields from the flight by using the trash can button in the Fields dropdown list above the map.
							</li>
						</ol>
					</div>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<Button style={style} icon={open ? "close" : "info"} circular onClick={() => setOpen((prev) => !prev)} />
			{open && (
				<div className="infoModal">
					{renderInfo(context)}
					<div>
						<strong>Map Controls</strong>
						<ul>
							<li>
								<i>Select Field</i>: Toggle the Lower Left corners using the toolbar to the left. Click the Lower Left
								corner of the field.
							</li>
							<li>
								<i>Select Area</i>: Hold shift and click-drag mouse
							</li>
							<li>
								<i>Nudge Plot</i>: Select plot and use arrow keys
							</li>
							<li>
								<i>Fine Controls</i>: Hold ctrl and nudge plots or use the rotate buttons
							</li>
							<li>
								<i>Coarse Controls</i>: Hold alt and nudge plots or use the rotate buttons
							</li>
						</ul>
						<strong>Auto Alignment Legend</strong>
						<ul className="autoAlignLegend">
							<li className="failed">Failed Alignment</li>
							<li className="poor">Poor Alignment</li>
							<li className="low">Low Confidence Alignment</li>
							<li className="medium">Medium Confidence Alignment</li>
						</ul>
					</div>
				</div>
			)}
		</>
	);
};

export default InfoModal;
