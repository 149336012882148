// @flow weak

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import "./sliderStyles.css";

// *******************************************************
// RAIL
// *******************************************************

export function SliderRail({ getRailProps }) {
	return (
		<Fragment>
			<div className="timelineRailOuter" {...getRailProps()} />
			<div className="timelineRailInner" />
		</Fragment>
	);
}

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired
};
import "rc-tooltip/assets/bootstrap.css";
// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({ domain: [min, max], handle: { id, value, percent, color }, disabled, getHandleProps }) {
	return (
		<Fragment>
			<div
				style={{
					left: `${percent}%`,
					position: "absolute",
					transform: "translate(-16px, -50%)",
					WebkitTapHighlightColor: "rgba(0,0,0,0)",
					zIndex: 12,
					width: 26,
					height: 42,
					cursor: "pointer",
					backgroundColor: "none"
				}}
				{...getHandleProps(id)}
			/>
			<div
				role="slider"
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				style={{
					left: `${percent}%`,
					position: "absolute",
					translate: "-11px",
					width: 9,
					height: 9,
					top: -6,
					boxSizing: "content-box",
					borderRadius: "50%",
					border: "var(--neutral-800) solid 3px",
					backgroundColor: disabled ? "var(--neutral-500)" : color,
					zIndex: 11
				}}
			/>
		</Fragment>
	);
}

Handle.propTypes = {
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
		color: PropTypes.string.isRequired
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Handle.defaultProps = {
	disabled: false
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps }) {
	return (
		<div
			className="timelineTrack"
			style={{
				backgroundColor: "transparent",
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`
			}}
			{...getTrackProps()}
		/>
	);
}

Track.propTypes = {
	source: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getTrackProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Track.defaultProps = {
	disabled: false
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, format, showLabel, onClick }) {
	return (
		<>
			<Tooltip
				placement="bottom"
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				trigger="hover"
				overlay={<div>{tick.tooltip}</div>}
				transitionName="rc-tooltip-zoom"
			>
				<div
					className="timelineTick"
					onClick={onClick}
					style={{
						backgroundColor: tick.color,
						left: `calc(${tick.percent}% - 8px)`
					}}
				/>
			</Tooltip>
			{showLabel && (
				<div
					onClick={onClick}
					className="timelineTickLabel"
					style={{
						marginTop: 12 * tick.marginTopMultiplier,
						left: tick.percent <= 95 ? `${tick.percent}%` : "unset",
						right: tick.percent > 95 ? "-6px" : "unset",
						transform: tick.percent < 95 && tick.percent > 5 ? "translate(-30%, 0)" : ""
					}}
				>
					{format(tick.value)}
				</div>
			)}
		</>
	);
}

Tick.propTypes = {
	tick: PropTypes.shape({
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
		marginTopMultiplier: PropTypes.number.isRequired,
		tooltip: PropTypes.object
	}).isRequired,
	count: PropTypes.number.isRequired,
	format: PropTypes.func.isRequired,
	color: PropTypes.string.isRequired,
	showLabel: PropTypes.bool,
	onClick: PropTypes.func
};

Tick.defaultProps = {
	format: (d) => d,
	showLabel: false
};
