import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Dropdown, Icon, List, Loader } from "semantic-ui-react";
import "./style.css";

import * as trialActions from "../../../../redux/actions/trialActions";

import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";

const TrialFavoritingWidget = ({ clientId, trialId, userSettings, displayMode = "title", refreshPage = false }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	const [isFavorited, setIsFavorited] = useState(false);
	const [hasRun, setHasRun] = useState(false);
	const [enabled, setEnabled] = useState(false);

	// Make sure the favorite state is set correctly on first load.
	useEffect(() => {
		setHasRun(true);
	}, []);

	useEffect(() => {
		if (hasRun && userSettings.trialFavoriteGuids) {
			let isTrialFavorited = userSettings.trialIsFavorited(trialId.toLowerCase());
			setIsFavorited(isTrialFavorited);
			setEnabled(true);
		}
	}, [hasRun, userSettings.trialFavoriteGuids]);

	function buttonClicked() {
		setEnabled(false);
		attemptToggleFavorite();
		setIsFavorited(!isFavorited);
	}

	async function attemptToggleFavorite() {
		const accessToken = await getTokenSilently();

		// If we need to favorite the current trial...
		if (isFavorited != true) {
			dispatch(trialActions.createTrialFavorite(clientId, accessToken, trialId))
				.then(() => {
					userSettings.getTrialFavoriteGuids(() => setEnabled(true));
					if (refreshPage) {
						window.location.reload(true);
					}
				})
				.catch((err) => {
					toast.error("Failed to favorite trial.");
					console.error(err);
					setIsFavorited(!isFavorited);
				});
		}
		// Otherwise, if we need to unfavorite the current trial...
		else {
			dispatch(trialActions.deleteTrialFavorite(clientId, accessToken, trialId))
				.then(() => {
					userSettings.getTrialFavoriteGuids(() => setEnabled(true));
					if (refreshPage) {
						window.location.reload(true);
					}
				})
				.catch((err) => {
					toast.error("Failed to unfavorite a trial.");
					console.error(err);
					setIsFavorited(!isFavorited);
				});
		}
	}

	return enabled === true ? (
		(() => {
			switch (displayMode) {
				case "title":
					return (
						<Dropdown.Item onClick={buttonClicked}>
							{isFavorited ? (
								<>
									<Icon name="star" size="small" style={{ textAlign: "center", color: "#f2711c" }} />
									<span style={{ color: "#000000" }}>Remove from Favorites</span>
								</>
							) : (
								<>
									<Icon name="star outline" size="small" style={{ textAlign: "center" }} />
									<span style={{ color: "#000000" }}>Add to Favorites</span>
								</>
							)}
						</Dropdown.Item>
					);
				case "table":
					return (
						<Tooltip
							placement="bottom"
							mouseEnterDelay={0}
							mouseLeaveDelay={0}
							trigger="hover"
							overlay={isFavorited ? <p>Unfavorite</p> : <p>Favorite</p>}
							transitionName="rc-tooltip-zoom"
						>
							<Button
								className="trial-favoriting-button"
								style={{ textAlign: "center", backgroundColor: "transparent" }}
								onClick={buttonClicked}
							>
								<Button.Content>
									{isFavorited ? (
										<Icon name="star" size="small" style={{ textAlign: "center", color: "rgb(65,101,134)" }}></Icon>
									) : (
										<Icon name="star outline" size="small" style={{ textAlign: "center" }}></Icon>
									)}
								</Button.Content>
							</Button>
						</Tooltip>
					);
				case "menu":
					return (
						<List.Item as={"a"} onClick={buttonClicked}>
							{isFavorited ? (
								<>
									<Icon name="star" style={{ textAlign: "center", color: "#f2711c" }} />
									<List.Content>Un-Favorite</List.Content>
								</>
							) : (
								<>
									<Icon name="star outline" style={{ textAlign: "center" }} />
									<List.Content>Favorite</List.Content>
								</>
							)}
						</List.Item>
					);
				default:
			}
		})()
	) : displayMode === "title" ? (
		<Dropdown.Item onClick={buttonClicked}>
			<Loader
				active
				inline
				size="small"
				style={{ marginRight: ".25rem", marginLeft: "4px", fontSize: 21, width: "1.18em", height: "1em" }}
			/>
			<span style={{ color: "#aaaaaa" }}>Loading Favorites...</span>
		</Dropdown.Item>
	) : displayMode === "table" ? (
		<Loader active inline size="tiny" style={{ padding: "11px 21px", marginRight: "5px" }} />
	) : (
		<Loader active inline size="small" style={{ padding: "11px 21px", marginRight: "5px" }} />
	);
};

TrialFavoritingWidget.propTypes = {
	clientId: PropTypes.string,
	trialId: PropTypes.string,
	userSettings: PropTypes.object,
	displayMode: PropTypes.string,
	refreshPage: PropTypes.bool
};

export default TrialFavoritingWidget;
