// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.table thead tr:first-child > th {\r\n\tposition: sticky !important;\r\n\ttop: -15px; /* table expands when scrolling so needed to float higher to prevent a strange overlap, set to 0 if you want to see what this fixes*/\r\n\tz-index: 1;\r\n\tbackground: white;\r\n\tborder-top: 0.2em solid #2185d0;\r\n}\r\n\r\n.ui.table thead tr:nth-child(2) > th {\r\n\tposition: sticky !important;\r\n\ttop: 31px; /* amount of space needed for top header*/\r\n\tz-index: 1;\r\n\tbackground: white;\r\n}\r\n\r\n.ui.blue.table {\r\n\tborder-top: unset;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Content/Widgets/AreaUnderTheCurveTable/styles.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,UAAU,EAAE,oIAAoI;CAChJ,UAAU;CACV,iBAAiB;CACjB,+BAA+B;AAChC;;AAEA;CACC,2BAA2B;CAC3B,SAAS,EAAE,yCAAyC;CACpD,UAAU;CACV,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".ui.table thead tr:first-child > th {\r\n\tposition: sticky !important;\r\n\ttop: -15px; /* table expands when scrolling so needed to float higher to prevent a strange overlap, set to 0 if you want to see what this fixes*/\r\n\tz-index: 1;\r\n\tbackground: white;\r\n\tborder-top: 0.2em solid #2185d0;\r\n}\r\n\r\n.ui.table thead tr:nth-child(2) > th {\r\n\tposition: sticky !important;\r\n\ttop: 31px; /* amount of space needed for top header*/\r\n\tz-index: 1;\r\n\tbackground: white;\r\n}\r\n\r\n.ui.blue.table {\r\n\tborder-top: unset;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"table": "table",
	"blue": "blue"
};
export default ___CSS_LOADER_EXPORT___;
