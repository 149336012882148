import React from "react";
import PropTypes from "prop-types";
import { Popup } from "react-map-gl";
import { Button } from "semantic-ui-react";

const ParcelPopup = ({ feature, selected = false, onAddParcel }) => {
	return (
		<Popup
			className="parcelPopup"
			latitude={Number(feature.properties.fields.lat)}
			longitude={Number(feature.properties.fields.lon)}
			anchor="top-left"
			closeOnClick={false}
		>
			<h1>Parcel Information - {feature.properties.headline}</h1>
			<hr />
			{feature.properties.fields.address !== undefined && <p>{feature.properties.fields.address}</p>}
			{feature.properties.fields.address2 !== undefined && <p>{feature.properties.fields.address2}</p>}
			{feature.properties.fields.lat !== undefined && feature.properties.fields.lon !== undefined && (
				<p>{feature.properties.fields.lat + "N, " + feature.properties.fields.lon + "E"}</p>
			)}
			{feature.properties.fields.owner !== undefined && <p>Parcel Owner: {feature.properties.fields.owner}</p>}

			{!selected && (
				<Button
					className="parcelPopupButton"
					positive
					compact
					content="Add to Selected Parcels"
					onClick={onAddParcel}
				/>
			)}
		</Popup>
	);
};

ParcelPopup.propTypes = {
	feature: PropTypes.object.isRequired,
	selected: PropTypes.bool,
	onAddParcel: PropTypes.func
};

export default ParcelPopup;
