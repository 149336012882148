import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, Table, Modal, Button, Loader, Form, Pagination, Dropdown } from "semantic-ui-react";
import moment from "moment";
import { toast } from "react-toastify";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useAuth0 } from "../../../../auth/auth0";

import * as clientActions from "../../../../redux/actions/clientActions";
import * as userLicenseActions from "../../../../redux/actions/userLicenseActions";
import LmAddUserLicense from "./LmAddUserLicense";
import LmManageUserLicense from "./LmManageUserLicense";

import "../styles.css";

const LicenseManagement = () => {
	const DATE_FORMAT = "MM/DD/YYYY";
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- Datasource
	const [userLicenses, setUserLicenses] = useState(null);

	//-- Dropdown Options
	const [clientOptions, setClientOptions] = useState([]);
	const [licenseOptions, setLicenseOptions] = useState([]);
	const [potentialUserOptions, setPotentialUserOptions] = useState([]);
	const [clientFilterOptions, setClientFilterOptions] = useState([]);
	const [licenseFilterOptions, setLicenseFilterOptions] = useState([]);
	const [potentialUserFilterOptions, setPotentialUserFilterOptions] = useState([]);
	const [licenseStatusFilterOptions, setLicenseStatusFilterOptions] = useState([]);

	//-- Selected Data
	const [selectedUserLicense, setSelectedUserLicense] = useState(null);

	//-- Selected Filter Data
	const [selectedClientId, setSelectedClientId] = useState("all");
	const [selectedLicenseId, setSelectedLicenseId] = useState("all");
	const [selectedUserId, setSelectedUserId] = useState("all");
	const [selectedLicenseStatus, setSelectedLicenseStatus] = useState("all");

	//-= Modal Control
	const [openAssignModal, setOpenAssignModal] = useState(false);
	const [openManageModal, setOpenManageModal] = useState(false);

	//-- UI Control
	const [loading, setLoading] = useState(true);

	//-- Table
	const [filteredUserLicenses, setFilteredUserLicenses] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [numberOfResults, setNumberOfResults] = useState(0);

	useEffect(() => {
		if (userAuth.isReady) {
			if (!clientOptions || clientOptions.length === 0) {
				getClients();
			}

			if (!licenseOptions || licenseOptions.length === 0) {
				getLicenses();
			}

			if (!potentialUserOptions || potentialUserOptions.length === 0) {
				getPotentialUsers();
			}

			if (!userLicenses || userLicenses.length === 0) {
				getUserLicenses();
			}

			if (!licenseStatusFilterOptions || licenseStatusFilterOptions.length === 0) {
				setupExpiredStatusOptions();
			}
		}
	}, [userAuth.isReady]);

	useEffect(() => {
		if (userLicenses) {
			handleFilter();
		}
	}, [userLicenses, itemsPerPage, activePage]);

	const getClients = async () => {
		const accessToken = await getTokenSilently();
		dispatch(clientActions.getClients(userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					const clientOpts = _.map(res.data, ({ clientId, name }) => {
						const opt = { key: clientId, value: clientId, text: name };
						return opt;
					});

					setClientOptions(clientOpts);

					const filterOpts = [{ key: "all", value: "all", text: "All" }].concat(clientOpts);
					setClientFilterOptions(filterOpts);
				} else {
					toast.error("Unable to load clients. Please try again.");
				}
			})
			.catch(() => {
				toast.error("Unable to load clients. Please try again.");
			});
	};

	const getUserLicenses = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(userLicenseActions.getUserLicenses(accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					setUserLicenses(res.data);
					handleFilter(res.data);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				toast.error("Unable to load licenses. Please try again.");
			});
	};

	async function getLicenses() {
		const accessToken = await getTokenSilently();
		dispatch(clientActions.getLicenses(userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					setupLicenseOptions(res.data);
				} else {
					console.error("Unable to load licenses. Please try again.");
				}
			})
			.catch(() => {
				console.error("Unable to load licenses. Please try again.");
				toast.error("Unable to load licenses. Please try again.");
			});
	}

	function setupLicenseOptions(lOptions) {
		const licenseOpts = _.map(lOptions, ({ id, name, description }) => {
			const opt = { key: id, value: id, text: name, description };
			return opt;
		});
		setLicenseOptions(licenseOpts);

		const filterOpts = [{ key: "all", value: "all", text: "All" }].concat(licenseOpts);
		setLicenseFilterOptions(filterOpts);
	}

	async function getPotentialUsers() {
		const accessToken = await getTokenSilently();
		dispatch(clientActions.getPotentialMembers(userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					setupPotentialUserOptions(res.data.potentials);
				} else {
					console.error("Unable to load licenses. Please try again.");
				}
			})
			.catch(() => {
				console.error("Unable to load licenses. Please try again.");
			});
	}

	function setupPotentialUserOptions(pmOptions) {
		const userEnabledPmOpts = stageUserOptions(
			_.filter(pmOptions, (pmOpt) => {
				return pmOpt.userEnabled === true;
			})
		);
		setPotentialUserOptions(userEnabledPmOpts);
		const pmOpts = stageUserOptions(pmOptions);
		const filterOpts = [{ key: "all", value: "all", text: "All" }].concat(pmOpts);
		setPotentialUserFilterOptions(filterOpts);
	}

	const stageUserOptions = (pmOptions) => {
		return _.map(pmOptions, ({ userId, personFirstName, personLastName, personEmailAddress }) => {
			const opt = {
				key: userId,
				value: userId,
				text: `${personFirstName} ${personLastName}`,
				content: (
					<div id="potential-user-item">
						<div id="potential-user-name" style={{ float: "left" }}>
							{personFirstName} {personLastName}
						</div>
						{personEmailAddress && (
							<div id="potential-user-email" style={{ float: "right", opacity: 0.75 }}>
								({personEmailAddress})
							</div>
						)}
					</div>
				)
			};
			return opt;
		});
	};

	const setupExpiredStatusOptions = () => {
		setLicenseStatusFilterOptions([
			{ key: "all", value: "all", text: "All" },
			{ key: "active", value: "active", text: "Active" },
			{ key: "expired", value: "expired", text: "Expired" }
		]);
	};

	const handleFilter = (data) => {
		const uLicenses = data && data.length > 0 ? data : userLicenses;
		const filteredList = uLicenses.filter((ul) => {
			const included =
				//-- Client
				(selectedClientId === "all" || ul.clientId === selectedClientId) &&
				//-- License
				(selectedLicenseId === "all" || ul.licenseId === selectedLicenseId) &&
				//-- User
				(selectedUserId === "all" || ul.userId === selectedUserId) &&
				//-- Expired Status
				(selectedLicenseStatus === "all" ||
					(selectedLicenseStatus === "expired" && (!ul.expiresDate || moment(ul.expiresDate).local() < moment())) ||
					(selectedLicenseStatus === "active" && ul.expiresDate && moment(ul.expiresDate).local() > moment()));
			return included;
		});
		const newTotalPages = Math.ceil(filteredList.length / itemsPerPage);
		setTotalPages(newTotalPages);
		setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
		setNumberOfResults(filteredList.length);
		const fUserLicenses = filteredList.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage);

		setFilteredUserLicenses(fUserLicenses);
	};

	const resetFilter = () => {
		setSelectedClientId("all");
		setSelectedLicenseId("all");
		setSelectedUserId("all");
		setSelectedLicenseStatus("all");
		const newTotalPages = Math.ceil(userLicenses.length / itemsPerPage);
		setTotalPages(newTotalPages);
		setNumberOfResults(userLicenses.length);
		setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
		const fUserLicenses = userLicenses.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage);
		setFilteredUserLicenses(fUserLicenses);
		setSortDirection(null);
		setSortColumn(null);
	};

	const handleSort = (clickedColumn) => {
		let m = moment(null);
		console.log(m);
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setFilteredUserLicenses(
					_.sortBy(
						filteredUserLicenses,
						function (d) {
							return d[clickedColumn] ? moment(d[clickedColumn], DATE_FORMAT) : null;
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total/i)) {
				setFilteredUserLicenses(_.sortBy(filteredUserLicenses, [clickedColumn]));
			} else if (clickedColumn.match(/firstName|lastName/i)) {
				setFilteredUserLicenses(_.sortBy(filteredUserLicenses, ["firstName", "lastName"]));
			} else if (clickedColumn.match(/expired/i)) {
				setFilteredUserLicenses(
					_.sortBy(filteredUserLicenses, [
						function (ful) {
							return ful.expiresDate === null || moment(ful.expiresDate).local() < moment();
						}
					])
				);
			} else {
				setFilteredUserLicenses(
					_.sortBy(filteredUserLicenses, [
						(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])
					])
				);
			}
			setSortDirection("ascending");
			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setFilteredUserLicenses(filteredUserLicenses.reverse());
	};

	const handleOpenAddModal = () => {
		setOpenAssignModal(true);
	};

	const handleCancelAddModal = () => {
		setOpenAssignModal(false);
	};

	const handleUserLicenseAdded = () => {
		setOpenAssignModal(false);
		getUserLicenses();
	};

	const handleOpenManageModal = (ul) => {
		setSelectedUserLicense(ul);
		setOpenManageModal(true);
	};

	const handleCancelManageModal = () => {
		setOpenManageModal(false);
		setSelectedUserLicense(null);
	};

	const handleUserLicenseUpdated = () => {
		setOpenManageModal(false);
		setSelectedUserLicense(null);
		getUserLicenses();
	};

	const handleUserLicenseRemoved = () => {
		setOpenManageModal(false);
		setSelectedUserLicense(null);
		getUserLicenses();
	};

	const addWarning = (ul) => {
		const active = moment(ul.expiresDate).local() > moment().local();
		const monthsRemaining = moment(ul.expiresDate).local().diff(moment().local(), "months", true);
		return active && monthsRemaining < 1;
	};

	const expired = (ul) => {
		const expired = moment(ul.expiresDate).local() < moment().local();
		return expired;
	};

	return loading ? (
		<Loader active />
	) : (
		<Segment basic>
			<Form>
				<Grid id="license-management-grid" columns="equal" verticalAlign="middle">
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-client">Client</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-client"
								selection
								search
								options={clientFilterOptions}
								value={selectedClientId}
								onChange={(event, { value }) => {
									setSelectedClientId(value);
								}}
								loading={!clientFilterOptions}
							></Form.Select>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-license">License</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-license"
								selection
								options={licenseFilterOptions}
								value={selectedLicenseId}
								onChange={(event, { value }) => {
									setSelectedLicenseId(value);
								}}
								loading={!licenseFilterOptions}
							></Form.Select>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-user">User</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-user"
								selection
								search
								options={potentialUserFilterOptions}
								value={selectedUserId}
								onChange={(event, { value }) => {
									setSelectedUserId(value);
								}}
								loading={!potentialUserFilterOptions}
							></Form.Select>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-license-status">License Status</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-license-status"
								selection
								options={licenseStatusFilterOptions}
								value={selectedLicenseStatus}
								onChange={(event, { value }) => {
									setSelectedLicenseStatus(value);
								}}
								loading={!licenseStatusFilterOptions}
							></Form.Select>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row textAlign="right">
						<Grid.Column>
							<Form.Field>
								<Button id="form-button-clear-filter" negative content="Reset" onClick={resetFilter} />
								<Button id="form-button-filter" primary content="Filter" onClick={handleFilter} />
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Modal
								open={openAssignModal}
								trigger={
									<Button
										floated="right"
										style={{ marginBottom: 10 }}
										color="blue"
										content="Assign License"
										onClick={() => {
											handleOpenAddModal();
										}}
									/>
								}
							>
								<Modal.Header>Assign License</Modal.Header>

								<LmAddUserLicense
									clientOptions={clientOptions}
									potentialUserOptions={potentialUserOptions}
									licenseOptions={licenseOptions}
									onCancel={handleCancelAddModal}
									onUserLicenseAdded={handleUserLicenseAdded}
								/>
							</Modal>
							<Table celled striped sortable selectable color="blue" textAlign="center">
								<Table.Header>
									<Table.Row textAlign="center">
										<Table.HeaderCell
											sorted={sortColumn === "firstName" ? sortDirection : null}
											onClick={() => handleSort("firstName")}
										>
											User
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "emailAddress" ? sortDirection : null}
											onClick={() => handleSort("emailAddress")}
										>
											Email Address
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "clientName" ? sortDirection : null}
											onClick={() => handleSort("clientName")}
										>
											Client
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "signUpDate" ? sortDirection : null}
											onClick={() => handleSort("signUpDate")}
										>
											Sign Up Date
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "expiresDate" ? sortDirection : null}
											onClick={() => handleSort("expiresDate")}
										>
											Expires Date
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "expired" ? sortDirection : null}
											onClick={() => handleSort("expired")}
										>
											Expired
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "licenseId" ? sortDirection : null}
											onClick={() => handleSort("licenseId")}
										>
											License
										</Table.HeaderCell>
										<Table.HeaderCell>Actions</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(filteredUserLicenses, (ul) => {
										return (
											<Table.Row key={ul.userLicenseId} warning={addWarning(ul)} error={expired(ul)}>
												<Table.Cell>
													{ul.firstName} {ul.lastName}
												</Table.Cell>
												<Table.Cell>{ul.emailAddress}</Table.Cell>
												<Table.Cell>{ul.clientName}</Table.Cell>
												<Table.Cell>
													{ul.signUpDate === null ? "" : moment(ul.signUpDate).local().format(DATE_FORMAT)}
												</Table.Cell>
												<Table.Cell>
													{ul.expiresDate === null ? "" : moment(ul.expiresDate).local().format(DATE_FORMAT)}
												</Table.Cell>
												<Table.Cell>{ul.expiresDate === null || expired(ul) ? "Expired" : "Active"}</Table.Cell>
												<Table.Cell>{ul.licenseName}</Table.Cell>
												<Table.Cell textAlign="center">
													<Button
														color="blue"
														content="Manage"
														onClick={() => {
															handleOpenManageModal(ul);
														}}
													/>
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
								<Table.Footer>
									<Table.Row textAlign="right">
										<Table.HeaderCell colSpan="11">
											<span style={{ marginRight: 10 }}>
												{numberOfResults === 1
													? `${numberOfResults} user license found`
													: `${numberOfResults} user licenses found`}
											</span>
											<Pagination
												onPageChange={(e, pageInfo) => {
													setActivePage(pageInfo.activePage);
												}}
												boundaryRange={5}
												siblingRange={1}
												totalPages={totalPages}
												activePage={activePage}
											/>
											<Dropdown
												onChange={(e, { value }) => {
													setItemsPerPage(value);
												}}
												selection
												options={[
													{ key: 1, text: 5, value: 5 },
													{ key: 2, text: 10, value: 10 },
													{ key: 3, text: 15, value: 15 },
													{ key: 4, text: 25, value: 25 },
													{ key: 5, text: 50, value: 50 }
												]}
												value={itemsPerPage}
												compact
												style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
											/>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Footer>
							</Table>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
			<Modal open={openManageModal}>
				<Modal.Header>Manage Member</Modal.Header>
				<LmManageUserLicense
					userLicenseToUpdate={selectedUserLicense}
					clientOptions={clientOptions}
					potentialUserOptions={potentialUserOptions}
					licenseOptions={licenseOptions}
					onCancel={handleCancelManageModal}
					onUserLicenseUpdated={handleUserLicenseUpdated}
					onUserLicenseRemoved={handleUserLicenseRemoved}
				/>
			</Modal>
		</Segment>
	);
};

export default LicenseManagement;
