import React from "react";
import PropTypes from "prop-types";
import { Button, Divider, Icon, Modal } from "semantic-ui-react";

const ConfrimNavModal = ({ open, setOpen, onSave, onDiscard, saving = false, discarding = false }) => {
	return (
		<Modal open={open}>
			<Modal.Header>
				Confirm Navigation
				<Icon name="close" link onClick={() => setOpen(false)} />
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>Page has unsaved changes</Modal.Description>
				<Divider />
				<div style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginTop: 15, marginBottom: 0 }}>
					<Button
						negative
						compact
						content="Discard Changes"
						loading={discarding}
						disabled={saving || discarding}
						onClick={() => {
							onDiscard();
						}}
					/>
					<Button
						style={{ margin: 0 }}
						positive
						compact
						content="Save and Continue"
						loading={saving}
						disabled={saving || discarding}
						onClick={() => {
							onSave();
						}}
					/>
				</div>
			</Modal.Content>
		</Modal>
	);
};

ConfrimNavModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	onSave: PropTypes.func,
	onDiscard: PropTypes.func,
	saving: PropTypes.bool,
	discarding: PropTypes.bool
};

export default ConfrimNavModal;
