import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Button, Modal, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "lodash";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import TrialSearch from "./TrialSearch";
import AssociatedTrialsTable from "./AssociatedTrialsTable";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as trialActions from "../../../../redux/actions/trialActions";
import * as lookupActions from "../../../../redux/actions/lookupActions";
import * as protocolActions from "../../../../redux/actions/protocolActions";

const AssociateTrials = ({ growingSeasonId, cropId, numberOfTreatments }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const history = useHistory();

	const moduleNavigation = useModuleNavigation();

	//-- Data Sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [trialSearchDataOptions, setTrialSearchDataOptions] = useState(null);
	const [associatedTrials, setAssociatedTrials] = useState([]);
	const [updatedAssociatedTrials, setUpdatedAssociatedTrials] = useState([]);
	const [stateDataOptions, setStateDataOptions] = useState([]);

	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [stateOptions, setStateOptions] = useState([]);
	const [cropOptions, setCropOptions] = useState([]);
	const [trialOptions, setTrialOptions] = useState([]);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [cooperatorOptions, setCooperatorOptions] = useState([]);
	const [farmOptions, setFarmOptions] = useState([]);
	const [trialOwnerOptions, setTrialOwnerOptions] = useState([]);
	const [fieldOptions, setFieldOptions] = useState([]);
	const [numberOfTreatmentOptions, setNumberOfTreatmentOptions] = useState([]);

	const [trialSearchResults, setTrialSearchResults] = useState([]);
	const [selectedFilterOptions, setSelectedFilterOptions] = useState({});

	//-- UI Control
	const [searchForTrialsModalOpen, setSearchForTrialsModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		if (clientId) {
			getTrialSearchDataOptions(clientId);
			getAssociatedTrials(moduleNavigation.protocolId, clientId);
		}
	}, [clientId]);

	useEffect(() => {
		if (userAuth.isReady && trialSearchDataOptions) {
			setGrowingSeasonOptionsState(trialSearchDataOptions.growingSeasons);
			setCropOptionsState(trialSearchDataOptions.crops);
			setTrialOptionsState(trialSearchDataOptions.trials);
			setCompanyOptionsState(trialSearchDataOptions.companies);
			setCooperatorOptionsState(trialSearchDataOptions.companies);
			setFarmOptionsState(trialSearchDataOptions.farms);
			setTrialOwnerOptionsState(trialSearchDataOptions.trialOwners);
			setFieldOptionsState(trialSearchDataOptions.fields);
			setNumberOfTreatmentsOptionsState();
		}
	}, [userAuth.isReady, trialSearchDataOptions]);

	useEffect(() => {
		if (numberOfTreatments && numberOfTreatmentOptions.length === 0) {
			setNumberOfTreatmentsOptionsState();
		}
	}, [numberOfTreatments]);

	useEffect(() => {
		if (userAuth.isReady && stateDataOptions) {
			setStateOptionsState(stateDataOptions);
		}
	}, [userAuth.isReady, stateDataOptions]);

	function setStateOptionsState(stateDataOptions) {
		setStateOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(stateDataOptions, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			)
		);
	}

	function setGrowingSeasonOptionsState(growingSeasons) {
		setGrowingSeasonOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(growingSeasons, ({ growingSeasonId, name }) => {
					const opt = { key: growingSeasonId, value: growingSeasonId, text: name };
					return opt;
				})
			)
		);
	}

	function setCropOptionsState(crops) {
		setCropOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(crops, ({ cropId, name }) => {
					const opt = { key: cropId, value: cropId, text: name };
					return opt;
				})
			)
		);
	}

	function setTrialOptionsState(trials) {
		setTrialOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(trials, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			)
		);
	}

	function setCompanyOptionsState(companies) {
		setCompanyOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(companies, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			)
		);
	}

	function setCooperatorOptionsState(companies) {
		let cooperatorCompanies = _.filter(companies, (c) => c.isCooperator === true);
		setCooperatorOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(cooperatorCompanies, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			)
		);
	}

	function setFarmOptionsState(farms) {
		setFarmOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(farms, ({ farmId, name }) => {
					const opt = { key: farmId, value: farmId, text: name };
					return opt;
				})
			)
		);
	}

	function setTrialOwnerOptionsState(trialOwners) {
		setTrialOwnerOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(trialOwners, ({ userId, firstName, lastName }) => {
					const opt = { key: userId, value: userId, text: `${firstName} ${lastName}` };
					return opt;
				})
			)
		);
	}

	function setFieldOptionsState(fields) {
		setFieldOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(fields, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			)
		);
	}

	function setNumberOfTreatmentsOptionsState() {
		let options = [];
		//-- Start at 1 because we cannot have trial with 0 treatments
		for (let i = 1; i <= numberOfTreatments; ++i) {
			const opt = { key: i, value: i, text: i };
			options.push(opt);
		}
		options.unshift({ key: "all", value: "all", text: "All" });
		setNumberOfTreatmentOptions(options);
	}

	function selectTrial(trial, checked) {
		if (checked === true) {
			setUpdatedAssociatedTrials([...updatedAssociatedTrials, trial]);
		} else {
			var copy = [...updatedAssociatedTrials];
			copy = _.filter(copy, (c) => {
				return c.trialId !== trial.trialId;
			});
			setUpdatedAssociatedTrials(copy);
		}
	}

	function removeAssociatedTrial(trialId) {
		var copy = [...updatedAssociatedTrials];
		copy = _.filter(copy, (c) => {
			return c.trialId !== trialId;
		});
		setAssociatedTrials(copy);
		setUpdatedAssociatedTrials(copy);
	}

	async function getTrialSearchDataOptions(clientId) {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getTrialSearchDataOptions(clientId, false, accessToken))
			.then((res) => {
				setTrialSearchDataOptions(res);
				//-- Don't set loading to false here because we are going to search after we get the options, so let that function set loading to false
			})
			.then(() => {
				dispatch(lookupActions.getStates(clientId, accessToken)).then((res) => {
					setStateDataOptions(res.data);
				});
			})
			.catch((err) => {
				toast.error("Error loading Trial search options. Please try again.");
				console.log(err);
			});
	}

	async function getAssociatedTrials(protocolId, clientId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(protocolActions.getAssociatedTrials(protocolId, clientId, accessToken))
			.then((res) => {
				setAssociatedTrials(res.data);
				setUpdatedAssociatedTrials(res.data);
				setLoading(false);
			})
			.catch((err) => {
				toast.error("Error loading Associated Trials. Please try again.");
				setLoading(false);
				console.log(err);
			});
	}

	function updateTrialSearchResults(updatedTrialSearchResults) {
		setTrialSearchResults(updatedTrialSearchResults);
	}

	function updateSelectedFilterOptions(updatedSelectedFilterOptions) {
		setSelectedFilterOptions(updatedSelectedFilterOptions);
	}

	function addSelectedTrials() {
		setAssociatedTrials(updatedAssociatedTrials);
		handleSearchForTrialsModalOpen();
	}

	function cancelSelectedTrials() {
		setUpdatedAssociatedTrials(associatedTrials);
		handleSearchForTrialsModalOpen();
	}

	function handleSearchForTrialsModalOpen() {
		setSearchForTrialsModalOpen(!searchForTrialsModalOpen);
	}

	async function save(navigate) {
		setSaving(true);
		const accessToken = await getTokenSilently();
		const trialsToAssociate = _.map(associatedTrials, "trialId");
		dispatch(
			protocolActions.createAssociatedTrials(moduleNavigation.protocolId, trialsToAssociate, clientId, accessToken)
		)
			.then(() => {
				setSaving(false);
				toast.success("Associated Trials saved.");
				if (navigate === true) {
					history.push(moduleNavigation.createProtocolLink(true, moduleNavigation.protocolId, "summary"));
				}
			})
			.catch((err) => {
				setSaving(false);
				toast.error("Error saving Associated Trials. Please try again.");
				console.log(err);
			});
	}

	function saveAndContinue() {
		save(true);
	}

	return loading ? (
		<Loader active />
	) : (
		<>
			<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
				<Button
					id="form-button-back"
					floated="left"
					compact
					secondary
					content={"Back"}
					disabled={saving}
					onClick={() => {
						history.push(moduleNavigation.createProtocolLink(true, moduleNavigation.protocolId, "assessments"));
					}}
				/>
				<Button
					id="form-button-continue"
					floated="right"
					compact
					color="green"
					content={"Save & Continue"}
					loading={saving}
					disabled={saving}
					onClick={saveAndContinue}
				/>
				<Button
					id="form-button-continue"
					floated="right"
					compact
					primary
					content={"Save"}
					loading={saving}
					disabled={saving}
					onClick={() => save(false)}
				/>
			</Segment>
			<Segment basic>
				<AssociatedTrialsTable
					trialList={associatedTrials}
					removeAssociatedTrial={removeAssociatedTrial}
					saving={saving}
				/>
				<Modal
					open={searchForTrialsModalOpen}
					trigger={
						<Button
							id="button-add-trials"
							primary
							disabled={saving}
							content={"Find and Add Trials"}
							onClick={handleSearchForTrialsModalOpen}
						/>
					}
				>
					<Modal.Header>Trial Search</Modal.Header>
					<Modal.Content scrolling>
						<TrialSearch
							growingSeasonOptions={growingSeasonOptions}
							selectedGrowingSeasonId={growingSeasonId}
							cropOptions={cropOptions}
							selectedCropId={cropId}
							stateOptions={stateOptions}
							trialOptions={trialOptions}
							companyOptions={companyOptions}
							cooperatorOptions={cooperatorOptions}
							farmOptions={farmOptions}
							trialOwnerOptions={trialOwnerOptions}
							fieldOptions={fieldOptions}
							numberOfTreatmentOptions={numberOfTreatmentOptions}
							associatedTrials={updatedAssociatedTrials}
							searchedTrials={trialSearchResults}
							selectedFilterOptions={selectedFilterOptions}
							selectTrial={selectTrial}
							updateTrialSearchResults={updateTrialSearchResults}
							updateSelectedFilterOptions={updateSelectedFilterOptions}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button primary floated="right" onClick={addSelectedTrials}>
							Add Selected Trials to Protocol
						</Button>
						<Button color="red" floated="right" onClick={cancelSelectedTrials}>
							Cancel
						</Button>
						<br style={{ clear: "both" }} />
					</Modal.Actions>
				</Modal>
			</Segment>
		</>
	);
};

AssociateTrials.propTypes = {
	growingSeasonId: PropTypes.string,
	cropId: PropTypes.string,
	numberOfTreatments: PropTypes.number
};

export default AssociateTrials;
