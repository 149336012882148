import _ from "lodash";

export default function validate(form, checkEmpty = false) {
	const messages = {
		name: "",
		abbreviation: "",
		address2: "",
		city: "",
		stateId: "",
		zipCode: "",
		county: "",
		lat: "",
		long: "",
		cooperatorCompanyId: "",
		owner: "",
		notes: ""
	};

	if (checkEmpty || form.name) {
		if ((checkEmpty && !form.name) || form.name.trim() === "") {
			messages.name = "Farms must have a name";
		} else if (form.name.length > 50) {
			messages.name = "Names cannot exceed 50 characters";
		}
	}

	if (checkEmpty || form.abbreviation) {
		if ((checkEmpty && !form.abbreviation) || form.abbreviation.trim() === "") {
			messages.abbreviation = "Farms must have an abbreviation";
		} else if (form.abbreviation.length > 50) {
			messages.abbreviation = "Abbreviations cannot exceed 50 characters";
		}
	}

	if (form.address2) {
		if (form.address2.length > 50) {
			messages.address2 = "2nd address lines cannot exceed 50 characters";
		}
	}

	if (form.long) {
		if (!Number(form.long)) {
			messages.long = "Longitude must be a number.";
		}
	}

	if (form.lat) {
		if (!Number(form.lat)) {
			messages.lat = "Latitude must be a number.";
		}
	}

	if (checkEmpty || form.city) {
		if ((checkEmpty && !form.city) || form.city.trim() === "") {
			messages.city = "Farms must have a city";
		} else if (form.city.length > 50) {
			messages.city = "City names cannot exceed 50 characters";
		}
	}

	if (checkEmpty || form.stateId) {
		if ((checkEmpty && !form.stateId) || form.stateId.trim() === "") {
			messages.stateId = "Farms must have a state";
		}
	}

	if (form.county) {
		if (form.county.length > 50) {
			messages.county = "County names cannot exceed 50 characters";
		}
	}

	if (form.zipCode) {
		if (form.zipCode.length > 10) {
			messages.zipCode = "Zip codes cannot exceed 10 characters";
		} else if (!Number(form.zipCode)) {
			messages.zipCode = "Zip code must be a number";
		}
	}

	if (checkEmpty || form.cooperatorCompanyId) {
		if ((checkEmpty && !form.cooperatorCompanyId) || form.cooperatorCompanyId.trim() === "") {
			messages.cooperatorCompanyId = "Farms must have a cooperator company";
		}
	}

	if (form.owner) {
		if (form.owner.length > 50) {
			messages.owner = "Land Owner names cannot exceed 50 characters";
		}
	}

	if (form.notes) {
		if (form.notes.length > 200) {
			messages.notes = "Notes cannot exceed 200 characters";
		}
	}

	const failed = _.some(Object.values(messages, (m) => m !== ""));
	return { failed, messages };
}
