import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";

import Tooltip from "rc-tooltip";
import { Dropdown, Icon, Image, Input, Menu } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";

import logo from "../../../assets/aerialFARMS_flat.svg";
import { useUserSettings } from "../../../hooks/useUserSettings";
import ProtocolFavoritingWidget from "../../Content/Widgets/Favorites/ProtocolFavoritingWidget";
import TrialFavoritingWidget from "../../Content/Widgets/Favorites/TrialFavoritingWidget";
import { ApplicationArea } from "../../Lumber/ApplicationAreas";
import "./style.css";

//-- #073763 = rgb(7, 55, 99)

const Header = ({ entityNames }) => {
	const { isAuthenticated } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const [clients, setClients] = useState(null);
	const clientLogoUri = useSelector((state) => (state.clientLogoUri ? state.clientLogoUri : null));

	const pageTitle = useMemo(() => {
		switch (moduleNavigation.activeModule.name) {
			case "farms":
				return entityNames?.farmName
					? `${entityNames?.farmName}, ${entityNames?.cooperatorName} (${entityNames?.city}, ${entityNames?.state})`
					: "";
			case "trials":
				return entityNames?.trialName
					? `${entityNames?.trialName}, ${entityNames?.cooperatorName} (${entityNames?.city}, ${entityNames?.state})`
					: "";
			case "fields":
				return entityNames?.fieldName;
			case "flights":
				return entityNames?.flightName;
			case "protocols":
				return entityNames?.protocolName;
			default:
				return undefined;
		}
	}, [entityNames, moduleNavigation.activeModule.name]);

	const relatedLinks = useMemo(() => {
		if (!entityNames || !entityNames.relatedLinks) return [];
		return entityNames.relatedLinks.map((rl) => {
			const curr = { name: rl.name, type: rl.type.charAt(0).toUpperCase() + rl.type.slice(1) };
			if (rl.type === "protocol") {
				curr.linkTo = moduleNavigation.createProtocolLink(true, rl.id, "overview-status");
			}
			if (rl.type === "field") {
				curr.linkTo = moduleNavigation.createFieldLink(true, rl.id, "viewer");
			}
			if (rl.type === "farm") {
				curr.linkTo = moduleNavigation.createFarmLink(true, rl.id, "viewer");
			}

			return curr;
		});
	}, [entityNames, moduleNavigation.activeModule.name]);

	useEffect(() => {
		if (_.isEmpty(clients)) {
			initializeClientOptions(userAuth.user.userInfo.clientMemberships);
		}
	}, [userAuth.user.userInfo.clientMemberships]);

	function initializeClientOptions(clients) {
		const clientOpts = _.map(clients, (client) => {
			const opt = {
				key: client.clientId,
				value: client.clientId,
				text: client.clientName
			};
			return opt;
		});

		setClients(clientOpts);

		userAuth.handleClientChange(userAuth.currentClientId, userAuth.currentClientId);
	}

	return (
		<header>
			<Menu borderless size="large" stackable id="rootHeader" className="nav header">
				<Menu.Item
					as={Link}
					to={moduleNavigation.homeModule.paths[0]}
					style={{ border: "unset", marginLeft: 7 }}
					name="home"
					fitted
					onClick={(e) => {
						if (!userAuth.isSubsriptionActive) {
							e.preventDefault();
						}
					}}
				>
					<Image src={logo} id="aFLogo" />
				</Menu.Item>

				<Menu.Item style={{ margin: "auto", padding: 0 }}>
					<PageTitle
						moduleNavigation={moduleNavigation}
						pageTitle={pageTitle}
						currentClientId={userAuth.currentClientId}
						relatedLinks={relatedLinks}
					/>
				</Menu.Item>

				{isAuthenticated &&
					renderAuthenticated(
						userAuth,
						userAuth.user.userInfo,
						clientLogoUri,
						clients,
						userAuth.currentClientId,
						userAuth.handleClientChange
					)}
			</Menu>
		</header>
	);
};

const PageTitle = ({ moduleNavigation, pageTitle, currentClientId, relatedLinks }) => {
	const jumpIcons = {
		Protocol: "folder open",
		Field: "th large",
		Farm: "farm"
	};

	const userSettings = useUserSettings();

	const isTrial = useMemo(() => {
		return moduleNavigation.activeModule.name === "trials";
	}, [moduleNavigation.activeModule]);

	const isProtocol = useMemo(() => {
		return moduleNavigation.activeModule.name === "protocols";
	}, [moduleNavigation.activeModule]);

	return pageTitle ? (
		<>
			<div className="headerTitle">
				<span className="headerModuleTitle">
					{moduleNavigation.activeSubmodule.pageTitle}
					{" / "}
				</span>
				<span className="headerPageTitle">{pageTitle}</span>

				{(isTrial || isProtocol) && (
					<Dropdown style={{ color: "#FFFFFF", fontSize: "1rem" }} pointing>
						<Dropdown.Menu style={{ transform: "translate(-50%, 0)", left: 16.5, zIndex: 2000 }}>
							{isTrial && currentClientId && moduleNavigation.trialId && (
								<TrialFavoritingWidget
									style={{ display: "inline" }}
									clientId={currentClientId.toUpperCase()}
									trialId={moduleNavigation.trialId.toUpperCase()}
									userSettings={userSettings}
									refreshPage={true}
								/>
							)}
							{isProtocol && currentClientId && moduleNavigation.protocolId && (
								<ProtocolFavoritingWidget
									style={{ display: "inline" }}
									clientId={currentClientId.toUpperCase()}
									protocolId={moduleNavigation.protocolId.toUpperCase()}
									userSettings={userSettings}
								/>
							)}
							{relatedLinks && !!relatedLinks.length && (
								<>
									<Dropdown.Item disabled>
										<div className="relatedLinksHeader">Related Links</div>
									</Dropdown.Item>
									{relatedLinks.map((rl, i) => (
										<Dropdown.Item key={i} className="relatedLink">
											<Link to={rl.linkTo} target="_blank">
												<Icon.Group style={{ width: 27 }}>
													<Icon name={jumpIcons[rl.type]} />
													<Icon className="shortcut" name="share" corner="bottom right" />
												</Icon.Group>
												<span>
													<b style={{ color: "#000000" }}>{rl.type}</b> ({rl.name})
												</span>
											</Link>
										</Dropdown.Item>
									))}
								</>
							)}
						</Dropdown.Menu>
					</Dropdown>
				)}
			</div>
		</>
	) : (
		<>
			<div className="headerTitle">
				<span className="headerPageTitle">{moduleNavigation.activeSubmodule.pageTitle}</span>
			</div>
		</>
	);
};

const renderAuthenticated = (userAuth, userInfo, clientLogoUri, clients, currentClientId, handleClientChange) => {
	const [clientList, setClientList] = useState([]);

	useEffect(() => {
		setClientList(clients);
	}, [clients]);

	const updateClientList = (query) => {
		const re = RegExp(_.escapeRegExp(query), "i");
		setClientList(_.filter(clients, (client) => re.test(client.text)));
	};

	return (
		<>
			<Menu.Menu id="userContextMenu">
				<span style={{ margin: "auto" }}>{`Hello, ${userInfo.basic.firstName}!`}</span>
				<Menu.Item name="userContext">
					<Dropdown
						style={{ color: "var(--nav-secondary)" }}
						trigger={
							<span>
								{clientLogoUri ? (
									<Image src={clientLogoUri} size="tiny" style={{ maxWidth: "80px", maxHeight: "60px" }} inline />
								) : (
									clients &&
									clients.length > 1 && (
										<span>
											(
											{
												clients.find((c) => {
													return c.value === currentClientId;
												}).text
											}
											)
										</span>
									)
								)}
							</span>
						}
					>
						<Dropdown.Menu
							style={{
								overflowY: "auto",
								maxHeight: "600px",
								minWidth: "300px",
								zIndex: "1100",
								right: 0,
								left: "unset"
							}}
						>
							<Dropdown.Header
								style={{ fontWeight: "unset", textTransform: "unset", opacity: ".75" }}
								content={
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={`${userInfo.basic.emailAddress}`}
										transitionName="rc-tooltip-zoom"
									>
										<span>
											Signed in as <strong>{`${userInfo.basic.firstName} ${userInfo.basic.lastName}`}</strong>
										</span>
									</Tooltip>
								}
							/>
							<Dropdown.Item>
								<Link to="/logout" style={{ display: "block" }}>
									<Icon name="sign out" size="small" />
									<span style={{ color: "#000000" }}>Log Out</span>
								</Link>
							</Dropdown.Item>
							<Dropdown.Divider />
							{(userAuth.isApAdmin ||
								userAuth.hasApplicationArea(ApplicationArea.ApSettings, userAuth.currentClientId) ||
								userAuth.hasApplicationArea(ApplicationArea.ClientSettings, userAuth.currentClientId)) && (
								<>
									<Dropdown.Item>
										<Link
											to={userAuth.isApAdmin ? "/ap-settings" : "/client-settings"}
											style={{ display: "block" }}
											target="_blank"
										>
											<Icon name="cog" size="small" />
											<span style={{ color: "#000000" }}>Settings</span>
										</Link>
									</Dropdown.Item>
									<Dropdown.Divider />
								</>
							)}
							<Dropdown.Item>
								<Link to="/support" style={{ display: "block" }}>
									<Icon name="help" size="small" />
									<span style={{ color: "#000000" }}>Support</span>
								</Link>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Header
								style={{ fontWeight: "unset", textTransform: "unset", opacity: ".75" }}
								content={clientList && clientList.length === 1 ? `Organization` : `Organizations`}
							/>
							{clients && clients.length > 1 && (
								<Input
									onClick={(e) => e.stopPropagation()}
									onChange={(e, { value }) => {
										updateClientList(value);
									}}
									icon="search"
									iconPosition="left"
								/>
							)}
							{_.map(clientList, ({ key, value, text }) => {
								return (
									<Dropdown.Item
										className="clientContainer"
										style={{}}
										key={key}
										id={value}
										onClick={(event, data) => {
											handleClientChange(data.id);
										}}
									>
										{value === currentClientId ? <Icon name="check circle outline" color="green" /> : null}
										{text}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</Menu.Item>
			</Menu.Menu>
		</>
	);
};

PageTitle.propTypes = {
	moduleNavigation: PropTypes.object,
	pageTitle: PropTypes.string,
	currentClientId: PropTypes.string,
	relatedLinks: PropTypes.array
};

Header.propTypes = {
	entityNames: PropTypes.object
};

export default Header;
