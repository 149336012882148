import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import _ from "lodash";

import { useModuleNavigation } from "./useModuleNavigation";

import * as clientActions from "../redux/actions/clientActions";

import moment from "moment";
import { ApplicationArea } from "../components/Lumber/ApplicationAreas";

const ENVIRONMENT = process.env.environment;

export const useUserAuth = () => {
	//-- Instantiate hooks
	const history = useHistory();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();

	//-- Track useUserAuth is ready to use
	const [isReady, setIsReady] = useState(false);

	//-- User Info
	const currentClientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const user = useSelector((state) => state.user);

	//-- Tracked User Info
	const [currentClient, setCurrentClient] = useState(null);

	const [setupNeeded, setSetupNeeded] = useState(true);

	//-- Licenses
	const [isApAdmin, setIsApAdmin] = useState(false);
	const [isSubsriptionActive, setIsSubscriptionActive] = useState(false);

	useEffect(() => {
		if (user && user.setupNeeded !== undefined && user.setupNeeded !== null) {
			setSetupNeeded(user.setupNeeded);
		}
	}, [user]);

	useEffect(() => {
		if (!currentClientId && user && !setupNeeded) {
			const clients = user.userInfo.clientMemberships;

			if (clients && clients.length !== 0) {
				//-- If local storage has a current client id, try to use it
				var localStorageCurrentClientId = localStorage.getItem(`${ENVIRONMENT}-currentClientId`);

				if (localStorageCurrentClientId && clients.some((c) => c.clientId === localStorageCurrentClientId)) {
					handleClientChange(currentClientId, localStorageCurrentClientId);
				} else {
					//-- Otherise, use the first in the list
					handleClientChange(currentClientId, clients[0].clientId);
				}
			}
		}
	}, [currentClientId, user, setupNeeded]);

	//-- Set Client and User
	useEffect(() => {
		if (currentClientId && user && !setupNeeded) {
			const cc = user.userInfo.clientMemberships.find((cm) => cm.clientId === currentClientId);
			setSetupNeeded(user.setupNeeded);
			setCurrentClient(cc);
			setLicenses(cc);
			setIsReady(true);
		}
	}, [currentClientId, user, setupNeeded]);

	//-- Set UserInfo
	useEffect(() => {
		if (currentClient && !setupNeeded) {
			setLicenses(currentClient);
		}
	}, [currentClient, setupNeeded]);

	const setLicenses = (cc) => {
		setIsApAdmin(hasLicense("ApAdmin", false));
		setIsSubscriptionActive(hasActiveSubscriptionForClient(cc.clientId));
	};

	function hasLicense(license, checkClient = true, clientId) {
		const hasLicense = checkClient
			? !!user.userInfo.userLicenses.find(
					(ur) =>
						(currentClientId ? ur.clientId === currentClientId : ur.clientId === clientId) && ur.license === license
			  )
			: !!user.userInfo.userLicenses.find((ur) => ur.license === license);
		return hasLicense;
	}

	function hasApplicationArea(applicationArea, clientId) {
		const hasApplicationArea = !!user.userInfo.applicationAreas.find(
			(ur) => (clientId ? ur.clientId === clientId : ur.clientId == currentClientId) && ur.name === applicationArea
		);
		return hasApplicationArea || user.userInfo.basic.isAerialPlotAdmin;
	}

	function hasActiveSubscriptionForClient(clientId) {
		const subscriptionActive = _.some(
			user.userInfo.userLicenses,
			(ul) =>
				(ul.currentClientId ? ul.clientId === currentClientId : ul.clientId === clientId) &&
				moment().isBefore(moment(ul.expiresDateTimeUtc))
		);
		return subscriptionActive || user.userInfo.basic.isAerialPlotSupport || user.userInfo.basic.isAerialPlotAdmin;
	}

	function userCanAccessModule(mod) {
		//-- Check for ApAdmin
		if (hasLicense("ApAdmin", false)) {
			return true;
		}

		//-- Check User Application Areas
		//-- If there are no specific application areas assigned to the module, the answer is yes
		if (mod.applicationAreas.length === 0) {
			return true;
		}

		const userApplicationAreas = user.userInfo.applicationAreas
			.filter((ur) => ur.clientId === currentClientId)
			.map((ur) => ur.name);

		if (!mod.applicationAreas.some((r) => userApplicationAreas.includes(r))) {
			return false;
		}

		return true;
	}

	function userCanAccessSubmodule(submod) {
		//-- If there isn't a submodule, the answer is sure why not
		if (Object.entries(submod).length === 0) {
			return true;
		}

		//-- Check for ApAdmin
		if (hasLicense("ApAdmin", false)) {
			return true;
		}

		//-- Check Application Areas
		//-- 	If there are no specific licenses assigned to the module, the answer is yes
		if (submod.applicationAreas.length === 0) {
			return true;
		}

		const userApplicationAreas = user.userInfo.applicationAreas
			.filter((ur) => ur.clientId === currentClientId)
			.map((ur) => ur.name);

		if (!submod.applicationAreas.some((r) => userApplicationAreas.includes(r))) {
			return false;
		}

		return true;
	}

	function goHome() {
		if (hasApplicationArea(ApplicationArea.HomeOverview, currentClientId)) {
			history.push(moduleNavigation.createDashboardLink(false));
		} else {
			moduleNavigation.goToSetupNeeded();
		}
	}

	function handleClientChange(currentClientId, newClientId) {
		if (!newClientId) {
			//-- Check local storage to see if a force push to the search/log pages is necessary
			var localStorageCurrentClientId = localStorage.getItem(`${ENVIRONMENT}-currentClientId`);

			if (localStorageCurrentClientId !== currentClientId) {
				localStorage.setItem(`${ENVIRONMENT}-currentClientId`, currentClientId);
				dispatch(clientActions.setCurrentClientId(currentClientId));

				if (!hasActiveSubscriptionForClient(currentClientId)) {
					moduleNavigation.goToSubscriptionExpired();
				} else if (moduleNavigation.trialId) {
					history.push(moduleNavigation.createTrialLink(false, null, "search"));
				} else if (moduleNavigation.fieldId) {
					history.push(moduleNavigation.createFieldLink(false, null, "search"));
				} else if (moduleNavigation.flightId) {
					history.push(moduleNavigation.createFlightLink(false, null, "log"));
				} else if (moduleNavigation.protocolId) {
					history.push(moduleNavigation.createProtocolLink(false, null, "search"));
				} else if (moduleNavigation.farmId) {
					history.push(moduleNavigation.createFarmLink(false, null, "search"));
				}
			}
		} else {
			newClientId = !newClientId ? currentClientId : newClientId;

			localStorage.setItem(`${ENVIRONMENT}-currentClientId`, newClientId);
			dispatch(clientActions.setCurrentClientId(newClientId));

			if (!hasActiveSubscriptionForClient(newClientId) && !moduleNavigation.savedPathIsLink()) {
				moduleNavigation.goToSubscriptionExpired();
			} else if (currentClientId && currentClientId != newClientId) {
				if (moduleNavigation.trialId) {
					history.push(moduleNavigation.createTrialLink(false, null, "search"));
				} else if (moduleNavigation.fieldId) {
					history.push(moduleNavigation.createFieldLink(false, null, "search"));
				} else if (moduleNavigation.flightId) {
					history.push(moduleNavigation.createFlightLink(false, null, "log"));
				}
			}
		}
	}

	function switchClient(currentClientId, newClientId) {
		newClientId = !newClientId ? currentClientId : newClientId;

		localStorage.setItem(`${ENVIRONMENT}-currentClientId`, newClientId);
		dispatch(clientActions.setCurrentClientId(newClientId));
	}

	const userAuth = {
		isReady,
		currentClientId,
		user,
		currentClient,
		setupNeeded,
		isApAdmin,
		isSubsriptionActive,
		handleClientChange,
		goHome,
		userCanAccessModule,
		userCanAccessSubmodule,
		switchClient,
		hasApplicationArea
	};

	return userAuth;
};
