import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal, Message, Loader, Button, Popup, Icon } from "semantic-ui-react";
import _ from "lodash";
import styles from "./styles.css";
import { toast } from "react-toastify";
import * as turf from "@turf/turf";
import { HubConnectionBuilder } from "@microsoft/signalr";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as flightActions from "../../../../redux/actions/flightActions";
import * as orthoActions from "../../../../redux/actions/orthoActions";

import moment from "moment";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import Tooltip from "rc-tooltip";

const UploadOrthoImage = ({
	flightImageId,
	orthos,
	selectedOrthomosaic,
	deleteUploadComponent,
	removeComponent,
	isOrthoTypeUploading,
	isOrthoTypeUploaded,
	clientId,
	orthoSelectedHandler,
	orthoRemovedHandler,
	orthoUploadedHandler,
	multispectralImageUploaded,
	multispectralImageName,
	flightsPlots,
	azureBrowseCallback,
	azureUploadSuccessCallback,
	selectedAzureFile
}) => {
	const dispatch = useDispatch();
	const userAuth = useUserAuth();
	const fileInputRef = useRef();
	const DATE_FORMAT = "MM/DD/YYYY";

	const { getTokenSilently } = useAuth0();

	//Data Sources
	const uploadingOrthos = useSelector((state) => state.orthoUploads);
	const moduleNavigation = useModuleNavigation();

	//Dropdown Options
	const [orthoTypeOptions, setOrthoTypeOptions] = useState([]);
	const [orthoImageTypeId, setOrthoImageTypeId] = useState(null);

	//Data
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [validationErrorState, setValidationErrorState] = useState(false);
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const [selectedFile, setSelectedFile] = useState({});
	const orthoTypes = orthos;
	const [selectedOrtho, setSelectedOrtho] = useState({});
	const [statusIcon, setStatusIcon] = useState("minus circle");
	const [isSelectedFileLocal, setisSelectedFileLocal] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isCutting, setIsCutting] = useState(false);
	const [displayPlotImageTooltip, setDisplayPlotImageTooltip] = useState(false);
	const [mapboxRetryLoading, setMapboxRetryLoading] = useState(false);
	const chunkSize = 1048576 * 3; //-- 3MB

	//-- SignalR
	//-- Create the signalR connection on component creation
	const [signalRConnection] = useState(setupSignalRConnection());
	const orthoRef = useRef(selectedOrtho);
	const [uploadStatus, setUploadStatus] = useState(null);

	//https://www.debuggr.io/react-update-unmounted-component/
	useEffect(() => {
		getPlotImagesUploadedStatus();

		//-- This will handle closing the connection on page navigation or browser close
		return () => stopSignalRConnection();
	}, []);

	useEffect(() => {
		if (selectedOrthomosaic) {
			var clonedOrtho = _.cloneDeep(selectedOrthomosaic);
			clonedOrtho.showRetryButton = clonedOrtho.isUploading && clonedOrtho.uploadStatus === "Uploading Image Chunks";
			clonedOrtho.showMapboxRetryButton = clonedOrtho.uploadStatus === "Mapbox Upload Failed";
			clonedOrtho.flightId = moduleNavigation.flightId;
			setSelectedOrtho(clonedOrtho);
		}
	}, [selectedOrthomosaic]);

	useEffect(() => {
		createDropdownOrthoOptions(orthoTypes);

		if (!orthoImageTypeId) {
			setOrthoImageTypeId(selectedOrtho.orthoImageTypeId);
		}
	}, [orthoTypes, orthoImageTypeId]);

	useEffect(() => {
		setStatusIcon(iconValue());
		orthoRef.current = selectedOrtho;

		if (selectedOrtho.isUploaded && signalRConnection) {
			stopSignalRConnection();
		}
	}, [selectedOrtho]);

	useEffect(() => {
		if (signalRConnection && signalRConnection?.state === "Disconnected" && selectedOrtho?.isUploading === true) {
			signalRConnection
				.start()
				.then(() => {
					signalRConnection.on("orthoUploadStatus", (uploadStatus) => {
						//-- uploadStatus has the following properties
						//-- flightImageId
						//-- isUploading
						//-- uploadStatus
						//-- tempFlightImageId

						if (
							orthoRef.current.uploadedFlightImageId === uploadStatus.flightImageId ||
							orthoRef.current.flightImageId === uploadStatus.flightImageId ||
							orthoRef.current.flightImageId === uploadStatus.tempFlightImageId
						) {
							setUploadStatus(uploadStatus.status);

							if (uploadStatus.status === "Uploaded") {
								dispatch(orthoActions.removeOrthoUpload(orthoRef.current.flightImageId));

								setSelectedOrtho((x) => ({
									...x,
									isUploading: false,
									showRetryButton: false,
									isUploaded: true
								}));
							} else {
								setSelectedOrtho((x) => ({
									...x,
									isUploading: uploadStatus.isUploading,
									showRetryButton: false,
									isUploaded: !uploadStatus.isUploading
								}));
							}
						}
					});
				})
				.catch((e) => console.log("Connection failed: ", e));
		}
	}, [signalRConnection, selectedOrtho]);

	useEffect(() => {
		if (selectedFile && orthoImageTypeId) {
			let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
			updatedSelectedOrtho.name = selectedFile.name;
			updatedSelectedOrtho.orthoImageTypeId = orthoImageTypeId;
			setSelectedOrtho(updatedSelectedOrtho);
		}
	}, [selectedFile]);

	useEffect(() => {
		if (selectedAzureFile !== undefined && flightImageId in selectedAzureFile) {
			const fileData = selectedAzureFile[flightImageId];
			fileData.name = fileData?.fileName;
			setSelectedFile(fileData);
			setSelectedOrtho({ ...selectedOrtho, name: fileData?.fileName });
			handleOrthoSelected(fileData?.fileName);
			setisSelectedFileLocal(false);
		}
	}, [selectedAzureFile]);

	useEffect(() => {
		if (!_.isEmpty(uploadingOrthos)) {
			const updatedSelectedOrtho = uploadingOrthos.find(
				(x) =>
					selectedOrtho.flightImageId &&
					(x.flightImageId === selectedOrtho.flightImageId || x.uploadedFlightImageId === selectedOrtho.flightImageId)
			);
			if (updatedSelectedOrtho) {
				//-- Check if the ortho failed to upload
				if (
					updatedSelectedOrtho.maxNumberRetryAttempts <= updatedSelectedOrtho.retryAttempt &&
					updatedSelectedOrtho.showRetryButton
				) {
					setValidationErrorState(true);
					setValidationErrorMessage(
						"Ortho failed to upload. Please try again or contact support if the error continues."
					);
				}
				setSelectedOrtho(updatedSelectedOrtho);
			} else if (selectedOrtho.isUploading) {
				setSelectedOrtho({ ...selectedOrtho, showTooltip: true });
			}
		} else if (selectedOrtho.isUploading && uploadStatus !== "Uploaded") {
			setSelectedOrtho({ ...selectedOrtho, showTooltip: true });
		}
	}, [uploadingOrthos]);

	function setupSignalRConnection() {
		const url = `${process.env.aerialPlotConnections.aerialImageApi.baseUrl}orthoUploadHub`;
		const newConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

		return newConnection;
	}

	function stopSignalRConnection() {
		if (signalRConnection) {
			signalRConnection.stop();
		}
	}

	const createDropdownOrthoOptions = (listOfOptions) => {
		const dropdownOrthoOptions = _.map(
			listOfOptions,
			({ orthoImageTypeId, orthoImageType, canGenerateMultispectralImage }) => {
				const opt = {
					key: orthoImageTypeId,
					value: orthoImageTypeId,
					text: orthoImageType,
					cangeneratemultispectralimage: String(canGenerateMultispectralImage)
				};
				return opt;
			}
		);

		//-- Move NDVI, NDRE and RGB to top of DDL
		const hasNdvi = _.findIndex(dropdownOrthoOptions, (o) => {
			return o.text === "NDVI";
		});

		const hasNdre = _.findIndex(dropdownOrthoOptions, (o) => {
			return o.text === "NDRE";
		});

		const hasRgb = _.findIndex(dropdownOrthoOptions, (o) => {
			return o.text === "RGB";
		});

		if (hasNdre !== -1) {
			dropdownOrthoOptions.unshift(dropdownOrthoOptions.splice(hasNdre, 1)[0]);
		}

		if (hasNdvi !== -1) {
			dropdownOrthoOptions.unshift(dropdownOrthoOptions.splice(hasNdvi, 1)[0]);
		}

		if (hasRgb !== -1) {
			dropdownOrthoOptions.unshift(dropdownOrthoOptions.splice(hasRgb, 1)[0]);
		}

		setOrthoTypeOptions(dropdownOrthoOptions);
	};

	const fileSelectedHandler = (event) => {
		if (event.target.files.length > 0) {
			const hasError = selectedOrtho.showRetryButton && validate(event.target.files[0]);
			if (hasError) {
				return;
			}
			setSelectedFile(event.target.files[0]);
			setSelectedOrtho({ ...selectedOrtho, name: event.target.files[0].name });
			handleOrthoSelected(event.target.files[0].name);
			setisSelectedFileLocal(true);
		}
	};

	const fileUploadHandler = async () => {
		if (isSelectedFileLocal) {
			const error = validate(selectedFile);

			if (error === true) {
				return;
			}

			selectedOrtho.orthoImageTypeId = orthoImageTypeId;

			let recordToCreate = new FormData();
			recordToCreate.set("flightId", selectedOrtho.flightId);
			recordToCreate.set("sensorId", selectedOrtho.sensorId);
			recordToCreate.set("orthoImageTypeId", selectedOrtho.orthoImageTypeId);
			recordToCreate.set("fileName", selectedFile.name);

			selectedOrtho.isUploading = true;
			selectedOrtho.uploadingChunks = true;
			handleOrthoUploaded(selectedFile.name);
			dispatch(orthoActions.addOrthoUpload(selectedOrtho));

			const accessToken = await getTokenSilently();

			dispatch(
				orthoActions.createInitialFlightImageRecord(
					recordToCreate,
					clientId,
					selectedFile,
					chunkSize,
					selectedOrtho,
					accessToken
				)
			).catch((e) => {
				console.error(e);
				toast.error("Image record failed to create");
			});
		} else {
			azureFileUploadHandler();
		}
	};

	async function azureFileUploadHandler() {
		const accessToken = await getTokenSilently();
		const azureShareFileItem = Object.values(selectedAzureFile)[0];
		// null properties are not needed for the azure file copy, but are stubbed for the orthoMetadata object
		const orthoMetadata = {
			flightId: selectedOrtho.flightId,
			tempFlightImageId: selectedOrtho.flightImageId,
			sensorId: selectedOrtho.sensorId,
			sensorName: selectedOrtho.sensorName,
			orthoImageTypeId: selectedOrtho.orthoImageTypeId,
			orthoImageTypeAbbreviation: null,
			fieldId: null,
			trialId: null,
			plotId: null,
			fileName: selectedOrtho.name,
			clientId
		};

		dispatch(
			orthoActions.addOrthoUpload({
				...selectedOrtho,
				isUploading: true,
				uploadPercent: 100,
				uploadingChunks: false,
				orthoImageTypeId: orthoImageTypeId
			})
		);
		setSelectedOrtho({
			...selectedOrtho,
			isUploading: true,
			uploadPercent: 100,
			uploadingChunks: false,
			orthoImageTypeId: orthoImageTypeId
		});
		const bodyData = { orthoMetadata, azureShareFileItem };
		dispatch(orthoActions.copyAzureFileToShare(bodyData, accessToken, clientId))
			.then((res) => {
				azureUploadSuccessCallback(bodyData);
				toast.success("Azure file copied successfully.");
				setSelectedOrtho({
					...selectedOrtho,
					uploadedFlightImageId: res.data,
					isUploaded: true,
					isUploading: false
				});

				dispatch(orthoActions.removeOrthoUpload(flightImageId));
			})
			.catch((e) => {
				console.error(e);
				if (e.statusCode === 500) {
					setSelectedOrtho({ ...selectedOrtho, isUploading: false });
					toast.error("Azure file copy failed. Please try again.");
					dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));
				} else if (e.statusCode === 400) {
					setSelectedOrtho({ ...selectedOrtho, isUploading: false });
					toast.error(e.response.data.Message);
					dispatch(orthoActions.removeOrthoUpload(selectedOrtho.flightImageId));
				} else {
					setSelectedOrtho({ ...selectedOrtho, isUploading: true, uploadPercent: 100, showTooltip: true });
				}
			});
	}

	async function generateMultispectralImage() {
		const error = validateGenerateImage();

		if (error === true) {
			return;
		}
		const ortho = _.cloneDeep(selectedOrtho);
		ortho.isUploading = true;
		ortho.uploadPercent = 100;
		const orthoImage = _.find(orthos, (o) => {
			return o.orthoImageTypeId === selectedOrtho.orthoImageTypeId;
		});
		const imageNames = multispectralImageName.split(".tif");
		ortho.name = `${imageNames[0]}_${orthoImage?.orthoImageType}.tif`;
		ortho.orthoImageTypeAbbreviation = orthoImage?.orthoImageType;
		ortho.sensorName = orthoImage?.sensorName;
		ortho.uploadStatus = "Generating Ortho";
		setSelectedOrtho(ortho);
		dispatch(orthoActions.addOrthoUpload(ortho));
		const accessToken = await getTokenSilently();
		dispatch(orthoActions.generateOrthoFromMultibandOrtho(clientId, ortho, accessToken))
			.then(() => {
				toast.success("Ortho Generated");
			})
			.catch((e) => {
				if (e.response && e.response.status === 500) {
					setSelectedOrtho({ ...selectedOrtho, isUploading: false });
					toast.error("Error uploading image");
				} else if (e.response && e.response.status === 400) {
					setSelectedOrtho({ ...selectedOrtho, isUploading: false });
					toast.error(e.response.data.Message);
				} else {
					setSelectedOrtho({ ...selectedOrtho, showTooltip: true });
				}
			});
	}

	async function retryUpload() {
		const error = validate(selectedFile);

		if (error === true) {
			return;
		}
		var image = _.cloneDeep(selectedOrtho);
		image.isUploading = true;
		image.showRetryButton = false;
		image.orthoImageTypeId = orthoImageTypeId;
		handleOrthoUploaded(image.name);
		let recordToCreate = new FormData();
		recordToCreate.set("flightId", selectedOrtho.flightId);
		recordToCreate.set("sensorId", selectedOrtho.sensorId);
		recordToCreate.set("orthoImageTypeId", selectedOrtho.orthoImageTypeId);
		recordToCreate.set("fileName", selectedFile.name);
		dispatch(orthoActions.addOrthoUpload(image));
		const accessToken = await getTokenSilently();
		if (image.uploadingChunks === true) {
			dispatch(
				orthoActions.retryFlightImageUploadChunks(
					recordToCreate,
					clientId,
					selectedFile,
					chunkSize,
					image,
					selectedOrtho.uploadedFlightImageId ?? selectedOrtho.flightImageId,
					selectedOrtho.numberOfChunksUploaded,
					accessToken
				)
			).catch((e) => {
				console.error(e);
				toast.error("Failed to upload ortho.");
			});
		}
	}

	async function retryMapboxUpload() {
		setMapboxRetryLoading(true);

		const accessToken = await getTokenSilently();
		dispatch(
			orthoActions.retryMapboxUpload(
				selectedOrtho.uploadedFlightImageId ?? selectedOrtho.flightImageId,
				clientId,
				accessToken
			)
		)
			.then(() => {
				setSelectedOrtho({ ...selectedOrtho, isUploaded: true, isUploading: false, showMapboxRetryButton: false });
				setMapboxRetryLoading(false);
			})
			.catch((e) => {
				if (e.response && (e.response.status === 500 || e.response.status === 600)) {
					console.error(e);
					toast.error("Mapbox Upload Failed");
					setMapboxRetryLoading(false);
				}
			});
	}

	function validate(file) {
		let error = false;
		if (!orthoImageTypeId) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("An ortho type must be selected.");
		} else if (selectedOrtho.showRetryButton === true && !file) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("To retry an uploading ortho, you must Browse the same file: " + selectedOrtho.name);
		} else if (selectedOrtho.showRetryButton === true && selectedOrtho.name !== file.name) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("To retry an uploading ortho, you must Browse the same file: " + selectedOrtho.name);
		} else if (selectedOrtho.name === "") {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("No Image Selected. Please select an image.");
		} else if (isOrthoTypeUploading(selectedOrtho)) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("You cannot have duplicate types of orthos for one sensor.");
		} else if (isOrthoTypeUploaded(selectedOrtho)) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("You cannot have duplicate types of orthos for one sensor.");
		} else if (file.type !== "image/tiff") {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("Image must be a tif image.");
		} else {
			error = false;
			setValidationErrorState(error);
		}

		return error;
	}

	function validateGenerateImage() {
		let error = false;
		if (!multispectralImageUploaded) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("Please upload a multispectral image to generate the selected ortho type.");
		} else if (isOrthoTypeUploading(selectedOrtho)) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("You cannot have duplicate types of orthos for one sensor.");
		} else if (isOrthoTypeUploaded(selectedOrtho)) {
			error = true;
			setValidationErrorState(error);
			setValidationErrorMessage("You cannot have duplicate types of orthos for one sensor.");
		} else {
			error = false;
			setValidationErrorState(error);
		}

		return error;
	}

	const iconValue = () => {
		if (selectedOrtho.isUploaded) {
			return "check circle";
		}
		return "minus circle";
	};

	async function downloadOrtho() {
		setIsDownloading(true);
		const accessToken = await getTokenSilently();
		dispatch(
			flightActions.downloadOrthoForFlight(
				selectedOrtho.uploadedFlightImageId ?? selectedOrtho.flightImageId,
				selectedOrtho.name,
				clientId,
				accessToken
			)
		)
			.then(() => {
				setIsDownloading(false);
				toast.success("Ortho downloaded.");
			})
			.catch(() => {
				setIsDownloading(false);
				toast.error("Ortho failed to download. Please try again.");
			});
	}

	async function deleteOrtho() {
		setIsDeleting(true);
		handleDeleteModalOpen();
		let updatedSelectedOrtho = _.cloneDeep(selectedOrtho);
		updatedSelectedOrtho.isDeleting = true;
		dispatch(orthoActions.updateUploadingOrtho(updatedSelectedOrtho));
		//Callback function will be called if the api call fails
		deleteUploadComponent(
			{
				flightImageId: selectedOrtho.flightImageId,
				uploadedFlightImageId: selectedOrtho.uploadedFlightImageId,
				clientId: clientId
			},
			() => {
				setIsDeleting(false);
				toast.error("Ortho failed to delete. Please try again.");
			}
		);
	}

	const handleDeleteModalOpen = () => {
		setDeleteModalOpen(!deleteModalOpen);
	};

	const handleOrthoSelected = (fileName) => {
		orthoSelectedHandler(fileName);
	};

	const handleOrthoRemoved = () => {
		orthoRemovedHandler(selectedFile.name);
	};

	const handleOrthoUploaded = (fileName) => {
		orthoUploadedHandler(fileName);
	};

	const createPlotImages = async (ortho) => {
		setIsCutting(true);

		let plotImageData = getPlotBoundingBoxes();

		const accessToken = await getTokenSilently();

		let orthoToCut = {
			flightId: moduleNavigation.flightId,
			clientId: clientId,
			sensorId: ortho.sensorId,
			orthoImageTypeId: ortho.orthoImageTypeId,
			plotImageData: plotImageData
		};

		dispatch(orthoActions.isFieldImageUploaded(orthoToCut, accessToken))
			.then((res) => {
				if (res.data == true) {
					dispatch(orthoActions.cutPlotImages(orthoToCut, accessToken))
						.then(() => {
							setIsCutting(false);
							toast.success("Plot Images Created Successfully");
						})
						.catch((err) => {
							if (err.response && (err.response.status === 500 || err.response.status === 600)) {
								setDisplayPlotImageTooltip(true);
							} else {
								console.error(err);
								setIsCutting(false);
								toast.error("Plot Images Failed to Create");
							}
						});
				} else {
					setIsCutting(false);
					toast.error("Field images need to be uploaded before cutting plot images.");
				}
			})
			.catch((err) => {
				console.error(err);
				setIsCutting(false);
				toast.error("Failed to find any flight images");
			});
	};

	const getPlotImagesUploadedStatus = async () => {
		if (selectedOrtho.isUploaded) {
			const accessToken = await getTokenSilently();

			let plotImagesToGet = {
				flightId: moduleNavigation.flightId,
				clientId: clientId,
				sensorId: selectedOrtho.sensorId,
				orthoImageTypeId: selectedOrtho.orthoImageTypeId
			};

			dispatch(orthoActions.getPlotImagesUploadedStatus(plotImagesToGet, accessToken)).then((res) => {
				if (!res.data) {
					setIsCutting(true);
					setDisplayPlotImageTooltip(true);
				}
			});
		}
	};

	function getPlotBoundingBoxes() {
		let result = [];
		_.map(flightsPlots, ({ fieldId, plotId, llLat, llLong, ulLat, ulLong, lrLat, lrLong, urLat, urLong }) => {
			let bbox = turf.bbox(
				turf.polygon([
					[
						[llLong, llLat],
						[ulLong, ulLat],
						[urLong, urLat],
						[lrLong, lrLat],
						[llLong, llLat]
					]
				])
			);
			let bboxPolygon = turf.bboxPolygon(bbox);

			let ll = [bboxPolygon.geometry.coordinates[0][3][0], bboxPolygon.geometry.coordinates[0][3][1]];
			let ul = [bboxPolygon.geometry.coordinates[0][0][0], bboxPolygon.geometry.coordinates[0][0][1]];
			let lr = [bboxPolygon.geometry.coordinates[0][2][0], bboxPolygon.geometry.coordinates[0][2][1]];
			let ur = [bboxPolygon.geometry.coordinates[0][1][0], bboxPolygon.geometry.coordinates[0][1][1]];

			let field = {
				llLat: ll[1],
				llLong: ll[0],
				ulLat: ul[1],
				ulLong: ul[0],
				lrLat: lr[1],
				lrLong: lr[0],
				urLat: ur[1],
				urLong: ur[0],
				fieldId: fieldId,
				plotId: plotId
			};

			result.push(field);
		});

		return result;
	}

	return (
		<Form error={validationErrorState}>
			<Message error content={validationErrorMessage} />
			<Form.Group style={{ gap: 2 }}>
				<Tooltip placement="top" overlay="Remove">
					<Form.Button
						icon={statusIcon}
						disabled={selectedOrtho.isUploaded || selectedOrtho.isUploading}
						onClick={() => {
							handleOrthoRemoved(selectedOrtho.name);
							removeComponent(selectedOrtho.flightImageId);
						}}
					/>
				</Tooltip>
				{isDownloading ? (
					<Loader active inline />
				) : (
					<Tooltip placement="top" overlay="Download Existing">
						<Form.Button
							icon="download"
							disabled={
								!selectedOrtho.isUploaded ||
								isDeleting ||
								selectedOrtho.isUploading ||
								!userAuth.hasApplicationArea(ApplicationArea.FlightDownloadOrtho, clientId)
							}
							onClick={downloadOrtho}
						/>
					</Tooltip>
				)}
				{isDeleting ? (
					<Loader active inline />
				) : (
					<Modal
						open={deleteModalOpen}
						trigger={
							selectedOrtho.analyzed ? null : (
								<Tooltip placement="top" overlay="Delete">
									<Form.Button
										onClick={handleDeleteModalOpen}
										icon="trash"
										disabled={
											!userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUpload, clientId) && (
												(!selectedOrtho.isUploading && !selectedOrtho.isUploaded) ||
												isDownloading ||
												isDeleting ||
												isCutting)
										}
									/>
								</Tooltip>
							)
						}
					>
						<Modal.Header>Delete Uploaded Ortho</Modal.Header>
						<Modal.Content>
							<Modal.Description>
								<p>Are you sure you want to delete {selectedOrtho.name}?</p>
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions>
							<Button floated="right" disabled={!userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUpload, clientId)} negative onClick={deleteOrtho}>
								Delete
							</Button>
							<Button floated="right" onClick={handleDeleteModalOpen}>
								Cancel
							</Button>
							<br style={{ clear: "both" }} />
						</Modal.Actions>
					</Modal>
				)}

				<Form.Select
					id="form-select-image-type"
					disabled={selectedOrtho.isUploaded || selectedOrtho.isUploading}
					options={orthoTypeOptions}
					value={orthoImageTypeId}
					onChange={(event, { value }) => {
						setOrthoImageTypeId(value);
						setSelectedOrtho({
							...selectedOrtho,
							orthoImageTypeId: value,
							canGenerateMultispectralImage:
								_.find(orthoTypeOptions, (oto) => {
									return oto.value === value;
								}).cangeneratemultispectralimage === "true"
						});
					}}
					loading={!orthoTypeOptions || orthoTypeOptions.length === 0}
				/>

				{selectedOrtho.isUploaded && !selectedOrtho.showRetryButton ? null : (
					<Form.Button
						content="Browse Local..."
						labelPosition="left"
						icon="file"
						onClick={() => fileInputRef.current.click()}
					/>
				)}
				{!userAuth.hasApplicationArea(ApplicationArea.FlightOrthoFileshare, userAuth.currentClientId) ||
					(selectedOrtho.isUploaded && !selectedOrtho.showRetryButton) ? null : (
					<Tooltip placement="top" overlay="Browse Cloud Files">
						<Form.Button icon="cloud" onClick={() => azureBrowseCallback(flightImageId)} />
					</Tooltip>
				)}

				<input ref={fileInputRef} type="file" accept="image/tiff" hidden onChange={fileSelectedHandler} />
				{<Form.Input type="text" width="5" readOnly value={selectedOrtho ? selectedOrtho.name : ""}></Form.Input>}
				{(selectedOrtho.isUploaded || selectedOrtho.isUploading || selectedOrtho.showRetryButton) &&
					(userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUpload, clientId) || userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUploadNoDelete, clientId)) ? null : (
					<Form.Button primary type="submit" onClick={fileUploadHandler}>
						Upload
					</Form.Button>
				)}
				{selectedOrtho.canGenerateMultispectralImage === true &&
					!selectedOrtho.isUploaded === true &&
					!selectedOrtho.isUploading === true &&
					!selectedOrtho.showRetryButton &&
					userAuth.hasApplicationArea(ApplicationArea.FlightGenerateOrtho, clientId) ? (
					<Form.Button primary type="submit" onClick={generateMultispectralImage}>
						Generate Image
					</Form.Button>
				) : null}
				{selectedOrtho.isUploading ? (
					selectedOrtho.uploadPercent < 100 ? (
						<div className={styles.centerText}>
							{selectedOrtho.retryingUpload === true ? (
								<p>
									{selectedOrtho.uploadPercent} % - Retrying upload in {selectedOrtho.retryInSeconds} seconds. Attempt:{" "}
									{selectedOrtho.retryAttempt} out of {selectedOrtho.maxNumberRetryAttempts}
								</p>
							) : (
								<p>{selectedOrtho.uploadPercent} %</p>
							)}
						</div>
					) : (
						<>
							<Loader active inline>
								{uploadStatus ?? selectedOrtho?.uploadStatus}
							</Loader>

							{selectedOrtho.showTooltip ? (
								<Popup
									content="Image is processing on the server. To check if the image has finished processing, reload the page by pressing F5."
									trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
								/>
							) : null}
						</>
					)
				) : null}
				{selectedOrtho?.showRetryButton ? (
					<Form.Button color="red" content="Retry Upload" onClick={retryUpload} />
				) : null}
				{selectedOrtho?.showMapboxRetryButton ? (
					<Form.Button
						color="red"
						content="Retry Mapbox Upload"
						loading={mapboxRetryLoading}
						disabled={mapboxRetryLoading}
						onClick={retryMapboxUpload}
					/>
				) : null}
				{selectedOrtho.isUploaded ? (
					<>
						{userAuth.isApAdmin === true && flightsPlots?.length > 0 && (
							<Form.Field>
								<Button
									primary
									onClick={() => createPlotImages(selectedOrtho)}
									loading={isCutting}
									disabled={isCutting || isDeleting}
								>
									Create Plot Images
								</Button>
								{displayPlotImageTooltip && (
									<Popup
										content="Plot images are processing on the server. To check if they have finished processing, reload the page by pressing F5."
										trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
									/>
								)}
							</Form.Field>
						)}
						<Form.Field className={styles.centerText}>
							Date Uploaded:{" "}
							{selectedOrtho?.createdDateTimeUtc
								? moment(selectedOrtho.createdDateTimeUtc).local().format(DATE_FORMAT)
								: moment().local().format(DATE_FORMAT)}
						</Form.Field>
					</>
				) : null}
			</Form.Group>
		</Form>
	);
};

UploadOrthoImage.propTypes = {
	flightImageId: PropTypes.string.isRequired,
	orthos: PropTypes.array.isRequired,
	selectedOrthomosaic: PropTypes.object.isRequired,
	deleteUploadComponent: PropTypes.func.isRequired,
	removeComponent: PropTypes.func.isRequired,
	isOrthoTypeUploading: PropTypes.func.isRequired,
	isOrthoTypeUploaded: PropTypes.func.isRequired,
	clientId: PropTypes.string.isRequired,
	orthoSelectedHandler: PropTypes.func.isRequired,
	orthoRemovedHandler: PropTypes.func.isRequired,
	orthoUploadedHandler: PropTypes.func.isRequired,
	multispectralImageUploaded: PropTypes.bool,
	multispectralImageName: PropTypes.string,
	flightsPlots: PropTypes.array,
	azureBrowseCallback: PropTypes.func,
	azureUploadSuccessCallback: PropTypes.func,
	selectedAzureFile: PropTypes.object
};

export default UploadOrthoImage;
